import {
  ClickableList,
  ClickableListItem
} from '@cdab/scania/qpr/components/atoms'
import { Block } from '@cdab/scania/sdds'

import type { DocumentListProps } from './document-list.types'

export function DocumentList({ documents }: DocumentListProps) {
  // TODO: Add file type icon
  // https://linear.app/congenialdata/issue/QPR-256/indicate-file-type-for-document-list

  return (
    <Block color='on-grey' disablePadding={true}>
      <ClickableList>
        {documents.map(document => (
          <ClickableListItem
            key={document.id}
            title={document.title}
            url={document.url}
            openInNewTab
          />
        ))}
      </ClickableList>
    </Block>
  )
}
