/**
 * Capitalizes the fist letter of a string
 *
 * @param text - The text to capitalize
 * @returns a capitalized string
 */
export const capitalizeFirstLetter = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1)

export type FormattedSystemMessage = {
  header: string
  message: string
}

/**
 * Capitalizes the first letter of each word of a string *
 * @param text - The text to capitalize
 * @returns a capitalized string
 */
export function capitalizeAllFirstLetters(text: string) {
  const splitText = text.toLowerCase().split(' ')
  for (let i = 0; i < splitText.length; i++) {
    splitText[i] =
      splitText[i].charAt(0).toUpperCase() + splitText[i].substring(1)
  }

  return splitText.join(' ')
}

/**
 * Converting Enum value to key with dashes for then use it as translation key  *
 * @enumString text - enum value converted to string
 * @returns a converted string
 */
export function enumToTranslationText(enumString: string) {
  const stringVal =
    enumString.toString().charAt(0).toLowerCase() +
    enumString.toString().slice(1)
  let bld = ''

  for (const char of stringVal) {
    if (char == char.toUpperCase()) {
      bld += '-'
    }

    bld += char
  }

  return bld.toString().toLowerCase()
}

/**
 * Check string value for length  *
 * @value value for check
 * @returns boolean
 */
export function checkStringLength(
  value: string,
  numChars: number,
  nullable = false
) {
  return nullable
    ? value?.length === 0 || value?.length >= numChars
    : value?.length >= numChars
}

/**
 * Remove html tags from string
 *
 * @text string for remove html tags
 * @returns string
 * @example
 * removeHtmlTags('<p>test</p>') // test
 */
export function removeHtmlTagsFromString(text: string) {
  return text.replace(/<\/?[^>]+(>|$)/g, '')
}

/**
 * Get first not empty line from html text
 * (working only with strings from our html editor)
 * @text string
 * @returns string
 * @example
 * getFirstLineFromHtmlText('<p>Te<em>s</em>t me<strong>ss</strong><s>ag</s>e</p><h4>sdbsd sdv</h4>') // Test message
 */
export function getFirstLineFromHtmlText(text: string) {
  for (let i = 5; i < text.length; i++) {
    const window = text.slice(i - 4, i)
    const bigWindow = text.slice(i - 5, i)

    if (bigWindow == '</h4>' || bigWindow == '</h5>' || bigWindow == '</h6>') {
      const mayBeFirstLine = text.slice(0, i)

      const mayBeFirstLineText = removeHtmlTagsFromString(mayBeFirstLine).trim()
      if (mayBeFirstLineText !== '') return mayBeFirstLineText
    }

    if (window == '</p>') {
      let mayBeFirstLine = text.slice(0, i)

      //should all next close tags like </ul>, otherwise they will be not deleted
      //searching for open tag like <h6> and adding string until that tag
      for (let ci = i + 1; ci < text.length; i++) {
        mayBeFirstLine += text.slice(ci, ci)
        if (text.charAt(ci - 1) == '<' && text.charAt(ci) != '/')
          if (ci - 2 > i) mayBeFirstLine = text.slice(0, ci - 2)
        break
      }

      const mayBeFirstLineText = removeHtmlTagsFromString(mayBeFirstLine).trim()
      if (mayBeFirstLineText !== '') return mayBeFirstLineText
    }
  }
  return removeHtmlTagsFromString(text)
}
