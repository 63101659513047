import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { CapitalizeFirstLetter } from '@cdab/headless-components'
import { AuditItemList } from '@cdab/scania/qpr/components/molecules'
import {
  IconArrowUp,
  IconChevronLeft,
  IconChevronRight,
  IconCross
} from '@cdab/scania/sdds'

import {
  BottomSticky,
  Content,
  Dropshadow,
  IconButton,
  Navigation,
  StyledIconChecvronRight,
  StyledLink,
  SummaryLinkItem,
  SummaryLinkItemCenter,
  SummaryLinkItemLeft,
  SummaryLinkItemRight,
  VariableProvider
} from './audit-bottom-navigation.styles'
import type { AuditBottomNavigationProps } from './audit-bottom-navigation.types'

/**
 * If you want to hide a button, omit the callback function.
 */
export const AuditBottomNavigation = observer(
  ({
    audit,
    onItemClick,
    onLeftClick,
    onToggleMenu,
    isOpen,
    onViewPledgeClick,
    onRightClick,
    currentOpenItems = [],
    currentSelectedItem,
    onScoreChange
  }: AuditBottomNavigationProps) => {
    const { t } = useTranslation('audit')
    function toggleMenu() {
      if (onToggleMenu) {
        onToggleMenu(!isOpen)
      }
    }

    return (
      <VariableProvider>
        <Dropshadow onClick={toggleMenu} isOpen={isOpen} />
        <BottomSticky isOpen={isOpen}>
          <StyledLink to='../summary'>
            <SummaryLinkItem>
              <SummaryLinkItemLeft />
              <SummaryLinkItemCenter>
                <CapitalizeFirstLetter>
                  {t('audit-summary')}
                </CapitalizeFirstLetter>
              </SummaryLinkItemCenter>
              <SummaryLinkItemRight>
                <StyledIconChecvronRight />
              </SummaryLinkItemRight>
            </SummaryLinkItem>
          </StyledLink>
          <Content>
            <AuditItemList
              showOpenPledgeButton
              currentSelectedItem={currentSelectedItem}
              onItemClick={onItemClick}
              audit={audit}
              currentOpenItems={currentOpenItems}
              onScoreChange={onScoreChange}
              onViewPledgeClick={onViewPledgeClick}
              isBottomNavigationList={true}
            />
          </Content>
        </BottomSticky>
        <Navigation>
          <div>
            {onLeftClick && (
              <IconButton onClick={onLeftClick}>
                <IconChevronLeft />
              </IconButton>
            )}
          </div>

          <div>
            <IconButton onClick={toggleMenu}>
              {isOpen ? <IconCross /> : <IconArrowUp />}
            </IconButton>
          </div>

          <div>
            {onRightClick && (
              <IconButton onClick={onRightClick}>
                <IconChevronRight />
              </IconButton>
            )}
          </div>
        </Navigation>
      </VariableProvider>
    )
  }
)
