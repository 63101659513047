import { Message, Row } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const StyledRow = styled(Row)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export const FullWidth = styled.div`
  width: 100%;
`

export const MessagesRow = styled(Row)`
  flex-direction: column;
`

export const StyledMessage = styled(Message)`
  flex: auto;
`

export const StyledFirstRow = styled(Row)`
  padding-top: 5rem;
`
