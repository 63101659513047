import { v4 as uuidv4 } from 'uuid'
import { action } from 'mobx'
import invariant from 'tiny-invariant'

import type {
  BackEnd,
  CreateDeviationResponse
} from '@cdab/scania/qpr/interactor'
import type { Audit, Deviation } from '@cdab/scania/qpr/schema'

import { getAudit } from '../store/audits'
import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import type { AuditModel } from '../audit-model'

type Type = 'create-deviation'

type Data = Deviation

export type CreateDeviationOperationData = OperationDataBase<Type, Data>

export class CreateDeviationOperation extends Operation {
  public data: CreateDeviationOperationData
  private response: CreateDeviationResponse | undefined = undefined

  constructor(auditId: Audit['id'], value: Data, guid = uuidv4()) {
    super(auditId, guid)

    this.data = {
      previousValue: undefined,
      type: 'create-deviation',
      value: value
    }
  }

  public ApplyTo = action((audit: AuditModel): void => {
    const { clientGuid, id } = this.data.value

    const deviation = audit.deviations.find(
      d => (d.id !== undefined && d.id === id) || d.clientGuid === clientGuid
    )

    if (deviation) {
      console.warn('We have already created deviation!', deviation)
      return
    }

    audit.deviations.push({
      ...this.data.value,
      id: this.response?.deviationId || this.data.value.id,
      actionPlanId: this.response?.actionPlanId || this.data.value.actionPlanId,
      files: [],
      isGettingFiles: false
    })
  })

  public GetName = () =>
    `${this.data.type}-${this.auditId}-${this.data.value.clientGuid}`

  public SendOperation = async (client: BackEnd): Promise<boolean> => {
    this.response = await client.AuditsRealTimeService.CreateDeviation(
      this.guid,
      this.data.value,
      new Date()
    )

    if (this.response.deviationId === -1) {
      return false
    }

    this.assignPostCreateData(this.response)

    return true
  }

  private assignPostCreateData = action(
    (createDeviationResponse: CreateDeviationResponse): void => {
      const { deviationId, actionPlanId } = createDeviationResponse

      const audit = getAudit(this.auditId)
      invariant(
        audit,
        'Trying to update a deviation without having it in state!'
      )

      const { clientGuid } = this.data.value
      const deviation = audit.deviations.find(d => d.clientGuid === clientGuid)
      invariant(
        deviation,
        'Tried to update a deviation we did not have in store!'
      )

      deviation.id = deviationId
      deviation.actionPlanId = actionPlanId
      this.data.value.id = deviationId
    }
  )

  public RollbackOn = action((audit: AuditModel): void => {
    const deviationIndex = audit.deviations.findIndex(
      ({ clientGuid }) => clientGuid === this.data.value.clientGuid
    )

    invariant(
      deviationIndex !== -1,
      'Tried to rollback create-deviation operation but deviation could not be found!'
    )

    audit.deviations.splice(deviationIndex, 1)
  })
}
