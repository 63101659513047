import { useCallback, useEffect, useState } from 'react'

import type { TOption } from '@cdab/scania/sdds'

import {
  CenteredContainer,
  ElasticContentContainer
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useUser } from '@cdab/scania/qpr/contexts/user-provider'
import { useCssVariableBreakpoint, useUpdateUser } from '@cdab/scania/qpr/hooks'
import {
  AuditReminderIntervals,
  EditRights,
  type Language,
  type Market,
  type User
} from '@cdab/scania/qpr/schema'
import {
  Button,
  Column,
  Container,
  Divider,
  Dropdown,
  DropdownOption,
  EmptyScreen,
  IconWarning,
  Row,
  Textfield,
  Toast,
  Toggle
} from '@cdab/scania/sdds'
import {
  capitalizeFirstLetter,
  checkStringLength,
  enumToTranslationText
} from '@cdab/utils'
import { useTranslation } from 'react-i18next'
import {
  isRouteErrorResponse,
  json,
  useLoaderData,
  useRouteError
} from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { StyledMessage } from './admin/checkpoints/checkpoints.styles'

type ProfilePageLoaderData = {
  user: User
  languages: Language[]
  userMarkets: Market[]
}

const Title = styled.h4.attrs({
  className: 'sdds-headline-04'
})`
  margin-bottom: 2rem;
  margin-top: 2rem;

  @media (max-width: ${({ theme }) => theme.size.lg}) {
    margin-bottom: 3rem;
  }
`
const StyledRow = styled(Row)`
  flex-wrap: nowrap;

  margin-top: 4rem;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.size.lg}) {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const ScrollableWrapper = styled.div`
  height: 100%;
  overflow: scroll;
`

const ToggleWrapper = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;

  @media (max-width: ${({ theme }) => theme.size.lg}) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`

const ButtonMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 4rem;

  @media (max-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 3rem;
  }
`

const StyledButton = styled(Button)`
  flex: 0.13;
  padding-right: 4px;
`

const StyledColumn = styled(Column)`
  @media (max-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`

interface DataProfile {
  cellPhone: string
  country: string
  languageId: number
  editRights: number
  alertOnCertify: boolean
  auditReminderInterval: number | null
  defaultMarketId: number
}

export async function loader(): Promise<ProfilePageLoaderData> {
  const client = getClient()
  const userInfo = await client.GetuserInfo()

  if (!userInfo) {
    throw json({
      status: 401
    })
  }

  try {
    const [user, languages, userMarkets] = await Promise.all([
      client.UserService.GetUser(userInfo.userId),
      client.UserService.GetLanguagesForUser(),
      client.MarketsService.GetMarketsForUser(userInfo.userId)
    ])

    return {
      user,
      languages,
      userMarkets
    }
  } catch (error) {
    throw new Response(null, { status: 404 })
  }
}

export function ProfilePage() {
  const { t } = useTranslation(['user', 'common'])
  const { updateTitles } = useTitle()
  const userContext = useUser()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { user, languages, userMarkets } =
    useLoaderData() as ProfilePageLoaderData

  const [data, setData] = useState<DataProfile>({
    cellPhone: user.cellPhone,
    country: user.country,
    languageId: user.languageId,
    editRights: user.editRights,
    alertOnCertify: user.alertOnCertify,
    auditReminderInterval: user.auditReminderInterval,
    defaultMarketId: user.defaultMarketId
  })

  const [submitState, handleClickUpdateUser, setSubmitState] = useUpdateUser(
    user.userId,
    {
      ...user,
      ...data,
      auditReminderInterval: data.auditReminderInterval,
      editRightId: data.editRights,
      roleId: user.role,
      viewId: user.viewId,
      markets: user.markets.map(market => market.id)
    },
    undefined
  )

  const [stateCellPhone, setStateCellPhone] = useState<boolean>(true)
  const [stateCountry, setStateCountry] = useState<boolean>(true)

  const checkAllData = useCallback(() => {
    return stateCountry && stateCellPhone
  }, [stateCellPhone, stateCountry])

  const validateAllData = useCallback(() => {
    setStateCellPhone(checkStringLength(data.cellPhone, 5, true))
    setStateCountry(checkStringLength(data.country, 4, true))

    return checkAllData()
  }, [checkAllData, data.cellPhone, data.country])

  useEffect(() => {
    updateTitles({
      contentHeader: {
        title: t('profile-and-settings'),
        subtitle: `${user.firstName} ${user.lastName}`
      },
      mobile: {
        title: t('profile-and-settings'),
        description: null
      }
    })
  }, [t])

  const updateUserContext = useCallback(() => {
    const userData: User = {
      ...user,
      ...data
    }

    userContext.setData(userData)
  }, [data, user, userContext])

  async function handleSave() {
    validateAllData()
    await handleClickUpdateUser()
    updateUserContext()
  }

  useEffect(() => {
    if (submitState.status === 'success') {
      toast(
        <Toast
          type='success'
          headline={t('profile-saved')}
          subheadline={submitState.message}
        />
      )
      setSubmitState({ status: 'none', message: null })
    }
  }, [submitState.status, submitState.message, t, setSubmitState])

  return (
    <ElasticContentContainer overflowHidden={true}>
      <ScrollableWrapper>
        <Container
          fluid='normal'
          paddingOnContainer={isLg}
          paddingOnColumns={isLg}
        >
          <Column width={12} lg={8} offset={{ lg: 2 }}>
            <StyledRow>
              <Column width={12} lg={6} padding={isLg}>
                <Title>{t('profile')}</Title>
              </Column>
            </StyledRow>
            <StyledRow>
              <StyledColumn width={12} lg={6} padding={isLg}>
                <Textfield
                  label={t('mobile-phone')}
                  labelPosition='outside'
                  maxLength={20}
                  name='cellPhone'
                  placeholder={t('mobile-phone')}
                  value={data.cellPhone ?? ''}
                  disabled={false}
                  onChange={e => {
                    setData({ ...data, cellPhone: e.target.value })
                  }}
                  onBlur={e => {
                    setStateCellPhone(
                      checkStringLength(e.target.value, 5, true)
                    )
                  }}
                  state={!stateCellPhone ? 'error' : undefined}
                  helper={
                    !stateCellPhone &&
                    t('message.validation-error', { chars: '5' })
                  }
                />
              </StyledColumn>
              <StyledColumn width={12} lg={6} padding={isLg}>
                <Textfield
                  label={t('country')}
                  labelPosition='outside'
                  maxLength={40}
                  name='country'
                  placeholder={t('country')}
                  value={data.country ?? ''}
                  disabled={false}
                  onChange={e => setData({ ...data, country: e.target.value })}
                  onBlur={e => {
                    setStateCountry(checkStringLength(e.target.value, 4, true))
                  }}
                  state={!stateCountry ? 'error' : 'success'}
                  helper={
                    !stateCountry &&
                    t('message.validation-error', { chars: '4' })
                  }
                />
              </StyledColumn>
            </StyledRow>
            <Divider type='light' className='sdds5 min-u-mb1' />
            <StyledRow>
              <Column width={12} lg={6} padding={isLg}>
                <Title>{t('settings')}</Title>
              </Column>
            </StyledRow>
            <StyledRow>
              <StyledColumn width={12} lg={6} padding={isLg}>
                <Dropdown
                  id='languageId'
                  label={t('language')}
                  size='lg'
                  labelPosition='outside'
                  placeholder={t('language')}
                  defaultOption={data.languageId.toString()}
                  openDirection={isLg ? 'down' : 'auto'}
                  onSelect={(option: TOption) => {
                    setData({
                      ...data,
                      languageId: parseInt(option.value)
                    })
                  }}
                >
                  {languages
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(language => (
                      <DropdownOption
                        key={language.id}
                        value={language.id.toString()}
                        text={language.name.trim()}
                      />
                    ))}
                </Dropdown>
              </StyledColumn>
              <StyledColumn width={12} lg={6} padding={isLg}>
                <Dropdown
                  id='auditReminderInterval'
                  label={t('audit-reminder-interval')}
                  size='lg'
                  labelPosition='outside'
                  placeholder={t('audit-reminder-interval')}
                  defaultOption={
                    data.auditReminderInterval
                      ? data.auditReminderInterval.toString()
                      : 'null'
                  }
                  openDirection={isLg ? 'down' : 'auto'}
                  onSelect={(option: TOption) => {
                    setData({
                      ...data,
                      auditReminderInterval:
                        option.value === 'null' ? null : parseInt(option.value)
                    })
                  }}
                >
                  {AuditReminderIntervals.map(interval => (
                    <DropdownOption
                      key={interval.id}
                      value={interval.id}
                      text={interval.name}
                    />
                  ))}
                </Dropdown>
              </StyledColumn>
            </StyledRow>
            <StyledRow>
              <StyledColumn width={12} lg={6} padding={isLg}>
                <Dropdown
                  id='editRightsId'
                  label={t('edit-rights')}
                  size='lg'
                  disabled={true}
                  labelPosition='outside'
                  placeholder={t('edit-rights')}
                  defaultOption={data.editRights.toString()}
                  openDirection={isLg ? 'down' : 'auto'}
                  onSelect={(option: TOption) => {
                    setData({
                      ...data,
                      editRights: parseInt(option.value)
                    })
                  }}
                >
                  {/* Since it not possible to show '0' value in dropdowns I'll do a temp solution.
                     +1 to all edit rights for show and then -1 back */}
                  {(
                    Object.keys(EditRights) as Array<keyof typeof EditRights>
                  ).map((key, index) => {
                    if (parseInt(key) >= 0) {
                      return (
                        <DropdownOption
                          key={index}
                          value={index.toString()}
                          text={t(
                            enumToTranslationText(EditRights[key].toString())
                          )}
                        />
                      )
                    }

                    return null
                  })}
                </Dropdown>
              </StyledColumn>
              <StyledColumn width={12} lg={6} padding={isLg}>
                <ToggleWrapper>
                  <Toggle
                    checked={data.alertOnCertify ?? false}
                    id='alertOnCertifyToggle'
                    label={t('alert-on-certify')}
                    onChange={() =>
                      setData({
                        ...data,
                        alertOnCertify: !data.alertOnCertify
                      })
                    }
                  />
                </ToggleWrapper>
              </StyledColumn>
            </StyledRow>
            <StyledRow>
              <StyledColumn width={12} lg={6} padding={isLg}>
                <Dropdown
                  id='defaultMarketId'
                  label={t('main-market')}
                  size='lg'
                  labelPosition='outside'
                  placeholder={t('main-market')}
                  defaultOption={data.defaultMarketId.toString()}
                  openDirection={isLg ? 'down' : 'auto'}
                  onSelect={(option: TOption) => {
                    setData({
                      ...data,
                      defaultMarketId: parseInt(option.value)
                    })
                  }}
                >
                  {userMarkets
                    .sort((a: Market, b: Market) =>
                      a.marketName.localeCompare(b.marketName)
                    )
                    .map(market => (
                      <DropdownOption
                        key={market.id}
                        value={market.id.toString()}
                        text={market.marketName}
                      />
                    ))}
                </Dropdown>
              </StyledColumn>
            </StyledRow>
            <StyledRow>
              <Column width={12} lg={12} padding={isLg}>
                {submitState.status === 'error' && (
                  <StyledMessage
                    className='sdds-u-mt1'
                    variant='single-line'
                    singleLineMessage={submitState.message}
                    type={submitState.status}
                  />
                )}
              </Column>
            </StyledRow>
            <StyledRow>
              <Column width={12} lg={12} padding={isLg}>
                <ButtonMessageWrapper>
                  <StyledButton
                    disabled={!checkAllData()}
                    text={capitalizeFirstLetter(t('save', { ns: 'common' }))}
                    onClick={handleSave}
                  />
                </ButtonMessageWrapper>
              </Column>
            </StyledRow>
          </Column>
        </Container>
      </ScrollableWrapper>
    </ElasticContentContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    switch (error.status) {
      case 401:
        message = t('not-logged-in')
        break
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight={true} width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
