import { createMachine, assign } from 'xstate'

// Context shape
interface BackendContext<T> {
  data: T | undefined
  error: unknown | undefined
}

// Shared context shapes
interface BackendContextEmpty<T> extends BackendContext<T> {
  data: undefined
  error: undefined
}

// States
export const BACKEND_STATE_IDLE = 'idle'
export const BACKEND_STATE_LOADING = 'loading'

export const BACKEND_STATE_SUCCESS = 'success'
interface BackendContextSuccess<T> extends BackendContext<T> {
  data: T
  error: undefined
}

export const BACKEND_STATE_ERROR = 'error'
interface BackendContextError<T> extends BackendContext<T> {
  data: undefined
  error: unknown
}

// Events
export const BACKEND_EVENT_START = 'START'
export const BACKEND_EVENT_RETRY = 'RETRY'
export const BACKEND_EVENT_RESTART = 'RESTART'

// Machine shape
export type BackendEvent = {
  type:
    | typeof BACKEND_EVENT_START
    | typeof BACKEND_EVENT_RETRY
    | typeof BACKEND_EVENT_RESTART
}

export type BackendTypestate<T> =
  | {
      value: typeof BACKEND_STATE_IDLE | typeof BACKEND_STATE_LOADING
      context: BackendContextEmpty<T>
    }
  | {
      value: typeof BACKEND_STATE_SUCCESS
      context: BackendContextSuccess<T>
    }
  | {
      value: typeof BACKEND_STATE_ERROR
      context: BackendContextError<T>
    }

export interface CreateBackendMachineConfig<T> {
  callBackend: () => Promise<T>
}

export const createBackendMachine = <T>({
  callBackend
}: CreateBackendMachineConfig<T>) =>
  createMachine<BackendContext<T>, BackendEvent, BackendTypestate<T>>({
    id: 'backendmachine',
    predictableActionArguments: true,
    initial: BACKEND_STATE_IDLE,
    context: {
      data: undefined,
      error: undefined
    },
    states: {
      [BACKEND_STATE_IDLE]: {
        on: {
          [BACKEND_EVENT_START]: BACKEND_STATE_LOADING
        }
      },
      [BACKEND_STATE_LOADING]: {
        entry: function resetContext() {
          assign({
            data: undefined,
            error: undefined
          })
        },
        invoke: {
          src: callBackend,
          onDone: {
            target: BACKEND_STATE_SUCCESS,
            actions: assign({
              data: (_, event) => event.data
            })
          },
          onError: {
            target: BACKEND_STATE_ERROR,
            actions: assign({
              error: (_, event) => event.data
            })
          }
        }
      },
      [BACKEND_STATE_ERROR]: {
        on: {
          [BACKEND_EVENT_RETRY]: BACKEND_STATE_LOADING
        }
      },
      [BACKEND_STATE_SUCCESS]: {
        on: {
          [BACKEND_EVENT_RESTART]: BACKEND_STATE_LOADING
        }
      }
    }
  })
