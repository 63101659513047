type SortDate = {
  expiryDate: Date | null
}

type SortAuditPointNo = {
  auditPointNo: string
}

export function sortFnByExpiryDate(
  { expiryDate: a }: SortDate,
  { expiryDate: b }: SortDate
) {
  if (a === null) return 1
  if (b === null) return -1

  return a < b ? 1 : -1
}

export function sortFnByAuditPointNo(
  { auditPointNo: a }: SortAuditPointNo,
  { auditPointNo: b }: SortAuditPointNo
): number {
  const aParts = a.split('.').map(Number)
  const bParts = b.split('.').map(Number)

  for (let i = 0; i < aParts.length; i++) {
    if (aParts[i] !== bParts[i]) {
      return aParts[i] - bParts[i]
    }
  }

  return 0
}
