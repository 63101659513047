import { IconCross } from '@cdab/scania/sdds'
import styled from 'styled-components'
import type { AuditDetailPanelWrapperProps } from './audit-detail-panel.interfaces'

const HeaderRow = styled.div`
  flex-wrap: nowrap !important;
  justify-content: space-between;
  align-items: center;
`

export function AuditDetailPanelWrapper({
  children,
  onCloseClick,
  header,
  className
}: AuditDetailPanelWrapperProps): JSX.Element {
  return (
    <div className={className}>
      <HeaderRow className='sdds-row'>
        <h4>{header}</h4>
        {onCloseClick && (
          <button
            className='sdds-btn sdds-btn-sm sdds-btn-secondary'
            onClick={onCloseClick}
            title='Close'
          >
            <IconCross />
          </button>
        )}
      </HeaderRow>
      {children}
    </div>
  )
}
