import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: '',
  toastClassName: '',
  bodyClassName: '',
  progressClassName: ''
})`
  --toastify-color-light: none;
  --toastify-toast-width: 352px;
  --toastify-toast-max-height: 116;

  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    margin-bottom: 2rem;
    padding: 0;
    border-left: 0 !important;
  }
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__progress-bar {
  }

  @media (max-width: ${({ theme }) => theme.size.md}) {
    width: 100%;
    padding-right: 4rem;
    left: 0;
    margin: 0;
    bottom: 0;
    transform: translateX(0);
  }
`
