import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export type ReferenceDocument = {
  auditPointId: number | null
  checkPointId: number | null
  documentVersion: number
  id: number
  title: string
  url: string
}

export const ReferenceDocumentSchema: JSONSchemaType<ReferenceDocument> = {
  type: 'object',
  properties: {
    auditPointId: {
      type: 'integer',
      nullable: true
    },
    checkPointId: {
      type: 'integer',
      nullable: true
    },
    documentVersion: {
      type: 'integer'
    },
    id: {
      type: 'integer'
    },
    title: {
      type: 'string'
    },
    url: {
      type: 'string'
    }
  },
  required: [
    'auditPointId',
    'checkPointId',
    'documentVersion',
    'id',
    'title',
    'url'
  ]
}

const validateReferenceDocument: ValidateFunction<ReferenceDocument> =
  ajv.compile(ReferenceDocumentSchema)

export const isValidReferenceDocument = (
  referenceDocument: unknown
): referenceDocument is ReferenceDocument =>
  isValidThing<ReferenceDocument>(referenceDocument, validateReferenceDocument)
