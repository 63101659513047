import { v4 as uuidv4 } from 'uuid'
import invariant from 'tiny-invariant'

import type { Audit, Deviation, FileData } from '@cdab/scania/qpr/schema'
import type { BackEnd } from '@cdab/scania/qpr/interactor'

import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import type { AuditModel, DeviationModel } from '../audit-model'

type Type = 'delete-deviation-file'

type Data = {
  deviationId: Required<Pick<Deviation, 'id'>>['id']
  fileId: FileData['id']
}

export type DeleteDeviationFileOperationData = OperationDataBase<Type, Data>

export class DeleteDeviationFileOperation extends Operation {
  public data: DeleteDeviationFileOperationData
  private file: FileData | undefined = undefined

  constructor(auditId: Audit['id'], value: Data, guid = uuidv4()) {
    super(auditId, guid)

    this.data = {
      type: 'delete-deviation-file',
      value
    }
  }

  private getDeviation = (audit: AuditModel): DeviationModel => {
    const { deviationId } = this.data.value

    const deviation = audit.deviations.find(d => d.id === deviationId)
    invariant(deviation, `No deviation found for deviation id ${deviation}`)

    return deviation
  }

  public ApplyTo(audit: AuditModel): void {
    const { deviationId, fileId } = this.data.value

    const deviation = this.getDeviation(audit)

    const fileIndex = deviation.files.findIndex(f => f.id === fileId)
    this.file = deviation.files[fileIndex]
    invariant(
      this.file,
      `No file found (for deviation with id ${deviationId}) with id ${fileId}`
    )
    invariant(
      this.file.isUploaded === true,
      "File is not uploaded. To delete a file which isn't uploaded, cancel the create operation instead"
    )

    deviation.files.splice(fileIndex, 1)
  }

  public GetName(): string {
    const { deviationId, fileId } = this.data.value
    return `${this.Type}-${deviationId}-${fileId}`
  }

  public SendOperation = async (client: BackEnd): Promise<boolean> => {
    const { deviationId, fileId } = this.data.value

    await client.StorageService.DeleteFileForDeviation(deviationId, fileId)

    return true
  }

  public RollbackOn(audit: AuditModel): void {
    invariant(this.file, `Cannot rollback when no file is saved!`)

    const deviation = this.getDeviation(audit)

    deviation.files.push(this.file)
  }
}
