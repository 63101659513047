import { Button, Container, Row } from '@cdab/scania/sdds'

export function LoggedOut() {
  return (
    <Container>
      <Row>
        <h1>Scania QPR</h1>
      </Row>
      <Row>
        <p>You have been logged out.</p>
      </Row>
      <Row>
        {/* Using standard a tag so that we get a full refetch of the page. It works using a react router Link,
        but this is faster and generally what we want since it resets the page. */}
        <a href='/'>
          <Button text='Press here to log in again' />
        </a>
      </Row>
    </Container>
  )
}
