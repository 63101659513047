import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Outlet, useLoaderData } from 'react-router'
import styled from 'styled-components'
import invariant from 'tiny-invariant'

import { CapitalizeFirstLetter } from '@cdab/headless-components'
import { BadgeTab, ElasticContentContainer } from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useUser } from '@cdab/scania/qpr/contexts/user-provider'
import type {
  ADHAuditWithUnreadMessages,
  Dealer,
  ExpiringDeviation,
  SystemMessage
} from '@cdab/scania/qpr/schema'
import { Badges, Column, NavigationTabs, Row } from '@cdab/scania/sdds'

export type DashboardLoaderData = {
  allSystemMessages: SystemMessage[]
  dealers: Dealer[]
  expiringDeviations: ExpiringDeviation[]
  unreadSystemMessages: SystemMessage[]
  adhAudits: ADHAuditWithUnreadMessages[]
}

export async function loader(): Promise<DashboardLoaderData> {
  const client = getClient()
  const userInfo = await client.GetuserInfo()
  invariant(
    userInfo,
    'Cannot fetch system messages without having logged in to Keycloak'
  )

  const [
    allSystemMessages,
    dealers,
    unreadSystemMessages,
    expiringDeviations,
    adhAudits
  ] = await Promise.all([
    client.SystemMessagesService.GetSystemMessagesForUser(),
    client.DealersService.GetExpiring(),
    client.SystemMessagesService.GetUnreadSystemMessagesForUser(),
    client.DeviationsService.GetExpiringDeviationsForUser(),
    client.ADHService.GetLinkActionPlansForUser()
  ])

  return {
    allSystemMessages,
    dealers,
    unreadSystemMessages,
    expiringDeviations,
    adhAudits
  }
}

export function Dashboard() {
  const { updateTitles, desktop } = useTitle()
  const { t } = useTranslation(['common', 'dashboard'])
  const { dealers, unreadSystemMessages, expiringDeviations, adhAudits } =
    useLoaderData() as DashboardLoaderData

  const totalDeviationsLength = adhAudits.reduce(
    (sum, audit) => sum + audit.deviations.length,
    0
  )
  useLoaderData() as DashboardLoaderData
  const { userData } = useUser()

  useEffect(() => {
    updateTitles({
      contentHeader: {
        title: t('dashboard'),
        subtitle: `Welcome, ${userData.firstName} ${userData.lastName}`
      },
      desktop: {
        title: desktop.title
      },
      mobile: {
        title: t('dashboard'),
        description: null
      }
    })
  }, [])

  return (
    <ElasticContentContainer
      overflowHidden={true}
      header={
        <NavigationTabs>
          <NavigationTabs.Tab to='system-messages'>
            <BadgeTab>
              <CapitalizeFirstLetter>
                {t('tabs.system-messages', { ns: 'dashboard' })}
              </CapitalizeFirstLetter>
              <Badges value={unreadSystemMessages.length.toString()} />
            </BadgeTab>
          </NavigationTabs.Tab>
          <NavigationTabs.Tab to='expiring-certificates'>
            <BadgeTab>
              <CapitalizeFirstLetter>
                {t('tabs.expiring-certificates', { ns: 'dashboard' })}
              </CapitalizeFirstLetter>
              <Badges value={dealers.length.toString()} />
            </BadgeTab>
          </NavigationTabs.Tab>
          <NavigationTabs.Tab to='expiring-action-plans'>
            <BadgeTab>
              <CapitalizeFirstLetter>
                {t('tabs.expiring-action-plans', { ns: 'dashboard' })}
              </CapitalizeFirstLetter>
              <Badges value={expiringDeviations.length.toString()} />
            </BadgeTab>
          </NavigationTabs.Tab>
          <NavigationTabs.Tab to='deviations-feedback'>
            <BadgeTab>
              <CapitalizeFirstLetter>
                {t('tabs.deviations-feedback', { ns: 'dashboard' })}
              </CapitalizeFirstLetter>
              <Badges value={totalDeviationsLength.toString()} />
            </BadgeTab>
          </NavigationTabs.Tab>
        </NavigationTabs>
      }
    >
      <Row fullHeight>
        <Outlet />
      </Row>
    </ElasticContentContainer>
  )
}

export const StyledColumn = styled(Column)`
  padding-top: var(--sdds-spacing-element-16);
  padding-bottom: var(--sdds-spacing-element-16);
`
