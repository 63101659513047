import type { Audit, AuditPoint } from '@cdab/scania/qpr/schema'
import { TdsButton } from '@scania/tegel-react'
import { useTranslation } from 'react-i18next'
import { useDeviationModal } from '../deviation-modal'

export interface CreateDeviationButtonProps {
  allowEditAuditPoint?: boolean
  defaultAuditPoint?: AuditPoint
  disabled?: boolean
  auditId: Audit['id']
}

export function CreateDeviationButton({
  allowEditAuditPoint,
  defaultAuditPoint,
  auditId,
  disabled
}: Readonly<CreateDeviationButtonProps>) {
  const { show } = useDeviationModal()
  const { t } = useTranslation('audit')

  return (
    <TdsButton
      className='sdds-u-mb2 sdds-u-mt2'
      size='sm'
      onClick={() =>
        show({
          auditId,
          allowEditAuditPoint,
          defaultAuditPoint
        })
      }
      disabled={disabled}
      text={t('create-deviation')}
    />
  )
}
