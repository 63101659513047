import {
  CenteredContainer,
  ScrollableWrapper
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { getDocumentIdFromParams } from '@cdab/scania/qpr/loaders'
import {
  Roles,
  type AdminReferenceDocument,
  type DocumentFileData
} from '@cdab/scania/qpr/schema'
import {
  Block,
  Breadcrumbs,
  Column,
  Container,
  EmptyScreen,
  IconWarning,
  Row
} from '@cdab/scania/sdds'
import { capitalizeAllFirstLetters, capitalizeFirstLetter } from '@cdab/utils'
import { useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs } from 'react-router'
import {
  isRouteErrorResponse,
  json,
  useLoaderData,
  useRouteError
} from 'react-router'
import { Text, Title } from './documentId.styles'

export type ReadOnlyDocumentLoaderData = {
  documentData: AdminReferenceDocument
  documentFile: DocumentFileData | undefined
}

export async function loader({
  params
}: LoaderFunctionArgs): Promise<ReadOnlyDocumentLoaderData> {
  try {
    const documentId = getDocumentIdFromParams(params)
    const client = getClient()
    const userInfo = await client.GetuserInfo()
    if (!userInfo) {
      throw json({
        status: 401
      })
    }

    const user = await client.UserService.GetUser(userInfo.userId)
    if (user.role > Roles.Auditor) {
      throw json({
        status: 404
      })
    }

    const documentData = await client.DocumentService.GetDocument(documentId)

    const documentFile = documentData.documentFileId
      ? await client.StorageService.GetDocumentFile(documentData.documentFileId)
      : undefined

    return {
      documentData,
      documentFile
    }
  } catch (error) {
    if (error instanceof Response) {
      throw new Response(null, {
        status: error.status
      })
    } else {
      throw new Response(null, {
        status: 404
      })
    }
  }
}

export function DocumentIdPage() {
  const { t } = useTranslation(['documents', 'common'])
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { documentData, documentFile } =
    useLoaderData() as ReadOnlyDocumentLoaderData

  function truncateText(text: string | undefined, maxLength = 50) {
    if (text && text.length > maxLength) {
      return text.slice(0, maxLength) + '...'
    }
    return text
  }

  return (
    <ScrollableWrapper>
      <Container
        fullHeight
        fluid='normal'
        paddingOnColumns={isLg}
        paddingOnContainer={isLg}
      >
        <Row>
          <Column width={12} className='sdds-u-pt2'>
            <Breadcrumbs
              links={[
                {
                  text: capitalizeAllFirstLetters(
                    t('reference-documents', { ns: 'common' })
                  ),
                  to: `/documents`
                },
                { text: documentData.title, to: '' }
              ]}
            />
          </Column>
        </Row>

        <Row>
          <Column
            width={12}
            lg={6}
            padding={false}
            style={{ marginTop: '5rem' }}
            offset={{ lg: 3 }}
          >
            <Block
              color='on-grey'
              header={t('document-details', { ns: 'documents' })}
              divider={true}
            >
              <Title>{t('name', { ns: 'common' })}</Title>
              <Text>{documentData.title}</Text>

              <Title>
                {capitalizeFirstLetter(
                  t('audit-point', {
                    ns: 'common'
                  })
                )}
              </Title>

              <Text>
                {' '}
                {documentData.auditPoints && documentData.auditPoints.length > 0
                  ? documentData.auditPoints.map((point, index) => (
                      <Text key={index}>{point.auditPointNo}</Text>
                    ))
                  : '--'}
              </Text>

              <Title>
                {capitalizeFirstLetter(
                  t('checkpoint', {
                    ns: 'common'
                  })
                )}
              </Title>
              <Text>
                {' '}
                {documentData.checkPoints && documentData.checkPoints.length > 0
                  ? documentData.checkPoints.map((point, index) => (
                      <Text key={index}>{point.checkPointNo}</Text>
                    ))
                  : '--'}
              </Text>

              <Title>
                {capitalizeFirstLetter(t('market', { ns: 'common' }))}
              </Title>
              <Text>
                {documentData.market === null
                  ? 'Factory'
                  : documentData.market.marketName}
              </Text>

              <Title>{'URL'}</Title>
              <br />
              <a
                target='_blank'
                href={
                  (documentData.documentFileId === null
                    ? documentData.url
                    : documentFile?.url) ?? ''
                }
                rel='noreferrer'
              >
                {truncateText(
                  documentData.documentFileId === null
                    ? documentData.title
                    : documentFile?.fileName
                )}
              </a>
            </Block>
          </Column>
        </Row>
      </Container>
    </ScrollableWrapper>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    if (error.status === 403 || error.status === 401) {
      message = t('access-denied')
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
