import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export type AuditType = {
  auditTypeId: number
  auditTypeDescription: string
}

export interface AuditTypeJson {
  auditTypeId: number
  auditTypeDescription: string
}

export const AuditTypeSchema: JSONSchemaType<AuditTypeJson> = {
  type: 'object',

  properties: {
    auditTypeId: {
      type: 'number'
    },
    auditTypeDescription: {
      type: 'string'
    }
  },
  required: ['auditTypeId', 'auditTypeDescription']
}

const validateAuditType: ValidateFunction<AuditTypeJson> =
  ajv.compile(AuditTypeSchema)

export const isValidAuditType = (
  auditType: unknown
): auditType is AuditTypeJson => {
  return isValidThing<AuditTypeJson>(auditType, validateAuditType)
}

export function createAuditTypeFromAuditTypeJson(
  json: AuditTypeJson
): AuditType {
  return {
    auditTypeId: json.auditTypeId,
    auditTypeDescription: json.auditTypeDescription
  }
}
