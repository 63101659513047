import {
  ElasticContentContainer,
  NewDeviation
} from '@cdab/scania/qpr/components'
import type {
  AuditPoint,
  CreateDeviation,
  DeviationValidationSchemaOut
} from '@cdab/scania/qpr/schema'
import { Button, Column, Container, Row, Toast } from '@cdab/scania/sdds'
import { capitalizeFirstLetter, useDoOnce } from '@cdab/utils'
import { v4 as uuidv4 } from 'uuid'

import { deviationsController } from '@cdab/scania/qpr/offline/controllers'

import { useDeviation } from '@cdab/scania/qpr/contexts/deviation'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { observer } from 'mobx-react-lite'
import type { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  useLocation,
  useNavigate,
  useParams,
  useRouteLoaderData
} from 'react-router-dom'
import { toast } from 'react-toastify'
import invariant from 'tiny-invariant'
import type { AuditLoaderData } from '../../../auditId'

type Params = {
  auditId: string
  auditPointId: string
  deviationId: string
}

export const AuditDeviationPageNew = observer((): JSX.Element => {
  const { t } = useTranslation()
  const { audit } = useRouteLoaderData('audit') as AuditLoaderData
  const { isReadonly, auditPoints } = audit
  const params = useParams<Params>()
  const { auditId: sAuditId, auditPointId: sAuditPointId } = params
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')

  const auditId = Number.parseInt(sAuditId ?? 'NaN')
  invariant(auditId, 'Audit not found')

  const auditPointId = Number.parseInt(sAuditPointId ?? 'NaN')
  let auditPoint: AuditPoint | undefined = undefined
  if (!Number.isNaN(auditPointId)) {
    auditPoint = auditPoints.find(ap => ap.id === auditPointId)
    invariant(auditPoint, `Could not find audit point with id ${auditPointId}`)
  }

  const goToPreviousPage = () => {
    const previousPath = pathname.lastIndexOf('/')

    if (previousPath !== -1) {
      const updatedPath = pathname.substring(0, previousPath)
      navigate(updatedPath)
    }
  }

  const ctx = useDeviation()
  useDoOnce(() => {
    ctx.updateDeviation(undefined, auditPoint)
  })

  const CancelAndSaveButtons = ({
    onCancelClick,
    onSubmitClick
  }: {
    onCancelClick: () => void
    onSubmitClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  }) => {
    const { t } = useTranslation()
    return (
      <Row className='sdds-u-mb2 sdds-u-mt2'>
        <Column width={12} lg={7}>
          <Button
            type='secondary'
            text={capitalizeFirstLetter(t('cancel', { ns: 'common' }))}
            size='sm'
            onClick={onCancelClick}
          />
          <Button
            size='sm'
            className='sdds-u-float-right'
            onClick={onSubmitClick}
            text={capitalizeFirstLetter(t('create', { ns: 'common' }))}
          />
        </Column>
      </Row>
    )
  }

  const createDeviation = (
    data: DeviationValidationSchemaOut
  ): Promise<void> => {
    const clientGuid = uuidv4()
    const deviation: CreateDeviation = data.withActionPlan
      ? {
          auditId,
          clientGuid,
          auditPointNumber: data.auditPointNumber,
          auditPointId: data.auditPointId,
          deviationWithoutActions: false,
          deviation: data.deviation,
          responsible: data.assignee,
          proposedActions: data.action,
          expirationDate: data.due ?? null,
          approvalDate: data.completed ?? null,
          actionPlanId: -1
        }
      : {
          auditId,
          clientGuid,
          auditPointNumber: data.auditPointNumber,
          auditPointId: data.auditPointId,
          deviationWithoutActions: true,
          deviation: data.deviation,
          expirationDate: null,
          proposedActions: null,
          responsible: null,
          approvalDate: null,
          actionPlanId: -1
        }

    deviationsController.CreateDeviation(deviation)

    if (data.files) {
      deviationsController.AddFiles(
        deviation.auditId,
        deviation.clientGuid,
        data.files.map(({ file }) => file)
      )
    }
    return Promise.resolve()
  }

  async function saveDeviation(
    deviation: DeviationValidationSchemaOut | FieldValues
  ) {
    try {
      await createDeviation(deviation as DeviationValidationSchemaOut)

      toast(
        <Toast
          type='success'
          headline={capitalizeFirstLetter(t('deviation-created'))}
          subheadline={capitalizeFirstLetter(t('deviation-created-success'))}
        />
      )

      goToPreviousPage()
    } catch (error) {
      toast(
        <Toast
          type='error'
          headline={capitalizeFirstLetter(t('deviation-not-created'))}
          subheadline={capitalizeFirstLetter(t('deviation-not-created-error'))}
        />
      )
    }
  }

  return (
    <Container paddingOnContainer={isLg} fullHeight>
      <ElasticContentContainer
        scrollY
        overflowHidden
        header={
          isLg && (
            <CancelAndSaveButtons
              onCancelClick={goToPreviousPage}
              onSubmitClick={() => {
                ctx?.form?.handleSubmit(saveDeviation)()
              }}
            />
          )
        }
        footer={
          !isLg && (
            <CancelAndSaveButtons
              onCancelClick={goToPreviousPage}
              onSubmitClick={() => {
                ctx?.form?.handleSubmit(saveDeviation)()
              }}
            />
          )
        }
      >
        <NewDeviation
          isReadOnly={isReadonly}
          auditId={audit.id}
          defaultAuditPoint={auditPoint}
        />
      </ElasticContentContainer>
    </Container>
  )
})
