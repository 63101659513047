import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export type AuditPoint = {
  auditPointNo: string
  translationGuid: string
  id: number
  isMandatory: boolean
  note: string | null
  pledgeId: number
  purposeTranslationGuid: string
  score: boolean | null
  sortOrder: number
}

export type AuditPointNumber = Pick<AuditPoint, 'id' | 'auditPointNo'>
export type AuditPointNumberJson = { id: number; auditPointNo: string }

export const AuditPointNumberSchema: JSONSchemaType<AuditPointNumberJson> = {
  type: 'object',
  properties: {
    id: { type: 'integer' },
    auditPointNo: { type: 'string' }
  },
  required: ['id', 'auditPointNo']
}

export const AuditPointNumbersSchema: JSONSchemaType<AuditPointNumberJson[]> = {
  type: 'array',
  items: AuditPointNumberSchema
}

export const AuditPointSchema: JSONSchemaType<AuditPoint> = {
  type: 'object',

  properties: {
    auditPointNo: {
      type: 'string'
    },
    translationGuid: {
      type: 'string'
    },
    id: {
      type: 'integer'
    },
    isMandatory: {
      type: 'boolean'
    },
    note: {
      type: 'string',
      nullable: true
    },
    pledgeId: {
      type: 'integer'
    },
    purposeTranslationGuid: {
      type: 'string'
    },
    score: {
      type: 'boolean',
      nullable: true
    },
    sortOrder: {
      type: 'integer'
    }
  },

  required: [
    'auditPointNo',
    'translationGuid',
    'id',
    'isMandatory',
    'note',
    'pledgeId',
    'purposeTranslationGuid',
    'score',
    'sortOrder'
  ]
}

const validateAuditPoint: ValidateFunction<AuditPoint> =
  ajv.compile(AuditPointSchema)

export const isValidAuditPoint = (
  auditPoint: unknown
): auditPoint is AuditPoint =>
  isValidThing<AuditPoint>(auditPoint, validateAuditPoint)

const validateAuditPointNumber: ValidateFunction<AuditPointNumberJson> =
  ajv.compile(AuditPointNumberSchema)

export const isValidAuditPointNumber = (
  data: unknown
): data is AuditPointNumberJson =>
  isValidThing<AuditPointNumberJson>(data, validateAuditPointNumber)

const validateAuditPointNumbers: ValidateFunction<AuditPointNumberJson[]> =
  ajv.compile(AuditPointNumbersSchema)

export const isValidAuditPointNumbers = (
  data: unknown
): data is AuditPointNumberJson[] =>
  isValidThing<AuditPointNumberJson[]>(data, validateAuditPointNumbers)
