import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { CreateDocumentData } from '@cdab/scania/qpr/interactor'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export type CreateDocumentState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'loading'
      message: null
    }
  | {
      status: 'none'
      message: null
    }

export function useCreateDocument(data: CreateDocumentData) {
  const { t } = useTranslation('documents')
  const [submitState, setSubmitState] = useState<CreateDocumentState>({
    status: 'none',
    message: null
  })
  const client = useBackend()

  const handleClickCreateDocument = async () => {
    setSubmitState({
      status: 'loading',
      message: null
    })

    try {
      await client.DocumentService.CreateDocument({
        ...data
      })
      setSubmitState({
        status: 'success',
        message: t('message.success-create')
      })
    } catch (e) {
      setSubmitState({
        status: 'error',
        message: t('message.error-create')
      })
    }
  }
  return [submitState, handleClickCreateDocument, setSubmitState] as const
}
