import { PROVIDER_FIREBASE } from './constants'
import type { ProviderBase } from './provider-base'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FirebaseParams {
  // TODO: Implement
}

export interface FirebaseProvider extends FirebaseParams, ProviderBase {
  type: typeof PROVIDER_FIREBASE
}

export function getFirebaseProvider(): FirebaseProvider {
  return {
    type: PROVIDER_FIREBASE
  }
}
