import styled from 'styled-components'
import type { ClickableListProps } from './clickable-list-item.types'
import { StyledRightActionButton } from './right-action-button'

export const ClickableList = styled.ul<ClickableListProps>`
  list-style: none;
  padding: 0;
  overflow-y: ${({ scrollable }) => (scrollable ? 'scroll' : 'inherit')};
`

export const StyledItem = styled.li<{
  backgroundColor?: string
}>`
  display: flex;
  flex-direction: row;
  flex: 1;

  padding-right: var(--sdds-spacing-element-24);
  border-bottom: 1px solid var(--sdds-grey-100);
  transition: background-color 0.1s ease-in-out;

  ${StyledRightActionButton} {
    transition: background-color 0.1s ease-in-out;
  }
  @media (min-width: ${({ theme }) => theme.size.lg}) {
    ${StyledRightActionButton} {
      display: none;
    }

    &:hover {
      ${StyledRightActionButton} {
        display: flex;
      }
    }
  }

  &:hover {
    background-color: var(--sdds-grey-100);
    cursor: pointer;
  }

  a {
    color: initial;
    display: flex;
    flex: 1;
    text-decoration: initial;
  }

  background-color: ${({ backgroundColor }) =>
    backgroundColor && backgroundColor};
`

export const Title = styled.span.attrs({
  className: 'sdds-detail-01'
})`
  opacity: 0.87;
`

export const Description = styled.span.attrs({
  className: 'sdds-detail-02'
})`
  margin-top: var(--sdds-spacing-elebment-4);
  opacity: 0.6;
`

export const InnerComponent = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

export const LeftItem = styled.div`
  align-self: center;
  margin-left: var(--sdds-spacing-element-16);
  margin-top: var(--sdds-spacing-element-8);
  margin-bottom: var(--sdds-spacing-element-8);

  height: auto;
  width: auto;
  display: flex;
  place-content: flex-start;
`

export const RightItem = styled.div`
  align-self: center;
  margin-top: var(--sdds-spacing-element-8);
  margin-bottom: var(--sdds-spacing-element-8);
  margin-left: var(--sdds-spacing-element-4);

  height: auto;
  width: auto;
  display: flex;
  place-content: flex-end;
`

export const Thumbnail = styled.img`
  height: 16rem;
  width: 16rem;
  object-fit: cover;
  overflow: hidden;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--sdds-spacing-element-8) 0 var(--sdds-spacing-element-8)
    var(--sdds-spacing-element-16);
`
