import styled from 'styled-components'
import {
  ActionWrapper,
  Description,
  IconWrapper,
  TextWrapper,
  Title,
  Wrapper
} from './empty-screen.styles'
import type { EmptyScreenProps } from './empty-screen.types'

const CenteredWrapper = styled.div`
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  display: flex;
`

export const EmptyScreen = (props: EmptyScreenProps) => {
  const { title, description, icon, callToAction } = props

  return (
    <Wrapper>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <TextWrapper>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}

        {callToAction && (
          <ActionWrapper type={callToAction.type}>
            {callToAction.component}
          </ActionWrapper>
        )}
      </TextWrapper>
    </Wrapper>
  )
}

EmptyScreen.CenteredWrapper = CenteredWrapper
