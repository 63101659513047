import styled from 'styled-components'

export const Title = styled.h4.attrs({
  className: 'sdds-headline-05'
})`
  ::first-letter {
    text-transform: capitalize;
  }

  margin-top: 0;
  margin-bottom: var(--sdds-spacing-layout-8);
  margin-left: var(--sdds-spacing-element-16);

  @media (min-width: ${({ theme }) => theme.size.lg}) {
    margin-left: 0;
  }
`
