import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { CreateDocumentData } from '@cdab/scania/qpr/interactor'
import type { DocumentFile } from '@cdab/scania/qpr/schema'
import { action } from 'mobx'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export type CreateDocumentFileState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'uploading'
      progress: number
    }
  | {
      status: 'none'
      message: null
    }

export function useCreateDocumentWithFile(
  data: CreateDocumentData,
  documentFile: DocumentFile | undefined
) {
  const { t } = useTranslation('documents')
  const [submitState, setSubmitState] = useState<CreateDocumentFileState>({
    status: 'none',
    message: null
  })
  const client = useBackend()

  const handleClickUploadAndCreateDocument = async () => {
    if (!documentFile) return

    setSubmitState({
      status: 'uploading',
      progress: 0
    })

    const uploadPromise = client.StorageService.UploadDocumentFile(
      documentFile.file,
      action(progressEvent => {
        if (typeof progressEvent.progress === 'undefined') return
        setSubmitState({
          status: 'uploading',
          progress: progressEvent.progress
        })
      })
    )

    const createPromise = client.DocumentService.CreateDocument({
      ...data,
      documentFileId: await uploadPromise
    })

    const [uploadResult, createResult] = await Promise.allSettled([
      uploadPromise,
      createPromise
    ])

    if (uploadResult.status === 'rejected') {
      setSubmitState({
        status: 'error',
        message: t('message.error-upload')
      })
      return
    }

    if (
      createResult.status === 'rejected' &&
      uploadResult.status === 'fulfilled'
    ) {
      await client.StorageService.DeleteDocumentFile(uploadResult.value)
      setSubmitState({
        status: 'error',
        message: t('message.error-create')
      })
      return
    }

    setSubmitState({ status: 'success', message: t('message.success-create') })
  }

  return [
    submitState,
    handleClickUploadAndCreateDocument,
    setSubmitState
  ] as const
}
