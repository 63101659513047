import { v4 as uuidv4 } from 'uuid'
import { action } from 'mobx'
import invariant from 'tiny-invariant'

import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { FileData } from '@cdab/scania/qpr/schema'

import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import { deleteAuditPointFile, addAuditPointFiles } from '../store/audit-points'
import type { AuditPointModel, AuditModel } from '../audit-model'

type Type = 'delete-audit-point-file'

type Data = {
  auditPointId: AuditPointModel['id']
  fileId: FileData['id']
}

export type DeleteAuditPointFileOperationData = OperationDataBase<Type, Data>

export class DeleteAuditPointFileOperation extends Operation {
  public data: DeleteAuditPointFileOperationData
  private deletedFile: FileData | undefined = undefined

  constructor(auditId: AuditModel['id'], value: Data, guid = uuidv4()) {
    super(auditId, guid)

    this.data = {
      type: 'delete-audit-point-file',
      value
    }
  }

  public ApplyTo = action((audit: AuditModel): void => {
    const { auditPointId, fileId } = this.data.value
    const deletedFile = deleteAuditPointFile(audit, auditPointId, fileId)

    this.deletedFile = deletedFile
  })

  public GetName = (): string => {
    return `${this.data.type}-${this.auditId}-${this.data.value.auditPointId}-${this.data.value.fileId}`
  }

  public SendOperation = async (client: BackEnd): Promise<boolean> => {
    await client.StorageService.DeleteFileForAuditPoint(
      this.auditId,
      this.data.value.auditPointId,
      this.data.value.fileId
    )

    return true
  }
  public RollbackOn(audit: AuditModel): void {
    invariant(
      this.deletedFile,
      `Cannot rollback operation without having applied it first`
    )
    invariant(
      this.deletedFile.id === this.data.value.fileId,
      `The saved deleted file was not the same as data.value.fileId!`
    )

    addAuditPointFiles(audit.id, this.data.value.auditPointId, [
      this.deletedFile
    ])
  }
}
