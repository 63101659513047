import type { AxiosInstance } from 'axios'

import type { RemindersService as RemindersServiceDefinition } from '@cdab/scania/qpr/interactor'
import { axiosErrorToError } from '@cdab/utils'

import type { Configuration } from './generated-swagger-client'
import { RemindersApi } from './generated-swagger-client'

export class RemindersService implements RemindersServiceDefinition {
  private readonly remindersApi: RemindersApi

  public constructor(
    configuration: Configuration,
    axiosInstance: AxiosInstance
  ) {
    this.remindersApi = new RemindersApi(
      configuration,
      configuration.basePath,
      axiosInstance
    )
  }

  public SendAuditReminderForUser = async (
    auditId: number
  ): Promise<string> => {
    try {
      const response = await this.remindersApi.apiRemindersAuditAuditIdGet(
        auditId
      )

      return response.data
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public SendActionPlanReminderForUser = async (
    auditId: number
  ): Promise<string> => {
    try {
      const response = await this.remindersApi.apiRemindersActionPlanAuditIdGet(
        auditId
      )

      return response.data
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }
}
