export enum SideMenuItemType {
  link,
  submenu
}
export interface SideMenuItemProps {
  title: string
  callbackFn?: () => void
  icon?: JSX.Element
  to: string
  external?: boolean
  openInNewTab?: boolean
  type: SideMenuItemType
  children?: React.ReactNode
}

export interface SideMenuSubItemProps {
  title: string
  to: string
  external?: boolean
  openInNewTab?: boolean
}
