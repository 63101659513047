import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { Audit } from '@cdab/scania/qpr/schema'

export type AuditReminderState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'loading'
      message: null
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

function useSendAuditReminder() {
  const client = useBackend()

  const sendAuditReminder = async (auditId: Audit['id']): Promise<string> => {
    try {
      const response = await client.RemindersService.SendAuditReminderForUser(
        auditId
      )

      return response
    } catch (e) {
      return 'fail'
    }
  }

  return {
    sendAuditReminder
  }
}

export function useAuditReminder(auditId: number) {
  const { t } = useTranslation('audit')

  const { sendAuditReminder } = useSendAuditReminder()
  const [stateAuditReminder, setStateAuditReminder] =
    useState<AuditReminderState>({
      status: 'none',
      message: null
    })

  const handleClickAuditReminder = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      setStateAuditReminder({ status: 'loading', message: null })
      const response = await sendAuditReminder(auditId)

      if (response === 'success') {
        setStateAuditReminder({ status: 'success', message: response })
      } else if (response === 'fail') {
        setStateAuditReminder({
          status: 'error',
          message: t('reminders.audit-reminder-fail')
        })
      } else {
        setStateAuditReminder({ status: 'error', message: response })
      }
    },
    [auditId, sendAuditReminder, t]
  )

  return [stateAuditReminder, handleClickAuditReminder] as const
}
