import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import type { MarketsService as MarketsServiceDefinition } from '@cdab/scania/qpr/interactor'
import type { Area, Market, User } from '@cdab/scania/qpr/schema'
import { isValidArea, isValidMarket } from '@cdab/scania/qpr/schema'
import { axiosErrorToError } from '@cdab/utils'

import type { Configuration } from './generated-swagger-client'
import { MarketsApi } from './generated-swagger-client'

export class MarketsService implements MarketsServiceDefinition {
  private readonly marketsApi: MarketsApi

  public constructor(
    configuration: Configuration,
    axiosInstance: AxiosInstance
  ) {
    this.marketsApi = new MarketsApi(
      configuration,
      configuration.basePath,
      axiosInstance
    )
  }
  public GetSisImportJobStatus = async (
    sisImportJobId: number
  ): Promise<string> => {
    const response =
      await this.marketsApi.apiMarketsSisImportJobSisImportJobIdGet(
        sisImportJobId
      )

    return response.data
  }

  public SyncDealersForMarket = async (
    marketId: number,
    options?: AxiosRequestConfig
  ): Promise<number> => {
    try {
      const response = await this.marketsApi.apiMarketsMarketIdSisImportJobPost(
        marketId,
        options
      )

      return response.data
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetMarketsForUser = async (
    userId: User['userId'],
    options?: AxiosRequestConfig
  ): Promise<Market[]> => {
    try {
      const response = await this.marketsApi.apiMarketsUserIdGet(
        userId,
        options
      )

      const markets: Market[] = []

      for (const market of response.data.data.markets ?? []) {
        if (isValidMarket(market)) {
          markets.push(market)
        }
      }

      return markets.sort((a: Market, b: Market) =>
        a.marketName.localeCompare(b.marketName)
      )
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetAllMarkets = async (
    options?: AxiosRequestConfig
  ): Promise<Market[]> => {
    try {
      const response = await this.marketsApi.apiMarketsGet(options)

      const markets: Market[] = []

      for (const market of response.data.data.markets ?? []) {
        if (isValidMarket(market)) {
          markets.push(market)
        }
      }

      return markets.sort((a: Market, b: Market) =>
        a.marketName.localeCompare(b.marketName)
      )
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetAllAreas = async (
    options?: AxiosRequestConfig
  ): Promise<Area[]> => {
    try {
      const response = await this.marketsApi.apiMarketsAreasGet(options)

      const areas: Area[] = []

      for (const area of response.data.data.areas ?? []) {
        if (isValidArea(area)) {
          areas.push(area)
        }
      }

      return areas.sort((a: Area, b: Area) => a.name.localeCompare(b.name))
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }
}
