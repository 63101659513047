import { cloneElement } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Button } from '../button'
import type { ButtonProps } from '../button/button.types'
import type { Props } from './modal.types'

interface DismissButtonProps
  extends Pick<ButtonProps, 'type' | 'disabled' | 'size' | 'text'> {
  /**
   * Close modal when clicking the button
   */
  closeModal?: boolean
}

export const FooterButton = ({
  closeModal = true,
  ...props
}: DismissButtonProps) => {
  const attr = closeModal ? { 'data-dismiss-modal': '' } : ''

  return <Button slot='sdds-modal-actions' {...attr} {...props} />
}

export function Modal({
  buttons,
  children,
  size = 'md',
  description,
  headline,
  preventCloseOnOverlayClick = false, // TODO: Add test
  selector = 'modal',
  trigger
}: Props) {
  const attr = preventCloseOnOverlayClick ? { prevent: '' } : ''

  return (
    <>
      {typeof trigger === 'string' ? (
        <Button id={selector} text={trigger} />
      ) : (
        cloneElement(trigger, { id: selector })
      )}

      <sdds-modal size={size} selector={`#${selector}`} role='dialog' {...attr}>
        <h5 slot='sdds-modal-headline'>{headline}</h5>

        <div role='document' slot='sdds-modal-body'>
          <div>{description}</div>
          <div>{children}</div>
        </div>

        {buttons &&
          buttons.map(button => cloneElement(button, { key: uuidv4() }))}
      </sdds-modal>
    </>
  )
}

Modal.FooterButton = FooterButton
