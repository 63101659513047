import type { PledgePerspective } from '@cdab/scania/qpr/schema'

export const PledgeSpectives: PledgePerspective[] = [
  {
    id: 1,
    perspectiveType: 1,
    pledgeId: 1,
    translationGuid: 'PledgeSpective_1'
  },
  {
    id: 2,
    perspectiveType: 1,
    pledgeId: 1,
    translationGuid: 'PledgeSpective_2'
  },
  {
    id: 3,
    perspectiveType: 1,
    pledgeId: 1,
    translationGuid: 'PledgeSpective_3'
  },
  {
    id: 4,
    perspectiveType: 1,
    pledgeId: 1,
    translationGuid: 'PledgeSpective_4'
  }
]
