import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'

export type UpdateTranslationResponse = 'success' | 'fail'

export function useUpdateTranslation() {
  const client = useBackend()

  const updateTranslation = async (
    translationId: number,
    translationText: string
  ): Promise<UpdateTranslationResponse> => {
    try {
      await client.TranslationsService.UpdateTranslation(
        translationId,
        translationText
      )
    } catch (e) {
      return 'fail'
    }

    return 'success'
  }

  return {
    updateTranslation
  }
}
