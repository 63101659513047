import styled from 'styled-components'

const StyledMenuButton = styled.div`
  padding: 2rem;
  margin-right: 2rem;
  cursor: pointer;
  height: 5rem;
  width: 5rem;
  box-sizing: content-box;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 1rem;

  transition: background 200ms;
  &:hover,
  &:active {
    background: var(--sdds-grey-300);
  }
`

const MenuIcon = () => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.03296 3.98828C7.03296 4.54057 7.48278 4.98828 8.03767 4.98828C8.59255 4.98828 9.04238 4.54057 9.04238 3.98828C9.04238 3.436 8.59255 2.98828 8.03767 2.98828C7.48278 2.98828 7.03296 3.436 7.03296 3.98828Z'
        fill='#0D0F13'
      ></path>
      <path
        d='M7.03296 8C7.03296 8.55228 7.48278 9 8.03767 9C8.59255 9 9.04238 8.55228 9.04238 8C9.04238 7.44772 8.59255 7 8.03767 7C7.48278 7 7.03296 7.44772 7.03296 8Z'
        fill='#0D0F13'
      ></path>
      <path
        d='M7.03296 12.0146C7.03296 12.5669 7.48278 13.0146 8.03767 13.0146C8.59255 13.0146 9.04238 12.5669 9.04238 12.0146C9.04238 11.4624 8.59255 11.0146 8.03767 11.0146C7.48278 11.0146 7.03296 11.4624 7.03296 12.0146Z'
        fill='#0D0F13'
      ></path>
    </svg>
  )
}

export function AuditMenuButton() {
  return (
    <StyledMenuButton>
      <MenuIcon />
    </StyledMenuButton>
  )
}
