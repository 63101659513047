import type { Deviation, ExpiringDeviation } from '@cdab/scania/qpr/schema'
import type { DeviationsService as IDeviationsService } from '@cdab/scania/qpr/interactor'
import { MockDeviations } from '@cdab/scania/qpr/mockdata'

import { wait } from '@cdab/utils'

const mockDeviationsData: Deviation[] = MockDeviations

export class DeviationsService implements IDeviationsService {
  private readonly mockDelayMS: number

  public constructor(mockDelayMS: number) {
    this.mockDelayMS = mockDelayMS
  }

  /**
   * Get a single deviation
   *
   * @param deviationId - Deviation ID
   * @returns Deviation object
   * @throws Error if deviationId is not found
   */
  public GetDeviation = async (deviationId: number): Promise<Deviation> => {
    await wait(this.mockDelayMS)

    const deviation = mockDeviationsData.find(
      deviation => deviation.id === deviationId
    )

    if (!deviation) throw new Error('No deviation found')

    return deviation
  }

  /**
   * Get deviations for an audit
   *
   * @param auditId - The audit id
   * @returns Deviations for the audit
   * @throws Error if no deviations found
   */
  public GetDeviations = async (auditId: number): Promise<Deviation[]> => {
    await wait(this.mockDelayMS)

    const deviations = mockDeviationsData.filter(
      deviation => deviation.auditId === auditId
    )

    if (!deviations.length) throw new Error('No deviations found')

    return deviations
  }

  public GetExpiringDeviationsForUser = async (
    includeTestMarket?: boolean
  ): Promise<ExpiringDeviation[]> => {
    if (includeTestMarket) return []

    throw new Error('Method not implemented.')
  }
}
