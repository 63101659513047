import type {
  Dealer,
  CertificationStatus,
  FavoriteAudit
} from '@cdab/scania/qpr/schema'
import { observable } from 'mobx'
import type { AuditModel } from '../audit-model'
import type { FavoriteAuditModel } from '../favorite-audit-model'

type AuditsStore = {
  [auditId: AuditModel['id']]: AuditModel | undefined
}

export const auditsStore = observable<AuditsStore>({}, undefined, {
  deep: true
})

// TODO: Add migrations à la audit model
export type DealerModel = {
  VERSION: 1
  auditDate: Date | null
  bus: boolean
  busSales: boolean | null
  city: string
  certificationStatus: CertificationStatus
  dealerId: string
  engine: boolean
  engineSales: boolean | null
  expiryDate: Date | null
  hasDeviations: boolean
  id: number
  name: string
  partsSales: boolean | null
  truck: boolean
  truckSales: boolean | null
  marketId: number
  marketName: string
}

type DealersStore = {
  [dealerId: Dealer['id']]: DealerModel | undefined
}

export const dealersStore = observable<DealersStore>({})

type FavoriteAuditsStore = {
  [auditId: FavoriteAudit['auditId']]: FavoriteAuditModel | undefined
}

export const favoriteAuditsStore = observable<FavoriteAuditsStore>({})
export const favoriteAuditsChangesStore = observable<FavoriteAuditsStore>({})
