import { z } from 'zod'
import type { JSONSchemaType } from 'ajv'
import { ajv, isValidThing } from './ajv'

export const DocumentFileDataSchema = z.object({
  id: z.string(),
  url: z.string(),
  fileName: z.string(),
  isUploaded: z.boolean(),
  uploadProgress: z.number()
})

export type DocumentFileData = z.infer<typeof DocumentFileDataSchema>

export interface DocumentFileDataJson {
  id: number
  url: string
  fileName: string
}

export const DocumentFileDataJsonSchema: JSONSchemaType<DocumentFileDataJson> =
  {
    type: 'object',
    properties: {
      id: { type: 'number' },
      url: { type: 'string' },
      fileName: { type: 'string' }
    },
    required: ['id', 'url', 'fileName']
  }

const validateDocumentFileData = ajv.compile(DocumentFileDataJsonSchema)

export const isValidDocumentFileData = (
  data: unknown
): data is DocumentFileDataJson =>
  isValidThing<DocumentFileDataJson>(data, validateDocumentFileData)

export function documentFileDataJsonToFileData(
  documentFileDataJson: DocumentFileDataJson
): DocumentFileData {
  const { id, ...jsonData } = documentFileDataJson

  return {
    id: id.toString(),
    isUploaded: true, // Every file we get from json (i.e. externally) is already uploaded
    uploadProgress: 1,
    ...jsonData
  }
}
