import type { DeviationModel } from '@cdab/scania/qpr/offline/models'
import type { AuditPoint } from '@cdab/scania/qpr/schema'
import { NewDeviationModal } from './new-deviation-modal'
import { UpdateDeviationModal } from './update-deviation-modal'

export function DeviationDialogWrapper(props: {
  onClose: () => void
  onDelete: () => void
  open: boolean
  deviation?: DeviationModel
  defaultAuditPoint?: AuditPoint
  allowEditAuditPoint: boolean
  auditId: number
}) {
  const { onClose, deviation } = props

  if (!deviation)
    return (
      <NewDeviationModal
        onClose={props.onClose}
        open={props.open}
        allowEditAuditPoint={props.allowEditAuditPoint}
        auditId={props.auditId}
        defaultAuditPoint={props.defaultAuditPoint}
      />
    )

  return (
    <UpdateDeviationModal
      onClose={onClose}
      onDelete={props.onDelete}
      open={props.open}
      allowEditAuditPoint={props.allowEditAuditPoint}
      auditId={props.auditId}
      deviation={deviation}
      defaultAuditPoint={props.defaultAuditPoint}
    />
  )
}
