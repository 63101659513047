import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import type { AuditBottomNavigationStyleProps } from './audit-bottom-navigation.styles.types'
import { getIconSize, IconChevronRight } from '@cdab/scania/sdds'

export const BOTTOM_NAVIGATION_HEIGHT = '70px'
const TRANSITION_DURATION = '250ms'

export const VariableProvider = styled.div`
  --safe-inset-bottom: env(safe-area-inset-bottom);
  --bottom-navigation-height: calc(
    ${BOTTOM_NAVIGATION_HEIGHT} + var(--safe-inset-bottom)
  );
`

export const BottomSticky = styled.div<AuditBottomNavigationStyleProps>`
  position: absolute;
  background-color: var(--sdds-blue-700);
  z-index: 9999;

  transition-timing-function: cubic-bezier(0.1, 0.7, 0.6, 0.9);
  transition-property: all;
  transition-duration: ${TRANSITION_DURATION};

  height: calc(100% - var(--bottom-navigation-height));
  width: 100%;

  left: 0;
  top: 0;
  transform: translateY(100%);

  display: flex;
  flex-direction: column;

  ${({ isOpen }) =>
    isOpen &&
    css`
      box-shadow: rgb(0 0 0 / 30%) 0 0 10px;
      transform: translateY(0);
    `};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
`

export const IconButton = styled.button.attrs({
  className: 'sdds-btn sdds-btn-secondary sdds-btn-icon'
})`
  svg {
    height: ${getIconSize('medium')};
    width: ${getIconSize('medium')};
  }

  &&& {
    display: flex;
    align-items: center;
    height: 100%;
    padding: var(--sdds-spacing-element-8);
    color: var(--sdds-white);
    border-color: var(--sdds-grey-500);
  }
`

export const Navigation = styled.div`
  z-index: 9999;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--sdds-blue-700);
  display: flex;
  flex-direction: row;
  padding: var(--sdds-spacing-layout-16);
  padding-bottom: calc(
    var(--sdds-spacing-layout-16) + var(--safe-inset-bottom)
  );
  height: var(--bottom-navigation-height);

  > div {
    display: flex;
    flex: 1;
    justify-content: center;

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
`

export const Dropshadow = styled.div<AuditBottomNavigationStyleProps>`
  visibility: ${({ isOpen }) => (isOpen ? 'isOpen' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '0.6' : '0')};
  transition: ${TRANSITION_DURATION};
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.1, 0.7, 0.6, 0.9);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 9999;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
`

export const SummaryLinkItem = styled.div`
  background: var(--sdds-blue-600);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: var(--sdds-spacing-element-16) var(--sdds-spacing-element-8);

  &:active {
    background: var(--sdds-btn-primary-background-active);
  }
`
export const SummaryLinkItemLeft = styled.div`
  display: flex;
  flex: 1;
  width: var(--sdds-spacing-element-16);
`
export const SummaryLinkItemCenter = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`
export const SummaryLinkItemRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const StyledIconChecvronRight = styled(IconChevronRight)`
  width: ${getIconSize('medium')};
  height: ${getIconSize('medium')};
`
