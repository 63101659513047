import { useUser } from '@cdab/scania/qpr/contexts/user-provider'
import {
  auditsController,
  dealersController
} from '@cdab/scania/qpr/offline/controllers'
import type { Audit, Dealer } from '@cdab/scania/qpr/schema'
import { EditRights } from '@cdab/scania/qpr/schema'
import { useMachine } from '@xstate/react'
import { useCallback, useEffect } from 'react'
import type { DoneInvokeEvent } from 'xstate'
import { assign, createMachine } from 'xstate'

type CertifyContext = {
  auditId: Audit['id']
  dealerId: Dealer['id']
  enableCertification: boolean
  result?: string
  error?: string
}

const certifyAuditMachine = createMachine(
  {
    predictableActionArguments: true,
    schema: {
      context: {} as CertifyContext,
      services: {} as {
        certifyAudit: { data: string }
      }
    },
    initial: 'idle',
    states: {
      idle: {
        always: [{ target: 'enabled', cond: 'canCertify' }]
      },
      disabled: {
        on: {
          ENABLE: { target: 'enabled' }
        }
      },
      enabled: {
        on: {
          CERTIFY: { target: 'loading' }
        }
      },
      loading: {
        invoke: {
          id: 'certifyAudit',
          src: 'certifyAudit',
          onDone: [
            {
              target: 'success',
              actions: assign<CertifyContext, DoneInvokeEvent<string>>({
                result: (ctx, ev) => ev.data
              }),
              cond: 'certificationSuccessful'
            },
            {
              target: 'error',
              actions: assign<CertifyContext, DoneInvokeEvent<string>>({
                error: (ctx, ev) => ev.data
              })
            }
          ],
          onError: {
            target: 'error',
            actions: assign<CertifyContext, DoneInvokeEvent<string>>({
              error: (ctx, ev) => ev.data
            })
          }
        }
      },
      success: {
        exit: ['getDealer', 'getAudit'],
        on: {
          DISABLE: { target: 'disabled' }
        }
      },
      error: {
        on: {
          CERTIFY: { target: 'loading' }
        }
      }
    }
  },
  {
    actions: {
      getDealer: ctx => dealersController.GetDealer(ctx.dealerId),
      getAudit: ctx =>
        auditsController.GetAudit(ctx.auditId, { forceLoad: true })
    },
    services: {
      certifyAudit: ctx => {
        return auditsController.CertifyAudit(ctx.auditId)
      }
    },
    guards: {
      certificationSuccessful: (ctx, event) => event['data'] === 'NoError',
      canCertify: ctx => ctx.enableCertification
    }
  }
)

function isAllowedToCertify(editRights: EditRights) {
  // We probably shouldn't compare >= EditAndCertify, since then we cannot
  // add a new edit right which is not allowed to certify without introducing
  // a bug here.
  // IMHO, it's better to restrict certification instead of accidentally enablig
  // certification for someone who shouldn't be allowed to.
  return (
    editRights === EditRights.EditAndCertify ||
    editRights === EditRights.EditCertifyAndRemote
  )
}

export function useCertifyAudit(
  auditId: number,
  canCertify: boolean,
  dealerId: number
) {
  const { userData } = useUser()
  const allowedToCertify = isAllowedToCertify(userData.editRights)
  const [stateCertify, sendCertify] = useMachine(certifyAuditMachine, {
    context: { auditId, dealerId, enableCertification: canCertify }
  })

  const handleClickCertify = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      sendCertify('CERTIFY')
    },
    [sendCertify]
  )

  const disableCertify = useCallback(() => {
    sendCertify('DISABLE')
  }, [sendCertify])

  useEffect(() => {
    if (canCertify) sendCertify({ type: 'ENABLE' })
  }, [canCertify, sendCertify])

  return [
    stateCertify,
    allowedToCertify,
    handleClickCertify,
    disableCertify
  ] as const
}
