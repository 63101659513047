import type { FunctionComponent, PropsWithChildren, ReactElement } from 'react'
import { Children, cloneElement, useState } from 'react'

import {
  Label,
  RadioButtonItem,
  StyledRadioButton,
  StyledRadioButtonGroup
} from './radio-button.styles'
import type {
  InternalItemProps,
  ItemProps,
  RadioButtonProps,
  RadioComposition
} from './radio-button.types'
import { ERROR_BAD_CHILD_ELEMENT } from './radio-button.constants'

function Item({
  className,
  disabled,
  id,
  label,
  onChange,
  checked,
  name,
  value
}: InternalItemProps) {
  const [myID] = useState(id || Math.random().toString())

  return (
    <RadioButtonItem className={className}>
      <StyledRadioButton
        checked={checked}
        name={name}
        id={myID}
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
      <Label htmlFor={myID}>{label}</Label>
    </RadioButtonItem>
  )
}

const RadioButton: FunctionComponent<RadioButtonProps> & RadioComposition = ({
  children,
  direction = 'vertical',
  name,
  defaultSelected,
  value,
  onChange
}) => {
  const [selectedValue, setSelectedValue] = useState<
    string | number | undefined
  >(defaultSelected)

  return (
    <StyledRadioButtonGroup direction={direction}>
      {Children.map(children, child => {
        const item = child as ReactElement<PropsWithChildren<InternalItemProps>>

        const isChecked =
          item.props.value === value || item.props.value === selectedValue

        const handleChange = () => {
          if (typeof value === 'undefined') {
            setSelectedValue(item.props.value)
          }
          if (onChange) {
            onChange(item.props.value)
          }
        }

        if (item.type === Item) {
          return cloneElement(item, {
            checked: isChecked,
            onChange: handleChange,
            name
          })
        } else {
          throw new Error(ERROR_BAD_CHILD_ELEMENT)
        }
      })}
    </StyledRadioButtonGroup>
  )
}

RadioButton.Item = Item as FunctionComponent<ItemProps>

export { RadioButton }
