import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import { downloadFileFromArrayBuffer } from '@cdab/utils'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { hasStatus } from './utils'

export type GetAreaReportState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'loading'
      message: null
    }
  | {
      status: 'none'
      message: null
    }

export function useGetAreaReport(areaIds: number[]) {
  const { t } = useTranslation('reports')
  const [submitState, setSubmitState] = useState<GetAreaReportState>({
    status: 'none',
    message: null
  })
  const client = useBackend()

  const handleClickGetAreaReport = useCallback(async () => {
    setSubmitState({
      status: 'loading',
      message: null
    })

    try {
      const response = await client.ReportsService.GetAreaReport(areaIds)
      const downloadFile = downloadFileFromArrayBuffer(
        response.data,
        response.headers['content-type'] ?? '',
        `AreaReport.xlsx`
      )

      if (response.status === 204 || !downloadFile) {
        setSubmitState({
          status: 'error',
          message: t('message.empty-report')
        })
        return
      }

      setSubmitState({
        status: 'success',
        message: t('message.success')
      })
    } catch (e) {
      if (hasStatus(e)) {
        if (e.status === 400) {
          setSubmitState({
            status: 'error',
            message: t('message.no-dealer-flags')
          })
          return
        }
      }

      setSubmitState({
        status: 'error',
        message: t('message.error')
      })
    }
  }, [areaIds, client.ReportsService, t])
  return [submitState, handleClickGetAreaReport, setSubmitState] as const
}
