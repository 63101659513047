type File = { url: string }

export function freeMemory(data?: File | File[]) {
  if (!data) return

  if (!Array.isArray(data)) {
    URL.revokeObjectURL(data.url)
    return
  }

  data.forEach(({ url }) => {
    URL.revokeObjectURL(url)
  })
}
