import { AccordionItem } from '@cdab/scania/sdds'
import type { AuditGridItemProps } from '../../types'

/**
 * This is an unfinished implementation.
 * See git history for inspiration.
 */
export function AuditGridItemCollapsible({
  header,
  children,
  className
}: AuditGridItemProps): JSX.Element {
  // const onClick = useCallback(
  //   (open: boolean) => (): void => {
  //     if (onHeaderClick && !open) onHeaderClick(id) // only trigger header click when closed
  //   },
  //   [id, onHeaderClick]
  // )

  return (
    <AccordionItem header={header} className={className} affix='prefix'>
      <div>{children}</div>
    </AccordionItem>
  )
}
