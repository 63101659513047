import { useEffect } from 'react'
import { useRouteError } from 'react-router'
import { useTranslation } from 'react-i18next'
import * as sentry from '@sentry/react'

import { RootErrorBoundary } from '@cdab/scania/qpr/components'

export function RootBoundary() {
  const { t } = useTranslation('errors')
  const error = useRouteError()

  useEffect(() => {
    console.error('the app crashed...', error)
    sentry.captureException(error)
  }, [error])

  return (
    <RootErrorBoundary
      title={t('something-bad')}
      description={t('something-bad-description')}
    />
  )
}
