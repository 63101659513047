import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { CreateUserData } from '@cdab/scania/qpr/interactor'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type CreateUserState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

export function useSendCreateUser() {
  const client = useBackend()

  const createUser = async (data: CreateUserData): Promise<string> => {
    const response = await client.UserService.CreateUser(data)

    return response
  }

  return {
    createUser
  }
}

export function useCreateUser(data: CreateUserData) {
  const { t } = useTranslation('user')
  const { createUser } = useSendCreateUser()
  const [submitState, setSubmitState] = useState<CreateUserState>({
    status: 'none',
    message: null
  })

  const handleClickUpdateUser = useCallback(async () => {
    setSubmitState({ status: 'none', message: null })

    try {
      const response = await createUser(data)

      switch (response) {
        case 'success': {
          setSubmitState({
            status: 'success',
            message: t('message.success-create')
          })
          return true
        }
        case 'User exists with same username': {
          setSubmitState({
            status: 'error',
            message: t('message.same-user')
          })
          return false
        }
        case 'User exists with same email': {
          setSubmitState({
            status: 'error',
            message: t('message.same-email')
          })
          return false
        }
        default: {
          setSubmitState({
            status: 'error',
            message: response
          })
          return false
        }
      }
    } catch (e) {
      setSubmitState({ status: 'error', message: t('message.error-create') })
      return false
    }
  }, [createUser, data, t])
  return [submitState, handleClickUpdateUser, setSubmitState] as const
}
