import { Wrapper, Description, Title } from './audit-header.styles'
import type { AuditHeaderProps } from './audit-header.types'

export function AuditHeader({ title, description }: AuditHeaderProps) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Wrapper>
  )
}
