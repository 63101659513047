export {
  ErrorBoundary as DocumentIdErrorBoundary,
  DocumentIdPage,
  loader as documentIdLoader
} from './documentId'
export {
  ErrorBoundary as DocumentsErrorBoundary,
  DocumentsPage,
  loader as documentsLoader
} from './documents'
