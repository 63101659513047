import { useState, useCallback, useEffect } from 'react'
import classnames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'

import { AppLauncher, AvatarMenu } from '@cdab/scania/qpr/components/molecules'
import { MobileMenuHeaderButton } from '@cdab/scania/qpr/components/atoms'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { WithLineLimit } from '@cdab/headless-components'

import {
  AppName,
  DesktopTitle,
  LeftNavigation,
  Logo,
  MobileDescription,
  MobileTitle,
  MobileTitleWrapper,
  Navigation,
  Overlay,
  ProfileMenu,
  RightNavigation
} from './toolbar-header.styles'

export interface ToolbarHeaderProps {
  onMobileMenuClick: () => void
  title: string
  mobileTitle: string
  currentActiveMenuItem?: number
  mobileDescription: string | null
  user: {
    userName: string
    name: string
  }
}

enum OpenMenu {
  None,
  MobileMenu,
  Avatar,
  AppLauncher
}

export function ToolbarHeader(props: ToolbarHeaderProps) {
  const { user, title, onMobileMenuClick, mobileTitle, mobileDescription } =
    props
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const [currentOpenMenu, setCurrentOpenMenu] = useState<OpenMenu>(
    OpenMenu.None
  )

  const location = useLocation()
  const navigate = useNavigate()

  const setOpenMenu = useCallback(
    (menu: OpenMenu) => () => {
      if (menu === OpenMenu.MobileMenu) {
        onMobileMenuClick()
      }
      if (currentOpenMenu === menu) {
        setCurrentOpenMenu(OpenMenu.None)
      } else {
        setCurrentOpenMenu(menu)
      }
    },
    [currentOpenMenu, onMobileMenuClick]
  )

  useEffect(() => {
    // Close all menu's on navigation
    setCurrentOpenMenu(OpenMenu.None)
  }, [location])

  const onLogoClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  return (
    <Navigation
      className={classnames({
        'sdds-nav__mob-menu--opened': currentOpenMenu === OpenMenu.MobileMenu,
        'sdds-nav__avatar--opened': currentOpenMenu === OpenMenu.Avatar,
        'sdds-nav__app-launcher--opened':
          currentOpenMenu === OpenMenu.AppLauncher
      })}
    >
      <LeftNavigation>
        <>
          <Overlay onClick={setOpenMenu(OpenMenu.None)} />

          <MobileMenuHeaderButton onClick={setOpenMenu(OpenMenu.MobileMenu)} />
        </>

        <AppName>
          {isLg ? (
            <DesktopTitle>{title}</DesktopTitle>
          ) : (
            <MobileTitleWrapper>
              <WithLineLimit maxLines={1}>
                <MobileTitle>{mobileTitle}</MobileTitle>
              </WithLineLimit>
              {mobileDescription && (
                <WithLineLimit maxLines={1}>
                  <MobileDescription>{mobileDescription}</MobileDescription>
                </WithLineLimit>
              )}
            </MobileTitleWrapper>
          )}
        </AppName>
      </LeftNavigation>

      <RightNavigation>
        <ProfileMenu>
          <AvatarMenu
            name={user.name}
            userName={user.userName}
            onAvatarMenuClick={setOpenMenu(OpenMenu.Avatar)}
          />
        </ProfileMenu>
        <AppLauncher
          onAppLauncherClick={setOpenMenu(OpenMenu.AppLauncher)}
          className='sdds-u-z1000'
        />
        <Logo onClick={onLogoClick} />
      </RightNavigation>
    </Navigation>
  )
}
