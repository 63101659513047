import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export interface DOSVersion {
  version: number
  name: string
}

export const DOSVersionSchema: JSONSchemaType<DOSVersion> = {
  type: 'object',

  properties: {
    version: {
      type: 'number'
    },
    name: {
      type: 'string'
    }
  },

  required: ['version', 'name']
}

const validateDOSVersion: ValidateFunction<DOSVersion> =
  ajv.compile(DOSVersionSchema)

export const isValidDOSVersion = (
  dosVersion: unknown
): dosVersion is DOSVersion =>
  isValidThing<DOSVersion>(dosVersion, validateDOSVersion)
