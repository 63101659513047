import { useState } from 'react'

import type { CheckPoint } from '@cdab/scania/qpr/schema'

import type { CheckpointListProps } from './checkpoint-list.types'
import { observer } from 'mobx-react-lite'
import { CheckpointItem } from '@cdab/scania/qpr/components/molecules'

type OpenItem = {
  id: number
}

export const CheckpointList = observer(
  ({ audit, checkPoints }: CheckpointListProps) => {
    const [openItems, setOpenItems] = useState<OpenItem[]>([])

    const onCheckpointClick = (selectedItem: CheckPoint) => () => {
      const newOpenItems = openItems.slice()

      const i = newOpenItems.findIndex(item => item.id === selectedItem.id)

      if (i === -1) {
        newOpenItems.push({ id: selectedItem.id })
      } else {
        newOpenItems.splice(i, 1)
      }

      setOpenItems(newOpenItems)
    }

    return (
      <>
        {checkPoints.map((checkPoint: CheckPoint) => (
          <CheckpointItem
            key={checkPoint.id}
            audit={audit}
            checkPoint={checkPoint}
            onClick={onCheckpointClick(checkPoint)}
            open={openItems.some(item => item.id === checkPoint.id)}
            hasNote={!!checkPoint.note}
          />
        ))}
      </>
    )
  }
)
