import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import type { DealersService as DealersServiceDefinition } from '@cdab/scania/qpr/interactor'
import type { Dealer, DealerJson } from '@cdab/scania/qpr/schema'
import {
  createDealerFromJson,
  isValidDealerJson
} from '@cdab/scania/qpr/schema'
import { axiosErrorToError, parseDate } from '@cdab/utils'

import type { Configuration } from './generated-swagger-client'
import { DealersApi } from './generated-swagger-client'

export class DealersService implements DealersServiceDefinition {
  private readonly dealersApi: DealersApi

  public constructor(
    configuration: Configuration,
    axiosInstance: AxiosInstance
  ) {
    this.dealersApi = new DealersApi(
      configuration,
      configuration.basePath,
      axiosInstance
    )
  }

  public GetDealerById = async (
    dealerId: number,
    options?: AxiosRequestConfig
  ): Promise<Dealer> => {
    try {
      const response = await this.dealersApi.apiDealersGet(
        undefined,
        [dealerId],
        options
      )

      const responseData = response.data?.data
        ? response.data.data[0]
        : undefined

      if (!responseData) {
        throw new Error('Dealer not found')
      }

      if (!isValidDealerJson(responseData)) {
        throw new Error('Invalid dealer json')
      }

      const dealer = createDealerFromJson(responseData)
      return dealer
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetDealersForMarket = async (
    marketId: number,
    options?: AxiosRequestConfig
  ): Promise<Dealer[]> => {
    try {
      const response = await this.dealersApi.apiDealersMarketIdGet(
        marketId,
        options
      )

      const dealers: Dealer[] = []

      for (const dealer of response.data.dealers ?? []) {
        if (!isValidDealerJson(dealer))
          throw new Error(`Dealer ${dealer.id} is invalid`)

        dealers.push(createDealerFromJson(dealer as DealerJson))
      }

      return dealers
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public UpdateExpirationDateForDealer = async (
    dealerId: number,
    newDate: string
  ): Promise<void> => {
    try {
      await this.dealersApi.apiDealersExpiringDateDealerIdExpiryDatePut(
        dealerId,
        newDate
      )
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetExpiring = async () => {
    try {
      const response = await this.dealersApi.apiDealersExpiringGet()

      const dealers: Dealer[] = []
      for (const dealer of response.data.dealers ?? []) {
        if (!isValidDealerJson(dealer))
          throw new Error(`Dealer ${dealer.id} is invalid`)

        dealers.push(createDealerFromJson(dealer))
      }

      return dealers
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetExpiryDateLimitForDealer = async (
    dealerId: number,
    options?: AxiosRequestConfig
  ): Promise<Date | null> => {
    try {
      const { data } =
        await this.dealersApi.apiDealersExpiryDateLimitDealerIdGet(
          dealerId,
          options
        )

      const isValidData =
        data !== null && typeof data === 'string' && data.length > 0
      if (!isValidData) {
        return null
      }

      return parseDate(data)
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }
}
