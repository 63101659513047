import styled from 'styled-components'
import classnames from 'classnames'

const StyledDefaultFooter = styled.div`
  /**
  * SDDS sets padding with !important
  * so to make this apply we also have to use !important
  */
  padding-left: 0 !important;
  padding-right: 0 !important;
`

export function DefaultFooter() {
  return (
    <StyledDefaultFooter className='sdds-footer sdds-col-max-12'>
      <div
        className={classnames('sdds-footer-main', {
          'sdds-u-pt0': true
        })}
      >
        <div className='sdds-footer-main-brand'>
          <p>Copyright &copy; 2022 Scania</p>
        </div>
      </div>
    </StyledDefaultFooter>
  )
}
