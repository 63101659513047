import React from 'react'
import { toast, type Id } from 'react-toastify'
import styled from 'styled-components'

type Type = 'success' | 'info' | 'warning' | 'error'

type Props = {
  headline: string
  subheadline?: string
  link?: {
    text: string
    url: string
  }
  type?: Type
}

const StyledToast = styled.div.attrs<{ type: Type }>(props => {
  return {
    className: `sdds-toast sdds-toast-${props.type}`
  }
})<{ type: Type }>`
  width: 100%;
`

export function Toast({
  headline,
  subheadline,
  link,
  type = 'info'
}: Props): React.JSX.Element {
  const toastId = React.useRef(null)

  const dismiss = () => toast.dismiss(toastId.current as any as Id)

  return (
    <StyledToast type={type} ref={toastId}>
      <div className='sdds-toast-icon'>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        ></svg>
      </div>

      <div className='sdds-toast-content'>
        <div className='sdds-toast-header' style={{ height: 'auto' }}>
          <span className='sdds-toast-headline'>{headline}</span>
          <span className='sdds-toast-dismiss' onClick={dismiss} />
        </div>

        {(subheadline || link) && (
          <div className='sdds-toast-body'>
            {subheadline && (
              <span className='sdds-toast-subheadline'>{subheadline}</span>
            )}
            {link && (
              <a className='sdds-toast-link' href={link.url}>
                {link.text}
              </a>
            )}
          </div>
        )}
      </div>
    </StyledToast>
  )
}
