import cx from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getEnvVarsQPR } from '@cdab/scania/qpr/env-vars'
import { useFeatures } from '@cdab/scania/qpr/feature-flags'
import {
  capitalize,
  getIconSize,
  IconArrowLeft,
  IconDocument,
  IconHeart,
  IconHome,
  IconLock,
  IconTruck
} from '@cdab/scania/sdds'

import { useUser } from '@cdab/scania/qpr/contexts/user-provider'
import { Roles } from '@cdab/scania/qpr/schema'
import { capitalizeAllFirstLetters } from '@cdab/utils'
import styled from 'styled-components'
import { SideMenuItem, SideMenuSubItem } from './side-menu-item'
import { SideMenuItemType } from './side-menu.types'

const StyledIconArrowLeft = styled(IconArrowLeft)`
  width: ${getIconSize('medium')};
  height: ${getIconSize('medium')};
`

export function SideMenu({
  isMobileMenuOpen,
  closeMobileMenu,
  onMobileMenuClick
}: {
  isMobileMenuOpen: boolean
  closeMobileMenu: () => void
  onMobileMenuClick: () => void
}) {
  const { t } = useTranslation(['favoriteAudits', 'common'])
  const { qpr2007url } = getEnvVarsQPR()
  const [isCollapsed, setIsCollapsed] = useState(true)

  const features = useFeatures([
    'audit_shortcuts',
    'readonly_documents_auditor'
  ])

  const userContext = useUser()

  const isAdminHighest = userContext.userData.role === Roles.FactoryAdmin
  const isFactoyEngineAndHigher =
    userContext.userData.role >= Roles.FactoryEngineAdmin
  const isCordinatorAndHigher = userContext.userData.role >= Roles.CoOrdinator
  const isAuditorOnly = userContext.userData.role === Roles.Auditor

  function toggleCollapsed() {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div
      className={cx('sdds-sidebar side-menu', {
        collapsed: !isCollapsed,
        'mobile-menu-open': isMobileMenuOpen
      })}
      style={{ overflowX: 'hidden' }}
    >
      <div className='sdds-sidebar-mheader' onClick={onMobileMenuClick}>
        <a className='sdds-sidebar-mheader__close' onClick={onMobileMenuClick}>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M3.40338 2.34308C3.11048 2.05019 2.63561 2.05019 2.34272 2.34308C2.04982 2.63598 2.04982 3.11085 2.34272 3.40374L6.93897 8L2.34283 12.5961C2.04994 12.889 2.04994 13.3639 2.34283 13.6568C2.63572 13.9497 3.1106 13.9497 3.40349 13.6568L7.99963 9.06066L12.5958 13.6568C12.8887 13.9497 13.3635 13.9497 13.6564 13.6568C13.9493 13.3639 13.9493 12.889 13.6564 12.5961L9.06029 8L13.6565 3.40376C13.9494 3.11086 13.9494 2.63599 13.6565 2.3431C13.3636 2.0502 12.8888 2.0502 12.5959 2.3431L7.99963 6.93934L3.40338 2.34308Z'
              fill='#171719'
            />
          </svg>
        </a>
      </div>
      <ul className='sdds-sidebar-nav sdds-sidebar-nav--main icons-enabled'>
        <SideMenuItem
          title='Dashboard'
          icon={<IconHome />}
          callbackFn={closeMobileMenu}
          to='/dashboard'
          type={SideMenuItemType.link}
        />

        <SideMenuItem
          title={capitalize(t('favorite-audits'))}
          icon={<IconHeart />}
          callbackFn={closeMobileMenu}
          to='/my-audits'
          type={SideMenuItemType.link}
        />
        <SideMenuItem
          title='Dealers'
          icon={<IconTruck />}
          to='/dealers'
          callbackFn={closeMobileMenu}
          type={SideMenuItemType.link}
        />

        <SideMenuItem
          title='Reports'
          icon={<IconDocument />}
          to='/reports'
          callbackFn={closeMobileMenu}
          type={SideMenuItemType.link}
        />
        {features.readonly_documents_auditor.enabled && isAuditorOnly && (
          <SideMenuItem
            title='Documents'
            icon={<IconDocument />}
            to='/documents'
            callbackFn={closeMobileMenu}
            type={SideMenuItemType.link}
          />
        )}
        <SideMenuItem
          type={SideMenuItemType.submenu}
          title='Admin'
          to={`#`}
          icon={<IconLock />}
          callbackFn={closeMobileMenu}
        >
          <SideMenuSubItem
            title='Admin'
            to={`${qpr2007url}/Default.aspx?action=admin`}
            external
            openInNewTab
          />

          {isCordinatorAndHigher && (
            <SideMenuSubItem //This should be visible to RoleId 100 and 75 and 50( for 50 Just it's markets)
              title={capitalizeAllFirstLetters(
                t('reference-documents', { ns: 'common' })
              )}
              to='/admin/documents'
            />
          )}

          {isAdminHighest && (
            <SideMenuSubItem //Just RoleId 100 should see this
              title={t('system-messages', { ns: 'common' })}
              to='/admin/system-messages'
            />
          )}

          <SideMenuSubItem
            title={t('translations', { ns: 'common' })}
            to='/admin/translations'
          />

          {isFactoyEngineAndHigher && ( //This should be visible to RoleId 100 and 75
            <SideMenuSubItem
              title={t('users', { ns: 'common' })}
              to='/admin/users'
            />
          )}
        </SideMenuItem>

        {features.audit_shortcuts.enabled && (
          <>
            <SideMenuItem
              title='Sample Audit'
              callbackFn={closeMobileMenu}
              to='/audit/12345'
              type={SideMenuItemType.link}
            />
            <SideMenuItem
              title='Good Audit'
              callbackFn={closeMobileMenu}
              to='/audit/14101'
              type={SideMenuItemType.link}
            />
          </>
        )}
      </ul>
      <ul className='sdds-sidebar-nav sdds-sidebar-nav--bottom icons-enabled'>
        <li className='sdds-sidebar-nav__item' onClick={toggleCollapsed}>
          <a className='sdds-sidebar-toggle sdds-sidebar-nav__item-link'>
            <StyledIconArrowLeft className='sdds-sidebar-nav__icon' />
            <span className='sdds-sidebar-nav__item-text'>Collapse</span>
          </a>
        </li>
      </ul>
    </div>
  )
}
