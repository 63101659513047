import { BaseLayoutTemplate } from '@cdab/scania/qpr/components'
import { useUser } from '@cdab/scania/qpr/contexts/user-provider'

export function PageTemplate(): JSX.Element {
  const { userData } = useUser()

  return (
    <BaseLayoutTemplate
      user={{
        name: `${userData.firstName} ${userData.lastName}`,
        userName: userData.userName
      }}
    />
  )
}
