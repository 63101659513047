import styled from 'styled-components'

export const IconButton = styled.button.attrs({
  className: 'sdds-btn sdds-btn-primary sdds-btn-md'
})`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: var(--sdds-grey-500);
    margin-right: var(--sdds-spacing-element-16);
    margin-top: var(--sdds-spacing-element-12);
    margin-bottom: var(--sdds-spacing-element-12);
    align-self: flex-start;
    color: var(--sdds-grey-50);
  }
  padding-left: 1rem;
  svg {
    width: 4rem;
    height: 4rem;
  }
`
