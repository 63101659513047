import type { AuditPoint } from '@cdab/scania/qpr/schema'
export const AuditPoints: AuditPoint[] = [
  {
    id: 743,
    translationGuid: '0dd2b71d-2872-4d50-9257-2ef772583f9e',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.1',
    purposeTranslationGuid: 'db9c02c9-3a69-4f83-bc07-f608472c9b99',
    score: false,
    ap1: 1,
    ap2: 1,
    ap3: 0,
    sortOrder: 1049600
  },
  {
    id: 744,
    translationGuid: '66627031-2e87-4eaa-90c3-5cec6c7bde28',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.2',
    purposeTranslationGuid: '4e00eac2-db7a-4cd2-8c5c-bd743df6db5f',
    score: true,
    ap1: 1,
    ap2: 2,
    ap3: 0,
    sortOrder: 1050624
  },
  {
    id: 745,
    translationGuid: 'e1eda1fb-1e47-4d52-a23a-08b0dc2f6e52',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.3',
    purposeTranslationGuid: '3124c3bf-789a-4ec9-aa01-e5073d27c70b',
    score: false,
    ap1: 1,
    ap2: 3,
    ap3: 0,
    sortOrder: 1051648
  },
  {
    id: 746,
    translationGuid: '9de6c679-e6e7-4a6f-bf3a-f318e5c7a00c',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.4',
    purposeTranslationGuid: 'f7e42d9c-e19c-4eda-830d-13986975503c',
    score: true,
    ap1: 1,
    ap2: 4,
    ap3: 0,
    sortOrder: 1052672
  },
  {
    id: 747,
    translationGuid: '05a9c5da-1747-4c30-8070-19d5b72a0127',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.5',
    purposeTranslationGuid: 'a0c9908e-146a-42d9-b1c8-848b1304871c',
    score: true,
    ap1: 1,
    ap2: 5,
    ap3: 0,
    sortOrder: 1053696
  },
  {
    id: 748,
    translationGuid: 'ba24f3af-21c6-4967-8a16-09661aa14846',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.6',
    purposeTranslationGuid: '2641b94d-30df-41eb-8162-f357a7bc3f0d',
    score: true,
    ap1: 1,
    ap2: 6,
    ap3: 0,
    sortOrder: 1054720
  },
  {
    id: 749,
    translationGuid: '47fb4f74-cd98-4c32-8031-e4c06fd53d58',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.7',
    purposeTranslationGuid: '3305b370-571a-4b53-971f-31fc8e516efa',
    score: true,
    ap1: 1,
    ap2: 7,
    ap3: 0,
    sortOrder: 1055744
  },
  {
    id: 750,
    translationGuid: '63df1efe-39a9-47b7-9a60-9937815d9a93',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.8',
    purposeTranslationGuid: '817bf4f7-aba9-4243-95f5-cf830e1e57cd',
    score: false,
    ap1: 1,
    ap2: 8,
    ap3: 0,
    sortOrder: 1056768
  },
  {
    id: 751,
    translationGuid: 'b8931684-b23a-40b3-a3f4-05b4be7fb91a',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.9',
    purposeTranslationGuid: '0de1ac9e-ddea-4012-872f-6d273868e091',
    score: false,
    ap1: 1,
    ap2: 9,
    ap3: 0,
    sortOrder: 1057792
  },
  {
    id: 752,
    translationGuid: 'f9203697-d602-4388-a4a2-e24ecf60acb7',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.10',
    purposeTranslationGuid: '5325433f-8fed-42f9-9be6-e7b4f5c65c9f',
    score: true,
    ap1: 1,
    ap2: 10,
    ap3: 0,
    sortOrder: 1058816
  },
  {
    id: 753,
    translationGuid: '19e007ad-70e3-4b4c-8fe0-abc5e8add012',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.11',
    purposeTranslationGuid: '9c8dbd47-9531-4c52-8292-755fc38ec4d7',
    score: true,
    ap1: 1,
    ap2: 11,
    ap3: 0,
    sortOrder: 1059840
  },
  {
    id: 754,
    translationGuid: '60125cf7-3f84-40f1-904f-6837106f7ab5',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.12',
    purposeTranslationGuid: 'b8af15b1-2360-4739-9c9e-617db4fec102',
    score: true,
    ap1: 1,
    ap2: 12,
    ap3: 0,
    sortOrder: 1060864
  },
  {
    id: 755,
    translationGuid: '6b98c3be-9044-4a0e-8e60-38ebdfadbaa9',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.13',
    purposeTranslationGuid: 'b103fe15-540c-448e-9670-d15cb562c642',
    score: true,
    ap1: 1,
    ap2: 13,
    ap3: 0,
    sortOrder: 1061888
  },
  {
    id: 756,
    translationGuid: '6d1bcf4b-4a30-4e46-a952-c1a13746be99',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.14',
    purposeTranslationGuid: 'f788cf61-f63c-4b1e-9a3d-5ab524e8646b',
    score: false,
    ap1: 1,
    ap2: 14,
    ap3: 0,
    sortOrder: 1062912
  },
  {
    id: 757,
    translationGuid: '81cd3305-4f13-4bf7-9856-4be606d45019',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.15',
    purposeTranslationGuid: 'ee0b35d5-07aa-4b77-9a43-109564c3e667',
    score: false,
    ap1: 1,
    ap2: 15,
    ap3: 0,
    sortOrder: 1063936
  },
  {
    id: 758,
    translationGuid: '1ec1eb0d-c2c1-48c3-bf06-f167d199e018',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.16',
    purposeTranslationGuid: '23011a1d-3253-4858-aaf0-a63ae76ec98e',
    score: true,
    ap1: 1,
    ap2: 16,
    ap3: 0,
    sortOrder: 1064960
  },
  {
    id: 759,
    translationGuid: '6d6d7d34-761a-41dd-8810-3ed7192d7bd4',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.17',
    purposeTranslationGuid: '99758146-3b82-435f-ad13-62da8b72124f',
    score: true,
    ap1: 1,
    ap2: 17,
    ap3: 0,
    sortOrder: 1065984
  },
  {
    id: 760,
    translationGuid: '56b79346-33b6-4d09-b70e-d68265cbdd8c',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.18',
    purposeTranslationGuid: 'ec0c1ebd-7048-4b72-b896-4fcec24166ba',
    score: false,
    ap1: 1,
    ap2: 18,
    ap3: 0,
    sortOrder: 1067008
  },
  {
    id: 761,
    translationGuid: '875c81c2-b1ff-4e4b-b4cb-17c7f4cd7c67',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.19',
    purposeTranslationGuid: 'cbc10ca8-16b5-4e9c-a52c-6bef75770c49',
    score: false,
    ap1: 1,
    ap2: 19,
    ap3: 0,
    sortOrder: 1068032
  },
  {
    id: 762,
    translationGuid: 'aa6c1734-3bf7-487b-96e7-cea9673250e3',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.20',
    purposeTranslationGuid: '49b50cea-269b-4e15-b1da-4d30b87409df',
    score: false,
    ap1: 1,
    ap2: 20,
    ap3: 0,
    sortOrder: 1069056
  },
  {
    id: 763,
    translationGuid: '00b57ac7-36fb-42f4-bdcb-38d9395c2198',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.21',
    purposeTranslationGuid: 'db7dae77-e77b-4e5f-9bc4-f49fc93a589a',
    score: false,
    ap1: 1,
    ap2: 21,
    ap3: 0,
    sortOrder: 1070080
  },
  {
    id: 764,
    translationGuid: '93878e0e-73a3-4935-9b71-132f449591d9',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 62,
    note: null,
    auditPointNo: '1.22',
    purposeTranslationGuid: 'cb8e3dfc-aa11-4f5e-b284-c86c7cd9fe39',
    score: false,
    ap1: 1,
    ap2: 22,
    ap3: 0,
    sortOrder: 1071104
  },
  {
    id: 765,
    translationGuid: '54c8f486-bfe8-43fa-84c3-a35924485664',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 63,
    note: null,
    auditPointNo: '2.1',
    purposeTranslationGuid: 'fbd758a0-168c-4cc7-a536-b1d132f6fd35',
    score: true,
    ap1: 2,
    ap2: 1,
    ap3: 0,
    sortOrder: 2098176
  },
  {
    id: 766,
    translationGuid: '9fe10990-cc60-41b4-abd0-189fcd977816',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 63,
    note: null,
    auditPointNo: '2.2',
    purposeTranslationGuid: 'c2767519-9a72-46ff-9ff3-d75a6c817216',
    score: true,
    ap1: 2,
    ap2: 2,
    ap3: 0,
    sortOrder: 2099200
  },
  {
    id: 767,
    translationGuid: 'a84dd7a1-941e-4f9b-934d-72059b9109e1',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 63,
    note: null,
    auditPointNo: '2.3',
    purposeTranslationGuid: 'dc1e723b-4a51-48c7-9bb6-3e92a6a14a1f',
    score: true,
    ap1: 2,
    ap2: 3,
    ap3: 0,
    sortOrder: 2100224
  },
  {
    id: 768,
    translationGuid: 'fc4f598a-02a7-43fd-9c6c-43a8ec6b23f4',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 63,
    note: null,
    auditPointNo: '2.4',
    purposeTranslationGuid: '235db04d-0b2e-4309-a497-8c6ea39397e7',
    score: true,
    ap1: 2,
    ap2: 4,
    ap3: 0,
    sortOrder: 2101248
  },
  {
    id: 769,
    translationGuid: '8d859850-03e8-4919-89c4-ee340535ef5d',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 63,
    note: null,
    auditPointNo: '2.5',
    purposeTranslationGuid: '5703c123-e872-4075-9caf-d5bf20308026',
    score: true,
    ap1: 2,
    ap2: 5,
    ap3: 0,
    sortOrder: 2102272
  },
  {
    id: 770,
    translationGuid: '7133e89f-08a5-430a-ab66-935da8b6937f',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 63,
    note: null,
    auditPointNo: '2.6',
    purposeTranslationGuid: 'fbcec19a-6d7b-4b1d-98d2-8dd658e2fdf7',
    score: false,
    ap1: 2,
    ap2: 6,
    ap3: 0,
    sortOrder: 2103296
  },
  {
    id: 771,
    translationGuid: '9dc38ca3-f717-4cdc-9fa9-9f271b56f382',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 63,
    note: null,
    auditPointNo: '2.7',
    purposeTranslationGuid: 'a79540ba-3f01-49f5-984a-60fa8a88109b',
    score: false,
    ap1: 2,
    ap2: 7,
    ap3: 0,
    sortOrder: 2104320
  },
  {
    id: 772,
    translationGuid: 'e342a721-8e64-4e54-b5cb-e8753a608bdc',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 64,
    note: null,
    auditPointNo: '3.1',
    purposeTranslationGuid: 'ed5521a2-41a9-41da-a216-ecf8bebc5d47',
    score: true,
    ap1: 3,
    ap2: 1,
    ap3: 0,
    sortOrder: 3146752
  },
  {
    id: 773,
    translationGuid: 'f2a5771b-1299-4169-8664-626a1eabc7c3',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 64,
    note: null,
    auditPointNo: '3.2',
    purposeTranslationGuid: '0e1a680f-cc4e-4c39-ada4-ecdafd26f962',
    score: true,
    ap1: 3,
    ap2: 2,
    ap3: 0,
    sortOrder: 3147776
  },
  {
    id: 774,
    translationGuid: 'c2bfa856-6c8c-4e61-b681-bacb7df68a00',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 64,
    note: null,
    auditPointNo: '3.3',
    purposeTranslationGuid: 'babb7915-e462-4729-b23d-33dda861a156',
    score: true,
    ap1: 3,
    ap2: 3,
    ap3: 0,
    sortOrder: 3148800
  },
  {
    id: 775,
    translationGuid: '6af77dbb-1261-4d1a-bd64-060f7eb05c6f',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 64,
    note: null,
    auditPointNo: '3.4',
    purposeTranslationGuid: 'b8c988cd-7c4a-486a-8081-30443775d543',
    score: true,
    ap1: 3,
    ap2: 4,
    ap3: 0,
    sortOrder: 3149824
  },
  {
    id: 776,
    translationGuid: '26003182-62f3-4384-8b87-48efa1d45eaa',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 64,
    note: null,
    auditPointNo: '3.5',
    purposeTranslationGuid: 'bc43ea0d-9965-4d9d-9657-30533bacb82c',
    score: true,
    ap1: 3,
    ap2: 5,
    ap3: 0,
    sortOrder: 3150848
  },
  {
    id: 777,
    translationGuid: '68f935c1-2dc2-4fc2-af09-422e3ddf6ec1',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 64,
    note: null,
    auditPointNo: '3.6',
    purposeTranslationGuid: 'f75e657b-05a2-4d66-9178-8275a12044f3',
    score: false,
    ap1: 3,
    ap2: 6,
    ap3: 0,
    sortOrder: 3151872
  },
  {
    id: 778,
    translationGuid: 'c6d7e474-530d-4d6b-9522-4aaf9e5dd965',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 64,
    note: null,
    auditPointNo: '3.7',
    purposeTranslationGuid: '4075b442-c183-4279-84d6-4fda8e992d88',
    score: false,
    ap1: 3,
    ap2: 7,
    ap3: 0,
    sortOrder: 3152896
  },
  {
    id: 779,
    translationGuid: '0f80c566-4df0-4f65-a31f-c702f8f57190',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 65,
    note: null,
    auditPointNo: '4.1',
    purposeTranslationGuid: '5c2d7351-26e3-4ec7-a02e-6b8498e7755a',
    score: true,
    ap1: 4,
    ap2: 1,
    ap3: 0,
    sortOrder: 4195328
  },
  {
    id: 781,
    translationGuid: '80c9da86-81f2-48c1-833f-d94c68939adf',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 65,
    note: null,
    auditPointNo: '4.2',
    purposeTranslationGuid: 'e9cf647b-3fce-4176-84a8-a092f9c8430d',
    score: true,
    ap1: 4,
    ap2: 2,
    ap3: 0,
    sortOrder: 4196352
  },
  {
    id: 782,
    translationGuid: '9df9bd43-a478-49bd-b5cd-005f13063bfb',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 65,
    note: null,
    auditPointNo: '4.3',
    purposeTranslationGuid: '86c47618-62d9-41b7-acc4-51ef24a0a407',
    score: true,
    ap1: 4,
    ap2: 3,
    ap3: 0,
    sortOrder: 4197376
  },
  {
    id: 783,
    translationGuid: 'e9384c4c-e1c7-4162-a431-3c992660a7cd',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 65,
    note: null,
    auditPointNo: '4.4',
    purposeTranslationGuid: '99c10821-b58c-44d3-870a-6d55779721f4',
    score: true,
    ap1: 4,
    ap2: 4,
    ap3: 0,
    sortOrder: 4198400
  },
  {
    id: 784,
    translationGuid: '5d4e59a2-94d2-4740-bb0e-5fda8ff76df1',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 65,
    note: null,
    auditPointNo: '4.5',
    purposeTranslationGuid: '456f60b5-1b7c-426f-a48c-b0167f5915f4',
    score: true,
    ap1: 4,
    ap2: 5,
    ap3: 0,
    sortOrder: 4199424
  },
  {
    id: 785,
    translationGuid: '3747d3cb-3dc4-4950-bcd6-586b6ec5ba12',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 65,
    note: null,
    auditPointNo: '4.6',
    purposeTranslationGuid: '61477e33-ad43-4dfb-a69d-8bff3589fa61',
    score: true,
    ap1: 4,
    ap2: 6,
    ap3: 0,
    sortOrder: 4200448
  },
  {
    id: 786,
    translationGuid: '1ff67dfd-07d7-43fd-a068-43e82181280a',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 65,
    note: null,
    auditPointNo: '4.7',
    purposeTranslationGuid: '51154c69-4cbe-4152-89e1-226e1a1fe069',
    score: true,
    ap1: 4,
    ap2: 7,
    ap3: 0,
    sortOrder: 4201472
  },
  {
    id: 787,
    translationGuid: '8c17533f-1896-4e6b-9720-45e347d8ac95',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 65,
    note: null,
    auditPointNo: '4.8',
    purposeTranslationGuid: 'e8b88650-1b55-4cf8-b107-913e877e66e0',
    score: true,
    ap1: 4,
    ap2: 8,
    ap3: 0,
    sortOrder: 4202496
  },
  {
    id: 788,
    translationGuid: 'f23a62e0-ffaf-4f61-8792-679d7ffea745',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 66,
    note: null,
    auditPointNo: '5.1',
    purposeTranslationGuid: '72c91bb0-bd94-496b-8c9a-1b7b2f0880fb',
    score: true,
    ap1: 5,
    ap2: 1,
    ap3: 0,
    sortOrder: 5243904
  },
  {
    id: 789,
    translationGuid: 'baf9f539-c4c3-448e-948e-4b5eed174721',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 66,
    note: null,
    auditPointNo: '5.2',
    purposeTranslationGuid: '26e8a47b-03a0-4bfb-8a17-9f18fed821fb',
    score: true,
    ap1: 5,
    ap2: 2,
    ap3: 0,
    sortOrder: 5244928
  },
  {
    id: 790,
    translationGuid: '5da02aac-8322-4619-b03b-6623bd32018f',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 66,
    note: null,
    auditPointNo: '5.3',
    purposeTranslationGuid: '515297ca-1c1c-447f-97ff-e4a60c66fe8b',
    score: true,
    ap1: 5,
    ap2: 3,
    ap3: 0,
    sortOrder: 5245952
  },
  {
    id: 791,
    translationGuid: 'b8c5f359-0756-4ea3-9e98-20b3aa627e47',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 66,
    note: null,
    auditPointNo: '5.4',
    purposeTranslationGuid: '8681bd6b-be83-4a33-bc56-ea3efe80c3ce',
    score: true,
    ap1: 5,
    ap2: 4,
    ap3: 0,
    sortOrder: 5246976
  },
  {
    id: 792,
    translationGuid: '9549a9df-8f47-4f25-b994-d7916c39bfbc',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 66,
    note: null,
    auditPointNo: '5.5',
    purposeTranslationGuid: 'd8d65750-b6b2-4b5f-ba12-871f5cc68db6',
    score: true,
    ap1: 5,
    ap2: 5,
    ap3: 0,
    sortOrder: 5248000
  },
  {
    id: 793,
    translationGuid: '3457c259-49e5-4da0-99f7-51c6af1fde4e',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 67,
    note: null,
    auditPointNo: '6.1',
    purposeTranslationGuid: '6e77e3b1-9f5d-4bb7-8bd9-63d29c3a0257',
    score: true,
    ap1: 6,
    ap2: 1,
    ap3: 0,
    sortOrder: 6292480
  },
  {
    id: 794,
    translationGuid: 'c98e878d-34f4-4106-a682-5292b496a1a1',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 67,
    note: null,
    auditPointNo: '6.2',
    purposeTranslationGuid: '8b424708-0822-420e-aaca-0f54ae6f5927',
    score: true,
    ap1: 6,
    ap2: 2,
    ap3: 0,
    sortOrder: 6293504
  },
  {
    id: 795,
    translationGuid: 'e0268036-679f-40a8-ae51-d46f3dd3a881',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 67,
    note: null,
    auditPointNo: '6.3',
    purposeTranslationGuid: 'c1bb4574-47b2-4c24-9f99-b9bdbdf2088d',
    score: true,
    ap1: 6,
    ap2: 3,
    ap3: 0,
    sortOrder: 6294528
  },
  {
    id: 796,
    translationGuid: 'eada9000-932f-4378-a589-1792738a9efb',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 67,
    note: null,
    auditPointNo: '6.4',
    purposeTranslationGuid: '1587ae08-4342-40ee-9924-825dcc71e55a',
    score: true,
    ap1: 6,
    ap2: 4,
    ap3: 0,
    sortOrder: 6295552
  },
  {
    id: 797,
    translationGuid: '72d37f91-ba93-4085-a914-8e746f1804d3',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 67,
    note: null,
    auditPointNo: '6.5',
    purposeTranslationGuid: '9448d400-053d-4b63-a2dd-ab20d39d053b',
    score: true,
    ap1: 6,
    ap2: 5,
    ap3: 0,
    sortOrder: 6296576
  },
  {
    id: 798,
    translationGuid: 'e2645922-2e94-4de3-8840-298c78435700',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 67,
    note: null,
    auditPointNo: '6.6',
    purposeTranslationGuid: '8b32836b-6436-40a2-ba05-707478781680',
    score: false,
    ap1: 6,
    ap2: 6,
    ap3: 0,
    sortOrder: 6297600
  },
  {
    id: 799,
    translationGuid: 'aa13ba2c-cd6a-4e5b-ad79-0f60c31b0159',
    isPointCheckedYes: null,
    isMandatory: false,
    pledgeId: 67,
    note: null,
    auditPointNo: '6.7',
    purposeTranslationGuid: '1cec67ce-ec0f-4615-9446-8152cd364892',
    score: null,
    ap1: 6,
    ap2: 7,
    ap3: 0,
    sortOrder: 6298624
  },
  {
    id: 800,
    translationGuid: 'c48cbc59-5fa0-4b30-b2cf-a32c7a15d0fd',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.1',
    purposeTranslationGuid: '38bb4312-6499-4979-b3a3-67cc3bb4263e',
    score: false,
    ap1: 7,
    ap2: 1,
    ap3: 0,
    sortOrder: 7341056
  },
  {
    id: 801,
    translationGuid: '50ae90f5-e818-4b20-aee4-08d50903a880',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.2',
    purposeTranslationGuid: 'b475cfa0-8b9c-428b-8d8c-cd5517881e70',
    score: false,
    ap1: 7,
    ap2: 2,
    ap3: 0,
    sortOrder: 7342080
  },
  {
    id: 802,
    translationGuid: '9a3e8c33-ddf6-416b-9b79-73554e9ba414',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.3',
    purposeTranslationGuid: 'ba392a7d-2c94-4cc0-9db2-5614388afaf7',
    score: true,
    ap1: 7,
    ap2: 3,
    ap3: 0,
    sortOrder: 7343104
  },
  {
    id: 803,
    translationGuid: '35ae2463-ddb0-4300-aeb4-e60d86e93fc6',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.4',
    purposeTranslationGuid: '4c8f1841-56c5-4d73-924a-38017026081c',
    score: true,
    ap1: 7,
    ap2: 4,
    ap3: 0,
    sortOrder: 7344128
  },
  {
    id: 804,
    translationGuid: '3fe2b990-1129-4d58-9476-8c99eaa81419',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.5',
    purposeTranslationGuid: '785e0110-234d-4073-8b7a-1dac2837aad7',
    score: false,
    ap1: 7,
    ap2: 5,
    ap3: 0,
    sortOrder: 7345152
  },
  {
    id: 805,
    translationGuid: 'ae92c2de-1163-4a9b-bc2f-68a9f07fc635',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.6',
    purposeTranslationGuid: '14d0c853-6858-4f5f-9b23-070c2048ffcb',
    score: true,
    ap1: 7,
    ap2: 6,
    ap3: 0,
    sortOrder: 7346176
  },
  {
    id: 806,
    translationGuid: '536fd804-812e-4a37-8e6c-d9f32264d809',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.7',
    purposeTranslationGuid: '369978f0-a015-4ae0-be0c-b36252fe9335',
    score: false,
    ap1: 7,
    ap2: 7,
    ap3: 0,
    sortOrder: 7347200
  },
  {
    id: 807,
    translationGuid: '66c95abb-f45d-40cc-85a4-7f5e34bb570f',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.8',
    purposeTranslationGuid: 'a5021b81-c819-4c41-b3b7-b39fc8552c4f',
    score: false,
    ap1: 7,
    ap2: 8,
    ap3: 0,
    sortOrder: 7348224
  },
  {
    id: 808,
    translationGuid: '2219a715-d3c7-4e0f-b24c-66dccded8072',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.9',
    purposeTranslationGuid: '36992d2f-1a0b-4483-8e73-c4b97638b81d',
    score: true,
    ap1: 7,
    ap2: 9,
    ap3: 0,
    sortOrder: 7349248
  },
  {
    id: 809,
    translationGuid: 'e818845b-1c22-402a-a112-41eca7c3867a',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.10',
    purposeTranslationGuid: 'a0510a5d-2367-4561-9f5f-1e060ae76803',
    score: true,
    ap1: 7,
    ap2: 10,
    ap3: 0,
    sortOrder: 7350272
  },
  {
    id: 810,
    translationGuid: '441d93ef-c43d-4330-b6ff-2a8b9ce43195',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.11',
    purposeTranslationGuid: 'fb2997e2-a10e-4742-8804-79926eeecbad',
    score: false,
    ap1: 7,
    ap2: 11,
    ap3: 0,
    sortOrder: 7351296
  },
  {
    id: 811,
    translationGuid: '2b1a45ec-30c1-41a4-bf23-49567d38f44f',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.12',
    purposeTranslationGuid: '4cb48644-2f22-4039-a36e-d8f2a49e745f',
    score: false,
    ap1: 7,
    ap2: 12,
    ap3: 0,
    sortOrder: 7352320
  },
  {
    id: 780,
    translationGuid: 'a6f2e552-4110-47b6-be14-04f2769d2f7f',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.13',
    purposeTranslationGuid: '68fb3886-3e91-4b00-988c-c381ed26a930',
    score: false,
    ap1: 7,
    ap2: 13,
    ap3: 0,
    sortOrder: 7353344
  },
  {
    id: 812,
    translationGuid: 'e7299e50-b832-4257-bf5c-850ba4db7e92',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.14',
    purposeTranslationGuid: '6601feb1-fe76-41ba-9004-f01ce5cad4f1',
    score: false,
    ap1: 7,
    ap2: 14,
    ap3: 0,
    sortOrder: 7354368
  },
  {
    id: 813,
    translationGuid: '17e7df68-7790-4beb-85c3-0ee71bcaa744',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.15',
    purposeTranslationGuid: '38c110f8-e372-4ba0-8573-cdf057a9a6bb',
    score: false,
    ap1: 7,
    ap2: 15,
    ap3: 0,
    sortOrder: 7355392
  },
  {
    id: 814,
    translationGuid: '8646a402-f79c-44cb-8ee5-e6f669bf46c4',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 68,
    note: null,
    auditPointNo: '7.16',
    purposeTranslationGuid: 'de616a22-26e4-4529-a74f-3b330c08af2b',
    score: true,
    ap1: 7,
    ap2: 16,
    ap3: 0,
    sortOrder: 7356416
  },
  {
    id: 815,
    translationGuid: '74467b94-9610-4be0-ace9-ea934a2a141b',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.1',
    purposeTranslationGuid: '49cbe8f0-21fb-49a9-8537-49ecd4faf3fc',
    score: true,
    ap1: 8,
    ap2: 1,
    ap3: 0,
    sortOrder: 8389632
  },
  {
    id: 816,
    translationGuid: 'edd119df-0f73-4800-9074-c088a98d1495',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.2',
    purposeTranslationGuid: 'fa28f488-de87-4715-ae03-6eafe6aac6d9',
    score: true,
    ap1: 8,
    ap2: 2,
    ap3: 0,
    sortOrder: 8390656
  },
  {
    id: 817,
    translationGuid: '325ca15f-944c-4aa8-8557-faf5459aee80',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.3',
    purposeTranslationGuid: '1f90a5c1-e252-4986-a0ec-03ca2bec2697',
    score: true,
    ap1: 8,
    ap2: 3,
    ap3: 0,
    sortOrder: 8391680
  },
  {
    id: 818,
    translationGuid: '52d4eb80-5cdf-4669-83c8-86389925915c',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.4',
    purposeTranslationGuid: '8545f6e9-28f5-4675-afee-6fa89f948b39',
    score: true,
    ap1: 8,
    ap2: 4,
    ap3: 0,
    sortOrder: 8392704
  },
  {
    id: 819,
    translationGuid: '0b640aad-433a-48a6-897e-71cb66c284b3',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.5',
    purposeTranslationGuid: 'e353e8d2-850e-4b9e-94d6-62b86821601e',
    score: true,
    ap1: 8,
    ap2: 5,
    ap3: 0,
    sortOrder: 8393728
  },
  {
    id: 820,
    translationGuid: '8647e6ec-a8fb-45e6-a52e-ffff4a87b0b5',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.6',
    purposeTranslationGuid: '991847c2-ecd7-4cae-8dbb-f6a5f60e13ae',
    score: true,
    ap1: 8,
    ap2: 6,
    ap3: 0,
    sortOrder: 8394752
  },
  {
    id: 821,
    translationGuid: 'ca4da1be-71af-40d5-ba2f-cccf102e0203',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.7',
    purposeTranslationGuid: 'b0879cad-9002-4f87-9fe3-81cab1d3f9df',
    score: false,
    ap1: 8,
    ap2: 7,
    ap3: 0,
    sortOrder: 8395776
  },
  {
    id: 822,
    translationGuid: '353e5a76-62c5-4141-b947-b79bc11a0b95',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.8',
    purposeTranslationGuid: '4a069f42-b24a-4146-9c11-56df6aa8aacf',
    score: false,
    ap1: 8,
    ap2: 8,
    ap3: 0,
    sortOrder: 8396800
  },
  {
    id: 823,
    translationGuid: 'b239d4e0-f1ce-4f37-ae33-3c53b30647f9',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.9',
    purposeTranslationGuid: '783519f1-d751-4698-9e53-40b8fbe95f2a',
    score: true,
    ap1: 8,
    ap2: 9,
    ap3: 0,
    sortOrder: 8397824
  },
  {
    id: 824,
    translationGuid: 'bc93b370-4c74-4738-b191-ab5e3829261c',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.10',
    purposeTranslationGuid: '1d1c1c04-df19-4deb-82c8-ab3570ba2eda',
    score: true,
    ap1: 8,
    ap2: 10,
    ap3: 0,
    sortOrder: 8398848
  },
  {
    id: 825,
    translationGuid: 'c6b6fb66-94f1-4663-944b-4d6bb9126744',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.11',
    purposeTranslationGuid: '418fe141-e2b5-4c3c-af89-9791ee204e73',
    score: true,
    ap1: 8,
    ap2: 11,
    ap3: 0,
    sortOrder: 8399872
  },
  {
    id: 826,
    translationGuid: '86ee6e78-d58d-4d09-82a8-f991eefabae5',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.12',
    purposeTranslationGuid: '93026d7a-4d93-4537-a91c-ddaddea00981',
    score: true,
    ap1: 8,
    ap2: 12,
    ap3: 0,
    sortOrder: 8400896
  },
  {
    id: 827,
    translationGuid: '16c2d016-b765-4055-b753-c3047b131946',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.13',
    purposeTranslationGuid: 'a6eddf07-e848-49cb-aa30-e9978452371a',
    score: true,
    ap1: 8,
    ap2: 13,
    ap3: 0,
    sortOrder: 8401920
  },
  {
    id: 828,
    translationGuid: 'a248f36b-f163-4445-8d4c-8b44a9bd0dbe',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.14',
    purposeTranslationGuid: 'df2bd303-0ccb-4d79-9416-8d7f912be4ed',
    score: true,
    ap1: 8,
    ap2: 14,
    ap3: 0,
    sortOrder: 8402944
  },
  {
    id: 829,
    translationGuid: '20e05cc4-5fe5-4eb9-b636-d9e5e145bb83',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.15',
    purposeTranslationGuid: '3c34b1a5-4293-4be3-a809-8a22bf9ca250',
    score: true,
    ap1: 8,
    ap2: 15,
    ap3: 0,
    sortOrder: 8403968
  },
  {
    id: 830,
    translationGuid: '2c63c871-658c-42b7-af60-143e59985f67',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.16',
    purposeTranslationGuid: '5a9edb84-45b2-4bad-9c00-b1d2dac30dd2',
    score: true,
    ap1: 8,
    ap2: 16,
    ap3: 0,
    sortOrder: 8404992
  },
  {
    id: 831,
    translationGuid: '0db49221-ed79-4fee-8983-3d25fb236280',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.17',
    purposeTranslationGuid: 'cfe3220c-06bd-4d0d-b2e0-df70156ede7e',
    score: true,
    ap1: 8,
    ap2: 17,
    ap3: 0,
    sortOrder: 8406016
  },
  {
    id: 832,
    translationGuid: 'aa78f342-6fc5-481a-826d-971483211e07',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 69,
    note: null,
    auditPointNo: '8.18',
    purposeTranslationGuid: 'e885c17d-78cd-4adb-b754-235f05660482',
    score: false,
    ap1: 8,
    ap2: 18,
    ap3: 0,
    sortOrder: 8407040
  },
  {
    id: 833,
    translationGuid: 'e380383c-1f9a-40be-8f8f-bb1619c6eb28',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 70,
    note: null,
    auditPointNo: '9.1',
    purposeTranslationGuid: '03b29c81-2b72-466e-b36c-f9128f67c97a',
    score: true,
    ap1: 9,
    ap2: 1,
    ap3: 0,
    sortOrder: 9438208
  },
  {
    id: 834,
    translationGuid: 'd5abff8b-ff33-4aa9-aafe-639c6aca30ee',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 70,
    note: null,
    auditPointNo: '9.2',
    purposeTranslationGuid: '04812f5c-3fca-40b2-a987-f3f2e956ec7b',
    score: true,
    ap1: 9,
    ap2: 2,
    ap3: 0,
    sortOrder: 9439232
  },
  {
    id: 835,
    translationGuid: 'e521a846-bbf0-466f-8fc7-4cb87b93c595',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 70,
    note: null,
    auditPointNo: '9.3',
    purposeTranslationGuid: '0d7a23d2-4899-49f8-a440-b91dbb88674b',
    score: true,
    ap1: 9,
    ap2: 3,
    ap3: 0,
    sortOrder: 9440256
  },
  {
    id: 836,
    translationGuid: 'f62f29da-3289-402e-94fa-ef25d1fe1221',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 70,
    note: null,
    auditPointNo: '9.4',
    purposeTranslationGuid: '02cb24d5-9d6f-4cd8-b77c-620611eed913',
    score: false,
    ap1: 9,
    ap2: 4,
    ap3: 0,
    sortOrder: 9441280
  },
  {
    id: 837,
    translationGuid: '1c72cf16-cc23-4dc4-8f34-ca396260f799',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 70,
    note: null,
    auditPointNo: '9.5',
    purposeTranslationGuid: 'f84cec75-4c21-46f4-b21c-56d97d672a4b',
    score: true,
    ap1: 9,
    ap2: 5,
    ap3: 0,
    sortOrder: 9442304
  },
  {
    id: 838,
    translationGuid: 'a4dab652-f091-4a9f-98de-35f76806af6b',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 70,
    note: null,
    auditPointNo: '9.6',
    purposeTranslationGuid: 'd495a89c-6517-4520-9a79-f57a465f93b1',
    score: true,
    ap1: 9,
    ap2: 6,
    ap3: 0,
    sortOrder: 9443328
  },
  {
    id: 839,
    translationGuid: '0a934a6b-a8e0-464a-bac2-f6cd68276b14',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 70,
    note: null,
    auditPointNo: '9.7',
    purposeTranslationGuid: '35d259ac-2921-4611-9551-04561f3c781c',
    score: true,
    ap1: 9,
    ap2: 7,
    ap3: 0,
    sortOrder: 9444352
  },
  {
    id: 840,
    translationGuid: '77cdb33a-2b10-45bf-b2dd-60608b4fa246',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 70,
    note: null,
    auditPointNo: '9.8',
    purposeTranslationGuid: 'd89ddce0-44cf-49b6-9207-1d2bc574dcf8',
    score: true,
    ap1: 9,
    ap2: 8,
    ap3: 0,
    sortOrder: 9445376
  },
  {
    id: 841,
    translationGuid: '1f874a34-7ad2-42c6-aad4-7b421ae7579c',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 71,
    note: null,
    auditPointNo: '10.1',
    purposeTranslationGuid: '41210370-c114-4b7e-917b-77a6c53c915c',
    score: true,
    ap1: 10,
    ap2: 1,
    ap3: 0,
    sortOrder: 10486784
  },
  {
    id: 842,
    translationGuid: 'e0dac7b5-4c9d-49e4-832a-6be7c999b655',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 71,
    note: null,
    auditPointNo: '10.2',
    purposeTranslationGuid: 'd72a5a57-75b1-40fb-afe8-3caff6ad5c5f',
    score: true,
    ap1: 10,
    ap2: 2,
    ap3: 0,
    sortOrder: 10487808
  },
  {
    id: 843,
    translationGuid: '2537dc49-d9d6-4869-b67a-60026a393f1f',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 71,
    note: null,
    auditPointNo: '10.3',
    purposeTranslationGuid: 'a72d5034-5ee3-41b5-9983-60bb79573d78',
    score: false,
    ap1: 10,
    ap2: 3,
    ap3: 0,
    sortOrder: 10488832
  },
  {
    id: 844,
    translationGuid: '5c20b0e7-28d3-424c-9381-7f1937216ec5',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 71,
    note: null,
    auditPointNo: '10.4',
    purposeTranslationGuid: 'd5391c0f-1584-40f2-9cb1-b50dfa31236a',
    score: true,
    ap1: 10,
    ap2: 4,
    ap3: 0,
    sortOrder: 10489856
  },
  {
    id: 845,
    translationGuid: '29edf06c-755d-4263-9457-b0256a27eda8',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 71,
    note: null,
    auditPointNo: '10.5',
    purposeTranslationGuid: 'a8e8e3d7-54ea-4667-aaaf-0a141fc03a6a',
    score: true,
    ap1: 10,
    ap2: 5,
    ap3: 0,
    sortOrder: 10490880
  },
  {
    id: 846,
    translationGuid: 'cc170397-ec83-4696-8ba5-326393dfd493',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 71,
    note: null,
    auditPointNo: '10.6',
    purposeTranslationGuid: '01982e10-894b-4ea7-8e85-795a72365525',
    score: false,
    ap1: 10,
    ap2: 6,
    ap3: 0,
    sortOrder: 10491904
  },
  {
    id: 847,
    translationGuid: '99290bb8-4fbb-4ea5-8dd2-70306a151e30',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 71,
    note: null,
    auditPointNo: '10.7',
    purposeTranslationGuid: 'd484db20-fcdf-4990-a7dd-ae0d3f7a69e5',
    score: true,
    ap1: 10,
    ap2: 7,
    ap3: 0,
    sortOrder: 10492928
  },
  {
    id: 848,
    translationGuid: '1982c1fd-1985-4aa0-b60c-802c1e59f7b8',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 71,
    note: null,
    auditPointNo: '10.8',
    purposeTranslationGuid: 'ec76a24f-248e-435f-a627-e82049c527e1',
    score: false,
    ap1: 10,
    ap2: 8,
    ap3: 0,
    sortOrder: 10493952
  },
  {
    id: 849,
    translationGuid: 'd9ff131e-8e6c-4c67-9728-624cedc5cd3c',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 71,
    note: null,
    auditPointNo: '10.9',
    purposeTranslationGuid: '53572cbf-b0fc-4f0b-b895-180ac396db6e',
    score: true,
    ap1: 10,
    ap2: 9,
    ap3: 0,
    sortOrder: 10494976
  },
  {
    id: 850,
    translationGuid: '9678b3de-eb37-49c6-a08d-143a7eddce28',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.1',
    purposeTranslationGuid: 'ef17f387-4936-47b3-9f08-4e7508dac519',
    score: false,
    ap1: 11,
    ap2: 1,
    ap3: 0,
    sortOrder: 11535360
  },
  {
    id: 851,
    translationGuid: 'e34ec6a5-2db5-4a01-9161-fbdbefd5c2cb',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.2',
    purposeTranslationGuid: 'f1ec40f1-355c-4fe6-bf29-e5e86da7daca',
    score: true,
    ap1: 11,
    ap2: 2,
    ap3: 0,
    sortOrder: 11536384
  },
  {
    id: 852,
    translationGuid: '6b4120f4-e1ac-43d6-ae91-4e69f48b7d8a',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.3',
    purposeTranslationGuid: '0a9d8564-728f-4924-bac2-55fd2431f515',
    score: true,
    ap1: 11,
    ap2: 3,
    ap3: 0,
    sortOrder: 11537408
  },
  {
    id: 853,
    translationGuid: 'b09d77af-84bc-4c23-b258-035ccca0f662',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 72,
    note: 'PARTS: 4,19%  on reguleeritud - väga hästi',
    auditPointNo: '11.4',
    purposeTranslationGuid: '164d3a45-6964-4eb7-aa01-08bbefe64ba5',
    score: true,
    ap1: 11,
    ap2: 4,
    ap3: 0,
    sortOrder: 11538432
  },
  {
    id: 854,
    translationGuid: '948a7907-0a7f-4096-bab9-0708fd0bc856',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.5',
    purposeTranslationGuid: 'dfb1686c-04c8-4e86-b1d8-2ab862302775',
    score: true,
    ap1: 11,
    ap2: 5,
    ap3: 0,
    sortOrder: 11539456
  },
  {
    id: 855,
    translationGuid: '36f45b7e-6309-4eed-bec5-210b4bd1c02d',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.6',
    purposeTranslationGuid: 'c469ba12-c1d4-430e-95a9-941515372517',
    score: true,
    ap1: 11,
    ap2: 6,
    ap3: 0,
    sortOrder: 11540480
  },
  {
    id: 856,
    translationGuid: 'bf51ced4-1c09-4e0c-8c99-3a05194dbe38',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.7',
    purposeTranslationGuid: '0674011e-8057-41ff-9f38-c471dc0c954d',
    score: true,
    ap1: 11,
    ap2: 7,
    ap3: 0,
    sortOrder: 11541504
  },
  {
    id: 857,
    translationGuid: '31e37a8c-35c4-423e-b8d4-a2f970189636',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.8',
    purposeTranslationGuid: '27805a7f-4e19-4033-ad14-72439a103a68',
    score: true,
    ap1: 11,
    ap2: 8,
    ap3: 0,
    sortOrder: 11542528
  },
  {
    id: 858,
    translationGuid: 'a66638a1-5170-412c-a706-50abc300b417',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.9',
    purposeTranslationGuid: '27463a4a-be7b-4d61-8eeb-76dd1ad1a033',
    score: true,
    ap1: 11,
    ap2: 9,
    ap3: 0,
    sortOrder: 11543552
  },
  {
    id: 859,
    translationGuid: 'ac692f73-cd0f-44e1-8bda-63743aaa4805',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.10',
    purposeTranslationGuid: '584c6587-f4a5-4838-81d9-0e87ed1ab963',
    score: true,
    ap1: 11,
    ap2: 10,
    ap3: 0,
    sortOrder: 11544576
  },
  {
    id: 860,
    translationGuid: '9967a93e-17ae-4b6a-84db-a8332d9e5a7e',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.11',
    purposeTranslationGuid: '7141c817-5284-4272-8d8f-0e7051efbc46',
    score: true,
    ap1: 11,
    ap2: 11,
    ap3: 0,
    sortOrder: 11545600
  },
  {
    id: 861,
    translationGuid: '0e78d24a-d2b3-4b89-a250-d54085b469c1',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.12',
    purposeTranslationGuid: '92e2af74-c4af-4bc7-936f-52b81ced705e',
    score: true,
    ap1: 11,
    ap2: 12,
    ap3: 0,
    sortOrder: 11546624
  },
  {
    id: 862,
    translationGuid: '92058328-94c9-47c9-881f-0b1ff9b6d4e9',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.13',
    purposeTranslationGuid: '30293a55-8a61-4ecb-a001-4364cf65c7e8',
    score: true,
    ap1: 11,
    ap2: 13,
    ap3: 0,
    sortOrder: 11547648
  },
  {
    id: 863,
    translationGuid: 'ce6980a6-f95b-4981-8527-b5b704e9f1d5',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 72,
    note: 'Öist dkohaletoimetamist ei ole ühelgi Eesti osakonnal.',
    auditPointNo: '11.14',
    purposeTranslationGuid: '1052669d-03ee-4814-8a90-3313827ed0a4',
    score: true,
    ap1: 11,
    ap2: 14,
    ap3: 0,
    sortOrder: 11548672
  },
  {
    id: 864,
    translationGuid: '2b87a6ae-cbed-4c99-879b-032b9f62f576',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.15',
    purposeTranslationGuid: 'b1b77aff-a94c-480b-92fb-fc365090b4b6',
    score: true,
    ap1: 11,
    ap2: 15,
    ap3: 0,
    sortOrder: 11549696
  },
  {
    id: 865,
    translationGuid: '2254c699-fb59-4b1b-9674-5b6f40c9f988',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.16',
    purposeTranslationGuid: '96338122-0c37-465b-9967-4d54ccba3c3a',
    score: true,
    ap1: 11,
    ap2: 16,
    ap3: 0,
    sortOrder: 11550720
  },
  {
    id: 866,
    translationGuid: '271b4a0b-3a76-42bf-baa6-719f288e7fe0',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.17',
    purposeTranslationGuid: 'cd3989bd-0ba7-48a8-b20e-0860ec00eace',
    score: false,
    ap1: 11,
    ap2: 17,
    ap3: 0,
    sortOrder: 11551744
  },
  {
    id: 867,
    translationGuid: '03befe07-5e9d-4c87-95d7-fd61de0a47bd',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.18',
    purposeTranslationGuid: '3f6122de-e6a8-4710-84fa-86eb1e4d1c67',
    score: false,
    ap1: 11,
    ap2: 18,
    ap3: 0,
    sortOrder: 11552768
  },
  {
    id: 868,
    translationGuid: 'a1e46c3f-9b95-4b22-aad6-f67ed0367e47',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.19',
    purposeTranslationGuid: 'ab16687b-1919-4f28-b0ee-92dcbd5d34e0',
    score: true,
    ap1: 11,
    ap2: 19,
    ap3: 0,
    sortOrder: 11553792
  },
  {
    id: 869,
    translationGuid: '6dda69ea-8e83-4dda-9e68-f7d49fa9b521',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 72,
    note: null,
    auditPointNo: '11.20',
    purposeTranslationGuid: '894bc7df-4e52-4392-a9e3-b3881f4970ae',
    score: false,
    ap1: 11,
    ap2: 20,
    ap3: 0,
    sortOrder: 11554816
  },
  {
    id: 870,
    translationGuid: '214e3ece-3276-46d3-bf01-3d1bd0a37180',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 73,
    note: null,
    auditPointNo: '12.1',
    purposeTranslationGuid: '6bad0c10-db8a-4a5a-bef7-76ab7264592e',
    score: false,
    ap1: 12,
    ap2: 1,
    ap3: 0,
    sortOrder: 12583936
  },
  {
    id: 871,
    translationGuid: '7b415d90-12a4-43ba-9d3b-0f834f3fb6fe',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 73,
    note: null,
    auditPointNo: '12.2',
    purposeTranslationGuid: '43e40b42-cae4-4173-bb99-1832b4af84a3',
    score: true,
    ap1: 12,
    ap2: 2,
    ap3: 0,
    sortOrder: 12584960
  },
  {
    id: 872,
    translationGuid: '7f1f7f9c-7497-432f-85fd-864ee6389052',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 73,
    note: null,
    auditPointNo: '12.3',
    purposeTranslationGuid: 'a5899234-9298-43a1-89cb-adfd7e7ca186',
    score: true,
    ap1: 12,
    ap2: 3,
    ap3: 0,
    sortOrder: 12585984
  },
  {
    id: 873,
    translationGuid: '805d82f9-a10b-403f-a4ac-33f836e9d972',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 73,
    note: null,
    auditPointNo: '12.4',
    purposeTranslationGuid: '9102ceed-ce5e-444d-b5bc-b83ce7d1f036',
    score: true,
    ap1: 12,
    ap2: 4,
    ap3: 0,
    sortOrder: 12587008
  },
  {
    id: 874,
    translationGuid: 'bfadc9d9-96fd-4f3b-a396-8ea1eb91539c',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 73,
    note: null,
    auditPointNo: '12.5',
    purposeTranslationGuid: '540c0407-28ff-4e2c-924b-3da438e309ba',
    score: false,
    ap1: 12,
    ap2: 5,
    ap3: 0,
    sortOrder: 12588032
  },
  {
    id: 875,
    translationGuid: '10f758eb-9697-46d6-bd71-13783e93e4bd',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 74,
    note: null,
    auditPointNo: '13.1',
    purposeTranslationGuid: 'cdd327e3-d6cd-4068-80e8-ed1d4016e2c2',
    score: true,
    ap1: 13,
    ap2: 1,
    ap3: 0,
    sortOrder: 13632512
  },
  {
    id: 876,
    translationGuid: 'ffed9c64-a622-41f7-a71b-9b48689e700e',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 74,
    note: null,
    auditPointNo: '13.2',
    purposeTranslationGuid: '4dfc8255-ca03-4d42-96dd-b9b286bb526b',
    score: true,
    ap1: 13,
    ap2: 2,
    ap3: 0,
    sortOrder: 13633536
  },
  {
    id: 877,
    translationGuid: '36c67905-1d5a-490c-b5d7-3edfb738f0d4',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 74,
    note: null,
    auditPointNo: '13.3',
    purposeTranslationGuid: '671de023-196e-4121-a32c-6f24066e7e4e',
    score: true,
    ap1: 13,
    ap2: 3,
    ap3: 0,
    sortOrder: 13634560
  },
  {
    id: 878,
    translationGuid: 'ff28ca97-dd79-4afc-a2cf-bed055b76f22',
    isMandatory: false,
    pledgeId: 74,
    note: 'Hetkel teostatakse arenguvestlusi',
    auditPointNo: '13.4',
    purposeTranslationGuid: 'a972f78d-49c2-4f2a-9e1f-cb05010561af',
    score: true,
    ap1: 13,
    ap2: 4,
    ap3: 0,
    sortOrder: 13635584
  },
  {
    id: 879,
    translationGuid: '4672b1d9-9d4f-4b68-870c-9c0c5fbaea47',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 74,
    note: null,
    auditPointNo: '13.5',
    purposeTranslationGuid: 'd268c2ba-606e-4024-8bf7-112d0541e03c',
    score: true,
    ap1: 13,
    ap2: 5,
    ap3: 0,
    sortOrder: 13636608
  },
  {
    id: 880,
    translationGuid: '720094cf-f66f-42cc-850a-0fdc0ab8c499',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 74,
    note: null,
    auditPointNo: '13.6',
    purposeTranslationGuid: 'ccc17e75-b218-43f7-bcb9-1fb013a42a89',
    score: true,
    ap1: 13,
    ap2: 6,
    ap3: 0,
    sortOrder: 13637632
  },
  {
    id: 881,
    translationGuid: '11d24f62-b654-410e-96de-926259161241',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 74,
    note: null,
    auditPointNo: '13.7',
    purposeTranslationGuid: '439759dd-a385-497c-89f2-87ced3328d2d',
    score: true,
    ap1: 13,
    ap2: 7,
    ap3: 0,
    sortOrder: 13638656
  },
  {
    id: 882,
    translationGuid: 'a46524d9-9300-4b7e-8ed5-5041b875d977',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 75,
    note: null,
    auditPointNo: '14.1',
    purposeTranslationGuid: 'da7f2a33-cf95-4da4-999b-a2f2997a1ee6',
    score: false,
    ap1: 14,
    ap2: 1,
    ap3: 0,
    sortOrder: 14681088
  },
  {
    id: 883,
    translationGuid: 'fc5719b1-213f-4a07-be4e-b967543f9d52',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 75,
    note: null,
    auditPointNo: '14.2',
    purposeTranslationGuid: '8faf4237-2935-4681-b48c-f28571f5094a',
    score: true,
    ap1: 14,
    ap2: 2,
    ap3: 0,
    sortOrder: 14682112
  },
  {
    id: 884,
    translationGuid: 'f2bc156d-d86d-4642-998d-b5bd8854d2c3',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 75,
    note: null,
    auditPointNo: '14.3',
    purposeTranslationGuid: '3b8187fb-64ef-4f33-a84d-c7e2b0f3adb1',
    score: true,
    ap1: 14,
    ap2: 3,
    ap3: 0,
    sortOrder: 14683136
  },
  {
    id: 885,
    translationGuid: 'b29349d4-d313-465f-91e1-59a1521b861b',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 75,
    note: null,
    auditPointNo: '14.4',
    purposeTranslationGuid: 'fb51532f-9cd1-4ddf-b56b-ec1e86bed83e',
    score: true,
    ap1: 14,
    ap2: 4,
    ap3: 0,
    sortOrder: 14684160
  },
  {
    id: 886,
    translationGuid: '2bae4599-eb41-447f-b747-ffca7d043c08',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 75,
    note: null,
    auditPointNo: '14.5',
    purposeTranslationGuid: '38d58a42-ae2b-48e9-a0be-2e779bbda5f3',
    score: false,
    ap1: 14,
    ap2: 5,
    ap3: 0,
    sortOrder: 14685184
  },
  {
    id: 887,
    translationGuid: 'ad3078dd-4866-4d88-b53a-0e0ca5a9bdcf',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 75,
    note: null,
    auditPointNo: '14.6',
    purposeTranslationGuid: '003b6ed7-a372-48df-80d0-968992fb102c',
    score: false,
    ap1: 14,
    ap2: 6,
    ap3: 0,
    sortOrder: 14686208
  },
  {
    id: 888,
    translationGuid: 'a37dcace-c114-4852-bcd7-ea50eeff18b0',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 75,
    note: null,
    auditPointNo: '14.7',
    purposeTranslationGuid: 'f26a48a9-9afb-47c9-ab96-f7275a4cb17e',
    score: false,
    ap1: 14,
    ap2: 7,
    ap3: 0,
    sortOrder: 14687232
  },
  {
    id: 889,
    translationGuid: '034fc57e-052c-4c0e-8617-fb60053c595e',
    isPointCheckedYes: false,
    isMandatory: true,
    pledgeId: 75,
    note: null,
    auditPointNo: '14.8',
    purposeTranslationGuid: 'fa785657-e457-441c-85aa-0727d894394a',
    score: false,
    ap1: 14,
    ap2: 8,
    ap3: 0,
    sortOrder: 14688256
  },
  {
    id: 890,
    translationGuid: '237a3e31-70f2-4862-80e5-34c6fcc5e9b4',
    isPointCheckedYes: false,
    isMandatory: false,
    pledgeId: 75,
    note: null,
    auditPointNo: '14.9',
    purposeTranslationGuid: '183ec77b-0e19-4218-aff6-525ab41576c0',
    score: false,
    ap1: 14,
    ap2: 9,
    ap3: 0,
    sortOrder: 14689280
  },
  {
    id: 891,
    translationGuid: '9e6d66d8-897e-4347-8493-842f4c814584',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 75,
    note: null,
    auditPointNo: '14.10',
    purposeTranslationGuid: '76bf7fec-1486-49e1-92bf-744522bf8f55',
    score: true,
    ap1: 14,
    ap2: 10,
    ap3: 0,
    sortOrder: 14690304
  },
  {
    id: 892,
    translationGuid: '25348799-c45f-4e2b-99a0-21dd97514df0',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 75,
    note: null,
    auditPointNo: '14.11',
    purposeTranslationGuid: 'ef81eeae-d123-46df-88d9-f495805632b5',
    score: true,
    ap1: 14,
    ap2: 11,
    ap3: 0,
    sortOrder: 14691328
  },
  {
    id: 893,
    translationGuid: '8235b6cf-c0b1-42b5-b0e3-7a16babe6e4d',
    isPointCheckedYes: true,
    isMandatory: false,
    pledgeId: 75,
    note: null,
    auditPointNo: '14.12',
    purposeTranslationGuid: '591ddcc6-c945-44c3-bc80-d3229f6fb50a',
    score: true,
    ap1: 14,
    ap2: 12,
    ap3: 0,
    sortOrder: 14692352
  },
  {
    id: 894,
    translationGuid: '7183c0e9-ac37-4551-8e19-2ba614639e99',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 76,
    note: null,
    auditPointNo: '15.1',
    purposeTranslationGuid: 'a7648ce1-b49b-487b-b06b-6c9045cb077c',
    score: true,
    ap1: 15,
    ap2: 1,
    ap3: 0,
    sortOrder: 15729664
  },
  {
    id: 895,
    translationGuid: 'bef3fa33-d9f7-4743-a6f6-11c8814c8e18',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 76,
    note: null,
    auditPointNo: '15.2',
    purposeTranslationGuid: 'e9768a05-6dee-48ce-b1ef-036758569f20',
    score: true,
    ap1: 15,
    ap2: 2,
    ap3: 0,
    sortOrder: 15730688
  },
  {
    id: 896,
    translationGuid: '7b697ccb-6a16-4bb8-a4fc-026ab50848c1',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 76,
    note: null,
    auditPointNo: '15.3',
    purposeTranslationGuid: 'fc0b1bd2-09e2-4d83-b7d9-632516fddff7',
    score: true,
    ap1: 15,
    ap2: 3,
    ap3: 0,
    sortOrder: 15731712
  },
  {
    id: 897,
    translationGuid: '6a5ab932-439b-4b5c-9951-c93c1aedeeb9',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 76,
    note: null,
    auditPointNo: '15.4',
    purposeTranslationGuid: '7b2fb89b-9252-45b2-8ae1-3bd8b8f95e3c',
    score: true,
    ap1: 15,
    ap2: 4,
    ap3: 0,
    sortOrder: 15732736
  },
  {
    id: 898,
    translationGuid: '16b79e04-0463-4a62-a900-861b1b574543',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 76,
    note: null,
    auditPointNo: '15.5',
    purposeTranslationGuid: 'b3fa8932-d907-4109-bc99-f375049c46c0',
    score: true,
    ap1: 15,
    ap2: 5,
    ap3: 0,
    sortOrder: 15733760
  },
  {
    id: 899,
    translationGuid: '9b1c6961-eea9-4d24-81c4-5544da6fb2e0',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 76,
    note: null,
    auditPointNo: '15.6',
    purposeTranslationGuid: '717d155c-5ed3-451c-bb2b-b5bbe3a4db87',
    score: true,
    ap1: 15,
    ap2: 6,
    ap3: 0,
    sortOrder: 15734784
  },
  {
    id: 900,
    translationGuid: '69a83705-8470-4867-88aa-a82069f892bc',
    isPointCheckedYes: true,
    isMandatory: true,
    pledgeId: 76,
    note: null,
    auditPointNo: '15.7',
    purposeTranslationGuid: '99c182e8-abab-4aee-9f42-0d7f780b7cae',
    score: true,
    ap1: 15,
    ap2: 7,
    ap3: 0,
    sortOrder: 15735808
  }
].map(a => ({ ...a }))
