import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import type {
  CreateDeviationOperation,
  Operation
} from '@cdab/scania/qpr/offline/models'
import * as sentry from '@sentry/react'

import { getLocalDeviation } from '@cdab/scania/qpr/offline/models'
import {
  cacheOperation,
  confirmOperation,
  removeCachedOperation
} from '@cdab/scania/qpr/offline/cache'
import { QprHubError } from './qpr-hub-error'
import invariant from 'tiny-invariant'

type OperationGuid = Operation['guid']

type HandleNewOperationConfig = {
  skipSend?: boolean
}

type OperationsState =
  | 'waiting-to-retry'
  | 'in-flight'
  | 'waiting-to-be-started'
  | 'awaiting-broadcast'

type StatefulOperation = {
  operation: Operation
  state: OperationsState
}

/**
 * TODO: This class should probably be rewritten into two. One that handles sending operations, and keeping track
 * of their status. And this class, which should manage what should happen when (what happens onConfirm, for example).
 */
class OperationsController {
  private statefulOperations = new Map<OperationGuid, StatefulOperation>()

  private sendNewOperation = async (operation: Operation) => {
    const { guid } = operation

    let statefulOperation = this.statefulOperations.get(guid)

    const casesWeWantToContinue =
      !statefulOperation ||
      statefulOperation.state === 'waiting-to-retry' ||
      statefulOperation.state === 'waiting-to-be-started'

    if (!casesWeWantToContinue) {
      return
    }

    if (!statefulOperation) {
      statefulOperation = {
        operation,
        state: 'in-flight'
      }
    } else {
      statefulOperation.state = 'in-flight'
    }
    this.statefulOperations.set(guid, statefulOperation)

    const client = getClient()
    let success = false
    try {
      success = await operation.SendOperation(client)

      if (success) {
        this.confirmOperation(operation)

        statefulOperation.state = 'awaiting-broadcast'
        this.statefulOperations.set(guid, statefulOperation)
      } else {
        this.rollbackOperation(operation)

        this.statefulOperations.delete(guid)
      }
    } catch (error) {
      console.warn('error with sending operation', error)
      const isHubError = QprHubError.isQprHubError(error)

      if (isHubError) {
        // Operation was not successful, but should not be retried. Rollback
        this.rollbackOperation(operation)
        this.statefulOperations.delete(guid)
      } else {
        statefulOperation.state = 'waiting-to-retry'
        this.statefulOperations.set(guid, statefulOperation)
        this.retryOperations([operation])
      }
      return
    }
  }

  public handleNewOperation = async (
    operation: Operation,
    config?: HandleNewOperationConfig
  ) => {
    const hasOperation = !!this.findOperation(op => op.guid === operation.guid)
    if (hasOperation) return

    operation.Apply()
    try {
      await cacheOperation(operation)
    } catch (error) {
      sentry.captureException(error)
      alert(
        'There was an error caching a change. Some updates could be lost on reload!'
      )
      console.warn('error caching operation!!!!', operation, error)
    }

    if (config?.skipSend) {
      const statefulOperation: StatefulOperation = {
        operation,
        state: 'waiting-to-be-started'
      }
      this.statefulOperations.set(operation.guid, statefulOperation)
    } else {
      await this.sendNewOperation(operation)
    }
  }

  public cancelOperation = (operationGuid: OperationGuid) => {
    const statefulOperation = this.statefulOperations.get(operationGuid)

    if (!statefulOperation) return

    this.rollbackOperation(statefulOperation.operation)
    this.statefulOperations.delete(operationGuid)

    removeCachedOperation(statefulOperation.operation)
  }

  private rollbackOperation = (operation: Operation) => {
    // We should probably compare with the current value and make sure it's equal to the operation's previous value.
    // Otherwise we might override a value that has been changed after this operations was sent
    operation.Rollback()

    removeCachedOperation(operation)
  }

  public retryOperations = (operations: Operation[]) => {
    // We might want a cache/queue of sorts here, so if we have this guid in the cache, we don't have to add it again
    const RETRY_TIMER_MS = 5 * 1000

    setTimeout(() => {
      operations.forEach(op => {
        const statefulOperations = this.statefulOperations.get(op.guid)

        if (!statefulOperations) return // It could've been canceled

        this.sendNewOperation(op)
      })
    }, RETRY_TIMER_MS)
  }

  public handleIncomingOperation = async (operation: Operation) => {
    const didSendOperation = this.statefulOperations.has(operation.guid)

    if (didSendOperation) {
      this.statefulOperations.delete(operation.guid)
      return
    }

    // Should we ever handle the situation where operation.previousValue is not equal to our current value?
    // Then, we have changes locally that the incoming operation didn't know about.
    // If we should handle it, should we do it inside apply? So that we ALWAYS do it when we apply an operation?
    operation.Apply()
  }

  private confirmOperation = async (operation: Operation) => {
    await confirmOperation(operation)
    await this.onOperationComplete(operation)
  }

  private onOperationComplete = async (operation: Operation) => {
    let op
    switch (operation.data.type) {
      case 'create-deviation':
        {
          op = operation as CreateDeviationOperation

          const deviation = getLocalDeviation(
            op.auditId,
            op.data.value.clientGuid
          )

          invariant(
            deviation.id,
            `No deviation id found for _confirmed_ "create-deviation" operation!`
          )

          const hasCreateDeviation = (deviationClientGuid: string) => {
            return !!Array.from(this.statefulOperations.values()).find(
              operation =>
                operation.operation.Type === 'create-deviation' &&
                (operation.operation as CreateDeviationOperation).data.value
                  .clientGuid === deviationClientGuid
            )
          }

          Array.from(this.statefulOperations.values())
            .filter(({ state }) => state === 'waiting-to-be-started')
            .forEach(({ operation }) => {
              // We want to start all operations of certain types AND which belongs to this (newly created) deviation
              if (
                operation.data.type === 'add-deviation-file' &&
                operation.data.value.deviationClientGuid ===
                  deviation.clientGuid &&
                !hasCreateDeviation(deviation.clientGuid)
              ) {
                this.sendNewOperation(operation)
              }

              if (
                operation.data.type === 'update-deviation' &&
                operation.data.value.clientGuid === deviation.clientGuid &&
                !hasCreateDeviation(deviation.clientGuid)
              ) {
                this.sendNewOperation(operation)
              }
            })
        }
        break

      case 'delete-deviation':
        // TODO: Delete all cached files for this devation
        break

      default:
        break
    }
  }

  public findOperation = (
    predicate: (operation: Operation) => boolean
  ): Operation | undefined => {
    const statefulOperationsArray = Array.from(this.statefulOperations.values())

    const statefulOperation = statefulOperationsArray.find(({ operation }) =>
      predicate(operation)
    )

    return statefulOperation?.operation
  }
}

export const operationsController = new OperationsController()
