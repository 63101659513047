import styled from 'styled-components'

import { Divider } from '../divider'
import type { BlockProps } from './block.types'

export const StyledBlock = styled.div<Pick<BlockProps, 'disablePadding'>>`
  ${({ disablePadding }) => disablePadding && 'padding: 0;'};
`

export const StyledDivider = styled(Divider)`
  margin: var(--sdds-spacing-layout-16) 0;
`

export const Header = styled.h6.attrs({
  className: 'sdds-headline-06'
})`
  margin: 0;
`
