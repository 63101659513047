import styled from 'styled-components'

export const Navigation = styled.nav.attrs<{ className: string }>({
  className: 'sdds-nav sdds-nav__sidemenu'
})<{ className: string }>`
  border-bottom: 1px solid var(--sdds-blue-700);
`

export const LeftNavigation = styled.div.attrs({
  className: 'sdds-nav__left'
})``

export const AppName = styled.div.attrs({
  className: 'sdds-nav__app-name'
})`
  flex: 1;
`

export const DesktopTitle = styled.span``

export const MobileTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const MobileTitle = styled.span``

export const MobileDescription = styled.span`
  font-size: 10px;
`

export const Overlay = styled.div.attrs({
  className: 'nav__overlay'
})``

export const RightNavigation = styled.div.attrs({
  className: 'sdds-nav__right'
})``

export const ProfileMenu = styled.ul.attrs({
  className: 'sdds-nav__toolbar-menu'
})`
  z-index: 1;
`

export const Logo = styled.div.attrs({
  className: 'sdds-nav__item sdds-nav__app-logo'
})``
