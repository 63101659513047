import type {
  AdminReferenceDocument,
  Audit,
  AuditPoint,
  Pledge,
  SystemMessage,
  User
} from '@cdab/scania/qpr/schema'
import { isNumber } from '@cdab/utils'
import type { Params } from 'react-router'
import { json } from 'react-router'

function throw400error(message: string) {
  throw json(
    {
      message
    },
    { status: 400 }
  )
}

type Config = {
  optional?: boolean
}

export function getAuditIdFromParams(
  params: Params,
  config?: Config
): Audit['id'] {
  const auditId = Number.parseInt(params['auditId'] || 'NaN')

  if (!config?.optional && !isNumber(auditId)) {
    throw400error(
      `auditPointLoader: auditId must be a number! auditId = ${auditId}`
    )
  }

  return auditId
}

export function getAuditPointIdFromParams(
  params: Params,
  config?: Config
): AuditPoint['id'] {
  const auditPointId = Number.parseInt(params['auditPointId'] || 'NaN')

  if (!config?.optional && !isNumber(auditPointId)) {
    throw400error(
      `auditPointLoader: auditPointId must be a number! auditPointId = ${auditPointId}`
    )
  }

  return auditPointId
}

export function getPledgeIdFromParams(
  params: Params,
  config?: Config
): Pledge['id'] {
  const pledgeId = Number.parseInt(params['pledgeId'] || 'NaN')

  if (!config?.optional && !isNumber(pledgeId)) {
    throw400error(
      `auditPointLoader: pledgeId must be a number! pledgeId = ${pledgeId}`
    )
  }

  return pledgeId
}

export function getMessageIdFromParams(
  params: Params,
  config?: Config
): SystemMessage['id'] {
  const messageId = Number.parseInt(params['messageId'] ?? 'NaN')

  if (!config?.optional && !isNumber(messageId)) {
    throw400error(
      `adminSystemMessageLoader: messageId must be a number! messageId = ${messageId}`
    )
  }

  return messageId
}

export function getUserIdFromParams(
  params: Params,
  config?: Config
): User['userId'] {
  const userId = Number.parseInt(params['userId'] ?? 'NaN')

  if (!config?.optional && !isNumber(userId)) {
    throw400error(
      `adminUsersLoader: userId must be a number! userId = ${userId}`
    )
  }

  return userId
}

export function getDocumentIdFromParams(
  params: Params,
  config?: Config
): AdminReferenceDocument['id'] {
  const documentId = Number.parseInt(params['documentId'] ?? 'NaN')

  if (!config?.optional && !isNumber(documentId)) {
    throw400error(
      `adminDocumentLoader: documentId must be a number! documentId = ${documentId}`
    )
  }

  return documentId
}

export function getAdminTranslationsTypeFromParams(
  params: Params,
  config?: Config
): string {
  const type = params['translationsType'] ?? ''

  if (!config?.optional && !(typeof type == 'string')) {
    throw400error(
      `adminTranslationsLoader: translation type must be a string! type = ${type}`
    )
  }

  return type
}

export function getAdminTranslationsLanguageIdFromParams(
  params: Params,
  config?: Config
): number {
  const languageId = Number.parseInt(params['languageId'] ?? 'NaN')

  if (!config?.optional && !isNumber(languageId)) {
    throw400error(
      `adminTranslationsLoader: languageId must be a number! languageId = ${languageId}`
    )
  }

  return languageId
}

export function getAdminTranslationsDOSVersionIdFromParams(
  params: Params,
  config?: Config
): number {
  const dosVersionId = Number.parseInt(params['dosVersionId'] ?? 'NaN')
  if (!config?.optional && !isNumber(dosVersionId)) {
    throw400error(
      `adminTranslationsLoader: dosVersionId must be a number! dosVersionId = ${dosVersionId}`
    )
  }

  return dosVersionId
}

export function getReportTypeFromParams(
  params: Params,
  config?: Config
): string {
  const type = params['reportType'] ?? ''

  if (!config?.optional && !(typeof type == 'string')) {
    throw400error(`reportsLoader: report type must be a string! type = ${type}`)
  }

  return type
}

export function getADHLinkFromParams(params: Params, config?: Config): string {
  const link = params['linkId'] ?? ''

  if (!config?.optional && typeof link !== 'string') {
    throw json({ status: 404 })
  }

  return link
}

export function getDeviaionIdFromParams(
  params: Params,
  config?: Config
): string {
  const deviationId = params['deviationId'] ?? ''

  if (!config?.optional && typeof deviationId !== 'string') {
    throw json({ status: 404 })
  }

  return deviationId
}
