export enum AuditItemState {
  NotSet = -1,
  No = 0,
  Yes = 1,
  NotComplete = 2
}
export function scoreToAuditItemState(score: boolean | null): AuditItemState {
  switch (score) {
    case true:
      return AuditItemState.Yes

    case false:
      return AuditItemState.No

    case null: // fallthrough
    default:
      return AuditItemState.NotSet
  }
}
export function auditItemStateToScore(state: AuditItemState): boolean | null {
  switch (state) {
    case AuditItemState.No:
      return false

    case AuditItemState.NotSet: // fallthrough
    case AuditItemState.NotComplete:
      return null

    case AuditItemState.Yes:
      return true
  }
}

export enum AuditItemType {
  Normal,
  Header,
  MajorSubheader,
  MinorSubheader
}

export enum ArrowType {
  Hidden,
  Up,
  Right,
  Down
}

export interface AuditItemProps {
  children: React.ReactNode
  prefix?: string | null
  value?: AuditItemState
  hideButton?: boolean
  onChange?: (newAuditItemState: AuditItemState) => void
  defaultValue?: AuditItemState
  onClick?: (e: React.MouseEvent, isLongPress: boolean) => void
  className?: string
  type?: AuditItemType
  fullWidth?: boolean
  selected?: boolean
  isMandatory?: boolean
  arrowType?: ArrowType
  maxLines?: number
  id?: string
  disableNo?: boolean
  disabled?: boolean
  rightItem?: JSX.Element
  fullChildren?: JSX.Element
  isBottomNavigationItem?: boolean
}
