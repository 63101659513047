import {
  CenteredContainer,
  ClickableListItem,
  ElasticContentContainer
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useUser } from '@cdab/scania/qpr/contexts/user-provider'
import { getEnvVarsQPR } from '@cdab/scania/qpr/env-vars'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { Roles, type Language } from '@cdab/scania/qpr/schema'
import {
  Column,
  Container,
  Dropdown,
  DropdownOption,
  EmptyScreen,
  IconWarning,
  Row
} from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  isRouteErrorResponse,
  json,
  useLoaderData,
  useNavigate,
  useRouteError
} from 'react-router'
import {
  StyledClickableList,
  StyledEndRow,
  StyledFirstIcon,
  StyledFirstIconButton,
  StyledFlexColumn,
  StyledIcon,
  StyledIconButton,
  StyledMainRow,
  Title
} from './base.styles'

export type AdminTranslationBaseLoader = {
  languages: Language[]
  templateUrl: string | undefined
}

export async function loader(): Promise<AdminTranslationBaseLoader> {
  const client = getClient()
  const { translationTemplateUrl } = getEnvVarsQPR()

  const userInfo = await client.GetuserInfo()
  if (!userInfo) {
    throw json({
      status: 401
    })
  }

  try {
    const [languages, templateUrl] = await Promise.all([
      client.UserService.GetLanguagesForUser(true),
      client.StorageService.GetFileUrl(translationTemplateUrl)
    ])
    return {
      languages,
      templateUrl
    }
  } catch (error) {
    if (error instanceof Response) {
      throw new Response(null, {
        status: error.status
      })
    } else {
      throw new Response(null, {
        status: 404
      })
    }
  }
}

const defaultDOSVersionId = 6

export function Page() {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { updateTitles } = useTitle()
  const userContext = useUser()
  const isxLg = useCssVariableBreakpoint('--sdds-grid-xlg')
  const { languages, templateUrl } =
    useLoaderData() as AdminTranslationBaseLoader

  const showCheckpointCreateButton =
    userContext.userData.role > Roles.CoOrdinator

  const onDownloadTranslationTemplateClick = useCallback(async () => {
    if (templateUrl) {
      window.open(templateUrl, '_self')
    }
  }, [templateUrl])

  const [dosVersionState, setDosVersionState] = useState(
    defaultDOSVersionId.toString()
  )

  const onLanguageListClick = useCallback(
    (languageId: number) => {
      if (languageId) {
        navigate(`/admin/translations/${dosVersionState}/${languageId}`)
      }
    },
    [dosVersionState, navigate]
  )

  const onCheckpointNewClick = useCallback(() => {
    navigate(`/admin/checkpoints`)
  }, [navigate])

  useEffect(() => {
    updateTitles({
      contentHeader: {
        subtitle: t('translations', { ns: 'common' }),
        title: t('administration', { ns: 'common' })
      },
      mobile: {
        title: `${t('translations', { ns: 'common' })}`,
        description: null
      }
    })
  }, [t])

  return (
    <ElasticContentContainer scrollY overflowHidden>
      <Container
        fullHeight
        fluid='normal'
        paddingOnColumns={isxLg}
        paddingOnContainer
      >
        <StyledMainRow>
          <Column width={12} lg={isxLg ? 6 : 12} padding={isxLg}>
            <StyledFlexColumn>
              <Title>{t('languages', { ns: 'common' })}</Title>
            </StyledFlexColumn>
            <StyledFlexColumn>
              <StyledClickableList scrollable>
                {languages
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(language => {
                    return (
                      <ClickableListItem
                        key={language.id}
                        title={language.name}
                        onClick={() => onLanguageListClick(language.id)}
                      />
                    )
                  })}
              </StyledClickableList>
            </StyledFlexColumn>
          </Column>
          <Column width={12} lg={isxLg ? 6 : 12} padding={isxLg}>
            <StyledEndRow>
              <Column width={12} lg={isxLg ? 8 : 12} padding={false}>
                <Row>
                  <Title>
                    {capitalizeFirstLetter(t('actions', { ns: 'common' }))}
                  </Title>
                </Row>
                <StyledFlexColumn>
                  <Dropdown
                    id='dosVersionId'
                    label={t('dos-version', { ns: 'common' })}
                    size='lg'
                    labelPosition='outside'
                    defaultOption={defaultDOSVersionId.toString()}
                    openDirection={isxLg ? 'down' : 'auto'}
                    onSelect={e => setDosVersionState(e.value)}
                  >
                    <DropdownOption
                      key={defaultDOSVersionId.toString()}
                      value={defaultDOSVersionId.toString()}
                      text='DOS 5'
                    />
                  </Dropdown>
                </StyledFlexColumn>
                {showCheckpointCreateButton && (
                  <StyledFlexColumn>
                    <StyledFirstIconButton onClick={onCheckpointNewClick}>
                      <span>{t('add-new-checkpoint', { ns: 'common' })}</span>
                      <StyledFirstIcon />
                    </StyledFirstIconButton>
                  </StyledFlexColumn>
                )}
                <StyledFlexColumn>
                  <StyledIconButton
                    onClick={() => onDownloadTranslationTemplateClick()}
                  >
                    <span>
                      {t('download-translation-template', { ns: 'common' })}
                    </span>
                    <StyledIcon />
                  </StyledIconButton>
                </StyledFlexColumn>
              </Column>
            </StyledEndRow>
          </Column>
        </StyledMainRow>
      </Container>
    </ElasticContentContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    switch (error.data.status) {
      case 401:
        message = t('not-logged-in')
        break
      case 403:
        message = t('access-denied')
        break
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
