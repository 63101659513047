import * as sentry from '@sentry/react'
import { runInAction } from 'mobx'
import { isRouteErrorResponse } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import type {
  RealTimeCallbacks,
  SetNoteData,
  Unsub
} from '@cdab/scania/qpr/interactor'
import {
  cacheAudit,
  getCachedAudit,
  getCachedOperations
} from '@cdab/scania/qpr/offline/cache'
import type {
  AddDeviationFileOperation,
  AuditModel,
  DealerModel,
  Operation,
  UpdateAuditData
} from '@cdab/scania/qpr/offline/models'
import {
  AddAuditFileOperation,
  CreateDeviationOperation,
  DeleteAuditFileOperation,
  DeleteDeviationOperation,
  SetAuditNoteOperation,
  SetAuditPointNoteOperation,
  SetAuditPointScoreOperation,
  SetCheckPointNoteOperation,
  SetCheckPointScore,
  SetPledgeNoteOperation,
  UpdateAuditOperation,
  UpdateDeviationOperation,
  addAuditFiles,
  getDealerModel,
  toAuditModel
} from '@cdab/scania/qpr/offline/models'
import type { Audit, Deviation, FileData } from '@cdab/scania/qpr/schema'

import {
  addAudit,
  getAudit,
  getAudit as getAuditModel
} from '@cdab/scania/qpr/offline/models'
import invariant from 'tiny-invariant'
import { dealersController } from './dealers-controller'
import { deviationsController } from './deviations-controller'
import { operationsController } from './operations-controller'

export type FullAuditData = {
  audit: AuditModel
  dealer: DealerModel
}

export type GetAuditConfig = {
  dontListen?: boolean
  forceLoad?: boolean
}

type LoadAuditConfig = {
  forceLoad: boolean
}

class AuditsController {
  private listeners = new Map<Audit['id'], Unsub>()
  private currentlyRunningGetAudits = new Map<
    Audit['id'],
    Promise<AuditModel>
  >()

  private onReconnected = () => {
    const auditIds = Array.from(this.listeners.keys())

    // Clear listeners so we can restart them
    this.listeners.forEach(unsub => {
      unsub()
    })
    this.listeners.clear()

    auditIds.forEach(auditId => {
      this.loadAndListenToAudit(auditId)
    })
  }

  /**
   * Gets audit from backend and adds it to the store.
   * Then it returns audit reference.
   */
  private loadAudit = async (
    auditId: Audit['id'],
    config: LoadAuditConfig
  ): Promise<AuditModel> => {
    // We don't have to get audit if we already are listening to the changes
    // Since we clear listeners after reconnect, this will be false when if we lose the connection
    if (!config.forceLoad && this.listeners.has(auditId)) {
      const audit = getAudit(auditId)
      if (audit) return audit
    }

    // Load using backend
    const auditData = await getClient().AuditsService.GetAudit(auditId)

    // update store with data
    const auditModel = addAudit(toAuditModel(auditData))
    // Save to cache
    await cacheAudit(auditModel)

    // When we get audit from backend, and we have cached operations, apply them
    try {
      const cachedOperations = await getCachedOperations(auditId)
      cachedOperations.forEach(op => {
        // But also, if we have cached operations we want to send them to backend
        operationsController.handleNewOperation(op)
      })
    } catch (error) {
      // But if we fail, don't worry, just log the event to sentry
      sentry.captureException(error)
      console.warn(
        `There was an error getting cached operations for audit with id ${auditId}\n`,
        error
      )
    }

    return auditModel
  }

  private loadCachedAudit = async (
    auditId: Audit['id']
  ): Promise<AuditModel | undefined> => {
    try {
      const cachedAudit = await getCachedAudit(auditId)
      const cachedOperations = await getCachedOperations(auditId)

      if (!cachedAudit) return undefined

      const audit = addAudit(cachedAudit)

      const hasCreateDeviation = (deviationClientGuid: string) => {
        return !!cachedOperations.find(
          co =>
            co.Type === 'create-deviation' &&
            (co as CreateDeviationOperation).data.value.clientGuid ===
              deviationClientGuid
        )
      }

      cachedOperations.forEach(op => {
        let skipSend = false
        switch (op.Type) {
          case 'update-deviation':
            skipSend = hasCreateDeviation(
              (op as UpdateDeviationOperation).data.value.clientGuid
            )
            break
          case 'add-deviation-file':
            skipSend = hasCreateDeviation(
              (op as AddDeviationFileOperation).data.value.deviationClientGuid
            )
            break
          default:
            break
        }

        operationsController.handleNewOperation(op, { skipSend })
      })

      return audit
    } catch (error) {
      sentry.captureException(error)
      console.error('There was an error loading cached audit :(', error)
      return undefined
    }
  }

  private loadAndListenToAudit = (
    auditId: Audit['id'],
    config?: GetAuditConfig
  ): Promise<void> =>
    this.loadAudit(auditId, { forceLoad: !!config?.forceLoad })
      .then(() => {
        if (!config?.dontListen && !this.listeners.has(auditId)) {
          this.listenToAudit(auditId)
        }
      })
      .catch(error => {
        // Nothing to do, we are probably offline
        // If we are offline, the request fails.
        // If we are online, the response fails.
        if (isRouteErrorResponse(error)) {
          // Therefore, throw when we are online and trying to access
          // an audit which can't be found
          throw error
        }
      })

  private getAudit = async (
    auditId: Audit['id'],
    config?: GetAuditConfig
  ): Promise<AuditModel> => {
    // FIXME: Should probably live in the constructor of this class, but there is a problem with client being undefined.
    // Try again after merging QPR-524
    getClient().AuditsRealTimeService.AddOnReconnectedCallback(
      this.onReconnected
    )

    let audit = await this.loadCachedAudit(auditId)

    if (audit) {
      // We have a cached audit and want to return it asap!
      // But we also have to start load and listener
      this.loadAndListenToAudit(auditId, config)

      return audit
    }

    audit = await this.loadAudit(auditId, { forceLoad: !!config?.forceLoad })
    if (!config?.dontListen && !this.listeners.has(auditId)) {
      this.listenToAudit(auditId)
    }

    return audit
  }

  public GetAudit = async (
    auditId: Audit['id'],
    config?: GetAuditConfig
  ): Promise<FullAuditData> => {
    let audit = getAuditModel(auditId)
    let dealer: DealerModel | undefined = undefined

    if (audit) {
      const { did: dealerId } = audit
      dealer = getDealerModel(dealerId)

      if (!config?.dontListen) {
        this.loadAndListenToAudit(auditId, config)
      }

      if (dealer) {
        return {
          audit,
          dealer
        }
      }

      dealer = await dealersController.GetDealer(dealerId)

      return {
        audit,
        dealer
      }
    }

    let currentlyRunningGetAudit = this.currentlyRunningGetAudits.get(auditId)

    if (!currentlyRunningGetAudit) {
      currentlyRunningGetAudit = this.getAudit(auditId, config)

      // When we are finished, deregister ourseleves
      currentlyRunningGetAudit.then(() => {
        // Just to make sure, should never be relevant
        if (this.currentlyRunningGetAudits.has(auditId)) {
          this.currentlyRunningGetAudits.delete(auditId)
        }
      })
      this.currentlyRunningGetAudits.set(auditId, currentlyRunningGetAudit)
    }

    audit = await currentlyRunningGetAudit
    dealer = await dealersController.GetDealer(audit.did)

    return {
      audit,
      dealer
    }
  }

  public async UpdateAudit(
    audit: Audit,
    update: UpdateAuditData
  ): Promise<void> {
    const operation = new UpdateAuditOperation(audit.id, update, {
      date: audit.date,
      description: audit.description,
      extraAuditors: audit.extraAuditors,
      auditTypeId: audit.auditTypeId
    })

    await operationsController.handleNewOperation(operation)
  }

  public async CertifyAudit(auditId: Audit['id']): Promise<string> {
    const client = getClient()
    const result = await client.AuditsService.CertifyAudit(auditId)
    if (result === 'NoError') {
      await this.getAudit(auditId)
    }

    return result
  }

  public SetNote = async (auditId: Audit['id'], note: string) => {
    const audit = getAudit(auditId)

    const operation = new SetAuditNoteOperation(
      auditId,
      { note },
      { note: audit?.note || '' }
    )

    operationsController.handleNewOperation(operation)
  }

  public AddFile = async (auditId: Audit['id'], file: File) => {
    const operation = new AddAuditFileOperation(auditId, {
      file,
      fileGuid: uuidv4()
    })

    operationsController.handleNewOperation(operation)
  }

  private getFilesMap = new Map<Audit['id'], Promise<void>>()
  public GetFiles = async (auditId: Audit['id']): Promise<void> => {
    let getFilesPromise = this.getFilesMap.get(auditId)
    if (getFilesPromise) return getFilesPromise

    const getFiles = async (auditId: Audit['id']): Promise<void> => {
      const { audit } = await this.GetAudit(auditId, { dontListen: true })
      if (audit.isGettingFiles) return
      const client = getClient()

      try {
        runInAction(() => {
          audit.isGettingFiles = true
        })

        const files = await client.StorageService.GetFilesForAudit(auditId)

        addAuditFiles(auditId, files)
      } catch (error) {
        console.warn('there was an error getting files for audit', error)
      } finally {
        runInAction(() => {
          audit.isGettingFiles = false
        })
      }
    }

    getFilesPromise = getFiles(auditId).then(() => {
      this.getFilesMap.delete(auditId)
    })
  }

  public DeleteFile = async (auditId: Audit['id'], fileId: FileData['id']) => {
    const { audit } = await this.GetAudit(auditId, { dontListen: true })

    const file = audit.files.find(f => f.id === fileId)
    invariant(
      file,
      `Cannot delete file we cannot find (no file for id ${fileId})`
    )

    if (file.isUploaded) {
      const operation = new DeleteAuditFileOperation(auditId, {
        fileId
      })

      operationsController.handleNewOperation(operation)
    } else {
      const operation = operationsController.findOperation(
        op =>
          op.Type === 'add-audit-file' &&
          (op as AddAuditFileOperation).data.value.fileGuid === fileId
      )

      invariant(
        operation,
        `Cannot cancel (delete) file operation we cannot find! (searching file id ${fileId}`
      )

      operationsController.cancelOperation(operation.guid)
    }
  }

  public listenToAudit = async (auditId: Audit['id']) => {
    if (this.listeners.has(auditId)) return

    const realtimeCallbacks = this.createRealtimeCallbacks()

    const unsub = await getClient().AuditsRealTimeService.ListenTo(
      auditId,
      realtimeCallbacks
    )

    this.listeners.set(auditId, unsub)
  }

  public StopListeningToAudit = (auditId: Audit['id']) => {
    const unsub = this.listeners.get(auditId)

    if (!unsub) return

    unsub()
  }

  private createRealtimeCallbacks = (): RealTimeCallbacks => {
    // FIXME: How do we handle previous values here?
    return {
      id: uuidv4(),
      onAuditPointScoreChange: (
        auditId: Audit['id'],
        auditPointId: number,
        score: boolean | null,
        callIdentifier: string
      ) => {
        const operation = new SetAuditPointScoreOperation(
          auditId,
          {
            auditPointId,
            score
          },
          {
            auditPointId,
            score
          },
          callIdentifier
        )

        operationsController.handleIncomingOperation(operation)
      },
      onNoteChange: (data: SetNoteData) => {
        let operation: Operation | undefined = undefined
        if (data.pledgeId) {
          operation = new SetPledgeNoteOperation(
            data.auditId,
            {
              note: data.note,
              pledgeId: data.pledgeId
            },
            undefined,
            data.callIdentifier
          )
        } else if (data.auditPointId) {
          operation = new SetAuditPointNoteOperation(
            data.auditId,
            { note: data.note, auditPointId: data.auditPointId },
            { note: data.note, auditPointId: data.auditPointId },
            data.callIdentifier
          )
        } else if (data.checkPointId) {
          operation = new SetCheckPointNoteOperation(
            data.auditId,
            { note: data.note, auditCheckPointId: data.checkPointId },
            { note: data.note, auditCheckPointId: data.checkPointId },
            data.callIdentifier
          )
        } else {
          operation = new SetAuditNoteOperation(
            data.auditId,
            { note: data.note },
            { note: data.note },
            data.callIdentifier
          )
        }

        if (!operation) {
          // Unhandled case.
          // When both pledge notes and check point notes are implemented, operation no longer has to be able to be undefined
          return
        }

        operationsController.handleIncomingOperation(operation)
      },
      onCreateDeviation: (callIdentifier, deviation) => {
        const operation = new CreateDeviationOperation(
          deviation.auditId,
          deviation,
          callIdentifier
        )

        operationsController.handleIncomingOperation(operation)
      },
      onDeviationUpdate: async deviationUpdate => {
        const deviation = await deviationsController.GetDeviation(
          deviationUpdate.deviation.auditId,
          deviationUpdate.deviation.id
        )

        if (!deviation) {
          console.warn(
            'we cannot update a deviation we cannot find',
            deviationUpdate.deviation.id
          )
          return
        }

        let updatedDeviationData: Deviation

        if (deviationUpdate.withActionPlan) {
          const { approvalDate, expirationDate, ...rest } =
            deviationUpdate.deviation
          updatedDeviationData = {
            ...deviation,
            ...rest,
            approvalDate: approvalDate ? new Date(approvalDate) : null,
            expirationDate: new Date(expirationDate),
            auditId: deviationUpdate.deviation.auditId,
            deviationWithoutActions: !deviationUpdate.withActionPlan
          }
        } else {
          const { ...rest } = deviationUpdate.deviation
          updatedDeviationData = {
            ...deviation,
            ...rest,
            deviationWithoutActions: !deviationUpdate.withActionPlan
          }
        }

        const operation = new UpdateDeviationOperation(
          deviationUpdate.deviation.auditId,
          updatedDeviationData,
          deviation,
          deviationUpdate.deviation.callIdentifier
        )

        operationsController.handleIncomingOperation(operation)
      },
      onCheckPointScoreChange: update => {
        const operation = new SetCheckPointScore(
          update.auditId,
          {
            checkPointId: update.id,
            score: update.isPointCheckedYes
          },
          {
            checkPointId: update.id,
            score: update.isPointCheckedYes
          },
          update.callIdentifier
        )

        operationsController.handleIncomingOperation(operation)
      },
      onDeviationDelete: deleteData => {
        const operation = new DeleteDeviationOperation(
          deleteData.auditId,
          {
            deviationId: deleteData.deviationId
          },
          deleteData.callIdentifier
        )

        operationsController.handleIncomingOperation(operation)
      }
    }
  }
}

export const auditsController = new AuditsController()
