import { v4 as uuidv4 } from 'uuid'
import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { AuditModel, PledgeModel } from '../audit-model'
import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import invariant from 'tiny-invariant'
import { action } from 'mobx'

type Type = 'set-pledge-note'

type Data = {
  pledgeId: PledgeModel['id']
  note: string
}

export type SetPledgeNoteOperationData = OperationDataBase<Type, Data>

export class SetPledgeNoteOperation extends Operation {
  public data: SetPledgeNoteOperationData

  constructor(
    auditId: AuditModel['id'],
    data: Data,
    previousNote?: string,
    guid = uuidv4()
  ) {
    super(auditId, guid)

    this.data = {
      type: 'set-pledge-note',
      value: data,
      previousValue: previousNote
        ? {
            note: previousNote,
            pledgeId: data.pledgeId
          }
        : undefined
    }
  }

  private getPledge = (audit: AuditModel): PledgeModel => {
    const pledge = audit.pledges.find(p => p.id === this.data.value.pledgeId)

    invariant(pledge, 'Cannot set note on pledge we cannot find')

    return pledge
  }

  public ApplyTo = action((audit: AuditModel): void => {
    const pledge = this.getPledge(audit)

    pledge.note = this.data.value.note
  })

  public GetName(): string {
    return `${this.data.type}-${this.auditId}-${this.data.value.pledgeId}`
  }

  public SendOperation = async (client: BackEnd): Promise<boolean> => {
    const { note, pledgeId } = this.data.value

    const success = await client.AuditsRealTimeService.SetNote({
      auditId: this.auditId,
      pledgeId,
      note,
      callIdentifier: this.guid,
      created: new Date(),
      auditPointId: null,
      checkPointId: null
    })

    return success
  }

  public RollbackOn = action((audit: AuditModel): void => {
    const pledge = this.getPledge(audit)

    pledge.note = this.data.previousValue?.note || null
  })
}
