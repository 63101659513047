import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { CertificationStatus } from '@cdab/scania/qpr/schema'
import { Block } from '@cdab/scania/sdds'
import { capitalizeFirstLetter, formatISODate } from '@cdab/utils'

import type {
  BlockRowProps,
  DealerInfoBlockProps
} from './dealer-info-block.types'

import { observer } from 'mobx-react-lite'
import { StyledCertification } from './dealer-info-block.styles'

function BlockRow(props: BlockRowProps): JSX.Element {
  const { color, left, right } = props

  return (
    <div className='sdds-detail-01' style={{ display: 'flex' }}>
      <div style={{ flex: 1, opacity: '0.60' }}>{left}</div>
      <div style={{ color, flex: 1 }}>{right}</div>
    </div>
  )
}

function Certification({ status }: { status: CertificationStatus }) {
  const { t } = useTranslation()
  const statusText =
    status === CertificationStatus.Certified
      ? t('certification-status.certified')
      : status === CertificationStatus.Expired
      ? t('certification-status.expired')
      : t('certification-status.never-certified')

  return (
    <StyledCertification certification={status}>
      {statusText}
    </StyledCertification>
  )
}

export const DealerInfoBlock = observer(function DealerInfoBlock(
  props: DealerInfoBlockProps
) {
  const { className, dealer } = props
  const { t } = useTranslation()
  const { name, city, certificationStatus, expiryDate, auditDate, id } = dealer
  const dealerLink = `/dealer/${id}`

  return (
    <div className={className}>
      <h4 className='sdds-u-mt0'>{capitalizeFirstLetter(t('dealer'))}</h4>
      <Block color='on-grey' header={`${name}, ${city}`}>
        <BlockRow
          left={capitalizeFirstLetter(t('certification'))}
          right={<Certification status={certificationStatus} />}
        />
        <BlockRow
          left={t('expiry-date')}
          right={expiryDate ? formatISODate(expiryDate) : ''}
        />
        <BlockRow
          left={t('audit-date')}
          right={auditDate ? formatISODate(auditDate) : ''}
        />
        <div className='sdds-u-mt1'>
          <Link className=' sdds-link sdds-link--no-underline' to={dealerLink}>
            {t('go-to-dealer', { ns: 'audit' })}
          </Link>
        </div>
      </Block>
    </div>
  )
})
