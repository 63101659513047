import styled from 'styled-components'

import type { StyleProps } from './details-panel.styles.types'

export const DetailsPanelInner = styled.div`
  height: 100%;
  width: 100%;
`

export const DetailsPanelOuter = styled.div<StyleProps>`
  display: ${({ hidden }) => (hidden ? 'none' : 'inherit')};
  height: 100%;
  width: 100%;
`
