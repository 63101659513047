import {
  BeforeDeleteModal,
  CenteredContainer,
  Editor,
  ElasticContentContainer,
  ScrollableWrapper
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useFeatures } from '@cdab/scania/qpr/feature-flags'
import {
  useCssVariableBreakpoint,
  useDeleteSystemMessage,
  useUpdateSystemMessage
} from '@cdab/scania/qpr/hooks'
import { getMessageIdFromParams } from '@cdab/scania/qpr/loaders'
import type { SystemMessage } from '@cdab/scania/qpr/schema'
import {
  Breadcrumbs,
  Button,
  Column,
  Container,
  EmptyScreen,
  IconWarning,
  Message,
  Row,
  Textarea,
  Textfield,
  Toast
} from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs } from 'react-router'
import {
  isRouteErrorResponse,
  useLoaderData,
  useNavigate,
  useRouteError
} from 'react-router'
import { toast } from 'react-toastify'
import {
  StyledButtonsRow,
  StyledFirstButton,
  StyledLastButtonWrapper,
  StyledRow,
  Title
} from './system-messages.styles'

export type SystemMessageData = Pick<
  SystemMessage,
  'id' | 'author' | 'text' | 'title'
>

type TextfieldState = 'error' | 'ok'

export type AdminSystemMessageLoaderData = {
  systemMessage: SystemMessage
}

export async function loader({
  params
}: LoaderFunctionArgs): Promise<AdminSystemMessageLoaderData> {
  try {
    const messageId = getMessageIdFromParams(params)
    const client = getClient()

    const [systemMessage] = await Promise.all([
      client.SystemMessagesService.GetSystemMessage(messageId)
    ])

    return {
      systemMessage
    }
  } catch (error) {
    if (error instanceof Response) {
      throw new Response(null, {
        status: error.status
      })
    } else {
      throw new Response(null, {
        status: 404
      })
    }
  }
}

function textfieldValueCheck(value: string): 'error' | 'ok' {
  return value?.length > 0 && value?.length < 5 ? 'error' : 'ok'
}

export function Page() {
  const navigate = useNavigate()
  const { t } = useTranslation(['systemMessages', 'common'])
  const { updateTitles } = useTitle()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { use_new_editor } = useFeatures(['use_new_editor'])
  const { systemMessage } = useLoaderData() as AdminSystemMessageLoaderData

  const [data, setData] = useState<SystemMessageData>({
    ...systemMessage
  })

  const [submitState, handleClickUpdateSystemMessage, setSubmitState] =
    useUpdateSystemMessage({
      ...systemMessage,
      ...data
    })

  const [submitDeleteState, handleClickDeleteSystemMessage, setDeleteState] =
    useDeleteSystemMessage(systemMessage.id)

  const [stateTitle, setStateTitle] = useState<TextfieldState>('ok')
  const [stateText, setStateText] = useState<TextfieldState>('ok')
  const [stateAuthor, setStateAuthor] = useState<TextfieldState>('ok')

  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const saveButtonEnabled = useCallback(() => {
    return stateTitle === 'error' ||
      stateText === 'error' ||
      stateAuthor === 'error'
      ? true
      : false
  }, [stateAuthor, stateText, stateTitle])

  const onSaveClick = () => {
    if (stateTitle === 'ok' && stateText === 'ok' && stateAuthor === 'ok') {
      handleClickUpdateSystemMessage()
    }
  }

  useEffect(() => {
    updateTitles({
      contentHeader: {
        title: t('administration', { ns: 'common' }),
        subtitle: t('system-messages', { ns: 'common' })
      },
      mobile: {
        title: `${t('system-messages', { ns: 'common' })}`,
        description: null
      }
    })
  }, [t])

  useEffect(() => {
    if (submitState.status === 'success') {
      toast(
        <Toast
          type='success'
          headline={t('system-message-saved', { ns: 'common' })}
          subheadline={submitState.message}
        />
      )
      setSubmitState({ status: 'none', message: null })
      navigate('/admin/system-messages/all')
    }

    if (submitDeleteState.status === 'success') {
      toast(
        <Toast
          type='success'
          headline={t('system-message-deleted', { ns: 'common' })}
          subheadline={submitDeleteState.message}
        />
      )
      setDeleteState({ status: 'none', message: null })
      navigate('/admin/system-messages/all')
    }
  }, [
    navigate,
    submitDeleteState.status,
    submitState.status,
    submitState.message,
    submitDeleteState.message,
    t,
    setSubmitState,
    setDeleteState
  ])

  return (
    <ElasticContentContainer overflowHidden>
      <ScrollableWrapper>
        <Container
          fullHeight
          fluid='normal'
          paddingOnColumns={isLg}
          paddingOnContainer={isLg}
        >
          {isLg && (
            <Row>
              <Column width={12} className='sdds-u-pt2'>
                <Breadcrumbs
                  links={[
                    {
                      text: t('system-messages', { ns: 'common' }),
                      to: `/admin/system-messages/all`
                    },
                    { text: t('edit', { ns: 'common' }), to: '' }
                  ]}
                />
              </Column>
            </Row>
          )}
          <Row>
            <Column width={12} lg={6} offset={{ lg: 3 }}>
              <StyledRow>
                <Title>{t('edit-title', { ns: 'systemMessages' })}</Title>
              </StyledRow>
              <StyledRow>
                <Textfield
                  label={t('title-label')}
                  labelPosition='outside'
                  maxLength={100}
                  name='title'
                  placeholder={t('title-label')}
                  value={data.title ?? ''}
                  disabled={false}
                  onChange={e => {
                    setData({ ...data, title: e.target.value })
                  }}
                  onBlur={e => {
                    setStateTitle(textfieldValueCheck(e.target.value))
                  }}
                  state={stateTitle === 'error' ? 'error' : undefined}
                  helper={
                    stateTitle === 'error' &&
                    t('message.validation-error', {
                      ns: 'systemMessages',
                      chars: '4'
                    })
                  }
                />
              </StyledRow>
              <StyledRow>
                {use_new_editor.enabled ? (
                  <Editor
                    content={data.text ?? ''}
                    onChange={text => {
                      setData({ ...data, text })
                    }}
                    placeholder={t('text-label')}
                    hasError={stateText === 'error'}
                    maxLength={500}
                    helper={
                      stateText === 'error' &&
                      t('message.validation-error', {
                        ns: 'systemMessages',
                        chars: '4'
                      })
                    }
                    onBlur={length => {
                      setStateText(length > 4 ? 'ok' : 'error')
                    }}
                  />
                ) : (
                  <Textarea
                    label={t('text-label')}
                    labelPosition='outside'
                    maxLength={500}
                    name='text'
                    placeholder={t('text-label')}
                    value={data.text ?? ''}
                    disabled={false}
                    rows={5}
                    onChange={e => {
                      setData({ ...data, text: e.target.value })
                    }}
                    onBlur={e => {
                      setStateText(textfieldValueCheck(e.target.value))
                    }}
                    state={stateText === 'error' ? 'error' : undefined}
                    helper={
                      stateText === 'error' &&
                      t('message.validation-error', {
                        ns: 'systemMessages',
                        chars: '4'
                      })
                    }
                  />
                )}
              </StyledRow>

              <StyledRow>
                <Textfield
                  label={t('author-label')}
                  labelPosition='outside'
                  maxLength={100}
                  name='author'
                  placeholder={t('author-label')}
                  value={data.author ?? ''}
                  disabled={false}
                  onChange={e => {
                    setData({ ...data, author: e.target.value })
                  }}
                  onBlur={e => {
                    setStateAuthor(textfieldValueCheck(e.target.value))
                  }}
                  state={stateAuthor === 'error' ? 'error' : undefined}
                  helper={
                    stateAuthor === 'error' &&
                    t('message.validation-error', {
                      ns: 'systemMessages',
                      chars: '4'
                    })
                  }
                />
              </StyledRow>
              <StyledRow>
                {submitState.status === 'error' && (
                  <Message
                    className='sdds-u-mt1'
                    variant='single-line'
                    singleLineMessage={submitState.message}
                    type={submitState.status}
                  />
                )}
                {submitDeleteState.status === 'error' && (
                  <Message
                    className='sdds-u-mt1'
                    variant='single-line'
                    singleLineMessage={submitDeleteState.message}
                    type={submitDeleteState.status}
                  />
                )}
              </StyledRow>
              <StyledButtonsRow>
                <StyledFirstButton
                  text={t('cancel', {
                    ns: 'common'
                  })}
                  type='secondary'
                  size='md'
                  onClick={() => navigate('/admin/system-messages/all')}
                />
                <Button
                  text={capitalizeFirstLetter(
                    t('save', {
                      ns: 'common'
                    })
                  )}
                  disabled={!saveButtonEnabled}
                  type='primary'
                  size='md'
                  onClick={() => onSaveClick()}
                />
                <StyledLastButtonWrapper>
                  <Button
                    text={t('delete', {
                      ns: 'common'
                    })}
                    onClick={() => setOpenDeleteModal(true)}
                    type='danger'
                    size='md'
                  />
                </StyledLastButtonWrapper>
              </StyledButtonsRow>
              <BeforeDeleteModal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onSubmit={() => {
                  setOpenDeleteModal(false)
                  handleClickDeleteSystemMessage()
                }}
              />
            </Column>
          </Row>
        </Container>
      </ScrollableWrapper>
    </ElasticContentContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    if (error.status === 403) {
      message = t('access-denied')
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
