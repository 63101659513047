import cx from 'classnames'
import styled, { css } from 'styled-components'
import type { RowProps } from './row.types'

export const StyledRow = styled.div.attrs<RowProps>(({ className }) => ({
  className: cx(className, 'sdds-row')
}))<RowProps>`
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};
`
