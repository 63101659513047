import {
  AdminTranslationItem,
  CenteredContainer,
  ElasticContentContainer
} from '@cdab/scania/qpr/components'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useUser } from '@cdab/scania/qpr/contexts/user-provider'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import {
  getAdminTranslationsLanguageIdFromParams,
  getAdminTranslationsTypeFromParams
} from '@cdab/scania/qpr/loaders'
import { Roles, type AdminTranslations } from '@cdab/scania/qpr/schema'
import { Column, EmptyScreen, IconWarning, Row } from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs } from 'react-router'
import {
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
  useRouteLoaderData
} from 'react-router'
import type { AdminTranslationsLanguageLoaderData } from './language'

type AdditionsDataTranslations = {
  title: string
  value: string
}

const TranslationsTypes = [
  'pledges',
  'audit-points',
  'audit-purposes',
  'check-points',
  'pledge-perspectives'
]

type DataTranslations = {
  id: number | null
  pointNo: string
  baseText: string
  translatedText: string | null
  translationGuid: string
  additionalData: AdditionsDataTranslations[] | undefined
}

function getDataFromType(
  translationsType: string,
  adminTranslations: AdminTranslations
): DataTranslations[] {
  switch (translationsType) {
    case 'pledges':
      return adminTranslations.pledgesTranslations
        .sort((a, b) =>
          Number.parseInt(a.pledgeNo) > Number.parseInt(b.pledgeNo) ? 1 : -1
        )
        .map(pt => ({
          id: pt.id,
          pointNo: pt.pledgeNo,
          baseText: pt.defaultDescription,
          translatedText: pt.translatedDescription,
          translationGuid: pt.translationGuid,
          additionalData: undefined
        }))
    case 'audit-points':
      return adminTranslations.auditPointsTranslations.map(apt => ({
        id: apt.id,
        pointNo: apt.auditPointNo,
        baseText: apt.defaultDescription,
        translatedText: apt.translatedDescription,
        translationGuid: apt.translationGuid,
        additionalData: undefined
      }))
    case 'audit-purposes':
      return adminTranslations.auditPointsTranslations.map(apt => ({
        id: apt.purposeTranslationId,
        pointNo: apt.auditPointNo,
        baseText: apt.purposeDefaultDescription,
        translatedText: apt.purposeTranslatedDescription,
        translationGuid: apt.purposeTranslationGuid,
        additionalData: undefined
      }))
    case 'check-points':
      return adminTranslations.checkPointsTranslations.map(cpt => ({
        id: cpt.id,
        pointNo: cpt.checkPointNo,
        baseText: cpt.defaultDescription,
        translatedText: cpt.translatedDescription,
        translationGuid: cpt.translationGuid,
        additionalData: undefined
      }))
    case 'pledge-perspectives':
      return adminTranslations.pledgePerspectivesTranslations
        .sort((a, b) =>
          Number.parseInt(a.pledgeNo) > Number.parseInt(b.pledgeNo) ? 1 : -1
        )
        .map(ppt => ({
          id: ppt.id,
          pointNo: ppt.pledgeNo,
          baseText: ppt.defaultDescription,
          translatedText: ppt.translatedDescription,
          translationGuid: ppt.translationGuid,
          additionalData: undefined
        }))
    default:
      return []
  }
}

export type TranslationTabLoaderData = {
  translationsType: string
  languageId: number
}

export function loader({
  params
}: LoaderFunctionArgs): TranslationTabLoaderData {
  const languageId = getAdminTranslationsLanguageIdFromParams(params)
  const translationsType = getAdminTranslationsTypeFromParams(params)

  if (!TranslationsTypes.includes(translationsType))
    throw new Response(null, {
      status: 404
    })

  return { translationsType, languageId }
}

export function Page(): React.JSX.Element {
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { t } = useTranslation('common')
  const { updateTitles } = useTitle()
  const { userData } = useUser()
  const { translationsType, languageId } =
    useLoaderData() as TranslationTabLoaderData
  const { translations } = useRouteLoaderData(
    'admin-translations-language'
  ) as AdminTranslationsLanguageLoaderData

  const data = getDataFromType(translationsType, translations)

  const [focusEditorGuid, setFocusEditorGuid] = useState<string>('')

  const disableTranslationItems = userData.role < Roles.CoOrdinator

  const onSaveTranslation = (guid: string, text: string) => {
    let findTranslation
    if (translationsType === 'pledges') {
      findTranslation = translations.pledgesTranslations.find(
        ({ translationGuid }) => translationGuid === guid
      )
    } else if (translationsType === 'audit-points') {
      findTranslation = translations.auditPointsTranslations.find(
        ({ translationGuid }) => translationGuid === guid
      )
    } else if (translationsType === 'audit-purposes') {
      findTranslation = translations.auditPointsTranslations.find(
        ({ purposeTranslationGuid }) => purposeTranslationGuid === guid
      )
      if (findTranslation) findTranslation.purposeTranslatedDescription = text
    } else if (translationsType === 'check-points') {
      findTranslation = translations.checkPointsTranslations.find(
        ({ translationGuid }) => translationGuid === guid
      )
    } else if (translationsType === 'pledge-perspectives') {
      findTranslation = translations.pledgePerspectivesTranslations.find(
        ({ translationGuid }) => translationGuid === guid
      )
    }

    if (translationsType !== 'audit-purposes' && findTranslation) {
      findTranslation.translatedDescription = text
    }
  }

  useEffect(() => {
    updateTitles({
      contentHeader: {
        title: t('administration'),
        subtitle: capitalizeFirstLetter(t('translations'))
      },
      mobile: {
        title: `${capitalizeFirstLetter(t('translations'))}`,
        description: null
      }
    })
  }, [t])

  return (
    <ElasticContentContainer overflowHidden={true} scrollY={true}>
      {data.map(translation => (
        <Row key={translation.translationGuid}>
          <Column width={12} padding={isLg} className='sdds-u-pt2'>
            <AdminTranslationItem
              id={translation.id}
              translationNo={translation.pointNo}
              baseText={translation.baseText}
              translationGuid={translation.translationGuid}
              translatedText={translation.translatedText}
              languageId={languageId}
              onEnableEditor={guid => setFocusEditorGuid(guid)}
              showEditor={translation.translationGuid === focusEditorGuid}
              showSavedErrorText={true}
              additionalInfo={translation.additionalData?.map((ad, index) => ({
                id: index,
                title: ad.title,
                text: ad.value
              }))}
              onSaveTranslation={onSaveTranslation}
              disable={disableTranslationItems}
            />
          </Column>
        </Row>
      ))}
    </ElasticContentContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    if (error.status === 403) {
      message = t('access-denied')
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
