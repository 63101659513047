import { CapitalizeFirstLetter } from '@cdab/headless-components'
import { InlineTabsFullbleed } from '@cdab/scania/sdds'
import { useTranslation } from 'react-i18next'
import type { AdminTranslationsTabsProps } from './admin-translation-tabs.types'

export function AdminTranslationsTabs({ links }: AdminTranslationsTabsProps) {
  const { t } = useTranslation('common')

  return (
    <InlineTabsFullbleed>
      <InlineTabsFullbleed.Tab to={links.pledges}>
        <CapitalizeFirstLetter>{t('pledges')}</CapitalizeFirstLetter>
      </InlineTabsFullbleed.Tab>
      <InlineTabsFullbleed.Tab to={links.auditPoints}>
        <CapitalizeFirstLetter>{t('audit-points')}</CapitalizeFirstLetter>
      </InlineTabsFullbleed.Tab>
      <InlineTabsFullbleed.Tab to={links.auditPurposes}>
        <CapitalizeFirstLetter>{t('audit-purposes')}</CapitalizeFirstLetter>
      </InlineTabsFullbleed.Tab>
      <InlineTabsFullbleed.Tab to={links.checkPoints}>
        <CapitalizeFirstLetter>{t('checkpoints')}</CapitalizeFirstLetter>
      </InlineTabsFullbleed.Tab>
      <InlineTabsFullbleed.Tab to={links.pledgePerspectives}>
        <CapitalizeFirstLetter>
          {t('pledge-perspectives')}
        </CapitalizeFirstLetter>
      </InlineTabsFullbleed.Tab>
    </InlineTabsFullbleed>
  )
}
