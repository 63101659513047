import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useRouteLoaderData } from 'react-router-dom'
import styled from 'styled-components'

import { AttachmentList, UploadFileButton } from '@cdab/scania/qpr/components'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import {
  getAuditIdFromParams,
  getPledgeIdFromParams
} from '@cdab/scania/qpr/loaders'
import { pledgeController } from '@cdab/scania/qpr/offline/controllers'
import type { FileData } from '@cdab/scania/qpr/schema'
import { Column, Container, Divider, Row, Spinner } from '@cdab/scania/sdds'
import type { AuditLoaderData } from '../../../../auditId'

import type { PledgeLoaderData } from '../pledge-base-view'

export async function loader({ params }: LoaderFunctionArgs) {
  const auditId = getAuditIdFromParams(params)
  const pledgeId = getPledgeIdFromParams(params)

  pledgeController.GetFiles(auditId, pledgeId)

  return null
}

export const PledgeAttachments = observer(function PledgeAttachments() {
  const { audit } = useRouteLoaderData('audit') as AuditLoaderData
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')

  const { pledge } = useRouteLoaderData('pledge') as PledgeLoaderData

  const { t } = useTranslation('audit')

  const onUploadFiles = useCallback(
    (files: FileList) => {
      Array.from(files).forEach(file => {
        pledgeController.AddFile(audit.id, pledge.id, file)
      })
    },
    [audit.id, pledge.id]
  )

  const onDeleteUploadedFile = useCallback(
    (fileId: FileData['id']) => {
      if (!window.confirm(t('attachments.confirm'))) {
        return
      }
      pledgeController.DeleteFile(audit.id, pledge.id, fileId)
    },
    [audit.id, pledge.id, t]
  )

  const uploadedFiles: FileData[] = []
  const localFiles: FileData[] = []

  //should be "pladge.files" we don't have it yet
  pledge.files.forEach(file => {
    if (file.isUploaded) {
      uploadedFiles.push(file)
    } else {
      localFiles.push(file)
    }
  })

  return (
    <Container fluid='normal' paddingOnContainer={isLg} paddingOnColumns={isLg}>
      {!audit.isReadonly && (
        <Row className='sdds-u-mt2 sdds-u-mb2'>
          <Column width={2}>
            <UploadFileButton size='sm' onUploadFiles={onUploadFiles} />
          </Column>
          <Column width={10}>
            {localFiles.length > 0 && (
              <ul>
                {localFiles.map(file => (
                  <li key={file.id}>
                    {file.fileName}
                    <progress max={1} value={file.uploadProgress}>
                      {Math.round(file.uploadProgress * 100)}%
                    </progress>
                  </li>
                ))}
              </ul>
            )}
          </Column>
        </Row>
      )}

      <Divider type='light' className='sdds-u-mb1' />

      <Row>
        <Column width={12} lg={6} padding={isLg}>
          {pledge.isGettingFiles ? (
            <Spinner />
          ) : (
            <AttachmentList
              hideUploadPrompt={audit.isReadonly}
              color='on-grey'
              files={uploadedFiles}
              onDeleteAttachment={onDeleteUploadedFile}
              fileType='images'
              disableDelete={audit.isReadonly}
              title={t('images', { ns: 'common' })}
            />
          )}
        </Column>
        <ColumnOtherDocuments width={12} lg={6} padding={isLg}>
          {pledge.isGettingFiles ? (
            <Spinner />
          ) : (
            <AttachmentList
              hideUploadPrompt={audit.isReadonly}
              color='on-grey'
              files={uploadedFiles}
              onDeleteAttachment={onDeleteUploadedFile}
              disableDelete={audit.isReadonly}
              fileType='other'
              title={t('other', { ns: 'common' })}
            />
          )}
        </ColumnOtherDocuments>
      </Row>
    </Container>
  )
})

const ColumnOtherDocuments = styled(Column)`
  margin-top: var(--sdds-spacing-layout-16);

  @media (min-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 0;
  }
`
