import invariant from 'tiny-invariant'
import { action } from 'mobx'
import { v4 as uuidv4 } from 'uuid'

import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import type { Audit, AuditPoint } from '@cdab/scania/qpr/schema'
import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { AuditModel } from '../audit-model'

type Type = 'set-audit-point-score'

type Data = {
  score: AuditPoint['score']
  auditPointId: AuditPoint['id']
}

export type SetAuditPointScoreData = OperationDataBase<Type, Data>

export class SetAuditPointScoreOperation extends Operation {
  public data: SetAuditPointScoreData

  constructor(
    auditId: Audit['id'],
    value: Data,
    previousValue: Data,
    guid: string = uuidv4()
  ) {
    super(auditId, guid)
    this.data = {
      type: 'set-audit-point-score',
      value,
      previousValue
    }
  }

  private getAuditPointIndex = (audit: AuditModel) => {
    const { auditPointId } = this.data.value

    const auditPointIndex = audit.auditPoints.findIndex(
      ({ id }) => id === auditPointId
    )

    invariant(
      auditPointIndex !== -1,
      `Tried to get audit point which doesn't exist with id: ${auditPointId}!`
    )
    return auditPointIndex
  }

  public ApplyTo = action((audit: AuditModel): void => {
    const auditPointIndex = this.getAuditPointIndex(audit)

    audit.auditPoints[auditPointIndex].score = this.data.value.score
  })

  public GetName = (): string =>
    `${this.data.type}-${this.auditId}-${this.data.value.auditPointId}`

  public SendOperation = async (client: BackEnd) => {
    const success = await client.AuditsRealTimeService.UpdateAuditPointScore(
      this.auditId,
      this.data.value.auditPointId,
      this.data.value.score,
      this.guid,
      new Date()
    )

    return success
  }

  public RollbackOn = action((audit: AuditModel): void => {
    invariant(
      this.data.previousValue,
      'Tried to roll back set audit point score, but there was no previous value!'
    )

    const auditPointIndex = this.getAuditPointIndex(audit)

    audit.auditPoints[auditPointIndex].score = this.data.previousValue.score
  })
}
