import React, { Fragment, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Container,
  Header,
  ModalActions,
  ModalBody,
  ModalHeadline,
  Overlay,
  Panel,
  FullWidthForm
} from './form-modal.styles'
import { ElasticContentContainer } from '@cdab/scania/qpr/components/atoms'

interface FormModalProps {
  open: boolean
  onClose: () => void
  onShowTransitionStarted?: () => void
  onHideTransitionEnded?: () => void
  onSubmit: (e: React.FormEvent) => void
  header?: React.ReactNode | undefined
  footer?: React.ReactNode | undefined
  children?: React.ReactNode | undefined
}

const noop: () => void = () => undefined
export function FormModal(props: FormModalProps) {
  const {
    open,
    onClose,
    onSubmit,
    children,
    footer,
    header,
    onShowTransitionStarted,
    onHideTransitionEnded
  } = props

  const hideTransitionEndedRef = useRef(noop)

  useEffect(() => {
    hideTransitionEndedRef.current = onHideTransitionEnded ?? noop
  }, [onHideTransitionEnded])

  useEffect(() => {
    let handle: ReturnType<typeof setTimeout>

    if (!open) {
      handle = setTimeout(() => hideTransitionEndedRef.current(), 300)
    }

    return () => clearTimeout(handle)
  }, [open])

  return (
    <Transition
      show={open}
      appear
      as={Fragment}
      beforeEnter={onShowTransitionStarted}
    >
      <Dialog onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='overlay-transition-enter'
          enterFrom='overlay-transition-enter-from'
          enterTo='overlay-transition-enter-to'
          leave='overlay-transition-leave'
          leaveFrom='overlay-transition-leave-from'
          leaveTo='overlay-transition-leave-to'
        >
          <Overlay />
        </Transition.Child>
        <Container>
          <Transition.Child
            as={Fragment}
            enter='dialog-transition-enter'
            enterFrom='dialog-transition-enter-from'
            enterTo='dialog-transition-enter-to'
            leave='dialog-transition-leave'
            leaveFrom='dialog-transition-leave-from'
            leaveTo='dialog-transition-leave-to'
          >
            <Dialog.Panel as={Panel}>
              <FullWidthForm onSubmit={onSubmit}>
                <ElasticContentContainer
                  header={
                    <Header>
                      <ModalHeadline as='h5'>{header}</ModalHeadline>
                    </Header>
                  }
                  footer={<ModalActions>{footer}</ModalActions>}
                  scrollY={true}
                >
                  <ModalBody>{children}</ModalBody>
                </ElasticContentContainer>
              </FullWidthForm>
            </Dialog.Panel>
          </Transition.Child>
        </Container>
      </Dialog>
    </Transition>
  )
}
