/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type {
  CreateUserData,
  UpdateUserData,
  UserService
} from '@cdab/scania/qpr/interactor'
import type { DOSVersion, Language, User } from '@cdab/scania/qpr/schema'
import { EditRights, Roles } from '@cdab/scania/qpr/schema'
import { wait } from '@cdab/utils'

export class UserMockService implements UserService {
  private readonly waitTime: number

  constructor(waitTime: number) {
    this.waitTime = waitTime
  }

  async GetUser(): Promise<User> {
    await wait(this.waitTime)
    return {
      userId: 123,
      firstName: 'John',
      lastName: 'Doe',
      userName: 'johndoe',
      languageId: 1,
      languageVersions: {
        lng: 'version-1'
      },
      email: 'email@email.com',
      viewId: 1,
      cellPhone: '51351',
      country: 'country',
      auditReminderInterval: 5,
      truck: true,
      bus: true,
      engine: false,
      alertOnCertify: true,
      active: true,
      defaultMarketId: 1,
      editRights: EditRights.EditAndCertify,
      role: Roles.FactoryAdmin,
      markets: []
    }
  }

  public UpdateUser = async (
    userId: User['userId'],
    data: UpdateUserData,
    keycloakUserName: string | undefined
  ): Promise<string> => {
    if (userId) throw new Error('Method not implemented.')
    if (keycloakUserName) throw new Error('Method not implemented.')
    if (data) throw new Error('Method not implemented.')

    throw new Error('Method not implemented.')
  }

  public GetLanguagesForUser = async (
    includeBaseLanguage?: boolean
  ): Promise<Language[]> => Promise.resolve([])

  public GetDOSVersionsForUser = async (): Promise<DOSVersion[]> =>
    Promise.resolve([])

  public CreateUser = async (data: CreateUserData): Promise<string> => {
    if (data) throw new Error('Method not implemented.')

    throw new Error('Method not implemented.')
  }
  public GetUsers = async (): Promise<User[]> => Promise.resolve([])
}
