import axios from 'axios'

/**
 * It takes an axios error and returns a standard error
 * @param {unknown} error - unknown - The error object that was thrown by the axios request.
 * @returns A function that takes an error and returns an error.
 */
export function axiosErrorToError(error: unknown): unknown {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      return new Response(error.response.data, {
        status: error.response.status,
        statusText: error.response.statusText
      })
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return new Error('Something went wrong')
    } else {
      // Something happened in setting up the request that triggered an Error
      return new Error('Something went very wrong')
    }
  }

  return error
}
