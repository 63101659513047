import type { Audit } from '@cdab/scania/qpr/schema'

import { AuditPoints } from './audit-points'
import { CheckPoints } from './checkpoints'
import { PledgeSpectives } from './pledge-perspectives'
import { Pledges } from './pledges'
import { ReferenceDocuments } from './reference-documents'

export const MockAudit321: Audit = {
  isCertified: false,
  isReadonly: false,
  id: 321,
  did: 1,
  date: new Date('2020-01-01'),
  pledgeAreas: [
    {
      description: 'Pledge Area 1',
      id: 0
    },
    {
      description: 'Pledge Area 2',
      id: 1
    }
  ],
  dosVersion: 6,
  pledges: Pledges,
  auditPoints: AuditPoints,
  checkPoints: CheckPoints,
  note: null,
  description: null,
  referenceDocuments: ReferenceDocuments,
  pledgePerspectives: PledgeSpectives,
  extraAuditors: '',
  deviations: [],
  auditTypeId: 0
}
