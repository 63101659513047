import styled, { css } from 'styled-components'
import { DelayedSpinner } from '@cdab/scania/sdds'

interface LoaderProps {
  fullScreen?: boolean
  spacing?: 'none' | 'small' | 'medium' | 'large'
}

interface LoaderStyleProps
  extends Pick<LoaderProps, 'fullScreen' | 'spacing'> {}

const StyledApplicationLoader = styled.div<LoaderStyleProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: ${({ fullScreen }) => (fullScreen ? '100vw' : '100%')};
  height: ${({ fullScreen }) => (fullScreen ? '100vh' : '100%')};

  ${({ spacing }) => {
    if (spacing === 'small')
      return css`
        padding: 8rem;
      `
    if (spacing === 'medium')
      return css`
        padding: 16rem;
      `
    if (spacing === 'large')
      return css`
        padding: 32rem;
      `
    else
      return css`
        padding: 0;
      `
  }};
`

export function ApplicationLoader({
  fullScreen = false,
  spacing = 'none'
}: LoaderProps) {
  return (
    <StyledApplicationLoader fullScreen={fullScreen} spacing={spacing}>
      <DelayedSpinner />
    </StyledApplicationLoader>
  )
}
