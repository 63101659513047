import { IconBurger } from '@cdab/scania/sdds'
import styled from 'styled-components'

export interface MobileMenuHeaderButtonProps {
  onClick: () => void
}

const HeaderButton = styled.button.attrs({
  className: 'sdds-nav__mob-menu-btn'
})``

export function MobileMenuHeaderButton({
  onClick
}: MobileMenuHeaderButtonProps) {
  return (
    <HeaderButton onClick={onClick}>
      <IconBurger />
    </HeaderButton>
  )
}
