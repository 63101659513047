import { cloneElement } from 'react'

import type { PopoverMenuProps } from './popover-menu.types'

export function PopoverMenu(props: PopoverMenuProps): JSX.Element {
  const {
    children,
    offsetDistance,
    offsetSkidding,
    placement,
    selector = 'selector',
    show,
    trigger
  } = props
  const customTrigger = cloneElement(trigger, { id: selector })

  return (
    <>
      {customTrigger}
      <sdds-popover-menu
        placement={placement}
        show={show}
        offsetDistance={offsetDistance}
        offsetSkidding={offsetSkidding}
        selector={`#${selector}`}
      >
        <ul className='sdds-popover-menu-wrapper'>{children}</ul>
      </sdds-popover-menu>
    </>
  )
}
