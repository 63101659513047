import type {
  CreateDocumentData,
  DocumentService as IDocumentService,
  UpdateDocumentData
} from '@cdab/scania/qpr/interactor'
import type { AdminReferenceDocument } from '@cdab/scania/qpr/schema'

export class DocumentService implements IDocumentService {
  private readonly mockDelayMS: number

  public constructor(mockDelayMS: number) {
    this.mockDelayMS = mockDelayMS
  }

  public GetDocuments = async (): Promise<AdminReferenceDocument[]> => {
    throw new Error('Not implemented')
  }
  public GetDocument = async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    documentId: number
  ): Promise<AdminReferenceDocument> => {
    throw new Error('Not implemented')
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  public CreateDocument = async (data: CreateDocumentData): Promise<void> => {
    throw new Error('Not implemented')
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  public UpdateDocument = async (data: UpdateDocumentData): Promise<void> => {
    throw new Error('Not implemented')
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  public DeleteDocument = async (documentId: number): Promise<void> => {
    throw new Error('Not implemented')
  }
}
