import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type DeleteDealerFileState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

export function useSendDeleteDealerFile() {
  const client = useBackend()

  const deleteDealerFile = async (
    dealerId: number,
    fileId: string
  ): Promise<string> => {
    try {
      await client.StorageService.DeleteFileForDealer(dealerId, fileId)
      return 'success'
    } catch (e) {
      return 'error'
    }
  }
  return {
    deleteDealerFile
  }
}

export function useDeleteDealerFile(dealerId: number) {
  const { t } = useTranslation('dealer')
  const { deleteDealerFile } = useSendDeleteDealerFile()
  const [submitState, setSubmitState] = useState<DeleteDealerFileState>({
    status: 'none',
    message: null
  })

  const [deletedFileIdsState, setDeletedFileIdsState] = useState<string[]>([])

  const handleClickDeleteDealerFile = useCallback(
    async (fileId: string) => {
      const response = await deleteDealerFile(dealerId, fileId)
      setSubmitState({ status: 'none', message: null })

      if (response === 'success') {
        setDeletedFileIdsState(deletedFileIdsState.concat([fileId]))
        setSubmitState({
          status: response,
          message: t('message.delete-success')
        })
        return
      }

      setSubmitState({ status: 'error', message: t('message.delete-error') })
    },
    [deleteDealerFile, dealerId, t, deletedFileIdsState]
  )
  return [
    submitState,
    handleClickDeleteDealerFile,
    deletedFileIdsState,
    setSubmitState
  ] as const
}
