import type {
  InlineTabsContentProps,
  InlineTabsProps
} from './inline-tabs.types'

type Attr = Record<string, boolean> | undefined

const InlineTabsContent = ({
  children,
  defaultTab,
  disabled,
  onClick,
  name,
  className
}: InlineTabsContentProps) => {
  const attributeDisabled: Attr = disabled
    ? { 'aria-disabled': true }
    : undefined
  const attributeDefault: Attr = defaultTab
    ? { 'data-default': true }
    : undefined

  function onClickFn(e: React.MouseEvent<HTMLElement>) {
    onClick && onClick(e)
  }

  return (
    <div
      data-name={name}
      role='tab'
      onClick={e => onClickFn(e)}
      className={className}
      {...attributeDisabled}
      {...attributeDefault}
    >
      {children}
    </div>
  )
}

const InlineTabs = ({
  autoHeight,
  className,
  modeVariant = 'primary',
  children
}: InlineTabsProps) => {
  const attr = autoHeight ? { 'auto-height': '' } : ''

  return (
    <sdds-inline-tabs
      role='tablist'
      className={className}
      mode-variant={modeVariant}
      {...attr}
    >
      {children}
    </sdds-inline-tabs>
  )
}
InlineTabs.Content = InlineTabsContent

export { InlineTabs }
