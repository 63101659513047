import { Link } from 'react-router-dom'

import { StyledPopoverMenuItem } from './popover-menu.styles'
import type { PopoverMenuItemProps } from './popover-menu.types'

export function PopoverMenuItem(props: PopoverMenuItemProps): JSX.Element {
  const { icon, label, onClick, openInNewTab, url } = props

  if (url && openInNewTab) {
    return (
      <StyledPopoverMenuItem>
        <a href={url} rel={'noreferrer'} target={'_blank'}>
          {icon}
          {label}
        </a>
      </StyledPopoverMenuItem>
    )
  }

  if (url) {
    return (
      <StyledPopoverMenuItem>
        <Link to={url}>
          {icon}
          {label}
        </Link>
      </StyledPopoverMenuItem>
    )
  }

  return (
    <StyledPopoverMenuItem onClick={onClick}>
      {icon}
      {label}
    </StyledPopoverMenuItem>
  )
}
