import type { MarketsService as IMarketsService } from '@cdab/scania/qpr/interactor'
import type { Area, Market } from '@cdab/scania/qpr/schema'
import { wait } from '@cdab/utils'

const mockMarketsForUserData: Market[] = [
  {
    id: 2,
    marketName: 'Germany',
    countryCode: 'DE',
    isMainMarket: true,
    areaId: 1
  },
  {
    id: 4,
    marketName: 'Sweden',
    countryCode: 'SE',
    isMainMarket: true,
    areaId: 1
  },
  {
    id: 1364,
    marketName: 'United Arab Emirates',
    countryCode: 'AE',
    isMainMarket: true,
    areaId: 1
  },
  {
    id: 1365,
    marketName: 'Argentina',
    countryCode: 'AR',
    isMainMarket: true,
    areaId: 1
  },
  {
    id: 4,
    marketName: 'Sweden',
    countryCode: 'SE',
    isMainMarket: true,
    areaId: 1
  }
]

export class MarketsService implements IMarketsService {
  private readonly mockDelayMS: number

  public constructor(mockDelayMS: number) {
    this.mockDelayMS = mockDelayMS
  }
  GetSisImportJobStatus(sisImportJobId: number): Promise<string> {
    throw new Error('Method not implemented.')
  }

  SyncDealersForMarket(marketId: number): Promise<number> {
    if (marketId) return Promise.resolve(0)

    return Promise.resolve(0)
  }

  public async GetMarketsForUser(): Promise<Market[]> {
    await wait(this.mockDelayMS)

    const markets = mockMarketsForUserData

    if (!markets.length) return []

    return markets
  }

  public async GetAllMarkets(): Promise<Market[]> {
    await wait(this.mockDelayMS)

    const markets = mockMarketsForUserData

    if (!markets.length) return []

    return markets
  }

  public async GetAllAreas(): Promise<Area[]> {
    throw new Error('Method not implemented.')
  }
}
