import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { Link } from 'react-router-dom'

export interface AvatarMenuProps {
  onAvatarMenuClick?: () => void
  userName: string
  name: string
}

export function AvatarMenu(props: AvatarMenuProps) {
  const { name, userName, onAvatarMenuClick } = props
  const onLogoutClick = () => {
    const client = getClient()
    client.Logout({ redirectUri: `${window.location.origin}/loggedout` })
  }

  return (
    <li className='sdds-nav__item sdds-nav__avatar'>
      <button className='sdds-nav__avatar-btn' onClick={onAvatarMenuClick}>
        <img
          className='sdds-nav__avatar-img'
          src='https://www.svgrepo.com/show/170303/avatar.svg'
          alt='profile'
        />
        <div className='sdds-nav__avatar-info sdds-nav__avatar-info--mobile'>
          <p className='sdds-nav__avatar-title'>{name}</p>
          <p className='sdds-nav__avatar-subtitle'>{userName}</p>
        </div>
      </button>
      <ul className='sdds-nav__avatar-menu'>
        <li className='sdds-nav__avatar-item sdds-nav__avatar-item--large'>
          <div className='sdds-nav__avatar-info'>
            <p className='sdds-nav__avatar-title'>{name}</p>
            <p className='sdds-nav__avatar-subtitle'>{userName}</p>
          </div>
        </li>
        <li className='sdds-nav__avatar-item'>
          <Link to='profile' className='sdds-nav__avatar-item-core'>
            My Profile
          </Link>
        </li>
        <li className='sdds-nav__avatar-item'>
          <button
            onClick={onLogoutClick}
            className='sdds-nav__avatar-item-core'
          >
            Logout
          </button>
        </li>
      </ul>
    </li>
  )
}
