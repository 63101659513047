import { v4 as uuidv4 } from 'uuid'
import { action } from 'mobx'
import invariant from 'tiny-invariant'

import type { Audit } from '@cdab/scania/qpr/schema'
import type { BackEnd } from '@cdab/scania/qpr/interactor'

import type { AuditModel } from '../audit-model'

import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'

type Type = 'set-audit-note'

type Data = { note: string }

export type SetAuditNoteOperationData = OperationDataBase<Type, Data>

export class SetAuditNoteOperation extends Operation {
  public data: SetAuditNoteOperationData

  constructor(
    auditId: Audit['id'],
    value: Data,
    previousValue: Data,
    guid = uuidv4()
  ) {
    super(auditId, guid)
    this.data = {
      type: 'set-audit-note',
      value,
      previousValue
    }
  }

  public ApplyTo = action((audit: AuditModel): void => {
    audit.note = this.data.value.note
  })

  public GetName = (): string => `${this.data.type}-${this.auditId}`

  public SendOperation = async (client: BackEnd) => {
    await client.AuditsService.SetAuditNote(
      this.auditId,
      this.data.value.note,
      this.guid
    )
    return true
  }

  public RollbackOn = action((audit: AuditModel): void => {
    invariant(
      this.data.previousValue,
      'Tried to roll back set audit note, but there was no previous value!'
    )
    audit.note = this.data.previousValue?.note
  })
}
