import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'

export interface HeaderDropdownMenuItemProps {
  active?: boolean
  text: string
  to: string
}

export function HeaderDropdownMenuItem({
  text,
  active,
  to
}: HeaderDropdownMenuItemProps) {
  const navigate = useNavigate()

  const onNavigate = () => {
    navigate(to)
  }

  return (
    <li
      className={classnames('sdds-nav__dropdown-item', {
        'sdds-nav__dropdown-item--active': active
      })}
      onClick={onNavigate}
    >
      <div className='sdds-nav__dropdown-item-core'>
        <p>{text}</p>
      </div>
    </li>
  )
}
