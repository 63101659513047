import { FormModal } from '@cdab/scania/qpr/components/molecules'
import { useGetDOSReport, useGetEmptyDOSReport } from '@cdab/scania/qpr/hooks'
import { Button, Checkbox, Message } from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledDiv } from './audit-report-modal.styles'
import type {
  AuditReportData,
  AuditReportModalProps
} from './audit-report-modal.types'

export function AuditReportModal({
  open,
  onClose,
  auditId
}: AuditReportModalProps) {
  const { t } = useTranslation('common')

  const [data, setData] = useState<AuditReportData>({
    viewCheckpoints: true,
    viewSummary: true,
    viewPerspectives: false,
    viewDeviations: false,
    viewNotes: false
  })

  const [
    getEmptyDOSReportState,
    clickGetEmptyDOSReportState,
    setEmptyDOSReportState
  ] = useGetEmptyDOSReport(6) //empty DOS version 5

  const [getDOSReportState, clickGetDOSReportState, setDOSReportState] =
    useGetDOSReport(auditId ?? 0) //DOS version 5

  const isDOSReport = auditId !== undefined
  const isLoading =
    getEmptyDOSReportState.status === 'loading' ||
    getDOSReportState.status === 'loading'

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (isLoading) return
    if (isDOSReport) {
      clickGetDOSReportState(
        data.viewSummary,
        data.viewCheckpoints,
        data.viewPerspectives,
        data.viewDeviations,
        data.viewNotes
      )
    } else {
      clickGetEmptyDOSReportState(
        data.viewSummary,
        data.viewCheckpoints,
        data.viewPerspectives
      )
    }
  }

  const handleFormClose = async () => {
    if (isLoading) return
    onClose()
  }

  useEffect(() => {
    if (getEmptyDOSReportState.status === 'success') {
      setEmptyDOSReportState({ status: 'none', message: null })
      onClose()
    }
    if (getDOSReportState.status === 'success') {
      setDOSReportState({ status: 'none', message: null })
      onClose()
    }
  }, [
    t,
    getEmptyDOSReportState.status,
    getEmptyDOSReportState.message,
    setEmptyDOSReportState,
    onClose,
    getDOSReportState.status,
    setDOSReportState
  ])

  return (
    <FormModal
      open={open}
      onClose={handleFormClose}
      onSubmit={handleFormSubmit}
      footer={
        <>
          {getEmptyDOSReportState.status === 'error' && (
            <Message
              variant='single-line'
              singleLineMessage={getEmptyDOSReportState.message}
              type='error'
            />
          )}
          <Button
            disabled={isLoading}
            text={capitalizeFirstLetter('generate')}
            onClick={handleFormSubmit}
          />
          <Button
            disabled={isLoading}
            type='secondary'
            onClick={handleFormClose}
            text={capitalizeFirstLetter(t('close'))}
          />
        </>
      }
    >
      <StyledDiv className='sdds-u-mt3'>
        <Checkbox
          label={t('view-checkPoints', { ns: 'common' })}
          checked={data.viewCheckpoints === true}
          onChange={() =>
            setData({
              ...data,
              viewCheckpoints: !data.viewCheckpoints
            })
          }
        />
        <Checkbox
          label={t('view-summary', { ns: 'common' })}
          checked={data.viewSummary === true}
          onChange={() =>
            setData({
              ...data,
              viewSummary: !data.viewSummary
            })
          }
        />
        <Checkbox
          label={t('view-perspectives', { ns: 'common' })}
          checked={data.viewPerspectives === true}
          onChange={() =>
            setData({
              ...data,
              viewPerspectives: !data.viewPerspectives
            })
          }
        />
        {isDOSReport && (
          <>
            <Checkbox
              label={t('view-deviations', { ns: 'common' })}
              checked={data.viewDeviations === true}
              onChange={() =>
                setData({
                  ...data,
                  viewDeviations: !data.viewDeviations
                })
              }
            />
            <Checkbox
              label={t('view-notes', { ns: 'common' })}
              checked={data.viewNotes === true}
              onChange={() =>
                setData({
                  ...data,
                  viewNotes: !data.viewNotes
                })
              }
            />
          </>
        )}
      </StyledDiv>
    </FormModal>
  )
}
