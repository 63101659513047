import { StyledContainer } from './container.styles'
import type { ContainerProps } from './container.types'

export const Container = ({
  children,
  className,
  fluid,
  fullHeight = false,
  fullHeightGrid = false,
  paddingOnColumns = true,
  paddingOnContainer = true,
  scrollY
}: Readonly<ContainerProps>): JSX.Element => {
  return (
    <StyledContainer
      scrollY={scrollY}
      className={className}
      fluid={fluid}
      fullHeight={fullHeight}
      fullHeightGrid={fullHeightGrid}
      paddingOnColumns={paddingOnColumns}
      paddingOnContainer={paddingOnContainer}
    >
      {children}
    </StyledContainer>
  )
}
