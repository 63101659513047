import type { AuditPoint } from '@cdab/scania/qpr/schema'
import { capitalizeFirstLetter, formatISODate } from '@cdab/utils'
import { useTranslation } from 'react-i18next'
import { Text, Title } from '../deviation-view-data/deviation-view-data.styles'

interface DeviationDataViewProps {
  auditPointNumber?: string | undefined
  deviation: string | undefined
  expirationDate: Date | undefined | null
  proposedActions: string | undefined | null
  responsible: string | undefined | null
  approvalDate: Date | null | undefined
  withActionPlan: boolean | undefined | null
  defaultAuditPoint: AuditPoint | undefined
}

export function DeviationDataView({
  auditPointNumber,
  deviation,
  expirationDate,
  proposedActions,
  withActionPlan,
  approvalDate,
  responsible,
  defaultAuditPoint
}: Readonly<DeviationDataViewProps>): JSX.Element {
  const { t } = useTranslation('common')
  const formattedApprovalDate = approvalDate ? formatISODate(approvalDate) : ''

  return (
    <>
      {!defaultAuditPoint && auditPointNumber && (
        <>
          <Title>{capitalizeFirstLetter(t('audit-point'))}</Title>
          <Text>{auditPointNumber}</Text>
        </>
      )}
      <Title>{capitalizeFirstLetter(t('deviation'))}</Title>
      <Text> {deviation} </Text>

      {withActionPlan && (
        <>
          <Title>
            {capitalizeFirstLetter(t('proposed-action', { ns: 'audit' }))}
          </Title>
          <Text>{proposedActions}</Text>

          <Title>
            {capitalizeFirstLetter(t('responsible', { ns: 'audit' }))}
          </Title>
          <Text>{responsible}</Text>

          <Title>{t('due-date')}</Title>
          <Text>{expirationDate ? formatISODate(expirationDate) : ''}</Text>
        </>
      )}

      <Title>{capitalizeFirstLetter(t('completed'))}</Title>
      <Text>
        {approvalDate === null
          ? capitalizeFirstLetter(t('not-completed'))
          : formattedApprovalDate}
      </Text>
    </>
  )
}
