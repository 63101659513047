import invariant from 'tiny-invariant'
import type { BackEnd } from '@cdab/scania/qpr/interactor'

import { getAudit } from '../store/audits'
import type { OperationData } from './create-operation-from-data'
import type { AuditModel } from '../audit-model'

export type OperationDataBase<TType extends string, TData extends object> = {
  value: TData
  type: TType
  previousValue?: TData
}

export abstract class Operation {
  public readonly auditId: AuditModel['id']
  public abstract data: OperationData
  public readonly guid: string

  constructor(auditId: AuditModel['id'], guid: string) {
    this.auditId = auditId
    this.guid = guid
  }

  public get Type() {
    return this.data.type
  }

  public abstract ApplyTo(audit: AuditModel): void

  /**
   * Apply always run BEFORE SendOperation
   */
  public Apply = () => {
    const audit = getAudit(this.auditId)
    invariant(audit, 'Audit was not set when trying to apply operation!')

    this.ApplyTo(audit)
  }

  /**
   * Returns a name unique for this kind of operation.
   * The same kind of operation but with different values get the same name.
   */
  public abstract GetName(): string

  /**
   * Returns true if operation is OK, false otherwise
   */
  public abstract SendOperation(client: BackEnd): Promise<boolean>

  public abstract RollbackOn(audit: AuditModel): void

  public Rollback = () => {
    const audit = getAudit(this.auditId)
    invariant(audit, 'Audit was not set when trying to roll back operation')

    this.RollbackOn(audit)
  }

  public OnConfirm() {
    // Do nothing, keep this if children want to override
  }

  public GetData = async () => JSON.parse(JSON.stringify(this))
}
