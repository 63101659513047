import { Block } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const EmptyScreenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
`

export const StyledBlock = styled(Block)`
  display: flex;
  flex-direction: column;
`
export const FormRow = styled.div.attrs({
  className: 'sdds-u-mb1'
})``

export const StyledDiv = styled.div`
  // Here I want ot use css variable, but it does not work https://bholmes.dev/blog/alternative-to-css-variable-media-queries/
  // this is --tds-grid-lg
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
  }
`
