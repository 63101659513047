import cx from 'classnames'
import styled from 'styled-components'
import type { InputProps, ToggleProps } from './toggle.types'

type ToggleDivProps = Required<Pick<ToggleProps, 'disabled'>>

export const StyledToggle = styled.div.attrs<ToggleDivProps>(props => ({
  className: cx('sdds-toggle', props.disabled ? 'disabled' : null)
}))<ToggleDivProps>``

export const StyledInput = styled.input.attrs<InputProps>(
  ({ id, checked }) => ({
    id: id,
    checked: checked,
    type: 'checkbox',
    className: 'sdds-toggle-input'
  })
)<InputProps>``

export const StyledLabel = styled.label.attrs({
  className: 'sdds-toggle-label'
})``

export const StyledSpan = styled.span.attrs({
  className: 'sdds-toggle-switch'
})``
