import {
  CenteredContainer,
  ElasticContentContainer,
  IconButton
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import {
  useCssVariableBreakpoint,
  useSddsTableRowClickWithCellValue
} from '@cdab/scania/qpr/hooks'
import { Roles, type User, type UserMarket } from '@cdab/scania/qpr/schema'
import {
  Column,
  Container,
  DataTable,
  EmptyScreen,
  HeaderCell,
  IconWarning,
  Row
} from '@cdab/scania/sdds'
import { enumToTranslationText } from '@cdab/utils'
import { useEffect, useState } from 'react'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import {
  isRouteErrorResponse,
  json,
  useLoaderData,
  useNavigate,
  useRouteError
} from 'react-router'
import { StyledIcon } from '../users/users.styles'

export type AdminUsersLoaderData = {
  users: User[]
}

export async function loader(): Promise<AdminUsersLoaderData> {
  const client = getClient()

  const userInfo = await client.GetuserInfo()
  if (!userInfo) {
    throw json({
      status: 401
    })
  }
  const user = await client.UserService.GetUser(userInfo.userId)
  if (user.role < Roles.FactoryEngineAdmin) {
    throw json({
      status: 403
    })
  }

  try {
    const [users] = await Promise.all([client.UserService.GetUsers()])
    return {
      users
    }
  } catch (error) {
    if (error instanceof Response) {
      throw new Response(null, {
        status: error.status
      })
    } else {
      throw new Response(null, {
        status: 404
      })
    }
  }
}

function getUserMarketsString(
  markets: UserMarket[],
  defaultMarketId: User['defaultMarketId']
) {
  if (markets.length === 1) return markets[0].name

  const MAX_MARKETS_TO_DISPLAY = 3

  const defaultMarket = markets.find(
    market => market.id === defaultMarketId
  )?.name

  let displayedMarkets = markets
    .filter(market => market.id !== defaultMarketId)
    .slice(
      0,
      defaultMarket ? MAX_MARKETS_TO_DISPLAY - 1 : MAX_MARKETS_TO_DISPLAY
    )
    .map(market => market.name)
    .join(', ')

  displayedMarkets =
    markets.length > 3 ? displayedMarkets + '...' : displayedMarkets

  return defaultMarket
    ? `${defaultMarket + ', '}${displayedMarkets}`
    : `${displayedMarkets} `
}

function prepareTableData(data: User[], t: TFunction) {
  const preparedData = data
    .sort((aUser: User, bUser: User) =>
      aUser.active && !bUser.active ? -1 : 1
    )
    .sort(sortByActiveAndName)
    .map(user => {
      return {
        name: `${user.firstName.trim()} ${user.lastName.trim()}`,
        userName: user.userName,
        email: user.email,
        market: getUserMarketsString(user.markets, user.defaultMarketId),
        role: t(enumToTranslationText(Roles[user.role].toString()), {
          ns: 'user'
        }),
        status: user.active ? t('active') : t('inactive'), //FIXME: Use translation
        userId: user.userId
      }
    })

  return preparedData
}

export function sortByActiveAndName(
  { firstName: aFirstName, lastName: aLastName, active: aActive }: User,
  { firstName: bFirstName, lastName: bLastName, active: bActive }: User
) {
  return aActive === bActive
    ? `${aFirstName.trim()} ${aLastName.trim()}`.localeCompare(
        `${bFirstName.trim()} ${bLastName.trim()}`
      )
    : aActive && !bActive
    ? -1
    : 1
}

export function Page() {
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'user'])
  const { updateTitles } = useTitle()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { users } = useLoaderData() as AdminUsersLoaderData
  const [tableEl, setTableEl] = useState<HTMLTableRowElement>()

  useSddsTableRowClickWithCellValue(tableEl, 'userId', userId => {
    navigate(`/admin/users/${userId}`)
  })

  useEffect(() => {
    updateTitles({
      contentHeader: {
        subtitle: t('users'),
        title: t('administration')
      },
      mobile: {
        title: `${t('users')}`,
        description: null
      }
    })
  }, [t])

  return (
    <ElasticContentContainer scrollY overflowHidden>
      <Container fluid='normal'>
        <Row>
          <Column width={12} padding={isLg}>
            <IconButton
              disabled={false}
              onClick={() => navigate('/admin/users/new')}
            >
              <span>{t('add-new')}</span>
              <StyledIcon />
            </IconButton>
          </Column>
          <Column width={12} padding={isLg} scrollX>
            <DataTable
              title={t('all-users')}
              data={prepareTableData(users, t)}
              ref={setTableEl}
              filtering
            >
              <HeaderCell columnKey='name' title={t('name')} />
              <HeaderCell
                columnKey='userName'
                title={t('user-name', { ns: 'user' })}
              />
              <HeaderCell
                columnKey='email'
                title={t('email', { ns: 'user' })}
              />
              <HeaderCell columnKey='market' title={t('market')} />
              <HeaderCell columnKey='role' title={t('role', { ns: 'user' })} />
              <HeaderCell columnKey='status' title={t('status')} />
              <HeaderCell columnKey='userId' title='ID' />
            </DataTable>
          </Column>
        </Row>
      </Container>
    </ElasticContentContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    switch (error.data.status) {
      case 401:
        message = t('not-logged-in')
        break
      case 403:
        message = t('access-denied')
        break
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
