import { useBackend } from '@cdab/scania/qpr/contexts/backend-deviation-handling-provider'
import type {
  CreateADHLinkChatMessageData,
  UploadProgress
} from '@cdab/scania/qpr/interactor'
import type { FileData, UserFile } from '@cdab/scania/qpr/schema'

import { action } from 'mobx'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export type ADHUploadedChatMessageFile = FileData & {
  local_file_url: string
}

export type ADHCreateChatMessageFileState =
  | {
      status: 'success'
      chatMessageId: number
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'uploading'
      progress: number
    }
  | {
      status: 'none'
      message: null
    }

export function useADHCreateChatMessageWithFile() {
  const { t } = useTranslation('ChatMessages')
  const [submitState, setSubmitState] = useState<ADHCreateChatMessageFileState>(
    {
      status: 'none',
      message: null
    }
  )

  const [uploadedFiles, setUploadedFiles] = useState<
    ADHUploadedChatMessageFile[]
  >([])
  const client = useBackend()

  const createChatMessageWithoutFile = async (
    data: CreateADHLinkChatMessageData
  ) => {
    const createPromise = client.ADHService.CreateADHLinkChatMessage({
      ...data
    })

    const [createResult] = await Promise.allSettled([createPromise])

    if (createResult.status === 'rejected') {
      setSubmitState({
        status: 'error',
        message: t('message.error-create')
      })
      return
    }

    setSubmitState({
      status: 'success',
      chatMessageId: createResult.value,
      message: t('message.success-create')
    })
  }

  const uploadFileAndCreateChatMessage = async (
    data: CreateADHLinkChatMessageData,
    chatMessageFile: UserFile
  ) => {
    const uploadPromise = client.ADHService.UploadChatFile(
      chatMessageFile.file,
      action((progressEvent: UploadProgress) => {
        if (typeof progressEvent.progress === 'undefined') return
        setSubmitState({
          status: 'uploading',
          progress: progressEvent.progress
        })
      })
    )

    const createPromise = client.ADHService.CreateADHLinkChatMessage({
      ...data,
      fileId: Number.parseInt((await uploadPromise).fileData?.id ?? '0')
    })

    const [uploadResult, createResult] = await Promise.allSettled([
      uploadPromise,
      createPromise
    ])

    return { uploadResult, createResult }
  }

  const handleClickUploadAndCreateChatMessage = async (
    data: CreateADHLinkChatMessageData,
    chatMessageFile: UserFile | undefined
  ) => {
    if (!chatMessageFile) {
      createChatMessageWithoutFile(data)

      return
    }

    setSubmitState({
      status: 'uploading',
      progress: 0
    })

    const { uploadResult, createResult } = await uploadFileAndCreateChatMessage(
      data,
      chatMessageFile
    )

    if (uploadResult.status === 'rejected') {
      setSubmitState({
        status: 'error',
        message: t('message.error-upload')
      })
      return
    }

    if (
      createResult.status === 'rejected' &&
      uploadResult.status === 'fulfilled'
    ) {
      const fileId = Number.parseInt(uploadResult.value.fileData?.id ?? '0')
      if (fileId > 0) {
        await client.ADHService.DeleteChatFile(fileId)
      }
      setSubmitState({
        status: 'error',
        message: t('message.error-create')
      })
      return
    }

    if (uploadResult.value.success && uploadResult.value.fileData) {
      const newUploadedFiles = uploadedFiles
      newUploadedFiles.push({
        ...uploadResult.value.fileData,
        local_file_url: chatMessageFile.url
      })

      setUploadedFiles(newUploadedFiles)
    }

    if (createResult.status === 'fulfilled') {
      setSubmitState({
        status: 'success',
        chatMessageId: createResult.value,
        message: t('message.success-create')
      })
    } else {
      setSubmitState({
        status: 'error',
        message: t('message.error-create')
      })
    }
  }

  return [
    submitState,
    uploadedFiles,
    handleClickUploadAndCreateChatMessage,
    setSubmitState
  ] as const
}
