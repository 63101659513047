import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export interface ADHDeviationWithUnreadMessages {
  actionPlanItemId: number
  auditPointId: number
  auditPointNo: string
  auditPointDescription: string
  deviation: string
  countOfMessages: number
}

export interface ADHDeviationWithUnreadMessagesJson {
  actionPlanItemId: number
  auditPointId: number
  auditPointNo: string
  auditPointDescription: string
  deviation: string
  countOfMessages: number
}

export const ADHDeviationWithUnreadMessagesSchema: JSONSchemaType<ADHDeviationWithUnreadMessagesJson> =
  {
    type: 'object',

    properties: {
      actionPlanItemId: {
        type: 'integer'
      },
      auditPointId: {
        type: 'integer'
      },
      auditPointNo: {
        type: 'string'
      },
      auditPointDescription: {
        type: 'string'
      },
      deviation: {
        type: 'string'
      },
      countOfMessages: {
        type: 'integer'
      }
    },

    required: [
      'actionPlanItemId',
      'auditPointId',
      'auditPointNo',
      'auditPointDescription',
      'deviation',
      'countOfMessages'
    ]
  }

const validateADHDeviationWithUnreadMessages: ValidateFunction<ADHDeviationWithUnreadMessagesJson> =
  ajv.compile(ADHDeviationWithUnreadMessagesSchema)

export const isValidADHDeviationWithUnreadMessages = (
  actionPlanItem: unknown
): actionPlanItem is ADHDeviationWithUnreadMessagesJson =>
  isValidThing<ADHDeviationWithUnreadMessagesJson>(
    actionPlanItem,
    validateADHDeviationWithUnreadMessages
  )
