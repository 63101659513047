import type { FileData } from '@cdab/scania/qpr/schema'

export function syncRemovedFiles(
  targetFileArray: FileData[],
  updatedFilesArray: FileData[]
) {
  const filesCopy = new Set(targetFileArray)
  filesCopy.forEach(file => {
    if (file.isUploaded === false) return

    // If a file exists in filesCopy, but not in updatedFilesArray, remove it from targetFileArray
    const indexInUpdatedArray = updatedFilesArray.findIndex(
      updatedFile => updatedFile.id === file.id
    )
    if (indexInUpdatedArray !== -1) return

    const index = targetFileArray.findIndex(
      targetFile => targetFile.id === file.id
    )
    targetFileArray.splice(index, 1)
  })
}
