import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export interface Area {
  id: number
  name: string
}

export const AreaSchema: JSONSchemaType<Area> = {
  type: 'object',

  properties: {
    id: {
      type: 'number'
    },
    name: {
      type: 'string'
    }
  },

  required: ['id', 'name']
}

const validateArea: ValidateFunction<Area> = ajv.compile(AreaSchema)

export const isValidArea = (area: unknown): area is Area =>
  isValidThing<Area>(area, validateArea)
