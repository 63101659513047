import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type CreateSyncSISSyncState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

export function useSendCreateSyncDealersForMarket() {
  const client = useBackend()

  const createSyncDealersForMarket = async (
    marketId: number
  ): Promise<number> => {
    const response = await client.MarketsService.SyncDealersForMarket(marketId)

    return response
  }

  return {
    createSyncDealersForMarket
  }
}

export function useCreateSyncDealersForMarket(marketId: number) {
  const { t } = useTranslation('market')
  const { createSyncDealersForMarket } = useSendCreateSyncDealersForMarket()
  const [createSISSyncState, setCreateSISSyncState] =
    useState<CreateSyncSISSyncState>({
      status: 'none',
      message: null
    })

  const handleClickCreateSISSyncState = useCallback(async () => {
    try {
      const response = await createSyncDealersForMarket(marketId)

      setCreateSISSyncState({ status: 'none', message: null })

      if (response && typeof response === 'number' && response > 0) {
        setCreateSISSyncState({
          status: 'success',
          message: t('message.dealers-synced')
        })
        return response
      }

      setCreateSISSyncState({
        status: 'error',
        message: t('message.sync-dealers-failed-error')
      })
      return null
    } catch (error) {
      setCreateSISSyncState({
        status: 'error',
        message: t('message.sync-dealers-failed-error')
      })
      return null
    }
  }, [createSyncDealersForMarket, marketId, t])
  return [handleClickCreateSISSyncState] as const
}
