import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

import { AuditPointSchema } from '../audit-point'
import { CheckPointSchema } from '../checkpoint'
import { DeviationSchema } from '../deviation'
import { PledgeSchema } from '../pledge'
import { PledgeAreaSchema } from '../pledge-area'
import { ReferenceDocumentSchema } from '../reference-document'

import type { AuditPoint } from '../audit-point'
import type { CheckPoint } from '../checkpoint'
import type { Deviation } from '../deviation'
import type { Pledge } from '../pledge'
import type { PledgeArea } from '../pledge-area'
import type { PledgePerspective } from '../pledge-perspective'
import { PledgePerspectiveSchema } from '../pledge-perspective'
import type { ReferenceDocument } from '../reference-document'

export enum AuditCreateError {
  UserWithIdNotAllowedToCreateAudit = -3,
  NotPossibleCreateAuditforDos5 = -4
}
export enum AuditCertifyError {
  AuditIsArchived = 'AuditIsArchived',
  DealerIsInvalid = 'DealerIsInvalid',
  UnfilledDevelopmentPoints = 'UnfilledDevelopmentPoints',
  UnfulfilledMandatoryPoints = 'UnfulfilledMandatoryPoints',
  UnfulfilledDeviations = 'UnfulfilledDeviations',
  NoError = 'NoError',
  NotValidAuditType = 'NotValidAuditType'
}

export enum AuditTypes {
  CertificationAudit = 1,
  NormativeAudit = 2,
  InterimAudit = 3,
  PreparationAudit = 4
}

type AuditCommon = {
  description: string | null
  id: number // This should ideally be string, but the old SQL database uses integers :(
  did: number
  note: string | null
  isCertified: boolean
  isReadonly: boolean
  dosVersion: number
  extraAuditors: string
  pledgePerspectives: PledgePerspective[]
  pledgeAreas: PledgeArea[]
  pledges: Pledge[]
  auditPoints: AuditPoint[]
  checkPoints: CheckPoint[]
  referenceDocuments: ReferenceDocument[]
  deviations: Deviation[]
  auditTypeId: number
}

export type Audit = AuditCommon & {
  date: Date
}

export type AuditJson = {
  date: string | null
} & AuditCommon

export const AuditSchema: JSONSchemaType<AuditJson> = {
  type: 'object',

  properties: {
    date: {
      type: 'string',
      format: 'iso-date-time',
      nullable: true
    },
    description: {
      type: 'string',
      nullable: true
    },
    id: {
      type: 'number'
    },
    did: {
      type: 'number'
    },
    isCertified: {
      type: 'boolean'
    },
    isReadonly: {
      type: 'boolean'
    },
    note: {
      type: 'string',
      nullable: true
    },
    dosVersion: {
      type: 'number',
      nullable: false
    },
    extraAuditors: {
      type: 'string'
    },
    pledgeAreas: {
      type: 'array',
      items: PledgeAreaSchema
    },
    pledges: {
      type: 'array',
      items: PledgeSchema
    },
    pledgePerspectives: {
      type: 'array',
      items: PledgePerspectiveSchema
    },
    auditPoints: {
      type: 'array',
      items: AuditPointSchema
    },
    checkPoints: {
      type: 'array',
      items: CheckPointSchema
    },
    referenceDocuments: {
      type: 'array',
      items: ReferenceDocumentSchema
    },
    deviations: {
      type: 'array',
      items: DeviationSchema as JSONSchemaType<Deviation>
    },
    auditTypeId: {
      type: 'number'
    }
  },

  required: [
    'date',
    'description',
    'id',
    'note',
    'pledgeAreas',
    'pledges',
    'auditPoints',
    'checkPoints',
    'referenceDocuments',
    'deviations',
    'auditTypeId'
  ]
}

const validateAudit: ValidateFunction<AuditJson> = ajv.compile(AuditSchema)

export const isValidAudit = (audit: unknown): audit is Audit =>
  isValidThing<AuditJson>(audit, validateAudit)
