import { InlineTabs, Row } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const StyledRow = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
`

export const EmptyScreenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
`

export const StyledInlineTabs = styled(InlineTabs)`
  margin-top: 1rem;
`

export const ActionRow = styled.div`
  align-items: center;
  background: white;
  background: white;
  border-bottom: 1px solid #dbdfe6;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 0;
  padding: 2rem;
  height: 15rem;
`

export const ActionRowMobile = styled.div`
  align-items: center;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 0;
  padding: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  height: 10rem;
`

export const Left = styled.div``

export const Right = styled.div`
  justify-self: end;
`

export const ActionRowTitle = styled.h4`
  margin: 0;
  text-transform: capitalize;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`
