import classNames from 'classnames'
import type { Breakpoints, Offset } from './column.types'

export const calculateSizes = (props: Breakpoints): string => {
  const sddsSizes = {
    xs: props.width,
    sm: props.sm,
    md: props.md,
    lg: props.lg,
    xlg: props.xlg,
    xxlg: props.xxlg,
    max: props.max
  }

  sddsSizes.sm = sddsSizes.sm || sddsSizes.xs
  sddsSizes.md = sddsSizes.md || sddsSizes.sm
  sddsSizes.lg = sddsSizes.lg || sddsSizes.md
  sddsSizes.xlg = sddsSizes.xlg || sddsSizes.lg
  sddsSizes.xxlg = sddsSizes.xxlg || sddsSizes.xlg
  sddsSizes.max = sddsSizes.max || sddsSizes.xlg

  const classes = Object.entries(sddsSizes).map(e => `sdds-col-${e[0]}-${e[1]}`)

  return classNames(classes)
}

export const calculateOffset = (props: Offset): string => {
  const sddsOffset = {
    xs: props.xs,
    sm: props.sm,
    md: props.md,
    lg: props.lg,
    xlg: props.xlg,
    xxlg: props.xxlg,
    max: props.max
  }

  sddsOffset.sm = sddsOffset.sm || sddsOffset.xs
  sddsOffset.md = sddsOffset.md || sddsOffset.sm
  sddsOffset.lg = sddsOffset.lg || sddsOffset.md
  sddsOffset.xlg = sddsOffset.xlg || sddsOffset.lg
  sddsOffset.xxlg = sddsOffset.xxlg || sddsOffset.xlg
  sddsOffset.max = sddsOffset.max || sddsOffset.xlg

  const classes = Object.entries(sddsOffset).map(
    offset => `sdds-col-${offset[0]}-${offset[1]}-offset`
  )

  return classNames(classes)
}
