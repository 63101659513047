import cx from 'classnames'
import type { AccordionItemProps } from './accordion-item.types'
import type { AccordionProps } from './accordion.types'

export function AccordionItem({
  children,
  expanded,
  affix,
  header
}: AccordionItemProps): JSX.Element {
  return (
    <sdds-accordion-item
      affix={affix}
      role='region'
      header={header}
      expanded={expanded ? 'true' : 'false'}
    >
      {children}
    </sdds-accordion-item>
  )
}

export function Accordion({
  children,
  onWhite,
  paddingReset = false
}: AccordionProps): JSX.Element {
  const classNames = cx({ 'sdds-on-white-bg': onWhite })

  return (
    <sdds-accordion
      className={classNames}
      padding-reset={paddingReset ? 'true' : 'false'}
    >
      {children}
    </sdds-accordion>
  )
}
