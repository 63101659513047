import { useCallback, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectFileButton } from './upload-file-button.styles'
import type { UploadFileButtonProps } from './upload-file-button.types'

export function UploadFileButton(props: Readonly<UploadFileButtonProps>) {
  const { t } = useTranslation()
  const {
    className,
    multiple = true,
    onUploadFiles,
    size,
    variant = 'primary',
    text = t('select-files')
  } = props
  const onChangeFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        onUploadFiles(e.target.files)
      }
      e.target.value = ''
    },
    [onUploadFiles]
  )

  return (
    <SelectFileButton variant={variant} size={size} className={className}>
      {text}
      <input
        title={t('select-files', { ns: 'common' })}
        type='file'
        multiple={multiple}
        onChange={onChangeFile}
      />
    </SelectFileButton>
  )
}
