import type {
  StorageService as IStorageService,
  OnUploadProgressUpdate,
  UploadDealerFileResponse,
  UploadFileResponse
} from '@cdab/scania/qpr/interactor'
import type {
  AdminReferenceDocument,
  Audit,
  DealerFileData,
  DocumentFileData,
  FileData
} from '@cdab/scania/qpr/schema'
import {
  dealerFileJsonArrayToDealerFileArray,
  dealerFileJsonToDealerFile,
  documentFileDataJsonToFileData,
  fileDataJsonArrayToFileDataArray,
  fileDataJsonToFileData,
  isValidDealerFile,
  isValidDealerFileDataArray,
  isValidDocumentFileData,
  isValidFileData,
  isValidFileDataArray
} from '@cdab/scania/qpr/schema'
import { axiosErrorToError, isNumber } from '@cdab/utils'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { ApiBase } from './api-base'
import type { Configuration } from './generated-swagger-client'
import { StorageApi } from './generated-swagger-client'

const createInvalidFileDataError = () => new Error('Invalid file data')

const createInvalidDealerFileError = () => new Error('Invalid dealer file data')

export class StorageService extends ApiBase implements IStorageService {
  private storageApi: StorageApi

  constructor(configuration: Configuration, axiosInstance: AxiosInstance) {
    super(configuration, axiosInstance)
    this.storageApi = new StorageApi(
      configuration,
      configuration.basePath,
      axiosInstance
    )
  }
  DeleteFileForDealer = async (
    dealerId: number,
    fileId: string
  ): Promise<void> => {
    const fileID = Number.parseInt(fileId)

    if (!isNumber(fileID)) throw new Error('File Id is not a number')

    await this.storageApi.apiStorageDeleteDealerDealerIdFileFileIdDelete(
      dealerId,
      fileID
    )
  }
  GetDealerFilesForDealer = async (
    dealerId: number
  ): Promise<DealerFileData[]> => {
    const res = await this.storageApi.apiStorageListFilesDealerDealerIdGet(
      dealerId
    )

    const dealerFileArray = res.data

    if (!isValidDealerFileDataArray(dealerFileArray))
      throw createInvalidDealerFileError()

    return dealerFileJsonArrayToDealerFileArray(dealerFileArray)
  }

  DeleteFileForDeviation = async (
    deviationId: number,
    sFileId: string
  ): Promise<void> => {
    const fileId = Number.parseInt(sFileId)

    if (!isNumber(fileId)) throw new Error('File Id is not a number')

    await this.storageApi.apiStorageDeleteDeviationDeviationIdFileFileIdDelete(
      deviationId,
      fileId
    )
  }

  DeleteFileForAuditPoint = async (
    auditId: number,
    auditPointId: number,
    sFileId: string
  ): Promise<void> => {
    const fileId = Number.parseInt(sFileId)

    if (!isNumber(fileId)) throw new Error('File Id is not a number')

    await this.storageApi.apiStorageDeleteAuditAuditIdAuditPointAuditPointIdFileFileIdDelete(
      auditId,
      auditPointId,
      fileId
    )
  }

  DeleteFileForAudit = async (
    auditId: number,
    sFileId: FileData['id']
  ): Promise<void> => {
    const fileId = Number.parseInt(sFileId)

    if (!isNumber(fileId)) throw new Error('File Id is not a number')

    await this.storageApi.apiStorageDeleteAuditAuditIdFileFileIdDelete(
      auditId,
      fileId
    )
  }

  DeleteFileForPledge = async (
    auditId: number,
    pledgeId: number,
    sFileId: string
  ): Promise<void> => {
    const fileId = Number.parseInt(sFileId)

    if (!isNumber(fileId)) throw new Error('File Id is not a number')

    await this.storageApi.apiStorageDeleteAuditAuditIdPledgePledgeIdFileFileIdDelete(
      auditId,
      pledgeId,
      fileId
    )
  }

  GetAllFiles = async (): Promise<string[]> => {
    const res = await this.storageApi.apiStorageListFilesGet()

    if (res.status === 200) {
      return res.data
    }

    throw new Error(
      'StorageService.GetAllFiles: Invalid response status code: ' +
        res.status.toString()
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  async GetFile(path: string): Promise<unknown> {
    throw new Error('Method not implemented.')
  }

  UploadFileForDealer = async (
    dealerId: number,
    file: File,
    onProgressUpdate?: OnUploadProgressUpdate
  ): Promise<UploadDealerFileResponse> => {
    const res = await this.storageApi.apiStorageUploadFilesDealerDealerIdPost(
      dealerId,
      file,
      {
        onUploadProgress: onProgressUpdate
      }
    )
    const { fileData: dealerFileJson, success } = res.data

    if (success === false) {
      return {
        success: false,
        dealerFile: null
      }
    }

    if (!isValidDealerFile(dealerFileJson)) throw createInvalidDealerFileError()

    return {
      success: true,
      dealerFile: dealerFileJsonToDealerFile(dealerFileJson)
    }
  }

  UploadFileForDeviation = async (
    auditId: number,
    deviationId: number,
    file: File,
    onProgressUpdate?: OnUploadProgressUpdate
  ): Promise<UploadFileResponse> => {
    // FIXME: POST is probably not the best way to do this. Large files will time out, especially on bad connections.
    // We should probably stream the data somehow...
    // But that's an implementation detail, it shouldn't be difficult to change since it would use the same API
    const res =
      await this.storageApi.apiStorageUploadFileDeviationDeviationIdPost(
        deviationId,
        file,
        {
          onUploadProgress: onProgressUpdate
        }
      )

    const { fileData: fileDataJson, success } = res.data

    if (success === false) {
      return {
        success: false,
        fileData: null
      }
    }

    if (!isValidFileData(fileDataJson)) throw createInvalidFileDataError()

    return {
      success: true,
      fileData: fileDataJsonToFileData(fileDataJson)
    }
  }

  GetFilesForDeviation = async (deviationId: number): Promise<FileData[]> => {
    const res =
      await this.storageApi.apiStorageListFilesDeviationDeviationIdGet(
        deviationId
      )

    const fileDataArray = res.data

    if (!isValidFileDataArray(fileDataArray)) throw createInvalidFileDataError()

    return fileDataJsonArrayToFileDataArray(fileDataArray)
  }

  async GetFilesForAuditPoint(
    auditId: number,
    auditPointId: number
  ): Promise<FileData[]> {
    const res =
      await this.storageApi.apiStorageListFilesAuditAuditIdAuditPointAuditPointIdGet(
        auditId,
        auditPointId
      )

    const fileDataArray = res.data

    if (!isValidFileDataArray(fileDataArray)) throw createInvalidFileDataError()

    return fileDataJsonArrayToFileDataArray(fileDataArray)
  }

  GetFilesForAudit = async (auditId: Audit['id']): Promise<FileData[]> => {
    const res = await this.storageApi.apiStorageListFilesAuditAuditIdGet(
      auditId
    )
    const fileDataArray = res.data

    if (!isValidFileDataArray(fileDataArray)) throw createInvalidFileDataError()

    return fileDataJsonArrayToFileDataArray(fileDataArray)
  }

  async GetFilesForPledge(
    auditId: number,
    pledgeId: number
  ): Promise<FileData[]> {
    const res =
      await this.storageApi.apiStorageListFilesAuditAuditIdPledgePledgeIdGet(
        auditId,
        pledgeId
      )

    const fileDataArray = res.data

    if (!isValidFileDataArray(fileDataArray)) throw createInvalidFileDataError()

    return fileDataJsonArrayToFileDataArray(fileDataArray)
  }

  DeleteFile = async (fileId: number) => {
    await this.storageApi.apiStorageUploadFilesFileIdDelete(fileId)
  }

  async UploadFileForAuditPoint(
    auditId: number,
    auditPointId: number,
    file: File,
    onUploadProgress?: OnUploadProgressUpdate
  ): Promise<UploadFileResponse> {
    const res =
      await this.storageApi.apiStorageUploadFilesAuditAuditIdAuditPointAuditPointIdPost(
        auditId,
        auditPointId,
        file,
        {
          onUploadProgress
        }
      )

    const { fileData: fileDataJson, success } = res.data

    if (success === false) {
      return {
        success: false,
        fileData: null
      }
    }

    if (!isValidFileData(fileDataJson)) throw createInvalidFileDataError()

    return {
      success: true,
      fileData: fileDataJsonToFileData(fileDataJson)
    }
  }
  UploadFileForAudit = async (
    auditId: Audit['id'],
    file: File,
    onUploadProgress?: OnUploadProgressUpdate | undefined
  ): Promise<UploadFileResponse> => {
    const res = await this.storageApi.apiStorageUploadFilesAuditAuditIdPost(
      auditId,
      file,
      {
        onUploadProgress
      }
    )

    const { fileData: fileDataJson, success } = res.data

    if (success === false) {
      return {
        success: false,
        fileData: null
      }
    }

    if (!isValidFileData(fileDataJson)) throw createInvalidFileDataError()

    return {
      success: true,
      fileData: fileDataJsonToFileData(fileDataJson)
    }
  }

  async UploadFileForPledge(
    auditId: number,
    pledgeId: number,
    file: File,
    onUploadProgress?: OnUploadProgressUpdate
  ): Promise<UploadFileResponse> {
    const res =
      await this.storageApi.apiStorageUploadFilesAuditAuditIdPledgePledgeIdPost(
        auditId,
        pledgeId,
        file,
        {
          onUploadProgress
        }
      )

    const { fileData: fileDataJson, success } = res.data

    if (success === false) {
      return {
        success: false,
        fileData: null
      }
    }

    if (!isValidFileData(fileDataJson)) throw createInvalidFileDataError()

    return {
      success: true,
      fileData: fileDataJsonToFileData(fileDataJson)
    }
  }

  async GetDocumentFile(fileId: number): Promise<DocumentFileData> {
    const res = await this.storageApi.apiStorageGetFileDocumentFileIdGet(fileId)

    const documentFileData = res.data

    if (!isValidDocumentFileData(documentFileData))
      throw createInvalidFileDataError()

    return documentFileDataJsonToFileData(res.data)
  }

  async UploadDocumentFile(
    file: File,
    onUploadProgress?: OnUploadProgressUpdate
  ): Promise<number> {
    const response = await this.storageApi.apiStorageUploadFileDocumentPost(
      file,
      {
        onUploadProgress
      }
    )

    return response.data
  }

  async DeleteDocumentFile(fileId: number): Promise<void> {
    await this.storageApi.apiStorageDeleteDocumentDelete(fileId)
  }

  async ReplaceDocumentFile(
    documentId: AdminReferenceDocument['id'],
    file: File,
    onUploadProgress?: OnUploadProgressUpdate
  ): Promise<number> {
    const response = await this.storageApi.apiStorageReplaceDocumentPut(
      documentId,
      file,
      {
        onUploadProgress
      }
    )

    return response.data
  }

  async GetFileUrl(key: string): Promise<string | undefined> {
    try {
      const response = await this.storageApi.apiStorageGetFileUrlKeyGet(key)

      return response.data
    } catch {
      return undefined
    }
  }

  public GetADHUserFilesForDeviation = async (
    actionPlanItemId: number,
    options?: AxiosRequestConfig
  ): Promise<FileData[]> => {
    try {
      const response = await this.storageApi.apiStorageADHListFilesDeviationGet(
        actionPlanItemId,
        options
      )

      const fileDataArray = response.data

      if (!isValidFileDataArray(fileDataArray))
        throw new Error('Invalid file data')

      return fileDataJsonArrayToFileDataArray(fileDataArray)
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }
}
