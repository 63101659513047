/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TranslationsService } from '@cdab/scania/qpr/interactor'
import type { AdminTranslations, Translations } from '@cdab/scania/qpr/schema'
import type { AxiosRequestConfig } from 'axios'

export class MockTranslationsService implements TranslationsService {
  delay: number
  constructor(delay: number) {
    this.delay = delay
  }

  GetTranslations(
    languageId: number,
    dosVersion: number
  ): Promise<Translations> {
    return Promise.resolve({})
  }

  GetAdminTranslations(
    languageId: number,
    dosVersion: number,
    options?: AxiosRequestConfig
  ): Promise<AdminTranslations> {
    throw new Error('Not implemented')
  }

  UpdateTranslation(
    translationId: number,
    translationText: string,
    options?: AxiosRequestConfig
  ): Promise<void> {
    throw new Error('Not implemented')
  }

  CreateTranslation(
    languageId: number,
    translationGuid: string,
    translatedText: string
  ): Promise<number> {
    throw new Error('Not implemented')
  }
}
