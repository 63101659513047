import styled from 'styled-components'
import { CapitalizeFirstLetter } from '@cdab/headless-components'

export interface StatusBadgeProps {
  text: string
  sddsColorVar: string
  className?: string
}

const StyledStatusBadge = styled.div<StatusBadgeProps>`
  display: inline-block;
  background: var(${props => props.sddsColorVar});
  color: white;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 999px;
`

export function StatusBadge(props: StatusBadgeProps) {
  return (
    <StyledStatusBadge {...props}>
      <CapitalizeFirstLetter>{props.text}</CapitalizeFirstLetter>
    </StyledStatusBadge>
  )
}

export default StatusBadge
