import type { GridItemType } from '@cdab/scania/qpr/settings'

import type { AuditGridItemProps } from './types'
import {
  AuditGridItemCollapsible,
  AuditGridItemHeader,
  AuditGridItemHidden,
  AuditGridItemPoint
} from './grid-item-components'

export function getAuditGridItem(
  gridItemType: GridItemType
): (props: AuditGridItemProps) => JSX.Element {
  switch (gridItemType) {
    case 'header':
      return AuditGridItemHeader
    case 'hidden':
      return AuditGridItemHidden
    case 'collapsible':
      return AuditGridItemCollapsible
    case 'point':
      return AuditGridItemPoint
  }
}
