export enum StatusClass {
  /**
   * **Information responses**
   *
   * The server is thinking through the request.
   */
  '1xx' = 1,
  /**
   * **Successful responses**
   *
   * The request was successfully completed and the server gave the browser the expected response. */
  '2xx' = 2,
  /**
   * **Redirection messages**
   *
   * You got redirected somewhere else. The request was received, but there’s a redirect of some kind.
   */
  '3xx' = 3,
  /**
   * **Client error responses**
   *
   * Page not found. The site or page couldn’t be reached. (The request was made, but the page isn’t valid — this is an error on the website’s side of the conversation and often appears when a page doesn’t exist on the site.)
   */
  '4xx' = 4,
  /**
   * **Server errors repsonses**
   *
   * Failure. A valid request was made by the client but the server failed to complete the request.
   */
  '5xx' = 5
}

type Params = {
  range: StatusClass
  statusCode: number
}

export function isHttpStatusCodeClass({ range, statusCode }: Params): boolean {
  const num = range.toString()
  const regEx = new RegExp(`${num}[0-9]{2}$`)
  const code = statusCode.toString()

  return regEx.test(code)
}
