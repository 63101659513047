import type { ReferenceDocument } from '@cdab/scania/qpr/schema'

export const ReferenceDocuments: ReferenceDocument[] = [
  {
    id: 926,
    auditPointId: 856,
    checkPointId: null,
    title: 'Attachment to Overall description FIFO',
    url: 'Attachment to Overall Description FIFO.pdf',
    documentVersion: 2
  },
  {
    id: 927,
    auditPointId: 779,
    checkPointId: null,
    title: 'Bibat On-line',
    url: 'https://bibatonline.scania.com/',
    documentVersion: 2
  },
  {
    id: 928,
    auditPointId: 857,
    checkPointId: null,
    title: 'Bibat On-line/Spare Parts',
    url: 'https://bibatonline.scania.com/index.aspx',
    documentVersion: 3
  },
  {
    id: 929,
    auditPointId: 840,
    checkPointId: null,
    title: 'BTI structure',
    url: 'BTI_structure.pdf',
    documentVersion: 7
  },
  {
    id: 931,
    auditPointId: 805,
    checkPointId: null,
    title: 'Calibration and verification of workshop equipment',
    url: 'Calibration and verification of workshop equipment.pdf',
    documentVersion: 2
  },
  {
    id: 932,
    auditPointId: 872,
    checkPointId: null,
    title: 'Scania Retail System',
    url: 'Scania Retail Ssystem.pdf',
    documentVersion: 6
  },
  {
    id: 933,
    auditPointId: 876,
    checkPointId: null,
    title: 'Competence development - process and tools',
    url: 'Competence development - process and tools.pdf',
    documentVersion: 10
  },
  {
    id: 934,
    auditPointId: 851,
    checkPointId: null,
    title: 'Compulsory Jobs - Trucks Long Haulage',
    url: 'Compulsory Jobs - Trucks Long Haulage 2016-08-18.xlsx',
    documentVersion: 2
  },
  {
    id: 935,
    auditPointId: 803,
    checkPointId: null,
    title: 'Compulsory workshop equipment',
    url: 'Compulsory workshop equipment.pdf',
    documentVersion: 9
  },
  {
    id: 936,
    auditPointId: 871,
    checkPointId: null,
    title: 'Criteria for customer satisfaction surveys',
    url: 'Criteria for customer satisfaction surveys.pdf',
    documentVersion: 4
  },
  {
    id: 937,
    auditPointId: 844,
    checkPointId: null,
    title: 'Current software versions',
    url: 'Current software versions.pdf',
    documentVersion: 89
  },
  {
    id: 939,
    auditPointId: 793,
    checkPointId: null,
    title: 'D inspection sheets (Large pre delivery)',
    url: 'D inspection sheet (Large pre delivery) (sample).pdf',
    documentVersion: 1
  },
  {
    id: 940,
    auditPointId: 793,
    checkPointId: null,
    title: 'D inspection sheets (Small pre delivery)',
    url: 'D inspection sheet Small pre delivery (sample).pdf',
    documentVersion: 1
  },
  {
    id: 941,
    auditPointId: 835,
    checkPointId: null,
    title: 'DCS standard - Additional sales during visit',
    url: 'DCS standard - Additional_sales_during_visit.pdf',
    documentVersion: 2
  },
  {
    id: 942,
    auditPointId: 820,
    checkPointId: null,
    title: 'DCS standard - Arrange appointment',
    url: 'DCS standard - Arrange Appointment.pdf',
    documentVersion: 19
  },
  {
    id: 943,
    auditPointId: 826,
    checkPointId: null,
    title: 'DCS standard - Call customer',
    url: 'DCS standard - Call-Customer.pdf',
    documentVersion: 3
  },
  {
    id: 945,
    auditPointId: 873,
    checkPointId: null,
    title: 'DCS standard - Call customer as follow-up',
    url: 'DCS standard - Call-Customer-as-Follow-up.pdf',
    documentVersion: 2
  },
  {
    id: 946,
    auditPointId: 826,
    checkPointId: null,
    title: 'DCS standard - Call customers based on maintenance',
    url: 'DCS standard - Call-Customer-Based-on-Maintenance.pdf',
    documentVersion: 5
  },
  {
    id: 947,
    auditPointId: 828,
    checkPointId: null,
    title: 'DCS standard - Daily steering',
    url: 'DCS standard - Daily Steering.pdf',
    documentVersion: 6
  },
  {
    id: 948,
    auditPointId: 865,
    checkPointId: null,
    title: 'DCS standard - Goods arrival and binning',
    url: 'DCS standard Goods_arrival_and_binning.pdf',
    documentVersion: 2
  },
  {
    id: 949,
    auditPointId: 845,
    checkPointId: null,
    title: 'DCS standard - Handle Scania Assistance case',
    url: 'DCS standard- Handle Scania Assistance case.pdf',
    documentVersion: 3
  },
  {
    id: 950,
    auditPointId: 830,
    checkPointId: null,
    title: 'DCS standard - Handle work order',
    url: 'DCS standard - Handle work order.pdf',
    documentVersion: 2
  },
  {
    id: 951,
    auditPointId: 836,
    checkPointId: null,
    title: 'DCS standard - Invoice Work Order',
    url: 'DCS standard- Invoice work order.pdf',
    documentVersion: 2
  },
  {
    id: 952,
    auditPointId: 826,
    checkPointId: null,
    title: 'DCS standard - Maintenance plan available',
    url: 'DCS standard - Maintenance-Plan-Available.pdf',
    documentVersion: 2
  },
  {
    id: 953,
    auditPointId: 826,
    checkPointId: null,
    title: 'DCS standard - Monitor maintenance plan',
    url: 'DCS standard - Monitor-Maintenance-Plan.pdf',
    documentVersion: 3
  },
  {
    id: 954,
    auditPointId: 865,
    checkPointId: null,
    title: 'DCS standard - Parts picking on demand picking',
    url: 'DCS standard Parts_picking_on_demand_picking.pdf',
    documentVersion: 4
  },
  {
    id: 955,
    auditPointId: 864,
    checkPointId: null,
    title: 'DCS standard - Pre-picking of parts',
    url: 'DCS standard -  Pre-picking-of-Parts.pdf',
    documentVersion: 3
  },
  {
    id: 956,
    auditPointId: 826,
    checkPointId: null,
    title: 'DCS standard - Remote Diagnostics',
    url: 'DCS standard - Remote-Diagnostics.pdf',
    documentVersion: 3
  },
  {
    id: 957,
    auditPointId: 855,
    checkPointId: null,
    title: 'DCS standard - Review target stock level DSM',
    url: 'DCS standard -  Review target stock level DSM.pdf',
    documentVersion: 3
  },
  {
    id: 958,
    auditPointId: 825,
    checkPointId: null,
    title: 'DCS standard - Vehicle return',
    url: 'DCS standard - Vehicle Return.pdf',
    documentVersion: 4
  },
  {
    id: 959,
    auditPointId: 820,
    checkPointId: null,
    title: 'DCS standard - Walk around vehicle check list (Service checklist)',
    url: 'https://teamroom.scania.com/share/page/site/midas/document-details?nodeRef=workspace://SpacesStore/a560b0f7-35b7-4cce-aac2-41ad8ee17907',
    documentVersion: 2
  },
  {
    id: 960,
    auditPointId: 827,
    checkPointId: null,
    title: 'DCS standard - Work order quality check',
    url: 'DCS standard - Work-Order-Quality-Check.pdf',
    documentVersion: 2
  },
  {
    id: 961,
    auditPointId: 819,
    checkPointId: null,
    title: 'DCS standard - Workshop resource planning',
    url: 'DCS standard - Workshop Resource planning.pdf',
    documentVersion: 3
  },
  {
    id: 962,
    auditPointId: 865,
    checkPointId: null,
    title: 'DCS standard -Stock take planned',
    url: 'DCS standard -Stock take planned.pdf',
    documentVersion: 2
  },
  {
    id: 963,
    auditPointId: 811,
    checkPointId: null,
    title: 'Dealer FRAS User guide',
    url: 'Dealer FRAS User guide.pdf',
    documentVersion: 2
  },
  {
    id: 964,
    auditPointId: 851,
    checkPointId: null,
    title: 'Dealer Stock Management (DSM) (https://dsm.scania.com)',
    url: 'https://dsm.scania.com',
    documentVersion: 2
  },
  {
    id: 965,
    auditPointId: 896,
    checkPointId: null,
    title:
      'E-learning: Doing things right - A guide to ethics at Scania (MyCompass)',
    url: 'https://scania.csod.com/LMS/catalog/Welcome.aspx?tab_page_id=-67&tab_id=-1  ',
    documentVersion: 1
  },
  {
    id: 966,
    auditPointId: 820,
    checkPointId: null,
    title: 'E-learning: General Workshop Process (MyCompass)',
    url: 'https://scania.csod.com/LMS/catalog/Welcome.aspx?tab_page_id=-67&tab_id=-1  ',
    documentVersion: 1
  },
  {
    id: 967,
    auditPointId: 807,
    checkPointId: null,
    title: 'E-learning: ISEC - Scania Information Security (MyCompass).',
    url: 'https://scania.csod.com/LMS/catalog/Welcome.aspx?tab_page_id=-67&tab_id=-1  ',
    documentVersion: 1
  },
  {
    id: 968,
    auditPointId: 878,
    checkPointId: null,
    title: 'E-learning: P_D Conversation (MyCompass)',
    url: 'https://scania.csod.com/LMS/catalog/Welcome.aspx?tab_page_id=-67&tab_id=-1  ',
    documentVersion: 2
  },
  {
    id: 969,
    auditPointId: 896,
    checkPointId: null,
    title: 'E-learning: Scania EU Competition Law Compliance (MyCompass)',
    url: 'https://scania.csod.com/ui/lms-learning-details/app/material/17105f7b-6e88-4398-8ac4-ec2d6174a90b',
    documentVersion: 2
  },
  {
    id: 970,
    auditPointId: 882,
    checkPointId: null,
    title: 'E-learning: Scania Basic Environmental Training (MyCompass)',
    url: 'https://scania.csod.com/ui/lms-learning-details/app/course/51d0427d-e68f-4f02-aae5-aaa2d40d625a',
    documentVersion: 4
  },
  {
    id: 975,
    auditPointId: 834,
    checkPointId: null,
    title: 'E-learning: Technical Packages Introduction (MyCompass)',
    url: 'https://scania.csod.com/LMS/catalog/Welcome.aspx?tab_page_id=-67&tab_id=-1  ',
    documentVersion: 1
  },
  {
    id: 976,
    auditPointId: 839,
    checkPointId: null,
    title: 'E-learning: Warranty (My Compass)',
    url: 'https://scania.csod.com/LMS/catalog/Welcome.aspx?tab_page_id=-67&tab_id=-1  ',
    documentVersion: 1
  },
  {
    id: 977,
    auditPointId: 887,
    checkPointId: null,
    title: 'E-learning: Safety in workshop (MyCompass)',
    url: 'https://scania.csod.com/GlobalSearch/search.aspx?s=&q=Safety%20in%20workshop',
    documentVersion: 2
  },
  {
    id: 980,
    auditPointId: 794,
    checkPointId: null,
    title: 'FRAS application for installation report',
    url: 'https://fras-ext.scania.com',
    documentVersion: 2
  },
  {
    id: 981,
    auditPointId: 768,
    checkPointId: null,
    title: 'Global e-mail signature',
    url: 'Global e-mail signature.pdf',
    documentVersion: 1
  },
  {
    id: 986,
    auditPointId: 788,
    checkPointId: null,
    title: 'Link to Reflex/Order',
    url: 'https://internal.scania.com/commercialsupport/2587.html',
    documentVersion: 17
  },
  {
    id: 987,
    auditPointId: 809,
    checkPointId: null,
    title: 'https://operationalanalysis.scania.com',
    url: 'https://operationalanalysis.scania.com',
    documentVersion: 1
  },
  {
    id: 988,
    auditPointId: 874,
    checkPointId: null,
    title: 'Inquiry CO Way self-assessment (checklist)',
    url: 'Inquiry CO Way self-assessment (checklist).pdf',
    documentVersion: 2
  },
  {
    id: 991,
    auditPointId: 874,
    checkPointId: null,
    title: 'Instruction CO Way assessment (use the checklist)',
    url: 'Instruction CO Way assessment (use the checklist).pdf',
    documentVersion: 2
  },
  {
    id: 992,
    auditPointId: 804,
    checkPointId: null,
    title: 'Routines for Scania tools',
    url: 'Routines for Scania Tools.pdf',
    documentVersion: 3
  },
  {
    id: 994,
    auditPointId: 807,
    checkPointId: null,
    title: 'ISEC Code of Conduct Information Security',
    url: 'Isec Code of Conduct Information Security.pdf',
    documentVersion: 4
  },
  {
    id: 995,
    auditPointId: 875,
    checkPointId: null,
    title: 'Learning Management System (MyCompass)',
    url: 'Link to Internal webpage (Reflex)',
    documentVersion: 1
  },
  {
    id: 996,
    auditPointId: 851,
    checkPointId: null,
    title: 'List of compulsory parts',
    url: 'List of Compulsory Parts.xlsx',
    documentVersion: 4
  },
  {
    id: 997,
    auditPointId: 795,
    checkPointId: null,
    title: 'Local Handshake Checklist',
    url: 'Local handshake checklist.pdf',
    documentVersion: 2
  },
  {
    id: 998,
    auditPointId: 780,
    checkPointId: null,
    title: 'Maintenance area setup standard',
    url: 'Maintenance Area Set up Standard.pdf',
    documentVersion: 1
  },
  {
    id: 999,
    auditPointId: 880,
    checkPointId: null,
    title: 'Necessary conditions for efficient e-learning',
    url: 'Necessary conditions for e-learning.pdf',
    documentVersion: 2
  },
  {
    id: 1000,
    auditPointId: 895,
    checkPointId: null,
    title: 'OECD guidelines for retailers',
    url: 'OECD guidelines for retailers.pdf',
    documentVersion: 3
  },
  {
    id: 1001,
    auditPointId: 856,
    checkPointId: null,
    title: 'Overall description FIFO',
    url: 'Overall description FIFO.pdf',
    documentVersion: 4
  },
  {
    id: 1002,
    auditPointId: 851,
    checkPointId: null,
    title: 'Parts list Scania Engines',
    url: 'Parts list Scania Engines.pdf',
    documentVersion: 7
  },
  {
    id: 1003,
    auditPointId: 817,
    checkPointId: null,
    title: 'Recall Campaigns goal definition for dealers/workshops',
    url: 'Recall Campaigns goal definition for dealers_workshops.xlsx',
    documentVersion: 4
  },
  {
    id: 1005,
    auditPointId: 853,
    checkPointId: null,
    title: 'Retail standard - Scania Parts',
    url: 'Retail Standard - Scania Parts.pdf',
    documentVersion: 1
  },
  {
    id: 1006,
    auditPointId: 887,
    checkPointId: null,
    title: 'Risk assessment safety and health - checklist',
    url: 'Risk assessment safety and health-checklist.pdf',
    documentVersion: 1
  },
  {
    id: 1007,
    auditPointId: 887,
    checkPointId: null,
    title: 'Risk assessment safety and health - method',
    url: 'Risk assessment safety and health-method.pdf',
    documentVersion: 1
  },
  {
    id: 1008,
    auditPointId: 888,
    checkPointId: null,
    title: 'Risk assessment Security and Fire prevention - retail',
    url: 'Risk assessment Security and Fire prevention – retail.pdf',
    documentVersion: 3
  },
  {
    id: 1009,
    auditPointId: 787,
    checkPointId: null,
    title: 'Role description - Service Salesperson',
    url: 'Role description Service Salesperson.pdf',
    documentVersion: 2
  },
  {
    id: 1010,
    auditPointId: 817,
    checkPointId: null,
    title: 'Role description for a Campaign Coordinator',
    url: 'Role  Description Campaign Coordinator.pdf',
    documentVersion: 1
  },
  {
    id: 1011,
    auditPointId: 875,
    checkPointId: null,
    title: 'Role descriptions (Reflex)',
    url: 'https://teamroom.scania.com/share/page/site/role-descriptions-dealers-and-distributers/folder-details?nodeRef=workspace://SpacesStore/e6a2578e-fb2b-4822-a401-ecc1679d034d',
    documentVersion: 2
  },
  {
    id: 1012,
    auditPointId: 810,
    checkPointId: null,
    title: 'Routine for TI communication',
    url: 'Routine for TI communication.pdf',
    documentVersion: 3
  },
  {
    id: 1013,
    auditPointId: 782,
    checkPointId: null,
    title: 'Rules of Engagement (RoE)',
    url: 'Rules of engagement (RoE).pdf',
    documentVersion: 2
  },
  {
    id: 1014,
    auditPointId: 889,
    checkPointId: null,
    title: 'Safety and fire protection in service workshops',
    url: 'Safety and fire protection in service workshops.pdf',
    documentVersion: 9
  },
  {
    id: 1017,
    auditPointId: 846,
    checkPointId: null,
    title: 'Scania Assistance - requirements for dealers and workshops',
    url: 'Scania Assistance Requirements for Dealers and Workshops.pdf',
    documentVersion: 29
  },
  {
    id: 1018,
    auditPointId: 885,
    checkPointId: null,
    title:
      'STD 4160 - List of Prohibited and Restricted Substances in Chemical  Products',
    url: 'https://til.scania.com/w/scs_0001943_99',
    documentVersion: 3
  },
  {
    id: 1019,
    auditPointId: 894,
    checkPointId: null,
    title: 'Scania Code of Conduct',
    url: 'Scania Code of Conduct.pdf',
    documentVersion: 31
  },
  {
    id: 1020,
    auditPointId: 800,
    checkPointId: null,
    title: 'Scania Commercial Facility Standard part number STD4326en',
    url: 'Scania Commercial Facility Standard part number STD4326en.pdf',
    documentVersion: 23
  },
  {
    id: 1021,
    auditPointId: 896,
    checkPointId: null,
    title: 'Scania Competition Policy',
    url: 'Scania Competition Policy.pdf',
    documentVersion: 1
  },
  {
    id: 1022,
    auditPointId: 888,
    checkPointId: null,
    title: 'Scania Security Guidelines',
    url: 'Scania Security Guidelines.pdf',
    documentVersion: 3
  },
  {
    id: 1023,
    auditPointId: 799,
    checkPointId: null,
    title: 'Service description Driver Performance',
    url: 'Service Description Driver Performance.pdf',
    documentVersion: 19
  },
  {
    id: 1024,
    auditPointId: 803,
    checkPointId: null,
    title: 'Scania Engines - Tools and equipment list',
    url: 'Scania Engines - Tools and equipment list.pdf',
    documentVersion: 5
  },
  {
    id: 1025,
    auditPointId: 896,
    checkPointId: null,
    title: 'Competition law compliance (SGP 8)',
    url: 'SGP 8 - Competition law compliance.pdf',
    documentVersion: 4
  },
  {
    id: 1026,
    auditPointId: 889,
    checkPointId: null,
    title: 'Scania Fire Protection Manual',
    url: 'https://teamroom.scania.com/share/page/site/corporate-information-documents/document-details?nodeRef=workspace://SpacesStore/4fff7074-f6c5-4f7c-998e-939caf494cb2',
    documentVersion: 5
  },
  {
    id: 1027,
    auditPointId: 839,
    checkPointId: null,
    title: 'Scania Global Warranty Manual',
    url: 'Scania_Global_Warranty_Manual.pdf',
    documentVersion: 2
  },
  {
    id: 1028,
    auditPointId: 766,
    checkPointId: null,
    title:
      'Scania Identity Manual - Independent companies appendix part number 19MC214',
    url: 'Scania_Identity_Manual_Appendix_Indenpendent companies_19MC214.pdf',
    documentVersion: 70
  },
  {
    id: 1029,
    auditPointId: 767,
    checkPointId: null,
    title:
      'Corporate assortment catalogue - Service vehicle decoration 21MC210',
    url: 'Corporate assortment catalogue_Service vehicle decoration 21MC210.pdf',
    documentVersion: 11
  },
  {
    id: 1030,
    auditPointId: 765,
    checkPointId: null,
    title: 'Corporate assortment catalogue – Signage part number 21MC268',
    url: 'Corporate assortment catalogue – Signage_part number 21MC268.pdf',
    documentVersion: 9
  },
  {
    id: 1031,
    auditPointId: 765,
    checkPointId: null,
    title:
      'Corporate assortment catalogue – Signage for small premises part number 21MC269',
    url: 'Corporate assortment catalogue – Signage for small premises part number 21MC269.pdf',
    documentVersion: 17
  },
  {
    id: 1032,
    auditPointId: 749,
    checkPointId: null,
    title: 'Scania Identity Manual - Signage appendix part number 16MC195',
    url: 'Scania Identity Manual - Signage_appendix part number 16MC195.pdf',
    documentVersion: 10
  },
  {
    id: 1033,
    auditPointId: 768,
    checkPointId: null,
    title: 'Scania Identity Manual - Stationery appendix part number 16MC197',
    url: 'Scania Identity Manual - Stationery appendix part number 16MC197.pdf',
    documentVersion: 4
  },
  {
    id: 1034,
    auditPointId: 744,
    checkPointId: null,
    title:
      'Scania_Identity Manual Appendix Web applications part number 19MC052',
    url: 'Scania_Identity_Manual_Appendix_Web_applications_part number 19MC052.pdf',
    documentVersion: 9
  },
  {
    id: 1035,
    auditPointId: 744,
    checkPointId: null,
    title: 'Scania Identity Manual part number 16MC135',
    url: 'Scania Identity Manual part number 16MC135.pdf',
    documentVersion: 73
  },
  {
    id: 1036,
    auditPointId: 762,
    checkPointId: null,
    title: 'Scania Interior Identity Guideline part number 17MC111',
    url: 'Scania Interior Identity Guideline part number 17MC111.pdf',
    documentVersion: 13
  },
  {
    id: 1037,
    auditPointId: 743,
    checkPointId: null,
    title: 'Scania International Service (SIS) database',
    url: 'https://sis.scania.com/www/',
    documentVersion: 1
  },
  {
    id: 1038,
    auditPointId: 836,
    checkPointId: null,
    title: 'Scania Invoice Standard',
    url: 'Scania Invoice Standard.pdf',
    documentVersion: 10
  },
  {
    id: 1039,
    auditPointId: 769,
    checkPointId: null,
    title: 'Scania Media Provider',
    url: 'https://media.scania.com/fotoweb',
    documentVersion: 5
  },
  {
    id: 1040,
    auditPointId: 885,
    checkPointId: null,
    title: 'Scania minimum environmental standard for retail facilities',
    url: 'Scania minimum environmental standard for retail facilities.pdf',
    documentVersion: 16
  },
  {
    id: 1041,
    auditPointId: 785,
    checkPointId: null,
    title: 'Scania minimum requirements on CRM',
    url: 'Scania Minimum Requirements on CRM.pdf',
    documentVersion: 2
  },
  {
    id: 1042,
    auditPointId: 834,
    checkPointId: null,
    title: 'Scania Multi',
    url: 'https://internal.scania.com/commercialsupport/314.html',
    documentVersion: 1
  },
  {
    id: 1043,
    auditPointId: 869,
    checkPointId: null,
    title: 'Scania Parts Online presentation',
    url: 'Scania Parts Online presentation.pdf',
    documentVersion: 1
  },
  {
    id: 1044,
    auditPointId: 756,
    checkPointId: null,
    title: 'Scania Retail Shop Guideline',
    url: 'Scania Retail Shop Guideline.pdf',
    documentVersion: 6
  },
  {
    id: 1045,
    auditPointId: 843,
    checkPointId: null,
    title: 'Scania Service vehicle standard and specification',
    url: 'Scania Service vehicle standard and specification.pdf',
    documentVersion: 5
  },
  {
    id: 1046,
    auditPointId: 812,
    checkPointId: null,
    title: 'Scania storage system for Scania Tools (document number 588700)',
    url: 'Scania storage system for Scania Tools (document number 588700).pdf',
    documentVersion: 3
  },
  {
    id: 1047,
    auditPointId: 804,
    checkPointId: null,
    title: 'Scania Tools (Reflex/TIL)',
    url: 'https://til.scania.com',
    documentVersion: 2
  },
  {
    id: 1048,
    auditPointId: 793,
    checkPointId: null,
    title: 'Scania truck bodybuilder portal',
    url: 'https://bodybuilder.scania.com',
    documentVersion: 5
  },
  {
    id: 1049,
    auditPointId: 867,
    checkPointId: null,
    title: 'Scania Warehousing Guidelines',
    url: 'Scania Warehousing Guidelines.pdf',
    documentVersion: 1
  },
  {
    id: 1050,
    auditPointId: 814,
    checkPointId: null,
    title: 'Scania Workshop History Information (WHI)',
    url: 'https://whi.scania.com',
    documentVersion: 2
  },
  {
    id: 1051,
    auditPointId: 755,
    checkPointId: null,
    title: 'Scania workwear - Brand and design guideline',
    url: 'Scania workwear - Brand and design guideline.pdf',
    documentVersion: 10
  },
  {
    id: 1052,
    auditPointId: 809,
    checkPointId: null,
    title: "Scania's service information (Reflex/TIL)",
    url: 'https://til.scania.com/el/til',
    documentVersion: 1
  },
  {
    id: 1053,
    auditPointId: 855,
    checkPointId: null,
    title: 'Service level measurement',
    url: 'Service level meassurement 01 Oct 2012.xlsx',
    documentVersion: 1
  },
  {
    id: 1055,
    auditPointId: 852,
    checkPointId: null,
    title: 'SIS products and services criteria',
    url: 'SIS products and services criteria.pdf',
    documentVersion: 33
  },
  {
    id: 1056,
    auditPointId: 743,
    checkPointId: null,
    title: 'SIS Update user manual',
    url: 'SIS Update, user manual_v1.27.pdf',
    documentVersion: 7
  },
  {
    id: 1057,
    auditPointId: 744,
    checkPointId: null,
    title: 'Social Media Guideline',
    url: 'Social Media Guideline.pdf',
    documentVersion: 4
  },
  {
    id: 1058,
    auditPointId: 783,
    checkPointId: null,
    title: 'Solution sales',
    url: 'Solution sales.pdf',
    documentVersion: 5
  },
  {
    id: 1059,
    auditPointId: 751,
    checkPointId: null,
    title: 'Standard for order and cleanliness',
    url: 'Standard for order and cleanliness.pdf',
    documentVersion: 10
  },
  {
    id: 1060,
    auditPointId: 820,
    checkPointId: null,
    title: 'Standards and Inspection lists for VRS',
    url: 'https://vrs.scania.com',
    documentVersion: 4
  },
  {
    id: 1061,
    auditPointId: 779,
    checkPointId: null,
    title: 'Structured sales process - Guidelines',
    url: 'Structured Sales Process - Guidelines.pdf',
    documentVersion: 10
  },
  {
    id: 1062,
    auditPointId: 891,
    checkPointId: null,
    title: 'TIA Guideline for Commercial Operations',
    url: 'TIA Guideline for Commercial Operations.pdf',
    documentVersion: 2
  },
  {
    id: 1063,
    auditPointId: 804,
    checkPointId: null,
    title: 'Tool list report application (Reflex)',
    url: 'https://mbs.scania.com/',
    documentVersion: 1
  },
  {
    id: 1064,
    auditPointId: 881,
    checkPointId: null,
    title: 'Top Team (Reflex)',
    url: 'https://internal.scania.com/communication/149.html',
    documentVersion: 2
  },
  {
    id: 1065,
    auditPointId: 813,
    checkPointId: null,
    title: 'Trolley for hand tools',
    url: 'Trolley for hand tools.pdf',
    documentVersion: 5
  },
  {
    id: 1066,
    auditPointId: 845,
    checkPointId: null,
    title: 'Uniform invoicing',
    url: 'Uniform invoicing.pdf',
    documentVersion: 28
  },
  {
    id: 1067,
    auditPointId: 845,
    checkPointId: null,
    title: 'Requirements for representing Scania Assistance in a market',
    url: 'Requirements for representing Scania Assistance in a market.pdf',
    documentVersion: 4
  },
  {
    id: 1068,
    auditPointId: 797,
    checkPointId: null,
    title: 'Vehicle handover checklist Trucks and buses',
    url: 'https://teamroom.scania.com/share/page/site/intranet-file-storage/folder-details?nodeRef=workspace://SpacesStore/c608f385-4b06-4df4-8c1a-2c27ea5b5f66',
    documentVersion: 3
  },
  {
    id: 1069,
    auditPointId: 797,
    checkPointId: null,
    title: 'Vehicle handover standard',
    url: 'Vehicle_handover_Standard.pdf',
    documentVersion: 2
  },
  {
    id: 1070,
    auditPointId: 851,
    checkPointId: null,
    title: 'Verification of the compulsory assortment of Scania Parts',
    url: 'Verification of compulsory assortment.pdf',
    documentVersion: 6
  },
  {
    id: 1071,
    auditPointId: 820,
    checkPointId: null,
    title: 'Workshop business process',
    url: 'Workshop Business Process.pdf',
    documentVersion: 7
  },
  {
    id: 1073,
    auditPointId: 839,
    checkPointId: null,
    title: 'Workshop Performance Report (Reflex)',
    url: 'https://sos.scania.com/TYC/',
    documentVersion: 6
  },
  {
    id: 1141,
    auditPointId: 793,
    checkPointId: null,
    title: "Bus Builder's Manual",
    url: 'https://til.scania.com/idcplg?IdcService=GET_FILE&RevisionSelectionMethod=LatestReleased&Rendition=web&noSaveAs=1&dDocName=BBM_0090611_01',
    documentVersion: 2
  },
  {
    id: 1143,
    auditPointId: 768,
    checkPointId: null,
    title: 'Scania Corporate Identity Material Catalogue part number 2596948 ',
    url: 'Scania Corporate Identity Material Catalogue part number 2596948.pdf',
    documentVersion: 1
  },
  {
    id: 1144,
    auditPointId: 781,
    checkPointId: null,
    title: 'Installation report - templates',
    url: 'https://teamroom.scania.com/share/page/site/intranet-file-storage/folder-details?nodeRef=workspace://SpacesStore/b4209357-106a-4200-b086-8cbb87c803bd',
    documentVersion: 1
  },
  {
    id: 1145,
    auditPointId: 809,
    checkPointId: null,
    title: 'https://conversion.scania.com',
    url: 'https://conversion.scania.com',
    documentVersion: 1
  },
  {
    id: 1146,
    auditPointId: 891,
    checkPointId: null,
    title: 'Minimum Health_Safety requirements in service workshops ',
    url: 'Minimum Health _ Safety requirements in service workshop.pdf',
    documentVersion: 2
  },
  {
    id: 1147,
    auditPointId: 885,
    checkPointId: null,
    title: 'STD4159 - Scania Restricted Substances  ',
    url: 'https://til.scania.com/w/scs_0000566_99',
    documentVersion: 4
  },
  {
    id: 1148,
    auditPointId: 885,
    checkPointId: null,
    title: 'STD4158 - Scania Prohibited Substances',
    url: 'https://til.scania.com/w/scs_0000565_99',
    documentVersion: 2
  },
  {
    id: 1149,
    auditPointId: 896,
    checkPointId: null,
    title: 'Prevention of Money Laundering Terrorism Financing (SGP 6)',
    url: 'SGP 6 - Prevention of Money Laundering and Terrorism Financing.pdf',
    documentVersion: 3
  },
  {
    id: 1161,
    auditPointId: 767,
    checkPointId: null,
    title:
      'Scania Identity Manual – appendix Service vehicles part number 16MC196',
    url: 'Scania Identity Manual – appendix Service vehicles_part number 16MC196.pdf',
    documentVersion: 1
  },
  {
    id: 1162,
    auditPointId: 885,
    checkPointId: null,
    title:
      'STD 4400 -Prohibited and restricted substances in Scania’s products',
    url: 'https://tilprod.scania.com/w/scs_0000769_99',
    documentVersion: 1
  },
  {
    id: 1163,
    auditPointId: 896,
    checkPointId: null,
    title:
      'E-learning: SGP 6: Prevention of Money Laundering and Terrorism Financing',
    url: 'https://scania.csod.com/ui/lms-learning-details/app/course/d3934a50-bd88-434f-b02b-9e5799813688',
    documentVersion: 2
  },
  {
    id: 1164,
    auditPointId: 899,
    checkPointId: null,
    title:
      'Scania Data Protection Instruction- Access and use of FMS data by Scania',
    url: 'Scania Data Protection Instruction- Access and use of FMS data by Scania.pdf',
    documentVersion: 3
  },
  {
    id: 1165,
    auditPointId: 886,
    checkPointId: null,
    title: 'Safety Data Sheet',
    url: 'https://teamroom.scania.com/share/page/site/scania-sds-archive/dashboard',
    documentVersion: 1
  },
  {
    id: 1166,
    auditPointId: 887,
    checkPointId: null,
    title: 'E-learning: Health and Safety for managers (MyCompass)',
    url: 'https://scania.csod.com/GlobalSearch/search.aspx?s=&q=Health%20and%20Safety%20for%20managers%20in%20Commercial%20operation%20Modul%201%20%2B%202\t',
    documentVersion: 1
  },
  {
    id: 1167,
    auditPointId: 884,
    checkPointId: null,
    title: 'Scania Indenpendent Distributor Code of Conduct',
    url: 'Scania Indenpendent Distributor Code of Conduct.pdf',
    documentVersion: 1
  }
]
