import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { SystemMessage } from '@cdab/scania/qpr/schema'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type DeleteSystemMessageState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

export function useSendDeleteSystemMessage() {
  const client = useBackend()

  const deleteSystemMessage = async (
    systemMessageId: SystemMessage['id']
  ): Promise<string> => {
    try {
      await client.SystemMessagesService.DeleteSystemMessage(systemMessageId)
      return 'success'
    } catch (e) {
      return 'error'
    }
  }

  return {
    deleteSystemMessage
  }
}

export function useDeleteSystemMessage(systemMessageId: SystemMessage['id']) {
  const { t } = useTranslation('systemMessages')
  const { deleteSystemMessage } = useSendDeleteSystemMessage()
  const [deleteState, setDeleteState] = useState<DeleteSystemMessageState>({
    status: 'none',
    message: null
  })

  const handleClickDeleteSystemMessage = useCallback(async () => {
    const response = await deleteSystemMessage(systemMessageId)
    setDeleteState({ status: 'none', message: null })

    if (response === 'success') {
      setDeleteState({ status: response, message: t('message.delete-success') })
      return true
    }

    setDeleteState({ status: 'error', message: t('message.delete-error') })
    return false
  }, [deleteSystemMessage, systemMessageId, t])
  return [deleteState, handleClickDeleteSystemMessage, setDeleteState] as const
}
