import { UploadFileButton } from '@cdab/scania/qpr/components/atoms'
import { AttachmentList } from '@cdab/scania/qpr/components/molecules'
import { useDeviation } from '@cdab/scania/qpr/contexts/deviation'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { auditsController } from '@cdab/scania/qpr/offline/controllers'
import type { DeviationModel } from '@cdab/scania/qpr/offline/models'
import type { FileData, UserFile } from '@cdab/scania/qpr/schema'
import { freeMemory } from '@cdab/scania/qpr/utils'
import {
  Block,
  Checkbox,
  Column,
  DelayedSpinner,
  Dropdown,
  DropdownOption,
  EmptyScreen,
  IconWarning
} from '@cdab/scania/sdds'
import { capitalizeFirstLetter, fileIsImage } from '@cdab/utils'
import { TdsDatetime, TdsTextField, TdsTextarea } from '@scania/tegel-react'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import invariant from 'tiny-invariant'
import { v4 as uuidv4 } from 'uuid'
import {
  EmptyScreenWrapper,
  FormRow,
  StyledBlock,
  StyledDiv
} from './new-deviation.styles'
import type {
  AuditPointNumberData,
  NewDeviationProps,
  UseAuditState
} from './new-deviation.types'

const useAudit = (auditId: number) => {
  const [state, setState] = useState<UseAuditState>({
    status: 'loading',
    audit: undefined
  })

  useEffect(() => {
    async function asyncEffect() {
      const { audit } = await auditsController.GetAudit(auditId)

      setState({
        status: 'success',
        audit
      })
    }
    asyncEffect()
  }, [auditId])

  return state
}

export const NewDeviation = (props: Readonly<NewDeviationProps>) => {
  const { t } = useTranslation('common')
  const { deviation } = props
  const { files } = deviation ?? {}
  const auditState = useAudit(props.auditId)
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')

  const ctx = useDeviation()

  const selectedFilesToUpload = (ctx?.form?.watch('files') as UserFile[]) ?? []

  let auditPointNumbers: AuditPointNumberData[] = []

  if (auditState.audit) {
    auditPointNumbers = auditState.audit.auditPoints
      .slice()
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map(({ auditPointNo, id }) => ({ auditPointNo, auditPointId: id }))
  }

  useEffect(() => {
    return () => {
      freeMemory(selectedFilesToUpload)
    }
  }, [selectedFilesToUpload])

  const onAuditPointNumberChange = ({
    auditPointId,
    auditPointNo
  }: AuditPointNumberData) => {
    ctx?.form?.setValue('auditPointNumber', auditPointNo)
    ctx?.form?.setValue('auditPointId', auditPointId.toString())
  }

  const onUploadFilesClick = (fileList: FileList) => {
    const files = Array.from(fileList)

    if (files.length === 0) return

    const inputFiles: UserFile[] = Array.from(files).map(file => ({
      file,
      id: uuidv4(),
      url: URL.createObjectURL(file)
    }))

    const nextFiles = [...selectedFilesToUpload, ...inputFiles]

    ctx?.form?.setValue('files', nextFiles)
  }

  const onDeleteSelectedFile = (fileId: FileData['id']) => {
    // TODO: Add translation
    if (!window.confirm('Are you sure you want to delete this file?')) return

    const selectedFileIndex = selectedFilesToUpload.findIndex(
      f => f.id === fileId
    )

    if (selectedFileIndex === -1) return

    const nextFiles = selectedFilesToUpload.slice()
    const deletedFiles = nextFiles.splice(selectedFileIndex, 1)

    freeMemory(deletedFiles)

    ctx?.form?.setValue('files', nextFiles)
  }

  if (auditState.status === 'loading') return <DelayedSpinner />

  const filesBeingUploaded: DeviationModel['files'] = []
  const uploadedFiles: DeviationModel['files'] = []
  files
    ?.filter(
      ({ id }) =>
        (ctx?.form?.getValues('filesToDelete') ?? []).includes(id) === false
    )
    .forEach(file => {
      if (file.isUploaded) {
        uploadedFiles.push(file)
      } else {
        filesBeingUploaded.push(file)
      }
    })

  const evidenceHasBeenUploaded =
    selectedFilesToUpload.length > 0 || filesBeingUploaded.length > 0

  return (
    <StyledDiv>
      <Column padding={isLg} width={12} lg={7} className='sdds-on-white-bg'>
        <StyledBlock
          divider
          description={t('deviation.description', { ns: 'audit' })}
          color='on-grey'
          header={t('deviation.title', { ns: 'audit' })}
        >
          <Checkbox
            {...ctx?.form?.register('withActionPlan')}
            label={t('deviation-without-action-plan', { ns: 'audit' })}
            checked={!ctx?.form?.watch('withActionPlan')}
            onChange={() => {
              ctx?.form?.setValue(
                'withActionPlan',
                !ctx?.form?.getValues('withActionPlan')
              )
            }}
          />
          {!props.defaultAuditPoint && (
            <FormRow>
              <Dropdown
                {...ctx?.form?.register('auditPointId')}
                onWhite
                size='sm'
                label={capitalizeFirstLetter(t('audit-point'))}
                placeholder={t('select-audit-point', { ns: 'audit' })}
                disabled={auditState.audit.isReadonly}
                onSelect={e => {
                  const auditPointId = Number(e.value)
                  const auditPointNo = auditPointNumbers.find(
                    ap => ap.auditPointId === auditPointId
                  )?.auditPointNo
                  invariant(auditPointNo)

                  onAuditPointNumberChange({ auditPointId, auditPointNo })
                }}
                defaultOption={ctx?.form?.watch('auditPointId')}
                helper={ctx?.form?.formState.errors[
                  'auditPointId'
                ]?.message?.toString()}
                state={ctx?.form?.formState.errors['auditPointId'] && 'error'}
              >
                {auditPointNumbers.map(({ auditPointId, auditPointNo }) => (
                  <DropdownOption
                    key={auditPointId}
                    value={auditPointId?.toString() || ''}
                    text={auditPointNo.toString()}
                  />
                ))}
              </Dropdown>
            </FormRow>
          )}
          <FormRow>
            <TdsTextarea
              {...ctx?.form?.register('deviation')}
              cols={2}
              helper={ctx?.form?.formState.errors[
                'deviation'
              ]?.message?.toString()}
              maxLength={1000}
              name='deviation'
              label={`${capitalizeFirstLetter(t('deviation'))}*`}
              labelPosition='outside'
              onTdsChange={e =>
                ctx?.form?.setValue('deviation', e.target.value)
              }
              placeholder={t('deviation')}
              state={ctx?.form?.formState.errors['deviation'] && 'error'}
              value={ctx?.form?.getValues('deviation') ?? ''}
            />
          </FormRow>

          {ctx?.form?.getValues('withActionPlan') && (
            <>
              <FormRow>
                <TdsTextarea
                  {...ctx?.form?.register('action')}
                  cols={2}
                  helper={ctx?.form?.formState.errors[
                    'action'
                  ]?.message?.toString()}
                  maxLength={1000}
                  label={`${capitalizeFirstLetter(
                    t('proposed-action', { ns: 'audit' })
                  )}*`}
                  labelPosition='outside'
                  name='action'
                  onTdsChange={e =>
                    ctx?.form?.setValue('action', e.target.value)
                  }
                  placeholder={t('proposed-action', { ns: 'audit' })}
                  state={ctx?.form?.formState.errors['action'] && 'error'}
                  value={ctx?.form?.getValues('action') ?? ''}
                />
              </FormRow>
              <FormRow>
                <TdsTextField
                  {...ctx?.form?.register('assignee')}
                  helper={ctx?.form?.formState.errors[
                    'assignee'
                  ]?.message?.toString()}
                  size='sm'
                  maxLength={100}
                  name='assignee'
                  label={`${capitalizeFirstLetter(t('responsible'))}*`}
                  labelPosition='outside'
                  onTdsChange={e =>
                    ctx?.form?.setValue('assignee', e.target.value)
                  }
                  placeholder={t('responsible', { ns: 'audit' })}
                  state={ctx?.form?.formState.errors['assignee'] && 'error'}
                  value={ctx?.form?.getValues('assignee')}
                />
              </FormRow>
              <FormRow>
                <TdsDatetime
                  {...ctx?.form?.register('due')}
                  min={undefined}
                  max={undefined}
                  helper={ctx?.form?.formState.errors[
                    'due'
                  ]?.message?.toString()}
                  size='sm'
                  name='dueDate'
                  label={`${capitalizeFirstLetter(t('due-date'))}*`}
                  type='date'
                  onTdsChange={e => ctx?.form?.setValue('due', e.target.value)}
                  state={ctx?.form?.formState.errors['due'] && 'error'}
                  value={ctx?.form?.getValues('due') ?? ''} //Not possible to be undefined when rerendering
                />
              </FormRow>
              <FormRow>
                <TdsDatetime
                  {...ctx?.form?.register('completed')}
                  min={undefined}
                  max={undefined}
                  helper={ctx?.form?.formState.errors[
                    'completed'
                  ]?.message?.toString()}
                  size='sm'
                  type='date'
                  name='completed'
                  label={capitalizeFirstLetter(t('completed'))}
                  onTdsChange={e =>
                    ctx?.form?.setValue('completed', e.target.value)
                  }
                  state={ctx?.form?.formState.errors['completed'] && 'error'}
                  value={ctx?.form?.getValues('completed') ?? ''} //Not possible to be undefined when rerendering
                />
              </FormRow>
            </>
          )}
        </StyledBlock>
      </Column>

      <Column width={12} lg={5} padding={isLg}>
        <Block
          className={classNames({ 'sdds-u-mt1': !isLg })}
          divider
          color='on-grey'
          header={
            evidenceHasBeenUploaded
              ? capitalizeFirstLetter(t('evidence', { ns: 'audit' }))
              : ''
          }
        >
          {evidenceHasBeenUploaded && (
            <UploadFileButton
              text={capitalizeFirstLetter(t('select-file', { ns: 'common' }))}
              size='sm'
              onUploadFiles={onUploadFilesClick}
            />
          )}

          {selectedFilesToUpload.length > 0 && (
            <div>
              <AttachmentList
                color='on-white'
                fileType='all'
                files={selectedFilesToUpload.map(({ file, id, url }) => ({
                  fileName: file.name,
                  id,
                  isImage: fileIsImage(file),
                  isUploaded: false,
                  uploadProgress: 0,
                  url
                }))}
                hideUploadProgress
                onDeleteAttachment={onDeleteSelectedFile}
                disableDelete={false}
              />
            </div>
          )}
          {filesBeingUploaded.length > 0 && (
            <div>
              <AttachmentList
                color='on-white'
                files={filesBeingUploaded}
                disableDelete
                title={t('files-not-yet-uploaded')}
              />
            </div>
          )}
          {!evidenceHasBeenUploaded && (
            <EmptyScreenWrapper>
              <EmptyScreen
                title={t('no-evidence-exists', { ns: 'audit' })}
                description='You have not added any evidence to this deviation.'
                icon={<IconWarning />}
                callToAction={{
                  type: 'button',
                  component: (
                    <UploadFileButton
                      text={t('add-evidence', { ns: 'audit' })}
                      size='sm'
                      onUploadFiles={onUploadFilesClick}
                    />
                  )
                }}
              />
            </EmptyScreenWrapper>
          )}
        </Block>
      </Column>
    </StyledDiv>
  )
}
