import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'

import { capitalizeFirstLetter } from '@cdab/utils'
import { Textarea } from '@cdab/scania/sdds'

import type { AuditNoteProps, NoteProps } from './audit-note.types'
import {
  DisabledNoteBlock,
  NoNote,
  NoteHeaderWrapper,
  NoteTitle,
  SavedText,
  UneditableNote
} from './audit-note.styles'

const SAVED_TEXT_DURATION = 1000

function Note({ note, ...props }: NoteProps) {
  const { t } = useTranslation('common')

  if (props.disabled) {
    return (
      <DisabledNoteBlock>
        {note.length === 0 ? (
          <NoNote className='detail-02'>{t('no-note')}</NoNote>
        ) : (
          <UneditableNote className='detail-02'>{note}</UneditableNote>
        )}
      </DisabledNoteBlock>
    )
  }

  return (
    <Textarea
      placeholder={t('note')}
      value={note}
      onChange={props.onChangeNote}
      onBlur={props.onBlur}
      maxLength={4000}
      disabled={props.disabled}
      autoResize
    />
  )
}

export function AuditNote({
  showTitle = true,
  showSavedText = true,
  ...props
}: AuditNoteProps) {
  const { t } = useTranslation('common')
  const { note, onSaveNote, disabled, className } = props
  const [savedTextIsVisible, setSavedTextIsVisible] = useState(false)

  const [editedNote, setEditedNote] = useState<string>(note)

  const onBlur = useCallback(() => {
    // We don't need to save if we haven't changed the note
    if (editedNote === props.note) return

    // FIXME: onSaveNote should have a callback which is called when the note has been cached.
    // When it has been cached, we should show the "saved" message
    onSaveNote(editedNote)
    setSavedTextIsVisible(true)
  }, [editedNote, onSaveNote, props.note])

  // When note was saved, show the saved text for 2 seconds
  useEffect(() => {
    const handler = setTimeout(() => {
      setSavedTextIsVisible(false)
    }, SAVED_TEXT_DURATION)

    return () => {
      clearTimeout(handler)
    }
  })

  useEffect(() => {
    // If note prop changes(someone else edited the note), overwrite current note
    if (note !== editedNote) {
      setEditedNote(note)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note])

  const onChangeNote = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setEditedNote(event.target.value)
    },
    []
  )

  return (
    <div onClick={event => event.stopPropagation()} className={className}>
      {(showTitle || showSavedText) && (
        <NoteHeaderWrapper>
          {showTitle && (
            <NoteTitle>{capitalizeFirstLetter(t('note'))}</NoteTitle>
          )}
          {savedTextIsVisible && <SavedText show={true}>Saved!</SavedText>}
        </NoteHeaderWrapper>
      )}
      <Note
        disabled={!!disabled}
        note={editedNote}
        onBlur={onBlur}
        onChangeNote={onChangeNote}
      />
    </div>
  )
}
