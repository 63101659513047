import { Button, Textfield } from '@cdab/scania/sdds'
import { ValidateUrl, capitalizeFirstLetter } from '@cdab/utils'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormModal } from '../form-modal'
import type { EditorLinkModalProps } from './editor-link-modal.types'

export function EditorLinkModal({
  open,
  text,
  onClose,
  onSubmit
}: EditorLinkModalProps) {
  const { t } = useTranslation(['common', 'errors'])
  const [textState, setTextState] = useState(text)
  const [urlState, setUrlState] = useState('')
  const [textErrorState, setTextErrorState] = useState(true)
  const [urlErrorState, setUrlErrorState] = useState(true)

  const minCharsText = 1

  const handleFormSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()
      setTextErrorState(textState.length > minCharsText)
      setUrlErrorState(ValidateUrl(urlState))
      if (textState.length > minCharsText && ValidateUrl(urlState))
        onSubmit(textState, urlState)
    },
    [onSubmit, textState, urlState]
  )

  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleFormSubmit}
      footer={
        <>
          <Button
            disabled={!textErrorState || !urlErrorState}
            text={capitalizeFirstLetter(t('save'))}
            onClick={handleFormSubmit}
          />
          <Button
            type='primary'
            onClick={onClose}
            text={capitalizeFirstLetter(t('close'))}
          />
        </>
      }
      header={'Add link'}
    >
      <div className='sdds-u-mt3'>
        <Textfield
          placeholder='Text'
          label='Text'
          labelPosition='outside'
          value={textState}
          onChange={e => setTextState(e.target.value)}
          onBlur={e => setTextErrorState(e.target.value.length > minCharsText)}
          state={!textErrorState ? 'error' : undefined}
          helper={
            !textErrorState &&
            t('message.validation-error', {
              chars: minCharsText.toString(),
              ns: 'errors'
            })
          }
        />
      </div>
      <div className='sdds-u-mt3'>
        <Textfield
          placeholder='URL'
          label='URL'
          labelPosition='outside'
          value={urlState}
          onChange={e => setUrlState(e.target.value)}
          onBlur={e => setUrlErrorState(ValidateUrl(e.target.value))}
          state={!urlErrorState ? 'error' : undefined}
          helper={
            !urlErrorState &&
            t('message.url-not-valid', {
              ns: 'errors'
            })
          }
        />
      </div>
    </FormModal>
  )
}
