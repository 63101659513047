import React from 'react'
import { useCallback } from 'react'

import type { FileData } from '@cdab/scania/qpr/schema'
import { Button } from '@cdab/scania/sdds'

import {
  UserImage,
  UserFileItem,
  UserFileLinkRow,
  ImageDeleteButton,
  RelativeDiv,
  UserFileLink
} from './attachment-list-item.styles'

export type AttachmentListItemProps = {
  file: FileData
  onDelete?: (id: FileData['id']) => void
  disableDelete?: boolean
}

export function AttachmentListItem(props: AttachmentListItemProps) {
  const { onDelete, disableDelete = false, file } = props
  const { fileName, id, url, isImage } = file
  const previewImage = false

  const onDeleteClick = useCallback(() => {
    if (onDelete && !disableDelete) {
      onDelete(id)
    }
  }, [disableDelete, id, onDelete])

  return (
    <UserFileItem key={id}>
      {previewImage && isImage ? (
        <RelativeDiv>
          <UserImage src={url} alt='User uploaded' />
          {!disableDelete && onDelete && (
            <ImageDeleteButton
              type='danger'
              onClick={onDeleteClick}
              data-file-id={id}
              text='X'
            />
          )}
        </RelativeDiv>
      ) : (
        <UserFileLinkRow>
          <UserFileLink target='_blank' href={url} rel='noreferrer'>
            {fileName}
          </UserFileLink>
          {!disableDelete && onDelete && (
            <Button
              type='danger'
              onClick={onDeleteClick}
              data-file-id={id}
              text='X'
            />
          )}
        </UserFileLinkRow>
      )}
    </UserFileItem>
  )
}
