import { Block } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const StyledBlock = styled(Block)`
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: var(--sdds-information);

  @media (min-width: ${({ theme }) => theme.size.md}) {
    height: 15rem;
    display: flex;
    align-items: center;
  }
`

export const ServiceIcon = styled.div<{ positive: boolean }>`
  color: ${({ positive }) =>
    positive ? 'var(--sdds-positive)' : 'var(--sdds-negative)'};
  width: 5rem;
  height: 5rem;
  text-align: center;
  display: inline-block;
`

export const ServiceTable = styled.div.attrs({
  role: 'table'
})`
  display: table;
  width: 100%;
`

export const TableRowGroup = styled.div.attrs({
  role: 'rowgroup'
})`
  display: table-row-group;
`

export const TableRow = styled.div.attrs({
  role: 'row'
})`
  display: table-row;
  text-align: center;
`

export const TableHeader = styled.div.attrs({
  className: 'sdds-detail-04',
  role: 'columnheader'
})`
  display: table-cell;
`

export const TableCell = styled.div.attrs({
  role: 'cell'
})`
  display: table-cell;
`
