import type {
  BackEnd,
  LogoutConfiguration,
  UserInfo
} from '@cdab/scania/qpr/interactor'
import axios from 'axios'

import Keycloak from 'keycloak-js'
import { ADHService } from './adh-service'
import { AuditsRealTimeService } from './audits-real-time-service'
import { AuditsService } from './audits-service'
import { DealersService } from './dealers-service'
import { DeviationsService } from './deviations-service'
import { DocumentService } from './documents-service'
import { Configuration } from './generated-swagger-client'
import { MarketsService } from './markets-service'
import { RemindersService } from './reminders-service'
import { ReportsService } from './reports-service'
import { StorageService } from './storage-service'
import { SystemMesssagesService } from './system-messages-service'
import { ApiTranslationsService } from './translations-service'
import { ApiUserService } from './user-service'

export interface BaseOptions {
  timeout: number
}

export type KeycloakConfiguration = {
  keycloakClientId: string
  keycloakRealm: string
  keycloakUrl: string
}

interface QprUserProfile extends Keycloak.KeycloakProfile {
  attributes: Record<string, string[]>
}

export class ApiClient implements BackEnd {
  private readonly auditsRealTimeEndpoint: string
  private keycloak: Keycloak.KeycloakInstance
  private UserInfo: UserInfo | undefined = undefined

  public AuditsRealTimeService: AuditsRealTimeService
  public AuditsService: AuditsService
  public DealersService: DealersService
  public DeviationsService: DeviationsService
  public MarketsService: MarketsService
  public SystemMessagesService: SystemMesssagesService
  public TranslationsService: ApiTranslationsService
  public UserService: ApiUserService
  public StorageService: StorageService
  public RemindersService: RemindersService
  public ReportsService: ReportsService
  public DocumentService: DocumentService
  public ADHService: ADHService

  public constructor(
    apiUrl: string,
    auditsRealTimeEndpoint: string,
    keycloakConfiguration: KeycloakConfiguration,
    baseOptions?: BaseOptions
  ) {
    this.auditsRealTimeEndpoint = auditsRealTimeEndpoint

    this.keycloak = Keycloak({
      clientId: keycloakConfiguration.keycloakClientId,
      realm: keycloakConfiguration.keycloakRealm,
      url: keycloakConfiguration.keycloakUrl
    })

    const config = new Configuration({
      basePath: apiUrl,
      apiKey: this.getApiToken,
      baseOptions
    })

    const instance = axios.create()

    instance.interceptors.response.use(
      response => response,
      async error =>
        // TODO: Add callback handlers, e.g. https://github.com/CongenialData/cplan-api-client/blob/main/src/api.ts

        Promise.reject(error)
    )

    this.DealersService = new DealersService(config, instance)

    this.DeviationsService = new DeviationsService(config, instance)

    this.MarketsService = new MarketsService(config, instance)

    this.SystemMessagesService = new SystemMesssagesService(config, instance)

    this.AuditsService = new AuditsService(config, instance)

    this.AuditsRealTimeService = new AuditsRealTimeService(
      this.auditsRealTimeEndpoint
    )
    this.UserService = new ApiUserService(config, instance)
    this.TranslationsService = new ApiTranslationsService(config, instance)
    this.StorageService = new StorageService(config, instance)
    this.RemindersService = new RemindersService(config, instance)
    this.ReportsService = new ReportsService(config, instance)
    this.DocumentService = new DocumentService(config, instance)
    this.ADHService = new ADHService(config, instance)
  }

  private getApiToken = async (): Promise<string> => {
    if (!this.keycloak.authenticated) {
      await this.Login()
    }

    await this.keycloak.updateToken(60)

    return `bearer ${this.keycloak.token}`
  }

  private loginPromise: ReturnType<BackEnd['Login']> | undefined = undefined
  public Login = async () => {
    if (this.loginPromise) return await this.loginPromise

    const handleLogin = async () => {
      try {
        const success = await this.keycloak.init({
          onLoad: 'login-required'
        })

        if (!success) throw new Error('Keycloak login was not successfull')

        await this.SetUserInfo()
      } finally {
        this.loginPromise = undefined
      }
    }

    this.loginPromise = handleLogin()
    return await this.loginPromise
  }

  private SetUserInfo = async () => {
    const userProfile = await this.keycloak.loadUserProfile()

    if (!('attributes' in userProfile)) {
      return
    }

    // If qpr-user-id doesn't exist, we'll throw and catch in parent 👍
    const sQprUserId = (userProfile as QprUserProfile).attributes[
      'qpr-user-id'
    ][0]
    const qprUserId = Number.parseInt(sQprUserId || 'NaN')
    if (Number.isNaN(qprUserId))
      throw new Error('Missing qpr user id on keycloak user')

    const { firstName, lastName } = userProfile

    if (!firstName) throw new Error('Missing firstName on keycloak user')
    if (!lastName) throw new Error('Missing lastName on keycloak user')

    this.UserInfo = {
      firstName,
      lastName,
      userId: qprUserId
    }
  }

  public GetuserInfo = async () => {
    if (this.UserInfo) return this.UserInfo

    await this.Login()

    return this.UserInfo
  }

  public IsLoggedIn = () => {
    return !!this.keycloak.authenticated
  }

  public Logout = async (config?: LogoutConfiguration) => {
    const { redirectUri } = config || {}

    await this.keycloak.logout({
      redirectUri
    })
  }
}
