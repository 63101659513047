import type { JSONSchemaType } from 'ajv'
import { z } from 'zod'
import { ajv, isValidThing } from './ajv'

export const DealerFileDataSchema = z.object({
  id: z.string(),
  url: z.string(),
  isImage: z.boolean(),
  fileName: z.string(),

  // FIXME: These two does not belong to schema, and should live in a FileDataModel
  isUploaded: z.boolean(),
  uploadProgress: z.number()
})

export type DealerFileData = z.infer<typeof DealerFileDataSchema>

export interface DealerFileDataJson {
  id: number
  url: string
  isImage: boolean
  fileName: string
}

export const DealerFileDataJsonSchema: JSONSchemaType<DealerFileDataJson> = {
  type: 'object',
  properties: {
    id: { type: 'number' },
    url: { type: 'string' },
    isImage: { type: 'boolean' },
    fileName: { type: 'string' }
  },
  required: ['id', 'url', 'isImage', 'fileName']
}

const validateDealerFile = ajv.compile(DealerFileDataJsonSchema)

export const isValidDealerFile = (data: unknown): data is DealerFileDataJson =>
  isValidThing<DealerFileDataJson>(data, validateDealerFile)

export function dealerFileJsonToDealerFile(
  dealerFileJson: DealerFileDataJson
): DealerFileData {
  const { id, ...jsonData } = dealerFileJson

  return {
    id: id.toString(),
    isUploaded: true, // Every file we get from json (i.e. externally) is already uploaded
    uploadProgress: 1,
    ...jsonData
  }
}

export const DealerFileDataArrayJsonSchema: JSONSchemaType<
  DealerFileDataJson[]
> = {
  type: 'array',
  items: DealerFileDataJsonSchema
}

const validateDealerFileDataArray = ajv.compile(DealerFileDataArrayJsonSchema)

export const isValidDealerFileDataArray = (
  data: unknown
): data is DealerFileDataJson[] =>
  isValidThing<DealerFileDataJson[]>(data, validateDealerFileDataArray)

export function dealerFileJsonArrayToDealerFileArray(
  dealerFileJsonArray: DealerFileDataJson[]
): DealerFileData[] {
  const dealerFileDataArray = dealerFileJsonArray.map(
    dealerFileJsonToDealerFile
  )
  return dealerFileDataArray
}
