import { useMachine } from '@xstate/react'
import { useMemo, useEffect, useState } from 'react'
import invariant from 'tiny-invariant'

import {
  createBackendMachine,
  BACKEND_STATE_IDLE,
  BACKEND_EVENT_START,
  BACKEND_STATE_SUCCESS
} from '@cdab/utils'
import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { User } from '@cdab/scania/qpr/schema'

import { userContext } from './user-context'

function useFetchUser() {
  const backend = useBackend()
  const machine = useMemo(
    () =>
      createBackendMachine<User>({
        callBackend: async () => {
          const userInfo = await backend.GetuserInfo()

          invariant(
            userInfo,
            'Cannot fetch user without having logged in to Keycloak!'
          )
          return backend.UserService.GetUser(userInfo.userId)
        }
      }),
    [backend]
  )

  const [state, send] = useMachine(machine)

  useEffect(() => {
    if (state.matches(BACKEND_STATE_IDLE)) {
      send(BACKEND_EVENT_START)
    }
  }, [send, state])

  return { state, send }
}

type UserProviderProps = {
  children: React.ReactNode
}

/**
 * @returns The provider when it has finished loading, null otherwise
 */
export function UserProvider({ children }: UserProviderProps) {
  const { state } = useFetchUser()
  const [userContextData, setUserContextData] = useState<User | undefined>()

  useEffect(() => {
    if (userContextData) return

    if (state.matches(BACKEND_STATE_SUCCESS) && state.context.data) {
      setUserContextData(state.context.data)
    }
  }, [userContextData, state])

  const contextValue = useMemo(() => {
    if (!userContextData) return

    return {
      userData: userContextData,
      setData: setUserContextData
    }
  }, [userContextData, setUserContextData])

  if (!contextValue) return null

  return (
    <userContext.Provider value={contextValue}>{children}</userContext.Provider>
  )
}
