import type { Props } from './audit-point-detail.types'

export function AuditPointDetail({ auditPoint }: Props): JSX.Element {
  return (
    <div>
      <p className='my-2'>Note: </p>
      <p>With a score: {String(auditPoint.score?.valueOf() ?? 'none')}</p>
    </div>
  )
}
