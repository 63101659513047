import type { TooltipProps } from './tooltip.types'

export function Tooltip(props: TooltipProps) {
  const {
    border,
    mouseOverTooltip = false,
    offsetDistance = 8,
    offsetSkidding = 0,
    placement = 'bottom',
    selector,
    show = false,
    text = ''
  } = props

  return (
    <sdds-tooltip
      border={border}
      mouse-over-tooltip={mouseOverTooltip}
      offset-distance={offsetDistance}
      offset-skidding={offsetSkidding}
      placement={placement}
      selector={selector}
      show={show}
      text={text}
    ></sdds-tooltip>
  )
}
