import type { Dispatch } from 'react'
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'

import type { z, ZodTypeAny } from 'zod'
import type { AllUnionKeys } from '../types'

export type ValidationErrorTexts = { _errors: string[] }
export type ValidationError<TIn> = Partial<{
  [key in AllUnionKeys<TIn>]: ValidationErrorTexts
}>
type ValueOf<T> = T[keyof T]

export type ValidationResult<TIn, TOut> = {
  success: boolean
  errors?: ValidationError<TIn>
  parsedData?: TOut
}

export type TValidationCallback<TIn, TOut> = (
  ...args:
    | [error: ValidationError<TIn>, data: undefined]
    | [error: null, data: TOut]
) => void

export type ParseFn<TIn, TOut> = (data: unknown) => ValidationResult<TIn, TOut>

export type State<TIn, TOut, TMeta> = {
  initialData: Partial<TIn>
  rawData: Partial<TIn>
  parsedData?: TOut
  errors?: ValidationError<TIn>
  meta?: TMeta
  parseAndValidate: (data: unknown) => ValidationResult<TIn, TOut>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
export type Action<TIn, TOut, TMeta> =
  | { type: 'SET'; key: string; value: ValueOf<TIn> }
  | { type: 'RESET'; values?: Partial<TIn> }
  | { type: 'VALIDATE' }

function reducer<TIn, TOut = TIn, TMeta = unknown>(
  state: State<TIn, TOut, TMeta>,
  action: Action<TIn, TOut, TMeta>
): State<TIn, TOut, TMeta> {
  switch (action.type) {
    case 'RESET':
      return {
        ...state,
        errors: undefined,
        rawData: action.values ?? state.initialData,
        initialData: action.values ?? state.initialData
      }
    case 'SET':
      return {
        ...state,
        rawData: {
          ...state.rawData,
          [action.key]: action.value
        }
      }
    case 'VALIDATE': {
      const result = state.parseAndValidate(state.rawData)
      return result.success
        ? {
            ...state,
            errors: undefined,
            parsedData: result.parsedData
          }
        : {
            ...state,
            errors: result.errors
          }
    }
  }
}

export function useForm<TIn, TOut = TIn, TMeta = unknown>(
  init: () => {
    initialValues?: Partial<TIn>
    metadata?: TMeta
    parser: ParseFn<TIn, TOut>
  }
) {
  const [state, dispatch] = useReducer(
    reducer,
    init,
    (initFn: typeof init): State<TIn, TOut, TMeta> => {
      const config = initFn()
      return {
        meta: config.metadata,
        rawData: { ...(config.initialValues ?? {}) },
        initialData: { ...(config.initialValues ?? {}) },
        parseAndValidate: config.parser
      }
    }
  ) as [State<TIn, TOut, TMeta>, Dispatch<Action<TIn, TOut, TMeta>>]

  const validationListeners = useRef<TValidationCallback<TIn, TOut>[]>([])

  useEffect(() => {
    if (!state.errors) return

    for (let i = 0; i < validationListeners.current.length; i++) {
      validationListeners.current[i](state.errors, undefined)
    }

    validationListeners.current = []
  }, [state.errors])

  useEffect(() => {
    if (!state.parsedData) return

    for (let i = 0; i < validationListeners.current.length; i++) {
      validationListeners.current[i](null, state.parsedData)
    }

    validationListeners.current = []
  }, [state.parsedData])

  const validate = useCallback(() => {
    dispatch({ type: 'VALIDATE' })
    return new Promise<TOut>((resolve, reject) => {
      validationListeners.current.push((errors, data) => {
        if (errors) {
          return reject(errors)
        }
        if (!data)
          return reject(new Error('No errors and no data after validation'))

        return resolve(data)
      })
    })
  }, [])

  const setValue = useCallback(
    (key: AllUnionKeys<TIn>, value: ValueOf<TIn>) => {
      dispatch({ type: 'SET', key: key as string, value })
    },
    []
  )

  const reset = useCallback((values?: Partial<TIn>) => {
    dispatch({ type: 'RESET', values })
  }, [])

  return useMemo(() => {
    return {
      validate,
      setValue,
      values: state.rawData,
      state,
      reset
    }
  }, [setValue, state, validate])
}

export function formatError(
  errors: ValidationErrorTexts | undefined,
  separator = ', '
) {
  if (!errors) return ''
  return errors._errors.join(separator)
}

export function createZodParser<TSchema extends ZodTypeAny>(schema: TSchema) {
  type In = z.input<TSchema>
  type Out = z.output<TSchema>
  return ((data: unknown) => {
    const result = schema.safeParse(data)
    return result.success
      ? {
          success: result.success,
          parsedData: result.data
        }
      : {
          success: false,
          errors: result.error.format()
        }
  }) as ParseFn<In, Out>
}
