import styled from 'styled-components'
import cx from 'classnames'

import type { HeaderProps, StyleProps } from './card.styles.types'

export const StyledCard = styled.div.attrs<StyleProps>(
  ({ className, onClick, whiteBg }) => ({
    className: cx(className, 'sdds-card', {
      'sdds-clickable': !!onClick,
      'sdds-on-white-bg': whiteBg
    })
  })
)<StyleProps>``

export const Divider = styled.div.attrs({
  className: 'sdds-divider-light-border-top'
})``

export const Avatar = styled.div.attrs({
  className: 'sdds-card-avatar',
  role: 'img'
})``

export const Header = styled.div.attrs<HeaderProps>(
  ({ hasAvatar }: HeaderProps) => {
    return {
      className: hasAvatar ? 'sdds-card-header-avatar' : 'sdds-card-header'
    }
  }
)<HeaderProps>``

export const Headlines = styled.div.attrs<HeaderProps>(
  ({ hasAvatar }: HeaderProps) => {
    return {
      className: cx({ 'sdds-card-headlines': hasAvatar })
    }
  }
)<HeaderProps>``

export const Image = styled.img.attrs(({ className }) => ({
  className: cx(className, 'sdds-card-img')
}))``

export const Headline = styled.h6.attrs({
  className: 'sdds-card-headline'
})``

export const Subheadline = styled.h6.attrs({
  className: 'sdds-card-sub-headline'
})``

export const Body = styled.div.attrs({
  className: 'sdds-card-body'
})``

export const Footer = styled.div.attrs({
  className: 'sdds-card-footer'
})``
