import { Button, Column } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const ColumnOtherDocuments = styled(Column)`
  margin-top: var(--sdds-spacing-layout-16);

  @media (min-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 0;
  }
`

export const Headline = styled.h4.attrs({
  className: 'sdds-headline-04'
})`
  margin-bottom: 4rem;

  @media (min-width: ${({ theme }) => theme.size.md}) {
    margin-top: 0;
  }
`

export const HeadlineRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`
export const SpinnerWrapper = styled.div`
  padding-left: 8rem;
`
export const ButtonsWrapper = styled.div`
  display: flex;
`

export const StyledButton = styled(Button)`
  padding-left: 2rem;
`
