import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { ChatMessage, FileData } from '@cdab/scania/qpr/schema'
import { useCallback, useState } from 'react'

export type GetDeviationChatMessagesState =
  | {
      status: 'success'
      chatMessages: ChatMessage[]
      adhLinksExists: boolean
      chatFiles: FileData[]
    }
  | {
      status: 'error'
    }
  | {
      status: 'loading'
    }
  | {
      status: 'none'
    }

export function useGetDeviationChatMessages() {
  const [submitState, setSubmitState] = useState<GetDeviationChatMessagesState>(
    {
      status: 'none'
    }
  )
  const client = useBackend()

  const getDeviationChatMessages = useCallback(
    async (deviationId: number) => {
      setSubmitState({
        status: 'loading'
      })

      try {
        const [chatMessages, chatFiles] = await Promise.all([
          client.AuditsService.GetADHChatMessagesForDeviation(deviationId),
          client.StorageService.GetADHUserFilesForDeviation(deviationId)
        ])

        if (!chatMessages) {
          setSubmitState({
            status: 'success',
            chatMessages: [],
            adhLinksExists: false,
            chatFiles
          })
          return
        }

        setSubmitState({
          status: 'success',
          chatMessages,
          adhLinksExists: true,
          chatFiles
        })
      } catch (e) {
        setSubmitState({
          status: 'error'
        })
      }
    },
    [client.AuditsService, client.StorageService]
  )

  return [submitState, getDeviationChatMessages] as const
}
