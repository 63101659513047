import { useEffect, useState } from 'react'

export function useMediaQuery(query: string): boolean {
  const [isMatch, setIsMatch] = useState<boolean>(
    () => window.matchMedia(query).matches
  )

  useEffect(() => {
    const mediaQuery = window.matchMedia(query)
    const onChangeMatch = (mc: MediaQueryListEvent) => setIsMatch(mc.matches)

    mediaQuery.addEventListener('change', onChangeMatch)

    return () => {
      mediaQuery.removeEventListener('change', onChangeMatch)
    }
  }, [query])

  return isMatch
}
