import styled from 'styled-components'

export const AuditBadges = styled.div`
  display: flex;
  align-items: center;
  padding: 0 var(--sdds-spacing-element-16);

  & > * {
    margin: 0 0 0 2rem;
  }

  &:first-child {
    margin: 0;
  }
`
