import React, { useCallback } from 'react'

import type { Deviation } from '@cdab/scania/qpr/schema'
import { Column, Container, Row } from '@cdab/scania/sdds'
import { DeviationCard } from '@cdab/scania/qpr/components/atoms'
import { formatDate } from '@cdab/utils'

import type { DeviationListProps } from './deviation-list.types'

export function DeviationList({
  deviations,
  onClickDeviation
}: DeviationListProps) {
  const onClickDeviationCard = useCallback(
    (deviation: Deviation) => () => {
      onClickDeviation(deviation.clientGuid)
    },
    [onClickDeviation]
  )

  return (
    <Container fluid='normal'>
      <Row role='list'>
        {deviations.map(
          (deviation: Deviation): JSX.Element => (
            <Column
              width={12}
              md={4}
              lg={3}
              key={deviation.clientGuid}
              role='listitem'
            >
              <div onClick={onClickDeviationCard(deviation)}>
                <DeviationCard
                  title={deviation.deviation}
                  date={
                    deviation.expirationDate
                      ? formatDate(deviation.expirationDate)
                      : ''
                  }
                  key={deviation.clientGuid}
                />
              </div>
            </Column>
          )
        )}
      </Row>
    </Container>
  )
}
