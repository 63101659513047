import type {
  AuditsRealTimeService as AuditsRealTimeServiceDefinition,
  CreateDeviationResponse,
  RealTimeCallbacks,
  SetNoteData,
  UpdateCheckPointScoreAsync
} from '@cdab/scania/qpr/interactor'

export type RealTimeMocks = Partial<AuditsRealTimeServiceDefinition>

export class MockAuditsRealTimeService
  implements AuditsRealTimeServiceDefinition
{
  private mocks: RealTimeMocks

  public constructor(mocks: RealTimeMocks = {}) {
    this.mocks = mocks
  }

  public AddOnReconnectedCallback = () => {
    return
  }

  public UpdateCheckPointScore = async (update: UpdateCheckPointScoreAsync) => {
    if (this.mocks.UpdateCheckPointScore) {
      return await this.mocks.UpdateCheckPointScore(update)
    }

    return true
  }

  public UpdateAuditPointScore = async (
    auditId: number,
    auditPointId: number,
    score: boolean | null,
    callIdentifier: string,
    actionCreated: Date
  ) => {
    if (this.mocks.UpdateAuditPointScore)
      return await this.mocks.UpdateAuditPointScore(
        auditId,
        auditPointId,
        score,
        callIdentifier,
        actionCreated
      )

    return true
  }

  public ListenTo = async (auditId: number, callbacks: RealTimeCallbacks) => {
    if (auditId) {
      return () => undefined
    }

    if (callbacks) {
      return () => undefined
    }

    return () => undefined
  }

  /* Incoming Events */
  public NoteSaveOrUpdateChanged = (data: SetNoteData) => {
    if (data.pledgeId) {
      // update pledge
      return
    }

    if (data.auditPointId) {
      // update audit point
      return
    }

    if (data.checkPointId) {
      // update check point
      return
    }

    return
  }

  public SetNote = async (data: SetNoteData) => {
    if (this.mocks.SetNote) {
      return await this.mocks.SetNote(data)
    }

    return true
  }

  public UpdateDeviation = async (): Promise<boolean> => {
    return Promise.resolve(true)
  }

  public CreateDeviation = async (): Promise<CreateDeviationResponse> => {
    throw new Error('Not implemented')
  }

  public DeleteDeviation = async (
    callIdentifier: string,
    created: Date,
    auditId: number,
    deviationId: number
  ): Promise<boolean> => {
    if (this.mocks.DeleteDeviation) {
      this.mocks.DeleteDeviation(callIdentifier, created, auditId, deviationId)
    }

    return Promise.resolve(true)
  }
}
