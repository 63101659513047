import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import type { Dealer } from '@cdab/scania/qpr/schema'
import type { DealerModel } from '@cdab/scania/qpr/offline/models'
import { toDealerModel } from '@cdab/scania/qpr/offline/models'
import { getDealerModel } from '@cdab/scania/qpr/offline/models'
import { addDealer } from '@cdab/scania/qpr/offline/models'
import { cacheDealer, getCachedDealer } from '@cdab/scania/qpr/offline/cache'

class DealersController {
  private currentlyRunningGetDealers = new Map<
    Dealer['id'],
    Promise<DealerModel>
  >()

  private loadDealer = async (dealerId: Dealer['id']): Promise<DealerModel> => {
    const client = getClient()

    const dealer = await client.DealersService.GetDealerById(dealerId)
    const dealerModel = addDealer(toDealerModel(dealer))

    await cacheDealer(dealerModel)

    return dealerModel
  }

  private loadCachedDealer = async (
    dealerId: Dealer['id']
  ): Promise<DealerModel | undefined> => {
    const dealer = await getCachedDealer(dealerId)

    if (!dealer) return undefined

    const dealerModel = addDealer(dealer)

    return dealerModel
  }

  private getDealer = async (dealerId: Dealer['id']): Promise<DealerModel> => {
    const cachedDealer = await this.loadCachedDealer(dealerId)

    let dealerModel: DealerModel
    if (cachedDealer) {
      dealerModel = addDealer(cachedDealer)

      // Even if we have cached a dealer, it could be updated so refetch it
      this.loadDealer(dealerId)
    } else {
      dealerModel = await this.loadDealer(dealerId) // load also handles caching
    }

    return dealerModel
  }

  public GetDealer = async (dealerId: Dealer['id']): Promise<DealerModel> => {
    let currentlyRunningGetDealer =
      this.currentlyRunningGetDealers.get(dealerId)

    if (!currentlyRunningGetDealer) {
      currentlyRunningGetDealer = this.getDealer(dealerId)

      // Clean up
      currentlyRunningGetDealer.then(() => {
        if (this.currentlyRunningGetDealers.has(dealerId)) {
          this.currentlyRunningGetDealers.delete(dealerId)
        }
      })

      this.currentlyRunningGetDealers.set(dealerId, currentlyRunningGetDealer)
    }

    const dealerModel = getDealerModel(dealerId)
    if (dealerModel) {
      return dealerModel
    }

    const dealer = await currentlyRunningGetDealer

    return dealer
  }

  public SetCachedExpiryDate = async (
    dealerId: Dealer['id'],
    expiryDate: Date
  ) => {
    const cachedDealer = await getCachedDealer(dealerId)

    if (!cachedDealer) return

    cachedDealer.expiryDate = expiryDate
    await cacheDealer(cachedDealer)
  }
}

export const dealersController = new DealersController()
