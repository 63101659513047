import { useTranslation } from 'react-i18next'
import { useParams, useRouteLoaderData } from 'react-router-dom'
import invariant from 'tiny-invariant'

import { DocumentList } from '@cdab/scania/qpr/components'
import { useReferenceDocuments } from '@cdab/scania/qpr/hooks'
import {
  Column,
  Container,
  EmptyScreen,
  IconMessageInactive,
  Row
} from '@cdab/scania/sdds'
import { isNumber } from '@cdab/utils'
import styled from 'styled-components'
import type { AuditLoaderData } from '../../../../auditId'

const Content = styled.div`
  height: 100%;
  padding-bottom: var(--sdds-spacing-element-40);
`

type Params = {
  auditId: string
  auditPointId: string
}

export function ReferenceDocumentsTab() {
  const { t } = useTranslation()
  const params = useParams<Params>()

  const { audit } = useRouteLoaderData('audit') as AuditLoaderData

  const auditPointId = Number.parseInt(params.auditPointId || 'NaN')
  invariant(
    isNumber(auditPointId),
    `Audit point id was not of type number: ${params.auditPointId}`
  )

  const referenceDocuments = useReferenceDocuments(audit, auditPointId)

  return (
    <Content>
      {referenceDocuments.length > 0 ? (
        <Container paddingOnContainer={false}>
          <Row>
            <Column width={12} lg={10} padding={false} offset={{ lg: 1 }}>
              <DocumentList documents={referenceDocuments} />
            </Column>
          </Row>
        </Container>
      ) : (
        <EmptyScreen.CenteredWrapper>
          <EmptyScreen
            icon={<IconMessageInactive />}
            title={t('no-documents-found')}
          />
        </EmptyScreen.CenteredWrapper>
      )}
    </Content>
  )
}
