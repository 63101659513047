import { AuditItem } from '@cdab/scania/qpr/components/atoms'
import styled, { css } from 'styled-components'

export const StyledAuditPointItem = styled(AuditItem)`
  &:hover {
    background-color: var(--sdds-grey-100);
  }

  ${({ isMandatory, selected }) => {
    if (isMandatory && selected) {
      return css`
        background-color: var(--sdds-orange-300);
        &:hover {
          background-color: var(--sdds-orange-400);
        }
      `
    }

    if (isMandatory) {
      return css`
        background-color: var(--sdds-orange-100);

        &:hover {
          background-color: var(--sdds-orange-200);
        }
      `
    }

    if (selected) {
      return css`
        background-color: var(--sdds-grey-300);
      `
    }

    return css`
      background-color: var(--sdds-grey-50);
    `
  }}
`
