import type { AccordionItemProps } from './accordion-item.types'

export function AccordionItem({
  children,
  className,
  expanded,
  affix = 'prefix',
  header,
  disabled = false
}: AccordionItemProps): JSX.Element {
  return (
    <sdds-accordion-item
      className={className}
      affix={affix}
      role='region'
      header={header}
      expanded={expanded ? 'true' : 'false'}
      disabled={disabled}
    >
      {children}
    </sdds-accordion-item>
  )
}
