import type { Props } from './checkpoint-detail.types'

export function CheckPointDetail({ checkPoint }: Props): JSX.Element {
  return (
    <div>
      <h4>Check Point: {checkPoint.checkPointNo}</h4>
      <p>With score: {String(checkPoint.score?.valueOf() ?? 'none')}</p>
    </div>
  )
}
