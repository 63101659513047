import type {
  FavoriteAudit,
  FavoriteAuditSingle
} from '@cdab/scania/qpr/schema'
import { action, toJS } from 'mobx'
import invariant from 'tiny-invariant'
import type { FavoriteAuditModel } from '../favorite-audit-model'
import { favoriteAuditsStore, favoriteAuditsChangesStore } from './store'

function mergeFavoriteAudits(
  target: FavoriteAuditModel,
  source: FavoriteAuditModel
) {
  invariant(
    target.VERSION === source.VERSION,
    `Tried to merge object on different versions!`
  )

  target.auditId = source.auditId
  target.createdTime = source.createdTime
  target.id = source.id
}

export const addFavoriteAudit = action(
  (
    favoriteAudit: FavoriteAuditModel,
    useChangesStore = false
  ): FavoriteAuditModel => {
    let Store = favoriteAuditsStore

    if (useChangesStore) {
      Store = favoriteAuditsChangesStore
    }

    let favoriteAuditModel = Store[favoriteAudit.auditId]

    if (favoriteAuditModel) {
      mergeFavoriteAudits(favoriteAuditModel, favoriteAudit)
    } else {
      Store[favoriteAudit.auditId] = {
        ...favoriteAudit
      }
      favoriteAuditModel = Store[favoriteAudit.auditId]
    }

    invariant(
      favoriteAuditModel,
      `No favorite audit in ${
        useChangesStore ? 'changes' : ''
      } store after just adding it!`
    )
    return favoriteAuditModel
  }
)

export function getFavoriteAuditModel(
  AuditId: FavoriteAudit['auditId'],
  useChangesStore = false
): FavoriteAuditModel | undefined {
  let Store = favoriteAuditsStore

  if (useChangesStore) {
    Store = favoriteAuditsChangesStore
  }

  const favoriteAudit = Store[AuditId]

  return favoriteAudit
}

export function restoreFavoriteAuditFromData(
  favoriteAudit: FavoriteAuditModel
): FavoriteAuditModel {
  return favoriteAudit
}

export function getFavoriteAuditModelCacheData(
  favoriteAudit: FavoriteAuditModel
) {
  return toJS(favoriteAudit)
}

export function toFavoriteAuditModel(
  favoriteAudit: FavoriteAuditSingle
): FavoriteAuditModel {
  const { auditId } = favoriteAudit
  invariant(auditId !== null)
  return {
    ...favoriteAudit,
    auditId,
    VERSION: 1
  }
}

export const deleteFavoriteAuditModel = action(
  (AuditId: FavoriteAuditModel['auditId'], useChangesStore = false) => {
    let Store = favoriteAuditsStore

    if (useChangesStore) {
      Store = favoriteAuditsChangesStore
    }

    const favoriteAudit = Store[AuditId]

    if (favoriteAudit) {
      favoriteAudit.id = null
    }

    return favoriteAudit
  }
)
