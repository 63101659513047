// TODO: Where should this class live? We should probably create a generic schema real time error, and use that

export type ParsedQprError = {
  ErrorDomain: string
  NumericErrorCode: number
  ErrorCode: string
  ErrorDescription: string
}

export class QprHubError {
  static parseMessage(message: string): ParsedQprError {
    const [, errorData] = message.split('QprHubException: ')
    if (!errorData) throw new Error('Exception is not an QprHubError')

    const data = JSON.parse(errorData)
    return data
  }
  static createFromException(e: any) {
    if (QprHubError.isQprHubError(e)) {
      return new QprHubError(e.message)
    }
    throw new Error('Exception is not a QprHubError')
  }

  static isQprHubError(obj: any): obj is QprHubError {
    if (!obj.message) return false
    try {
      QprHubError.parseMessage(obj.message)
      return true
    } catch {
      return false
    }
  }

  constructor(message: string) {
    this.message = message
  }

  message = ''
  _parsedData: ParsedQprError | undefined
  get parsedData(): ParsedQprError {
    if (!this._parsedData) {
      this._parsedData = QprHubError.parseMessage(this.message)
    }

    return this._parsedData
  }

  getValue(key: keyof ParsedQprError) {
    return this.parsedData[key]
  }

  get errorDomain() {
    return this.getValue('ErrorDomain')
  }
  get errorCode() {
    return this.getValue('ErrorCode')
  }
  get numericErrorCode() {
    return this.getValue('NumericErrorCode')
  }
  get errorDescription() {
    return this.getValue('ErrorDescription')
  }
}
