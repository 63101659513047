import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export interface DealerAudit {
  auditDate: Date | null
  auditDescription: string
  auditor: string
  auditTypeDescription: string
  dosVersion: string | null
  extraAuditors: string
  hasDeviations: boolean
  hasNotes: boolean
  id: number
  isCertificationAudit: boolean | null
}

export interface DealerAuditJson {
  auditDate: string | null
  auditDescription: string
  auditor: string
  auditTypeDescription: string
  dosVersion: string | null
  extraAuditors: string
  hasDeviations: boolean
  hasNotes: boolean
  id: number
  isCertificationAudit: boolean | null
}

export const DealerAuditSchema: JSONSchemaType<DealerAuditJson> = {
  type: 'object',

  properties: {
    auditDate: {
      type: 'string',
      format: 'iso-date-time',
      nullable: true
    },
    auditDescription: {
      type: 'string'
    },
    auditor: {
      type: 'string'
    },
    dosVersion: {
      type: 'string',
      nullable: true
    },
    extraAuditors: {
      type: 'string'
    },

    auditTypeDescription: {
      type: 'string'
    },
    hasDeviations: {
      type: 'boolean'
    },
    hasNotes: {
      type: 'boolean'
    },
    id: {
      type: 'number'
    },
    isCertificationAudit: {
      type: 'boolean',
      nullable: true
    }
  },

  required: [
    'auditDate',
    'auditDescription',
    'auditor',
    'dosVersion',
    'extraAuditors',
    'hasDeviations',
    'hasNotes',
    'id',
    'isCertificationAudit'
  ]
}

const validateDealerAudit: ValidateFunction<DealerAuditJson> =
  ajv.compile(DealerAuditSchema)

export const isValidDealerAuditJson = (
  dealerAudit: unknown
): dealerAudit is DealerAuditJson => {
  return isValidThing<DealerAuditJson>(dealerAudit, validateDealerAudit)
}

export function createDealerAuditFromJson(json: DealerAuditJson): DealerAudit {
  return {
    auditDate: json.auditDate ? new Date(json.auditDate) : null,
    auditDescription: json.auditDescription,
    auditor: json.auditor,
    auditTypeDescription: json.auditTypeDescription,
    dosVersion: json.dosVersion,
    extraAuditors: json.extraAuditors,
    hasDeviations: json.hasDeviations,
    hasNotes: json.hasNotes,
    id: json.id,
    isCertificationAudit: json.isCertificationAudit
  }
}
