import { AuditItem } from '@cdab/scania/qpr/components/atoms'
import styled from 'styled-components'

export const StyledPledgeItem = styled(AuditItem)`
  background-color: ${props =>
    props.selected ? 'var(--sdds-grey-500)' : 'var(--sdds-grey-300)'};
  &:hover {
    background-color: ${props =>
      props.selected ? 'var(--sdds-grey-600)' : 'var(--sdds-grey-400)'};
  }
`
