import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export type AuditPointTranslation = {
  id: number | null
  auditPointNo: string
  defaultDescription: string
  translationGuid: string
  translatedDescription: string | null
  purposeTranslationId: number | null
  purposeTranslationGuid: string
  purposeDefaultDescription: string
  purposeTranslatedDescription: string | null
  md: string
  dealerWorkshopSales: string
}

export const AuditPointTranslationSchema: JSONSchemaType<AuditPointTranslation> =
  {
    type: 'object',
    properties: {
      id: {
        type: 'integer',
        nullable: true
      },
      auditPointNo: {
        type: 'string'
      },
      defaultDescription: {
        type: 'string'
      },
      translatedDescription: {
        type: 'string',
        nullable: true
      },
      translationGuid: {
        type: 'string'
      },
      purposeTranslationId: {
        type: 'integer',
        nullable: true
      },
      purposeTranslationGuid: {
        type: 'string'
      },
      purposeDefaultDescription: {
        type: 'string'
      },
      purposeTranslatedDescription: {
        type: 'string',
        nullable: true
      },
      md: {
        type: 'string'
      },
      dealerWorkshopSales: {
        type: 'string'
      }
    },
    required: [
      'id',
      'auditPointNo',
      'defaultDescription',
      'translationGuid',
      'translatedDescription',
      'purposeTranslationId',
      'purposeTranslationGuid',
      'purposeDefaultDescription',
      'purposeTranslatedDescription',
      'md',
      'dealerWorkshopSales'
    ]
  }

const validateAuditPointTranslation: ValidateFunction<AuditPointTranslation> =
  ajv.compile(AuditPointTranslationSchema)

export const isValidAuditPointTranslation = (
  auditPointTranslation: unknown
): auditPointTranslation is AuditPointTranslation =>
  isValidThing<AuditPointTranslation>(
    auditPointTranslation,
    validateAuditPointTranslation
  )

export type CheckPointTranslation = {
  id: number | null
  checkPointNo: string
  defaultDescription: string
  translationGuid: string
  translatedDescription: string | null
}

export const CheckPointTranslationSchema: JSONSchemaType<CheckPointTranslation> =
  {
    type: 'object',
    properties: {
      id: {
        type: 'integer',
        nullable: true
      },
      checkPointNo: {
        type: 'string'
      },
      defaultDescription: {
        type: 'string'
      },
      translationGuid: {
        type: 'string'
      },
      translatedDescription: {
        type: 'string',
        nullable: true
      }
    },
    required: [
      'id',
      'checkPointNo',
      'defaultDescription',
      'translationGuid',
      'translatedDescription'
    ]
  }

const validateCheckPointTranslation: ValidateFunction<CheckPointTranslation> =
  ajv.compile(CheckPointTranslationSchema)

export const isValidCheckPointTranslation = (
  checkPointTranslation: unknown
): checkPointTranslation is CheckPointTranslation =>
  isValidThing<CheckPointTranslation>(
    checkPointTranslation,
    validateCheckPointTranslation
  )

export type PledgePerspectiveTranslation = {
  id: number | null
  pledgeNo: string
  perspectiveType: string
  defaultDescription: string
  translationGuid: string
  translatedDescription: string | null
}

export const PledgePerspectiveTranslationSchema: JSONSchemaType<PledgePerspectiveTranslation> =
  {
    type: 'object',
    properties: {
      id: {
        type: 'integer',
        nullable: true
      },
      pledgeNo: {
        type: 'string'
      },
      perspectiveType: {
        type: 'string'
      },
      defaultDescription: {
        type: 'string'
      },
      translationGuid: {
        type: 'string'
      },
      translatedDescription: {
        type: 'string',
        nullable: true
      }
    },
    required: [
      'id',
      'pledgeNo',
      'perspectiveType',
      'defaultDescription',
      'translationGuid',
      'translatedDescription'
    ]
  }

const validatePledgePerspectiveTranslation: ValidateFunction<PledgePerspectiveTranslation> =
  ajv.compile(PledgePerspectiveTranslationSchema)

export const isValidPledgePerspectiveTranslation = (
  pledgePerspectiveTranslation: unknown
): pledgePerspectiveTranslation is PledgePerspectiveTranslation =>
  isValidThing<PledgePerspectiveTranslation>(
    pledgePerspectiveTranslation,
    validatePledgePerspectiveTranslation
  )

export type PledgesTranslation = {
  id: number | null
  pledgeNo: string
  defaultDescription: string
  translationGuid: string
  translatedDescription: string | null
}

export const PledgesTranslationSchema: JSONSchemaType<PledgesTranslation> = {
  type: 'object',
  properties: {
    id: {
      type: 'integer',
      nullable: true
    },
    pledgeNo: {
      type: 'string'
    },
    defaultDescription: {
      type: 'string'
    },
    translationGuid: {
      type: 'string'
    },
    translatedDescription: {
      type: 'string',
      nullable: true
    }
  },
  required: [
    'id',
    'pledgeNo',
    'defaultDescription',
    'translationGuid',
    'translatedDescription'
  ]
}

const validatePledgesTranslation: ValidateFunction<PledgesTranslation> =
  ajv.compile(PledgesTranslationSchema)

export const isValidPledgesTranslation = (
  pledgesTranslation: unknown
): pledgesTranslation is PledgesTranslation =>
  isValidThing<PledgesTranslation>(
    pledgesTranslation,
    validatePledgesTranslation
  )

export interface AdminTranslations {
  auditPointsTranslations: AuditPointTranslation[]
  checkPointsTranslations: CheckPointTranslation[]
  pledgePerspectivesTranslations: PledgePerspectiveTranslation[]
  pledgesTranslations: PledgesTranslation[]
}

export const AdminTranslationsSchema: JSONSchemaType<AdminTranslations> = {
  type: 'object',
  properties: {
    auditPointsTranslations: {
      type: 'array',
      items: AuditPointTranslationSchema
    },
    checkPointsTranslations: {
      type: 'array',
      items: CheckPointTranslationSchema
    },
    pledgePerspectivesTranslations: {
      type: 'array',
      items: PledgePerspectiveTranslationSchema
    },
    pledgesTranslations: { type: 'array', items: PledgesTranslationSchema }
  },
  required: [
    'auditPointsTranslations',
    'checkPointsTranslations',
    'pledgePerspectivesTranslations',
    'pledgesTranslations'
  ]
}

const validateAdminTranslations: ValidateFunction<AdminTranslations> =
  ajv.compile(AdminTranslationsSchema)

export const isValidAdminTranslations = (
  adminTranslations: unknown
): adminTranslations is AdminTranslations =>
  isValidThing<AdminTranslations>(adminTranslations, validateAdminTranslations)
