import { UploadFileButton } from '@cdab/scania/qpr/components/atoms'
import { AttachmentList } from '@cdab/scania/qpr/components/molecules'
import {
  useCreateDocument,
  useCreateDocumentWithFile,
  useCssVariableBreakpoint
} from '@cdab/scania/qpr/hooks'
import type { User } from '@cdab/scania/qpr/schema'
import { Roles, type DocumentFile } from '@cdab/scania/qpr/schema'
import { freeMemory } from '@cdab/scania/qpr/utils'
import {
  Button,
  Column,
  DelayedSpinner,
  Dropdown,
  DropdownOption,
  RadioButton,
  Textfield,
  Toast,
  type TOption
} from '@cdab/scania/sdds'
import {
  ValidateUrl,
  capitalizeFirstLetter,
  checkStringLength
} from '@cdab/utils'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import {
  ButtonWrapper,
  SpinnerWrapper,
  StyledButton,
  StyledColumn,
  StyledFirstButton,
  StyledLinkRow,
  StyledMessage,
  StyledRow,
  StyledSectionTitle,
  StyledTextfieldWrapper,
  Title
} from './document-create-form.styles'
import type {
  DocumentCreateFormProps,
  NewAdminDocumentCreateData
} from './document-create-form.types'

export function DocumentCreateForm({
  auditPoints,
  checkPoints,
  allMarkets,
  currentUser
}: DocumentCreateFormProps) {
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const navigate = useNavigate()
  const { t } = useTranslation(['documents', 'common'])

  const [data, setData] = useState<NewAdminDocumentCreateData>({
    title: '',
    auditPointsIds: [],
    checkPointsIds: [],
    marketId: null,
    url: ''
  })

  const [stateDocumentTitle, setStateDocumentTitle] = useState<boolean>(true)
  const [stateDocumentType, setStateDocumentType] = useState('File')
  const [stateAuditPoints, setStateAuditPoints] = useState<boolean>(true)
  const [stateCheckPoints, setStateCheckPoints] = useState<boolean>(true)
  const [stateUrl, setStateUrl] = useState<boolean>(true)

  const [stateFileToUpload, setStateFileToUpload] = useState<
    DocumentFile | undefined
  >(undefined)

  const [
    createUploadDocumentState,
    handleClickUploadCreateDocument,
    setCreateUploadDocumentState
  ] = useCreateDocumentWithFile(
    { ...data, documentFileId: null },
    stateFileToUpload
  )

  const [
    createDocumentState,
    handleClickCreateDocument,
    setCreateDocumentState
  ] = useCreateDocument({
    ...data,
    documentFileId: null
  })

  const isLoading =
    createUploadDocumentState.status === 'uploading' ||
    createDocumentState.status === 'loading'

  const isSuccess =
    createUploadDocumentState.status === 'success' ||
    createDocumentState.status === 'success'

  const shouldShowUploadButton =
    stateDocumentType === 'File' && !stateFileToUpload

  const shouldShowAttachmentsList =
    stateDocumentType === 'File' && stateFileToUpload

  const onDeleteSelectedFile = (fileId: string) => {
    if (!stateFileToUpload) return

    freeMemory(stateFileToUpload)

    setStateFileToUpload(undefined)
  }

  const onUploadFileClick = (fileList: FileList) => {
    const files = Array.from(fileList)

    if (files.length === 0) return

    const inputFiles: DocumentFile[] = Array.from(files).map(file => ({
      file,
      id: uuidv4(),
      url: URL.createObjectURL(file)
    }))

    setStateFileToUpload(inputFiles[0])
  }

  function hasAccess(currentUser: User): boolean {
    return currentUser.role === Roles.CoOrdinator
  }

  const checkAllData = useCallback(() => {
    return (
      checkStringLength(data.title, 4) &&
      (data.auditPointsIds.length > 0 || data.checkPointsIds.length > 0) &&
      (stateDocumentType === 'File'
        ? stateFileToUpload !== undefined
        : ValidateUrl(data.url))
    )
  }, [
    data.title,
    data.auditPointsIds.length,
    data.checkPointsIds.length,
    data.url,
    stateDocumentType,
    stateFileToUpload
  ])

  const validateAllData = useCallback(() => {
    setStateDocumentTitle(checkStringLength(data.title, 4))
    setStateAuditPoints(
      data.auditPointsIds.length > 0 || data.checkPointsIds.length > 0
    )
    setStateCheckPoints(
      data.auditPointsIds.length > 0 || data.checkPointsIds.length > 0
    )
    setStateUrl(ValidateUrl(data.url))

    return checkAllData()
  }, [
    data.title,
    data.auditPointsIds.length,
    data.checkPointsIds.length,
    data.url,
    checkAllData
  ])

  const onChangeAuditPoints = useCallback(
    (option: TOption) => {
      const changedAuditPointId = parseInt(option.value)
      let curAuditPoints = data.auditPointsIds
      const auditPointInData = curAuditPoints.find(
        auditPointId => auditPointId === changedAuditPointId
      )
      const hasCheckPoints = data.checkPointsIds.length > 0
      if (auditPointInData) {
        curAuditPoints = curAuditPoints.filter(e => e !== auditPointInData)
        setData({
          ...data,
          auditPointsIds: curAuditPoints.filter(e => e !== auditPointInData)
        })
        setStateAuditPoints(curAuditPoints.length > 0 || hasCheckPoints)
        setStateCheckPoints(curAuditPoints.length > 0 || hasCheckPoints)
      } else {
        curAuditPoints.push(changedAuditPointId)
        setData({
          ...data,
          auditPointsIds: curAuditPoints
        })
        setStateAuditPoints(curAuditPoints.length > 0 || hasCheckPoints)
        setStateCheckPoints(curAuditPoints.length > 0 || hasCheckPoints)
      }
    },
    [data]
  )

  const onChangeCheckPoints = useCallback(
    (option: TOption) => {
      const changedCheckPointId = parseInt(option.value)
      let curCheckPoints = data.checkPointsIds
      const checkPointInData = curCheckPoints.find(
        checkPointId => checkPointId === changedCheckPointId
      )
      const hasAuditPoints = data.auditPointsIds.length > 0
      if (checkPointInData) {
        curCheckPoints = curCheckPoints.filter(e => e !== checkPointInData)
        setData({
          ...data,
          checkPointsIds: curCheckPoints.filter(e => e !== checkPointInData)
        })
        setStateAuditPoints(curCheckPoints.length > 0 || hasAuditPoints)
        setStateCheckPoints(curCheckPoints.length > 0 || hasAuditPoints)
      } else {
        curCheckPoints.push(changedCheckPointId)
        setData({
          ...data,
          checkPointsIds: curCheckPoints
        })
        setStateAuditPoints(curCheckPoints.length > 0 || hasAuditPoints)
        setStateCheckPoints(curCheckPoints.length > 0 || hasAuditPoints)
      }
    },
    [data]
  )

  useEffect(() => {
    return () => {
      freeMemory(stateFileToUpload)
    }
  }, [stateFileToUpload])

  useEffect(() => {
    if (isSuccess) {
      if (createUploadDocumentState.status === 'success') {
        toast(
          <Toast
            type='success'
            headline={t('document-created', { ns: 'common' })}
            subheadline={createUploadDocumentState.message}
          />
        )
        setCreateUploadDocumentState({ status: 'none', message: null })
      }

      if (createDocumentState.status === 'success') {
        toast(
          <Toast
            type='success'
            headline={t('document-created', { ns: 'common' })}
            subheadline={createDocumentState.message}
          />
        )
        setCreateDocumentState({ status: 'none', message: null })
      }

      navigate('/admin/documents/all')
    }
  }, [
    createDocumentState,
    createUploadDocumentState,
    isSuccess,
    navigate,
    setCreateDocumentState,
    setCreateUploadDocumentState,
    t
  ])

  return (
    <Column width={12} lg={6} offset={{ lg: 3 }}>
      <StyledRow>
        <Column width={12} lg={6} padding={isLg}>
          <Title>{t('add-new-document', { ns: 'documents' })}</Title>
        </Column>
      </StyledRow>
      <StyledRow>
        <StyledColumn width={12} padding={isLg}>
          <Textfield
            label={t('name', { ns: 'common' })}
            labelPosition='outside'
            maxLength={200}
            placeholder={t('add-name-for-document', {
              ns: 'documents'
            })}
            value={data.title}
            onChange={e => {
              setData({ ...data, title: e.target.value })
            }}
            onBlur={e =>
              setStateDocumentTitle(checkStringLength(e.target.value, 4))
            }
            state={!stateDocumentTitle ? 'error' : undefined}
            helper={
              !stateDocumentTitle &&
              t('message.validation-error', { chars: '4' })
            }
          />
        </StyledColumn>
      </StyledRow>
      <StyledRow>
        <StyledColumn width={12} lg={6} padding={isLg}>
          <Dropdown
            id='auditPointId'
            label={capitalizeFirstLetter(
              t('audit-point', {
                ns: 'common'
              })
            )}
            size='lg'
            labelPosition='outside'
            placeholder={t('select-audit-point', {
              ns: 'documents'
            })}
            defaultOption={data.auditPointsIds.join(',')}
            type='multiselect'
            onSelect={onChangeAuditPoints}
            state={!stateAuditPoints ? 'error' : undefined}
            openDirection={isLg ? 'down' : 'auto'}
            helper={
              !stateAuditPoints &&
              capitalizeFirstLetter(
                t('auditpoint-required', { ns: 'documents' })
              )
            }
          >
            {auditPoints.map(({ id, auditPointNo }) => {
              return (
                <DropdownOption
                  key={id}
                  value={id.toString()}
                  text={auditPointNo}
                />
              )
            })}
          </Dropdown>
        </StyledColumn>
        <StyledColumn width={12} lg={6} padding={isLg}>
          <Dropdown
            id='checkPointId'
            label={capitalizeFirstLetter(
              t('checkpoint', {
                ns: 'common'
              })
            )}
            size='lg'
            labelPosition='outside'
            placeholder={t('select-check-point', {
              ns: 'documents'
            })}
            defaultOption={data.checkPointsIds.join(',')}
            type='multiselect'
            onSelect={onChangeCheckPoints}
            openDirection={isLg ? 'down' : 'auto'}
            state={!stateCheckPoints ? 'error' : undefined}
            helper={
              !stateCheckPoints && t('checkpoint-required', { ns: 'documents' })
            }
          >
            {checkPoints.map(({ id, checkPointNo }) => {
              return (
                <DropdownOption
                  key={id}
                  value={id.toString()}
                  text={checkPointNo}
                />
              )
            })}
          </Dropdown>
        </StyledColumn>
      </StyledRow>
      <StyledRow>
        <StyledColumn width={12} padding={isLg}>
          <Dropdown
            id='marketId'
            label={capitalizeFirstLetter(t('market', { ns: 'common' }))}
            size='lg'
            labelPosition='outside'
            placeholder={t('select-market', { ns: 'documents' })}
            defaultOption={'null'}
            openDirection={isLg ? 'down' : 'auto'}
            onSelect={(option: TOption) => {
              setData({
                ...data,
                marketId:
                  option.value === 'null' ? null : parseInt(option.value)
              })
            }}
          >
            {!hasAccess(currentUser) && (
              <DropdownOption key='factory' value='null' text='Factory' />
            )}
            {allMarkets.map(market => (
              <DropdownOption
                key={market.id}
                value={market.id.toString()}
                text={market.marketName}
              />
            ))}
          </Dropdown>
        </StyledColumn>
      </StyledRow>
      <StyledRow>
        <StyledColumn width={12} lg={3} padding={isLg}>
          <StyledSectionTitle>
            {t('document-type', { ns: 'documents' })}
          </StyledSectionTitle>
          <RadioButton
            name='document'
            defaultSelected='File'
            onChange={e => setStateDocumentType(e)}
          >
            <RadioButton.Item
              label={t('file', { ns: 'documents' })}
              value='File'
            />
            <RadioButton.Item
              label={t('link', { ns: 'common' })}
              value='Link'
            />
          </RadioButton>
        </StyledColumn>
        <StyledColumn width={12} lg={9} padding={isLg}>
          {shouldShowUploadButton ? (
            <UploadFileButton
              className='sdds-u-mt3'
              size='sm'
              variant='secondary'
              multiple={false}
              onUploadFiles={onUploadFileClick}
            />
          ) : shouldShowAttachmentsList ? (
            <AttachmentList
              color='on-white'
              fileType='all'
              files={[
                {
                  fileName: stateFileToUpload.file.name,
                  id: stateFileToUpload.id,
                  isImage: false,
                  isUploaded: createUploadDocumentState.status === 'success',
                  uploadProgress:
                    createUploadDocumentState.status === 'uploading'
                      ? createUploadDocumentState.progress
                      : createUploadDocumentState.status === 'success'
                      ? 100
                      : 0,
                  url: stateFileToUpload.url
                }
              ]}
              hideUploadProgress={false}
              onDeleteAttachment={onDeleteSelectedFile}
              disableDelete={createUploadDocumentState.status === 'uploading'}
            />
          ) : (
            <StyledLinkRow>
              <StyledTextfieldWrapper>
                <Textfield
                  label='URL'
                  labelPosition='outside'
                  maxLength={600}
                  name='URL'
                  placeholder='https//google.com'
                  value={data.url ?? ''}
                  onChange={e => {
                    setData({ ...data, url: e.target.value })
                  }}
                  onBlur={e => setStateUrl(ValidateUrl(e.target.value))}
                  state={!stateUrl ? 'error' : undefined}
                  helper={!stateUrl && t('url-notValid', { ns: 'documents' })}
                />
              </StyledTextfieldWrapper>
              <Button
                size='md'
                type='secondary'
                text={t('open', { ns: 'common' })}
                onClick={() => data.url && window.open(data.url, '_blank')}
              />
            </StyledLinkRow>
          )}
        </StyledColumn>
      </StyledRow>
      <StyledRow>
        <Column width={12} lg={12} padding={isLg}>
          {createUploadDocumentState.status === 'error' && (
            <StyledMessage
              className='sdds-u-mt1'
              variant='single-line'
              singleLineMessage={createUploadDocumentState.message}
              type={createUploadDocumentState.status}
            />
          )}
          {createDocumentState.status === 'error' && (
            <StyledMessage
              className='sdds-u-mt1'
              variant='single-line'
              singleLineMessage={createDocumentState.message}
              type={createDocumentState.status}
            />
          )}
        </Column>
      </StyledRow>
      <StyledRow>
        <Column width={12} lg={12} padding={isLg}>
          <ButtonWrapper>
            <StyledFirstButton
              text={t('cancel', {
                ns: 'common'
              })}
              type='secondary'
              onClick={() => navigate('/admin/documents/all')}
            />
            {createUploadDocumentState.status === 'uploading' ? (
              <SpinnerWrapper>
                <DelayedSpinner size='md' />
              </SpinnerWrapper>
            ) : (
              <StyledButton
                disabled={isLoading || isSuccess || !checkAllData()}
                text={capitalizeFirstLetter(t('save', { ns: 'common' }))}
                onClick={() => {
                  if (!isLoading && !isSuccess && validateAllData())
                    stateDocumentType === 'File'
                      ? handleClickUploadCreateDocument()
                      : handleClickCreateDocument()
                }}
              />
            )}
          </ButtonWrapper>
        </Column>
      </StyledRow>
    </Column>
  )
}
