import parse from 'html-react-parser'
import type { TFunction } from 'i18next'

export function filteredTranslation(
  guid: string,
  t: TFunction
): string | React.JSX.Element | React.JSX.Element[] {
  const text = t(guid)

  return parse(text)
}
