import type { Audit, PledgePerspective } from '@cdab/scania/qpr/schema'

import type { AuditModelBase } from './base'
import { restoreDeviationDates } from './restoration.utils'
import type { AuditModelV2, AuditModelV2Data } from './v2'

export type AuditModelV3Data = AuditModelV2Data & {
  pledgePerspectives: PledgePerspective[]
}

export type AuditModelV3 = AuditModelBase<AuditModelV3Data, 3>

export function restoreAuditFromDataV3(audit: AuditModelV3) {
  restoreDeviationDates(audit.deviations)

  // FIXME: I believe the more correct approach would be to keep this "stateful" data uncached,
  // maybe in a different cache. But it works for now.
  audit.isGettingFiles = false

  return audit
}

export function createFromAuditV3(audit: Audit) {
  const auditModel: AuditModelV3 = {
    ...audit,
    deviations: audit.deviations.map(d => ({
      ...d,
      files: [],
      isGettingFiles: false
    })),
    auditPoints: audit.auditPoints.map(ap => ({
      ...ap,
      files: [],
      isGettingFiles: false
    })),
    pledges: audit.pledges.map(pl => ({
      ...pl,
      files: [],
      isGettingFiles: false
    })),
    files: [],
    isGettingFiles: false,
    VERSION: 3
  }

  return auditModel
}

export function migrateToV3(audit: AuditModelV2): AuditModelV3 {
  const auditModel: AuditModelV3 = Object.assign(audit, {
    PledgePerspectives: [],
    VERSION: 3 as const
  })

  return auditModel
}
