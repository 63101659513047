import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { CreateSystemMessageData } from '@cdab/scania/qpr/interactor'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type CreateSystemMessageState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

export function useSendCreateSystemMessage() {
  const client = useBackend()

  const createSystemMessage = async (
    data: CreateSystemMessageData
  ): Promise<string> => {
    try {
      await client.SystemMessagesService.CreateSystemMessage(data)
      return 'success'
    } catch (e) {
      return 'error'
    }
  }

  return {
    createSystemMessage
  }
}

export function useCreateSystemMessage(data: CreateSystemMessageData) {
  const { t } = useTranslation('systemMessages')
  const { createSystemMessage } = useSendCreateSystemMessage()
  const [submitState, setSubmitState] = useState<CreateSystemMessageState>({
    status: 'none',
    message: null
  })

  const handleClickCreateSystemMessage = useCallback(async () => {
    const response = await createSystemMessage(data)
    setSubmitState({ status: 'none', message: null })

    if (response === 'success') {
      setSubmitState({ status: response, message: t('message.create-success') })
      return true
    }

    setSubmitState({ status: 'error', message: t('message.create-error') })
    return false
  }, [data, t, createSystemMessage])
  return [submitState, handleClickCreateSystemMessage, setSubmitState] as const
}
