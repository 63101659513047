import { arrayBufferToFile } from '@cdab/utils'
import invariant from 'tiny-invariant'
import type {
  AddAuditFileOperationData,
  CachedAddAuditFileOperationData
} from './add-audit-file'
import { AddAuditFileOperation } from './add-audit-file'
import type {
  AddAuditPointFileOperationData,
  CachedAddAuditPointFileOperationData
} from './add-audit-point-file'
import { AddAuditPointFileOperation } from './add-audit-point-file'
import type {
  AddDeviationFileOperationData,
  CachedAddDeviationFileOperationData
} from './add-deviation-file'
import { AddDeviationFileOperation } from './add-deviation-file'
import type { CreateDeviationOperationData } from './create-deviation'
import { CreateDeviationOperation } from './create-deviation'
import type { DeleteAuditFileOperationData } from './delete-audit-file'
import { DeleteAuditFileOperation } from './delete-audit-file'
import type { DeleteAuditPointFileOperationData } from './delete-audit-point-file'
import { DeleteAuditPointFileOperation } from './delete-audit-point-file'
import type { DeleteDeviationOperationData } from './delete-deviation'
import { DeleteDeviationOperation } from './delete-deviation'
import type { DeleteDeviationFileOperationData } from './delete-deviation-file'
import { DeleteDeviationFileOperation } from './delete-deviation-file'
import type { Operation } from './operations-base'
import type { SetAuditNoteOperationData } from './set-audit-note'
import { SetAuditNoteOperation } from './set-audit-note'
import type { SetAuditPointNoteOperationData } from './set-audit-point-note'
import { SetAuditPointNoteOperation } from './set-audit-point-note'
import type { SetAuditPointScoreData } from './set-audit-point-score'
import { SetAuditPointScoreOperation } from './set-audit-point-score'
import type { SetCheckPointNoteOperationData } from './set-checkpoint-note'
import { SetCheckPointNoteOperation } from './set-checkpoint-note'
import type { SetCheckPointScoreData } from './set-checkpoint-score'
import { SetCheckPointScore } from './set-checkpoint-score'
import type { SetPledgeNoteOperationData } from './set-pledge-note'
import { SetPledgeNoteOperation } from './set-pledge-note'
import type { UpdateAuditOperationData } from './update-audit'
import { UpdateAuditOperation } from './update-audit'
import type { UpdateDeviationOperationData } from './update-deviation'
import { UpdateDeviationOperation } from './update-deviation'
import type { DeletePledgeFileOperationData } from './delete-pledge-file'
import { DeletePledgeFileOperation } from './delete-pledge-file'
import type {
  AddPledgeFileOperationData,
  CachedAddPledgeFileOperationData
} from './add-pledge-file'
import { AddPledgeFileOperation } from './add-pledge-file'

const ERR_PREVIOUS_VALUE_NOTE_SET =
  'createOperationFromData: previous value was not set!'

export type OperationData =
  | CreateDeviationOperationData
  | DeleteDeviationOperationData
  | SetAuditNoteOperationData
  | SetAuditPointNoteOperationData
  | SetAuditPointScoreData
  | UpdateDeviationOperationData
  | SetCheckPointNoteOperationData
  | SetCheckPointScoreData
  | AddAuditPointFileOperationData
  | AddDeviationFileOperationData
  | DeleteAuditPointFileOperationData
  | UpdateAuditOperationData
  | DeleteDeviationFileOperationData
  | SetPledgeNoteOperationData
  | AddAuditFileOperationData
  | DeleteAuditFileOperationData
  | AddPledgeFileOperationData
  | DeletePledgeFileOperationData

export type OperationType = Pick<OperationData, 'type'>['type']

export function createOperationFromData(op: Operation): Operation {
  const { data } = op

  let operation

  switch (data.type) {
    case 'create-deviation':
      operation = new CreateDeviationOperation(op.auditId, data.value, op.guid)
      break

    case 'delete-deviation':
      operation = new DeleteDeviationOperation(op.auditId, data.value, op.guid)
      break

    case 'set-audit-note':
      invariant(data.previousValue, ERR_PREVIOUS_VALUE_NOTE_SET)
      operation = new SetAuditNoteOperation(
        op.auditId,
        data.value,
        data.previousValue,
        op.guid
      )
      break

    case 'set-audit-point-note':
      invariant(data.previousValue, ERR_PREVIOUS_VALUE_NOTE_SET)
      operation = new SetAuditPointNoteOperation(
        op.auditId,
        data.value,
        data.previousValue,
        op.guid
      )
      break

    case 'set-audit-point-score':
      invariant(data.previousValue, ERR_PREVIOUS_VALUE_NOTE_SET)
      operation = new SetAuditPointScoreOperation(
        op.auditId,
        data.value,
        data.previousValue,
        op.guid
      )
      break

    case 'update-deviation':
      invariant(data.previousValue, ERR_PREVIOUS_VALUE_NOTE_SET)
      operation = new UpdateDeviationOperation(
        op.auditId,
        data.value,
        data.previousValue,
        op.guid
      )

      // If data comes from JSON.parse, dates will be strings. So we need to parse them
      operation = updateDates(operation)
      break

    case 'set-checkpoint-score':
      invariant(data.previousValue, ERR_PREVIOUS_VALUE_NOTE_SET)
      operation = new SetCheckPointScore(
        op.auditId,
        data.value,
        data.previousValue,
        op.guid
      )
      break

    case 'add-audit-point-file':
      operation = new AddAuditPointFileOperation(
        op.auditId,
        {
          ...(op.data as AddAuditPointFileOperationData).value,

          file: arrayBufferToFile(
            (op as CachedAddAuditPointFileOperationData).data.value.fileBuffer,
            (op as CachedAddAuditPointFileOperationData).data.value.fileName,
            (op as CachedAddAuditPointFileOperationData).data.value.mimeType
          )
        },
        op.guid
      )
      break

    case 'add-deviation-file':
      {
        const {
          data: {
            value: { fileBuffer, fileName, mimeType }
          }
        } = op as unknown as CachedAddDeviationFileOperationData

        operation = new AddDeviationFileOperation(
          op.auditId,
          {
            ...data.value,
            file: arrayBufferToFile(fileBuffer, fileName, mimeType)
          },
          op.guid
        )
      }
      break

    case 'delete-audit-point-file':
      operation = new DeleteAuditPointFileOperation(
        op.auditId,
        data.value,
        op.guid
      )
      break

    case 'update-audit':
      invariant(data.previousValue, ERR_PREVIOUS_VALUE_NOTE_SET)

      data.value.date = ensureDate(data.value.date)
      data.previousValue.date = ensureDate(data.value.date)

      operation = new UpdateAuditOperation(
        op.auditId,
        data.value,
        data.previousValue,
        op.guid
      )
      break

    case 'delete-deviation-file':
      operation = new DeleteDeviationFileOperation(
        op.auditId,
        data.value,
        op.guid
      )
      break

    case 'set-pledge-note':
      operation = new SetPledgeNoteOperation(
        op.auditId,
        data.value,
        data.previousValue?.note,
        op.guid
      )
      break

    case 'add-audit-file':
      {
        const {
          data: {
            value: { fileBuffer, fileName, mimeType }
          }
        } = op as unknown as CachedAddAuditFileOperationData

        operation = new AddAuditFileOperation(
          op.auditId,
          {
            ...data.value,
            file: arrayBufferToFile(fileBuffer, fileName, mimeType)
          },
          op.guid
        )
      }
      break

    case 'delete-audit-file':
      operation = new DeleteAuditFileOperation(op.auditId, data.value, op.guid)
      break

    case 'set-checkpoint-note':
      invariant(data.previousValue, ERR_PREVIOUS_VALUE_NOTE_SET)
      operation = new SetCheckPointNoteOperation(
        op.auditId,
        data.value,
        data.previousValue,
        op.guid
      )
      break
    case 'add-pledge-file':
      operation = new AddPledgeFileOperation(
        op.auditId,
        {
          ...(op.data as AddPledgeFileOperationData).value,

          file: arrayBufferToFile(
            (op as CachedAddPledgeFileOperationData).data.value.fileBuffer,
            (op as CachedAddPledgeFileOperationData).data.value.fileName,
            (op as CachedAddPledgeFileOperationData).data.value.mimeType
          )
        },
        op.guid
      )
      break
    case 'delete-pledge-file':
      operation = new DeletePledgeFileOperation(op.auditId, data.value, op.guid)
      break

    // NO DEFAULT, so that TypeScript reminds us to update this function!
  }

  return operation
}

function ensureDate<T extends Date | null>(d: T | string): T {
  if (typeof d === 'string') return new Date(d) as T
  return d
}

function updateDates(op: UpdateDeviationOperation): UpdateDeviationOperation {
  op.data.value.approvalDate = ensureDate(op.data.value.approvalDate)
  op.data.value.expirationDate = ensureDate(op.data.value.expirationDate)

  if (op.data.previousValue) {
    op.data.previousValue.approvalDate = ensureDate(
      op.data.previousValue.approvalDate
    )
    op.data.previousValue.expirationDate = ensureDate(
      op.data.previousValue.expirationDate
    )
  }

  return op
}
