import { StyledSpinner } from './spinner.styles'
import type { SpinnerProps } from './spinner.types'

export function Spinner({
  size = 'lg',
  variant = 'standard',
  block = true
}: SpinnerProps) {
  return (
    <StyledSpinner block={block}>
      <sdds-spinner size={size} variant={variant}></sdds-spinner>
    </StyledSpinner>
  )
}
