import { getEnvVarsQPR, setupEnvironment } from '@cdab/scania/qpr/env-vars'
import {
  addTheme,
  defineCustomElements as defineCustomElementsSdds
} from '@scania/components'
import { theme as scania } from '@scania/theme-light'
import * as Sentry from '@sentry/react'
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './app'

import {
  getProviderFromEnvironment,
  initializeClient
} from '@cdab/scania/qpr/contexts/backend-provider'
import { defineCustomElements } from '@scania/tegel-react'

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router'
import './index.css' // TODO: Do we really neeed this?
import './register-webcomponents'

defineCustomElements()
defineCustomElementsSdds()

setupEnvironment('QPR')
defineCustomElements()
addTheme(scania)

const {
  NODE_ENV,
  SentryDsn,
  SentryEnabled,
  SentryEnvironment,
  SentryReplaysOnErrorSampleRate,
  SentryReplaysSessionSampleRate
} = getEnvVarsQPR()

if (NODE_ENV === 'production' && SentryEnabled) {
  Sentry.init({
    dsn: SentryDsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    environment: SentryEnvironment,
    // Sets sample rate to to be 100% while in development and sample at a lower rate in production
    replaysSessionSampleRate:
      NODE_ENV === 'production' ? SentryReplaysSessionSampleRate : 1, // While you're testing, set replaysSessionSampleRate to 1.0, as that ensures that every user session will be sent to Sentry.
    // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
    replaysOnErrorSampleRate: SentryReplaysOnErrorSampleRate // replaysSessionSampleRate is looked at first, so no need to check environment (https://docs.sentry.io/platforms/javascript/guides/react/session-replay/#sampling)
  })
}

const backendProvider = getProviderFromEnvironment()
initializeClient(backendProvider)

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
  <StrictMode>
    <sdds-theme />
    <App />
  </StrictMode>
)
