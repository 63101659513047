import {
  DeviationChat,
  DeviationDetails,
  ElasticContentContainer
} from '@cdab/scania/qpr/components'
import {
  useCssVariableBreakpoint,
  useGetDeviationChatMessages
} from '@cdab/scania/qpr/hooks'
import { deviationsController } from '@cdab/scania/qpr/offline/controllers'
import type {
  AuditPoint,
  Deviation,
  DeviationValidationSchemaOut,
  FileData
} from '@cdab/scania/qpr/schema'
import {
  Column,
  Container,
  EmptyScreen,
  IconWarning,
  InlineTabs,
  Spinner,
  Toast
} from '@cdab/scania/sdds'
import { capitalizeFirstLetter, useDoOnce } from '@cdab/utils'
import { TdsButton } from '@scania/tegel-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useLocation,
  useNavigate,
  useParams,
  useRouteLoaderData
} from 'react-router'
import { toast } from 'react-toastify'
import invariant from 'tiny-invariant'
import type { AuditLoaderData } from '../../../auditId'

import { useDeviation } from '@cdab/scania/qpr/contexts/deviation'
import { observer } from 'mobx-react-lite'
import type { FieldValues } from 'react-hook-form'
import {
  ActionRow,
  ActionRowMobile,
  ActionRowTitle,
  EmptyScreenWrapper,
  Left,
  Right,
  StyledInlineTabs,
  StyledRow
} from './edit-deviation.styles'

type Params = {
  auditId: string
  auditPointId: string
  deviationId: string
}

interface AuditDeviaionPageEditProps {
  oneColumnDetails: boolean
}

export const AuditDeviaionPageEdit = observer(
  ({ oneColumnDetails }: AuditDeviaionPageEditProps): JSX.Element => {
    const { t } = useTranslation()
    const isXxlg = useCssVariableBreakpoint('--sdds-grid-xxlg')
    const { audit } = useRouteLoaderData('audit') as AuditLoaderData
    const { deviations, isReadonly } = audit
    const params = useParams<Params>()
    const deviationId = params.deviationId
    const navigate = useNavigate()
    const { pathname, state } = useLocation()
    const [isEditing, setIsEditing] = useState(false)
    const ctx = useDeviation()

    const [uploadedDealerFilesState, setUploadedDealerFilesState] = useState<
      FileData[]
    >([])

    const deviation = deviations.find(
      deviation => deviation.clientGuid === deviationId
    )
    invariant(deviation, 'Deviation not found')

    const { auditPointId: sAuditPointId } = params
    const auditPointId = Number.parseInt(sAuditPointId ?? 'NaN')
    let auditPoint: AuditPoint | undefined = undefined
    if (!Number.isNaN(auditPointId)) {
      auditPoint = audit.auditPoints.find(ap => ap.id === auditPointId)
      invariant(
        auditPoint,
        `Could not find audit point with id ${auditPointId}`
      )
    }

    const [getDeviationChatMessagesState, getDeviationChatMessages] =
      useGetDeviationChatMessages()

    useDoOnce(() => {
      if (!deviation.id) return
      getDeviationChatMessages(deviation.id)
      ctx.updateDeviation(deviation, auditPoint)
    })

    const updateDeviation = (
      data: DeviationValidationSchemaOut
    ): Promise<void> => {
      const common: Partial<Deviation> = {
        ...deviation,
        auditPointId: data.auditPointId,
        auditPointNumber: data.auditPointNumber,
        deviation: data.deviation
      }

      const updatedDeviation: Deviation = data.withActionPlan
        ? {
            ...(common as Required<typeof common>),
            deviationWithoutActions: false,
            approvalDate: data.completed ?? null,
            expirationDate: data.due,
            proposedActions: data.action,
            responsible: data.assignee
          }
        : {
            ...(common as Required<typeof common>),
            deviationWithoutActions: true,
            approvalDate: null,
            expirationDate: null,
            proposedActions: null,
            responsible: null
          }
      deviationsController.UpdateDeviation(updatedDeviation)

      if (data.files) {
        deviationsController.AddFiles(
          audit.id,
          deviation.clientGuid,
          data.files.map(({ file }) => file)
        )
      }

      data.filesToDelete?.forEach(fileId => {
        deviationsController.DeleteFile(audit.id, deviation.clientGuid, fileId)
      })

      return Promise.resolve()
    }

    async function saveDeviation(
      deviation: DeviationValidationSchemaOut | FieldValues
    ) {
      try {
        await updateDeviation(deviation as DeviationValidationSchemaOut)

        toast(
          <Toast
            type='success'
            headline={capitalizeFirstLetter(t('deviation-saved'))}
            subheadline={capitalizeFirstLetter(t('deviation-updated'))}
          />
        )
        setIsEditing(false)
      } catch (error) {
        toast(
          <Toast
            type='error'
            headline={capitalizeFirstLetter(t('deviation-not-saved'))}
            subheadline={capitalizeFirstLetter(t('deviation-not-saved-error'))}
          />
        )
      }
    }

    const onDelete = () => {
      if (window.confirm('Are you sure you want to delete this deviation?')) {
        deviationsController.DeleteDeviation(audit.id, deviation.clientGuid)

        goToPrevoiusPage()
        toast(
          <Toast
            type='success'
            headline={capitalizeFirstLetter(t('deviation-deleted'))}
            subheadline={capitalizeFirstLetter(t('deviation-deleted-success'))}
          />
        )
      }
    }

    const onUploadFile = (file: FileData) => {
      setUploadedDealerFilesState([...uploadedDealerFilesState, file])
    }

    useEffect(() => {
      deviationsController.GetFiles(audit.id, deviation.clientGuid)
    }, [audit.id, deviation?.clientGuid])

    //TODO: should we show only files from dealer messages?
    const dealerFiles =
      getDeviationChatMessagesState.status === 'success'
        ? getDeviationChatMessagesState.chatFiles.concat(
            uploadedDealerFilesState
          )
        : []

    const goToPrevoiusPage = () => {
      const lastIndexPath = pathname.lastIndexOf('/')
      let previousPath: string | undefined = undefined
      if (lastIndexPath !== -1) {
        previousPath = pathname.substring(0, lastIndexPath)
      }

      if (state && 'previousPath' in state) {
        navigate(state.previousPath)
      } else if (previousPath) {
        navigate(previousPath)
      }
    }

    function ActionButtons() {
      return (
        <ActionRow>
          <Left>
            {!isReadonly && isEditing ? (
              <TdsButton
                variant='danger'
                onClick={onDelete}
                text={t('delete')}
                size='sm'
              />
            ) : (
              <TdsButton
                onClick={goToPrevoiusPage}
                size='sm'
                text={capitalizeFirstLetter(t('back'))}
                variant='secondary'
              />
            )}
          </Left>

          <ActionRowTitle>
            {isEditing ? 'Editing ' : t('deviation-details', { ns: 'audit' })}
          </ActionRowTitle>

          <Right>
            {!isReadonly && isEditing && (
              <>
                <TdsButton
                  variant='secondary'
                  text={capitalizeFirstLetter(t('cancel'))}
                  size='sm'
                  onClick={() => setIsEditing(false)}
                  className='sdds-u-mr2'
                />
                <TdsButton
                  size='sm'
                  onClick={() => {
                    ctx?.form?.handleSubmit(saveDeviation)()
                  }}
                  text={capitalizeFirstLetter(t('save'))}
                />
              </>
            )}

            {!isReadonly && !isEditing && (
              <TdsButton
                size='sm'
                text={capitalizeFirstLetter(t('edit'))}
                onClick={() => setIsEditing(true)}
              />
            )}
          </Right>
        </ActionRow>
      )
    }

    function ActionButtonsMobile() {
      return (
        <ActionRowMobile>
          <Left>
            {!isReadonly && isEditing ? (
              <TdsButton
                variant='danger'
                onClick={onDelete}
                text={t('delete')}
                size='sm'
              />
            ) : (
              <TdsButton
                onClick={goToPrevoiusPage}
                size='sm'
                text={capitalizeFirstLetter(t('back'))}
                variant='secondary'
              />
            )}
          </Left>

          <ActionRowTitle />

          <Right>
            {!isReadonly && isEditing && (
              <>
                <TdsButton
                  variant='secondary'
                  text={capitalizeFirstLetter(t('cancel'))}
                  size='sm'
                  onClick={() => setIsEditing(false)}
                  className='sdds-u-mr2'
                />
                <TdsButton
                  size='sm'
                  onClick={() => {
                    ctx?.form?.handleSubmit(saveDeviation)()
                  }}
                  text={capitalizeFirstLetter(t('save'))}
                />
              </>
            )}

            {!isReadonly && !isEditing && (
              <TdsButton
                size='sm'
                text={capitalizeFirstLetter(t('edit'))}
                onClick={() => setIsEditing(true)}
              />
            )}
          </Right>
        </ActionRowMobile>
      )
    }

    if (isXxlg) {
      return (
        <Container
          fluid='normal'
          paddingOnContainer={false}
          fullHeight
          scrollY={false}
        >
          <StyledRow>
            <Column
              width={6}
              style={{ borderRight: '1px solid var(--tds-divider-background)' }}
              padding={false}
            >
              <ElasticContentContainer
                scrollY
                overflowHidden
                header={<ActionButtons />}
              >
                <div style={{ padding: '4rem' }}>
                  <DeviationDetails
                    editMode={isEditing}
                    deviation={deviation}
                    isReadOnly={isReadonly}
                    auditId={audit.id}
                    onDelete={onDelete}
                    chatFiles={dealerFiles}
                    defaultAuditPoint={auditPoint}
                    oneColumn={oneColumnDetails}
                  />
                </div>
              </ElasticContentContainer>
            </Column>
            <Column width={6} padding={false}>
              {getDeviationChatMessagesState.status === 'success' ? (
                <DeviationChat
                  messages={getDeviationChatMessagesState.chatMessages}
                  actionPlanItemId={deviation.id}
                  files={getDeviationChatMessagesState.chatFiles}
                  onUploadFile={onUploadFile}
                  isReadOnly={isReadonly}
                />
              ) : getDeviationChatMessagesState.status === 'loading' ? (
                <Spinner />
              ) : (
                getDeviationChatMessagesState.status === 'error' && (
                  <EmptyScreenWrapper>
                    <EmptyScreen
                      title={t('something-went-wrong', { ns: 'common' })}
                      description={t('chat-is-not-loaded', { ns: 'common' })}
                      icon={<IconWarning />}
                    />
                  </EmptyScreenWrapper>
                )
              )}
            </Column>
          </StyledRow>
        </Container>
      )
    }

    return (
      <Container paddingOnContainer={false} fullHeight scrollY={false}>
        <StyledRow>
          <Column width={12} padding={false}>
            <StyledInlineTabs modeVariant='primary'>
              <InlineTabs.Content
                name={capitalizeFirstLetter(t('deviation', { ns: 'common' }))}
              >
                <ElasticContentContainer
                  scrollY
                  overflowHidden
                  header={<ActionButtonsMobile />}
                >
                  <div style={{ padding: '4rem' }}>
                    <DeviationDetails
                      editMode={isEditing}
                      deviation={deviation}
                      isReadOnly={isReadonly}
                      auditId={audit.id}
                      onDelete={onDelete}
                      chatFiles={dealerFiles}
                      defaultAuditPoint={auditPoint}
                      oneColumn={oneColumnDetails}
                    />
                  </div>
                </ElasticContentContainer>
              </InlineTabs.Content>
              <InlineTabs.Content
                name={capitalizeFirstLetter(t('chat', { ns: 'common' }))}
              >
                {getDeviationChatMessagesState.status === 'success' ? (
                  <DeviationChat
                    messages={getDeviationChatMessagesState.chatMessages}
                    actionPlanItemId={deviation.id}
                    files={getDeviationChatMessagesState.chatFiles}
                    onUploadFile={onUploadFile}
                    isReadOnly={isReadonly}
                  />
                ) : getDeviationChatMessagesState.status === 'loading' ? (
                  <Spinner />
                ) : (
                  getDeviationChatMessagesState.status === 'error' && (
                    <EmptyScreenWrapper>
                      <EmptyScreen
                        title={t('something-went-wrong', { ns: 'common' })}
                        description={t('chat-is-not-loaded', { ns: 'common' })}
                        icon={<IconWarning />}
                      />
                    </EmptyScreenWrapper>
                  )
                )}
              </InlineTabs.Content>
            </StyledInlineTabs>
          </Column>
        </StyledRow>
      </Container>
    )
  }
)
