import type { ChangeEvent } from 'react'
import { useCallback } from 'react'

import { FormModal } from '@cdab/scania/qpr/components/molecules'
import { AuditTypes } from '@cdab/scania/qpr/schema'
import type { TOption } from '@cdab/scania/sdds'
import {
  Button,
  DateTime,
  Dropdown,
  DropdownOption,
  Message,
  Textfield
} from '@cdab/scania/sdds'
import {
  capitalizeFirstLetter,
  enumToTranslationText,
  formatError,
  formatISODate,
  useForm
} from '@cdab/utils'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import type { AuditModalProps } from './audit-modal.types'
import { auditFormParser } from './audit-modal.validation'

export const AuditModal = observer(function AuditModal(props: AuditModalProps) {
  const {
    open,
    onClose,
    initialDate,
    initialDescription,
    initialExtraAuditor,
    initialAuditTypeId,
    onSubmit,
    disableSave,
    header,
    onHideTransitionEnded,
    onShowTransitionStarted,
    errorMessage
  } = props
  const { t } = useTranslation()
  const { state, validate, setValue, values, reset } = useForm(() => ({
    parser: auditFormParser,
    initialValues: {
      date: formatISODate(new Date(initialDate?.getTime() ?? Date.now())).split(
        'T'
      )[0],
      description: initialDescription,
      extraAuditor: initialExtraAuditor,
      auditTypeId: initialAuditTypeId
    }
  }))

  const handleFormSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()

      try {
        const data = await validate()

        const res = await onSubmit(data)
        if (res) {
          reset()
          onClose()
        }
      } catch (e) {
        // Do nothing. Something failed
      }
    },
    [errorMessage, onClose, onSubmit, reset, validate]
  )

  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleFormSubmit}
      header={header}
      onShowTransitionStarted={onShowTransitionStarted}
      onHideTransitionEnded={onHideTransitionEnded}
      footer={
        <>
          {errorMessage && (
            <Message
              className='sdds-u-mt1'
              variant='single-line'
              singleLineMessage={errorMessage}
              type={'error'}
            />
          )}

          <Button
            disabled={disableSave}
            text={capitalizeFirstLetter(t('save'))}
            onClick={handleFormSubmit}
          />
          <Button
            onClick={() => onClose()}
            type='secondary'
            text={t('cancel')}
          />
        </>
      }
    >
      <div className='sdds-u-mt3'>
        <Dropdown
          id='auditTypeId'
          label={capitalizeFirstLetter(t('audit-types'))}
          size='lg'
          labelPosition='outside'
          defaultOption={initialAuditTypeId?.toString()}
          onSelect={(option: TOption) => {
            setValue('auditTypeId', parseInt(option.value))
          }}
        >
          {(Object.keys(AuditTypes) as Array<keyof typeof AuditTypes>).map(
            (key, index) => {
              if (parseInt(key)) {
                return (
                  <DropdownOption
                    key={index}
                    value={key.toString()}
                    text={t(enumToTranslationText(AuditTypes[key].toString()), {
                      ns: 'audit'
                    })}
                  />
                )
              }
              return null
            }
          )}
        </Dropdown>
      </div>

      <div className='sdds-u-mt3'>
        <DateTime
          label={t('audit-date')}
          type='date'
          value={values.date || ''}
          onInput={(e: ChangeEvent<HTMLInputElement>) =>
            setValue('date', e.target.value)
          }
          state={state.errors?.date && 'error'}
          helper={formatError(state.errors?.date)}
        />
      </div>
      <div className='sdds-u-mt3'>
        <Textfield
          labelPosition='outside'
          label={t('description')}
          placeholder={t('description')}
          value={values.description || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setValue('description', e.target.value)
          }
          state={state.errors?.description && 'error'}
          helper={formatError(state.errors?.description)}
        />
      </div>
      <div className='sdds-u-mt3'>
        <Textfield
          labelPosition='outside'
          label={t('extra-auditor')}
          placeholder={t('extra-auditor')}
          value={values.extraAuditor || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setValue('extraAuditor', e.target.value)
          }
          state={state.errors?.extraAuditor && 'error'}
          helper={formatError(state.errors?.extraAuditor)}
        />
      </div>
    </FormModal>
  )
})
