import {
  ADHLinkCreateForm,
  ADHLinkDetailsForm,
  AuditPageTabs,
  ElasticContentContainer
} from '@cdab/scania/qpr/components'
import { useDeviationModal } from '@cdab/scania/qpr/components/organisms'
import {
  useCreateADHLink,
  useCssVariableBreakpoint,
  useDeleteADHLink,
  useIsDos5,
  useSddsTableRowClickWithCellValue
} from '@cdab/scania/qpr/hooks'
import type { ADHLink, Deviation } from '@cdab/scania/qpr/schema'
import {
  Column,
  Container,
  DataTable,
  EmptyScreen,
  HeaderCell,
  IconMessageInactive,
  Link,
  Row,
  Toast
} from '@cdab/scania/sdds'
import { formatISODate } from '@cdab/utils'
import { TdsBlock, TdsButton } from '@scania/tegel-react'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Outlet,
  useLocation,
  useNavigate,
  useRouteLoaderData
} from 'react-router-dom'
import { toast } from 'react-toastify'
import invariant from 'tiny-invariant'
import { v4 as uuidv4 } from 'uuid'
import type { AuditLoaderData } from '../../auditId'

function prepareTableData(data: readonly Deviation[]) {
  const preparedData = data
    .map(deviation => {
      if (deviation.deviationWithoutActions) {
        return {
          auditPointNumber: deviation.auditPointNumber,
          deviation: deviation.deviation,
          proposedActions: '(no action plan)',
          responsible: '',
          expirationDate: '',
          approvalDate: '',
          guid: deviation.clientGuid
        }
      }

      invariant(
        deviation.expirationDate,
        'A deviation with action plan needs an expiration date'
      )

      return {
        auditPointNumber: deviation.auditPointNumber,
        deviation: deviation.deviation,
        proposedActions: deviation.proposedActions,
        responsible: deviation.responsible,
        expirationDate: formatISODate(deviation.expirationDate),
        approvalDate: deviation.approvalDate
          ? formatISODate(deviation.approvalDate)
          : '',
        guid: deviation.clientGuid
      }
    })
    .sort((a, b) => {
      const aPoints: string[] = a.auditPointNumber.split('.')
      const bPoints: string[] = b.auditPointNumber.split('.')

      // We should always have two points: Major.minor
      if (aPoints.length !== 2) return 1
      if (bPoints.length !== 2) return -1

      const aMajor = Number.parseInt(aPoints[0])
      const aMinor = Number.parseInt(aPoints[1])
      const bMajor = Number.parseInt(bPoints[0])
      const bMinor = Number.parseInt(bPoints[1])

      if (aMajor === bMajor) {
        // Same Major, use minor
        if (aMinor === bMinor) return 0
        return aMinor > bMinor ? 1 : -1
      } else {
        // Different Major, use it
        return aMajor > bMajor ? 1 : -1
      }
    })

  return preparedData
}

export const DeviationsPage = observer((): JSX.Element => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')

  const { audit, adhLinks } = useRouteLoaderData('audit') as AuditLoaderData
  const { deviations, id: auditId, isReadonly } = audit

  const { pathname } = useLocation()
  const [tableEl, setTableEl] = useState<HTMLTableRowElement>()
  const isDos5 = useIsDos5(audit)
  const { show: showDeviationModal } = useDeviationModal()

  const [adhLinksState, setAdhLinksState] = useState<ADHLink[]>(adhLinks)

  const [adhLinkFormEditDataState, setAdhLinkFormDataState] = useState<
    ADHLink | undefined
  >(undefined)

  const [createAdhLinkState, handleClickCreateLink, setCreateAdhLinkState] =
    useCreateADHLink()
  const [deleteAdhLinkState, handleClickDeleteLink, setDeleteAdhLinkState] =
    useDeleteADHLink()

  const onLinkClick = (adhLink: ADHLink) => {
    ;(
      document.querySelector(
        `[selector="#edit-link-button"]`
      ) as HTMLTdsModalElement
    ).showModal()
    setAdhLinkFormDataState(adhLink)
  }

  useSddsTableRowClickWithCellValue(tableEl, 'guid', deviationGuid => {
    const deviation = deviations.find(
      ({ clientGuid }) => clientGuid === deviationGuid
    )

    invariant(deviation, `Could not find deviation with guid ${deviationGuid}`)

    navigate(`/audit/${auditId}/deviations/${deviation.clientGuid}`)
  })

  const handleClickCreateDeviation = useCallback(() => {
    navigate(`/audit/${auditId}/deviations/new`)
  }, [auditId, navigate])

  useEffect(() => {
    if (createAdhLinkState.status === 'success') {
      toast(
        <Toast
          type='success'
          headline={t('link-created')}
          subheadline={createAdhLinkState.message}
        />
      )
      const newAdhLinks = [...adhLinksState]
      newAdhLinks.push(createAdhLinkState.linkData)
      setAdhLinksState(newAdhLinks)
      setCreateAdhLinkState({ status: 'none', message: null })
    }
    if (deleteAdhLinkState.status === 'success') {
      toast(
        <Toast
          type='success'
          headline={t('link-deleted')}
          subheadline={deleteAdhLinkState.message}
        />
      )
      const newAdhLinks = adhLinksState.filter(
        ak => ak.id !== deleteAdhLinkState.linkId
      )

      setAdhLinksState(newAdhLinks)
      setDeleteAdhLinkState({ status: 'none', message: null })
    }
  }, [
    adhLinks,
    adhLinksState,
    createAdhLinkState,
    deleteAdhLinkState,
    setCreateAdhLinkState,
    setDeleteAdhLinkState,
    t
  ])

  if (pathname === `/audit/${auditId}/deviations/new`) {
    return (
      <ElasticContentContainer
        scrollY
        header={
          !isLg && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flexGrow: 1 }}>
                <AuditPageTabs
                  isDos5={isDos5}
                  links={{
                    points: '../points',
                    summary: '../summary',
                    deviations: '../deviations'
                  }}
                  numberOfDeviations={audit.deviations.length}
                />
              </div>
            </div>
          )
        }
      >
        <Outlet />
      </ElasticContentContainer>
    )
  }

  if (!deviations.length) {
    return (
      <Container
        fluid='normal'
        fullHeight
        paddingOnColumns={isLg}
        paddingOnContainer={isLg}
      >
        <EmptyScreen.CenteredWrapper>
          <EmptyScreen
            icon={<IconMessageInactive />}
            title={t('no-deviations-found')}
            description={t('no-deviations-found-description')}
            callToAction={
              !audit.isReadonly
                ? {
                    type: 'button',
                    component: (
                      <TdsButton
                        className='tds-u-mb2 tds-u-mt2'
                        size='sm'
                        onClick={() => {
                          !isReadonly && handleClickCreateDeviation()
                        }}
                        disabled={isReadonly}
                        text={t('create-deviation', { ns: 'audit' })}
                      />
                    )
                  }
                : undefined
            }
          />
        </EmptyScreen.CenteredWrapper>
      </Container>
    )
  }

  return (
    <ElasticContentContainer
      scrollY
      header={
        !isLg && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flexGrow: 1 }}>
              <AuditPageTabs
                isDos5={isDos5}
                links={{
                  points: '../points',
                  summary: '../summary',
                  deviations: '../deviations'
                }}
                numberOfDeviations={audit.deviations.length}
              />
            </div>
          </div>
        )
      }
    >
      {pathname === `/audit/${auditId}/deviations` ? (
        <Container>
          <Row className='tds-u-pb2 tds-u-pt2'>
            {!audit.isReadonly && (
              <Column width={12} padding={isLg}>
                <div
                  style={{ display: 'flex', gap: '2rem' }}
                  className='tds-u-mb2'
                >
                  <TdsButton
                    size='sm'
                    onClick={() => !isReadonly && handleClickCreateDeviation()}
                    disabled={isReadonly}
                    text={t('create-deviation', { ns: 'audit' })}
                  />

                  {deviations.some(
                    deviation => !deviation.deviationWithoutActions
                  ) && (
                    <TdsButton
                      id='create-link-button'
                      size='sm'
                      text={t('create-link', { ns: 'audit' })}
                      modeVariant='secondary'
                      variant='secondary'
                    />
                  )}
                </div>
              </Column>
            )}
            {adhLinksState.length > 0 && (
              <Column width={12} padding={isLg} className='tds-u-mb2'>
                <TdsBlock modeVariant='secondary'>
                  <h6 className='tds-headline-06 tds-u-m0'>
                    {t('available-links', { ns: 'audit' })}
                  </h6>
                  <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
                    {adhLinksState.map(adhLink => (
                      <li key={adhLink.id}>
                        <Link
                          key={adhLink.id}
                          noUnderline={false}
                          onClick={() => onLinkClick(adhLink)}
                        >
                          {adhLink.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </TdsBlock>
              </Column>
            )}
            <Column width={12} padding={isLg} scrollX>
              <DataTable
                title='Deviations on Audit'
                data={prepareTableData(deviations)}
                ref={setTableEl}
                scroll
              >
                <HeaderCell
                  columnKey='auditPointNumber'
                  title={t('audit-point')}
                />
                <HeaderCell
                  columnKey='deviationWithoutActions'
                  title={t('deviation-without-action-plan', { ns: 'audit' })}
                  hidden
                />
                <HeaderCell columnKey='deviation' title='Deviation' />
                <HeaderCell
                  columnKey='proposedActions'
                  title={t('proposed-actions', { ns: 'audit' })}
                />
                <HeaderCell
                  columnKey='responsible'
                  title={t('responsible', { ns: 'audit' })}
                />
                <HeaderCell
                  columnKey='expirationDate'
                  title={t('expiry-date')}
                />
                <HeaderCell
                  columnKey='approvalDate'
                  title={t('approval-date')}
                />
                <HeaderCell columnKey='guid' title='TODO: Hide this column' />
              </DataTable>
            </Column>
          </Row>

          <ADHLinkDetailsForm
            selector='#edit-link-button'
            audit={audit}
            linkData={adhLinkFormEditDataState}
            onSubmit={linkId => {
              handleClickDeleteLink(linkId)
            }}
          />

          <ADHLinkCreateForm
            selector='#create-link-button'
            audit={audit}
            onSubmit={data => {
              handleClickCreateLink({ ...data, key: uuidv4().toString() })
            }}
            links={adhLinksState}
          />
        </Container>
      ) : (
        <Outlet />
      )}
    </ElasticContentContainer>
  )
})
