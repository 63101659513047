import { useEffect, useMemo, useState, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { FormModal } from '@cdab/scania/qpr/components/molecules'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import type { Dealer } from '@cdab/scania/qpr/schema'
import { Button, DateTime, Message, Spinner, Toast } from '@cdab/scania/sdds'
import { capitalizeFirstLetter, formatISODate } from '@cdab/utils'
import { toast } from 'react-toastify'

function formatDate(date: Date | null): string | undefined {
  if (date === null) return undefined

  return formatISODate(date).split('T')[0]
}

export function checkValidDate(
  dateString: string | undefined,
  dealerExpiryDate: Date | null,
  expiryDateOld: Date | null | undefined
): boolean {
  let limitDate = undefined

  if (expiryDateOld) {
    limitDate = expiryDateOld
  } else if (dealerExpiryDate) {
    limitDate = dealerExpiryDate
  }

  const date = new Date(dateString || '')
  if (!limitDate || Number.isNaN(date.valueOf())) return true

  date.setMinutes(date.getTimezoneOffset()) // Convert to UTC
  return date <= limitDate
}

type UseDealerModalState =
  | {
      status: 'success'
      expiryDateOld: Date | null
    }
  | {
      status: 'loading'
      expiryDateOld: undefined
    }
  | {
      status: 'error'
      expiryDateOld: undefined
    }

const useExpiryDateOld = (dealerId: number, open: boolean) => {
  const [state, setState] = useState<UseDealerModalState>({
    status: 'loading',
    expiryDateOld: undefined
  })

  useEffect(() => {
    async function getExpiryDateLimit() {
      try {
        const client = getClient()

        const expiryDateOld =
          await client.DealersService.GetExpiryDateLimitForDealer(dealerId)
        setState({
          status: 'success',
          expiryDateOld
        })
      } catch (error) {
        setState({
          status: 'error',
          expiryDateOld: undefined
        })
        console.warn(error)
      }
    }
    if (open) getExpiryDateLimit()
  }, [dealerId, open])

  return state
}

export type DealerModalProps = {
  open: boolean
  onClose: () => void
  onSubmit: (date: string) => Promise<boolean>
  dealer: Dealer
}

export function DealerModal({
  open,
  onClose,
  onSubmit,
  dealer
}: DealerModalProps) {
  const { t } = useTranslation(['common', 'errors'])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [date, setDate] = useState(formatDate(dealer.expiryDate))
  const [dateError, setError] = useState<null | string>(null)
  const modalState = useExpiryDateOld(dealer.id, open)
  const isValidExpiryDate = useMemo(
    () => checkValidDate(date, dealer.expiryDate, modalState.expiryDateOld),
    [date, dealer.expiryDate, modalState.expiryDateOld]
  )

  const isSaveDisabled =
    !isValidExpiryDate ||
    isSubmitting ||
    !!dateError ||
    modalState.status !== 'success'

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (isSaveDisabled) return

    if (isSubmitting || !dealer.expiryDate) return
    setError(null)

    if (!date) {
      setError(t('invalid-date', { ns: 'errors' }))
      return
    }

    if (!isValidExpiryDate) {
      setError(t('previous-date', { ns: 'errors' }))
      return
    }

    try {
      setIsSubmitting(true)
      const result = await onSubmit(date)

      if (!result) {
        setError(t('previous-date', { ns: 'errors' }))
      } else {
        toast(
          <Toast
            type='success'
            headline={t('expiry-date-updated', { ns: 'common' })}
            subheadline={t('expiry-date-updated-success', { ns: 'common' })}
          />
        )
      }
    } catch (error) {
      // TODO: Log to sentry
      console.warn(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const onInput = async (e: ChangeEvent<HTMLInputElement>) => {
    setError(null)

    if (!e.target.value) {
      setError(t('invalid-date', { ns: 'errors' }))
      return
    }

    const isValidExpiryDate = checkValidDate(
      e.target.value,
      dealer.expiryDate,
      modalState.expiryDateOld
    )

    if (!isValidExpiryDate) {
      setError(t('previous-date', { ns: 'errors' }))
      return
    }

    setDate(e.target.value)
  }

  const handleFormClose = async () => {
    setDate(formatDate(dealer.expiryDate))
    setError(null)
    onClose()
  }

  return (
    <FormModal
      open={open}
      onClose={handleFormClose}
      onSubmit={handleFormSubmit}
      footer={
        <>
          <Button
            disabled={isSaveDisabled}
            text={capitalizeFirstLetter(t('save'))}
            onClick={handleFormSubmit}
          />
          <Button
            disabled={isSubmitting}
            type='secondary'
            onClick={handleFormClose}
            text={capitalizeFirstLetter(t('close'))}
          />
        </>
      }
      header={`${dealer.name}, ${dealer.city}`}
    >
      <div className='sdds-u-mt3'>
        {modalState.status === 'error' && (
          <Message
            variant='single-line'
            singleLineMessage={t('dealer-modal-expiry-date', { ns: 'errors' })}
            type='error'
          />
        )}
        {modalState.status === 'loading' && <Spinner />}
        {modalState.status === 'success' && (
          <DateTime
            helper={dateError || undefined}
            label={t('expiry-date')}
            type='date'
            value={date}
            state={dateError ? 'error' : 'none'}
            onInput={onInput}
          />
        )}
      </div>
    </FormModal>
  )
}
