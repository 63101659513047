import type { PropsWithChildren } from 'react'
import React, { useCallback, useContext, useMemo, useReducer } from 'react'

import type {
  ActionOptions,
  DeviationModalAction,
  DeviationModalState
} from './deviation-modal.types'

import {
  DeviationValidationSchema,
  type Deviation,
  type DeviationValidationSchemaIn,
  type DeviationValidationSchemaOut,
  type FileData,
  type UserFile
} from '@cdab/scania/qpr/schema'
import { createZodParser, formatISODate, useForm } from '@cdab/utils'
import { DeviationDialogWrapper } from './deviation-dialog-wrapper'

export function useDeviationForm(
  deviation?: Partial<Deviation>,
  auditPointId?: string,
  auditPointNumber?: string,
  files?: UserFile[],
  filesToDelete?: FileData['id'][]
) {
  return useForm<
    DeviationValidationSchemaIn,
    DeviationValidationSchemaOut,
    { deviationId?: number }
  >(() => {
    let initialValues: Partial<DeviationValidationSchemaIn> = {
      withActionPlan: true,
      auditPointId: deviation?.auditPointId?.toString() ?? auditPointId,
      auditPointNumber: deviation?.auditPointNumber ?? auditPointNumber,
      files,
      filesToDelete
    }

    if (deviation) {
      const due = deviation.expirationDate
        ? formatISODate(deviation.expirationDate)
        : ''
      const completed = deviation.approvalDate
        ? formatISODate(deviation.approvalDate)
        : ''
      initialValues = {
        ...initialValues,
        withActionPlan: !deviation.deviationWithoutActions,
        deviation: deviation.deviation,
        action: deviation.proposedActions || '',
        assignee: deviation.responsible || '',
        due,
        completed
      }
    }

    return {
      initialValues,
      metadata: { deviationId: deviation?.id },
      parser: createZodParser(DeviationValidationSchema)
    }
  })
}

function reducer(
  state: DeviationModalState,
  action: DeviationModalAction
): DeviationModalState {
  switch (action.type) {
    case 'OPEN':
      return {
        open: true,
        deviation: action.deviation,
        allowEditAuditPoint: !!action.allowEditAuditPoint,
        auditId: action.auditId,
        defaultAuditPoint: action.defaultAuditPoint
      }
    case 'CLOSE':
      return {
        open: false,
        allowEditAuditPoint: state.allowEditAuditPoint,
        auditId: -1
      }
    default:
      return state
  }
}

function initState(): DeviationModalState {
  // Or read state from url/get or something
  return {
    open: false,
    allowEditAuditPoint: false,
    auditId: -1
  }
}
export const deviationModalContext = React.createContext<
  | undefined
  | {
      state: DeviationModalState
      dispatch: React.Dispatch<DeviationModalAction>
    }
>(undefined)

export function DeviationModalProvider({ children }: PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, undefined, initState)

  const value = useMemo(() => ({ state, dispatch }), [state])

  const onClose = useCallback(() => {
    dispatch({ type: 'CLOSE' })
  }, [])
  const onDelete = useCallback(() => {
    dispatch({ type: 'CLOSE' })
  }, [])

  return (
    <deviationModalContext.Provider value={value}>
      {children}
      {state.auditId !== -1 && (
        <DeviationDialogWrapper
          onClose={onClose}
          onDelete={onDelete}
          open={state.open}
          auditId={state.auditId}
          deviation={state.deviation}
          allowEditAuditPoint={state.allowEditAuditPoint || false}
          defaultAuditPoint={state.defaultAuditPoint}
        />
      )}
    </deviationModalContext.Provider>
  )
}

export function useDeviationModal() {
  const context = useContext(deviationModalContext)
  if (!context)
    throw new Error(
      'useDeviationModal must be used within DeviationModalProvider'
    )
  const { dispatch } = context

  const show = useCallback(
    (config: ActionOptions<'OPEN'>) => {
      dispatch({ type: 'OPEN', ...config })
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      ...context,
      show
    }),
    [context, show]
  )
}
