import { type Editor } from '@tiptap/react'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { EditorLinkModal } from './editor-link-modal'
import { OptionButton } from './option-button'

export const RichTextOptions = ({ editor }: { editor: Editor }) => {
  const [openLinkModalState, setOpenLinkModalState] = useState(false)
  const [linkTextState, setLinkTextState] = useState('')
  const currentSelection = editor.view.state.selection

  const linkModalOnSubmit = useCallback(
    (text: string, url: string) => {
      let insertTransaction

      //current selection is empty, we should add text and apply link,
      //otherwise we should replace selected text by new one and apply link
      if (currentSelection.empty) {
        insertTransaction = editor.state.tr.insertText(text)
        editor.view.dispatch(insertTransaction)
        editor
          .chain()
          .setTextSelection({
            from: insertTransaction.selection.from - text.length,
            to: insertTransaction.selection.from
          })
          .run()
        editor.chain().toggleLink({ href: url }).run()
      } else {
        editor
          .chain()
          .focus()
          .insertContentAt(
            {
              from: currentSelection.from,
              to: currentSelection.to
            },
            text
          )
          .run()
        editor
          .chain()
          .setTextSelection({
            from: currentSelection.from,
            to: currentSelection.from + text.length
          })
          .run()
        editor.chain().focus().toggleLink({ href: url }).run()
      }
      editor.commands.focus()
      setOpenLinkModalState(false)
    },
    [currentSelection.empty, currentSelection.from, currentSelection.to, editor]
  )

  function onCloseLinkModal() {
    setOpenLinkModalState(false)
    setLinkTextState('')
    editor.commands.focus()
  }

  if (!editor) {
    return null
  }

  return (
    <div>
      <OptionsContainer>
        <OptionButton
          id='bold'
          onClick={() => editor.chain().focus().toggleBold().run()}
          active={editor.isActive('bold')}
        >
          <b>B</b>
        </OptionButton>
        <OptionButton
          id='italic'
          onClick={() => editor.chain().focus().toggleItalic().run()}
          active={editor.isActive('italic')}
        >
          <em>I</em>
        </OptionButton>
        <OptionButton
          id='underline'
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          active={editor.isActive('underline')}
        >
          <u>U</u>
        </OptionButton>
        <OptionButton
          id='strike'
          onClick={() => editor.chain().focus().toggleStrike().run()}
          active={editor.isActive('strike')}
        >
          <s>S</s>
        </OptionButton>
      </OptionsContainer>
      <OptionsContainer>
        <OptionButton
          active={editor.isActive('heading', { level: 4 })}
          id='heading1'
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
          style={{ fontSize: '3.5rem' }}
        >
          H
        </OptionButton>
        <OptionButton
          active={editor.isActive('heading', { level: 5 })}
          id='heading2'
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }
          style={{ fontSize: '2.5rem' }}
        >
          H
        </OptionButton>
        <OptionButton
          active={editor.isActive('heading', { level: 6 })}
          id='heading3'
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }
          style={{ fontSize: '2rem' }}
        >
          H
        </OptionButton>
      </OptionsContainer>
      <OptionsContainer>
        <OptionButton
          id='unordered'
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          active={editor.isActive('listItem')}
        >
          &bull;
        </OptionButton>
        <OptionButton
          id='ordered'
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          active={editor.isActive('orderedList')}
        >
          1
        </OptionButton>
      </OptionsContainer>
      <OptionsContainer>
        <OptionButton
          id='link'
          onClick={() => {
            if (editor.isActive('link')) {
              editor.chain().focus().unsetLink().run()
            } else {
              const { from, to } = editor.view.state.selection
              setLinkTextState(editor.state.doc.textBetween(from, to, ''))
              setOpenLinkModalState(true)
            }
          }}
          active={editor.isActive('link')}
        >
          L
        </OptionButton>
      </OptionsContainer>
      {openLinkModalState && (
        <EditorLinkModal
          open={!!openLinkModalState}
          text={linkTextState}
          onClose={onCloseLinkModal}
          onSubmit={linkModalOnSubmit}
        />
      )}
    </div>
  )
}

const OptionsContainer = styled.div`
  background-color: var(--sdds-grey-50);
  display: inline-grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  margin-right: 4rem;
  border-radius: 1rem;
`
