import { AuditItemState } from './audit-item.types'

export const expectedNumOfStates = 4

export function getNextAuditItemState(
  current: AuditItemState,
  disableNo?: boolean
): AuditItemState {
  switch (current) {
    case AuditItemState.No:
      return AuditItemState.NotSet

    case AuditItemState.NotSet:
      return AuditItemState.Yes

    case AuditItemState.Yes:
      if (disableNo) {
        return AuditItemState.NotSet
      }

      return AuditItemState.No

    case AuditItemState.NotComplete: // fallthrough
    default:
      return AuditItemState.NotSet
  }
}
