import { v4 as uuidv4 } from 'uuid'
import { action } from 'mobx'
import invariant from 'tiny-invariant'

import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { Audit, Deviation, FileData } from '@cdab/scania/qpr/schema'
import { fileIsImage, fileToArrayBuffer } from '@cdab/utils'

import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import { getAudit } from '../store/audits'
import type { CachedExtraFileData } from './types'
import type { AuditModel, DeviationModel } from '../audit-model'

export const AddDeviationFileOperationType = 'add-deviation-file'
type Type = typeof AddDeviationFileOperationType

type Data = {
  deviationClientGuid: Deviation['clientGuid']
  file: File
  fileGuid: string
}

export type AddDeviationFileOperationData = OperationDataBase<Type, Data>

export type CachedAddDeviationFileOperationData = AddDeviationFileOperation & {
  data: AddDeviationFileOperationData & {
    value: Data & CachedExtraFileData
  }
}

export class AddDeviationFileOperation extends Operation {
  public data: AddDeviationFileOperationData
  private responseFileData: FileData | null = null

  constructor(auditId: Audit['id'], value: Data, guid = uuidv4()) {
    super(auditId, guid)

    this.data = {
      type: 'add-deviation-file',
      value,
      previousValue: undefined
    }
  }

  private getDeviation = (audit: AuditModel): DeviationModel => {
    const { deviationClientGuid } = this.data.value

    const deviation = audit.deviations.find(
      d => d.clientGuid === deviationClientGuid
    )
    invariant(
      deviation,
      `Cannot find deviation (when trying to add file) with guid ${deviationClientGuid}`
    )

    return deviation
  }

  public ApplyTo = action((audit: AuditModel): void => {
    const { file, fileGuid } = this.data.value

    const deviationFileData: FileData = this.responseFileData ?? {
      fileName: file.name,
      id: fileGuid,
      isImage: fileIsImage(file),
      isUploaded: false,
      url: URL.createObjectURL(file),
      uploadProgress: 0
    }

    const deviation = this.getDeviation(audit)
    const hasFile = !!deviation.files.find(f => f.id === deviationFileData.id)
    if (hasFile) {
      return
    }

    deviation.files.push(deviationFileData)
  })

  public GetName = (): string =>
    `${this.data.type}-${this.data.value.deviationClientGuid}-${this.data.value.fileGuid}`

  private getAudit = (): AuditModel => {
    const audit = getAudit(this.auditId)
    invariant(audit, `Cannot add file for audit we cannot find!`)

    return audit
  }

  public SendOperation = async (client: BackEnd): Promise<boolean> => {
    const audit = this.getAudit()
    const deviation = this.getDeviation(audit)

    invariant(deviation.id, `Cannot add file for deviation without id!`)

    const { file, fileGuid } = this.data.value
    const deviationFile = deviation.files.find(f => f.id === fileGuid)

    const response = await client.StorageService.UploadFileForDeviation(
      this.auditId,
      deviation.id,
      file,
      action(progressEvent => {
        if (!deviationFile) return

        if (typeof progressEvent.progress === 'undefined') return

        deviationFile.uploadProgress = progressEvent.progress
      })
    )

    this.responseFileData = response.fileData

    return response.success
  }

  public RollbackOn = action((audit: AuditModel): void => {
    const deviation = this.getDeviation(audit)

    const fileIndex = deviation.files.findIndex(
      f => f.id === this.data.value.fileGuid
    )

    if (fileIndex === -1) return

    const [file] = deviation.files.splice(fileIndex, 1)

    if (file) {
      URL.revokeObjectURL(file.url)
    }
  })

  public override OnConfirm = action(() => {
    if (!this.responseFileData) return

    // When we confirm that we have added this file, we want to
    // clear up the object url so we don't have a memory leak
    const audit = this.getAudit()
    const deviation = this.getDeviation(audit)

    const { fileGuid } = this.data.value
    const file = deviation.files.find(f => f.id === fileGuid)

    invariant(file, `Cannot confirm file we cannot find!`)

    URL.revokeObjectURL(file.url)
    file.url = this.responseFileData.url
    file.id = this.responseFileData.id
    file.isUploaded = true
  })

  public override GetData =
    async (): Promise<CachedAddDeviationFileOperationData> => {
      const { file } = this.data.value
      const fileBuffer = await fileToArrayBuffer(file)

      const cachedData: CachedExtraFileData = {
        fileBuffer,
        fileName: file.name,
        mimeType: file.type
      }

      const json = JSON.parse(JSON.stringify(this))

      return {
        ...json,
        data: {
          ...json.data,
          value: {
            ...json.data.value,
            ...cachedData
          }
        }
      }
    }
}
