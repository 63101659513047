import { ClickableList } from '@cdab/scania/qpr/components'
import { Column, IconDocument, IconPlus, Row } from '@cdab/scania/sdds'
import cx from 'classnames'
import styled from 'styled-components'

export const StyledEndRow = styled(Row)`
  display: flex;
  justify-content: flex-end;
`
export const StyledColumn = styled(Column)`
  @media (max-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`
export const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

export const StyledClickableList = styled(ClickableList).attrs({
  className: cx('sdds-block__ongrey')
})`
  overflow: hidden;
  margin-top: 7rem;
`

export const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledIcon = styled(IconDocument).attrs({
  className: 'sdds-btn-icon'
})`
  &&& {
    color: var(--sdds-black);
    margin-left: var(--sdds-spacing-element-16);
  }
`

export const StyledFirstIcon = styled(IconPlus).attrs({
  className: 'sdds-btn-icon'
})`
  &&& {
    margin-left: var(--sdds-spacing-element-16);
    color: var(--sdds-white);
  }
`

export const StyledFirstIconButton = styled.button.attrs({
  className: 'sdds-btn sdds-btn-primary sdds-btn-md'
})`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--sdds-spacing-element-12);
    margin-bottom: var(--sdds-spacing-element-12);
  }
  svg {
    width: 4rem;
    height: 4rem;
  }
`

export const StyledIconButton = styled.button.attrs({
  className: 'sdds-btn sdds-btn-secondary sdds-btn-md'
})`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--sdds-spacing-element-12);
    margin-bottom: var(--sdds-spacing-element-12);
    color: var(--sdds-black);
    &:hover {
      svg {
        color: var(--sdds-white);
      }
      span {
        color: var(--sdds-white);
      }
    }
  }
  svg {
    width: 4rem;
    height: 4rem;
  }
`

export const Title = styled.h3.attrs({
  className: 'sdds-headline-03'
})``

export const StyledMainRow = styled(Row)`
  display: flex;

  @media (max-width: ${({ theme }) => theme.size.xlg}) {
    flex-direction: column;
  }

  @media (min-width: ${({ theme }) => theme.size.xlg}) {
    flex-direction: row;
  }
`
