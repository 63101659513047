import invariant from 'tiny-invariant'
import type {
  APP_CONFIG_ADH,
  APP_CONFIG_INPUT_ADH,
  APP_CONFIG_INPUT_QPR,
  APP_CONFIG_QPR,
  ConfigSource,
  ENV_APP,
  NODE_ENV
} from './types'

function createConfigFromEnvironmentQPR(nodeEnv: NODE_ENV): APP_CONFIG_QPR {
  // Read from env to set variables
  const devAppConfig: APP_CONFIG_QPR = {
    NODE_ENV: nodeEnv,
    ProviderType: String(process.env['NX_APP_SCANIA_QPR_WEB_PROVIDER_TYPE']),
    ApiUrl: String(process.env['NX_APP_SCANIA_QPR_WEB_API_URL']),
    AuditsRealTimeEndpoint: String(
      process.env['NX_APP_SCANIA_QPR_WEB_AUDITS_REAL_TIME_ENDPOINT']
    ),
    SentryEnabled:
      String(process.env['NX_APP_SCANIA_QPR_WEB_SENTRY_ENABLED']) === 'true',
    SentryEnvironment: String(
      process.env['NX_APP_SCANIA_QPR_WEB_SENTRY_ENVIRONMENT']
    ),
    SentryDsn: String(process.env['NX_APP_SCANIA_QPR_WEB_SENTRY_DSN']),
    SentryReplaysOnErrorSampleRate: Number(
      process.env['NX_APP_SCANIA_QPR_WEB_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE']
    ),
    SentryReplaysSessionSampleRate: Number(
      process.env['NX_APP_SCANIA_QPR_WEB_SENTRY_REPLAYS_SESSION_SAMPLE_RATE']
    ),
    DosVersion: parseInt(
      process.env['NX_APP_SCANIA_QPR_WEB_DOS_VERSION'] || ''
    ),
    FactoryEnglishId: String(
      process.env['NX_APP_SCANIA_QPR_FACTORY_ENGLISH_ID']
    ),
    keycloakClientId: String(
      process.env['NX_APP_SCANIA_QPR_KEYCLOAK_CLIENT_ID']
    ),
    keycloakRealm: String(process.env['NX_APP_SCANIA_QPR_KEYCLOAK_REALM']),
    keycloakUrl: String(process.env['NX_APP_SCANIA_QPR_KEYCLOAK_URL']),
    flagsmithApiKey: String(process.env['NX_APP_SCANIA_QPR_FLAGSMITH_API_KEY']),
    flagsmithApiUrl: String(process.env['NX_APP_SCANIA_QPR_FLAGSMITH_API_URL']),
    qpr2007url: String(process.env['NX_APP_SCANIA_QPR_2007_URL']),
    i18nDebugEnabled:
      String(process.env['NX_APP_SCANIA_QPR_WEB_I18N_DEBUG_ENABLED']) ===
      'true',
    translationTemplateUrl: String(
      process.env['NX_APP_SCANIA_QPR_WEB_TRANSLATION_TEMPLATE_URL']
    ),
    adhUrl: String(process.env['NX_APP_SCANIA_ADH_URL'])
  }

  return devAppConfig
}

function createConfigFromEnvironmentADH(nodeEnv: NODE_ENV): APP_CONFIG_ADH {
  // Read from env to set variables
  const devAppConfig: APP_CONFIG_ADH = {
    NODE_ENV: nodeEnv,
    ProviderType: String(process.env['NX_APP_SCANIA_ADH_PROVIDER_TYPE']),
    ApiUrl: String(process.env['NX_APP_SCANIA_ADH_API_URL']),
    SentryEnabled:
      String(process.env['NX_APP_SCANIA_ADH_SENTRY_ENABLED']) === 'true',
    SentryEnvironment: String(
      process.env['NX_APP_SCANIA_ADH_SENTRY_ENVIRONMENT']
    ),
    SentryDsn: String(process.env['NX_APP_SCANIA_ADH_SENTRY_DSN']),
    SentryReplaysOnErrorSampleRate: Number(
      process.env['NX_APP_SCANIA_ADH_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE']
    ),
    SentryReplaysSessionSampleRate: Number(
      process.env['NX_APP_SCANIA_ADH_SENTRY_REPLAYS_SESSION_SAMPLE_RATE']
    )
  }

  return devAppConfig
}

function createConfigFromWindowAppConfigQPR(
  nodeEnv: NODE_ENV,
  config: APP_CONFIG_INPUT_QPR
): APP_CONFIG_QPR {
  // Since all environment variables are provided as strings, we need to convert them
  const prodAppConfig: APP_CONFIG_QPR = {
    NODE_ENV: nodeEnv,
    ProviderType: String(config.NX_APP_SCANIA_QPR_WEB_PROVIDER_TYPE),
    ApiUrl: String(config.NX_APP_SCANIA_QPR_WEB_API_URL),
    AuditsRealTimeEndpoint: String(
      config.NX_APP_SCANIA_QPR_WEB_AUDITS_REAL_TIME_ENDPOINT
    ),
    SentryDsn: String(config.NX_APP_SCANIA_QPR_WEB_SENTRY_DSN),
    SentryEnabled:
      String(config.NX_APP_SCANIA_QPR_WEB_SENTRY_ENABLED) === 'true',
    SentryEnvironment: String(config.NX_APP_SCANIA_QPR_WEB_SENTRY_ENVIRONMENT),
    SentryReplaysOnErrorSampleRate: Number(
      config.NX_APP_SCANIA_QPR_WEB_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
    ),
    SentryReplaysSessionSampleRate: Number(
      config.NX_APP_SCANIA_QPR_WEB_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
    ),
    DosVersion: parseInt(config.NX_APP_SCANIA_QPR_WEB_DOS_VERSION || ''),
    FactoryEnglishId: String(config.NX_APP_SCANIA_QPR_FACTORY_ENGLISH_ID),
    keycloakClientId: String(config.NX_APP_SCANIA_QPR_KEYCLOAK_CLIENT_ID),
    keycloakRealm: String(config.NX_APP_SCANIA_QPR_KEYCLOAK_REALM),
    keycloakUrl: String(config.NX_APP_SCANIA_QPR_KEYCLOAK_URL),
    flagsmithApiKey: String(config.NX_APP_SCANIA_QPR_FLAGSMITH_API_KEY),
    flagsmithApiUrl: String(config.NX_APP_SCANIA_QPR_FLAGSMITH_API_URL),
    qpr2007url: String(config.NX_APP_SCANIA_QPR_2007_URL),
    i18nDebugEnabled:
      String(config.NX_APP_SCANIA_QPR_WEB_I18N_DEBUG_ENABLED) === 'true',
    translationTemplateUrl: String(
      config.NX_APP_SCANIA_QPR_WEB_TRANSLATION_TEMPLATE_URL
    ),
    adhUrl: String(config.NX_APP_SCANIA_ADH_URL)
  }

  return prodAppConfig
}

function createConfigFromWindowAppConfigADH(
  nodeEnv: NODE_ENV,
  config: APP_CONFIG_INPUT_ADH
): APP_CONFIG_ADH {
  // Since all environment variables are provided as strings, we need to convert them
  const prodAppConfig: APP_CONFIG_ADH = {
    NODE_ENV: nodeEnv,
    ProviderType: String(config.NX_APP_SCANIA_ADH_PROVIDER_TYPE),
    ApiUrl: String(config.NX_APP_SCANIA_ADH_API_URL),
    SentryDsn: String(config.NX_APP_SCANIA_ADH_SENTRY_DSN),
    SentryEnabled: String(config.NX_APP_SCANIA_ADH_SENTRY_ENABLED) === 'true',
    SentryEnvironment: String(config.NX_APP_SCANIA_ADH_SENTRY_ENVIRONMENT),
    SentryReplaysOnErrorSampleRate: Number(
      config.NX_APP_SCANIA_ADH_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
    ),
    SentryReplaysSessionSampleRate: Number(
      config.NX_APP_SCANIA_ADH_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
    )
  }

  return prodAppConfig
}

export function setupEnvironmentQPR(): void {
  const configSource: ConfigSource =
    (process.env['NX_CONFIG_SOURCE']?.trim() as ConfigSource) ||
    'window-app-config'

  let appConfig: APP_CONFIG_QPR
  let windowConfig

  const nodeEnv = process.env['NODE_ENV'] as NODE_ENV | undefined
  invariant(nodeEnv, 'process.env.NODE_ENV was undefined!')

  switch (configSource) {
    case 'environment':
      appConfig = createConfigFromEnvironmentQPR(nodeEnv)
      break

    case 'window-app-config':
      windowConfig = window.APP_CONFIG as unknown as APP_CONFIG_INPUT_QPR

      invariant(
        windowConfig,
        "Asked to create config from window.APP_CONFIG but it wasn't defined!"
      )

      appConfig = createConfigFromWindowAppConfigQPR(
        nodeEnv,
        window.APP_CONFIG as unknown as APP_CONFIG_INPUT_QPR
      )
      break

    default:
      throw new Error('Invalid config source: ' + configSource)
  }
  window.APP_CONFIG = appConfig
}

export function setupEnvironmentADH(): void {
  const configSource: ConfigSource =
    (process.env['NX_CONFIG_SOURCE']?.trim() as ConfigSource) ||
    'window-app-config'

  let appConfig: APP_CONFIG_ADH
  let windowConfig

  const nodeEnv = process.env['NODE_ENV'] as NODE_ENV | undefined
  invariant(nodeEnv, 'process.env.NODE_ENV was undefined!')

  switch (configSource) {
    case 'environment':
      appConfig = createConfigFromEnvironmentADH(nodeEnv)
      break

    case 'window-app-config':
      windowConfig = window.APP_CONFIG as unknown as APP_CONFIG_INPUT_ADH

      invariant(
        windowConfig,
        "Asked to create config from window.APP_CONFIG but it wasn't defined!"
      )

      appConfig = createConfigFromWindowAppConfigADH(
        nodeEnv,
        window.APP_CONFIG as unknown as APP_CONFIG_INPUT_ADH
      )
      break

    default:
      throw new Error('Invalid config source: ' + configSource)
  }
  window.APP_CONFIG = appConfig
}

export function setupEnvironment(app: ENV_APP): void {
  switch (app) {
    case 'QPR':
      setupEnvironmentQPR()
      break
    case 'ADH':
      setupEnvironmentADH()
      break
    default:
      throw new Error('Invalid app: ' + app)
  }
}

export function getEnvVarsQPR(): APP_CONFIG_QPR {
  if (!window.APP_CONFIG || Object.keys(window.APP_CONFIG).length === 0)
    throw new Error(
      'window.APP_CONFIG was not set! Have you called "setupEnvironment"?'
    )

  return window.APP_CONFIG as APP_CONFIG_QPR
}

export function getEnvVarsADH(): APP_CONFIG_ADH {
  if (!window.APP_CONFIG || Object.keys(window.APP_CONFIG).length === 0)
    throw new Error(
      'window.APP_CONFIG was not set! Have you called "setupEnvironment"?'
    )

  return window.APP_CONFIG as APP_CONFIG_ADH
}
