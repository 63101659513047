import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import type { Audit } from '@cdab/scania/qpr/schema'
import {
  addFavoriteAudit,
  getFavoriteAuditModel,
  toFavoriteAuditModel,
  deleteFavoriteAuditModel
} from '@cdab/scania/qpr/offline/models'
import type { FavoriteAuditModel } from '@cdab/scania/qpr/offline/models'
import {
  cacheFavoriteAudit,
  cacheFavoriteAuditChanges,
  deleteCachedFavoriteAuditChanges,
  getCachedFavoriteAudit,
  getCachedFavoriteAuditChanges
} from '@cdab/scania/qpr/offline/cache'
import { runInAction } from 'mobx'
import invariant from 'tiny-invariant'

class FavoriteAuditsController {
  private listenersRetryDoChanges = new Map<Audit['id'], boolean>()

  private loadFavoriteAudit = async (
    auditId: Audit['id'],
    useChangesStore = false
  ): Promise<FavoriteAuditModel> => {
    const client = getClient()
    const user = await client.GetuserInfo()

    const favoriteAuditSingle =
      await client.AuditsService.GetFavoriteAuditForUserAndAudit(
        user ? user.userId : 0,
        auditId
      )
    const favoriteAuditsModel = addFavoriteAudit(
      toFavoriteAuditModel(favoriteAuditSingle),
      useChangesStore
    )

    if (favoriteAuditsModel.id !== null) {
      if (useChangesStore) {
        await cacheFavoriteAuditChanges(favoriteAuditsModel)
      } else {
        await cacheFavoriteAudit(favoriteAuditsModel)
      }
    }

    return favoriteAuditsModel
  }

  private loadCachedFavoriteAudit = async (
    auditId: Audit['id'],
    useChangesStore = false
  ): Promise<FavoriteAuditModel | undefined> => {
    let favoriteAudit: FavoriteAuditModel | undefined

    if (useChangesStore) {
      favoriteAudit = await getCachedFavoriteAuditChanges(auditId)
    } else {
      favoriteAudit = await getCachedFavoriteAudit(auditId)
    }

    if (!favoriteAudit) return undefined

    const FavoriteAuditsModel = addFavoriteAudit(favoriteAudit, useChangesStore)

    return FavoriteAuditsModel
  }

  private getFavoriteAudit = async (
    auditId: Audit['id'],
    useChangesStore = false
  ): Promise<FavoriteAuditModel> => {
    const cachedFavoriteAudit = await this.loadCachedFavoriteAudit(
      auditId,
      useChangesStore
    )

    let favoriteAuditsModel: FavoriteAuditModel
    if (cachedFavoriteAudit) {
      favoriteAuditsModel = addFavoriteAudit(
        cachedFavoriteAudit,
        useChangesStore
      )

      // Even if we have cached a favorite audit, it could be updated so refetch it
      this.loadFavoriteAudit(auditId, useChangesStore)
    } else {
      favoriteAuditsModel = await this.loadFavoriteAudit(
        auditId,
        useChangesStore
      ) // load also handles caching
    }

    return favoriteAuditsModel
  }

  //Try to do changes from store
  private DoChangesFromStore = async (auditId: Audit['id']) => {
    try {
      const client = getClient()

      const favoriteAuditChanges = await getCachedFavoriteAuditChanges(auditId)
      const favoriteAudit = await getCachedFavoriteAudit(auditId)

      if (favoriteAuditChanges) {
        //Try to delete favorite audit
        if (favoriteAuditChanges?.id == null) {
          if (favoriteAudit && favoriteAudit.id !== null) {
            await client.AuditsService.DeleteFavoriteAudit(favoriteAudit.id)
            //Successful deleted, then clear store
            favoriteAudit.id = null
            cacheFavoriteAudit(favoriteAudit)
          }
        } else {
          // Try to add favorite audit
          if (favoriteAudit && favoriteAudit.id === null) {
            const favoriteAuditId =
              await client.AuditsService.CreateFavoriteAudit(auditId)
            //Successful added, then clear store
            if (favoriteAuditId) {
              favoriteAudit.id = favoriteAuditId
              cacheFavoriteAudit(favoriteAudit)
            }
          }
        }
      }
      //Finnaly clear cache changes
      deleteCachedFavoriteAuditChanges(auditId)

      if (this.listenersRetryDoChanges.has(auditId)) {
        this.listenersRetryDoChanges.set(auditId, false)
      }
    } catch {
      console.warn('Error during do changes for favorite audits')
    }
  }

  // Only ever allowed to run once for each audit id!
  private getDataAndCreateModel = async (
    auditId: Audit['id']
  ): Promise<void> => {
    await this.DoChangesFromStore(auditId)

    // Get data from backend, and update the mobx store
    try {
      const client = getClient()
      const user = await client.GetuserInfo()

      const favoriteAuditSingle =
        await client.AuditsService.GetFavoriteAuditForUserAndAudit(
          user ? user.userId : 0,
          auditId
        )
      favoriteAuditSingle.auditId = auditId

      const favoriteAudit = await toFavoriteAuditModel(favoriteAuditSingle)

      await cacheFavoriteAudit(favoriteAudit)

      addFavoriteAudit({
        ...favoriteAudit
      })
    } catch {
      console.warn('Error during getting favorite audit from backend')
    }
  }

  public retryDoChanges = (auditId: Audit['id']) => {
    const RETRY_TIMER_MS = 60 * 1000

    this.listenersRetryDoChanges.set(auditId, true)

    setTimeout(() => {
      this.listenersRetryDoChanges.forEach((running, auditId) => {
        if (running) this.DoChangesFromStore(auditId)
      })
    }, RETRY_TIMER_MS)
  }

  public Get = async (auditId: Audit['id']): Promise<FavoriteAuditModel> => {
    let favoriteAudit: FavoriteAuditModel | undefined = undefined

    this.DoChangesFromStore(auditId)

    favoriteAudit = getFavoriteAuditModel(auditId)
    if (favoriteAudit) {
      this.getDataAndCreateModel(auditId)

      favoriteAudit = addFavoriteAudit({
        ...favoriteAudit
      })
      return favoriteAudit
    }

    favoriteAudit = await getCachedFavoriteAuditChanges(auditId)
    if (favoriteAudit) {
      favoriteAudit = addFavoriteAudit(
        {
          ...favoriteAudit
        },
        true
      )
      return favoriteAudit
    }

    favoriteAudit = await getCachedFavoriteAudit(auditId)
    if (favoriteAudit) {
      this.getDataAndCreateModel(auditId)

      favoriteAudit = addFavoriteAudit({
        ...favoriteAudit
      })
      return favoriteAudit
    }

    favoriteAudit = {
      auditId,
      createdTime: new Date(),
      id: null,
      VERSION: 1
    }
    this.getDataAndCreateModel(auditId)

    favoriteAudit = addFavoriteAudit({
      ...favoriteAudit
    })
    return favoriteAudit
  }

  public Add = async (auditId: Audit['id']) => {
    await this.DoChangesFromStore(auditId)

    let favoriteAudit: FavoriteAuditModel | undefined = undefined

    favoriteAudit = getFavoriteAuditModel(auditId, true)

    if (!favoriteAudit) {
      favoriteAudit = getFavoriteAuditModel(auditId, false)
    }

    //We have in store from backend
    if (favoriteAudit?.id) {
      await addFavoriteAudit(favoriteAudit)
      await addFavoriteAudit(favoriteAudit, true)
      return favoriteAudit
    } else {
      // We don't have in backend store
      favoriteAudit = {
        auditId: auditId,
        id: 1,
        createdTime: new Date(),
        VERSION: 1
      }

      await cacheFavoriteAuditChanges(favoriteAudit) // Add to changes store

      try {
        // Try to add favorite audit in backend
        const client = getClient()
        const favoriteAuditId = await client.AuditsService.CreateFavoriteAudit(
          auditId
        )
        // Added in backend
        if (favoriteAuditId) {
          //Add model and clear cache
          runInAction(() => {
            invariant(favoriteAudit, '')
            favoriteAudit.id = favoriteAuditId
          })

          await addFavoriteAudit(favoriteAudit)
          await addFavoriteAudit(favoriteAudit, true)

          await cacheFavoriteAudit(favoriteAudit)
          await deleteCachedFavoriteAuditChanges(auditId)
        }
      } catch (error) {
        //Feiled, add model with id 1 and cache to changes
        await addFavoriteAudit(favoriteAudit, false)
        await addFavoriteAudit(favoriteAudit, true)
        await cacheFavoriteAuditChanges(favoriteAudit)
        this.retryDoChanges(auditId)
        console.warn('Error in adding favorite audit')
      }
    }

    return favoriteAudit
  }

  public Remove = async (auditId: Audit['id']) => {
    await this.DoChangesFromStore(auditId)

    let favoriteAudit: FavoriteAuditModel | undefined = undefined

    favoriteAudit = getFavoriteAuditModel(auditId, true)

    if (!favoriteAudit) {
      favoriteAudit = getFavoriteAuditModel(auditId, false)
    }

    if (!favoriteAudit?.id) {
      await deleteFavoriteAuditModel(auditId)
      await deleteFavoriteAuditModel(auditId, true)
      return favoriteAudit
    } else {
      try {
        // Try to delete in backend
        const client = getClient()

        await client.AuditsService.DeleteFavoriteAudit(favoriteAudit.id)
        //Successful deleted, then clear stores
        await deleteFavoriteAuditModel(auditId)
        await deleteFavoriteAuditModel(auditId, true)
        //Cache to store and delete from changes store
        await cacheFavoriteAudit(favoriteAudit)
        await deleteCachedFavoriteAuditChanges(auditId)
      } catch (error) {
        //Feiled, update model and updating changes store and return object with null id
        await deleteFavoriteAuditModel(auditId)
        await deleteFavoriteAuditModel(auditId, true)
        await cacheFavoriteAuditChanges(favoriteAudit)
        this.retryDoChanges(auditId)
        console.warn('Error in removing favorite audit')
      }
    }

    return favoriteAudit
  }
}

export const favoriteAuditsController = new FavoriteAuditsController()
