import { parseDate } from '@cdab/utils'
import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export type ExpiringDeviation = {
  auditDate: Date
  auditId: number
  dealerCity: string
  dealerName: string
  expiryDate: Date
  id: number
  hasDeviations: boolean
}

export interface ExpiringDeviationJson {
  auditDate: string
  auditId: number
  dealerCity: string
  dealerName: string
  expiryDate: string
  id: number
  hasDeviations: boolean
}

export const ExpiringDeviationSchema: JSONSchemaType<ExpiringDeviationJson> = {
  type: 'object',

  properties: {
    auditDate: {
      type: 'string',
      format: 'iso-date-time'
    },
    auditId: {
      type: 'integer'
    },
    dealerCity: {
      type: 'string'
    },
    dealerName: {
      type: 'string'
    },
    expiryDate: {
      type: 'string',
      format: 'iso-date-time',
      nullable: false
    },
    id: {
      type: 'integer',
      nullable: false
    },
    hasDeviations: {
      type: 'boolean'
    }
  },

  required: [
    'auditDate',
    'auditId',
    'dealerCity',
    'dealerName',
    'expiryDate',
    'id',
    'hasDeviations'
  ]
}

const validateExpiringDeviation: ValidateFunction<ExpiringDeviationJson> =
  ajv.compile(ExpiringDeviationSchema)

export const isValidExpiringDeviationJson = (
  expiringDeviation: unknown
): expiringDeviation is ExpiringDeviationJson => {
  return isValidThing<ExpiringDeviationJson>(
    expiringDeviation,
    validateExpiringDeviation
  )
}

export function createExpiringDeviationFromJson(
  json: ExpiringDeviationJson
): ExpiringDeviation {
  return {
    auditDate: parseDate(json.auditDate),
    auditId: json.auditId,
    dealerCity: json.dealerCity,
    dealerName: json.dealerName,
    expiryDate: parseDate(json.expiryDate),
    id: json.id,
    hasDeviations: json.hasDeviations
  }
}
