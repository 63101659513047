import type { Configuration } from './generated-swagger-client'
import type { AxiosInstance } from 'axios'

export class ApiBase {
  protected readonly axiosInstance: AxiosInstance
  protected readonly configuration: Configuration

  public constructor(
    configuration: Configuration,
    axiosInstance: AxiosInstance
  ) {
    this.configuration = configuration
    this.axiosInstance = axiosInstance
  }
}
