import type { WithId } from '@cdab/type-utils'
import {
  useMemo,
  createContext,
  useContext,
  useState,
  useCallback
} from 'react'

import type { Template as TemplateBase } from './types'

export type TemplateId = number
interface Template extends WithId<TemplateId>, TemplateBase {
  displayName: string
}

type TemplateContext = {
  selectedTemplateId: TemplateId
  templates: Template[]
  setTemplate: (templateId: TemplateId) => void
}

const templates: Template[] = [
  {
    id: 1,
    displayName: 'Standard QPR',
    pledgeAreas: {
      displayType: 'hidden',
      showOnly: []
    },
    pledges: {
      displayType: 'collapsible',
      showOnly: []
    },
    auditPoints: {
      displayType: 'hidden',
      showOnly: []
    },
    checkPoints: {
      displayType: 'hidden',
      showOnly: []
    }
  },
  {
    id: 2,
    displayName: 'All steps',
    pledgeAreas: {
      displayType: 'step',
      showOnly: []
    },
    pledges: {
      displayType: 'step',
      showOnly: []
    },
    auditPoints: {
      displayType: 'step',
      showOnly: []
    },
    checkPoints: {
      displayType: 'step',
      showOnly: []
    }
  },
  {
    id: 3,
    displayName: 'By Pledge Areas',
    pledgeAreas: {
      displayType: 'step',
      showOnly: []
    },
    pledges: {
      displayType: 'header',
      showOnly: []
    },
    auditPoints: {
      displayType: 'header',
      showOnly: []
    },
    checkPoints: {
      displayType: 'hidden',
      showOnly: []
    }
  },
  {
    id: 4,
    displayName: 'Only Audit Points',
    pledgeAreas: {
      displayType: 'hidden',
      showOnly: []
    },
    pledges: {
      displayType: 'hidden',
      showOnly: []
    },
    auditPoints: {
      displayType: 'header',
      showOnly: []
    },
    checkPoints: {
      displayType: 'hidden',
      showOnly: []
    }
  },
  {
    id: 5,
    displayName: 'Everything visible',
    pledgeAreas: {
      displayType: 'header',
      showOnly: []
    },
    pledges: {
      displayType: 'header',
      showOnly: []
    },
    auditPoints: {
      displayType: 'header',
      showOnly: []
    },
    checkPoints: {
      displayType: 'header',
      showOnly: []
    }
  }
]

const templateContext = createContext<TemplateContext>(
  null as unknown as TemplateContext
)

export interface TemplateProviderProps {
  children: React.ReactNode
}

export function TemplateProvider(props: TemplateProviderProps) {
  const [selectedTemplateId, setSelectedTemplateId] = useState<TemplateId>(
    templates[0].id
  )

  const setTemplate = useCallback((templateId: TemplateId) => {
    setSelectedTemplateId(templateId)
  }, [])

  return (
    <templateContext.Provider
      value={{
        selectedTemplateId,
        setTemplate,
        templates
      }}
    >
      {props.children}
    </templateContext.Provider>
  )
}

export function useTemplate(): Readonly<Template> {
  const { selectedTemplateId, templates } = useContext(templateContext)

  const template = useMemo(() => {
    const template = templates.find(t => t.id === selectedTemplateId)

    if (!template) throw new Error('Invalid template ID')

    Object.freeze(template)

    return template
  }, [selectedTemplateId, templates])

  return template
}

export function useSetTemplate() {
  const { setTemplate } = useContext(templateContext)

  return setTemplate
}

export function useAllAvailableTemplates() {
  const { templates } = useContext(templateContext)

  return templates
}
