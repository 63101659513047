import invariant from 'tiny-invariant'
import { v4 as uuidv4 } from 'uuid'

import type { Deviation, Audit, FileData } from '@cdab/scania/qpr/schema'
import type { DeviationModel } from '@cdab/scania/qpr/offline/models'
import { getLocalDeviation } from '@cdab/scania/qpr/offline/models'
import { deleteDeviationFiles } from '@cdab/scania/qpr/offline/models'
import { DeleteDeviationFileOperation } from '@cdab/scania/qpr/offline/models'
import {
  AddDeviationFileOperation,
  addDeviatoinFiles,
  DeleteDeviationOperation,
  UpdateDeviationOperation
} from '@cdab/scania/qpr/offline/models'
import { CreateDeviationOperation } from '@cdab/scania/qpr/offline/models'
import { getAudit, getDeviation } from '@cdab/scania/qpr/offline/models'

import { operationsController } from './operations-controller'
import { auditsController } from './audits-controller'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { runInAction } from 'mobx'

class DeviationsController {
  public GetDeviation = async (
    auditId: Audit['id'],
    deviationId: Deviation['id']
  ): Promise<DeviationModel | undefined> => {
    if (!getAudit(auditId)) {
      await auditsController.GetAudit(auditId)
    }

    const deviation = getDeviation(auditId, deviationId)

    return deviation
  }

  public DeleteDeviation = async (
    auditId: Audit['id'],
    deviationClientGuid: Deviation['clientGuid']
  ) => {
    const deviation = getLocalDeviation(auditId, deviationClientGuid)

    if (deviation.id === undefined) {
      const createOperation = operationsController.findOperation(op => {
        if (op.Type !== 'create-deviation') return false

        const { clientGuid } = (op as CreateDeviationOperation).data.value
        const isEq = clientGuid === deviationClientGuid

        return isEq
      })

      invariant(createOperation, `Tried to cancel operation we cannot find!`)
      operationsController.cancelOperation(createOperation.guid)
    } else {
      const operation = new DeleteDeviationOperation(auditId, {
        deviationId: deviation.id
      })

      operationsController.handleNewOperation(operation)
    }
  }

  public UpdateDeviation = async (updatedDeviation: Deviation) => {
    const previousDeviation = await this.GetDeviation(
      updatedDeviation.auditId,
      updatedDeviation.id
    )
    invariant(
      previousDeviation,
      'Could not get deviation (does it exist?). Nothing to update.'
    )

    const operation = new UpdateDeviationOperation(
      updatedDeviation.auditId,
      updatedDeviation,
      previousDeviation
    )

    const exists = updatedDeviation.id !== undefined
    operationsController.handleNewOperation(operation, {
      skipSend: !exists
    })
  }

  public CreateDeviation = async (deviation: Deviation) => {
    const operation = new CreateDeviationOperation(deviation.auditId, deviation)

    operationsController.handleNewOperation(operation)
  }

  private isGettingFilesForDeviation = new Map<
    Deviation['clientGuid'],
    Promise<void>
  >()
  public GetFiles = async (
    auditId: Audit['id'],
    deviationGuid: Deviation['clientGuid']
  ): Promise<void> => {
    let isGettingFiles = this.isGettingFilesForDeviation.get(deviationGuid)
    if (isGettingFiles) return isGettingFiles

    const getFiles = async (
      auditId: Audit['id'],
      deviationGuid: Deviation['clientGuid']
    ): Promise<void> => {
      const { audit } = await auditsController.GetAudit(auditId, {
        dontListen: true
      })

      const deviation = audit.deviations.find(
        d => d.clientGuid === deviationGuid
      )
      if (!deviation || deviation.id === undefined) return

      try {
        const client = getClient()
        runInAction(() => {
          invariant(deviation)
          deviation.isGettingFiles = true
        })

        const files = await client.StorageService.GetFilesForDeviation(
          deviation.id
        )

        // TODO: Maybe we should clear all uploaded files here, since we have just fetched all.
        // If someone else deletes a file and I have opened this deviation before, the file will still show
        // Then we will be up to date (eventually).
        deleteDeviationFiles(
          auditId,
          deviation.clientGuid,
          fileData => fileData.isUploaded
        )
        addDeviatoinFiles(auditId, deviation.clientGuid, files)
      } catch (error) {
        // FIXME: Log to sentry
        console.warn('There was an error getting files for deviation')
      } finally {
        runInAction(() => {
          invariant(deviation)
          deviation.isGettingFiles = false
        })
      }
    }

    isGettingFiles = getFiles(auditId, deviationGuid).then(() => {
      if (this.isGettingFilesForDeviation.has(deviationGuid)) {
        this.isGettingFilesForDeviation.delete(deviationGuid)
      }
    })
    this.isGettingFilesForDeviation.set(deviationGuid, isGettingFiles)

    return isGettingFiles
  }

  public AddFile = async (
    auditId: Audit['id'],
    deviationClientGuid: Deviation['clientGuid'],
    file: File
  ) => {
    const operation = new AddDeviationFileOperation(auditId, {
      deviationClientGuid,
      file,
      fileGuid: uuidv4()
    })

    const deviation = getLocalDeviation(auditId, deviationClientGuid)

    const skipSend = !deviation.id

    operationsController.handleNewOperation(operation, {
      skipSend
    })
  }

  public AddFiles = (
    auditId: Audit['id'],
    deviationClientGuid: Deviation['clientGuid'],
    files: File[]
  ) => {
    files.forEach(file => this.AddFile(auditId, deviationClientGuid, file))
  }

  public DeleteFile = async (
    auditId: Audit['id'],
    deviationClientGuid: Deviation['clientGuid'],
    fileId: FileData['id']
  ) => {
    const { audit } = await auditsController.GetAudit(auditId, {
      dontListen: true
    })

    const deviation = audit.deviations.find(
      d => d.clientGuid === deviationClientGuid
    )
    invariant(deviation, `Cannot delete file for a deviation we cannot find!`)

    const file = deviation.files.find(f => f.id === fileId)
    invariant(file, `Cannot delete file we cannot find!`)

    if (file.isUploaded) {
      invariant(deviation.id, `No deviation id even though file is uploaded!`)
      const operation = new DeleteDeviationFileOperation(auditId, {
        deviationId: deviation.id,
        fileId
      })

      operationsController.handleNewOperation(operation)
    } else {
      const operation = operationsController.findOperation(
        op =>
          op.Type === 'add-deviation-file' &&
          (op as AddDeviationFileOperation).data.value.fileGuid === fileId
      )
      invariant(
        operation,
        `No add deviation file operation found for file id ${fileId}`
      )

      operationsController.cancelOperation(operation.guid)
    }
  }
}

export const deviationsController = new DeviationsController()
