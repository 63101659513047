import { Column, Row } from '@cdab/scania/sdds'
import { Subtitle, StyledContentHeader, Title } from './content-header.styles'
import type { ContentHeaderProps } from './content-header.types'

export function ContentHeader({ subtitle, title }: ContentHeaderProps) {
  return (
    <StyledContentHeader fluid='push'>
      <Row>
        <Column width={12}>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Column>
      </Row>
    </StyledContentHeader>
  )
}
