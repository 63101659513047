/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ReportsService as IReportsService } from '@cdab/scania/qpr/interactor'
import type { AxiosResponse } from 'axios'

export class ReportsService implements IReportsService {
  private readonly mockDelayMS: number

  public constructor(mockDelayMS: number) {
    this.mockDelayMS = mockDelayMS
  }
  public GetAreaReport = async (areaIds: number[]): Promise<AxiosResponse> => {
    throw new Error('Not implemented')
  }

  public GetMarketSummaryReport = async (
    marketIds: number[],
    areaIds: number[]
  ): Promise<AxiosResponse> => {
    throw new Error('Not implemented')
  }

  public GetMarketDevioationsReport = async (
    marketIds: number[],
    dateFrom: string,
    dateTo: string
  ): Promise<AxiosResponse> => {
    throw new Error('Not implemented')
  }

  public GetEmptyDOSReport = async (
    dosVersion: number,
    viewSummary: boolean,
    viewCheckpoints: boolean,
    viewPerspectives: boolean
  ): Promise<AxiosResponse> => {
    throw new Error('Not implemented')
  }

  public GetDOSReport = async (
    auditId: number,
    viewSummary: boolean,
    viewCheckpoints: boolean,
    viewPerspectives: boolean,
    viewDeviations: boolean,
    viewNotes: boolean
  ): Promise<AxiosResponse> => {
    throw new Error('Not implemented')
  }
}
