import {
  AuditPageTabs,
  AuditSummary,
  ElasticContentContainer
} from '@cdab/scania/qpr/components'
import { useCssVariableBreakpoint, useIsDos5 } from '@cdab/scania/qpr/hooks'
import { getAuditIdFromParams } from '@cdab/scania/qpr/loaders'
import { auditsController } from '@cdab/scania/qpr/offline/controllers'
import { Column, Container, Message } from '@cdab/scania/sdds'
import { useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useRouteLoaderData } from 'react-router-dom'
import styled from 'styled-components'
import type { AuditLoaderData } from '../auditId'

const StyledContainer = styled(Container)`
  @media (min-width: ${({ theme }) => theme.size.md}) {
    padding-top: var(--sdds-spacing-element-16);
  }
`

const RowsWithGap = styled.div`
  & > * {
    padding-top: var(--sdds-spacing-element-16);
  }

  &:first-child {
    padding-top: 0;
  }
`

export function loader({ params }: LoaderFunctionArgs) {
  const auditId = getAuditIdFromParams(params)

  auditsController.GetFiles(auditId)

  return null
}

export function SummaryPage(): JSX.Element {
  const { t } = useTranslation()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { audit, dealer, favoriteAuditData } = useRouteLoaderData(
    'audit'
  ) as AuditLoaderData
  const isDos5 = useIsDos5(audit)

  return (
    <ElasticContentContainer
      scrollY
      header={
        !isLg && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flexGrow: 1 }}>
              <AuditPageTabs
                isDos5={isDos5}
                links={{
                  points: '../points',
                  summary: '../summary',
                  deviations: '../deviations'
                }}
                numberOfDeviations={audit.deviations.length}
              />
            </div>
          </div>
        )
      }
    >
      <StyledContainer fluid='normal' fullHeight>
        <RowsWithGap>
          {!isDos5 && (
            <Column width={12}>
              <Message
                variant='multi-line'
                singleLineMessage={t('audit:not-dos-5-warning')}
                hideIcon
                type='warning'
                multiLineMessage={t('audit:not-dos-5-warning-explanation')}
              />
            </Column>
          )}
          <AuditSummary
            audit={audit}
            dealer={dealer}
            favoriteAuditData={favoriteAuditData}
          />
        </RowsWithGap>
      </StyledContainer>
    </ElasticContentContainer>
  )
}
