import { AreaMarketReport, EmptyState } from '@cdab/scania/qpr/components'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useUser } from '@cdab/scania/qpr/contexts/user-provider'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { getReportTypeFromParams } from '@cdab/scania/qpr/loaders'
import { Column, Container, Row } from '@cdab/scania/sdds'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs } from 'react-router'
import {
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
  useRouteLoaderData
} from 'react-router'
import type { ReportsPageLoaderData } from './reports'

const areaMarketReportTypes = [
  'area-summary',
  'country-summary',
  'top-market-deviations'
]

export type AreaMarketReportLoaderData = {
  reportType: string
}

export function loader({
  params
}: LoaderFunctionArgs): AreaMarketReportLoaderData {
  const reportType = getReportTypeFromParams(params)

  if (!areaMarketReportTypes.includes(reportType))
    throw new Response(null, {
      status: 404
    })

  return { reportType }
}

export function Page() {
  const { t } = useTranslation(['reports', 'common'])
  const { updateTitles } = useTitle()
  const { userData } = useUser()
  const { reportType } = useLoaderData() as AreaMarketReportLoaderData
  const loaderData = useRouteLoaderData('reports') as ReportsPageLoaderData

  let markets = loaderData.markets
  const userMarkets = loaderData.userMarkets
  const areas = loaderData.areas

  if (userData.role < 75) markets = userMarkets

  useEffect(() => {
    updateTitles({
      mobile: {
        title: t('reports'),
        description: null
      }
    })
  }, [updateTitles, t])

  return (
    <AreaMarketReport reportType={reportType} areas={areas} markets={markets} />
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <Container
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column width={12} padding={isLg}>
            <EmptyState
              title={t('could-not-fetch-reports')}
              description={t('talk-to-someone')}
            />
          </Column>
        </Row>
      </Container>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
