import { useRef, useState } from 'react'

export function useLongPress(timeDuration: number) {
  const [isLongPressState, setIsLongPressState] = useState<boolean>(false)
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined)
  const isLongPress = useRef<boolean>()

  function startPressTimer() {
    isLongPress.current = false
    timerRef.current = setTimeout(() => {
      isLongPress.current = true
      setIsLongPressState(true)
    }, timeDuration)
  }

  function handleOnMouseDown() {
    startPressTimer()
    setIsLongPressState(false)
  }

  function handleOnMouseUp() {
    clearTimeout(timerRef.current)
  }

  function handleOnTouchStart() {
    startPressTimer()
    setIsLongPressState(false)
  }

  function handleOnTouchEnd() {
    if (isLongPressState) return
    clearTimeout(timerRef.current)
  }

  return {
    isLongPressState,
    handlers: {
      onMouseDown: handleOnMouseDown,
      onMouseUp: handleOnMouseUp,
      onTouchStart: handleOnTouchStart,
      onTouchEnd: handleOnTouchEnd
    }
  }
}
