import { z } from 'zod'
import type { JSONSchemaType } from 'ajv'
import { ajv, isValidThing } from './ajv'

export const FileDataSchema = z.object({
  id: z.string(),
  url: z.string(),
  isImage: z.boolean(),
  fileName: z.string(),

  // FIXME: These two does not belong to schema, and should live in a FileDataModel
  isUploaded: z.boolean(),
  uploadProgress: z.number()
})

export type FileData = z.infer<typeof FileDataSchema>

export interface FileDataJson {
  id: number
  url: string
  isImage: boolean
  fileName: string
}

export const FileDataJsonSchema: JSONSchemaType<FileDataJson> = {
  type: 'object',
  properties: {
    id: { type: 'number' },
    url: { type: 'string' },
    isImage: { type: 'boolean' },
    fileName: { type: 'string' }
  },
  required: ['id', 'url', 'isImage', 'fileName']
}

const validateFileData = ajv.compile(FileDataJsonSchema)

export const isValidFileData = (data: unknown): data is FileDataJson =>
  isValidThing<FileDataJson>(data, validateFileData)

export function fileDataJsonToFileData(fileDataJson: FileDataJson): FileData {
  const { id, ...jsonData } = fileDataJson

  return {
    id: id.toString(),
    isUploaded: true, // Every file we get from json (i.e. externally) is already uploaded
    uploadProgress: 1,
    ...jsonData
  }
}

export const FileDataArrayJsonSchema: JSONSchemaType<FileDataJson[]> = {
  type: 'array',
  items: FileDataJsonSchema
}

const validateFileDataArray = ajv.compile(FileDataArrayJsonSchema)

export const isValidFileDataArray = (data: unknown): data is FileDataJson[] =>
  isValidThing<FileDataJson[]>(data, validateFileDataArray)

export function fileDataJsonArrayToFileDataArray(
  fileDataJsonArray: FileDataJson[]
): FileData[] {
  const fileDataArray = fileDataJsonArray.map(fileDataJsonToFileData)

  return fileDataArray
}
