import cx from 'classnames'
import { Link } from 'react-router-dom'

export type Breadcrumb = {
  text: string
  to: string
}
export interface BreadcrumbsProps {
  links: Breadcrumb[]
}

export function Breadcrumbs({ links }: BreadcrumbsProps): JSX.Element {
  return (
    <div className='sdds-breadcrumb'>
      {links.map((link, i, row) => (
        <div
          key={Math.random()}
          className={cx('sdds-breadcrumb-item', {
            'sdds-breadcrumb-item-current': i + 1 === row.length
          })}
        >
          <Link to={link.to}>{link.text}</Link>
        </div>
      ))}
    </div>
  )
}
