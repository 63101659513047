import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export interface FavoriteAuditSingle {
  auditId: number
  createdTime: Date | null
  id: number | null
}

export interface FavoriteAuditSingleJson {
  auditId: number
  createdTime: string | null
  id: number | null
}

export const FavoriteAuditSingleSchema: JSONSchemaType<FavoriteAuditSingleJson> =
  {
    type: 'object',

    properties: {
      auditId: {
        type: 'number',
        nullable: true
      },
      createdTime: {
        type: 'string',
        format: 'iso-date-time',
        nullable: true
      },
      id: {
        type: 'number',
        nullable: true
      }
    },

    required: ['auditId', 'createdTime', 'id']
  }

const validateFavoriteAuditSingle: ValidateFunction<FavoriteAuditSingleJson> =
  ajv.compile(FavoriteAuditSingleSchema)

export const isValidFavoriteAuditSingleJson = (
  favoriteAuditSingle: unknown
): favoriteAuditSingle is FavoriteAuditSingleJson => {
  return isValidThing<FavoriteAuditSingleJson>(
    favoriteAuditSingle,
    validateFavoriteAuditSingle
  )
}

export function createFavoriteAuditSingleFromJson(
  json: FavoriteAuditSingleJson
): FavoriteAuditSingle {
  return {
    auditId: json.auditId,
    createdTime: json.createdTime ? new Date(json.createdTime) : null,
    id: json.id
  }
}
