import { dividerTestId } from './card.constants'
import {
  Avatar,
  Body,
  Divider,
  Footer,
  Header,
  Headline,
  Headlines,
  Image,
  StyledCard,
  Subheadline
} from './card.styles'
import type { CardProps } from './card.types'

export function Card({
  avatar,
  children,
  image,
  divider = false,
  footer,
  headline,
  onClick,
  onWhite = false,
  subheadline,
  className
}: Readonly<CardProps>) {
  return (
    <StyledCard
      className={className}
      clickable={!!onClick}
      onClick={onClick}
      whiteBg={onWhite}
    >
      {image && <Image alt={image.alt} src={image.url} />}
      <Header hasAvatar={!!avatar}>
        {avatar ? (
          <>
            <Avatar>{avatar}</Avatar>
            <Headlines hasAvatar={!!avatar}>
              {headline && <Headline>{headline}</Headline>}
              {subheadline && <Subheadline>{subheadline}</Subheadline>}
            </Headlines>
          </>
        ) : (
          <>
            {headline && <Headline>{headline}</Headline>}
            {subheadline && <Subheadline>{subheadline}</Subheadline>}
          </>
        )}
      </Header>

      {divider && <Divider data-testid={dividerTestId} />}
      {children && <Body>{children}</Body>}
      {footer && <Footer>{footer}</Footer>}
    </StyledCard>
  )
}
