import {
  isRouteErrorResponse,
  Navigate,
  useLoaderData,
  useRouteError
} from 'react-router'
import { json } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Column, Container, Row } from '@cdab/scania/sdds'
import { EmptyState } from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import type { Market } from '@cdab/scania/qpr/schema'

type DealersPageLoaderData = {
  markets: Market[]
}

export async function loader(): Promise<DealersPageLoaderData> {
  const client = getClient()

  const user = await client.GetuserInfo()
  if (!user) {
    throw json(null, {
      status: 401
    })
  }

  const markets = await client.MarketsService.GetMarketsForUser(user.userId)
  return {
    markets
  }
}

export function DealersPage() {
  const { t } = useTranslation('market')
  const { markets } = useLoaderData() as DealersPageLoaderData

  if (markets.length === 0) {
    return (
      <Container>
        <Row>
          <Column width={12}>
            <EmptyState
              title={t('no-market-available')}
              description={t('no-markets-description')}
            />
          </Column>
        </Row>
      </Container>
    )
  }

  const sortedMarkets = markets.sort((a: Market, b: Market) =>
    a.marketName.localeCompare(b.marketName)
  )
  const market =
    sortedMarkets.filter(m => m.isMainMarket).at(0) || sortedMarkets[0]

  return <Navigate replace to={`${market.id.toString()}`} />
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    switch (error.status) {
      case 401:
        message = t('not-logged-in')
        break
    }
    return (
      <Container
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column width={12} padding={isLg}>
            <EmptyState
              title={t('could-not-fetch-dealers')}
              description={message}
            />
          </Column>
        </Row>
      </Container>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
