import { useTranslation } from 'react-i18next'

import { CapitalizeFirstLetter } from '@cdab/headless-components'
import { InlineTabsFullbleed, Badges } from '@cdab/scania/sdds'

import type { AuditPageTabsProps } from './audit-page-tabs.types'
import { BadgeTab } from './audit-page-tabs.styled'

export function AuditPageTabs(props: AuditPageTabsProps) {
  const { t } = useTranslation()

  return (
    <InlineTabsFullbleed>
      <InlineTabsFullbleed.Tab to={props.links.summary}>
        <CapitalizeFirstLetter>{t('summary')}</CapitalizeFirstLetter>
      </InlineTabsFullbleed.Tab>
      {props.isDos5 && (
        <InlineTabsFullbleed.Tab to={props.links.points}>
          <CapitalizeFirstLetter>{t('audit-points')}</CapitalizeFirstLetter>
        </InlineTabsFullbleed.Tab>
      )}
      <InlineTabsFullbleed.Tab to={props.links.deviations}>
        <BadgeTab>
          <CapitalizeFirstLetter>{t('deviations')}</CapitalizeFirstLetter>
          <Badges value={props.numberOfDeviations.toString()} />
        </BadgeTab>
      </InlineTabsFullbleed.Tab>
    </InlineTabsFullbleed>
  )
}
