import { EditorContent } from '@tiptap/react'
import styled from 'styled-components'

export const RichControlsWrapper = styled.div`
  border-radius: 1rem 1rem 0 0;
  border-bottom: 1px solid var(--sdds-divider-light);
  background-color: var(--sdds-white);
  padding: var(--sdds-spacing-element-8);
`

export const StyledEditorContent = styled(EditorContent)`
  outline: none;
  padding-top: 0;
`

export const StyledContentWrapper = styled.div`
  padding: 5rem 4rem 5rem 4rem;
`

//copy of editor main element
export const StyledTextContent = styled.div`
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  letter-spacing: -0.14px;
  line-height: 4rem;
  white-space: break-spaces;
  overflow-wrap: break-word;
`

export const StyledFakeToolbarDiv = styled.div`
  height: 12.25rem;
`
