import { FormModal } from '@cdab/scania/qpr/components/molecules'
import { Button } from '@cdab/scania/sdds'
import { useTranslation } from 'react-i18next'
import type { BeforeDeleteModalProps } from './before-delete-modal.types'

// TODO: Here is a temporary solution, we should switch to a new sdds component later (QPR-898) */

export function BeforeDeleteModal({
  open,
  onClose,
  onSubmit,
  objectName
}: BeforeDeleteModalProps) {
  const { t } = useTranslation('common')
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
  }

  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleFormSubmit}
      footer={
        <>
          <Button
            type='primary'
            onClick={() => onSubmit()}
            size='md'
            text={t('yes')}
          />
          <Button type='secondary' onClick={onClose} size='md' text={t('no')} />
        </>
      }
      header={
        <div style={{ textTransform: 'none' }}>
          {objectName
            ? ` ${t('are-you-sure-you-want-delete', { object: objectName })}`
            : t('are-you-sure')}
        </div>
      }
    />
  )
}
