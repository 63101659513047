import { ElasticContentContainer } from '@cdab/scania/qpr/components/atoms'
import { TdsBadge } from '@scania/tegel-react'
import styled from 'styled-components'

export const StyledElasticContentContainer = styled(ElasticContentContainer)`
  height: calc(100% - var(--tds-header-height));
`

export const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem;
  overflow-y: scroll;
  flex-grow: 1;
  background-color: var(--tds-grey-50);
  height: 100%;
`

export const StyledUploadingMessage = styled.div`
  opacity: 0.5;
  display: flex;
  flex-direction: row-reverse;
`

export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--sdds-spacing-element-8);
`

export const ChatHeaderWrapper = styled.div`
  background: white;
  border-bottom: 1px solid #dbdfe6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15rem;
`

export const ActionRow = styled.div`
  align-items: center;
  background: white;
  background: white;
  border-bottom: 1px solid #dbdfe6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 0;
  padding: 2rem;
  height: 15rem;
`

export const ActionRowTitle = styled.h4`
  margin-right: 1rem;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
`

export const StyledBadges = styled(TdsBadge)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`
