import type { PropsWithChildren } from 'react'
import { Content, Container, CapItem } from './elastic-content-container.styles'
import type { ElasticContentContainerProps } from './elastic-content-container.interfaces'

export function ElasticContentContainer({
  header,
  footer,
  children,
  scrollX = false,
  scrollY = false,
  alwaysShowScrollbars = false,
  overflowHidden,
  ...props
}: PropsWithChildren<ElasticContentContainerProps>) {
  const scrollType = alwaysShowScrollbars ? 'scroll' : 'auto'
  return (
    <Container overflowHidden={overflowHidden} {...props}>
      {header && <CapItem>{header}</CapItem>}
      <Content scrollX={scrollX} scrollY={scrollY} scrollType={scrollType}>
        {children}
      </Content>
      {footer && <CapItem>{footer}</CapItem>}
    </Container>
  )
}
