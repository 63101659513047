import type { DealerAudit } from '@cdab/scania/qpr/schema'

export const MockDealerAudits: DealerAudit[] = [
  {
    id: 1981,
    auditDate: new Date('2005-12-16T00:00:00'),
    isCertificationAudit: false,
    dosVersion: '2',
    auditDescription: 'QPR',
    auditor: 'System  ',
    extraAuditors: 'Jan Högberg',
    hasNotes: false,
    hasDeviations: false,
    auditTypeDescription: 'Regular'
  },
  {
    id: 3308,
    auditDate: new Date('2008-11-21T00:00:00'),
    isCertificationAudit: false,
    dosVersion: '3',
    auditDescription: 'Första DOS3',
    auditor: 'Klas Welander',
    extraAuditors: '',
    hasNotes: true,
    hasDeviations: true,
    auditTypeDescription: 'Regular'
  },
  {
    id: 4571,
    auditDate: new Date('2010-11-18T00:00:00'),
    isCertificationAudit: false,
    dosVersion: '3',
    auditDescription: 'K2',
    auditor: 'Klas Welander',
    extraAuditors: '',
    hasNotes: true,
    hasDeviations: true,
    auditTypeDescription: 'Regular'
  },
  {
    id: 5998,
    auditDate: new Date('2012-09-12T00:00:00'),
    isCertificationAudit: false,
    dosVersion: '3',
    auditDescription: 'K2',
    auditor: 'Klas Welander',
    extraAuditors: '',
    hasNotes: true,
    hasDeviations: true,
    auditTypeDescription: 'Regular'
  },
  {
    id: 7554,
    auditDate: new Date('2014-08-21T00:00:00'),
    isCertificationAudit: false,
    dosVersion: '5',
    auditDescription: 'K2',
    auditor: 'Klas Welander',
    extraAuditors: '',
    hasNotes: true,
    hasDeviations: true,
    auditTypeDescription: 'Regular'
  },
  {
    id: 9357,
    auditDate: new Date('2016-12-14T00:00:00'),
    isCertificationAudit: false,
    dosVersion: '5',
    auditDescription: 'K2',
    auditor: 'Kjell Hallberg',
    extraAuditors: '',
    hasNotes: true,
    hasDeviations: true,
    auditTypeDescription: 'Regular'
  },
  {
    id: 11030,
    auditDate: new Date('2018-10-31T00:00:00'),
    isCertificationAudit: false,
    dosVersion: '5',
    auditDescription: 'K2',
    auditor: 'Kjell Hallberg',
    extraAuditors: '',
    hasNotes: true,
    hasDeviations: true,
    auditTypeDescription: 'Regular'
  },
  {
    id: 13077,
    auditDate: new Date('2022-01-01T00:00:00'),
    isCertificationAudit: true,
    dosVersion: '6',
    auditDescription: 'K2',
    auditor: 'Kjell Hallberg',
    extraAuditors: '',
    hasNotes: true,
    hasDeviations: true,
    auditTypeDescription: 'Regular'
  }
]
