import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { Audit } from '@cdab/scania/qpr/schema'

export type AuditActionPlanReminderState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'loading'
      message: null
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

export function useSendActionPlanReminder() {
  const client = useBackend()

  const sendActionPlanReminder = async (
    auditId: Audit['id']
  ): Promise<string> => {
    try {
      const response =
        await client.RemindersService.SendActionPlanReminderForUser(auditId)
      return response
    } catch (e) {
      return 'fail'
    }
  }

  return {
    sendActionPlanReminder
  }
}

export function useActionPlanReminder(auditId: number) {
  const { t } = useTranslation('audit')

  const { sendActionPlanReminder } = useSendActionPlanReminder()
  const [stateActionPlanReminder, setStateActionPlanReminder] =
    useState<AuditActionPlanReminderState>({
      status: 'none',
      message: null
    })
  const handleClickActionPlanReminder = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      setStateActionPlanReminder({ status: 'loading', message: null })
      const response = await sendActionPlanReminder(auditId)

      if (response === 'success') {
        setStateActionPlanReminder({ status: 'success', message: response })
      } else if (response === 'fail') {
        setStateActionPlanReminder({
          status: 'error',
          message: t('reminders.audit-action-plan-reminder-fail')
        })
      } else {
        setStateActionPlanReminder({ status: 'error', message: response })
      }
    },
    [auditId, sendActionPlanReminder, t]
  )

  return [stateActionPlanReminder, handleClickActionPlanReminder] as const
}
