/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type {
  CreateADHLinkChatMessageData,
  ADHService as IADHService,
  OnUploadProgressUpdate,
  UploadFileResponse
} from '@cdab/scania/qpr/interactor'
import type {
  ADHAuditWithUnreadMessages,
  ADHLink
} from '@cdab/scania/qpr/schema'

export class ADHService implements IADHService {
  private readonly mockDelayMS: number

  public constructor(mockDelayMS: number) {
    this.mockDelayMS = mockDelayMS
  }
  GetLinkActionPlansForUser(): Promise<ADHAuditWithUnreadMessages[]> {
    throw new Error('Method not implemented.')
  }
  GetADHLinkFromKey(ADHLink: string): Promise<ADHLink> {
    throw new Error('Method not implemented.')
  }
  GetADHLinkChatFiles(linkId: number): Promise<
    {
      id: string
      url: string
      isImage: boolean
      fileName: string
      isUploaded: boolean
      uploadProgress: number
    }[]
  > {
    throw new Error('Method not implemented.')
  }
  DeleteChatFile(fileId: number): Promise<void> {
    throw new Error('Method not implemented.')
  }
  UploadChatFile(
    file: File,
    onProgressUpdate?: OnUploadProgressUpdate | undefined
  ): Promise<UploadFileResponse> {
    throw new Error('Method not implemented.')
  }
  GetKeyChatFiles(linkId: number): Promise<
    {
      id: string
      url: string
      isImage: boolean
      fileName: string
      isUploaded: boolean
      uploadProgress: number
    }[]
  > {
    throw new Error('Method not implemented.')
  }
  GetDataKeyFromKey(ADHLink: string): Promise<ADHLink> {
    throw new Error('Method not implemented.')
  }
  CreateADHLinkChatMessage(
    data: CreateADHLinkChatMessageData
  ): Promise<number> {
    throw new Error('Method not implemented.')
  }

  SetChatMessagesAsReadByDealer(actionPlanItemId: number): Promise<void> {
    if (actionPlanItemId) {
      throw new Error('Method not implemented.')
    }

    throw new Error('Method not implemented.')
  }
}
