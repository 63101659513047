import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export type PledgePerspective = {
  id: number
  perspectiveType: number
  pledgeId: number
  translationGuid: string
}

export const PledgePerspectiveSchema: JSONSchemaType<PledgePerspective> = {
  type: 'object',
  properties: {
    id: {
      type: 'number'
    },
    perspectiveType: {
      type: 'number',
      nullable: true
    },
    pledgeId: {
      type: 'number',
      nullable: true
    },
    translationGuid: {
      type: 'string'
    }
  },
  required: ['id', 'pledgeId', 'perspectiveType', 'translationGuid']
}

const validatePledgePerspective: ValidateFunction<PledgePerspective> =
  ajv.compile(PledgePerspectiveSchema)

export const isValidPledgePerspective = (
  pledgePerspective: unknown
): pledgePerspective is PledgePerspective =>
  isValidThing<PledgePerspective>(pledgePerspective, validatePledgePerspective)
