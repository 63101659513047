import { useEffect } from 'react'
import { Outlet } from 'react-router'

import { useUser } from '@cdab/scania/qpr/contexts/user-provider'
import {
  FeatureFlagProvider,
  useGetFeatureFlags,
  useIdentifyUser
} from '@cdab/scania/qpr/feature-flags'

import { getEnvVarsQPR } from '@cdab/scania/qpr/env-vars'

export function WithFeatureFlags() {
  const { userData } = useUser()
  const { identifyUser } = useIdentifyUser()
  const { flagsmithApiKey, flagsmithApiUrl } = getEnvVarsQPR()
  const { getFlags } = useGetFeatureFlags()

  useEffect(() => {
    getFlags()

    if (!userData) {
      return
    }
    identifyUser(userData.userId.toString())
  }, [getFlags, identifyUser, userData])

  return (
    <FeatureFlagProvider apiKey={flagsmithApiKey} url={flagsmithApiUrl}>
      <Outlet />
    </FeatureFlagProvider>
  )
}
