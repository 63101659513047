import { UploadFileButton } from '@cdab/scania/qpr/components/atoms'
import {
  AttachmentList,
  FormModal
} from '@cdab/scania/qpr/components/molecules'
import { auditsController } from '@cdab/scania/qpr/offline/controllers'
import type {
  AuditModel,
  DeviationModel
} from '@cdab/scania/qpr/offline/models'
import type { AuditPoint, FileData, UserFile } from '@cdab/scania/qpr/schema'
import { freeMemory } from '@cdab/scania/qpr/utils'
import {
  Button,
  Checkbox,
  DelayedSpinner,
  Divider,
  Dropdown,
  DropdownOption,
  Spinner,
  Textarea,
  Textfield
} from '@cdab/scania/sdds'
import { capitalizeFirstLetter, fileIsImage, formatError } from '@cdab/utils'
import { observer } from 'mobx-react-lite'
import type { FormEvent } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import invariant from 'tiny-invariant'
import { v4 as uuidv4 } from 'uuid'
import { useDeviationForm } from './deviation-modal.hooks'
import { DateRow, FormRow, StyledDateTime } from './deviation-modal.styles'
import type { DeviationModalProps } from './deviation-modal.types'

type UseAuditState =
  | {
      status: 'success'
      audit: AuditModel
    }
  | {
      status: 'loading'
      audit: undefined
    }

const useAudit = (auditId: number) => {
  const [state, setState] = useState<UseAuditState>({
    status: 'loading',
    audit: undefined
  })

  useEffect(() => {
    async function asyncEffect() {
      const { audit } = await auditsController.GetAudit(auditId)

      setState({
        status: 'success',
        audit
      })
    }
    asyncEffect()
  }, [auditId])

  return state
}

type AuditPointNumberData = Pick<AuditPoint, 'auditPointNo'> & {
  auditPointId: AuditPoint['id']
}

export type LocalFileData = FileData & {
  file: File
}

export const DeviationModal = observer((props: DeviationModalProps) => {
  const { deviation, open, onSave, allowEditAuditPoint } = props
  const { isGettingFiles, files } = deviation || {}

  // TODO: We can probably remove this useState and only use values from useDeviationForm
  const [selectedFilesToUpload, setSelectedFilesToUpload] = useState<
    UserFile[]
  >([])

  const { t } = useTranslation(['audit', 'common'])

  const auditState = useAudit(props.auditId)

  const { validate, state, setValue } = useDeviationForm(
    deviation,
    props.defaultAuditPoint?.id.toString(),
    props.defaultAuditPoint?.auditPointNo
  )

  const isReadonlyRef = useRef(true)

  const onClose = useCallback(() => {
    freeMemory(selectedFilesToUpload)
    props.onClose()
  }, [props, selectedFilesToUpload])

  const onSubmit = useCallback(
    (e: FormEvent<Element>) => {
      e.preventDefault()
      if (isReadonlyRef.current) return // Nothing to do

      validate()
        .then(d => {
          return onSave(d)
        })
        .then(() => onClose())
        .catch(e => {
          console.error('ERROR', e)
        })
    },
    [validate, onSave, onClose]
  )

  const onAuditPointNumberChange = useCallback(
    ({ auditPointId, auditPointNo }: AuditPointNumberData) => {
      setValue('auditPointNumber', auditPointNo)
      setValue('auditPointId', auditPointId.toString())
    },
    [setValue]
  )

  const onUploadFilesClick = (fileList: FileList) => {
    const files = Array.from(fileList)

    if (files.length === 0) return

    const inputFiles: UserFile[] = Array.from(files).map(file => ({
      file,
      id: uuidv4(),
      url: URL.createObjectURL(file)
    }))

    const nextFiles = [...selectedFilesToUpload, ...inputFiles]

    setValue('files', nextFiles)
    setSelectedFilesToUpload(nextFiles)
  }

  const onDeleteFileClick = (fileId: UserFile['id']) => {
    // TODO: Add translation
    if (!window.confirm('Are you sure you want to delete this file?')) return

    const currentValues = state.rawData.filesToDelete ?? []
    currentValues.push(fileId)

    setValue('filesToDelete', currentValues)
  }

  const onDeleteSelectedFile = useCallback(
    (fileId: FileData['id']) => {
      // TODO: Add translation
      if (!window.confirm('Are you sure you want to delete this file?')) return

      const selectedFileIndex = selectedFilesToUpload.findIndex(
        f => f.id === fileId
      )

      if (selectedFileIndex === -1) return

      const nextFiles = selectedFilesToUpload.slice()
      const deletedFiles = nextFiles.splice(selectedFileIndex, 1)

      freeMemory(deletedFiles)

      setSelectedFilesToUpload(nextFiles)
      setValue('files', nextFiles)
    },
    [selectedFilesToUpload, setValue]
  )

  if (auditState.status === 'loading') return <DelayedSpinner />

  const { audit } = auditState
  const isReadonly = (isReadonlyRef.current = audit.isReadonly)

  const auditPointNumbers: AuditPointNumberData[] = audit.auditPoints
    .slice()
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map(({ auditPointNo, id }) => ({ auditPointNo, auditPointId: id }))

  const filesBeingUploaded: DeviationModel['files'] = []
  const uploadedFiles: DeviationModel['files'] = []
  files
    ?.filter(
      ({ id }) => (state.rawData.filesToDelete ?? []).includes(id) === false
    )
    .forEach(file => {
      if (file.isUploaded) {
        uploadedFiles.push(file)
      } else {
        filesBeingUploaded.push(file)
      }
    })

  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      header={t('deviation', { ns: 'common' })}
      footer={
        <>
          {!isReadonly && (
            <Button
              disabled={isReadonly}
              text={capitalizeFirstLetter(t('save', { ns: 'common' }))}
              onClick={onSubmit}
            />
          )}
          <Button
            text={capitalizeFirstLetter(t('cancel', { ns: 'common' }))}
            onClick={onClose}
            type='secondary'
          />
          {props.onDelete && !isReadonly && (
            <Button
              text={capitalizeFirstLetter(t('delete', { ns: 'common' }))}
              onClick={props.onDelete}
              disabled={isReadonly}
            />
          )}
        </>
      }
    >
      <div>
        {!props.hideWithActionPlanCheckbox && !isReadonly && (
          <FormRow extra={true}>
            <Checkbox
              label={t('deviation-without-action-plan', { ns: 'audit' })}
              checked={!state.rawData.withActionPlan}
              onChange={e => setValue('withActionPlan', !e.target.checked)}
              disabled={isReadonly}
            />
          </FormRow>
        )}
        {allowEditAuditPoint && (
          <FormRow>
            <Dropdown
              label={t('audit-point', { ns: 'common' }) + '*'}
              placeholder={t('select-audit-point')}
              labelPosition='outside'
              disabled={isReadonly}
              onSelect={e => {
                const auditPointId = Number(e.value)
                const auditPointNo = auditPointNumbers.find(
                  ap => ap.auditPointId === auditPointId
                )?.auditPointNo
                invariant(auditPointNo)

                onAuditPointNumberChange({ auditPointId, auditPointNo })
              }}
              defaultOption={state.rawData.auditPointId}
            >
              {auditPointNumbers.map(({ auditPointId, auditPointNo }) => (
                <DropdownOption
                  key={auditPointId}
                  value={auditPointId?.toString() || ''}
                  text={auditPointNo.toString()}
                />
              ))}
            </Dropdown>
            {state.errors?.auditPointId && (
              <div style={{ color: 'red' }}>
                {formatError(state.errors?.auditPointId)}
              </div>
            )}
          </FormRow>
        )}
        <FormRow>
          <Textarea
            helper={formatError(state.errors?.deviation)}
            label={t('deviation', { ns: 'common' }) + '*'}
            maxLength={1000}
            name='deviation'
            onChange={e => setValue('deviation', e.target.value)}
            placeholder={t('deviation', { ns: 'common' })}
            state={state.errors?.deviation && 'error'}
            value={state.rawData.deviation ?? ''}
            disabled={isReadonly}
          ></Textarea>
        </FormRow>
        {state.rawData.withActionPlan && (
          <>
            <FormRow>
              <Textarea
                helper={formatError(state.errors?.action)}
                label={t('proposed-action', { ns: 'common' }) + '*'}
                maxLength={1000}
                name='action'
                onChange={e => setValue('action', e.target.value)}
                placeholder={t('proposed-action', { ns: 'common' })}
                state={state.errors?.action && 'error'}
                value={state.rawData.action ?? ''}
                disabled={isReadonly}
              ></Textarea>
            </FormRow>
            <FormRow>
              <Textfield
                helper={formatError(state.errors?.assignee)}
                label={t('responsible', { ns: 'audit' }) + '*'}
                labelPosition='outside'
                maxLength={100}
                name='assignee'
                onChange={e => setValue('assignee', e.target.value)}
                placeholder={t('responsible', { ns: 'audit' })}
                state={state.errors?.assignee && 'error'}
                value={state.rawData.assignee ?? ''}
                disabled={isReadonly}
              />
            </FormRow>
            <DateRow>
              <StyledDateTime
                helper={formatError(state.errors?.due)}
                name='dueDate'
                noMinWidth={true}
                label={t('due-date', { ns: 'common' }) + '*'}
                onInput={e => setValue('due', e.target.value)}
                state={state.errors?.due && 'error'}
                type='date'
                value={state.rawData.due ?? ''}
                disabled={isReadonly}
              />
              <StyledDateTime
                helper={formatError(state.errors?.completed)}
                name='completed'
                label={t('completion-date', { ns: 'common' })}
                noMinWidth={true}
                onInput={e => setValue('completed', e.target.value)}
                state={state.errors?.completed && 'error'}
                type='date'
                value={state.rawData.completed ?? ''}
                disabled={isReadonly}
              />
            </DateRow>
          </>
        )}

        <div>
          {!isReadonly && (
            <UploadFileButton size='sm' onUploadFiles={onUploadFilesClick} />
          )}

          {selectedFilesToUpload.length > 0 && (
            <div>
              <Divider type='light' className='sdds-u-mt2 sdds-u-mb2' />
              <AttachmentList
                color='on-white'
                fileType='all'
                files={selectedFilesToUpload.map(({ file, id, url }) => ({
                  fileName: file.name,
                  id,
                  isImage: fileIsImage(file),
                  isUploaded: false,
                  uploadProgress: 0,
                  url
                }))}
                hideUploadProgress
                onDeleteAttachment={onDeleteSelectedFile}
                disableDelete={isReadonly}
                title={t('files-to-add-on-save')}
              />
            </div>
          )}
          {filesBeingUploaded.length > 0 && (
            <div>
              <Divider type='light' className='sdds-u-mt2 sdds-u-mb2' />
              <AttachmentList
                color='on-white'
                files={filesBeingUploaded}
                disableDelete
                title={t('files-not-yet-uploaded')}
              />
            </div>
          )}

          <div>
            {!(isReadonly && uploadedFiles.length === 0) && (
              <>
                <Divider type='light' className='sdds-u-mt2 sdds-u-mb2' />
                <AttachmentList
                  color='on-white'
                  files={uploadedFiles}
                  onDeleteAttachment={onDeleteFileClick}
                  disableDelete={isReadonly}
                />
              </>
            )}
            {isGettingFiles && <Spinner />}
          </div>
        </div>
      </div>
    </FormModal>
  )
})
