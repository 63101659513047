import {
  auditsController,
  dealersController
} from '@cdab/scania/qpr/offline/controllers'
import { Toast } from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import type { EditAuditModalProps, ParsedAuditData } from './audit-modal.types'
import { AuditModal } from './common-audit-modal'

export const AuditEditModal = observer(function AuditEditModal(
  props: EditAuditModalProps
) {
  const [disableSave, setDisableSave] = useState(false)
  const { audit, onClose, open } = props
  const [putModalInDom, setPutModalInDom] = useState(false)
  const [t] = useTranslation('common')

  const onSubmit = useCallback(
    async (data: ParsedAuditData) => {
      setDisableSave(true)
      await auditsController.UpdateAudit(audit, {
        date: data.date,
        description: data.description,
        extraAuditors: data.extraAuditor ?? '',
        auditTypeId: data.auditTypeId
      })
      toast(
        <Toast
          type='success'
          headline={capitalizeFirstLetter(t('audit-updated', { ns: 'common' }))}
          subheadline={capitalizeFirstLetter(
            t('audit-updated-success', { ns: 'common' })
          )}
        />
      )
      dealersController.GetDealer(audit.did) // GetDealer is used here so UpdateAudit endpoint could update dealer date
      setDisableSave(false)
      return true
    },
    [audit, t]
  )

  const onHideTransitionEnded = useCallback(() => {
    setPutModalInDom(false)
  }, [])

  if (!putModalInDom) {
    if (open && !putModalInDom) setPutModalInDom(true)
    return null
  }

  return (
    <AuditModal
      header={t('edit-audit')}
      initialDate={audit.date}
      initialDescription={audit.description ?? undefined}
      initialExtraAuditor={audit.extraAuditors}
      onSubmit={onSubmit}
      initialAuditTypeId={audit.auditTypeId}
      disableSave={disableSave}
      open={open}
      onClose={onClose}
      onHideTransitionEnded={onHideTransitionEnded}
    />
  )
})
