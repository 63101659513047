import styled from 'styled-components'

import { BOTTOM_NAVIGATION_HEIGHT } from '@cdab/scania/qpr/components'
import { Column } from '@cdab/scania/sdds'

export const Content = styled.div`
  overflow-y: scroll;
  height: 100%;

  padding-bottom: ${BOTTOM_NAVIGATION_HEIGHT};

  @media (min-width: ${({ theme }) => theme.size.lg}) {
    padding-bottom: 0;
  }
`

export const ColumnWithShadow = styled(Column)`
  @media (min-width: ${({ theme }) => theme.size.lg}) {
    box-shadow: 0 0 8px rgb(0 0 0 / 10%);
  }
`

export const FullHeightRelativeContainer = styled.div`
  position: relative;
  height: 100%;
`
