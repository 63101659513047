import { CapitalizeFirstLetter } from '@cdab/headless-components'
import { NavigationTabs, Badges } from '@cdab/scania/sdds'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { BadgeTab } from '../badge-tab'
import type { AuditPointTabsProps } from './audit-point-tabs.types'

export const SEARCH_PARAM_OPEN_PLEDGES_KEY = 'openPledges'

export const AuditPointTabs = (props: AuditPointTabsProps) => {
  const { numberOfDeviations, numberOfReferenceDocuments } = props
  const { t } = useTranslation()
  const [searchParams] = useSearchParams({
    [SEARCH_PARAM_OPEN_PLEDGES_KEY]: []
  })

  function getLink(path: string) {
    const link = `${path}?${searchParams.toString()}`
    return link
  }

  return (
    <NavigationTabs>
      <NavigationTabs.Tab to={getLink('status')}>
        <CapitalizeFirstLetter>{t('status')}</CapitalizeFirstLetter>
      </NavigationTabs.Tab>
      <NavigationTabs.Tab to={getLink('checkpoints')}>
        <CapitalizeFirstLetter>{t('checkpoints')}</CapitalizeFirstLetter>
      </NavigationTabs.Tab>
      <NavigationTabs.Tab to={getLink('deviations')}>
        <BadgeTab>
          <CapitalizeFirstLetter>{t('deviations')}</CapitalizeFirstLetter>
          <Badges value={numberOfDeviations.toString()} />
        </BadgeTab>
      </NavigationTabs.Tab>
      <NavigationTabs.Tab to={getLink('reference-documents')}>
        <BadgeTab>
          <CapitalizeFirstLetter>
            {t('reference-documents')}
          </CapitalizeFirstLetter>{' '}
          <Badges value={numberOfReferenceDocuments.toString()} />
        </BadgeTab>
      </NavigationTabs.Tab>
      <NavigationTabs.Tab to={getLink('attachments')}>
        <CapitalizeFirstLetter>{t('attachments')}</CapitalizeFirstLetter>
      </NavigationTabs.Tab>
    </NavigationTabs>
  )
}
