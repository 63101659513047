import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import type { ActionFunctionArgs } from 'react-router'
import invariant from 'tiny-invariant'

export const action = async ({ params, request }: ActionFunctionArgs) => {
  if (request.method === 'PUT') {
    const client = getClient()

    invariant(params['messageId'], 'Invalid system message id')

    try {
      await client.SystemMessagesService.ReadSystemMessageIdForUser(
        Number(params['messageId'])
      )
    } catch {
      return { ok: false }
    }
  }
  return { ok: true }
}
