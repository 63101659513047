import type { AxiosInstance } from 'axios'

import type {
  CreateDocumentData,
  DocumentService as DocumentServiceDefinition,
  UpdateDocumentData
} from '@cdab/scania/qpr/interactor'
import { axiosErrorToError } from '@cdab/utils'

import type { AdminReferenceDocument } from '@cdab/scania/qpr/schema'
import { isValidAdminReferenceDocument } from '@cdab/scania/qpr/schema'
import type { Configuration } from './generated-swagger-client'
import { DocumentApi } from './generated-swagger-client'

export class DocumentService implements DocumentServiceDefinition {
  private readonly DocumentsApi: DocumentApi

  public constructor(
    configuration: Configuration,
    axiosInstance: AxiosInstance
  ) {
    this.DocumentsApi = new DocumentApi(
      configuration,
      configuration.basePath,
      axiosInstance
    )
  }

  public GetDocuments = async (): Promise<AdminReferenceDocument[]> => {
    try {
      const response = await this.DocumentsApi.apiDocumentGet()

      const documents: AdminReferenceDocument[] = []

      for (const document of response.data.documents ?? []) {
        if (!isValidAdminReferenceDocument(document))
          throw new Error(`Admin reference document ${document.id} is invalid`)

        documents.push(document as AdminReferenceDocument)
      }

      return documents
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetDocument = async (
    documentId: AdminReferenceDocument['id']
  ): Promise<AdminReferenceDocument> => {
    try {
      const response = await this.DocumentsApi.apiDocumentDocumentIdGet(
        documentId
      )

      if (!isValidAdminReferenceDocument(response.data))
        throw new Error(
          `Admin reference document ${response.data.id} is invalid`
        )

      return response.data as AdminReferenceDocument
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public CreateDocument = async (data: CreateDocumentData): Promise<void> => {
    try {
      await this.DocumentsApi.apiDocumentPost({ ...data })
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public UpdateDocument = async (data: UpdateDocumentData): Promise<void> => {
    try {
      await this.DocumentsApi.apiDocumentPut({ ...data })
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public DeleteDocument = async (documentId: number): Promise<void> => {
    try {
      await this.DocumentsApi.apiDocumentDocumentIdDelete(documentId)
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }
}
