import { getEnvVarsQPR } from '@cdab/scania/qpr/env-vars'

import type { Provider } from './client-providers'
import {
  PROVIDER_API,
  PROVIDER_FIREBASE,
  PROVIDER_MOCK,
  getApiProvider,
  getFirebaseProvider,
  getMockProvider
} from './client-providers'

export function getProviderFromEnvironment(): Provider {
  let provider: Provider | undefined

  const { ProviderType } = getEnvVarsQPR()

  switch (ProviderType) {
    case PROVIDER_API:
      provider = getApiProvider()
      break

    case PROVIDER_FIREBASE:
      provider = getFirebaseProvider()
      break

    case PROVIDER_MOCK:
      provider = getMockProvider()
      break

    default:
      throw new Error('No provider could be created from environment')
  }

  return provider
}
