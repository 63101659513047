import { ClickableList } from '@cdab/scania/qpr/components'
import { Block, Button, Container, IconMessage, Row } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const StyledIcon = styled(IconMessage).attrs({
  className: 'sdds-btn-icon'
})``

export const StyledPaddingWrapper = styled.div`
  padding-bottom: 3rem;

  @media (max-width: ${({ theme }) => theme.size.lg}) {
    padding-left: 3rem;
  }
`

export const StyledContainer = styled(Container)`
  background: var(--sdds-grey);
`

export const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

export const StyledBlock = styled(Block)`
  height: 100%;
  overflow-x: scroll;
`

export const StyledClickableList = styled(ClickableList)`
  overflow: hidden;
`

export const StyledRow = styled(Row)`
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`

export const StyledButtonsRow = styled(Row)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8rem;
  @media (max-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 6rem;
  }
`

export const StyledLastButtonWrapper = styled.div`
  flex: 1 0 30%;
  direction: rtl;
`

export const StyledFirstButton = styled(Button)`
  padding-right: 8px;
`

export const Title = styled.h3.attrs({
  className: 'sdds-headline-03'
})`
  margin-bottom: 2rem;
  margin-top: 2rem;
`
