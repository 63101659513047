export {
  ErrorBoundary as AdminTranslationErrorBoundary,
  Page as AdminTranslationsBasePage,
  loader as adminTranslationBaseLoader
} from './base'
export {
  ErrorBoundary as AdminTranslationsLanguageErrorBoundary,
  Page as AdminTranslationsLanguagePage,
  loader as adminTranslationsLanguageLoader
} from './language'
export {
  ErrorBoundary as AdminTranslationsTabErrorBoundary,
  Page as AdminTranslationsTabPage,
  loader as adminTranslationsTabLoader
} from './translationsTab'
