import cx from 'classnames'
import styled, { css } from 'styled-components'

import { ColumnStyles } from '../column'
import { RowStyles } from '../row'

import type { StyleProps } from './container.styles.types'

export const StyledContainer = styled.div.attrs<StyleProps>(
  ({ className, fluid, paddingOnColumns }) => ({
    className: cx(className, {
      'sdds-container-fluid': fluid === 'normal',
      'sdds-container-push-fluid': fluid === 'push',
      'sdds-container': !fluid,
      'sdds-no-padding': paddingOnColumns === false
    })
  })
)<StyleProps>`
  width: 100%;

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};

  ${({ paddingOnContainer }) =>
    paddingOnContainer === false &&
    css`
      padding: 0;
    `};

  ${({ fullHeightGrid }) =>
    fullHeightGrid &&
    css`
      height: 100%;

      ${ColumnStyles.StyledColumn}, ${RowStyles.StyledRow} {
        height: 100%;
      }
    `};

  overflow: ${({ scrollY }) => (scrollY ? 'auto' : 'visible')};
`
