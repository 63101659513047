import type { Audit, CheckPoint } from '@cdab/scania/qpr/schema'
import invariant from 'tiny-invariant'
import { auditsStore } from './store'

export function getCheckPoint(
  auditId: Audit['id'],
  checkPointId: CheckPoint['id']
): CheckPoint {
  const audit = auditsStore[auditId]
  invariant(audit)

  const checkPoint = audit.checkPoints.find(({ id }) => id === checkPointId)
  invariant(checkPoint)

  return checkPoint
}
