import { v4 as uuidv4 } from 'uuid'
import { action } from 'mobx'
import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { Audit, Deviation } from '@cdab/scania/qpr/schema'
import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import invariant from 'tiny-invariant'
import type { AuditModel, DeviationModel } from '../audit-model'

type Type = 'delete-deviation'

type RequiredDeviationId = Required<Pick<Deviation, 'id'>>['id']

type Data = { deviationId: RequiredDeviationId }

export type DeleteDeviationOperationData = OperationDataBase<Type, Data>

export class DeleteDeviationOperation extends Operation {
  public data: DeleteDeviationOperationData
  private deletedDeviation: DeviationModel | undefined = undefined

  constructor(auditId: Audit['id'], value: Data, guid = uuidv4()) {
    super(auditId, guid)

    this.data = {
      type: 'delete-deviation',
      value,
      previousValue: undefined
    }
  }

  private getDeviationIndex = (audit: AuditModel): number => {
    const { deviationId } = this.data.value
    const i = audit.deviations.findIndex(d => {
      const isEqual = d.id === deviationId

      return isEqual
    })

    return i
  }

  public ApplyTo = action((audit: AuditModel): void => {
    const deviationIndex = this.getDeviationIndex(audit)

    if (deviationIndex === -1) return

    const [deviation] = audit.deviations.splice(deviationIndex, 1)
    this.deletedDeviation = deviation
  })

  public GetName = () =>
    `${this.data.type}-${this.auditId}-${this.data.value.deviationId}`

  public SendOperation = async (client: BackEnd) => {
    invariant(this.deletedDeviation, `No deleted deviation to remove`)

    const deviationId = this.deletedDeviation.id
    invariant(deviationId, 'No id on deleted deviation!')

    const result = await client.AuditsRealTimeService.DeleteDeviation(
      this.guid,
      new Date(),
      this.auditId,
      deviationId
    )

    return result
  }

  public RollbackOn = action((audit: AuditModel): void => {
    invariant(
      this.deletedDeviation,
      'Tried to roll back deletion, but no deviation was deleted!'
    )

    audit.deviations.push(this.deletedDeviation)
    this.deletedDeviation = undefined
  })
}
