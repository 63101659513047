import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import type {
  CreateSystemMessageData,
  SystemMessagesService as SystemMessagesServiceDefinition
} from '@cdab/scania/qpr/interactor'
import type { SystemMessage } from '@cdab/scania/qpr/schema'
import { mapResponseToSystemMessage } from '@cdab/scania/qpr/schema'
import { axiosErrorToError } from '@cdab/utils'

import type { Configuration } from './generated-swagger-client'
import { SystemMessagesApi } from './generated-swagger-client'

export class SystemMesssagesService implements SystemMessagesServiceDefinition {
  private readonly systemMessagesApi: SystemMessagesApi

  public constructor(
    configuration: Configuration,
    axiosInstance: AxiosInstance
  ) {
    this.systemMessagesApi = new SystemMessagesApi(
      configuration,
      configuration.basePath,
      axiosInstance
    )
  }

  public GetSystemMessagesForUser = async (
    options?: AxiosRequestConfig
  ): Promise<SystemMessage[]> => {
    try {
      const response = await this.systemMessagesApi.apiSystemMessagesGet(
        options
      )

      const systemMessages: SystemMessage[] = []

      for (const systemMessage of response.data.systemMessages ?? []) {
        systemMessages.push(mapResponseToSystemMessage(systemMessage))
      }

      systemMessages.reverse() // Sort on the "latest" message first

      return systemMessages
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetUnreadSystemMessagesForUser = async (
    options?: AxiosRequestConfig
  ): Promise<SystemMessage[]> => {
    try {
      const response = await this.systemMessagesApi.apiSystemMessagesUnreadGet(
        options
      )

      const systemMessages: SystemMessage[] = []

      for (const systemMessage of response.data.systemMessages ?? []) {
        systemMessages.push(mapResponseToSystemMessage(systemMessage))
      }

      systemMessages.reverse() // Sort on the "latest" message first

      return systemMessages
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public ReadSystemMessageIdForUser = async (
    messageId: SystemMessage['id'],
    options?: AxiosRequestConfig
  ): Promise<void> => {
    try {
      await this.systemMessagesApi.apiSystemMessagesReadSystemMessageIdPut(
        messageId,
        options
      )
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetSystemMessage = async (
    messageId: SystemMessage['id'],
    options?: AxiosRequestConfig
  ): Promise<SystemMessage> => {
    try {
      const response =
        await this.systemMessagesApi.apiSystemMessagesSystemMessageIdGet(
          messageId,
          options
        )

      return mapResponseToSystemMessage(response.data)
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public UpdateSystemMessage = async (
    systemMessage: SystemMessage,
    options?: AxiosRequestConfig
  ): Promise<void> => {
    try {
      await this.systemMessagesApi.apiSystemMessagesPut(
        {
          systemMessageAuthor: systemMessage.author,
          systemMessageText: systemMessage.text,
          systemMessageId: systemMessage.id,
          systemMessageTitle: systemMessage.title
        },
        options
      )
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public DeleteSystemMessage = async (
    systemMessageId: SystemMessage['id'],
    options?: AxiosRequestConfig
  ): Promise<void> => {
    try {
      await this.systemMessagesApi.apiSystemMessagesSystemMessageIdDelete(
        systemMessageId,
        options
      )
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public CreateSystemMessage = async (
    data: CreateSystemMessageData,
    options?: AxiosRequestConfig
  ): Promise<void> => {
    try {
      this.systemMessagesApi.apiSystemMessagesPost(
        {
          systemMessageAuthor: data.author,
          systemMessageText: data.text,
          systemMessageTitle: data.title
        },
        options
      )
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }
}
