import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import invariant from 'tiny-invariant'

import { useSddsTableRowClickWithCellValue } from '@cdab/scania/qpr/hooks'
import type { Deviation } from '@cdab/scania/qpr/schema'
import { DataTable, HeaderCell } from '@cdab/scania/sdds'
import { formatISODate } from '@cdab/utils'

type DeviationTableProps = {
  deviations: Deviation[]
  onClickDeviation: (guid: Deviation['clientGuid']) => void
}

function usePrepareTableData(data: Deviation[]) {
  return data.map(deviation => {
    if (deviation.deviationWithoutActions) {
      return {
        expirationDate: '',
        approvalDate: '',
        deviation: deviation.deviation,
        responsible: '',
        proposedActions: '(no action plan)',
        guid: deviation.clientGuid
      }
    }

    invariant(
      deviation.expirationDate,
      'A deviation with action plan needs an expiration date'
    )

    return {
      expirationDate: formatISODate(deviation.expirationDate),
      approvalDate: deviation.approvalDate
        ? formatISODate(deviation.approvalDate)
        : '',
      deviation: deviation.deviation,
      responsible: deviation.responsible,
      proposedActions: deviation.proposedActions,
      guid: deviation.clientGuid
    }
  })
}

export function DeviationTable(props: DeviationTableProps) {
  const { deviations, onClickDeviation } = props
  const { t } = useTranslation('common')
  const [tableEl, setTableEl] = useState<HTMLTableRowElement>()

  useSddsTableRowClickWithCellValue(tableEl, 'guid', deviationGuid => {
    onClickDeviation(deviationGuid)
  })

  const tableData = usePrepareTableData(deviations)

  return (
    <DataTable
      ref={setTableEl}
      data={tableData}
      noMinWidth
      whiteBackground
      scroll
    >
      <HeaderCell
        sortable
        columnKey='expirationDate'
        title={t('expiry-date')}
      />
      <HeaderCell
        sortable
        columnKey='approvalDate'
        title={t('approval-date')}
      />
      <HeaderCell sortable columnKey='deviation' title='Description' />
      <HeaderCell
        sortable
        columnKey='responsible'
        title={t('responsible', { ns: 'audit' })}
      />
      <HeaderCell
        sortable
        columnKey='proposedActions'
        title={t('proposed-actions')}
      />
      <HeaderCell columnKey='id' title={t('id')} />
    </DataTable>
  )
}
