/* tslint:disable */
/* eslint-disable */
/**
 * Scania.QPR-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ADHAuditWithUnreadMessagesDto
 */
export interface ADHAuditWithUnreadMessagesDto {
    /**
     * 
     * @type {number}
     * @memberof ADHAuditWithUnreadMessagesDto
     */
    'auditId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ADHAuditWithUnreadMessagesDto
     */
    'dealerInfo'?: string;
    /**
     * 
     * @type {Array<ADHDeviationWithUnreadMessagesDto>}
     * @memberof ADHAuditWithUnreadMessagesDto
     */
    'deviations'?: Array<ADHDeviationWithUnreadMessagesDto>;
}
/**
 * 
 * @export
 * @interface ADHAuditsWithUnreadMessagesDto
 */
export interface ADHAuditsWithUnreadMessagesDto {
    /**
     * 
     * @type {Array<ADHAuditWithUnreadMessagesDto>}
     * @memberof ADHAuditsWithUnreadMessagesDto
     */
    'audits'?: Array<ADHAuditWithUnreadMessagesDto>;
}
/**
 * 
 * @export
 * @interface ADHChatMessageCreateDto
 */
export interface ADHChatMessageCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ADHChatMessageCreateDto
     */
    'actionPlanItemId': number;
    /**
     * 
     * @type {string}
     * @memberof ADHChatMessageCreateDto
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ADHChatMessageCreateDto
     */
    'author'?: string;
    /**
     * 
     * @type {number}
     * @memberof ADHChatMessageCreateDto
     */
    'fileId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ADHChatMessageCreateDto
     */
    'isDealer': boolean;
}
/**
 * 
 * @export
 * @interface ADHChatMessageDto
 */
export interface ADHChatMessageDto {
    /**
     * 
     * @type {number}
     * @memberof ADHChatMessageDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ADHChatMessageDto
     */
    'adhChatMessageText': string;
    /**
     * 
     * @type {string}
     * @memberof ADHChatMessageDto
     */
    'adhChatMessageAuthor': string;
    /**
     * 
     * @type {string}
     * @memberof ADHChatMessageDto
     */
    'adhChatMessageCreatedTime': string;
    /**
     * 
     * @type {number}
     * @memberof ADHChatMessageDto
     */
    'chatFileId': number;
    /**
     * 
     * @type {boolean}
     * @memberof ADHChatMessageDto
     */
    'readByAuditor': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ADHChatMessageDto
     */
    'readByDealer': boolean;
    /**
     * 
     * @type {number}
     * @memberof ADHChatMessageDto
     */
    'actionPlanItemId': number;
}
/**
 * 
 * @export
 * @interface ADHDeviationWithUnreadMessagesDto
 */
export interface ADHDeviationWithUnreadMessagesDto {
    /**
     * 
     * @type {number}
     * @memberof ADHDeviationWithUnreadMessagesDto
     */
    'actionPlanItemId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ADHDeviationWithUnreadMessagesDto
     */
    'auditPointId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ADHDeviationWithUnreadMessagesDto
     */
    'auditPointNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ADHDeviationWithUnreadMessagesDto
     */
    'auditPointDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof ADHDeviationWithUnreadMessagesDto
     */
    'deviation'?: string;
    /**
     * 
     * @type {number}
     * @memberof ADHDeviationWithUnreadMessagesDto
     */
    'countOfMessages'?: number;
}
/**
 * 
 * @export
 * @interface ADHLinkActionPlanItemDto
 */
export interface ADHLinkActionPlanItemDto {
    /**
     * 
     * @type {number}
     * @memberof ADHLinkActionPlanItemDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ADHLinkActionPlanItemDto
     */
    'linkId': number;
    /**
     * 
     * @type {number}
     * @memberof ADHLinkActionPlanItemDto
     */
    'auditId': number;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkActionPlanItemDto
     */
    'auditPointNo': string;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkActionPlanItemDto
     */
    'auditPointDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ADHLinkActionPlanItemDto
     */
    'actionPlanId': number;
    /**
     * 
     * @type {number}
     * @memberof ADHLinkActionPlanItemDto
     */
    'actionPlanItemId': number;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkActionPlanItemDto
     */
    'deviation': string;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkActionPlanItemDto
     */
    'proposedActions': string;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkActionPlanItemDto
     */
    'responsible': string;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkActionPlanItemDto
     */
    'dealerInfo': string;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkActionPlanItemDto
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkActionPlanItemDto
     */
    'finishedDate': string;
    /**
     * 
     * @type {number}
     * @memberof ADHLinkActionPlanItemDto
     */
    'actionPlanItemStatus': number;
    /**
     * 
     * @type {Array<ADHChatMessageDto>}
     * @memberof ADHLinkActionPlanItemDto
     */
    'adhChatMessages': Array<ADHChatMessageDto>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ADHLinkActionPlanItemDto
     */
    'userFiles': Array<number>;
}
/**
 * 
 * @export
 * @interface ADHLinkActionlanItemCreateDto
 */
export interface ADHLinkActionlanItemCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ADHLinkActionlanItemCreateDto
     */
    'auditId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ADHLinkActionlanItemCreateDto
     */
    'actionPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ADHLinkActionlanItemCreateDto
     */
    'actionPlanItemId'?: number;
}
/**
 * 
 * @export
 * @interface ADHLinkCreateDto
 */
export interface ADHLinkCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ADHLinkCreateDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ADHLinkCreateDto
     */
    'did'?: number;
    /**
     * 
     * @type {number}
     * @memberof ADHLinkCreateDto
     */
    'auditId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkCreateDto
     */
    'key'?: string;
    /**
     * 
     * @type {number}
     * @memberof ADHLinkCreateDto
     */
    'languageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkCreateDto
     */
    'expiryDate'?: string;
    /**
     * 
     * @type {Array<ADHLinkActionlanItemCreateDto>}
     * @memberof ADHLinkCreateDto
     */
    'actionPlanItems'?: Array<ADHLinkActionlanItemCreateDto>;
}
/**
 * 
 * @export
 * @interface ADHLinkDto
 */
export interface ADHLinkDto {
    /**
     * 
     * @type {number}
     * @memberof ADHLinkDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkDto
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkDto
     */
    'expiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ADHLinkDto
     */
    'dealerName'?: string;
    /**
     * 
     * @type {Array<ADHLinkActionPlanItemDto>}
     * @memberof ADHLinkDto
     */
    'adhLinkActionPlanItems'?: Array<ADHLinkActionPlanItemDto>;
}
/**
 * 
 * @export
 * @interface ADHLinksDto
 */
export interface ADHLinksDto {
    /**
     * 
     * @type {Array<ADHLinkDto>}
     * @memberof ADHLinksDto
     */
    'keys'?: Array<ADHLinkDto>;
}
/**
 * 
 * @export
 * @interface AdminTranslationsDto
 */
export interface AdminTranslationsDto {
    /**
     * 
     * @type {Array<AuditPointTranslation>}
     * @memberof AdminTranslationsDto
     */
    'auditPointsTranslations'?: Array<AuditPointTranslation>;
    /**
     * 
     * @type {Array<CheckPointTranslation>}
     * @memberof AdminTranslationsDto
     */
    'checkPointsTranslations'?: Array<CheckPointTranslation>;
    /**
     * 
     * @type {Array<PledgePerspectiveTranslation>}
     * @memberof AdminTranslationsDto
     */
    'pledgePerspectivesTranslations'?: Array<PledgePerspectiveTranslation>;
    /**
     * 
     * @type {Array<PledgeTranslation>}
     * @memberof AdminTranslationsDto
     */
    'pledgesTranslations'?: Array<PledgeTranslation>;
}
/**
 * 
 * @export
 * @interface Area
 */
export interface Area {
    /**
     * 
     * @type {number}
     * @memberof Area
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AreasDto
 */
export interface AreasDto {
    /**
     * 
     * @type {Array<Area>}
     * @memberof AreasDto
     */
    'areas'?: Array<Area>;
}
/**
 * 
 * @export
 * @interface AreasDtoObjectResultEnvelope
 */
export interface AreasDtoObjectResultEnvelope {
    /**
     * 
     * @type {AreasDto}
     * @memberof AreasDtoObjectResultEnvelope
     */
    'data': AreasDto;
    /**
     * 
     * @type {number}
     * @memberof AreasDtoObjectResultEnvelope
     */
    'timeSpentOnServer_ms': number;
}
/**
 * 
 * @export
 * @interface Audit
 */
export interface Audit {
    /**
     * 
     * @type {number}
     * @memberof Audit
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'auditDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Audit
     */
    'isCertificationAudit'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'dosVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'auditDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'auditor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'extraAuditors'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Audit
     */
    'hasNotes'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Audit
     */
    'hasDeviations'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    'auditTypeDescription'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AuditCertifyErrorCode {
    NoError = 'NoError',
    InvalidAudit = 'InvalidAudit',
    AuditIsArchived = 'AuditIsArchived',
    DealerIsInvalid = 'DealerIsInvalid',
    UnfilledDevelopmentPoints = 'UnfilledDevelopmentPoints',
    UnfulfilledMandatoryPoints = 'UnfulfilledMandatoryPoints',
    UnfulfilledDeviations = 'UnfulfilledDeviations',
    NotValidAuditType = 'NotValidAuditType'
}

/**
 * 
 * @export
 * @interface AuditCertifyErrorCodeObjectResultEnvelope
 */
export interface AuditCertifyErrorCodeObjectResultEnvelope {
    /**
     * 
     * @type {AuditCertifyErrorCode}
     * @memberof AuditCertifyErrorCodeObjectResultEnvelope
     */
    'data': AuditCertifyErrorCode;
    /**
     * 
     * @type {number}
     * @memberof AuditCertifyErrorCodeObjectResultEnvelope
     */
    'timeSpentOnServer_ms': number;
}
/**
 * 
 * @export
 * @interface AuditCompleteDto
 */
export interface AuditCompleteDto {
    /**
     * 
     * @type {number}
     * @memberof AuditCompleteDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditCompleteDto
     */
    'auditDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuditCompleteDto
     */
    'isCertified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuditCompleteDto
     */
    'isArchived'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuditCompleteDto
     */
    'isReadonly'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuditCompleteDto
     */
    'auditDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditCompleteDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditCompleteDto
     */
    'extraAuditors'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuditCompleteDto
     */
    'totalNumberOfMandatoryPoints'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditCompleteDto
     */
    'dosVersion'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditCompleteDto
     */
    'did'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditCompleteDto
     */
    'auditTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditCompleteDto
     */
    'achievedMandatoryPoints'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditCompleteDto
     */
    'totalNumberOfDevelopmentPoints'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditCompleteDto
     */
    'achievedDevelopmentPoints'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditCompleteDto
     */
    'dosScoring'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditCompleteDto
     */
    'accreditedLength'?: number;
    /**
     * 
     * @type {Array<PledgeArea>}
     * @memberof AuditCompleteDto
     */
    'pledgeAreas'?: Array<PledgeArea>;
    /**
     * 
     * @type {Array<Pledge>}
     * @memberof AuditCompleteDto
     */
    'pledges'?: Array<Pledge>;
    /**
     * 
     * @type {Array<PledgePerspective>}
     * @memberof AuditCompleteDto
     */
    'pledgePerspectives'?: Array<PledgePerspective>;
    /**
     * 
     * @type {Array<AuditPoint>}
     * @memberof AuditCompleteDto
     */
    'auditPoints'?: Array<AuditPoint>;
    /**
     * 
     * @type {Array<CheckPoint>}
     * @memberof AuditCompleteDto
     */
    'checkPoints'?: Array<CheckPoint>;
    /**
     * 
     * @type {Array<ReferenceDocument>}
     * @memberof AuditCompleteDto
     */
    'referenceDocuments'?: Array<ReferenceDocument>;
    /**
     * 
     * @type {Array<DeviationClass>}
     * @memberof AuditCompleteDto
     */
    'deviations'?: Array<DeviationClass>;
    /**
     * 
     * @type {number}
     * @memberof AuditCompleteDto
     */
    'timeSpentOnServer_ms'?: number;
}
/**
 * 
 * @export
 * @interface AuditCreateDto
 */
export interface AuditCreateDto {
    /**
     * 
     * @type {number}
     * @memberof AuditCreateDto
     */
    'did': number;
    /**
     * 
     * @type {string}
     * @memberof AuditCreateDto
     */
    'auditDate': string;
    /**
     * 
     * @type {string}
     * @memberof AuditCreateDto
     */
    'auditDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuditCreateDto
     */
    'userId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AuditCreateDto
     */
    'dosversion': number;
    /**
     * 
     * @type {string}
     * @memberof AuditCreateDto
     */
    'extraAuditors'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuditCreateDto
     */
    'isRemoteAudit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AuditCreateDto
     */
    'auditTypeId'?: number;
}
/**
 * 
 * @export
 * @interface AuditPoint
 */
export interface AuditPoint {
    /**
     * 
     * @type {number}
     * @memberof AuditPoint
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditPoint
     */
    'translationGuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuditPoint
     */
    'isPointCheckedYes'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuditPoint
     */
    'isMandatory'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AuditPoint
     */
    'pledgeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditPoint
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditPoint
     */
    'auditPointNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditPoint
     */
    'purposeTranslationGuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuditPoint
     */
    'score'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AuditPoint
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface AuditPointIdAndNumberDto
 */
export interface AuditPointIdAndNumberDto {
    /**
     * 
     * @type {number}
     * @memberof AuditPointIdAndNumberDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditPointIdAndNumberDto
     */
    'auditPointNo'?: string;
}
/**
 * 
 * @export
 * @interface AuditPointIdAndNumberDtoListObjectResultEnvelope
 */
export interface AuditPointIdAndNumberDtoListObjectResultEnvelope {
    /**
     * 
     * @type {Array<AuditPointIdAndNumberDto>}
     * @memberof AuditPointIdAndNumberDtoListObjectResultEnvelope
     */
    'data': Array<AuditPointIdAndNumberDto>;
    /**
     * 
     * @type {number}
     * @memberof AuditPointIdAndNumberDtoListObjectResultEnvelope
     */
    'timeSpentOnServer_ms': number;
}
/**
 * 
 * @export
 * @interface AuditPointTranslation
 */
export interface AuditPointTranslation {
    /**
     * 
     * @type {number}
     * @memberof AuditPointTranslation
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AuditPointTranslation
     */
    'auditPointNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditPointTranslation
     */
    'defaultDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditPointTranslation
     */
    'translatedDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditPointTranslation
     */
    'translationGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditPointTranslation
     */
    'purposeTranslationGuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuditPointTranslation
     */
    'purposeTranslationId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AuditPointTranslation
     */
    'purposeDefaultDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditPointTranslation
     */
    'purposeTranslatedDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditPointTranslation
     */
    'md'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditPointTranslation
     */
    'dealerWorkshopSales'?: string;
}
/**
 * 
 * @export
 * @interface AuditPointUpdateDto
 */
export interface AuditPointUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof AuditPointUpdateDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AuditPointUpdateDto
     */
    'auditId': number;
    /**
     * 
     * @type {boolean}
     * @memberof AuditPointUpdateDto
     */
    'isPointCheckedYes'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AuditPointUpdateDto
     */
    'callIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof AuditPointUpdateDto
     */
    'created': string;
}
/**
 * 
 * @export
 * @interface AuditPointsTranslationsDto
 */
export interface AuditPointsTranslationsDto {
    /**
     * 
     * @type {Array<AuditPointTranslation>}
     * @memberof AuditPointsTranslationsDto
     */
    'auditPointTranslations'?: Array<AuditPointTranslation>;
    /**
     * 
     * @type {number}
     * @memberof AuditPointsTranslationsDto
     */
    'timeSpentOnServer_ms'?: number;
}
/**
 * 
 * @export
 * @interface AuditTypeDto
 */
export interface AuditTypeDto {
    /**
     * 
     * @type {number}
     * @memberof AuditTypeDto
     */
    'auditTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditTypeDto
     */
    'auditTypeDescription'?: string;
}
/**
 * 
 * @export
 * @interface AuditTypesDto
 */
export interface AuditTypesDto {
    /**
     * 
     * @type {Array<AuditTypeDto>}
     * @memberof AuditTypesDto
     */
    'auditTypes'?: Array<AuditTypeDto>;
}
/**
 * 
 * @export
 * @interface AuditUpdateDto
 */
export interface AuditUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof AuditUpdateDto
     */
    'auditDate': string;
    /**
     * 
     * @type {string}
     * @memberof AuditUpdateDto
     */
    'auditDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditUpdateDto
     */
    'extraAuditors'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuditUpdateDto
     */
    'auditTypeId'?: number;
}
/**
 * 
 * @export
 * @interface AvailableAuditsDto
 */
export interface AvailableAuditsDto {
    /**
     * 
     * @type {Array<Audit>}
     * @memberof AvailableAuditsDto
     */
    'audits'?: Array<Audit>;
    /**
     * 
     * @type {number}
     * @memberof AvailableAuditsDto
     */
    'timeSpentOnServer_ms'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CertificationStatus {
    Certified = 'Certified',
    Expired = 'Expired',
    NeverCertified = 'NeverCertified'
}

/**
 * 
 * @export
 * @interface CheckPoint
 */
export interface CheckPoint {
    /**
     * 
     * @type {number}
     * @memberof CheckPoint
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckPoint
     */
    'translationGuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckPoint
     */
    'auditPointId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckPoint
     */
    'checkPointNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckPoint
     */
    'note'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CheckPoint
     */
    'isPointCheckedYes'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof CheckPoint
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CheckPoint
     */
    'score'?: boolean | null;
}
/**
 * 
 * @export
 * @interface CheckPointIdAndNumberDto
 */
export interface CheckPointIdAndNumberDto {
    /**
     * 
     * @type {number}
     * @memberof CheckPointIdAndNumberDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckPointIdAndNumberDto
     */
    'checkPointNo'?: string;
}
/**
 * 
 * @export
 * @interface CheckPointTranslation
 */
export interface CheckPointTranslation {
    /**
     * 
     * @type {number}
     * @memberof CheckPointTranslation
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CheckPointTranslation
     */
    'checkPointNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckPointTranslation
     */
    'defaultDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckPointTranslation
     */
    'translatedDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckPointTranslation
     */
    'translationGuid'?: string;
}
/**
 * 
 * @export
 * @interface CheckPointUpdateDto
 */
export interface CheckPointUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CheckPointUpdateDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CheckPointUpdateDto
     */
    'auditId': number;
    /**
     * 
     * @type {boolean}
     * @memberof CheckPointUpdateDto
     */
    'isPointCheckedYes': boolean;
    /**
     * 
     * @type {string}
     * @memberof CheckPointUpdateDto
     */
    'callIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof CheckPointUpdateDto
     */
    'created': string;
}
/**
 * 
 * @export
 * @interface CheckPointsTranslationsDto
 */
export interface CheckPointsTranslationsDto {
    /**
     * 
     * @type {Array<CheckPointTranslation>}
     * @memberof CheckPointsTranslationsDto
     */
    'checkPointTranslations'?: Array<CheckPointTranslation>;
    /**
     * 
     * @type {number}
     * @memberof CheckPointsTranslationsDto
     */
    'timeSpentOnServer_ms'?: number;
}
/**
 * 
 * @export
 * @interface CheckpointCreateDto
 */
export interface CheckpointCreateDto {
    /**
     * 
     * @type {number}
     * @memberof CheckpointCreateDto
     */
    'auditPointId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckpointCreateDto
     */
    'dosVersionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckpointCreateDto
     */
    'baseTranslation'?: string;
}
/**
 * 
 * @export
 * @interface DOSVersion
 */
export interface DOSVersion {
    /**
     * 
     * @type {number}
     * @memberof DOSVersion
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof DOSVersion
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DOSVersionsDto
 */
export interface DOSVersionsDto {
    /**
     * 
     * @type {Array<DOSVersion>}
     * @memberof DOSVersionsDto
     */
    'dosVersions'?: Array<DOSVersion>;
}
/**
 * 
 * @export
 * @interface DealerDto
 */
export interface DealerDto {
    /**
     * 
     * @type {number}
     * @memberof DealerDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DealerDto
     */
    'dealerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DealerDto
     */
    'dealerName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DealerDto
     */
    'truck'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerDto
     */
    'bus'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerDto
     */
    'engine'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerDto
     */
    'truckSales'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DealerDto
     */
    'busSales'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DealerDto
     */
    'engineSales'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DealerDto
     */
    'partsSales'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DealerDto
     */
    'dealerCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof DealerDto
     */
    'auditDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DealerDto
     */
    'expiryDate'?: string | null;
    /**
     * 
     * @type {CertificationStatus}
     * @memberof DealerDto
     */
    'certificationStatus'?: CertificationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof DealerDto
     */
    'hasDeviations'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DealerDto
     */
    'marketId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DealerDto
     */
    'marketName'?: string;
}
/**
 * 
 * @export
 * @interface DealerDtoICollectionObjectResultEnvelope
 */
export interface DealerDtoICollectionObjectResultEnvelope {
    /**
     * 
     * @type {Array<DealerDto>}
     * @memberof DealerDtoICollectionObjectResultEnvelope
     */
    'data': Array<DealerDto>;
    /**
     * 
     * @type {number}
     * @memberof DealerDtoICollectionObjectResultEnvelope
     */
    'timeSpentOnServer_ms': number;
}
/**
 * 
 * @export
 * @interface DealerFileDto
 */
export interface DealerFileDto {
    /**
     * 
     * @type {number}
     * @memberof DealerFileDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DealerFileDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof DealerFileDto
     */
    'fileName': string;
    /**
     * 
     * @type {boolean}
     * @memberof DealerFileDto
     */
    'isImage': boolean;
}
/**
 * 
 * @export
 * @interface DealersDto
 */
export interface DealersDto {
    /**
     * 
     * @type {Array<DealerDto>}
     * @memberof DealersDto
     */
    'dealers'?: Array<DealerDto>;
    /**
     * 
     * @type {number}
     * @memberof DealersDto
     */
    'timeSpentOnServer_ms'?: number;
}
/**
 * 
 * @export
 * @interface Deviation
 */
export interface Deviation {
    /**
     * 
     * @type {string}
     * @memberof Deviation
     */
    'auditDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Deviation
     */
    'auditId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Deviation
     */
    'dealerCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deviation
     */
    'dealerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Deviation
     */
    'expiryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Deviation
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Deviation
     */
    'hasDeviations'?: boolean;
}
/**
 * 
 * @export
 * @interface DeviationClass
 */
export interface DeviationClass {
    /**
     * 
     * @type {number}
     * @memberof DeviationClass
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviationClass
     */
    'auditId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviationClass
     */
    'deviation'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviationClass
     */
    'proposedActions'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviationClass
     */
    'responsible'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviationClass
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviationClass
     */
    'approvalDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviationClass
     */
    'auditPointId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeviationClass
     */
    'deviationWithoutActions'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DeviationClass
     */
    'auditPointNo'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviationClass
     */
    'actionPlanId'?: number;
}
/**
 * 
 * @export
 * @interface DeviationCreateDto
 */
export interface DeviationCreateDto {
    /**
     * 
     * @type {number}
     * @memberof DeviationCreateDto
     */
    'auditId': number;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateDto
     */
    'deviation': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateDto
     */
    'proposedActions': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateDto
     */
    'responsible': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateDto
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateDto
     */
    'approvalDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviationCreateDto
     */
    'auditPointId': number;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateDto
     */
    'callIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateDto
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateDto
     */
    'auditPointNumber': string;
}
/**
 * 
 * @export
 * @interface DeviationCreateResponseDto
 */
export interface DeviationCreateResponseDto {
    /**
     * 
     * @type {number}
     * @memberof DeviationCreateResponseDto
     */
    'auditId': number;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateResponseDto
     */
    'deviation': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateResponseDto
     */
    'proposedActions': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateResponseDto
     */
    'responsible': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateResponseDto
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateResponseDto
     */
    'approvalDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviationCreateResponseDto
     */
    'auditPointId': number;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateResponseDto
     */
    'callIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateResponseDto
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationCreateResponseDto
     */
    'auditPointNumber': string;
    /**
     * 
     * @type {number}
     * @memberof DeviationCreateResponseDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeviationCreateResponseDto
     */
    'actionPlanId': number;
}
/**
 * 
 * @export
 * @interface DeviationDeleteDto
 */
export interface DeviationDeleteDto {
    /**
     * 
     * @type {number}
     * @memberof DeviationDeleteDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeviationDeleteDto
     */
    'auditId': number;
    /**
     * 
     * @type {string}
     * @memberof DeviationDeleteDto
     */
    'callIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationDeleteDto
     */
    'created': string;
}
/**
 * 
 * @export
 * @interface DeviationUpdateDto
 */
export interface DeviationUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof DeviationUpdateDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeviationUpdateDto
     */
    'auditPointId': number;
    /**
     * 
     * @type {string}
     * @memberof DeviationUpdateDto
     */
    'auditPointNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationUpdateDto
     */
    'deviation': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationUpdateDto
     */
    'proposedActions': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationUpdateDto
     */
    'responsible': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationUpdateDto
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationUpdateDto
     */
    'approvalDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviationUpdateDto
     */
    'callIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationUpdateDto
     */
    'created': string;
    /**
     * 
     * @type {number}
     * @memberof DeviationUpdateDto
     */
    'auditId': number;
}
/**
 * 
 * @export
 * @interface DeviationWithoutActionsCreateDto
 */
export interface DeviationWithoutActionsCreateDto {
    /**
     * 
     * @type {number}
     * @memberof DeviationWithoutActionsCreateDto
     */
    'auditId': number;
    /**
     * 
     * @type {number}
     * @memberof DeviationWithoutActionsCreateDto
     */
    'auditPointId': number;
    /**
     * 
     * @type {string}
     * @memberof DeviationWithoutActionsCreateDto
     */
    'deviation': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationWithoutActionsCreateDto
     */
    'callIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationWithoutActionsCreateDto
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationWithoutActionsCreateDto
     */
    'auditPointNumber': string;
}
/**
 * 
 * @export
 * @interface DeviationWithoutActionsCreateResponseDto
 */
export interface DeviationWithoutActionsCreateResponseDto {
    /**
     * 
     * @type {number}
     * @memberof DeviationWithoutActionsCreateResponseDto
     */
    'auditId': number;
    /**
     * 
     * @type {number}
     * @memberof DeviationWithoutActionsCreateResponseDto
     */
    'auditPointId': number;
    /**
     * 
     * @type {string}
     * @memberof DeviationWithoutActionsCreateResponseDto
     */
    'deviation': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationWithoutActionsCreateResponseDto
     */
    'callIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationWithoutActionsCreateResponseDto
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationWithoutActionsCreateResponseDto
     */
    'auditPointNumber': string;
    /**
     * 
     * @type {number}
     * @memberof DeviationWithoutActionsCreateResponseDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeviationWithoutActionsCreateResponseDto
     */
    'actionPlanId': number;
}
/**
 * 
 * @export
 * @interface DeviationWithoutActionsUpdateDto
 */
export interface DeviationWithoutActionsUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof DeviationWithoutActionsUpdateDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeviationWithoutActionsUpdateDto
     */
    'auditPointId': number;
    /**
     * 
     * @type {string}
     * @memberof DeviationWithoutActionsUpdateDto
     */
    'auditPointNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationWithoutActionsUpdateDto
     */
    'deviation': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationWithoutActionsUpdateDto
     */
    'callIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof DeviationWithoutActionsUpdateDto
     */
    'created': string;
    /**
     * 
     * @type {number}
     * @memberof DeviationWithoutActionsUpdateDto
     */
    'auditId': number;
}
/**
 * 
 * @export
 * @interface DeviationsDto
 */
export interface DeviationsDto {
    /**
     * 
     * @type {Array<DeviationClass>}
     * @memberof DeviationsDto
     */
    'deviations'?: Array<DeviationClass>;
    /**
     * 
     * @type {number}
     * @memberof DeviationsDto
     */
    'timeSpentOnServer_ms'?: number;
}
/**
 * 
 * @export
 * @interface DeviationsExpiringDto
 */
export interface DeviationsExpiringDto {
    /**
     * 
     * @type {Array<Deviation>}
     * @memberof DeviationsExpiringDto
     */
    'deviations'?: Array<Deviation>;
    /**
     * 
     * @type {number}
     * @memberof DeviationsExpiringDto
     */
    'timeSpentOnServer_ms'?: number;
}
/**
 * 
 * @export
 * @interface DocumentCreateDto
 */
export interface DocumentCreateDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof DocumentCreateDto
     */
    'auditPointsIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DocumentCreateDto
     */
    'checkPointsIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DocumentCreateDto
     */
    'marketId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentCreateDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCreateDto
     */
    'url'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentCreateDto
     */
    'documentFileId'?: number | null;
}
/**
 * 
 * @export
 * @interface DocumentDto
 */
export interface DocumentDto {
    /**
     * 
     * @type {number}
     * @memberof DocumentDto
     */
    'id'?: number;
    /**
     * 
     * @type {Array<AuditPointIdAndNumberDto>}
     * @memberof DocumentDto
     */
    'auditPoints'?: Array<AuditPointIdAndNumberDto>;
    /**
     * 
     * @type {Array<CheckPointIdAndNumberDto>}
     * @memberof DocumentDto
     */
    'checkPoints'?: Array<CheckPointIdAndNumberDto>;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'title'?: string;
    /**
     * 
     * @type {MarketIdAndNameDto}
     * @memberof DocumentDto
     */
    'market'?: MarketIdAndNameDto;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'url'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentDto
     */
    'documentVersion'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentDto
     */
    'documentFileId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'physicalDocumentFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'updatedTime'?: string;
}
/**
 * 
 * @export
 * @interface DocumentFileDto
 */
export interface DocumentFileDto {
    /**
     * 
     * @type {number}
     * @memberof DocumentFileDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DocumentFileDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentFileDto
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface DocumentUpdateDto
 */
export interface DocumentUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof DocumentUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DocumentUpdateDto
     */
    'auditPointsIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DocumentUpdateDto
     */
    'checkPointsIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DocumentUpdateDto
     */
    'marketId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'url'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentUpdateDto
     */
    'documentFileId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentUpdateDto
     */
    'documentVersion'?: number;
}
/**
 * 
 * @export
 * @interface DocumentsDto
 */
export interface DocumentsDto {
    /**
     * 
     * @type {Array<DocumentDto>}
     * @memberof DocumentsDto
     */
    'documents'?: Array<DocumentDto>;
    /**
     * 
     * @type {number}
     * @memberof DocumentsDto
     */
    'timeSpentOnServer_ms'?: number;
}
/**
 * 
 * @export
 * @interface FavoriteAuditDto
 */
export interface FavoriteAuditDto {
    /**
     * 
     * @type {string}
     * @memberof FavoriteAuditDto
     */
    'auditDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FavoriteAuditDto
     */
    'auditDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof FavoriteAuditDto
     */
    'auditId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FavoriteAuditDto
     */
    'auditor'?: string;
    /**
     * 
     * @type {string}
     * @memberof FavoriteAuditDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof FavoriteAuditDto
     */
    'dealerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FavoriteAuditDto
     */
    'dealerCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof FavoriteAuditDto
     */
    'expiryDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FavoriteAuditDto
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FavoriteAuditDto
     */
    'isCertified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FavoriteAuditDto
     */
    'userId'?: number;
}
/**
 * 
 * @export
 * @interface FavoriteAuditSingleDto
 */
export interface FavoriteAuditSingleDto {
    /**
     * 
     * @type {number}
     * @memberof FavoriteAuditSingleDto
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FavoriteAuditSingleDto
     */
    'auditId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FavoriteAuditSingleDto
     */
    'createdTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FavoriteAuditSingleDto
     */
    'userId'?: number | null;
}
/**
 * 
 * @export
 * @interface FavoritesAuditsCreateDto
 */
export interface FavoritesAuditsCreateDto {
    /**
     * 
     * @type {number}
     * @memberof FavoritesAuditsCreateDto
     */
    'auditId': number;
    /**
     * 
     * @type {string}
     * @memberof FavoritesAuditsCreateDto
     */
    'createdTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FavoritesAuditsCreateDto
     */
    'userId'?: number;
}
/**
 * 
 * @export
 * @interface FavoritesAuditsDto
 */
export interface FavoritesAuditsDto {
    /**
     * 
     * @type {Array<FavoriteAuditDto>}
     * @memberof FavoritesAuditsDto
     */
    'favoritesAudits'?: Array<FavoriteAuditDto>;
    /**
     * 
     * @type {number}
     * @memberof FavoritesAuditsDto
     */
    'timeSpentOnServer_ms'?: number;
}
/**
 * 
 * @export
 * @interface Language
 */
export interface Language {
    /**
     * 
     * @type {number}
     * @memberof Language
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface LanguagesDto
 */
export interface LanguagesDto {
    /**
     * 
     * @type {Array<Language>}
     * @memberof LanguagesDto
     */
    'languages'?: Array<Language>;
}
/**
 * 
 * @export
 * @interface ListenToAuditDto
 */
export interface ListenToAuditDto {
    /**
     * 
     * @type {number}
     * @memberof ListenToAuditDto
     */
    'auditId': number;
}
/**
 * 
 * @export
 * @interface Market
 */
export interface Market {
    /**
     * 
     * @type {number}
     * @memberof Market
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Market
     */
    'marketName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Market
     */
    'countryCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Market
     */
    'isMainMarket'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Market
     */
    'areaId'?: number | null;
}
/**
 * 
 * @export
 * @interface MarketIdAndNameDto
 */
export interface MarketIdAndNameDto {
    /**
     * 
     * @type {number}
     * @memberof MarketIdAndNameDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketIdAndNameDto
     */
    'marketName'?: string;
}
/**
 * 
 * @export
 * @interface MarketsDto
 */
export interface MarketsDto {
    /**
     * 
     * @type {Array<Market>}
     * @memberof MarketsDto
     */
    'markets'?: Array<Market>;
}
/**
 * 
 * @export
 * @interface MarketsDtoObjectResultEnvelope
 */
export interface MarketsDtoObjectResultEnvelope {
    /**
     * 
     * @type {MarketsDto}
     * @memberof MarketsDtoObjectResultEnvelope
     */
    'data': MarketsDto;
    /**
     * 
     * @type {number}
     * @memberof MarketsDtoObjectResultEnvelope
     */
    'timeSpentOnServer_ms': number;
}
/**
 * 
 * @export
 * @interface NoteCreateUpdateDto
 */
export interface NoteCreateUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof NoteCreateUpdateDto
     */
    'auditId': number;
    /**
     * 
     * @type {number}
     * @memberof NoteCreateUpdateDto
     */
    'pledgeId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NoteCreateUpdateDto
     */
    'auditPointId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NoteCreateUpdateDto
     */
    'checkPointId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NoteCreateUpdateDto
     */
    'note': string;
    /**
     * 
     * @type {string}
     * @memberof NoteCreateUpdateDto
     */
    'callIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof NoteCreateUpdateDto
     */
    'created': string;
}
/**
 * 
 * @export
 * @interface NoteDeleteDto
 */
export interface NoteDeleteDto {
    /**
     * 
     * @type {number}
     * @memberof NoteDeleteDto
     */
    'auditId': number;
    /**
     * 
     * @type {number}
     * @memberof NoteDeleteDto
     */
    'pledgeId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NoteDeleteDto
     */
    'auditPointId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NoteDeleteDto
     */
    'checkPointId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NoteDeleteDto
     */
    'callIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof NoteDeleteDto
     */
    'created': string;
}
/**
 * 
 * @export
 * @interface Pledge
 */
export interface Pledge {
    /**
     * 
     * @type {number}
     * @memberof Pledge
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Pledge
     */
    'translationGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pledge
     */
    'pledgeNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pledge
     */
    'note'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Pledge
     */
    'pledgeAreaId'?: number | null;
}
/**
 * 
 * @export
 * @interface PledgeArea
 */
export interface PledgeArea {
    /**
     * 
     * @type {number}
     * @memberof PledgeArea
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PledgeArea
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface PledgePerspective
 */
export interface PledgePerspective {
    /**
     * 
     * @type {number}
     * @memberof PledgePerspective
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PledgePerspective
     */
    'translationGuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PledgePerspective
     */
    'pledgeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PledgePerspective
     */
    'perspectiveType'?: number;
}
/**
 * 
 * @export
 * @interface PledgePerspectiveTranslation
 */
export interface PledgePerspectiveTranslation {
    /**
     * 
     * @type {number}
     * @memberof PledgePerspectiveTranslation
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PledgePerspectiveTranslation
     */
    'pledgeNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof PledgePerspectiveTranslation
     */
    'perspectiveType'?: string;
    /**
     * 
     * @type {string}
     * @memberof PledgePerspectiveTranslation
     */
    'defaultDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof PledgePerspectiveTranslation
     */
    'translatedDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof PledgePerspectiveTranslation
     */
    'translationGuid'?: string;
}
/**
 * 
 * @export
 * @interface PledgePerspectivesTranslationsDto
 */
export interface PledgePerspectivesTranslationsDto {
    /**
     * 
     * @type {Array<PledgePerspectiveTranslation>}
     * @memberof PledgePerspectivesTranslationsDto
     */
    'pledgePerspectiveTranslations'?: Array<PledgePerspectiveTranslation>;
    /**
     * 
     * @type {number}
     * @memberof PledgePerspectivesTranslationsDto
     */
    'timeSpentOnServer_ms'?: number;
}
/**
 * 
 * @export
 * @interface PledgeTranslation
 */
export interface PledgeTranslation {
    /**
     * 
     * @type {number}
     * @memberof PledgeTranslation
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PledgeTranslation
     */
    'pledgeNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof PledgeTranslation
     */
    'defaultDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof PledgeTranslation
     */
    'translatedDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof PledgeTranslation
     */
    'translationGuid'?: string;
}
/**
 * 
 * @export
 * @interface PledgesTranslationsDto
 */
export interface PledgesTranslationsDto {
    /**
     * 
     * @type {Array<PledgeTranslation>}
     * @memberof PledgesTranslationsDto
     */
    'pledgeTranslations'?: Array<PledgeTranslation>;
    /**
     * 
     * @type {number}
     * @memberof PledgesTranslationsDto
     */
    'timeSpentOnServer_ms'?: number;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ReferenceDocument
 */
export interface ReferenceDocument {
    /**
     * 
     * @type {number}
     * @memberof ReferenceDocument
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceDocument
     */
    'auditPointId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceDocument
     */
    'checkPointId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReferenceDocument
     */
    'logicalDocumentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceDocument
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceDocument
     */
    'documentVersion'?: number;
}
/**
 * 
 * @export
 * @interface SISDealerInfoDto
 */
export interface SISDealerInfoDto {
    /**
     * 
     * @type {string}
     * @memberof SISDealerInfoDto
     */
    'dealerID'?: string;
    /**
     * 
     * @type {string}
     * @memberof SISDealerInfoDto
     */
    'marketID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SISDealerInfoDto
     */
    'certified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SISDealerInfoDto
     */
    'lastCertificationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SISDealerInfoDto
     */
    'exemptionUntil'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SISDealerInfoDto
     */
    'expiringDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SISDealerInfoDto
     */
    'actionPlan'?: boolean;
}
/**
 * 
 * @export
 * @interface SISSynchronizationDto
 */
export interface SISSynchronizationDto {
    /**
     * 
     * @type {Array<SISDealerInfoDto>}
     * @memberof SISSynchronizationDto
     */
    'sisDealerInfoSchemaList'?: Array<SISDealerInfoDto>;
}
/**
 * 
 * @export
 * @interface SetNoteDto
 */
export interface SetNoteDto {
    /**
     * 
     * @type {string}
     * @memberof SetNoteDto
     */
    'callIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof SetNoteDto
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface StopListenToAuditDto
 */
export interface StopListenToAuditDto {
    /**
     * 
     * @type {number}
     * @memberof StopListenToAuditDto
     */
    'auditId': number;
}
/**
 * 
 * @export
 * @interface SyncMarketDto
 */
export interface SyncMarketDto {
    /**
     * 
     * @type {number}
     * @memberof SyncMarketDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SyncMarketDto
     */
    'countryCode'?: string;
}
/**
 * 
 * @export
 * @interface SystemMessage
 */
export interface SystemMessage {
    /**
     * 
     * @type {number}
     * @memberof SystemMessage
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SystemMessage
     */
    'systemMessageText': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessage
     */
    'systemMessageAuthor': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessage
     */
    'systemMessageTitle': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessage
     */
    'systemMessageCreatedTime': string;
}
/**
 * 
 * @export
 * @interface SystemMessageCreateDto
 */
export interface SystemMessageCreateDto {
    /**
     * 
     * @type {string}
     * @memberof SystemMessageCreateDto
     */
    'systemMessageText': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageCreateDto
     */
    'systemMessageAuthor': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageCreateDto
     */
    'systemMessageTitle': string;
}
/**
 * 
 * @export
 * @interface SystemMessageUpdateDto
 */
export interface SystemMessageUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof SystemMessageUpdateDto
     */
    'systemMessageId': number;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageUpdateDto
     */
    'systemMessageText': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageUpdateDto
     */
    'systemMessageAuthor': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageUpdateDto
     */
    'systemMessageTitle': string;
}
/**
 * 
 * @export
 * @interface SystemMessagesDto
 */
export interface SystemMessagesDto {
    /**
     * 
     * @type {Array<SystemMessage>}
     * @memberof SystemMessagesDto
     */
    'systemMessages'?: Array<SystemMessage>;
    /**
     * 
     * @type {number}
     * @memberof SystemMessagesDto
     */
    'timeSpentOnServer_ms'?: number;
}
/**
 * 
 * @export
 * @interface TranslationCreateDto
 */
export interface TranslationCreateDto {
    /**
     * 
     * @type {string}
     * @memberof TranslationCreateDto
     */
    'translationGuid': string;
    /**
     * 
     * @type {number}
     * @memberof TranslationCreateDto
     */
    'languageId': number;
    /**
     * 
     * @type {string}
     * @memberof TranslationCreateDto
     */
    'translatedText': string;
}
/**
 * 
 * @export
 * @interface TranslationUpdateDto
 */
export interface TranslationUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof TranslationUpdateDto
     */
    'translationText': string;
}
/**
 * 
 * @export
 * @interface TranslationsDto
 */
export interface TranslationsDto {
    /**
     * 
     * @type {number}
     * @memberof TranslationsDto
     */
    'timeSpentOnServer_ms'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TranslationsDto
     */
    'translations'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface UploadDealerFileDto
 */
export interface UploadDealerFileDto {
    /**
     * 
     * @type {boolean}
     * @memberof UploadDealerFileDto
     */
    'success': boolean;
    /**
     * 
     * @type {DealerFileDto}
     * @memberof UploadDealerFileDto
     */
    'fileData': DealerFileDto;
}
/**
 * 
 * @export
 * @interface UploadFileDto
 */
export interface UploadFileDto {
    /**
     * 
     * @type {boolean}
     * @memberof UploadFileDto
     */
    'success': boolean;
    /**
     * 
     * @type {UserFileDto}
     * @memberof UploadFileDto
     */
    'fileData': UserFileDto;
}
/**
 * 
 * @export
 * @interface UserCreateDto
 */
export interface UserCreateDto {
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof UserCreateDto
     */
    'roleId': number;
    /**
     * 
     * @type {number}
     * @memberof UserCreateDto
     */
    'languageId': number;
    /**
     * 
     * @type {number}
     * @memberof UserCreateDto
     */
    'defaultView': number;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'cellPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'country'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserCreateDto
     */
    'auditReminderInterval'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'truck': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'bus': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'engine': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    'alertOnCertify': boolean;
    /**
     * 
     * @type {number}
     * @memberof UserCreateDto
     */
    'editRightId': number;
    /**
     * 
     * @type {number}
     * @memberof UserCreateDto
     */
    'defaultMarketId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserCreateDto
     */
    'markets'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'userName'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'roleId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'languageId'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserDto
     */
    'languageVersions'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'editRightId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'viewId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'cellPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'country'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'auditReminderInterval'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'truck'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'bus'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'engine'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'alertOnCertify'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'active'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'defaultMarketId'?: number | null;
    /**
     * 
     * @type {Array<UserMarket>}
     * @memberof UserDto
     */
    'markets'?: Array<UserMarket>;
}
/**
 * 
 * @export
 * @interface UserFileDto
 */
export interface UserFileDto {
    /**
     * 
     * @type {number}
     * @memberof UserFileDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserFileDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof UserFileDto
     */
    'fileName': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserFileDto
     */
    'isImage': boolean;
}
/**
 * 
 * @export
 * @interface UserMarket
 */
export interface UserMarket {
    /**
     * 
     * @type {number}
     * @memberof UserMarket
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserMarket
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserUpdateDto
 */
export interface UserUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'userName'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateDto
     */
    'roleId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateDto
     */
    'languageId'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserUpdateDto
     */
    'languageVersions'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof UserUpdateDto
     */
    'editRightId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateDto
     */
    'viewId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'cellPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'country'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateDto
     */
    'auditReminderInterval'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateDto
     */
    'truck'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateDto
     */
    'bus'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateDto
     */
    'engine'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateDto
     */
    'alertOnCertify'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateDto
     */
    'active'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateDto
     */
    'defaultMarketId'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserUpdateDto
     */
    'markets'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UsersDto
 */
export interface UsersDto {
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof UsersDto
     */
    'users'?: Array<UserDto>;
}

/**
 * ADHApi - axios parameter creator
 * @export
 */
export const ADHApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete ADH chat message.
         * @param {number} chatMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHChatMessageChatMessageIdDelete: async (chatMessageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatMessageId' is not null or undefined
            assertParamExists('apiADHChatMessageChatMessageIdDelete', 'chatMessageId', chatMessageId)
            const localVarPath = `/api/ADH/chatMessage/{chatMessageId}`
                .replace(`{${"chatMessageId"}}`, encodeURIComponent(String(chatMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves a new ADH chat message.
         * @param {ADHChatMessageCreateDto} aDHChatMessageCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHChatMessagePost: async (aDHChatMessageCreateDto: ADHChatMessageCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aDHChatMessageCreateDto' is not null or undefined
            assertParamExists('apiADHChatMessagePost', 'aDHChatMessageCreateDto', aDHChatMessageCreateDto)
            const localVarPath = `/api/ADH/chatMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aDHChatMessageCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set all messages for action plan item as read bu dealer.
         * @param {number} actionPlanItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHChatMessageReadActionPlanItemIdPut: async (actionPlanItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionPlanItemId' is not null or undefined
            assertParamExists('apiADHChatMessageReadActionPlanItemIdPut', 'actionPlanItemId', actionPlanItemId)
            const localVarPath = `/api/ADH/chatMessage/read/{actionPlanItemId}`
                .replace(`{${"actionPlanItemId"}}`, encodeURIComponent(String(actionPlanItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHDeleteFileADHChatMessageDelete: async (fileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiADHDeleteFileADHChatMessageDelete', 'fileId', fileId)
            const localVarPath = `/api/ADH/DeleteFile/ADHChatMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get ADH Link data.
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiADHGet', 'key', key)
            const localVarPath = `/api/ADH`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get link deviations for user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHLinkDeviationsUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ADH/linkDeviations/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} linkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHListFilesADHLinkLinkIdGet: async (linkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkId' is not null or undefined
            assertParamExists('apiADHListFilesADHLinkLinkIdGet', 'linkId', linkId)
            const localVarPath = `/api/ADH/ListFiles/ADHLink/{linkId}`
                .replace(`{${"linkId"}}`, encodeURIComponent(String(linkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHUploadFileADHChatMessagePost: async (fileToUpload?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ADH/UploadFile/ADHChatMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (fileToUpload !== undefined) { 
                localVarFormParams.append('fileToUpload', fileToUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ADHApi - functional programming interface
 * @export
 */
export const ADHApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ADHApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete ADH chat message.
         * @param {number} chatMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiADHChatMessageChatMessageIdDelete(chatMessageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiADHChatMessageChatMessageIdDelete(chatMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves a new ADH chat message.
         * @param {ADHChatMessageCreateDto} aDHChatMessageCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiADHChatMessagePost(aDHChatMessageCreateDto: ADHChatMessageCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiADHChatMessagePost(aDHChatMessageCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set all messages for action plan item as read bu dealer.
         * @param {number} actionPlanItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiADHChatMessageReadActionPlanItemIdPut(actionPlanItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiADHChatMessageReadActionPlanItemIdPut(actionPlanItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiADHDeleteFileADHChatMessageDelete(fileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiADHDeleteFileADHChatMessageDelete(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get ADH Link data.
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiADHGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ADHLinkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiADHGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get link deviations for user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiADHLinkDeviationsUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ADHAuditsWithUnreadMessagesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiADHLinkDeviationsUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} linkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiADHListFilesADHLinkLinkIdGet(linkId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiADHListFilesADHLinkLinkIdGet(linkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiADHUploadFileADHChatMessagePost(fileToUpload?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiADHUploadFileADHChatMessagePost(fileToUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ADHApi - factory interface
 * @export
 */
export const ADHApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ADHApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete ADH chat message.
         * @param {number} chatMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHChatMessageChatMessageIdDelete(chatMessageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiADHChatMessageChatMessageIdDelete(chatMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves a new ADH chat message.
         * @param {ADHChatMessageCreateDto} aDHChatMessageCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHChatMessagePost(aDHChatMessageCreateDto: ADHChatMessageCreateDto, options?: any): AxiosPromise<number> {
            return localVarFp.apiADHChatMessagePost(aDHChatMessageCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set all messages for action plan item as read bu dealer.
         * @param {number} actionPlanItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHChatMessageReadActionPlanItemIdPut(actionPlanItemId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiADHChatMessageReadActionPlanItemIdPut(actionPlanItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHDeleteFileADHChatMessageDelete(fileId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiADHDeleteFileADHChatMessageDelete(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get ADH Link data.
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHGet(key: string, options?: any): AxiosPromise<ADHLinkDto> {
            return localVarFp.apiADHGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get link deviations for user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHLinkDeviationsUserGet(options?: any): AxiosPromise<ADHAuditsWithUnreadMessagesDto> {
            return localVarFp.apiADHLinkDeviationsUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} linkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHListFilesADHLinkLinkIdGet(linkId: number, options?: any): AxiosPromise<Array<UserFileDto>> {
            return localVarFp.apiADHListFilesADHLinkLinkIdGet(linkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiADHUploadFileADHChatMessagePost(fileToUpload?: any, options?: any): AxiosPromise<UploadFileDto> {
            return localVarFp.apiADHUploadFileADHChatMessagePost(fileToUpload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ADHApi - object-oriented interface
 * @export
 * @class ADHApi
 * @extends {BaseAPI}
 */
export class ADHApi extends BaseAPI {
    /**
     * 
     * @summary Delete ADH chat message.
     * @param {number} chatMessageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ADHApi
     */
    public apiADHChatMessageChatMessageIdDelete(chatMessageId: number, options?: AxiosRequestConfig) {
        return ADHApiFp(this.configuration).apiADHChatMessageChatMessageIdDelete(chatMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves a new ADH chat message.
     * @param {ADHChatMessageCreateDto} aDHChatMessageCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ADHApi
     */
    public apiADHChatMessagePost(aDHChatMessageCreateDto: ADHChatMessageCreateDto, options?: AxiosRequestConfig) {
        return ADHApiFp(this.configuration).apiADHChatMessagePost(aDHChatMessageCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set all messages for action plan item as read bu dealer.
     * @param {number} actionPlanItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ADHApi
     */
    public apiADHChatMessageReadActionPlanItemIdPut(actionPlanItemId: number, options?: AxiosRequestConfig) {
        return ADHApiFp(this.configuration).apiADHChatMessageReadActionPlanItemIdPut(actionPlanItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ADHApi
     */
    public apiADHDeleteFileADHChatMessageDelete(fileId: number, options?: AxiosRequestConfig) {
        return ADHApiFp(this.configuration).apiADHDeleteFileADHChatMessageDelete(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get ADH Link data.
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ADHApi
     */
    public apiADHGet(key: string, options?: AxiosRequestConfig) {
        return ADHApiFp(this.configuration).apiADHGet(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get link deviations for user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ADHApi
     */
    public apiADHLinkDeviationsUserGet(options?: AxiosRequestConfig) {
        return ADHApiFp(this.configuration).apiADHLinkDeviationsUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} linkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ADHApi
     */
    public apiADHListFilesADHLinkLinkIdGet(linkId: number, options?: AxiosRequestConfig) {
        return ADHApiFp(this.configuration).apiADHListFilesADHLinkLinkIdGet(linkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [fileToUpload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ADHApi
     */
    public apiADHUploadFileADHChatMessagePost(fileToUpload?: any, options?: AxiosRequestConfig) {
        return ADHApiFp(this.configuration).apiADHUploadFileADHChatMessagePost(fileToUpload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get health check of API.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppHealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/App/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get health check of API.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppHealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppHealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @summary Get health check of API.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppHealthGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiAppHealthGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * 
     * @summary Get health check of API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public apiAppHealthGet(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).apiAppHealthGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuditHubApi - axios parameter creator
 * @export
 */
export const AuditHubApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Response is sent to DeviationDeleteChanged.
         * @param {DeviationDeleteDto} [deviationDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubDeleteDeviationAsyncPost: async (deviationDeleteDto?: DeviationDeleteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auditHub/DeleteDeviationAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviationDeleteDto !== undefined) {
                localVarQueryParameter['deviationDeleteDto'] = deviationDeleteDto;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Response is sent to NoteDeleteChanged.
         * @summary Deletes a Note. Provide one of PledgeId, AuditPointId or CheckPointId, let the others be null.
         * @param {NoteDeleteDto} [noteDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubDeleteNoteAsyncPost: async (noteDeleteDto?: NoteDeleteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auditHub/DeleteNoteAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (noteDeleteDto !== undefined) {
                localVarQueryParameter['noteDeleteDto'] = noteDeleteDto;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts listening to changes made to a specific audit
         * @param {ListenToAuditDto} [listenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubListenToAuditPost: async (listenDto?: ListenToAuditDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auditHub/ListenToAudit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (listenDto !== undefined) {
                localVarQueryParameter['listenDto'] = listenDto;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Response is sent to DeviationSaveChanged.
         * @param {DeviationCreateDto} [deviationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubSaveDeviationAsyncPost: async (deviationCreateDto?: DeviationCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auditHub/SaveDeviationAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviationCreateDto !== undefined) {
                localVarQueryParameter['deviationCreateDto'] = deviationCreateDto;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviationWithoutActionsCreateDto} [deviationWithoutActionsCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubSaveDeviationWithoutActionAsyncPost: async (deviationWithoutActionsCreateDto?: DeviationWithoutActionsCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auditHub/SaveDeviationWithoutActionAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviationWithoutActionsCreateDto !== undefined) {
                localVarQueryParameter['deviationWithoutActionsCreateDto'] = deviationWithoutActionsCreateDto;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Response is sent to NoteSaveOrUpdateChanged.
         * @summary Updates a Note if it exists otherwise a new Note will be added. Provide one of PledgeId, AuditPointId or CheckPointId, let the others be null.
         * @param {NoteCreateUpdateDto} [noteSaveDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubSaveOrUpdateNoteAsyncPost: async (noteSaveDto?: NoteCreateUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auditHub/SaveOrUpdateNoteAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (noteSaveDto !== undefined) {
                localVarQueryParameter['noteSaveDto'] = noteSaveDto;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stops listening to changes made to a specific audit
         * @param {StopListenToAuditDto} [stopDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubStopListenToAuditPost: async (stopDto?: StopListenToAuditDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auditHub/StopListenToAudit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (stopDto !== undefined) {
                localVarQueryParameter['stopDto'] = stopDto;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Response is sent to AuditPointCheckBoxChanged.
         * @param {AuditPointUpdateDto} [auditPointUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubUpdateAuditPointCheckBoxAsyncPost: async (auditPointUpdateDto?: AuditPointUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auditHub/UpdateAuditPointCheckBoxAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (auditPointUpdateDto !== undefined) {
                localVarQueryParameter['auditPointUpdateDto'] = auditPointUpdateDto;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Response is sent to CheckPointCheckBoxChanged.
         * @param {CheckPointUpdateDto} [checkPointUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubUpdateCheckPointCheckBoxAsyncPost: async (checkPointUpdateDto?: CheckPointUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auditHub/UpdateCheckPointCheckBoxAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (checkPointUpdateDto !== undefined) {
                localVarQueryParameter['checkPointUpdateDto'] = checkPointUpdateDto;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Response is sent to DeviationUpdateChanged.
         * @param {DeviationUpdateDto} [deviationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubUpdateDeviationAsyncPost: async (deviationUpdateDto?: DeviationUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auditHub/UpdateDeviationAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviationUpdateDto !== undefined) {
                localVarQueryParameter['deviationUpdateDto'] = deviationUpdateDto;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Response is sent to DeviationWithoutActionUpdateChanged.
         * @param {DeviationWithoutActionsUpdateDto} [deviationWithoutActionsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubUpdateDeviationWithoutActionsAsyncPost: async (deviationWithoutActionsUpdateDto?: DeviationWithoutActionsUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auditHub/UpdateDeviationWithoutActionsAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviationWithoutActionsUpdateDto !== undefined) {
                localVarQueryParameter['deviationWithoutActionsUpdateDto'] = deviationWithoutActionsUpdateDto;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditHubApi - functional programming interface
 * @export
 */
export const AuditHubApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditHubApiAxiosParamCreator(configuration)
    return {
        /**
         * Response is sent to DeviationDeleteChanged.
         * @param {DeviationDeleteDto} [deviationDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditHubDeleteDeviationAsyncPost(deviationDeleteDto?: DeviationDeleteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditHubDeleteDeviationAsyncPost(deviationDeleteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Response is sent to NoteDeleteChanged.
         * @summary Deletes a Note. Provide one of PledgeId, AuditPointId or CheckPointId, let the others be null.
         * @param {NoteDeleteDto} [noteDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditHubDeleteNoteAsyncPost(noteDeleteDto?: NoteDeleteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditHubDeleteNoteAsyncPost(noteDeleteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starts listening to changes made to a specific audit
         * @param {ListenToAuditDto} [listenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditHubListenToAuditPost(listenDto?: ListenToAuditDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditHubListenToAuditPost(listenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Response is sent to DeviationSaveChanged.
         * @param {DeviationCreateDto} [deviationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditHubSaveDeviationAsyncPost(deviationCreateDto?: DeviationCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditHubSaveDeviationAsyncPost(deviationCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviationWithoutActionsCreateDto} [deviationWithoutActionsCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditHubSaveDeviationWithoutActionAsyncPost(deviationWithoutActionsCreateDto?: DeviationWithoutActionsCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditHubSaveDeviationWithoutActionAsyncPost(deviationWithoutActionsCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Response is sent to NoteSaveOrUpdateChanged.
         * @summary Updates a Note if it exists otherwise a new Note will be added. Provide one of PledgeId, AuditPointId or CheckPointId, let the others be null.
         * @param {NoteCreateUpdateDto} [noteSaveDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditHubSaveOrUpdateNoteAsyncPost(noteSaveDto?: NoteCreateUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditHubSaveOrUpdateNoteAsyncPost(noteSaveDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stops listening to changes made to a specific audit
         * @param {StopListenToAuditDto} [stopDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditHubStopListenToAuditPost(stopDto?: StopListenToAuditDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditHubStopListenToAuditPost(stopDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Response is sent to AuditPointCheckBoxChanged.
         * @param {AuditPointUpdateDto} [auditPointUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditHubUpdateAuditPointCheckBoxAsyncPost(auditPointUpdateDto?: AuditPointUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditHubUpdateAuditPointCheckBoxAsyncPost(auditPointUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Response is sent to CheckPointCheckBoxChanged.
         * @param {CheckPointUpdateDto} [checkPointUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditHubUpdateCheckPointCheckBoxAsyncPost(checkPointUpdateDto?: CheckPointUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditHubUpdateCheckPointCheckBoxAsyncPost(checkPointUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Response is sent to DeviationUpdateChanged.
         * @param {DeviationUpdateDto} [deviationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditHubUpdateDeviationAsyncPost(deviationUpdateDto?: DeviationUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditHubUpdateDeviationAsyncPost(deviationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Response is sent to DeviationWithoutActionUpdateChanged.
         * @param {DeviationWithoutActionsUpdateDto} [deviationWithoutActionsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditHubUpdateDeviationWithoutActionsAsyncPost(deviationWithoutActionsUpdateDto?: DeviationWithoutActionsUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditHubUpdateDeviationWithoutActionsAsyncPost(deviationWithoutActionsUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditHubApi - factory interface
 * @export
 */
export const AuditHubApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditHubApiFp(configuration)
    return {
        /**
         * Response is sent to DeviationDeleteChanged.
         * @param {DeviationDeleteDto} [deviationDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubDeleteDeviationAsyncPost(deviationDeleteDto?: DeviationDeleteDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.auditHubDeleteDeviationAsyncPost(deviationDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Response is sent to NoteDeleteChanged.
         * @summary Deletes a Note. Provide one of PledgeId, AuditPointId or CheckPointId, let the others be null.
         * @param {NoteDeleteDto} [noteDeleteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubDeleteNoteAsyncPost(noteDeleteDto?: NoteDeleteDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.auditHubDeleteNoteAsyncPost(noteDeleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts listening to changes made to a specific audit
         * @param {ListenToAuditDto} [listenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubListenToAuditPost(listenDto?: ListenToAuditDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.auditHubListenToAuditPost(listenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Response is sent to DeviationSaveChanged.
         * @param {DeviationCreateDto} [deviationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubSaveDeviationAsyncPost(deviationCreateDto?: DeviationCreateDto, options?: any): AxiosPromise<string> {
            return localVarFp.auditHubSaveDeviationAsyncPost(deviationCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviationWithoutActionsCreateDto} [deviationWithoutActionsCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubSaveDeviationWithoutActionAsyncPost(deviationWithoutActionsCreateDto?: DeviationWithoutActionsCreateDto, options?: any): AxiosPromise<string> {
            return localVarFp.auditHubSaveDeviationWithoutActionAsyncPost(deviationWithoutActionsCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Response is sent to NoteSaveOrUpdateChanged.
         * @summary Updates a Note if it exists otherwise a new Note will be added. Provide one of PledgeId, AuditPointId or CheckPointId, let the others be null.
         * @param {NoteCreateUpdateDto} [noteSaveDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubSaveOrUpdateNoteAsyncPost(noteSaveDto?: NoteCreateUpdateDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.auditHubSaveOrUpdateNoteAsyncPost(noteSaveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Stops listening to changes made to a specific audit
         * @param {StopListenToAuditDto} [stopDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubStopListenToAuditPost(stopDto?: StopListenToAuditDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.auditHubStopListenToAuditPost(stopDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Response is sent to AuditPointCheckBoxChanged.
         * @param {AuditPointUpdateDto} [auditPointUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubUpdateAuditPointCheckBoxAsyncPost(auditPointUpdateDto?: AuditPointUpdateDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.auditHubUpdateAuditPointCheckBoxAsyncPost(auditPointUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Response is sent to CheckPointCheckBoxChanged.
         * @param {CheckPointUpdateDto} [checkPointUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubUpdateCheckPointCheckBoxAsyncPost(checkPointUpdateDto?: CheckPointUpdateDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.auditHubUpdateCheckPointCheckBoxAsyncPost(checkPointUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Response is sent to DeviationUpdateChanged.
         * @param {DeviationUpdateDto} [deviationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubUpdateDeviationAsyncPost(deviationUpdateDto?: DeviationUpdateDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.auditHubUpdateDeviationAsyncPost(deviationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Response is sent to DeviationWithoutActionUpdateChanged.
         * @param {DeviationWithoutActionsUpdateDto} [deviationWithoutActionsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditHubUpdateDeviationWithoutActionsAsyncPost(deviationWithoutActionsUpdateDto?: DeviationWithoutActionsUpdateDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.auditHubUpdateDeviationWithoutActionsAsyncPost(deviationWithoutActionsUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditHubApi - object-oriented interface
 * @export
 * @class AuditHubApi
 * @extends {BaseAPI}
 */
export class AuditHubApi extends BaseAPI {
    /**
     * Response is sent to DeviationDeleteChanged.
     * @param {DeviationDeleteDto} [deviationDeleteDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHubApi
     */
    public auditHubDeleteDeviationAsyncPost(deviationDeleteDto?: DeviationDeleteDto, options?: AxiosRequestConfig) {
        return AuditHubApiFp(this.configuration).auditHubDeleteDeviationAsyncPost(deviationDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Response is sent to NoteDeleteChanged.
     * @summary Deletes a Note. Provide one of PledgeId, AuditPointId or CheckPointId, let the others be null.
     * @param {NoteDeleteDto} [noteDeleteDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHubApi
     */
    public auditHubDeleteNoteAsyncPost(noteDeleteDto?: NoteDeleteDto, options?: AxiosRequestConfig) {
        return AuditHubApiFp(this.configuration).auditHubDeleteNoteAsyncPost(noteDeleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts listening to changes made to a specific audit
     * @param {ListenToAuditDto} [listenDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHubApi
     */
    public auditHubListenToAuditPost(listenDto?: ListenToAuditDto, options?: AxiosRequestConfig) {
        return AuditHubApiFp(this.configuration).auditHubListenToAuditPost(listenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Response is sent to DeviationSaveChanged.
     * @param {DeviationCreateDto} [deviationCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHubApi
     */
    public auditHubSaveDeviationAsyncPost(deviationCreateDto?: DeviationCreateDto, options?: AxiosRequestConfig) {
        return AuditHubApiFp(this.configuration).auditHubSaveDeviationAsyncPost(deviationCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviationWithoutActionsCreateDto} [deviationWithoutActionsCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHubApi
     */
    public auditHubSaveDeviationWithoutActionAsyncPost(deviationWithoutActionsCreateDto?: DeviationWithoutActionsCreateDto, options?: AxiosRequestConfig) {
        return AuditHubApiFp(this.configuration).auditHubSaveDeviationWithoutActionAsyncPost(deviationWithoutActionsCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Response is sent to NoteSaveOrUpdateChanged.
     * @summary Updates a Note if it exists otherwise a new Note will be added. Provide one of PledgeId, AuditPointId or CheckPointId, let the others be null.
     * @param {NoteCreateUpdateDto} [noteSaveDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHubApi
     */
    public auditHubSaveOrUpdateNoteAsyncPost(noteSaveDto?: NoteCreateUpdateDto, options?: AxiosRequestConfig) {
        return AuditHubApiFp(this.configuration).auditHubSaveOrUpdateNoteAsyncPost(noteSaveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stops listening to changes made to a specific audit
     * @param {StopListenToAuditDto} [stopDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHubApi
     */
    public auditHubStopListenToAuditPost(stopDto?: StopListenToAuditDto, options?: AxiosRequestConfig) {
        return AuditHubApiFp(this.configuration).auditHubStopListenToAuditPost(stopDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Response is sent to AuditPointCheckBoxChanged.
     * @param {AuditPointUpdateDto} [auditPointUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHubApi
     */
    public auditHubUpdateAuditPointCheckBoxAsyncPost(auditPointUpdateDto?: AuditPointUpdateDto, options?: AxiosRequestConfig) {
        return AuditHubApiFp(this.configuration).auditHubUpdateAuditPointCheckBoxAsyncPost(auditPointUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Response is sent to CheckPointCheckBoxChanged.
     * @param {CheckPointUpdateDto} [checkPointUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHubApi
     */
    public auditHubUpdateCheckPointCheckBoxAsyncPost(checkPointUpdateDto?: CheckPointUpdateDto, options?: AxiosRequestConfig) {
        return AuditHubApiFp(this.configuration).auditHubUpdateCheckPointCheckBoxAsyncPost(checkPointUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Response is sent to DeviationUpdateChanged.
     * @param {DeviationUpdateDto} [deviationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHubApi
     */
    public auditHubUpdateDeviationAsyncPost(deviationUpdateDto?: DeviationUpdateDto, options?: AxiosRequestConfig) {
        return AuditHubApiFp(this.configuration).auditHubUpdateDeviationAsyncPost(deviationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Response is sent to DeviationWithoutActionUpdateChanged.
     * @param {DeviationWithoutActionsUpdateDto} [deviationWithoutActionsUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditHubApi
     */
    public auditHubUpdateDeviationWithoutActionsAsyncPost(deviationWithoutActionsUpdateDto?: DeviationWithoutActionsUpdateDto, options?: AxiosRequestConfig) {
        return AuditHubApiFp(this.configuration).auditHubUpdateDeviationWithoutActionsAsyncPost(deviationWithoutActionsUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuditsApi - axios parameter creator
 * @export
 */
export const AuditsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Set all messages for action plan item as read bu auditor.
         * @param {number} actionPlanItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsADHChatMessageReadActionPlanItemIdPut: async (actionPlanItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionPlanItemId' is not null or undefined
            assertParamExists('apiAuditsADHChatMessageReadActionPlanItemIdPut', 'actionPlanItemId', actionPlanItemId)
            const localVarPath = `/api/Audits/ADH/chatMessage/read/{actionPlanItemId}`
                .replace(`{${"actionPlanItemId"}}`, encodeURIComponent(String(actionPlanItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get chat messages for deviation.
         * @param {number} actionPlanItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsADHChatMessagesGet: async (actionPlanItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionPlanItemId' is not null or undefined
            assertParamExists('apiAuditsADHChatMessagesGet', 'actionPlanItemId', actionPlanItemId)
            const localVarPath = `/api/Audits/ADH/chatMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (actionPlanItemId !== undefined) {
                localVarQueryParameter['actionPlanItemId'] = actionPlanItemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete ADH link.
         * @param {number} linkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsADHLinkIdDelete: async (linkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkId' is not null or undefined
            assertParamExists('apiAuditsADHLinkIdDelete', 'linkId', linkId)
            const localVarPath = `/api/Audits/ADH/{linkId}`
                .replace(`{${"linkId"}}`, encodeURIComponent(String(linkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves a new ADH link.
         * @param {ADHLinkCreateDto} aDHLinkCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsADHLinkPost: async (aDHLinkCreateDto: ADHLinkCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aDHLinkCreateDto' is not null or undefined
            assertParamExists('apiAuditsADHLinkPost', 'aDHLinkCreateDto', aDHLinkCreateDto)
            const localVarPath = `/api/Audits/ADH/link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aDHLinkCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get ADH links of an audit.
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsADHLinksGet: async (auditId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiAuditsADHLinksGet', 'auditId', auditId)
            const localVarPath = `/api/Audits/ADH/links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (auditId !== undefined) {
                localVarQueryParameter['auditId'] = auditId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch all audit point numbers from and audit
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditAuditIdAuditPointNumbersGet: async (auditId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiAuditsAuditAuditIdAuditPointNumbersGet', 'auditId', auditId)
            const localVarPath = `/api/Audits/audit/{auditId}/auditPointNumbers`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Certify an audit
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditAuditIdCertifyPost: async (auditId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiAuditsAuditAuditIdCertifyPost', 'auditId', auditId)
            const localVarPath = `/api/Audits/audit/{auditId}/certify`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get complete audit from auditId
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditAuditIdGet: async (auditId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiAuditsAuditAuditIdGet', 'auditId', auditId)
            const localVarPath = `/api/Audits/audit/{auditId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update note on an audit
         * @param {number} auditId 
         * @param {SetNoteDto} setNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditIdNotePut: async (auditId: number, setNoteDto: SetNoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiAuditsAuditIdNotePut', 'auditId', auditId)
            // verify required parameter 'setNoteDto' is not null or undefined
            assertParamExists('apiAuditsAuditIdNotePut', 'setNoteDto', setNoteDto)
            const localVarPath = `/api/Audits/{auditId}/note`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an audit.
         * @param {number} auditId 
         * @param {AuditUpdateDto} auditUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditIdPut: async (auditId: number, auditUpdateDto: AuditUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiAuditsAuditIdPut', 'auditId', auditId)
            // verify required parameter 'auditUpdateDto' is not null or undefined
            assertParamExists('apiAuditsAuditIdPut', 'auditUpdateDto', auditUpdateDto)
            const localVarPath = `/api/Audits/{auditId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Audit Point numbers.
         * @param {number} dosVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditPointAuditPointNumbersDosVersionGet: async (dosVersion: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dosVersion' is not null or undefined
            assertParamExists('apiAuditsAuditPointAuditPointNumbersDosVersionGet', 'dosVersion', dosVersion)
            const localVarPath = `/api/Audits/auditPoint/auditPointNumbers/{dosVersion}`
                .replace(`{${"dosVersion"}}`, encodeURIComponent(String(dosVersion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get auditTypes list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Audits/AuditTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Check Point numbers.
         * @param {number} dosVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsCheckPointCheckPointNumbersDosVersionGet: async (dosVersion: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dosVersion' is not null or undefined
            assertParamExists('apiAuditsCheckPointCheckPointNumbersDosVersionGet', 'dosVersion', dosVersion)
            const localVarPath = `/api/Audits/checkPoint/checkPointNumbers/{dosVersion}`
                .replace(`{${"dosVersion"}}`, encodeURIComponent(String(dosVersion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new Checkpoint.
         * @param {CheckpointCreateDto} checkpointCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsCheckPointPost: async (checkpointCreateDto: CheckpointCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkpointCreateDto' is not null or undefined
            assertParamExists('apiAuditsCheckPointPost', 'checkpointCreateDto', checkpointCreateDto)
            const localVarPath = `/api/Audits/checkPoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkpointCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available audits for dealer.
         * @param {number} did 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsDealerDidGet: async (did: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('apiAuditsDealerDidGet', 'did', did)
            const localVarPath = `/api/Audits/dealer/{did}`
                .replace(`{${"did"}}`, encodeURIComponent(String(did)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete favorite audit with Id.
         * @param {number} favoriteAuditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsFavoriteFavoriteAuditIdDelete: async (favoriteAuditId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoriteAuditId' is not null or undefined
            assertParamExists('apiAuditsFavoriteFavoriteAuditIdDelete', 'favoriteAuditId', favoriteAuditId)
            const localVarPath = `/api/Audits/favorite/{FavoriteAuditId}`
                .replace(`{${"FavoriteAuditId"}}`, encodeURIComponent(String(favoriteAuditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves a new favorite audit. Returns new favoriteAuditId.
         * @param {FavoritesAuditsCreateDto} favoritesAuditsCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsFavoritePost: async (favoritesAuditsCreateDto: FavoritesAuditsCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoritesAuditsCreateDto' is not null or undefined
            assertParamExists('apiAuditsFavoritePost', 'favoritesAuditsCreateDto', favoritesAuditsCreateDto)
            const localVarPath = `/api/Audits/favorite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(favoritesAuditsCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get favorite audit for user and audit.
         * @param {number} userId 
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsFavoriteUserIdAuditIdGet: async (userId: number, auditId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiAuditsFavoriteUserIdAuditIdGet', 'userId', userId)
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiAuditsFavoriteUserIdAuditIdGet', 'auditId', auditId)
            const localVarPath = `/api/Audits/favorite/{userId}/{auditId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get favorites audits for user.
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsFavoriteUserIdGet: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiAuditsFavoriteUserIdGet', 'userId', userId)
            const localVarPath = `/api/Audits/favorite/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves a new audit. Returns new auditId.
         * @param {AuditCreateDto} auditCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsPost: async (auditCreateDto: AuditCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditCreateDto' is not null or undefined
            assertParamExists('apiAuditsPost', 'auditCreateDto', auditCreateDto)
            const localVarPath = `/api/Audits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditsApi - functional programming interface
 * @export
 */
export const AuditsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Set all messages for action plan item as read bu auditor.
         * @param {number} actionPlanItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsADHChatMessageReadActionPlanItemIdPut(actionPlanItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsADHChatMessageReadActionPlanItemIdPut(actionPlanItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get chat messages for deviation.
         * @param {number} actionPlanItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsADHChatMessagesGet(actionPlanItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ADHChatMessageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsADHChatMessagesGet(actionPlanItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete ADH link.
         * @param {number} linkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsADHLinkIdDelete(linkId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsADHLinkIdDelete(linkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves a new ADH link.
         * @param {ADHLinkCreateDto} aDHLinkCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsADHLinkPost(aDHLinkCreateDto: ADHLinkCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ADHLinkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsADHLinkPost(aDHLinkCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get ADH links of an audit.
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsADHLinksGet(auditId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ADHLinksDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsADHLinksGet(auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch all audit point numbers from and audit
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsAuditAuditIdAuditPointNumbersGet(auditId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditPointIdAndNumberDtoListObjectResultEnvelope>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsAuditAuditIdAuditPointNumbersGet(auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Certify an audit
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsAuditAuditIdCertifyPost(auditId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditCertifyErrorCodeObjectResultEnvelope>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsAuditAuditIdCertifyPost(auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get complete audit from auditId
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsAuditAuditIdGet(auditId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditCompleteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsAuditAuditIdGet(auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update note on an audit
         * @param {number} auditId 
         * @param {SetNoteDto} setNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsAuditIdNotePut(auditId: number, setNoteDto: SetNoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsAuditIdNotePut(auditId, setNoteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an audit.
         * @param {number} auditId 
         * @param {AuditUpdateDto} auditUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsAuditIdPut(auditId: number, auditUpdateDto: AuditUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsAuditIdPut(auditId, auditUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all Audit Point numbers.
         * @param {number} dosVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsAuditPointAuditPointNumbersDosVersionGet(dosVersion: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditPointIdAndNumberDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsAuditPointAuditPointNumbersDosVersionGet(dosVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get auditTypes list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsAuditTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditTypesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsAuditTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all Check Point numbers.
         * @param {number} dosVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsCheckPointCheckPointNumbersDosVersionGet(dosVersion: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CheckPointIdAndNumberDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsCheckPointCheckPointNumbersDosVersionGet(dosVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new Checkpoint.
         * @param {CheckpointCreateDto} checkpointCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsCheckPointPost(checkpointCreateDto: CheckpointCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsCheckPointPost(checkpointCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available audits for dealer.
         * @param {number} did 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsDealerDidGet(did: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableAuditsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsDealerDidGet(did, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete favorite audit with Id.
         * @param {number} favoriteAuditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsFavoriteFavoriteAuditIdDelete(favoriteAuditId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsFavoriteFavoriteAuditIdDelete(favoriteAuditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves a new favorite audit. Returns new favoriteAuditId.
         * @param {FavoritesAuditsCreateDto} favoritesAuditsCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsFavoritePost(favoritesAuditsCreateDto: FavoritesAuditsCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsFavoritePost(favoritesAuditsCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get favorite audit for user and audit.
         * @param {number} userId 
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsFavoriteUserIdAuditIdGet(userId: number, auditId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoriteAuditSingleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsFavoriteUserIdAuditIdGet(userId, auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get favorites audits for user.
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsFavoriteUserIdGet(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoritesAuditsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsFavoriteUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves a new audit. Returns new auditId.
         * @param {AuditCreateDto} auditCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditsPost(auditCreateDto: AuditCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditsPost(auditCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditsApi - factory interface
 * @export
 */
export const AuditsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditsApiFp(configuration)
    return {
        /**
         * 
         * @summary Set all messages for action plan item as read bu auditor.
         * @param {number} actionPlanItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsADHChatMessageReadActionPlanItemIdPut(actionPlanItemId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuditsADHChatMessageReadActionPlanItemIdPut(actionPlanItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get chat messages for deviation.
         * @param {number} actionPlanItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsADHChatMessagesGet(actionPlanItemId: number, options?: any): AxiosPromise<Array<ADHChatMessageDto>> {
            return localVarFp.apiAuditsADHChatMessagesGet(actionPlanItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete ADH link.
         * @param {number} linkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsADHLinkIdDelete(linkId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuditsADHLinkIdDelete(linkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves a new ADH link.
         * @param {ADHLinkCreateDto} aDHLinkCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsADHLinkPost(aDHLinkCreateDto: ADHLinkCreateDto, options?: any): AxiosPromise<ADHLinkDto> {
            return localVarFp.apiAuditsADHLinkPost(aDHLinkCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get ADH links of an audit.
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsADHLinksGet(auditId: number, options?: any): AxiosPromise<ADHLinksDto> {
            return localVarFp.apiAuditsADHLinksGet(auditId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch all audit point numbers from and audit
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditAuditIdAuditPointNumbersGet(auditId: number, options?: any): AxiosPromise<AuditPointIdAndNumberDtoListObjectResultEnvelope> {
            return localVarFp.apiAuditsAuditAuditIdAuditPointNumbersGet(auditId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Certify an audit
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditAuditIdCertifyPost(auditId: number, options?: any): AxiosPromise<AuditCertifyErrorCodeObjectResultEnvelope> {
            return localVarFp.apiAuditsAuditAuditIdCertifyPost(auditId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get complete audit from auditId
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditAuditIdGet(auditId: number, options?: any): AxiosPromise<AuditCompleteDto> {
            return localVarFp.apiAuditsAuditAuditIdGet(auditId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update note on an audit
         * @param {number} auditId 
         * @param {SetNoteDto} setNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditIdNotePut(auditId: number, setNoteDto: SetNoteDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuditsAuditIdNotePut(auditId, setNoteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an audit.
         * @param {number} auditId 
         * @param {AuditUpdateDto} auditUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditIdPut(auditId: number, auditUpdateDto: AuditUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuditsAuditIdPut(auditId, auditUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Audit Point numbers.
         * @param {number} dosVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditPointAuditPointNumbersDosVersionGet(dosVersion: number, options?: any): AxiosPromise<Array<AuditPointIdAndNumberDto>> {
            return localVarFp.apiAuditsAuditPointAuditPointNumbersDosVersionGet(dosVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get auditTypes list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsAuditTypesGet(options?: any): AxiosPromise<AuditTypesDto> {
            return localVarFp.apiAuditsAuditTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Check Point numbers.
         * @param {number} dosVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsCheckPointCheckPointNumbersDosVersionGet(dosVersion: number, options?: any): AxiosPromise<Array<CheckPointIdAndNumberDto>> {
            return localVarFp.apiAuditsCheckPointCheckPointNumbersDosVersionGet(dosVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new Checkpoint.
         * @param {CheckpointCreateDto} checkpointCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsCheckPointPost(checkpointCreateDto: CheckpointCreateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuditsCheckPointPost(checkpointCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available audits for dealer.
         * @param {number} did 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsDealerDidGet(did: number, options?: any): AxiosPromise<AvailableAuditsDto> {
            return localVarFp.apiAuditsDealerDidGet(did, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete favorite audit with Id.
         * @param {number} favoriteAuditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsFavoriteFavoriteAuditIdDelete(favoriteAuditId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuditsFavoriteFavoriteAuditIdDelete(favoriteAuditId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves a new favorite audit. Returns new favoriteAuditId.
         * @param {FavoritesAuditsCreateDto} favoritesAuditsCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsFavoritePost(favoritesAuditsCreateDto: FavoritesAuditsCreateDto, options?: any): AxiosPromise<number> {
            return localVarFp.apiAuditsFavoritePost(favoritesAuditsCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get favorite audit for user and audit.
         * @param {number} userId 
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsFavoriteUserIdAuditIdGet(userId: number, auditId: number, options?: any): AxiosPromise<FavoriteAuditSingleDto> {
            return localVarFp.apiAuditsFavoriteUserIdAuditIdGet(userId, auditId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get favorites audits for user.
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsFavoriteUserIdGet(userId: number, options?: any): AxiosPromise<FavoritesAuditsDto> {
            return localVarFp.apiAuditsFavoriteUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves a new audit. Returns new auditId.
         * @param {AuditCreateDto} auditCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditsPost(auditCreateDto: AuditCreateDto, options?: any): AxiosPromise<number> {
            return localVarFp.apiAuditsPost(auditCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditsApi - object-oriented interface
 * @export
 * @class AuditsApi
 * @extends {BaseAPI}
 */
export class AuditsApi extends BaseAPI {
    /**
     * 
     * @summary Set all messages for action plan item as read bu auditor.
     * @param {number} actionPlanItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsADHChatMessageReadActionPlanItemIdPut(actionPlanItemId: number, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsADHChatMessageReadActionPlanItemIdPut(actionPlanItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get chat messages for deviation.
     * @param {number} actionPlanItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsADHChatMessagesGet(actionPlanItemId: number, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsADHChatMessagesGet(actionPlanItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete ADH link.
     * @param {number} linkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsADHLinkIdDelete(linkId: number, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsADHLinkIdDelete(linkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves a new ADH link.
     * @param {ADHLinkCreateDto} aDHLinkCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsADHLinkPost(aDHLinkCreateDto: ADHLinkCreateDto, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsADHLinkPost(aDHLinkCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get ADH links of an audit.
     * @param {number} auditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsADHLinksGet(auditId: number, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsADHLinksGet(auditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch all audit point numbers from and audit
     * @param {number} auditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsAuditAuditIdAuditPointNumbersGet(auditId: number, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsAuditAuditIdAuditPointNumbersGet(auditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Certify an audit
     * @param {number} auditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsAuditAuditIdCertifyPost(auditId: number, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsAuditAuditIdCertifyPost(auditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get complete audit from auditId
     * @param {number} auditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsAuditAuditIdGet(auditId: number, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsAuditAuditIdGet(auditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update note on an audit
     * @param {number} auditId 
     * @param {SetNoteDto} setNoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsAuditIdNotePut(auditId: number, setNoteDto: SetNoteDto, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsAuditIdNotePut(auditId, setNoteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an audit.
     * @param {number} auditId 
     * @param {AuditUpdateDto} auditUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsAuditIdPut(auditId: number, auditUpdateDto: AuditUpdateDto, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsAuditIdPut(auditId, auditUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all Audit Point numbers.
     * @param {number} dosVersion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsAuditPointAuditPointNumbersDosVersionGet(dosVersion: number, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsAuditPointAuditPointNumbersDosVersionGet(dosVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get auditTypes list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsAuditTypesGet(options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsAuditTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all Check Point numbers.
     * @param {number} dosVersion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsCheckPointCheckPointNumbersDosVersionGet(dosVersion: number, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsCheckPointCheckPointNumbersDosVersionGet(dosVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new Checkpoint.
     * @param {CheckpointCreateDto} checkpointCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsCheckPointPost(checkpointCreateDto: CheckpointCreateDto, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsCheckPointPost(checkpointCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available audits for dealer.
     * @param {number} did 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsDealerDidGet(did: number, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsDealerDidGet(did, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete favorite audit with Id.
     * @param {number} favoriteAuditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsFavoriteFavoriteAuditIdDelete(favoriteAuditId: number, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsFavoriteFavoriteAuditIdDelete(favoriteAuditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves a new favorite audit. Returns new favoriteAuditId.
     * @param {FavoritesAuditsCreateDto} favoritesAuditsCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsFavoritePost(favoritesAuditsCreateDto: FavoritesAuditsCreateDto, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsFavoritePost(favoritesAuditsCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get favorite audit for user and audit.
     * @param {number} userId 
     * @param {number} auditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsFavoriteUserIdAuditIdGet(userId: number, auditId: number, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsFavoriteUserIdAuditIdGet(userId, auditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get favorites audits for user.
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsFavoriteUserIdGet(userId: number, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsFavoriteUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves a new audit. Returns new auditId.
     * @param {AuditCreateDto} auditCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public apiAuditsPost(auditCreateDto: AuditCreateDto, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).apiAuditsPost(auditCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DealersApi - axios parameter creator
 * @export
 */
export const DealersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update dealer expiring date.
         * @param {number} dealerId 
         * @param {string} expiryDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealersExpiringDateDealerIdExpiryDatePut: async (dealerId: number, expiryDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealerId' is not null or undefined
            assertParamExists('apiDealersExpiringDateDealerIdExpiryDatePut', 'dealerId', dealerId)
            // verify required parameter 'expiryDate' is not null or undefined
            assertParamExists('apiDealersExpiringDateDealerIdExpiryDatePut', 'expiryDate', expiryDate)
            const localVarPath = `/api/Dealers/expiringDate/{dealerId}/{ExpiryDate}`
                .replace(`{${"dealerId"}}`, encodeURIComponent(String(dealerId)))
                .replace(`{${"ExpiryDate"}}`, encodeURIComponent(String(expiryDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dealers that are expiring or has expired for current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealersExpiringGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dealers/expiring`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get limit of expiry date from history of changes.
         * @param {number} dealerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealersExpiryDateLimitDealerIdGet: async (dealerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealerId' is not null or undefined
            assertParamExists('apiDealersExpiryDateLimitDealerIdGet', 'dealerId', dealerId)
            const localVarPath = `/api/Dealers/expiryDateLimit/{dealerId}`
                .replace(`{${"dealerId"}}`, encodeURIComponent(String(dealerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [marketIds] 
         * @param {Array<number>} [dealerIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealersGet: async (marketIds?: Array<number>, dealerIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dealers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (marketIds) {
                localVarQueryParameter['MarketIds'] = marketIds;
            }

            if (dealerIds) {
                localVarQueryParameter['DealerIds'] = dealerIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dealers for market.
         * @param {number} marketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealersMarketIdGet: async (marketId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('apiDealersMarketIdGet', 'marketId', marketId)
            const localVarPath = `/api/Dealers/{marketId}`
                .replace(`{${"marketId"}}`, encodeURIComponent(String(marketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets dealer info for sis sync with two optional parameters.
         * @param {string} [dealerId] 
         * @param {string} [countryCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealersSisSyncDealerInfoGet: async (dealerId?: string, countryCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dealers/sisSyncDealerInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dealerId !== undefined) {
                localVarQueryParameter['dealerId'] = dealerId;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DealersApi - functional programming interface
 * @export
 */
export const DealersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DealersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update dealer expiring date.
         * @param {number} dealerId 
         * @param {string} expiryDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDealersExpiringDateDealerIdExpiryDatePut(dealerId: number, expiryDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealersExpiringDateDealerIdExpiryDatePut(dealerId, expiryDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dealers that are expiring or has expired for current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDealersExpiringGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealersExpiringGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get limit of expiry date from history of changes.
         * @param {number} dealerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDealersExpiryDateLimitDealerIdGet(dealerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealersExpiryDateLimitDealerIdGet(dealerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [marketIds] 
         * @param {Array<number>} [dealerIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDealersGet(marketIds?: Array<number>, dealerIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealerDtoICollectionObjectResultEnvelope>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealersGet(marketIds, dealerIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dealers for market.
         * @param {number} marketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDealersMarketIdGet(marketId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealersMarketIdGet(marketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets dealer info for sis sync with two optional parameters.
         * @param {string} [dealerId] 
         * @param {string} [countryCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDealersSisSyncDealerInfoGet(dealerId?: string, countryCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SISSynchronizationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDealersSisSyncDealerInfoGet(dealerId, countryCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DealersApi - factory interface
 * @export
 */
export const DealersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DealersApiFp(configuration)
    return {
        /**
         * 
         * @summary Update dealer expiring date.
         * @param {number} dealerId 
         * @param {string} expiryDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealersExpiringDateDealerIdExpiryDatePut(dealerId: number, expiryDate: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDealersExpiringDateDealerIdExpiryDatePut(dealerId, expiryDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dealers that are expiring or has expired for current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealersExpiringGet(options?: any): AxiosPromise<DealersDto> {
            return localVarFp.apiDealersExpiringGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get limit of expiry date from history of changes.
         * @param {number} dealerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealersExpiryDateLimitDealerIdGet(dealerId: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiDealersExpiryDateLimitDealerIdGet(dealerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [marketIds] 
         * @param {Array<number>} [dealerIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealersGet(marketIds?: Array<number>, dealerIds?: Array<number>, options?: any): AxiosPromise<DealerDtoICollectionObjectResultEnvelope> {
            return localVarFp.apiDealersGet(marketIds, dealerIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dealers for market.
         * @param {number} marketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealersMarketIdGet(marketId: number, options?: any): AxiosPromise<DealersDto> {
            return localVarFp.apiDealersMarketIdGet(marketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets dealer info for sis sync with two optional parameters.
         * @param {string} [dealerId] 
         * @param {string} [countryCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDealersSisSyncDealerInfoGet(dealerId?: string, countryCode?: string, options?: any): AxiosPromise<SISSynchronizationDto> {
            return localVarFp.apiDealersSisSyncDealerInfoGet(dealerId, countryCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DealersApi - object-oriented interface
 * @export
 * @class DealersApi
 * @extends {BaseAPI}
 */
export class DealersApi extends BaseAPI {
    /**
     * 
     * @summary Update dealer expiring date.
     * @param {number} dealerId 
     * @param {string} expiryDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersApi
     */
    public apiDealersExpiringDateDealerIdExpiryDatePut(dealerId: number, expiryDate: string, options?: AxiosRequestConfig) {
        return DealersApiFp(this.configuration).apiDealersExpiringDateDealerIdExpiryDatePut(dealerId, expiryDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dealers that are expiring or has expired for current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersApi
     */
    public apiDealersExpiringGet(options?: AxiosRequestConfig) {
        return DealersApiFp(this.configuration).apiDealersExpiringGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get limit of expiry date from history of changes.
     * @param {number} dealerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersApi
     */
    public apiDealersExpiryDateLimitDealerIdGet(dealerId: number, options?: AxiosRequestConfig) {
        return DealersApiFp(this.configuration).apiDealersExpiryDateLimitDealerIdGet(dealerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [marketIds] 
     * @param {Array<number>} [dealerIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersApi
     */
    public apiDealersGet(marketIds?: Array<number>, dealerIds?: Array<number>, options?: AxiosRequestConfig) {
        return DealersApiFp(this.configuration).apiDealersGet(marketIds, dealerIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dealers for market.
     * @param {number} marketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersApi
     */
    public apiDealersMarketIdGet(marketId: number, options?: AxiosRequestConfig) {
        return DealersApiFp(this.configuration).apiDealersMarketIdGet(marketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets dealer info for sis sync with two optional parameters.
     * @param {string} [dealerId] 
     * @param {string} [countryCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersApi
     */
    public apiDealersSisSyncDealerInfoGet(dealerId?: string, countryCode?: string, options?: AxiosRequestConfig) {
        return DealersApiFp(this.configuration).apiDealersSisSyncDealerInfoGet(dealerId, countryCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviationsApi - axios parameter creator
 * @export
 */
export const DeviationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all deviation to an audit.
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeviationsAuditAuditIdGet: async (auditId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiDeviationsAuditAuditIdGet', 'auditId', auditId)
            const localVarPath = `/api/Deviations/audit/{auditId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get deviations that are about to expire.
         * @param {boolean} [includeTestMarket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeviationsUserGet: async (includeTestMarket?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deviations/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeTestMarket !== undefined) {
                localVarQueryParameter['includeTestMarket'] = includeTestMarket;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviationsApi - functional programming interface
 * @export
 */
export const DeviationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all deviation to an audit.
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeviationsAuditAuditIdGet(auditId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviationsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeviationsAuditAuditIdGet(auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get deviations that are about to expire.
         * @param {boolean} [includeTestMarket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeviationsUserGet(includeTestMarket?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviationsExpiringDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeviationsUserGet(includeTestMarket, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviationsApi - factory interface
 * @export
 */
export const DeviationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all deviation to an audit.
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeviationsAuditAuditIdGet(auditId: number, options?: any): AxiosPromise<DeviationsDto> {
            return localVarFp.apiDeviationsAuditAuditIdGet(auditId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get deviations that are about to expire.
         * @param {boolean} [includeTestMarket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeviationsUserGet(includeTestMarket?: boolean, options?: any): AxiosPromise<DeviationsExpiringDto> {
            return localVarFp.apiDeviationsUserGet(includeTestMarket, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviationsApi - object-oriented interface
 * @export
 * @class DeviationsApi
 * @extends {BaseAPI}
 */
export class DeviationsApi extends BaseAPI {
    /**
     * 
     * @summary Get all deviation to an audit.
     * @param {number} auditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationsApi
     */
    public apiDeviationsAuditAuditIdGet(auditId: number, options?: AxiosRequestConfig) {
        return DeviationsApiFp(this.configuration).apiDeviationsAuditAuditIdGet(auditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get deviations that are about to expire.
     * @param {boolean} [includeTestMarket] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviationsApi
     */
    public apiDeviationsUserGet(includeTestMarket?: boolean, options?: AxiosRequestConfig) {
        return DeviationsApiFp(this.configuration).apiDeviationsUserGet(includeTestMarket, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a document.
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentDocumentIdDelete: async (documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiDocumentDocumentIdDelete', 'documentId', documentId)
            const localVarPath = `/api/Document/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a document data.
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentDocumentIdGet: async (documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiDocumentDocumentIdGet', 'documentId', documentId)
            const localVarPath = `/api/Document/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Documents.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves a new document.
         * @param {DocumentCreateDto} documentCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentPost: async (documentCreateDto: DocumentCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentCreateDto' is not null or undefined
            assertParamExists('apiDocumentPost', 'documentCreateDto', documentCreateDto)
            const localVarPath = `/api/Document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a document
         * @param {DocumentUpdateDto} [documentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentPut: async (documentUpdateDto?: DocumentUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a document.
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentDocumentIdDelete(documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentDocumentIdDelete(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a document data.
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentDocumentIdGet(documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentDocumentIdGet(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all Documents.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves a new document.
         * @param {DocumentCreateDto} documentCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentPost(documentCreateDto: DocumentCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentPost(documentCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a document
         * @param {DocumentUpdateDto} [documentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentPut(documentUpdateDto?: DocumentUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentPut(documentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a document.
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentDocumentIdDelete(documentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiDocumentDocumentIdDelete(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a document data.
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentDocumentIdGet(documentId: number, options?: any): AxiosPromise<DocumentDto> {
            return localVarFp.apiDocumentDocumentIdGet(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Documents.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGet(options?: any): AxiosPromise<DocumentsDto> {
            return localVarFp.apiDocumentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves a new document.
         * @param {DocumentCreateDto} documentCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentPost(documentCreateDto: DocumentCreateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiDocumentPost(documentCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a document
         * @param {DocumentUpdateDto} [documentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentPut(documentUpdateDto?: DocumentUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiDocumentPut(documentUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * 
     * @summary Delete a document.
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentDocumentIdDelete(documentId: number, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiDocumentDocumentIdDelete(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a document data.
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentDocumentIdGet(documentId: number, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiDocumentDocumentIdGet(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all Documents.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentGet(options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiDocumentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves a new document.
     * @param {DocumentCreateDto} documentCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentPost(documentCreateDto: DocumentCreateDto, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiDocumentPost(documentCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a document
     * @param {DocumentUpdateDto} [documentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentPut(documentUpdateDto?: DocumentUpdateDto, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiDocumentPut(documentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExposerApi - axios parameter creator
 * @export
 */
export const ExposerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary DO NOT USE!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exposeExposerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/expose/Exposer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExposerApi - functional programming interface
 * @export
 */
export const ExposerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExposerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary DO NOT USE!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exposeExposerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviationWithoutActionsCreateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exposeExposerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExposerApi - factory interface
 * @export
 */
export const ExposerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExposerApiFp(configuration)
    return {
        /**
         * 
         * @summary DO NOT USE!
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exposeExposerGet(options?: any): AxiosPromise<DeviationWithoutActionsCreateResponseDto> {
            return localVarFp.exposeExposerGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExposerApi - object-oriented interface
 * @export
 * @class ExposerApi
 * @extends {BaseAPI}
 */
export class ExposerApi extends BaseAPI {
    /**
     * 
     * @summary DO NOT USE!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExposerApi
     */
    public exposeExposerGet(options?: AxiosRequestConfig) {
        return ExposerApiFp(this.configuration).exposeExposerGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketsApi - axios parameter creator
 * @export
 */
export const MarketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all areas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsAreasGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/markets/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all markets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/markets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new SisImportJob for specified market
         * @param {number} marketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsMarketIdSisImportJobPost: async (marketId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('apiMarketsMarketIdSisImportJobPost', 'marketId', marketId)
            const localVarPath = `/api/markets/{marketId}/SisImportJob`
                .replace(`{${"marketId"}}`, encodeURIComponent(String(marketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts the   synchronize program and synchronizes the market in the SyncMarketDto.
         * @param {SyncMarketDto} syncMarketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsPost: async (syncMarketDto: SyncMarketDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'syncMarketDto' is not null or undefined
            assertParamExists('apiMarketsPost', 'syncMarketDto', syncMarketDto)
            const localVarPath = `/api/markets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncMarketDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SisImportJob by id.
         * @param {number} sisImportJobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsSisImportJobSisImportJobIdGet: async (sisImportJobId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sisImportJobId' is not null or undefined
            assertParamExists('apiMarketsSisImportJobSisImportJobIdGet', 'sisImportJobId', sisImportJobId)
            const localVarPath = `/api/markets/SisImportJob/{sisImportJobId}`
                .replace(`{${"sisImportJobId"}}`, encodeURIComponent(String(sisImportJobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get markets for user. Default - current user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsUserIdGet: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiMarketsUserIdGet', 'userId', userId)
            const localVarPath = `/api/markets/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketsApi - functional programming interface
 * @export
 */
export const MarketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all areas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsAreasGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreasDtoObjectResultEnvelope>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsAreasGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all markets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketsDtoObjectResultEnvelope>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates new SisImportJob for specified market
         * @param {number} marketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsMarketIdSisImportJobPost(marketId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsMarketIdSisImportJobPost(marketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Starts the   synchronize program and synchronizes the market in the SyncMarketDto.
         * @param {SyncMarketDto} syncMarketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsPost(syncMarketDto: SyncMarketDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsPost(syncMarketDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get SisImportJob by id.
         * @param {number} sisImportJobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsSisImportJobSisImportJobIdGet(sisImportJobId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsSisImportJobSisImportJobIdGet(sisImportJobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get markets for user. Default - current user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsUserIdGet(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketsDtoObjectResultEnvelope>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketsApi - factory interface
 * @export
 */
export const MarketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all areas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsAreasGet(options?: any): AxiosPromise<AreasDtoObjectResultEnvelope> {
            return localVarFp.apiMarketsAreasGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all markets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsGet(options?: any): AxiosPromise<MarketsDtoObjectResultEnvelope> {
            return localVarFp.apiMarketsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new SisImportJob for specified market
         * @param {number} marketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsMarketIdSisImportJobPost(marketId: number, options?: any): AxiosPromise<number> {
            return localVarFp.apiMarketsMarketIdSisImportJobPost(marketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts the   synchronize program and synchronizes the market in the SyncMarketDto.
         * @param {SyncMarketDto} syncMarketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsPost(syncMarketDto: SyncMarketDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiMarketsPost(syncMarketDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get SisImportJob by id.
         * @param {number} sisImportJobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsSisImportJobSisImportJobIdGet(sisImportJobId: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiMarketsSisImportJobSisImportJobIdGet(sisImportJobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get markets for user. Default - current user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsUserIdGet(userId: number, options?: any): AxiosPromise<MarketsDtoObjectResultEnvelope> {
            return localVarFp.apiMarketsUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketsApi - object-oriented interface
 * @export
 * @class MarketsApi
 * @extends {BaseAPI}
 */
export class MarketsApi extends BaseAPI {
    /**
     * 
     * @summary Get all areas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsAreasGet(options?: AxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsAreasGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all markets.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsGet(options?: AxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new SisImportJob for specified market
     * @param {number} marketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsMarketIdSisImportJobPost(marketId: number, options?: AxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsMarketIdSisImportJobPost(marketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts the   synchronize program and synchronizes the market in the SyncMarketDto.
     * @param {SyncMarketDto} syncMarketDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsPost(syncMarketDto: SyncMarketDto, options?: AxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsPost(syncMarketDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get SisImportJob by id.
     * @param {number} sisImportJobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsSisImportJobSisImportJobIdGet(sisImportJobId: number, options?: AxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsSisImportJobSisImportJobIdGet(sisImportJobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get markets for user. Default - current user
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsUserIdGet(userId: number, options?: AxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RemindersApi - axios parameter creator
 * @export
 */
export const RemindersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send mail.
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRemindersActionPlanAuditIdGet: async (auditId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiRemindersActionPlanAuditIdGet', 'auditId', auditId)
            const localVarPath = `/api/Reminders/actionPlan/{auditId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send mail.
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRemindersAuditAuditIdGet: async (auditId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiRemindersAuditAuditIdGet', 'auditId', auditId)
            const localVarPath = `/api/Reminders/audit/{auditId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RemindersApi - functional programming interface
 * @export
 */
export const RemindersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RemindersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send mail.
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRemindersActionPlanAuditIdGet(auditId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRemindersActionPlanAuditIdGet(auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send mail.
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRemindersAuditAuditIdGet(auditId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRemindersAuditAuditIdGet(auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RemindersApi - factory interface
 * @export
 */
export const RemindersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RemindersApiFp(configuration)
    return {
        /**
         * 
         * @summary Send mail.
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRemindersActionPlanAuditIdGet(auditId: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiRemindersActionPlanAuditIdGet(auditId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send mail.
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRemindersAuditAuditIdGet(auditId: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiRemindersAuditAuditIdGet(auditId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RemindersApi - object-oriented interface
 * @export
 * @class RemindersApi
 * @extends {BaseAPI}
 */
export class RemindersApi extends BaseAPI {
    /**
     * 
     * @summary Send mail.
     * @param {number} auditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RemindersApi
     */
    public apiRemindersActionPlanAuditIdGet(auditId: number, options?: AxiosRequestConfig) {
        return RemindersApiFp(this.configuration).apiRemindersActionPlanAuditIdGet(auditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send mail.
     * @param {number} auditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RemindersApi
     */
    public apiRemindersAuditAuditIdGet(auditId: number, options?: AxiosRequestConfig) {
        return RemindersApiFp(this.configuration).apiRemindersAuditAuditIdGet(auditId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get areas report.
         * @param {Array<number>} [areaIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsAreasGet: async (areaIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (areaIds) {
                localVarQueryParameter['areaIds'] = areaIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get country summary report.
         * @param {Array<number>} [marketIds] 
         * @param {Array<number>} [areaIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsCountryGet: async (marketIds?: Array<number>, areaIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (marketIds) {
                localVarQueryParameter['marketIds'] = marketIds;
            }

            if (areaIds) {
                localVarQueryParameter['areaIds'] = areaIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get DOS report.
         * @param {number} [auditId] 
         * @param {boolean} [viewSummary] 
         * @param {boolean} [viewCheckPoints] 
         * @param {boolean} [viewPerspectives] 
         * @param {boolean} [viewDeviations] 
         * @param {boolean} [viewNotes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDOSReportGet: async (auditId?: number, viewSummary?: boolean, viewCheckPoints?: boolean, viewPerspectives?: boolean, viewDeviations?: boolean, viewNotes?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/DOSReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (auditId !== undefined) {
                localVarQueryParameter['auditId'] = auditId;
            }

            if (viewSummary !== undefined) {
                localVarQueryParameter['viewSummary'] = viewSummary;
            }

            if (viewCheckPoints !== undefined) {
                localVarQueryParameter['viewCheckPoints'] = viewCheckPoints;
            }

            if (viewPerspectives !== undefined) {
                localVarQueryParameter['viewPerspectives'] = viewPerspectives;
            }

            if (viewDeviations !== undefined) {
                localVarQueryParameter['viewDeviations'] = viewDeviations;
            }

            if (viewNotes !== undefined) {
                localVarQueryParameter['viewNotes'] = viewNotes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get empty DOS report.
         * @param {number} [dosVersion] 
         * @param {boolean} [viewSummary] 
         * @param {boolean} [viewCheckPoints] 
         * @param {boolean} [viewPerspectives] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsEmptyDOSReportGet: async (dosVersion?: number, viewSummary?: boolean, viewCheckPoints?: boolean, viewPerspectives?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/emptyDOSReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dosVersion !== undefined) {
                localVarQueryParameter['dosVersion'] = dosVersion;
            }

            if (viewSummary !== undefined) {
                localVarQueryParameter['viewSummary'] = viewSummary;
            }

            if (viewCheckPoints !== undefined) {
                localVarQueryParameter['viewCheckPoints'] = viewCheckPoints;
            }

            if (viewPerspectives !== undefined) {
                localVarQueryParameter['viewPerspectives'] = viewPerspectives;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get market deviations report.
         * @param {Array<number>} [marketIds] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsMarketDeviationsGet: async (marketIds?: Array<number>, dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/marketDeviations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (marketIds) {
                localVarQueryParameter['marketIds'] = marketIds;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get areas report.
         * @param {Array<number>} [areaIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsAreasGet(areaIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsAreasGet(areaIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get country summary report.
         * @param {Array<number>} [marketIds] 
         * @param {Array<number>} [areaIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsCountryGet(marketIds?: Array<number>, areaIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsCountryGet(marketIds, areaIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get DOS report.
         * @param {number} [auditId] 
         * @param {boolean} [viewSummary] 
         * @param {boolean} [viewCheckPoints] 
         * @param {boolean} [viewPerspectives] 
         * @param {boolean} [viewDeviations] 
         * @param {boolean} [viewNotes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsDOSReportGet(auditId?: number, viewSummary?: boolean, viewCheckPoints?: boolean, viewPerspectives?: boolean, viewDeviations?: boolean, viewNotes?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsDOSReportGet(auditId, viewSummary, viewCheckPoints, viewPerspectives, viewDeviations, viewNotes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get empty DOS report.
         * @param {number} [dosVersion] 
         * @param {boolean} [viewSummary] 
         * @param {boolean} [viewCheckPoints] 
         * @param {boolean} [viewPerspectives] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsEmptyDOSReportGet(dosVersion?: number, viewSummary?: boolean, viewCheckPoints?: boolean, viewPerspectives?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsEmptyDOSReportGet(dosVersion, viewSummary, viewCheckPoints, viewPerspectives, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get market deviations report.
         * @param {Array<number>} [marketIds] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsMarketDeviationsGet(marketIds?: Array<number>, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsMarketDeviationsGet(marketIds, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get areas report.
         * @param {Array<number>} [areaIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsAreasGet(areaIds?: Array<number>, options?: any): AxiosPromise<string> {
            return localVarFp.apiReportsAreasGet(areaIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get country summary report.
         * @param {Array<number>} [marketIds] 
         * @param {Array<number>} [areaIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsCountryGet(marketIds?: Array<number>, areaIds?: Array<number>, options?: any): AxiosPromise<string> {
            return localVarFp.apiReportsCountryGet(marketIds, areaIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get DOS report.
         * @param {number} [auditId] 
         * @param {boolean} [viewSummary] 
         * @param {boolean} [viewCheckPoints] 
         * @param {boolean} [viewPerspectives] 
         * @param {boolean} [viewDeviations] 
         * @param {boolean} [viewNotes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsDOSReportGet(auditId?: number, viewSummary?: boolean, viewCheckPoints?: boolean, viewPerspectives?: boolean, viewDeviations?: boolean, viewNotes?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.apiReportsDOSReportGet(auditId, viewSummary, viewCheckPoints, viewPerspectives, viewDeviations, viewNotes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get empty DOS report.
         * @param {number} [dosVersion] 
         * @param {boolean} [viewSummary] 
         * @param {boolean} [viewCheckPoints] 
         * @param {boolean} [viewPerspectives] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsEmptyDOSReportGet(dosVersion?: number, viewSummary?: boolean, viewCheckPoints?: boolean, viewPerspectives?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.apiReportsEmptyDOSReportGet(dosVersion, viewSummary, viewCheckPoints, viewPerspectives, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get market deviations report.
         * @param {Array<number>} [marketIds] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsMarketDeviationsGet(marketIds?: Array<number>, dateFrom?: string, dateTo?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiReportsMarketDeviationsGet(marketIds, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Get areas report.
     * @param {Array<number>} [areaIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsAreasGet(areaIds?: Array<number>, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsAreasGet(areaIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get country summary report.
     * @param {Array<number>} [marketIds] 
     * @param {Array<number>} [areaIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsCountryGet(marketIds?: Array<number>, areaIds?: Array<number>, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsCountryGet(marketIds, areaIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get DOS report.
     * @param {number} [auditId] 
     * @param {boolean} [viewSummary] 
     * @param {boolean} [viewCheckPoints] 
     * @param {boolean} [viewPerspectives] 
     * @param {boolean} [viewDeviations] 
     * @param {boolean} [viewNotes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsDOSReportGet(auditId?: number, viewSummary?: boolean, viewCheckPoints?: boolean, viewPerspectives?: boolean, viewDeviations?: boolean, viewNotes?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsDOSReportGet(auditId, viewSummary, viewCheckPoints, viewPerspectives, viewDeviations, viewNotes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get empty DOS report.
     * @param {number} [dosVersion] 
     * @param {boolean} [viewSummary] 
     * @param {boolean} [viewCheckPoints] 
     * @param {boolean} [viewPerspectives] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsEmptyDOSReportGet(dosVersion?: number, viewSummary?: boolean, viewCheckPoints?: boolean, viewPerspectives?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsEmptyDOSReportGet(dosVersion, viewSummary, viewCheckPoints, viewPerspectives, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get market deviations report.
     * @param {Array<number>} [marketIds] 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsMarketDeviationsGet(marketIds?: Array<number>, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsMarketDeviationsGet(marketIds, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageApi - axios parameter creator
 * @export
 */
export const StorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user files for deviation.
         * @param {number} actionPlanItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageADHListFilesDeviationGet: async (actionPlanItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionPlanItemId' is not null or undefined
            assertParamExists('apiStorageADHListFilesDeviationGet', 'actionPlanItemId', actionPlanItemId)
            const localVarPath = `/api/Storage/ADH/ListFiles/deviation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (actionPlanItemId !== undefined) {
                localVarQueryParameter['actionPlanItemId'] = actionPlanItemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} auditPointId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageDeleteAuditAuditIdAuditPointAuditPointIdFileFileIdDelete: async (auditId: number, auditPointId: number, fileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiStorageDeleteAuditAuditIdAuditPointAuditPointIdFileFileIdDelete', 'auditId', auditId)
            // verify required parameter 'auditPointId' is not null or undefined
            assertParamExists('apiStorageDeleteAuditAuditIdAuditPointAuditPointIdFileFileIdDelete', 'auditPointId', auditPointId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiStorageDeleteAuditAuditIdAuditPointAuditPointIdFileFileIdDelete', 'fileId', fileId)
            const localVarPath = `/api/Storage/Delete/Audit/{auditId}/AuditPoint/{auditPointId}/File/{fileId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)))
                .replace(`{${"auditPointId"}}`, encodeURIComponent(String(auditPointId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageDeleteAuditAuditIdFileFileIdDelete: async (auditId: number, fileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiStorageDeleteAuditAuditIdFileFileIdDelete', 'auditId', auditId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiStorageDeleteAuditAuditIdFileFileIdDelete', 'fileId', fileId)
            const localVarPath = `/api/Storage/Delete/Audit/{auditId}/File/{fileId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} pledgeId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageDeleteAuditAuditIdPledgePledgeIdFileFileIdDelete: async (auditId: number, pledgeId: number, fileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiStorageDeleteAuditAuditIdPledgePledgeIdFileFileIdDelete', 'auditId', auditId)
            // verify required parameter 'pledgeId' is not null or undefined
            assertParamExists('apiStorageDeleteAuditAuditIdPledgePledgeIdFileFileIdDelete', 'pledgeId', pledgeId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiStorageDeleteAuditAuditIdPledgePledgeIdFileFileIdDelete', 'fileId', fileId)
            const localVarPath = `/api/Storage/Delete/Audit/{auditId}/Pledge/{pledgeId}/File/{fileId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)))
                .replace(`{${"pledgeId"}}`, encodeURIComponent(String(pledgeId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealerId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageDeleteDealerDealerIdFileFileIdDelete: async (dealerId: number, fileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealerId' is not null or undefined
            assertParamExists('apiStorageDeleteDealerDealerIdFileFileIdDelete', 'dealerId', dealerId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiStorageDeleteDealerDealerIdFileFileIdDelete', 'fileId', fileId)
            const localVarPath = `/api/Storage/Delete/Dealer/{dealerId}/File/{fileId}`
                .replace(`{${"dealerId"}}`, encodeURIComponent(String(dealerId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviationId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageDeleteDeviationDeviationIdFileFileIdDelete: async (deviationId: number, fileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviationId' is not null or undefined
            assertParamExists('apiStorageDeleteDeviationDeviationIdFileFileIdDelete', 'deviationId', deviationId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiStorageDeleteDeviationDeviationIdFileFileIdDelete', 'fileId', fileId)
            const localVarPath = `/api/Storage/Delete/Deviation/{deviationId}/File/{fileId}`
                .replace(`{${"deviationId"}}`, encodeURIComponent(String(deviationId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageDeleteDocumentDelete: async (fileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiStorageDeleteDocumentDelete', 'fileId', fileId)
            const localVarPath = `/api/Storage/Delete/Document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageGetFileDocumentFileIdGet: async (fileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiStorageGetFileDocumentFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/Storage/GetFile/Document/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageGetFilePathGet: async (path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('apiStorageGetFilePathGet', 'path', path)
            const localVarPath = `/api/Storage/GetFile/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageGetFileUrlKeyGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiStorageGetFileUrlKeyGet', 'key', key)
            const localVarPath = `/api/Storage/GetFileUrl/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} auditPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageListFilesAuditAuditIdAuditPointAuditPointIdGet: async (auditId: number, auditPointId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiStorageListFilesAuditAuditIdAuditPointAuditPointIdGet', 'auditId', auditId)
            // verify required parameter 'auditPointId' is not null or undefined
            assertParamExists('apiStorageListFilesAuditAuditIdAuditPointAuditPointIdGet', 'auditPointId', auditPointId)
            const localVarPath = `/api/Storage/ListFiles/Audit/{auditId}/AuditPoint/{auditPointId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)))
                .replace(`{${"auditPointId"}}`, encodeURIComponent(String(auditPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageListFilesAuditAuditIdGet: async (auditId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiStorageListFilesAuditAuditIdGet', 'auditId', auditId)
            const localVarPath = `/api/Storage/ListFiles/Audit/{auditId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} pledgeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageListFilesAuditAuditIdPledgePledgeIdGet: async (auditId: number, pledgeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiStorageListFilesAuditAuditIdPledgePledgeIdGet', 'auditId', auditId)
            // verify required parameter 'pledgeId' is not null or undefined
            assertParamExists('apiStorageListFilesAuditAuditIdPledgePledgeIdGet', 'pledgeId', pledgeId)
            const localVarPath = `/api/Storage/ListFiles/Audit/{auditId}/Pledge/{pledgeId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)))
                .replace(`{${"pledgeId"}}`, encodeURIComponent(String(pledgeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageListFilesDealerDealerIdGet: async (dealerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealerId' is not null or undefined
            assertParamExists('apiStorageListFilesDealerDealerIdGet', 'dealerId', dealerId)
            const localVarPath = `/api/Storage/ListFiles/Dealer/{dealerId}`
                .replace(`{${"dealerId"}}`, encodeURIComponent(String(dealerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageListFilesDeviationDeviationIdGet: async (deviationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviationId' is not null or undefined
            assertParamExists('apiStorageListFilesDeviationDeviationIdGet', 'deviationId', deviationId)
            const localVarPath = `/api/Storage/ListFiles/Deviation/{deviationId}`
                .replace(`{${"deviationId"}}`, encodeURIComponent(String(deviationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageListFilesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Storage/ListFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fileId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageReplaceDocumentPut: async (fileId: number, fileToUpload?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiStorageReplaceDocumentPut', 'fileId', fileId)
            const localVarPath = `/api/Storage/Replace/Document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }


            if (fileToUpload !== undefined) { 
                localVarFormParams.append('fileToUpload', fileToUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deviationId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFileDeviationDeviationIdPost: async (deviationId: number, fileToUpload?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviationId' is not null or undefined
            assertParamExists('apiStorageUploadFileDeviationDeviationIdPost', 'deviationId', deviationId)
            const localVarPath = `/api/Storage/UploadFile/Deviation/{deviationId}`
                .replace(`{${"deviationId"}}`, encodeURIComponent(String(deviationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (fileToUpload !== undefined) { 
                localVarFormParams.append('fileToUpload', fileToUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFileDocumentPost: async (fileToUpload?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Storage/UploadFile/Document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (fileToUpload !== undefined) { 
                localVarFormParams.append('fileToUpload', fileToUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilePost: async (files?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Storage/UploadFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} auditPointId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilesAuditAuditIdAuditPointAuditPointIdPost: async (auditId: number, auditPointId: number, fileToUpload?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiStorageUploadFilesAuditAuditIdAuditPointAuditPointIdPost', 'auditId', auditId)
            // verify required parameter 'auditPointId' is not null or undefined
            assertParamExists('apiStorageUploadFilesAuditAuditIdAuditPointAuditPointIdPost', 'auditPointId', auditPointId)
            const localVarPath = `/api/Storage/UploadFiles/Audit/{auditId}/AuditPoint/{auditPointId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)))
                .replace(`{${"auditPointId"}}`, encodeURIComponent(String(auditPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (fileToUpload !== undefined) { 
                localVarFormParams.append('fileToUpload', fileToUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} pledgeId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilesAuditAuditIdPledgePledgeIdPost: async (auditId: number, pledgeId: number, fileToUpload?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiStorageUploadFilesAuditAuditIdPledgePledgeIdPost', 'auditId', auditId)
            // verify required parameter 'pledgeId' is not null or undefined
            assertParamExists('apiStorageUploadFilesAuditAuditIdPledgePledgeIdPost', 'pledgeId', pledgeId)
            const localVarPath = `/api/Storage/UploadFiles/Audit/{auditId}/Pledge/{pledgeId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)))
                .replace(`{${"pledgeId"}}`, encodeURIComponent(String(pledgeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (fileToUpload !== undefined) { 
                localVarFormParams.append('fileToUpload', fileToUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auditId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilesAuditAuditIdPost: async (auditId: number, fileToUpload?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiStorageUploadFilesAuditAuditIdPost', 'auditId', auditId)
            const localVarPath = `/api/Storage/UploadFiles/Audit/{auditId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (fileToUpload !== undefined) { 
                localVarFormParams.append('fileToUpload', fileToUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} deviationId 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilesAuditIdDeviationDeviationIdPost: async (auditId: number, deviationId: number, files?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditId' is not null or undefined
            assertParamExists('apiStorageUploadFilesAuditIdDeviationDeviationIdPost', 'auditId', auditId)
            // verify required parameter 'deviationId' is not null or undefined
            assertParamExists('apiStorageUploadFilesAuditIdDeviationDeviationIdPost', 'deviationId', deviationId)
            const localVarPath = `/api/Storage/UploadFiles/{auditId}/Deviation/{deviationId}`
                .replace(`{${"auditId"}}`, encodeURIComponent(String(auditId)))
                .replace(`{${"deviationId"}}`, encodeURIComponent(String(deviationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealerId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilesDealerDealerIdPost: async (dealerId: number, fileToUpload?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealerId' is not null or undefined
            assertParamExists('apiStorageUploadFilesDealerDealerIdPost', 'dealerId', dealerId)
            const localVarPath = `/api/Storage/UploadFiles/Dealer/{dealerId}`
                .replace(`{${"dealerId"}}`, encodeURIComponent(String(dealerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (fileToUpload !== undefined) { 
                localVarFormParams.append('fileToUpload', fileToUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilesFileIdDelete: async (fileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiStorageUploadFilesFileIdDelete', 'fileId', fileId)
            const localVarPath = `/api/Storage/UploadFiles/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageApi - functional programming interface
 * @export
 */
export const StorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get user files for deviation.
         * @param {number} actionPlanItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageADHListFilesDeviationGet(actionPlanItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageADHListFilesDeviationGet(actionPlanItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} auditPointId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageDeleteAuditAuditIdAuditPointAuditPointIdFileFileIdDelete(auditId: number, auditPointId: number, fileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageDeleteAuditAuditIdAuditPointAuditPointIdFileFileIdDelete(auditId, auditPointId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageDeleteAuditAuditIdFileFileIdDelete(auditId: number, fileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageDeleteAuditAuditIdFileFileIdDelete(auditId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} pledgeId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageDeleteAuditAuditIdPledgePledgeIdFileFileIdDelete(auditId: number, pledgeId: number, fileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageDeleteAuditAuditIdPledgePledgeIdFileFileIdDelete(auditId, pledgeId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealerId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageDeleteDealerDealerIdFileFileIdDelete(dealerId: number, fileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageDeleteDealerDealerIdFileFileIdDelete(dealerId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} deviationId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageDeleteDeviationDeviationIdFileFileIdDelete(deviationId: number, fileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageDeleteDeviationDeviationIdFileFileIdDelete(deviationId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageDeleteDocumentDelete(fileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageDeleteDocumentDelete(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageGetFileDocumentFileIdGet(fileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageGetFileDocumentFileIdGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageGetFilePathGet(path: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageGetFilePathGet(path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageGetFileUrlKeyGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageGetFileUrlKeyGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} auditPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageListFilesAuditAuditIdAuditPointAuditPointIdGet(auditId: number, auditPointId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageListFilesAuditAuditIdAuditPointAuditPointIdGet(auditId, auditPointId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageListFilesAuditAuditIdGet(auditId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageListFilesAuditAuditIdGet(auditId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} pledgeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageListFilesAuditAuditIdPledgePledgeIdGet(auditId: number, pledgeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageListFilesAuditAuditIdPledgePledgeIdGet(auditId, pledgeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageListFilesDealerDealerIdGet(dealerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DealerFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageListFilesDealerDealerIdGet(dealerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} deviationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageListFilesDeviationDeviationIdGet(deviationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageListFilesDeviationDeviationIdGet(deviationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageListFilesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageListFilesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fileId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageReplaceDocumentPut(fileId: number, fileToUpload?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageReplaceDocumentPut(fileId, fileToUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} deviationId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageUploadFileDeviationDeviationIdPost(deviationId: number, fileToUpload?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageUploadFileDeviationDeviationIdPost(deviationId, fileToUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageUploadFileDocumentPost(fileToUpload?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageUploadFileDocumentPost(fileToUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageUploadFilePost(files?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageUploadFilePost(files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} auditPointId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageUploadFilesAuditAuditIdAuditPointAuditPointIdPost(auditId: number, auditPointId: number, fileToUpload?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageUploadFilesAuditAuditIdAuditPointAuditPointIdPost(auditId, auditPointId, fileToUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} pledgeId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageUploadFilesAuditAuditIdPledgePledgeIdPost(auditId: number, pledgeId: number, fileToUpload?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageUploadFilesAuditAuditIdPledgePledgeIdPost(auditId, pledgeId, fileToUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auditId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageUploadFilesAuditAuditIdPost(auditId: number, fileToUpload?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageUploadFilesAuditAuditIdPost(auditId, fileToUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} deviationId 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageUploadFilesAuditIdDeviationDeviationIdPost(auditId: number, deviationId: number, files?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageUploadFilesAuditIdDeviationDeviationIdPost(auditId, deviationId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealerId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageUploadFilesDealerDealerIdPost(dealerId: number, fileToUpload?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadDealerFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageUploadFilesDealerDealerIdPost(dealerId, fileToUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorageUploadFilesFileIdDelete(fileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorageUploadFilesFileIdDelete(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageApi - factory interface
 * @export
 */
export const StorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageApiFp(configuration)
    return {
        /**
         * 
         * @summary Get user files for deviation.
         * @param {number} actionPlanItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageADHListFilesDeviationGet(actionPlanItemId: number, options?: any): AxiosPromise<Array<UserFileDto>> {
            return localVarFp.apiStorageADHListFilesDeviationGet(actionPlanItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} auditPointId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageDeleteAuditAuditIdAuditPointAuditPointIdFileFileIdDelete(auditId: number, auditPointId: number, fileId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiStorageDeleteAuditAuditIdAuditPointAuditPointIdFileFileIdDelete(auditId, auditPointId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageDeleteAuditAuditIdFileFileIdDelete(auditId: number, fileId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiStorageDeleteAuditAuditIdFileFileIdDelete(auditId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} pledgeId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageDeleteAuditAuditIdPledgePledgeIdFileFileIdDelete(auditId: number, pledgeId: number, fileId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiStorageDeleteAuditAuditIdPledgePledgeIdFileFileIdDelete(auditId, pledgeId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealerId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageDeleteDealerDealerIdFileFileIdDelete(dealerId: number, fileId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiStorageDeleteDealerDealerIdFileFileIdDelete(dealerId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviationId 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageDeleteDeviationDeviationIdFileFileIdDelete(deviationId: number, fileId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiStorageDeleteDeviationDeviationIdFileFileIdDelete(deviationId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageDeleteDocumentDelete(fileId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiStorageDeleteDocumentDelete(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageGetFileDocumentFileIdGet(fileId: number, options?: any): AxiosPromise<DocumentFileDto> {
            return localVarFp.apiStorageGetFileDocumentFileIdGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageGetFilePathGet(path: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiStorageGetFilePathGet(path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageGetFileUrlKeyGet(key: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiStorageGetFileUrlKeyGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} auditPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageListFilesAuditAuditIdAuditPointAuditPointIdGet(auditId: number, auditPointId: number, options?: any): AxiosPromise<Array<UserFileDto>> {
            return localVarFp.apiStorageListFilesAuditAuditIdAuditPointAuditPointIdGet(auditId, auditPointId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageListFilesAuditAuditIdGet(auditId: number, options?: any): AxiosPromise<Array<UserFileDto>> {
            return localVarFp.apiStorageListFilesAuditAuditIdGet(auditId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} pledgeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageListFilesAuditAuditIdPledgePledgeIdGet(auditId: number, pledgeId: number, options?: any): AxiosPromise<Array<UserFileDto>> {
            return localVarFp.apiStorageListFilesAuditAuditIdPledgePledgeIdGet(auditId, pledgeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageListFilesDealerDealerIdGet(dealerId: number, options?: any): AxiosPromise<Array<DealerFileDto>> {
            return localVarFp.apiStorageListFilesDealerDealerIdGet(dealerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageListFilesDeviationDeviationIdGet(deviationId: number, options?: any): AxiosPromise<Array<UserFileDto>> {
            return localVarFp.apiStorageListFilesDeviationDeviationIdGet(deviationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageListFilesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiStorageListFilesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fileId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageReplaceDocumentPut(fileId: number, fileToUpload?: any, options?: any): AxiosPromise<number> {
            return localVarFp.apiStorageReplaceDocumentPut(fileId, fileToUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deviationId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFileDeviationDeviationIdPost(deviationId: number, fileToUpload?: any, options?: any): AxiosPromise<UploadFileDto> {
            return localVarFp.apiStorageUploadFileDeviationDeviationIdPost(deviationId, fileToUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFileDocumentPost(fileToUpload?: any, options?: any): AxiosPromise<number> {
            return localVarFp.apiStorageUploadFileDocumentPost(fileToUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilePost(files?: Array<any>, options?: any): AxiosPromise<void> {
            return localVarFp.apiStorageUploadFilePost(files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} auditPointId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilesAuditAuditIdAuditPointAuditPointIdPost(auditId: number, auditPointId: number, fileToUpload?: any, options?: any): AxiosPromise<UploadFileDto> {
            return localVarFp.apiStorageUploadFilesAuditAuditIdAuditPointAuditPointIdPost(auditId, auditPointId, fileToUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} pledgeId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilesAuditAuditIdPledgePledgeIdPost(auditId: number, pledgeId: number, fileToUpload?: any, options?: any): AxiosPromise<UploadFileDto> {
            return localVarFp.apiStorageUploadFilesAuditAuditIdPledgePledgeIdPost(auditId, pledgeId, fileToUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auditId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilesAuditAuditIdPost(auditId: number, fileToUpload?: any, options?: any): AxiosPromise<UploadFileDto> {
            return localVarFp.apiStorageUploadFilesAuditAuditIdPost(auditId, fileToUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auditId 
         * @param {number} deviationId 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilesAuditIdDeviationDeviationIdPost(auditId: number, deviationId: number, files?: Array<any>, options?: any): AxiosPromise<void> {
            return localVarFp.apiStorageUploadFilesAuditIdDeviationDeviationIdPost(auditId, deviationId, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealerId 
         * @param {any} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilesDealerDealerIdPost(dealerId: number, fileToUpload?: any, options?: any): AxiosPromise<UploadDealerFileDto> {
            return localVarFp.apiStorageUploadFilesDealerDealerIdPost(dealerId, fileToUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorageUploadFilesFileIdDelete(fileId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiStorageUploadFilesFileIdDelete(fileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorageApi - object-oriented interface
 * @export
 * @class StorageApi
 * @extends {BaseAPI}
 */
export class StorageApi extends BaseAPI {
    /**
     * 
     * @summary Get user files for deviation.
     * @param {number} actionPlanItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageADHListFilesDeviationGet(actionPlanItemId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageADHListFilesDeviationGet(actionPlanItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} auditId 
     * @param {number} auditPointId 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageDeleteAuditAuditIdAuditPointAuditPointIdFileFileIdDelete(auditId: number, auditPointId: number, fileId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageDeleteAuditAuditIdAuditPointAuditPointIdFileFileIdDelete(auditId, auditPointId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} auditId 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageDeleteAuditAuditIdFileFileIdDelete(auditId: number, fileId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageDeleteAuditAuditIdFileFileIdDelete(auditId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} auditId 
     * @param {number} pledgeId 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageDeleteAuditAuditIdPledgePledgeIdFileFileIdDelete(auditId: number, pledgeId: number, fileId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageDeleteAuditAuditIdPledgePledgeIdFileFileIdDelete(auditId, pledgeId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dealerId 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageDeleteDealerDealerIdFileFileIdDelete(dealerId: number, fileId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageDeleteDealerDealerIdFileFileIdDelete(dealerId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviationId 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageDeleteDeviationDeviationIdFileFileIdDelete(deviationId: number, fileId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageDeleteDeviationDeviationIdFileFileIdDelete(deviationId, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageDeleteDocumentDelete(fileId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageDeleteDocumentDelete(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageGetFileDocumentFileIdGet(fileId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageGetFileDocumentFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} path 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageGetFilePathGet(path: string, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageGetFilePathGet(path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageGetFileUrlKeyGet(key: string, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageGetFileUrlKeyGet(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} auditId 
     * @param {number} auditPointId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageListFilesAuditAuditIdAuditPointAuditPointIdGet(auditId: number, auditPointId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageListFilesAuditAuditIdAuditPointAuditPointIdGet(auditId, auditPointId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} auditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageListFilesAuditAuditIdGet(auditId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageListFilesAuditAuditIdGet(auditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} auditId 
     * @param {number} pledgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageListFilesAuditAuditIdPledgePledgeIdGet(auditId: number, pledgeId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageListFilesAuditAuditIdPledgePledgeIdGet(auditId, pledgeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dealerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageListFilesDealerDealerIdGet(dealerId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageListFilesDealerDealerIdGet(dealerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageListFilesDeviationDeviationIdGet(deviationId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageListFilesDeviationDeviationIdGet(deviationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageListFilesGet(options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageListFilesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fileId 
     * @param {any} [fileToUpload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageReplaceDocumentPut(fileId: number, fileToUpload?: any, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageReplaceDocumentPut(fileId, fileToUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deviationId 
     * @param {any} [fileToUpload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageUploadFileDeviationDeviationIdPost(deviationId: number, fileToUpload?: any, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageUploadFileDeviationDeviationIdPost(deviationId, fileToUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [fileToUpload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageUploadFileDocumentPost(fileToUpload?: any, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageUploadFileDocumentPost(fileToUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<any>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageUploadFilePost(files?: Array<any>, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageUploadFilePost(files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} auditId 
     * @param {number} auditPointId 
     * @param {any} [fileToUpload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageUploadFilesAuditAuditIdAuditPointAuditPointIdPost(auditId: number, auditPointId: number, fileToUpload?: any, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageUploadFilesAuditAuditIdAuditPointAuditPointIdPost(auditId, auditPointId, fileToUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} auditId 
     * @param {number} pledgeId 
     * @param {any} [fileToUpload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageUploadFilesAuditAuditIdPledgePledgeIdPost(auditId: number, pledgeId: number, fileToUpload?: any, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageUploadFilesAuditAuditIdPledgePledgeIdPost(auditId, pledgeId, fileToUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} auditId 
     * @param {any} [fileToUpload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageUploadFilesAuditAuditIdPost(auditId: number, fileToUpload?: any, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageUploadFilesAuditAuditIdPost(auditId, fileToUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} auditId 
     * @param {number} deviationId 
     * @param {Array<any>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageUploadFilesAuditIdDeviationDeviationIdPost(auditId: number, deviationId: number, files?: Array<any>, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageUploadFilesAuditIdDeviationDeviationIdPost(auditId, deviationId, files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dealerId 
     * @param {any} [fileToUpload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageUploadFilesDealerDealerIdPost(dealerId: number, fileToUpload?: any, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageUploadFilesDealerDealerIdPost(dealerId, fileToUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public apiStorageUploadFilesFileIdDelete(fileId: number, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).apiStorageUploadFilesFileIdDelete(fileId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemMessagesApi - axios parameter creator
 * @export
 */
export const SystemMessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all SystemMessages.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SystemMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves a new system message.
         * @param {SystemMessageCreateDto} systemMessageCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesPost: async (systemMessageCreateDto: SystemMessageCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemMessageCreateDto' is not null or undefined
            assertParamExists('apiSystemMessagesPost', 'systemMessageCreateDto', systemMessageCreateDto)
            const localVarPath = `/api/SystemMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemMessageCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a system message
         * @param {SystemMessageUpdateDto} [systemMessageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesPut: async (systemMessageUpdateDto?: SystemMessageUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SystemMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemMessageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets a SystemMessage as read for this user.
         * @param {number} systemMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesReadSystemMessageIdPut: async (systemMessageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemMessageId' is not null or undefined
            assertParamExists('apiSystemMessagesReadSystemMessageIdPut', 'systemMessageId', systemMessageId)
            const localVarPath = `/api/SystemMessages/read/{systemMessageId}`
                .replace(`{${"systemMessageId"}}`, encodeURIComponent(String(systemMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete system message with Id.
         * @param {number} systemMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesSystemMessageIdDelete: async (systemMessageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemMessageId' is not null or undefined
            assertParamExists('apiSystemMessagesSystemMessageIdDelete', 'systemMessageId', systemMessageId)
            const localVarPath = `/api/SystemMessages/{systemMessageId}`
                .replace(`{${"systemMessageId"}}`, encodeURIComponent(String(systemMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a system message.
         * @param {number} systemMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesSystemMessageIdGet: async (systemMessageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemMessageId' is not null or undefined
            assertParamExists('apiSystemMessagesSystemMessageIdGet', 'systemMessageId', systemMessageId)
            const localVarPath = `/api/SystemMessages/{systemMessageId}`
                .replace(`{${"systemMessageId"}}`, encodeURIComponent(String(systemMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all unread SystemMessages for a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesUnreadGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SystemMessages/unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemMessagesApi - functional programming interface
 * @export
 */
export const SystemMessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemMessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all SystemMessages.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSystemMessagesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemMessagesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSystemMessagesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves a new system message.
         * @param {SystemMessageCreateDto} systemMessageCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSystemMessagesPost(systemMessageCreateDto: SystemMessageCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSystemMessagesPost(systemMessageCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a system message
         * @param {SystemMessageUpdateDto} [systemMessageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSystemMessagesPut(systemMessageUpdateDto?: SystemMessageUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSystemMessagesPut(systemMessageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets a SystemMessage as read for this user.
         * @param {number} systemMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSystemMessagesReadSystemMessageIdPut(systemMessageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSystemMessagesReadSystemMessageIdPut(systemMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete system message with Id.
         * @param {number} systemMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSystemMessagesSystemMessageIdDelete(systemMessageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSystemMessagesSystemMessageIdDelete(systemMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a system message.
         * @param {number} systemMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSystemMessagesSystemMessageIdGet(systemMessageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSystemMessagesSystemMessageIdGet(systemMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all unread SystemMessages for a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSystemMessagesUnreadGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemMessagesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSystemMessagesUnreadGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemMessagesApi - factory interface
 * @export
 */
export const SystemMessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemMessagesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all SystemMessages.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesGet(options?: any): AxiosPromise<SystemMessagesDto> {
            return localVarFp.apiSystemMessagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves a new system message.
         * @param {SystemMessageCreateDto} systemMessageCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesPost(systemMessageCreateDto: SystemMessageCreateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiSystemMessagesPost(systemMessageCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a system message
         * @param {SystemMessageUpdateDto} [systemMessageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesPut(systemMessageUpdateDto?: SystemMessageUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiSystemMessagesPut(systemMessageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets a SystemMessage as read for this user.
         * @param {number} systemMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesReadSystemMessageIdPut(systemMessageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiSystemMessagesReadSystemMessageIdPut(systemMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete system message with Id.
         * @param {number} systemMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesSystemMessageIdDelete(systemMessageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiSystemMessagesSystemMessageIdDelete(systemMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a system message.
         * @param {number} systemMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesSystemMessageIdGet(systemMessageId: number, options?: any): AxiosPromise<SystemMessage> {
            return localVarFp.apiSystemMessagesSystemMessageIdGet(systemMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all unread SystemMessages for a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessagesUnreadGet(options?: any): AxiosPromise<SystemMessagesDto> {
            return localVarFp.apiSystemMessagesUnreadGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemMessagesApi - object-oriented interface
 * @export
 * @class SystemMessagesApi
 * @extends {BaseAPI}
 */
export class SystemMessagesApi extends BaseAPI {
    /**
     * 
     * @summary Get all SystemMessages.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemMessagesApi
     */
    public apiSystemMessagesGet(options?: AxiosRequestConfig) {
        return SystemMessagesApiFp(this.configuration).apiSystemMessagesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves a new system message.
     * @param {SystemMessageCreateDto} systemMessageCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemMessagesApi
     */
    public apiSystemMessagesPost(systemMessageCreateDto: SystemMessageCreateDto, options?: AxiosRequestConfig) {
        return SystemMessagesApiFp(this.configuration).apiSystemMessagesPost(systemMessageCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a system message
     * @param {SystemMessageUpdateDto} [systemMessageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemMessagesApi
     */
    public apiSystemMessagesPut(systemMessageUpdateDto?: SystemMessageUpdateDto, options?: AxiosRequestConfig) {
        return SystemMessagesApiFp(this.configuration).apiSystemMessagesPut(systemMessageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets a SystemMessage as read for this user.
     * @param {number} systemMessageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemMessagesApi
     */
    public apiSystemMessagesReadSystemMessageIdPut(systemMessageId: number, options?: AxiosRequestConfig) {
        return SystemMessagesApiFp(this.configuration).apiSystemMessagesReadSystemMessageIdPut(systemMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete system message with Id.
     * @param {number} systemMessageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemMessagesApi
     */
    public apiSystemMessagesSystemMessageIdDelete(systemMessageId: number, options?: AxiosRequestConfig) {
        return SystemMessagesApiFp(this.configuration).apiSystemMessagesSystemMessageIdDelete(systemMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a system message.
     * @param {number} systemMessageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemMessagesApi
     */
    public apiSystemMessagesSystemMessageIdGet(systemMessageId: number, options?: AxiosRequestConfig) {
        return SystemMessagesApiFp(this.configuration).apiSystemMessagesSystemMessageIdGet(systemMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all unread SystemMessages for a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemMessagesApi
     */
    public apiSystemMessagesUnreadGet(options?: AxiosRequestConfig) {
        return SystemMessagesApiFp(this.configuration).apiSystemMessagesUnreadGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TranslationsApi - axios parameter creator
 * @export
 */
export const TranslationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsAdminTranslationsDosVersionLanguageIdGet: async (dosVersion: number, languageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dosVersion' is not null or undefined
            assertParamExists('apiTranslationsAdminTranslationsDosVersionLanguageIdGet', 'dosVersion', dosVersion)
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('apiTranslationsAdminTranslationsDosVersionLanguageIdGet', 'languageId', languageId)
            const localVarPath = `/api/Translations/adminTranslations/{dosVersion}/{languageId}`
                .replace(`{${"dosVersion"}}`, encodeURIComponent(String(dosVersion)))
                .replace(`{${"languageId"}}`, encodeURIComponent(String(languageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TranslationCreateDto} translationCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsAdminTranslationsPost: async (translationCreateDto: TranslationCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'translationCreateDto' is not null or undefined
            assertParamExists('apiTranslationsAdminTranslationsPost', 'translationCreateDto', translationCreateDto)
            const localVarPath = `/api/Translations/adminTranslations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translationCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsAuditPointsDosVersionLanguageIdGet: async (dosVersion: number, languageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dosVersion' is not null or undefined
            assertParamExists('apiTranslationsAuditPointsDosVersionLanguageIdGet', 'dosVersion', dosVersion)
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('apiTranslationsAuditPointsDosVersionLanguageIdGet', 'languageId', languageId)
            const localVarPath = `/api/Translations/auditPoints/{dosVersion}/{languageId}`
                .replace(`{${"dosVersion"}}`, encodeURIComponent(String(dosVersion)))
                .replace(`{${"languageId"}}`, encodeURIComponent(String(languageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsCheckPointsDosVersionLanguageIdGet: async (dosVersion: number, languageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dosVersion' is not null or undefined
            assertParamExists('apiTranslationsCheckPointsDosVersionLanguageIdGet', 'dosVersion', dosVersion)
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('apiTranslationsCheckPointsDosVersionLanguageIdGet', 'languageId', languageId)
            const localVarPath = `/api/Translations/checkPoints/{dosVersion}/{languageId}`
                .replace(`{${"dosVersion"}}`, encodeURIComponent(String(dosVersion)))
                .replace(`{${"languageId"}}`, encodeURIComponent(String(languageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsPledgePerspectivesDosVersionLanguageIdGet: async (dosVersion: number, languageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dosVersion' is not null or undefined
            assertParamExists('apiTranslationsPledgePerspectivesDosVersionLanguageIdGet', 'dosVersion', dosVersion)
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('apiTranslationsPledgePerspectivesDosVersionLanguageIdGet', 'languageId', languageId)
            const localVarPath = `/api/Translations/pledgePerspectives/{dosVersion}/{languageId}`
                .replace(`{${"dosVersion"}}`, encodeURIComponent(String(dosVersion)))
                .replace(`{${"languageId"}}`, encodeURIComponent(String(languageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsPledgesDosVersionLanguageIdGet: async (dosVersion: number, languageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dosVersion' is not null or undefined
            assertParamExists('apiTranslationsPledgesDosVersionLanguageIdGet', 'dosVersion', dosVersion)
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('apiTranslationsPledgesDosVersionLanguageIdGet', 'languageId', languageId)
            const localVarPath = `/api/Translations/pledges/{dosVersion}/{languageId}`
                .replace(`{${"dosVersion"}}`, encodeURIComponent(String(dosVersion)))
                .replace(`{${"languageId"}}`, encodeURIComponent(String(languageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} translationId 
         * @param {TranslationUpdateDto} [translationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsTranslationIdPut: async (translationId: number, translationUpdateDto?: TranslationUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'translationId' is not null or undefined
            assertParamExists('apiTranslationsTranslationIdPut', 'translationId', translationId)
            const localVarPath = `/api/Translations/{translationId}`
                .replace(`{${"translationId"}}`, encodeURIComponent(String(translationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsTranslationsDosVersionLanguageIdGet: async (dosVersion: number, languageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dosVersion' is not null or undefined
            assertParamExists('apiTranslationsTranslationsDosVersionLanguageIdGet', 'dosVersion', dosVersion)
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('apiTranslationsTranslationsDosVersionLanguageIdGet', 'languageId', languageId)
            const localVarPath = `/api/Translations/translations/{dosVersion}/{languageId}`
                .replace(`{${"dosVersion"}}`, encodeURIComponent(String(dosVersion)))
                .replace(`{${"languageId"}}`, encodeURIComponent(String(languageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [languageIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsTranslationsVersionsGet: async (languageIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Translations/translations/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (languageIds) {
                localVarQueryParameter['languageIds'] = languageIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationsApi - functional programming interface
 * @export
 */
export const TranslationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TranslationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationsAdminTranslationsDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTranslationsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationsAdminTranslationsDosVersionLanguageIdGet(dosVersion, languageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TranslationCreateDto} translationCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationsAdminTranslationsPost(translationCreateDto: TranslationCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationsAdminTranslationsPost(translationCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationsAuditPointsDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditPointsTranslationsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationsAuditPointsDosVersionLanguageIdGet(dosVersion, languageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationsCheckPointsDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckPointsTranslationsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationsCheckPointsDosVersionLanguageIdGet(dosVersion, languageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationsPledgePerspectivesDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PledgePerspectivesTranslationsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationsPledgePerspectivesDosVersionLanguageIdGet(dosVersion, languageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationsPledgesDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PledgesTranslationsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationsPledgesDosVersionLanguageIdGet(dosVersion, languageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} translationId 
         * @param {TranslationUpdateDto} [translationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationsTranslationIdPut(translationId: number, translationUpdateDto?: TranslationUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationsTranslationIdPut(translationId, translationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationsTranslationsDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationsTranslationsDosVersionLanguageIdGet(dosVersion, languageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [languageIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationsTranslationsVersionsGet(languageIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationsTranslationsVersionsGet(languageIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TranslationsApi - factory interface
 * @export
 */
export const TranslationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TranslationsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsAdminTranslationsDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: any): AxiosPromise<AdminTranslationsDto> {
            return localVarFp.apiTranslationsAdminTranslationsDosVersionLanguageIdGet(dosVersion, languageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TranslationCreateDto} translationCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsAdminTranslationsPost(translationCreateDto: TranslationCreateDto, options?: any): AxiosPromise<number> {
            return localVarFp.apiTranslationsAdminTranslationsPost(translationCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsAuditPointsDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: any): AxiosPromise<AuditPointsTranslationsDto> {
            return localVarFp.apiTranslationsAuditPointsDosVersionLanguageIdGet(dosVersion, languageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsCheckPointsDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: any): AxiosPromise<CheckPointsTranslationsDto> {
            return localVarFp.apiTranslationsCheckPointsDosVersionLanguageIdGet(dosVersion, languageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsPledgePerspectivesDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: any): AxiosPromise<PledgePerspectivesTranslationsDto> {
            return localVarFp.apiTranslationsPledgePerspectivesDosVersionLanguageIdGet(dosVersion, languageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsPledgesDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: any): AxiosPromise<PledgesTranslationsDto> {
            return localVarFp.apiTranslationsPledgesDosVersionLanguageIdGet(dosVersion, languageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} translationId 
         * @param {TranslationUpdateDto} [translationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsTranslationIdPut(translationId: number, translationUpdateDto?: TranslationUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTranslationsTranslationIdPut(translationId, translationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dosVersion 
         * @param {number} languageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsTranslationsDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: any): AxiosPromise<TranslationsDto> {
            return localVarFp.apiTranslationsTranslationsDosVersionLanguageIdGet(dosVersion, languageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [languageIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsTranslationsVersionsGet(languageIds?: Array<number>, options?: any): AxiosPromise<TranslationsDto> {
            return localVarFp.apiTranslationsTranslationsVersionsGet(languageIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TranslationsApi - object-oriented interface
 * @export
 * @class TranslationsApi
 * @extends {BaseAPI}
 */
export class TranslationsApi extends BaseAPI {
    /**
     * 
     * @param {number} dosVersion 
     * @param {number} languageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public apiTranslationsAdminTranslationsDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).apiTranslationsAdminTranslationsDosVersionLanguageIdGet(dosVersion, languageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TranslationCreateDto} translationCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public apiTranslationsAdminTranslationsPost(translationCreateDto: TranslationCreateDto, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).apiTranslationsAdminTranslationsPost(translationCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dosVersion 
     * @param {number} languageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public apiTranslationsAuditPointsDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).apiTranslationsAuditPointsDosVersionLanguageIdGet(dosVersion, languageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dosVersion 
     * @param {number} languageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public apiTranslationsCheckPointsDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).apiTranslationsCheckPointsDosVersionLanguageIdGet(dosVersion, languageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dosVersion 
     * @param {number} languageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public apiTranslationsPledgePerspectivesDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).apiTranslationsPledgePerspectivesDosVersionLanguageIdGet(dosVersion, languageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dosVersion 
     * @param {number} languageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public apiTranslationsPledgesDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).apiTranslationsPledgesDosVersionLanguageIdGet(dosVersion, languageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} translationId 
     * @param {TranslationUpdateDto} [translationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public apiTranslationsTranslationIdPut(translationId: number, translationUpdateDto?: TranslationUpdateDto, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).apiTranslationsTranslationIdPut(translationId, translationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dosVersion 
     * @param {number} languageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public apiTranslationsTranslationsDosVersionLanguageIdGet(dosVersion: number, languageId: number, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).apiTranslationsTranslationsDosVersionLanguageIdGet(dosVersion, languageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [languageIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public apiTranslationsTranslationsVersionsGet(languageIds?: Array<number>, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).apiTranslationsTranslationsVersionsGet(languageIds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get DOS versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDosversionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/dosversions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Excel file with users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserExportGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get languages
         * @param {boolean} [includeBaseLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLanguagesGet: async (includeBaseLanguage?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeBaseLanguage !== undefined) {
                localVarQueryParameter['includeBaseLanguage'] = includeBaseLanguage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPermissionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves a new user. Returns new userId.
         * @param {UserCreateDto} userCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost: async (userCreateDto: UserCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateDto' is not null or undefined
            assertParamExists('apiUserPost', 'userCreateDto', userCreateDto)
            const localVarPath = `/api/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRequestAccessCookieGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/RequestAccessCookie`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user from userId.    EditRights:   0         Read Only  1         Edit Only  2        Edit and Certify  3         Edit, Certify and Remote
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdGet: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUserUserIdGet', 'userId', userId)
            const localVarPath = `/api/User/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user    keycloakUserName:   current keycloak username(old). Optional param if you want to update keycloak as well.
         * @param {number} userId 
         * @param {UserUpdateDto} userUpdateDto 
         * @param {string} [keycloakUserName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdPut: async (userId: number, userUpdateDto: UserUpdateDto, keycloakUserName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUserUserIdPut', 'userId', userId)
            // verify required parameter 'userUpdateDto' is not null or undefined
            assertParamExists('apiUserUserIdPut', 'userUpdateDto', userUpdateDto)
            const localVarPath = `/api/User/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keycloakUserName !== undefined) {
                localVarQueryParameter['keycloakUserName'] = keycloakUserName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get DOS versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDosversionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DOSVersionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDosversionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Excel file with users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserExportGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserExportGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get languages
         * @param {boolean} [includeBaseLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserLanguagesGet(includeBaseLanguage?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguagesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserLanguagesGet(includeBaseLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPermissionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPermissionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves a new user. Returns new userId.
         * @param {UserCreateDto} userCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPost(userCreateDto: UserCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPost(userCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRequestAccessCookieGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRequestAccessCookieGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user from userId.    EditRights:   0         Read Only  1         Edit Only  2        Edit and Certify  3         Edit, Certify and Remote
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUserIdGet(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a user    keycloakUserName:   current keycloak username(old). Optional param if you want to update keycloak as well.
         * @param {number} userId 
         * @param {UserUpdateDto} userUpdateDto 
         * @param {string} [keycloakUserName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUserIdPut(userId: number, userUpdateDto: UserUpdateDto, keycloakUserName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUserIdPut(userId, userUpdateDto, keycloakUserName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get DOS versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDosversionsGet(options?: any): AxiosPromise<DOSVersionsDto> {
            return localVarFp.apiUserDosversionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Excel file with users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserExportGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiUserExportGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet(options?: any): AxiosPromise<UsersDto> {
            return localVarFp.apiUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get languages
         * @param {boolean} [includeBaseLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLanguagesGet(includeBaseLanguage?: boolean, options?: any): AxiosPromise<LanguagesDto> {
            return localVarFp.apiUserLanguagesGet(includeBaseLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPermissionsGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiUserPermissionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves a new user. Returns new userId.
         * @param {UserCreateDto} userCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost(userCreateDto: UserCreateDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserPost(userCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRequestAccessCookieGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiUserRequestAccessCookieGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user from userId.    EditRights:   0         Read Only  1         Edit Only  2        Edit and Certify  3         Edit, Certify and Remote
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdGet(userId: number, options?: any): AxiosPromise<UserDto> {
            return localVarFp.apiUserUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user    keycloakUserName:   current keycloak username(old). Optional param if you want to update keycloak as well.
         * @param {number} userId 
         * @param {UserUpdateDto} userUpdateDto 
         * @param {string} [keycloakUserName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdPut(userId: number, userUpdateDto: UserUpdateDto, keycloakUserName?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserUserIdPut(userId, userUpdateDto, keycloakUserName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Get DOS versions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserDosversionsGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserDosversionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Excel file with users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserExportGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserExportGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get languages
     * @param {boolean} [includeBaseLanguage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserLanguagesGet(includeBaseLanguage?: boolean, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserLanguagesGet(includeBaseLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserPermissionsGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserPermissionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves a new user. Returns new userId.
     * @param {UserCreateDto} userCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserPost(userCreateDto: UserCreateDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserPost(userCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserRequestAccessCookieGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserRequestAccessCookieGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user from userId.    EditRights:   0         Read Only  1         Edit Only  2        Edit and Certify  3         Edit, Certify and Remote
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUserIdGet(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user    keycloakUserName:   current keycloak username(old). Optional param if you want to update keycloak as well.
     * @param {number} userId 
     * @param {UserUpdateDto} userUpdateDto 
     * @param {string} [keycloakUserName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUserIdPut(userId: number, userUpdateDto: UserUpdateDto, keycloakUserName?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserUserIdPut(userId, userUpdateDto, keycloakUserName, options).then((request) => request(this.axios, this.basePath));
    }
}


