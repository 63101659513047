import type {
  AuditPoint,
  CheckPoint,
  Deviation,
  Pledge
} from '@cdab/scania/qpr/schema'

export type AuditPointStatistics = {
  numOfDeviations: number
  numOfNotes: number
}

export function getAuditPointStatistics(
  auditPoint: AuditPoint,
  checkpoints: CheckPoint[],
  deviations: Deviation[]
): AuditPointStatistics {
  const numOfDeviations = deviations.filter(
    d => d.auditPointId === auditPoint.id
  ).length

  const auditHasNote = auditPoint.note?.length ? 1 : 0
  const checkPointNotes = checkpoints.filter(
    chp => chp.note && chp.note !== '' && chp.auditPointId === auditPoint.id
  ).length
  const numOfNotes = auditHasNote + checkPointNotes

  return {
    numOfDeviations,
    numOfNotes
  }
}

export type PledgeStatistics = {
  numOfDeviations: number
  numOfNotes: number
}

export function getPledgeStatistics(
  pledgeNote: Pledge['note'],
  auditPoints: AuditPoint[],
  checkpoints: CheckPoint[],
  deviations: Deviation[]
): PledgeStatistics {
  let totalNumOfDeviations = 0
  let totalNumOfNotes = 0

  if (pledgeNote) totalNumOfNotes += 1

  auditPoints.forEach(auditPoint => {
    const { numOfNotes, numOfDeviations } = getAuditPointStatistics(
      auditPoint,
      checkpoints,
      deviations
    )

    totalNumOfNotes += numOfNotes
    totalNumOfDeviations += numOfDeviations
  })

  return {
    numOfDeviations: totalNumOfDeviations,
    numOfNotes: totalNumOfNotes
  }
}
