import { useCallback } from 'react'

import type { SuspendedPromise } from '@cdab/utils'

type Option = { value: string; label: string }
type TransformFn<T> = (data: T) => Option[]

type SuspendedSelectProps<T> = {
  resource: SuspendedPromise<T>
  onChange?: (value: string, label: string) => void
  value?: string
}

export function SuspendedSelect<T extends Option[]>(
  props: SuspendedSelectProps<T> & { transform?: TransformFn<T> }
): JSX.Element
export function SuspendedSelect<T>(
  props: SuspendedSelectProps<T> & { transform: TransformFn<T> }
): JSX.Element
export function SuspendedSelect<T>(
  props: SuspendedSelectProps<T> & { transform?: TransformFn<T> }
) {
  const { resource, onChange, value, transform } = props

  const data = resource.read()
  const items: Option[] = transform
    ? transform(data)
    : // We know this must be an Option[] because transform is required if it's not
      (data as unknown as Option[])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (!onChange) return

      const index = e.target.selectedIndex
      const label = e.target[index].textContent || ''
      onChange(e.target.value, label)
    },
    [onChange]
  )

  return (
    <select onChange={handleChange} value={value}>
      <option>Select Audit point</option>
      {items.map(item => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  )
}
