import { NavLink } from 'react-router-dom'
import cx from 'classnames'

import type {
  NavigationTabProps,
  NavigationTabsProps
} from './navigation-tabs.types'

const Tab = ({
  active = false, // TODO: Add correc tstyle for this state
  children,
  disabled = false,
  to
}: NavigationTabProps) => {
  return (
    <NavLink
      role='tab'
      to={to}
      className={({ isActive }) =>
        cx('sdds-navigation-tabs--tab', {
          'sdds-navigation-tabs--tab__active': isActive,
          'sdds-navigation-tabs--tab__disabled': disabled
        })
      }
    >
      {children}
    </NavLink>
  )
}

const NavigationTabs = ({ children }: NavigationTabsProps) => {
  return <sdds-navigation-tabs role='tablist'>{children}</sdds-navigation-tabs>
}
NavigationTabs.Tab = Tab

export { NavigationTabs }
