import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { Audit, CheckPoint } from '@cdab/scania/qpr/schema'
import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import { v4 as uuidv4 } from 'uuid'
import { action } from 'mobx'
import invariant from 'tiny-invariant'
import type { AuditModel } from '../audit-model'

type Data = {
  score: CheckPoint['score']
  checkPointId: CheckPoint['id']
}

export type SetCheckPointScoreData = OperationDataBase<
  'set-checkpoint-score',
  Data
>

export class SetCheckPointScore extends Operation {
  public data: SetCheckPointScoreData

  constructor(
    auditId: Audit['id'],
    value: Data,
    previousValue: Data,
    guid = uuidv4()
  ) {
    super(auditId, guid)
    this.data = {
      type: 'set-checkpoint-score',
      value,
      previousValue
    }
  }

  private getCheckPoint = (audit: AuditModel) => {
    const { checkPointId } = this.data.value
    const checkPoint = audit.checkPoints.find(({ id }) => id === checkPointId)
    invariant(
      checkPoint,
      `Check Point not found, cannot update! id: ${checkPointId}`
    )

    return checkPoint
  }

  public ApplyTo = action((audit: AuditModel) => {
    const checkPoint = this.getCheckPoint(audit)

    checkPoint.score = this.data.value.score
  })

  public GetName(): string {
    return `${this.data.type}-${this.auditId}-${this.data.value.checkPointId}`
  }

  public SendOperation = async (client: BackEnd): Promise<boolean> => {
    const success = await client.AuditsRealTimeService.UpdateCheckPointScore({
      auditId: this.auditId,
      callIdentifier: this.guid,
      created: new Date(),
      id: this.data.value.checkPointId,
      isPointCheckedYes: this.data.value.score
    })

    return success
  }

  public RollbackOn(audit: AuditModel): void {
    const checkPoint = this.getCheckPoint(audit)

    invariant(
      this.data.previousValue,
      `Cannot rollback ${this.data.type} without previous value! previous value = ${this.data.previousValue}`
    )
    checkPoint.score = this.data.previousValue.score
  }
}
