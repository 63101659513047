import { TdsButton } from '@scania/tegel-react'
import styled from 'styled-components'

export const StyledLinkRow = styled.div`
  display: flex;
  align-items: center;
`

export const StyledDeviationsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

export const StyledTextfieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 2rem;
`

export const StyledButton = styled(TdsButton)`
  padding-top: 6rem;
`
