import { useCallback } from 'react'

import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'

import { deviationsController } from '@cdab/scania/qpr/offline/controllers'
import type {
  AuditPoint,
  CreateDeviation,
  DeviationValidationSchemaOut
} from '@cdab/scania/qpr/schema'
import { Toast } from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'
import { useTranslation } from 'react-i18next'
import { DeviationModal } from './deviation-modal'

export function NewDeviationModal(props: {
  onClose: () => void
  open: boolean
  auditId: number
  allowEditAuditPoint: boolean
  defaultAuditPoint?: AuditPoint
}) {
  const { auditId, allowEditAuditPoint } = props

  const onCancel = useCallback(() => {
    props.onClose()
  }, [props])

  const { t } = useTranslation(['common'])

  const onSave = useCallback(
    (data: DeviationValidationSchemaOut) => {
      const clientGuid = uuidv4()
      const deviation: CreateDeviation = data.withActionPlan
        ? {
            auditId,
            clientGuid,
            auditPointNumber: data.auditPointNumber,
            auditPointId: data.auditPointId,
            deviationWithoutActions: false,
            deviation: data.deviation,
            responsible: data.assignee,
            proposedActions: data.action,
            expirationDate: data.due || null,
            approvalDate: data.completed || null,
            actionPlanId: -1
          }
        : {
            auditId,
            clientGuid,
            auditPointNumber: data.auditPointNumber,
            auditPointId: data.auditPointId,
            deviationWithoutActions: true,
            deviation: data.deviation,
            expirationDate: null,
            proposedActions: null,
            responsible: null,
            approvalDate: null,
            actionPlanId: -1
          }

      deviationsController.CreateDeviation(deviation)

      toast(
        <Toast
          type='success'
          headline={capitalizeFirstLetter(t('deviation-created'))}
          subheadline={capitalizeFirstLetter(t('deviation-created-success'))}
        />
      )

      if (data.files) {
        deviationsController.AddFiles(
          deviation.auditId,
          deviation.clientGuid,
          data.files.map(({ file }) => file)
        )
      }
    },
    [auditId, t]
  )

  return (
    <DeviationModal
      onClose={onCancel}
      open={props.open}
      onSave={onSave}
      auditId={auditId}
      hideWithActionPlanCheckbox={false}
      allowEditAuditPoint={allowEditAuditPoint}
      defaultAuditPoint={props.defaultAuditPoint}
    />
  )
}
