import styled from 'styled-components'

export const AuditPointNumberWrapper = styled.div`
  background-color: var(--sdds-white);
  display: flex;
  padding: var(--sdds-spacing-element-16) var(--sdds-spacing-element-32) 0;
`

export const AuditPointNumber = styled.h6`
  margin: 0 var(--sdds-spacing-element-16) 0 0;
`

export const StyledSpan = styled.span`
  > :first-child {
    margin-top: 0;
  }
`
