import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export interface UserMarket {
  id: number
  name: string
}

export const UserMarketSchema: JSONSchemaType<UserMarket> = {
  type: 'object',

  properties: {
    id: {
      type: 'number'
    },
    name: {
      type: 'string'
    }
  },

  required: ['id', 'name']
}

const validateUserMarket: ValidateFunction<UserMarket> =
  ajv.compile(UserMarketSchema)

export const isValidUserMarket = (
  userMarket: unknown
): userMarket is UserMarket =>
  isValidThing<UserMarket>(userMarket, validateUserMarket)
