import { forwardRef } from 'react'
import { Bubble } from './chat-message.styles'
import type { ChatMessageProps } from './chat-message.types'

export const ChatMessage = forwardRef<HTMLTdsCardElement, ChatMessageProps>(
  function ChatMessage(
    {
      children,
      message,
      id,
      imagePlacement,
      rightPosition = false,
      imageUrl,
      onMessageClick,
      hasThumbnail
    }: Readonly<ChatMessageProps>,
    ref
  ) {
    return (
      <Bubble
        id={id}
        ref={ref}
        right={rightPosition}
        header={message}
        imagePlacement={imagePlacement}
        body-img={!hasThumbnail ? imageUrl : null}
        onClick={onMessageClick}
      >
        {hasThumbnail && imageUrl && (
          <img slot='thumbnail' src={imageUrl} alt='Thumbnail for the card.' />
        )}
        <div slot='body'>{children}</div>
      </Bubble>
    )
  }
)
