import type { Audit, CheckPoint } from '@cdab/scania/qpr/schema'
import {
  getAudit,
  getCheckPoint,
  SetCheckPointNoteOperation,
  SetCheckPointScore
} from '@cdab/scania/qpr/offline/models'
import { operationsController } from './operations-controller'
import { auditsController } from './audits-controller'

class CheckPointsController {
  public GetCheckPoint = async (
    auditId: Audit['id'],
    checkPointId: CheckPoint['id']
  ) => {
    if (!getAudit(auditId)) {
      await auditsController.GetAudit(auditId)
    }

    const checkPoint = getCheckPoint(auditId, checkPointId)

    return checkPoint
  }

  public SetScore = async (
    auditId: Audit['id'],
    checkPointId: CheckPoint['id'],
    score: CheckPoint['score']
  ) => {
    const checkPoint = await this.GetCheckPoint(auditId, checkPointId)

    const operation = new SetCheckPointScore(
      auditId,
      {
        checkPointId,
        score
      },
      {
        checkPointId,
        score: checkPoint.score
      }
    )

    operationsController.handleNewOperation(operation)
  }

  public SetNote = async (
    auditId: Audit['id'],
    checkPointId: CheckPoint['id'],
    note: string
  ) => {
    const checkPoint = await this.GetCheckPoint(auditId, checkPointId)

    const operation = new SetCheckPointNoteOperation(
      auditId,
      {
        auditCheckPointId: checkPointId,
        note
      },
      {
        auditCheckPointId: checkPointId,
        note: checkPoint.note || ''
      }
    )

    operationsController.handleNewOperation(operation)
  }
}

export const checkPointsController = new CheckPointsController()
