import styled from 'styled-components'
import { Divider } from '@cdab/scania/sdds'

const TitleWrapper = styled.div`
  margin-top: 9rem;
`

const Title = styled.h2.attrs({
  className: 'sdds-headline-03'
})`
  margin-bottom: 4rem;
`

const Description = styled.p.attrs({
  className: 'sdds-body-01 sdds-text-negative'
})`
  opacity: 0.6;
  margin-top: 4rem;
  margin-bottom: 9rem;
`

const StyledDivider = styled(Divider)<{ extraMargin: boolean }>`
  margin-bottom: 8rem;
`

type PageHeaderProps = {
  title: string
  description?: string
}

export function PageHeader(props: PageHeaderProps): JSX.Element {
  const { description, title } = props

  return (
    <TitleWrapper>
      <Title>{title}</Title>
      <StyledDivider extraMargin={!description} type='light' />
      {description && <Description>{description}</Description>}
    </TitleWrapper>
  )
}
