import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from '@cdab/utils'
import { Block, Button } from '@cdab/scania/sdds'
import { AuditNote } from '@cdab/scania/qpr/components/atoms'
import { useCallback, useState } from 'react'
import { auditsController } from '@cdab/scania/qpr/offline/controllers'
import { AuditEditModal } from '../audit-modal/audit-edit-modal'
import styled from 'styled-components'
import type { AuditModel } from '@cdab/scania/qpr/offline/models'

type AuditInfoProps = {
  audit: AuditModel
  className?: string
}

const StyledHeader = styled.h4`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledButton = styled(Button)`
  && {
    height: auto;
    padding: 0 2rem;

    &,
    &:hover,
    &:active,
    &:focus {
      background: transparent;
      color: var(--sdds-link-visited);
      border: none;
    }
  }
`

export const AuditInfo = observer(function AuditInfo(props: AuditInfoProps) {
  const { className, audit } = props
  const [t] = useTranslation()
  const [showEditAuditModal, setShowEditAuditModal] = useState(false)
  const onSaveNote = useCallback(
    (content: string) => {
      auditsController.SetNote(audit.id, content)
    },
    [audit.id]
  )

  const { isReadonly } = audit

  return (
    <div className={className}>
      <StyledHeader>
        {capitalizeFirstLetter(t('audit'))}
        {!isReadonly && (
          <StyledButton
            onClick={() => setShowEditAuditModal(true)}
            size='sm'
            text={capitalizeFirstLetter(t('edit-audit'))}
          />
        )}
      </StyledHeader>
      <Block color='on-grey'>
        <AuditNote
          className='sdds-on-white-bg'
          note={audit.note ?? ''}
          onSaveNote={onSaveNote}
          disabled={isReadonly}
        />
        <AuditEditModal
          audit={audit}
          onClose={() => setShowEditAuditModal(false)}
          open={showEditAuditModal}
        />
      </Block>
    </div>
  )
})
