import type { Audit } from '@cdab/scania/qpr/schema'
import type { Detail, GridItemId } from './types'
import {
  AUDIT_POINT_GRID_ITEM_ID_START,
  CHECKPOINT_GRID_ITEM_ID_START
} from '@cdab/scania/qpr/settings'

export function getAuditDetailFromItemId(
  gridItemId: GridItemId,
  audit: Audit
): Detail | undefined {
  // Audit point
  if (gridItemId.startsWith(AUDIT_POINT_GRID_ITEM_ID_START)) {
    const id = gridItemId.slice(AUDIT_POINT_GRID_ITEM_ID_START.length)

    const auditPoint = audit.auditPoints.find(
      ap => ap.id === Number.parseInt(id, 10)
    )

    if (!auditPoint) return undefined

    return {
      type: 'AUDIT_POINT',
      gridItemId,
      ...auditPoint
    }
  }

  // Check point
  if (gridItemId.startsWith(CHECKPOINT_GRID_ITEM_ID_START)) {
    const id = gridItemId.slice(CHECKPOINT_GRID_ITEM_ID_START.length)

    const checkPoint = audit.checkPoints.find(
      cp => cp.id === Number.parseInt(id, 10)
    )

    if (!checkPoint) return undefined

    return {
      type: 'CHECK_POINT',
      gridItemId,
      ...checkPoint
    }
  }

  return undefined
}
