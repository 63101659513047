import type { Dealer } from '@cdab/scania/qpr/schema'
import { IconCross, IconTick } from '@cdab/scania/sdds'

import {
  ServiceIcon,
  ServiceTable,
  StyledBlock,
  TableCell,
  TableHeader,
  TableRow,
  TableRowGroup
} from './dealer-service-card.styles'
import type { DealerServiceCardProps } from './dealer-service-card.types'

export const TRUCK_LABEL = 'T'
export const BUS_LABEL = 'B'
export const ENGINE_LABEL = 'E'
export const TRUCK_SALES_LABEL = 'TS'
export const BUS_SALES_LABEL = 'BS'
export const ENGINE_SALES_LABEL = 'ES'
export const PARTS_SALES_LABEL = 'PS'

const hasService = (hasService: boolean | null) =>
  hasService ? (
    <ServiceIcon positive={true}>
      <IconTick />
    </ServiceIcon>
  ) : (
    <ServiceIcon positive={false}>
      <IconCross />
    </ServiceIcon>
  )

function DealerServiceTable({ dealer }: { dealer: Dealer }): JSX.Element {
  return (
    <>
      {/* <Tooltip
        placement='top'
        selector='#truck'
        text={t('services-sales.truck-services')}
      />
      <Tooltip
        placement='top'
        selector='#bus'
        text={t('services-sales.bus-services')}
      />
      <Tooltip
        placement='top'
        selector='#engine'
        text={t('services-sales.engine-services')}
      />
      <Tooltip
        placement='top'
        selector='#truckSales'
        text={t('services-sales.truck-sales')}
      />
      <Tooltip
        placement='top'
        selector='#busSales'
        text={t('services-sales.bus-sales')}
      />
      <Tooltip
        placement='top'
        selector='#engineSales'
        text={t('services-sales.engine-sales')}
      />
      <Tooltip
        placement='top'
        selector='#partsSales'
        text={t('services-sales.parts-sales')}
      /> */}
      <ServiceTable>
        <TableRowGroup>
          <TableRow data-testid='headers'>
            <TableHeader id='truck'>{TRUCK_LABEL}</TableHeader>
            <TableHeader id='bus'>{BUS_LABEL}</TableHeader>
            <TableHeader id='engine'>{ENGINE_LABEL}</TableHeader>
            <TableHeader id='truckSales'>{TRUCK_SALES_LABEL}</TableHeader>
            <TableHeader id='busSales'>{BUS_SALES_LABEL}</TableHeader>
            <TableHeader id='engineSales'>{ENGINE_SALES_LABEL}</TableHeader>
            <TableHeader id='partsSales'>{PARTS_SALES_LABEL}</TableHeader>
          </TableRow>
        </TableRowGroup>
        <TableRowGroup>
          <TableRow data-testid='services'>
            <TableCell>{hasService(dealer.truck)}</TableCell>
            <TableCell>{hasService(dealer.bus)}</TableCell>
            <TableCell>{hasService(dealer.engine)}</TableCell>
            <TableCell>{hasService(dealer.truckSales)}</TableCell>
            <TableCell>{hasService(dealer.busSales)}</TableCell>
            <TableCell>{hasService(dealer.engineSales)}</TableCell>
            <TableCell>{hasService(dealer.partsSales)}</TableCell>
          </TableRow>
        </TableRowGroup>
      </ServiceTable>
    </>
  )
}

export function DealerServiceCard({ dealer }: DealerServiceCardProps) {
  return (
    <StyledBlock color='on-grey'>
      <DealerServiceTable dealer={dealer} />
    </StyledBlock>
  )
}
