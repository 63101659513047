import type { Audit } from '@cdab/scania/qpr/schema'
import {
  AUDIT_POINT_GRID_ITEM_ID_START,
  CHECKPOINT_GRID_ITEM_ID_START,
  PLEDGE_AREA_GRID_ITEM_ID_START,
  PLEDGE_GRID_ITEM_ID_START
} from '@cdab/scania/qpr/settings'

import type {
  GridItemId,
  GridFormattedAudit,
  GridFormattedAuditPoint,
  GridFormattedPledge,
  GridFormattedPledgeArea
} from './types'

export function isValidGridItemId(s: string): s is GridItemId {
  const startOk =
    s.startsWith(AUDIT_POINT_GRID_ITEM_ID_START) ||
    s.startsWith(CHECKPOINT_GRID_ITEM_ID_START) ||
    s.startsWith(PLEDGE_AREA_GRID_ITEM_ID_START) ||
    s.startsWith(PLEDGE_GRID_ITEM_ID_START)

  const regex = new RegExp(/-\d+$/)
  return startOk && regex.test(s)
}

export function formatForGrid(audit: Audit): GridFormattedAudit {
  const pledgeAreas = audit.pledgeAreas.map(
    (pledgeArea): GridFormattedPledgeArea => {
      const pledges = audit.pledges
        .filter(pledge => pledge.pledgeAreaId === pledgeArea.id)
        .map((pledge): GridFormattedPledge => {
          const auditPoints = audit.auditPoints
            .filter(auditPoint => auditPoint.pledgeId === pledge.id)
            .map((auditPoint): GridFormattedAuditPoint => {
              const checkPoints = audit.checkPoints.filter(
                checkPoint => checkPoint.auditPointId === auditPoint.id
              )

              return {
                ...auditPoint,
                checkPoints
              }
            })

          return {
            ...pledge,
            auditPoints
          }
        })

      return {
        ...pledgeArea,
        pledges
      }
    }
  )

  return {
    pledgeAreas
  }
}
