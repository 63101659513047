// import flagsmith from 'flagsmith'
import type { IFlagsmithTrait } from 'flagsmith/types'
import { typedFlagsmith } from './features'
import invariant from 'tiny-invariant'

import type { FeatureFlag, Features, TraitOptions } from './flags.types'

type UseFeatures<T extends keyof Features> = Pick<Features, T>

export function useFeatures<T extends keyof Features>(
  flags: T[]
): UseFeatures<T> {
  const x: Partial<UseFeatures<T>> = {}

  flags.forEach(flag => {
    const value = typedFlagsmith.getValue<
      Pick<Features, typeof flag>[typeof flag]['value']
    >(flag, {
      json: true
    })

    const enabled = typedFlagsmith.hasFeature(flag)

    const temp = {
      enabled,
      value
    }

    ;(x as any)[flag] = temp
  })

  const keys = Object.keys(x)
  invariant(keys.length === flags.length)
  keys.forEach(key => {
    invariant((x as any)[key] !== undefined)
  })

  return x as UseFeatures<T>
}

export function useFeatureValue(feature: FeatureFlag) {
  const value = typedFlagsmith.getValue(feature)

  return value
}

export function useGetFeatureFlags() {
  return {
    getFlags: typedFlagsmith.getFlags
  }
}

export function useIdentifyUser() {
  return {
    identifyUser: (userId: string) => typedFlagsmith.identify(userId)
  }
}

export function useTrait() {
  return {
    setTrait: (trait: TraitOptions, value: IFlagsmithTrait) =>
      typedFlagsmith.setTrait(trait, value)
  }
}
