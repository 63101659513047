import { useMemo } from 'react'
import { DOS_VERSION_5 } from '@cdab/scania/qpr/schema'
import type { AuditModel } from '@cdab/scania/qpr/offline/models'

export function useIsDos5(audit: AuditModel) {
  const isDos5 = useMemo(
    () => audit.dosVersion === DOS_VERSION_5,
    [audit.dosVersion]
  )

  return isDos5
}
