import { capitalize } from '../utils'
import type { TextFieldProps } from './textfield.types'

export const TEST_ID_TEXTFIELD = 'textfield'
export const TEST_ID_OUTSIDE_LABEL = 'outside-label'
export const TEST_ID_INSIDE_LABEL = 'inside-label'

export function Textfield(props: Readonly<TextFieldProps>) {
  const {
    autoFocus,
    className,
    disabled,
    labelPosition,
    maxLength,
    name,
    onBlur,
    onChange,
    prefix,
    size,
    state,
    suffix,
    type = 'text',
    value = null
  } = props
  const helper = props.helper ? capitalize(props.helper) : ''
  const label = props.label ? capitalize(props.label) : undefined
  const placeholder = props.placeholder
    ? capitalize(props.placeholder)
    : undefined

  return (
    <sdds-textfield
      autofocus={autoFocus}
      className={className}
      data-testid={TEST_ID_TEXTFIELD}
      disabled={disabled}
      label-inside={labelPosition === 'inside' && label ? label : ''}
      maxlength={maxLength}
      name={name}
      onBlur={onBlur ?? undefined}
      onInput={onChange}
      placeholder={placeholder}
      size={size}
      state={state}
      type={type}
      value={value}
    >
      {prefix && <span slot='sdds-prefix'>{prefix}</span>}
      {suffix && <span slot='sdds-suffix'>{suffix}</span>}
      {label && props.labelPosition === 'outside' && (
        <span data-testid={TEST_ID_OUTSIDE_LABEL} slot='sdds-label'>
          {label}
        </span>
      )}
      <span slot='sdds-helper'>{helper}</span>
    </sdds-textfield>
  )
}
