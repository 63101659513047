import { FormModal } from '@cdab/scania/qpr/components/molecules'
import { Button } from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import type { SystemMessageModalProps } from './system-messages-modal.types'

export function SystemMessageModal({
  open,
  onClose,
  systemMessage
}: Readonly<SystemMessageModalProps>) {
  const { t } = useTranslation('common')

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
  }

  return (
    <FormModal
      open={open}
      onClose={onClose}
      onSubmit={handleFormSubmit}
      footer={
        <Button
          type='primary'
          onClick={onClose}
          text={capitalizeFirstLetter(t('close'))}
        />
      }
      header={systemMessage.title}
    >
      <div className='sdds-u-mt3 sdds-detail-01'>
        {parse(systemMessage.text)}
      </div>
    </FormModal>
  )
}
