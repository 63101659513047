import { WithLineLimit } from '@cdab/headless-components'
import { removeHtmlTagsFromString } from '@cdab/utils'

import {
  Description,
  InnerComponent,
  LeftItem,
  RightItem,
  StyledItem,
  TextContainer,
  Thumbnail,
  Title
} from './clickable-list-item.styles'
import type { ClickableListItemProps } from './clickable-list-item.types'

export function ClickableListItem(props: ClickableListItemProps): JSX.Element {
  const {
    description,
    leftItem,
    linesOfDescription = 1,
    openInNewTab,
    rightItem,
    thumbnail,
    title,
    url,
    breakTitleWords,
    breakDescriptionWords,
    children,
    onClick,
    backgroundColor
  } = props

  const innerComponent = (
    <>
      {leftItem && <LeftItem>{leftItem}</LeftItem>}
      {thumbnail && <Thumbnail src={thumbnail.src} alt={thumbnail.alt} />}
      <InnerComponent>
        <TextContainer>
          <WithLineLimit maxLines={1} breakWords={breakTitleWords}>
            <Title>{title}</Title>
          </WithLineLimit>
          <WithLineLimit
            maxLines={linesOfDescription}
            breakWords={breakDescriptionWords}
          >
            {description && (
              <Description>{removeHtmlTagsFromString(description)}</Description>
            )}
          </WithLineLimit>
          {children}
        </TextContainer>
      </InnerComponent>
      {rightItem && <RightItem>{rightItem}</RightItem>}
    </>
  )

  if (url) {
    return (
      <StyledItem backgroundColor={backgroundColor} onClick={onClick}>
        <a
          href={url}
          target={openInNewTab ? '_blank' : '_self'}
          rel='noreferrer'
        >
          {innerComponent}
        </a>
      </StyledItem>
    )
  }

  return (
    <StyledItem backgroundColor={backgroundColor} onClick={onClick}>
      {innerComponent}
    </StyledItem>
  )
}
