/**
 * Wait for specified milliseconds
 * @param ms
 */
export const wait = async (ms: number): Promise<void> =>
  new Promise<void>(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })

export default wait
