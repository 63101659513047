import cx from 'classnames'
import styled from 'styled-components'
import type { StyleProps } from './upload-file-button.types'

export const SelectFileButton = styled.label.attrs<StyleProps>(
  ({ variant, size }) => ({
    className: cx('sdds-btn', `sdds-btn-${variant}`, {
      [`sdds-btn-${size}`]: size
    })
  })
)<StyleProps>`
  input[type='file'] {
    display: none;
  }
`
