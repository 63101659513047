import type { ProviderBase } from './provider-base'
import { PROVIDER_MOCK } from './constants'
import type { RealTimeMocks } from '@cdab/scania/qpr/interactors/mock'

interface MockParams {
  mockDelayMS: number
}

export interface MockProvider extends MockParams, ProviderBase {
  type: typeof PROVIDER_MOCK
  realTimeMocks?: RealTimeMocks
}

export function getMockProvider(): MockProvider {
  return {
    type: PROVIDER_MOCK,
    mockDelayMS: 0
  }
}
