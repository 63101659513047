import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import type { IFlagsmith } from 'flagsmith/types'

import type { FeatureFlag } from './flags.types'

flagsmith as IFlagsmith<FeatureFlag>

type FeatureFlagProviderProps = {
  children: React.ReactElement[] | React.ReactElement
  apiKey: string
  url: string
}

export function FeatureFlagProvider({
  children,
  apiKey,
  url
}: FeatureFlagProviderProps) {
  return (
    <FlagsmithProvider
      options={{
        cacheFlags: true,
        environmentID: apiKey,
        enableAnalytics: true,
        realtime: true,
        preventFetch: true,
        api: url
      }}
      flagsmith={flagsmith}
    >
      {children}
    </FlagsmithProvider>
  )
}
