import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'

import {
  AuditCertifyError,
  type Audit,
  type Dealer
} from '@cdab/scania/qpr/schema'
import {
  Block,
  Button,
  DelayedSpinner,
  Message,
  Toast
} from '@cdab/scania/sdds'

import { ActionsSection } from './audit-summary.styles'

import {
  useActionPlanReminder,
  useAuditReminder,
  useCertifyAudit
} from '@cdab/scania/qpr/hooks'
import { capitalizeFirstLetter } from '@cdab/utils'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

export interface AuditActionsProps {
  dealerId: Dealer['id']
  auditId: Audit['id']
  canCertify: boolean
  className?: string
  hasDeviations: boolean
  isCertified: boolean
}

function getErrorMessage(error: string | undefined, t: TFunction) {
  switch (error) {
    case AuditCertifyError.AuditIsArchived: {
      return capitalizeFirstLetter(t('certify.audit-is-archived'))
    }
    case AuditCertifyError.DealerIsInvalid: {
      return capitalizeFirstLetter(t('certify.dealer-is-invalid'))
    }
    case AuditCertifyError.UnfilledDevelopmentPoints: {
      return t('certify.unfilled-development-points')
    }
    case AuditCertifyError.UnfulfilledMandatoryPoints: {
      return t('certify.unfulfilled-mandatory-points')
    }
    case AuditCertifyError.UnfulfilledDeviations: {
      return t('certify.unfulfilled-deviations')
    }
    case AuditCertifyError.NotValidAuditType: {
      return t('certify.not-valid-audit-type')
    }
    default: {
      return ''
    }
  }
}

export function AuditActions({
  auditId,
  canCertify,
  dealerId,
  hasDeviations,
  isCertified
}: AuditActionsProps) {
  const { t } = useTranslation(['audit', 'common'])

  const [stateCertify, allowedToCertify, handleClickCertify, disableCertify] =
    useCertifyAudit(auditId, canCertify, dealerId)

  const [stateAuditReminder, handleClickAuditReminder] =
    useAuditReminder(auditId)

  const [stateActionPlanReminder, handleClickActionPlanReminder] =
    useActionPlanReminder(auditId)

  const certifyButtonVisible =
    canCertify && allowedToCertify && !stateCertify.matches('success')
  const auditReminderButtonVisible = isCertified
  const actionPlanReminderButtonVisible = hasDeviations

  useEffect(() => {
    if (stateCertify.matches('success')) {
      toast(
        <Toast
          type='success'
          headline={capitalizeFirstLetter(t('certify.success-certified'))}
        />
      )
      disableCertify()
    }
  }, [auditId, dealerId, disableCertify, stateCertify, t])

  if (
    !certifyButtonVisible &&
    !auditReminderButtonVisible &&
    !actionPlanReminderButtonVisible
  ) {
    return null
  }

  return (
    <Block
      color='on-grey'
      header={capitalizeFirstLetter(t('actions', { ns: 'common' }))}
      className='sdds-u-mt3'
    >
      <ActionsSection>
        {certifyButtonVisible && (
          <Button
            fullBleed
            type='primary'
            onClick={handleClickCertify}
            disabled={!['enabled', 'error'].some(stateCertify.matches)}
            text={capitalizeFirstLetter(t('certify', { ns: 'common' }))}
          />
        )}
        {stateCertify.matches('loading') && <DelayedSpinner />}
        {auditReminderButtonVisible && (
          <Button
            fullBleed
            type='primary'
            disabled={stateAuditReminder.status === 'loading'}
            onClick={handleClickAuditReminder}
            text={capitalizeFirstLetter(
              t('reminders.audit-reminder', { ns: 'audit' })
            )}
          />
        )}

        {actionPlanReminderButtonVisible && (
          <Button
            fullBleed
            type='primary'
            disabled={stateActionPlanReminder.status === 'loading'}
            onClick={handleClickActionPlanReminder}
            text={capitalizeFirstLetter(
              t('reminders.action-plan-reminder', { ns: 'audit' })
            )}
          />
        )}
      </ActionsSection>
      {stateCertify.matches('error') && (
        <Message
          className='sdds-u-mt1'
          variant='single-line'
          singleLineMessage={getErrorMessage(stateCertify.context.error, t)}
          type={'error'}
        />
      )}
      {(stateAuditReminder.status === 'error' ||
        stateAuditReminder.status === 'success') && (
        <Message
          className='sdds-u-mt1'
          variant='single-line'
          singleLineMessage={
            stateAuditReminder.status === 'success'
              ? t('reminders.audit-reminder-success', { ns: 'audit' })
              : stateAuditReminder.message
          }
          type={stateAuditReminder.status}
        />
      )}
      {(stateActionPlanReminder.status === 'error' ||
        stateActionPlanReminder.status === 'success') && (
        <Message
          className='sdds-u-mt1'
          variant='single-line'
          singleLineMessage={
            stateActionPlanReminder.status === 'success'
              ? t('reminders.audit-action-plan-reminder-success', {
                  ns: 'audit'
                })
              : stateActionPlanReminder.message
          }
          type={stateActionPlanReminder.status}
        />
      )}
    </Block>
  )
}
