export type AppLauncherItemProps = {
  url: string
  text: string
}

export function AppLauncherItem(props: AppLauncherItemProps) {
  return (
    <li className='sdds-nav__app-launcher-item'>
      <a
        href={props.url}
        target='_blank'
        rel='noopener noreferrer'
        className='sdds-nav__app-launcher-item-core'
      >
        {props.text}
      </a>
    </li>
  )
}
