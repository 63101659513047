import type { Dealer } from '@cdab/scania/qpr/schema'
import { formatISODate } from '@cdab/utils'
import type { TFunction } from 'i18next'

export type SortDealer = Pick<
  Dealer,
  'expiryDate' | 'name' | 'marketName' | 'city' | 'auditDate' | 'hasDeviations'
>

const now = Date.now()
export function byExpiryDateAndName(
  { expiryDate: a, name: aname }: SortDealer,
  { expiryDate: b, name: bname }: SortDealer
) {
  // We want to sort first on date then on name.
  // Dates can either be
  // "never certified", "expired", or "expiring in the future".
  // We want to show the order: "never", "expired", "expires".
  // When we are in the same "state", we want to sort on name
  const aNeverCertified = a === null
  const bNeverCertified = b === null

  if (aNeverCertified && bNeverCertified) return aname.localeCompare(bname)
  if (aNeverCertified) return -1
  if (bNeverCertified) return 1

  const aExpiryDate = a.valueOf()
  const bExpiryDate = b.valueOf()

  // If (a/b)Expired is not converted to local time zone,
  // then this is a bug since now is. Sorry...
  const aExpired = aExpiryDate < now.valueOf()
  const bExpired = bExpiryDate < now.valueOf()

  //If both expired or expires, they are in same group
  // sort same dates by name otherwise by dates
  if ((aExpired && bExpired) || (!aExpired && !bExpired))
    return aExpiryDate === bExpiryDate
      ? aname.localeCompare(bname)
      : aExpiryDate > bExpiryDate
      ? 1
      : -1
  //One of them expired or not and second one opposite,
  // let's divide them into different groups
  return aExpired ? -1 : 1
}

export function byDealerName(
  { name: aname }: SortDealer,
  { name: bname }: SortDealer
) {
  return aname.localeCompare(bname)
}

export function byLocation(
  { marketName: a, city: acity }: SortDealer,
  { marketName: b, city: bcity }: SortDealer
) {
  if (a === b) return acity.localeCompare(bcity)

  return a.localeCompare(b)
}

export function byAuditDate(
  { auditDate: a }: SortDealer,
  { auditDate: b }: SortDealer
) {
  if (a === null) return -1
  if (b === null) return 1

  return a < b ? -1 : 1
}

export function byHasActiveDeviations(
  { hasDeviations: a }: SortDealer,
  { hasDeviations: b }: SortDealer
) {
  if (a) return 1
  if (b) return 1

  return -1
}

export type DescriptionDealer = Pick<Dealer, 'auditDate' | 'expiryDate'>

export function getExpiryDescription(
  { expiryDate, auditDate }: DescriptionDealer,
  t: TFunction
) {
  if (auditDate === null || expiryDate === null)
    return t('expiring-certificates.never-certified')

  const s: string[] = []
  s.push(t('expiring-certificates.audited', { date: formatISODate(auditDate) }))

  const expiryDateString = formatISODate(expiryDate)
  s.push(
    t(
      `expiring-certificates.${
        expiryDate.valueOf() < now.valueOf() ? 'expired' : 'expires'
      }`,
      { date: expiryDateString }
    )
  )

  return s.join('  |  ')
}
