import type { Audit } from '@cdab/scania/qpr/schema'
import type { AuditModelBase } from './base'
import { restoreDeviationDates } from './restoration.utils'
import type { AuditModelV4, AuditModelV4Data } from './v4'

export type AuditModelV5Data = AuditModelV4Data & {
  auditTypeId: number
}

export type AuditModelV5 = AuditModelBase<AuditModelV5Data, 5>

export function restoreAuditFromDataV5(audit: AuditModelV5) {
  restoreDeviationDates(audit.deviations)

  // FIXME: I believe the more correct approach would be to keep this "stateful" data uncached,
  // maybe in a different cache. But it works for now.
  audit.isGettingFiles = false

  return audit
}

export function createFromAuditV5(audit: Audit) {
  const auditModel: AuditModelV5 = {
    ...audit,
    auditPoints: audit.auditPoints.map(ap => ({
      ...ap,
      files: [],
      isGettingFiles: false
    })),
    deviations: audit.deviations.map(d => ({
      ...d,
      files: [],
      isGettingFiles: false
    })),
    pledges: audit.pledges.map(pl => ({
      ...pl,
      files: [],
      isGettingFiles: false
    })),
    files: [],
    isGettingFiles: false,
    VERSION: 5
  }
  return auditModel
}

export function migrateToV5(audit: AuditModelV4): AuditModelV5 {
  const auditModal: AuditModelV5 = Object.assign(audit, {
    auditTypeId: 1,
    VERSION: 5 as const
  })

  return auditModal
}
