import { ThemeProvider } from '@cdab/scania/qpr/contexts/theme'
import { Outlet } from 'react-router-dom'

export const WithTheme = () => (
  <ThemeProvider>
    <Outlet />
  </ThemeProvider>
)

export default WithTheme
