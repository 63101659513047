import styled, { css } from 'styled-components'

import { CertificationStatus } from '@cdab/scania/qpr/schema'

type Props = {
  certification: CertificationStatus
}

export const Title = styled.h4`
  text-transform: capitalize;
`

export const StyledCertification = styled.span.attrs<Props>({
  className: 'sdds-detail-04'
})<Props>`
  ${({ certification }) => css`
    color: ${certification === CertificationStatus.Certified
      ? 'var(--sdds-green-500)'
      : certification === CertificationStatus.Expired
      ? 'var(--sdds-red-500)'
      : 'var(--sdds-grey-500)'};
  `};
`
