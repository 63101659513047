import styled from 'styled-components'

interface OptionButtonProps {
  active: boolean
  children: React.ReactNode
  id: string
  onClick: () => void
  style?: React.CSSProperties
  tooltip?: string
}

const ButtonContainer = styled.div`
  border-radius: 1rem;

  &:first-of-type {
    button {
      border-radius: 1rem 0 0 1rem;
    }
  }

  &:last-of-type {
    button {
      border-radius: 0 1rem 1rem 0;
    }
  }

  &:only-of-type {
    button {
      border-radius: 1rem;
    }
  }
`

const StyledOptionButton = styled.button.attrs({
  className: 'sdds-detail-02'
})<{ active: boolean }>`
  border: none;
  width: var(--sdds-spacing-element-40);
  height: var(--sdds-spacing-element-32);

  background-color: ${({ active }) =>
    active ? 'var(--sdds-green-100)' : 'inherit'};

  &:hover {
    cursor: pointer;
    background: ${({ active }) =>
      active ? 'var(--sdds-green-100)' : 'var(--sdds-grey-100)'};
  }
`

export const OptionButton = ({
  active,
  onClick,
  children,
  id,
  style
}: OptionButtonProps) => {
  return (
    <ButtonContainer>
      <StyledOptionButton
        onClick={onClick}
        id={id}
        active={active}
        style={style}
      >
        {children}
      </StyledOptionButton>
    </ButtonContainer>
  )
}
