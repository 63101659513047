import {
  TdsHeader,
  TdsHeaderBrandSymbol,
  TdsHeaderHamburger,
  TdsHeaderTitle,
  TdsIcon
} from '@scania/tegel-react'
import type { ADHHeaderProps } from './adh-header.types'

export function ADHHeader(props: Readonly<ADHHeaderProps>) {
  return (
    <TdsHeader>
      <TdsHeaderHamburger onClick={props.onClickHamburger}>
        <button>
          <TdsIcon
            name='arrow_left'
            size='16px'
            data-dashlane-shadowhost='true'
            className='hydrated'
            slot='hamburger'
            data-dashlane-observed='true'
          />
        </button>
      </TdsHeaderHamburger>
      <TdsHeaderTitle>{props.title}</TdsHeaderTitle>
      <TdsHeaderBrandSymbol slot='end'>
        <a
          aria-label='Scania - red gryphon on blue shield'
          href='https://scania.com'
        />
      </TdsHeaderBrandSymbol>
    </TdsHeader>
  )
}
