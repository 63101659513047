import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'
import {
  ChatMessageSchema,
  mapResponseToADHChatMessage,
  type ChatMessage,
  type ChatMessageJson
} from '../chat-message'

export interface ADHActionPlanItem {
  id: number
  linkId: number
  auditId: number
  actionPlanId: number
  actionPlanItemId: number
  auditPointNo: string
  auditPointDescription: string
  deviation: string
  proposedActions: string
  responsible: string
  expirationDate: string | undefined
  finishedDate: string | undefined
  actionPlanItemStatus: number
  chatMessages: ChatMessage[]
  userFiles: number[]
}

export interface ADHActionPlanItemJson {
  id: number
  linkId: number
  auditId: number
  actionPlanId: number
  actionPlanItemId: number
  auditPointNo: string
  auditPointDescription: string
  deviation: string
  proposedActions: string
  responsible: string
  expirationDate: string | undefined
  finishedDate: string | undefined
  actionPlanItemStatus: number
  adhChatMessages: ChatMessageJson[]
  userFiles: number[]
}

export const ADHActionPlanItemSchema: JSONSchemaType<ADHActionPlanItemJson> = {
  type: 'object',

  properties: {
    id: {
      type: 'integer'
    },
    linkId: {
      type: 'integer'
    },
    auditId: {
      type: 'integer'
    },
    actionPlanId: {
      type: 'integer'
    },
    actionPlanItemId: {
      type: 'integer'
    },
    auditPointNo: {
      type: 'string'
    },
    auditPointDescription: {
      type: 'string'
    },
    deviation: {
      type: 'string'
    },
    proposedActions: {
      type: 'string'
    },
    responsible: {
      type: 'string'
    },
    expirationDate: {
      type: 'string',
      nullable: true
    },
    finishedDate: {
      type: 'string',
      nullable: true
    },
    actionPlanItemStatus: {
      type: 'integer'
    },
    adhChatMessages: {
      type: 'array',
      items: ChatMessageSchema
    },
    userFiles: {
      type: 'array',
      items: { type: 'integer' }
    }
  },

  required: [
    'id',
    'linkId',
    'auditId',
    'actionPlanId',
    'actionPlanItemId',
    'auditPointNo',
    'auditPointDescription',
    'deviation',
    'proposedActions',
    'actionPlanItemStatus',
    'responsible',
    'userFiles'
  ]
}

const validateADHActionPlanItem: ValidateFunction<ADHActionPlanItemJson> =
  ajv.compile(ADHActionPlanItemSchema)

export const isValidADHActionPlanItem = (
  actionPlanItem: unknown
): actionPlanItem is ADHActionPlanItemJson =>
  isValidThing<ADHActionPlanItemJson>(actionPlanItem, validateADHActionPlanItem)

export const mapResponseToADHActionPlanItem = (
  json: ADHActionPlanItemJson
): ADHActionPlanItem => {
  const actionPlanItem: ADHActionPlanItem = {
    id: json.id,
    linkId: json.linkId,
    auditId: json.auditId,
    actionPlanId: json.actionPlanId,
    actionPlanItemId: json.actionPlanItemId,
    auditPointNo: json.auditPointNo,
    auditPointDescription: json.auditPointDescription,
    deviation: json.deviation,
    responsible: json.responsible,
    proposedActions: json.proposedActions,
    expirationDate: json.expirationDate,
    finishedDate: json.finishedDate,
    actionPlanItemStatus: json.actionPlanItemStatus,
    chatMessages: [],
    userFiles: []
  }

  for (const cm of json.adhChatMessages ?? []) {
    actionPlanItem.chatMessages.push(mapResponseToADHChatMessage(cm))
  }

  for (const f of json.userFiles ?? []) {
    actionPlanItem.userFiles.push(f)
  }

  return actionPlanItem
}
