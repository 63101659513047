import { WithLineLimit } from '@cdab/headless-components'
import type { AuditItemState } from '@cdab/scania/qpr/components/atoms'
import {
  auditItemStateToScore,
  scoreToAuditItemState
} from '@cdab/scania/qpr/components/atoms'
import { auditPointsController } from '@cdab/scania/qpr/offline/controllers'
import type { Audit, AuditPoint } from '@cdab/scania/qpr/schema'
import type {
  PointTypeAuditPoint,
  SelectedItem
} from '@cdab/scania/qpr/template-engine'
import parse from 'html-react-parser'
import { observer } from 'mobx-react-lite'
import { forwardRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AuditItemContent,
  DocumentIcon,
  Extra,
  ExtrasRow,
  NoteIcon
} from './audit-item-list.styles'
import { StyledAuditPointItem } from './audit-point-item.styles'
import { getAuditPointStatistics } from './statistics'

export type AuditItemListItemProps = {
  audit: Audit
  auditPoint: AuditPoint
  onClick?: (item: SelectedItem) => void
  onScoreChange?: (item: SelectedItem, score: boolean | null) => void
  currentSelectedItem?: SelectedItem
  disabled: boolean
}

export const AuditPointItem = observer(
  forwardRef<HTMLDivElement, AuditItemListItemProps>(
    (
      {
        audit,
        auditPoint,
        currentSelectedItem,
        ...props
      }: AuditItemListItemProps,
      ref
    ) => {
      const { t } = useTranslation('audit')
      const onScoreChange = useCallback(
        (newState: AuditItemState) => {
          const score = auditItemStateToScore(newState)

          auditPointsController.SetScore(audit.id, auditPoint.id, score)

          if (props.onScoreChange) {
            props.onScoreChange(
              {
                id: auditPoint.id,
                pointType: 'audit-point'
              },
              score
            )
          }
        },
        [audit.id, auditPoint, props]
      )

      const onItemClick = useCallback(() => {
        if (props.onClick) {
          props.onClick({
            id: auditPoint.id,
            pointType: 'audit-point'
          })
        }
      }, [auditPoint.id, props])

      // We loose real time updates if we use useMemo here
      const { numOfNotes, numOfDeviations } = getAuditPointStatistics(
        auditPoint,
        audit.checkPoints,
        audit.deviations
      )

      return (
        <StyledAuditPointItem
          ref={ref}
          value={scoreToAuditItemState(auditPoint.score)}
          onChange={onScoreChange}
          prefix={auditPoint.auditPointNo}
          onClick={onItemClick}
          isMandatory={auditPoint.isMandatory}
          id={`${'audit-point' as PointTypeAuditPoint}-${auditPoint.id}`}
          selected={
            currentSelectedItem?.pointType === 'audit-point' &&
            currentSelectedItem?.id === auditPoint.id
          }
          disabled={props.disabled}
        >
          <AuditItemContent>
            <WithLineLimit maxLines={2}>
              {parse(t(auditPoint.translationGuid))}
            </WithLineLimit>
            <ExtrasRow>
              {numOfDeviations > 0 && (
                <Extra>
                  <DocumentIcon /> {numOfDeviations}
                </Extra>
              )}
              {numOfNotes > 0 && (
                <Extra>
                  <NoteIcon /> {numOfNotes}
                </Extra>
              )}
            </ExtrasRow>
          </AuditItemContent>
        </StyledAuditPointItem>
      )
    }
  )
)
