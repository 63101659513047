import type { ReferenceDocumentModel } from './audit-model'
import { addOrMergeArrayObject } from './merge'

const findPredicate =
  (el: ReferenceDocumentModel) => (sd: ReferenceDocumentModel) =>
    sd.id === el.id &&
    sd.auditPointId === el.auditPointId &&
    sd.checkPointId === el.checkPointId

export const mergeReferenceDocuments = (
  target: ReferenceDocumentModel[],
  other: ReferenceDocumentModel[]
): ReferenceDocumentModel[] => {
  other.forEach(referenceDocument =>
    addOrMergeArrayObject(
      target,
      referenceDocument,
      // The same document (same id) can exist on multiple points.
      // A unique id for the document therefore is the tuple (id, auditPointId, checkPointId)
      findPredicate(referenceDocument)
    )
  )

  return target.filter(el => other.find(findPredicate(el)))
}
