import type { ListItemProps } from './list-item.types'
import { ListItemType } from './list-item.types'
import {
  Content,
  ContentWrapper,
  Prefix,
  Title,
  StyledListItem
} from './list-item.styles'

export function ListItem({
  className,
  title,
  description,
  prefix,
  onClick,
  highlighted,
  type = ListItemType.normal
}: ListItemProps) {
  return (
    <StyledListItem
      type={type}
      className={className}
      clickable={!!onClick}
      onClick={onClick}
      selected={!!highlighted}
    >
      <ContentWrapper>
        {prefix && <Prefix>{prefix}</Prefix>}
        <Content maxLines={1}>
          <Title>{title}</Title>
        </Content>
        <Content maxLines={1}>
          {description && <span>{description}</span>}
        </Content>
      </ContentWrapper>
    </StyledListItem>
  )
}
