import {
  AuditPointDetail,
  CheckPointDetail
} from '@cdab/scania/qpr/components/atoms'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import type { AuditDetailPanelProps } from './audit-detail-panel.interfaces'
import { AuditDetailPanelWrapper } from './audit-detail-panel.wrapper'

export function AuditDetailPanel({
  detail,
  className,
  onCloseClick
}: AuditDetailPanelProps): JSX.Element {
  const { t } = useTranslation('audit')
  // We don't need a default case, since we must fullfill each case for detail.type or we get compile-errors
  // eslint-disable-next-line default-case
  switch (detail.type) {
    case 'AUDIT_POINT':
      return (
        <AuditDetailPanelWrapper
          header={`Audit Point ${detail.auditPointNo}`}
          className={className}
          onCloseClick={onCloseClick}
        >
          <p className='my-2'>
            <span className='italic'>Description:</span>
            {` ${parse(t(detail.translationGuid))}`}
          </p>
          <AuditPointDetail auditPoint={detail} />
        </AuditDetailPanelWrapper>
      )

    case 'CHECK_POINT':
      return (
        <AuditDetailPanelWrapper
          header={`Check Point ${detail.checkPointNo}`}
          className={className}
          onCloseClick={onCloseClick}
        >
          <p className='my-2'>
            <span className='italic'>Description:</span>
            {` ${parse(t(detail.translationGuid))}`}
          </p>
          <CheckPointDetail checkPoint={detail} />
        </AuditDetailPanelWrapper>
      )
  }
}
