import type { RemindersService as IRemindersService } from '@cdab/scania/qpr/interactor'

export class RemindersService implements IRemindersService {
  private readonly mockDelayMS: number

  public constructor(mockDelayMS: number) {
    this.mockDelayMS = mockDelayMS
  }

  public SendAuditReminderForUser = async () => {
    throw new Error('Not implemented')
  }

  public SendActionPlanReminderForUser = async () => {
    throw new Error('Not implemented')
  }
}
