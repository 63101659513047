import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { Dealer } from '@cdab/scania/qpr/schema'

export type UpdateDealerResponse = 'success' | 'fail'

export function useEditDealer() {
  const client = useBackend()

  const updateDealer = async (
    dealer: Dealer,
    date: string
  ): Promise<UpdateDealerResponse> => {
    if (!date) {
      return 'fail'
    }

    try {
      await client.DealersService.UpdateExpirationDateForDealer(dealer.id, date)
      dealer.expiryDate = new Date(date)
    } catch (e) {
      return 'fail'
    }

    return 'success'
  }

  return {
    updateDealer
  }
}
