import {
  AuditBadge,
  AuditHeader,
  AuditPageTabs,
  ElasticContentContainer,
  EmptyState
} from '@cdab/scania/qpr/components'
import {
  AuditBadges,
  FavoriteAuditIconButton,
  HeaderRow,
  TabContainer
} from '@cdab/scania/qpr/components/atoms'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useFeatures } from '@cdab/scania/qpr/feature-flags'
import { useCssVariableBreakpoint, useIsDos5 } from '@cdab/scania/qpr/hooks'
import { getAuditIdFromParams } from '@cdab/scania/qpr/loaders'
import {
  auditPointsController,
  auditsController,
  favoriteAuditsController,
  type FullAuditData
} from '@cdab/scania/qpr/offline/controllers'
import type { FavoriteAuditModel } from '@cdab/scania/qpr/offline/models'
import type { ADHLink } from '@cdab/scania/qpr/schema'
import { Column, Container, Row } from '@cdab/scania/sdds'
import { formatISODate } from '@cdab/utils'
import * as sentry from '@sentry/react'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs } from 'react-router-dom'
import {
  Outlet,
  isRouteErrorResponse,
  useLoaderData,
  useParams,
  useRouteError
} from 'react-router-dom'
import invariant from 'tiny-invariant'

type Params = {
  auditId: string
  auditPointId: string
}

export type AuditLoaderData = FullAuditData & {
  favoriteAuditData?: FavoriteAuditModel
  adhLinks: ADHLink[]
}

export async function loader({
  params
}: LoaderFunctionArgs): Promise<AuditLoaderData> {
  // Since this loader uses the offline controller, we need to catch the error to make react-router work. If we don't catch the error, auditsController will throw one error for each route that uses this loader, and a blank page will be rendered.
  const auditId = getAuditIdFromParams(params)
  try {
    const [fullAuditData, favoriteAuditData] = await Promise.all([
      auditsController.GetAudit(auditId),
      favoriteAuditsController.Get(auditId).catch(() => undefined)
    ])

    try {
      const client = getClient()
      const adhLinks = await client.AuditsService.GetADHLinksForAudit(auditId)
      return {
        ...fullAuditData,
        favoriteAuditData,
        adhLinks
      }
    } catch (error) {
      return {
        ...fullAuditData,
        favoriteAuditData,
        adhLinks: []
      }
    }
  } catch (error) {
    // We want to capture this error (although it will happen multiple times if we get here),
    // and not the error we "re-throw below"
    sentry.captureException(error)

    throw new Response('Audit was not found', { status: 404 })
  }
}

export const AuditIdBaseView = observer((): JSX.Element => {
  const { auditId, auditPointId } = useParams<Params>()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { show_debug_actions } = useFeatures(['show_debug_actions'])

  const { updateTitles } = useTitle()

  invariant(auditId, 'auditId is required')

  const { audit, favoriteAuditData } = useLoaderData() as AuditLoaderData
  const isDos5 = useIsDos5(audit)
  const numberOfDeviations = audit.deviations.length

  const onApproveAllAuditPointsClick = useCallback(() => {
    audit.auditPoints.forEach(ap => {
      auditPointsController.SetScore(audit.id, ap.id, true)
    })
  }, [audit.auditPoints, audit.id])

  const onShowAsReadOnly = useCallback(() => {
    runInAction(() => {
      audit.isReadonly = !audit.isReadonly
    })
  }, [audit])

  useEffect(() => {
    // Only update the title if we are on the audit summary or deviations page. If we update here, this will override the title set by the child component inside the Outlet.
    if (!auditPointId) {
      updateTitles({
        mobile: {
          title: audit.description ?? '',
          description: (audit.date && formatISODate(new Date(audit.date))) || ''
        }
      })
    }
  }, [audit.date, audit.description, auditPointId, updateTitles])

  return (
    <ElasticContentContainer
      overflowHidden={true}
      header={
        isLg && (
          <>
            {show_debug_actions.enabled && (
              <HeaderRow>
                <Column width={12}>
                  <h4>DEBUG ACTIONS</h4>
                  <Row>
                    <button onClick={onApproveAllAuditPointsClick}>
                      Approve all audit points
                    </button>
                    <button onClick={onShowAsReadOnly}>
                      Toggle (visibility as) readonly
                    </button>
                  </Row>
                </Column>
              </HeaderRow>
            )}

            <HeaderRow>
              <AuditHeader
                title={
                  (audit.date && formatISODate(new Date(audit.date))) || ''
                }
                description={audit.description ?? ''}
              />

              <AuditBadges>
                <FavoriteAuditIconButton
                  favoriteAudit={favoriteAuditData}
                  auditId={audit.id}
                />
                {audit.isCertified && <AuditBadge type={'certify'} />}
                {audit.isReadonly && <AuditBadge type={'readonly'} />}
              </AuditBadges>
            </HeaderRow>

            <AuditPageTabs
              isDos5={isDos5}
              links={{
                deviations: 'deviations',
                points: 'points',
                summary: 'summary'
              }}
              numberOfDeviations={numberOfDeviations}
            />
          </>
        )
      }
    >
      <TabContainer>
        <Outlet />
      </TabContainer>
    </ElasticContentContainer>
  )
})

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <Container
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column width={12} padding={isLg}>
            <EmptyState
              title={t('audit-not-found')}
              description={t('talk-to-someone')}
            />
          </Column>
        </Row>
      </Container>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
