import type { ButtonProps } from './button.types'

export function Button({ ariaLabel, ...props }: Readonly<ButtonProps>) {
  let style: React.CSSProperties = {}

  if (
    'style' in props &&
    typeof props.style === 'object' &&
    props.style !== null
  ) {
    style = { ...props.style }
  }

  if (props.fullBleed) {
    // As according to their example
    // https://tegel.scania.com/components/buttons/code#web-component
    style.width = '100%'
  }

  return (
    <sdds-button
      aria-label={ariaLabel}
      class={props.className}
      style={style}
      {...props}
    />
  )
}
