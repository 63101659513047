import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export type GetSisImportJobStatusState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'syncing'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

export function useHandleGetSisImportJobStatus() {
  const { t } = useTranslation('market')
  const [stateGetSisImportJobStatus, setStateGetSisImportJobStatus] =
    useState<GetSisImportJobStatusState>({
      status: 'none',
      message: null
    })
  const client = useBackend()

  const getSisImportJobStatus = async (
    sisImportJobId: number,
    intervalId: NodeJS.Timeout
  ) => {
    try {
      const sisImportJobStatusWhiteSpace =
        await client.MarketsService.GetSisImportJobStatus(sisImportJobId)

      const sisImportJobStatus = sisImportJobStatusWhiteSpace.trim()

      if (sisImportJobStatus === 'FINISHED') {
        setStateGetSisImportJobStatus({
          status: 'success',
          message: t('message.dealers-synced')
        })
        clearInterval(intervalId)
      } else if (
        sisImportJobStatus === 'NEW' ||
        sisImportJobStatus === 'IN PROGRESS'
      ) {
        setStateGetSisImportJobStatus({
          status: 'syncing',
          message: ''
        })
      } else {
        setStateGetSisImportJobStatus({
          status: 'error',
          message: t('message.sync-dealers-failed-error')
        })
        clearInterval(intervalId)
      }
    } catch (error) {
      setStateGetSisImportJobStatus({
        status: 'error',
        message: t('message.sync-dealers-failed-error')
      })
      clearInterval(intervalId)
    }
  }

  const handleCallGetSisImportJobStatus = async (sisImportJobId: number) => {
    setStateGetSisImportJobStatus({
      status: 'syncing',
      message: ''
    })
    const intervalId = setInterval(async () => {
      getSisImportJobStatus(sisImportJobId, intervalId)
    }, 10000)
  }
  return [
    stateGetSisImportJobStatus,
    handleCallGetSisImportJobStatus,
    setStateGetSisImportJobStatus
  ] as const
}
