import { useState } from 'react'
import { Outlet } from 'react-router-dom'

import {
  ContentHeader,
  ElasticContentContainer,
  SideMenu
} from '@cdab/scania/qpr/components/atoms'
import {
  DeviationModalProvider,
  ToolbarHeader
} from '@cdab/scania/qpr/components/organisms'
import { useTitle } from '@cdab/scania/qpr/contexts/title'

import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { useRerenderOnce } from '@cdab/utils'
import { Body } from './base-layout.styles'
import type { BaseLayoutTemplateProps } from './base-layout.types'

export function BaseLayoutTemplate(props: BaseLayoutTemplateProps) {
  const { user } = props
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { desktop, mobile, contentHeader } = useTitle()
  const isLg = useCssVariableBreakpoint('--sdds-grid-md')

  // FIXME: This is a bad hack required to set correct flagsmith features when using <LoggedInBoundary />
  // I *believe* the problem is that we are getting the features from flagsmith without the hook,
  // so when we get values they are not "up to date". But that's just my intuition - Dennis
  useRerenderOnce()

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  const closeMobileMenu = () => {
    setMobileMenuOpen(false)
  }

  return (
    <DeviationModalProvider>
      <ElasticContentContainer
        header={
          <ToolbarHeader
            user={user}
            title={desktop.title}
            mobileDescription={mobile.description}
            mobileTitle={mobile.title}
            onMobileMenuClick={toggleMobileMenu}
          />
        }
      >
        <Body className='sdds-push'>
          <SideMenu
            isMobileMenuOpen={mobileMenuOpen}
            closeMobileMenu={closeMobileMenu}
            onMobileMenuClick={() => toggleMobileMenu()}
          />
          {isLg && contentHeader?.subtitle && contentHeader?.title ? (
            <ElasticContentContainer
              overflowHidden
              header={
                <ContentHeader
                  title={contentHeader.title}
                  subtitle={contentHeader.subtitle}
                />
              }
            >
              <Outlet />
            </ElasticContentContainer>
          ) : (
            <Outlet />
          )}
        </Body>
      </ElasticContentContainer>
    </DeviationModalProvider>
  )
}
