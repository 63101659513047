import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export type AvailableMarket = {
  id: number
}

export const AvailableMarketSchema: JSONSchemaType<AvailableMarket> = {
  type: 'object',

  properties: {
    id: {
      type: 'number'
    }
  },

  required: ['id']
}

const validateAvailableMarket: ValidateFunction<AvailableMarket> = ajv.compile(
  AvailableMarketSchema
)

export const isValidAvailableMarket = (
  availableMarket: unknown
): availableMarket is AvailableMarket =>
  isValidThing<AvailableMarket>(availableMarket, validateAvailableMarket)
