/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Dealer } from '@cdab/scania/qpr/schema'
import type { DealersService as IDealersService } from '@cdab/scania/qpr/interactor'
import { MockDealers } from '@cdab/scania/qpr/mockdata'

import { wait } from '@cdab/utils'

const mockDealersData: Dealer[] = MockDealers

export class DealersService implements IDealersService {
  private readonly mockDelayMS: number

  public constructor(mockDelayMS: number) {
    this.mockDelayMS = mockDelayMS
  }

  /**
   * Get a dealer by id
   *
   * @param dealerId - The dealer id
   * @returns Dealer
   */
  public GetDealerById = async (dealerId: number): Promise<Dealer> => {
    await wait(this.mockDelayMS)

    return mockDealersData[dealerId]
  }

  /**
   * Get dealers for a market
   *
   * @param marketId - The market id
   * @returns Dealers for the market
   */

  public GetDealersForMarket = async (auditId: number): Promise<Dealer[]> => {
    await wait(this.mockDelayMS)

    return mockDealersData
  }

  public UpdateExpirationDateForDealer = async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    dealerId: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    newDate: string
  ): Promise<void> => {
    throw new Error('Method not implemented.')
  }

  public GetExpiring = async () => {
    throw new Error('Not implemented')
  }

  public GetExpiryDateLimitForDealer = async (
    dealerId: number
  ): Promise<Date | null> => {
    throw new Error('Method not implemented.')
  }
}
