import type { JSONSchemaType, ValidateFunction } from 'ajv'
import type {
  ADHDeviationWithUnreadMessages,
  ADHDeviationWithUnreadMessagesJson
} from '../adh-deviation-with-unread-messages/adh-deviation-with-unread-messages'
import { ADHDeviationWithUnreadMessagesSchema } from '../adh-deviation-with-unread-messages/adh-deviation-with-unread-messages'
import { ajv, isValidThing } from '../ajv'

export interface ADHAuditWithUnreadMessages {
  auditId: number
  dealerInfo: string
  deviations: ADHDeviationWithUnreadMessages[]
}

export interface ADHAuditWithUnreadMessagesJson {
  auditId: number
  dealerInfo: string
  deviations: ADHDeviationWithUnreadMessagesJson[]
}

export const ADHAuditWithUnreadMessagesSchema: JSONSchemaType<ADHAuditWithUnreadMessagesJson> =
  {
    type: 'object',

    properties: {
      auditId: {
        type: 'integer'
      },
      dealerInfo: {
        type: 'string'
      },
      deviations: {
        type: 'array',
        items: ADHDeviationWithUnreadMessagesSchema
      }
    },

    required: ['auditId', 'dealerInfo', 'deviations']
  }

const validateADHAuditWithUnreadMessages: ValidateFunction<ADHAuditWithUnreadMessagesJson> =
  ajv.compile(ADHAuditWithUnreadMessagesSchema)

export const isValidADHAuditWithUnreadMessages = (
  ADHAuditWithUnreadMessages: unknown
): ADHAuditWithUnreadMessages is ADHAuditWithUnreadMessagesJson =>
  isValidThing<ADHAuditWithUnreadMessagesJson>(
    ADHAuditWithUnreadMessages,
    validateADHAuditWithUnreadMessages
  )
