import type { Audit } from '@cdab/scania/qpr/schema'

import type { AuditModelBase } from './base'
import { restoreDeviationDates } from './restoration.utils'
import type { AuditModelV3, AuditModelV3Data } from './v3'

export type AuditModelV4Data = AuditModelV3Data

export type AuditModelV4 = AuditModelBase<AuditModelV4Data, 4>

export function restoreAuditFromDataV4(audit: AuditModelV4) {
  restoreDeviationDates(audit.deviations)

  // FIXME: I believe the more correct approach would be to keep this "stateful" data uncached,
  // maybe in a different cache. But it works for now.
  audit.isGettingFiles = false

  return audit
}

export function createFromAuditV4(audit: Audit) {
  const auditModel: AuditModelV4 = {
    ...audit,
    auditPoints: audit.auditPoints.map(ap => ({
      ...ap,
      files: [],
      isGettingFiles: false
    })),
    deviations: audit.deviations.map(d => ({
      ...d,
      files: [],
      isGettingFiles: false
    })),
    pledges: audit.pledges.map(pl => ({
      ...pl,
      files: [],
      isGettingFiles: false
    })),
    files: [],
    isGettingFiles: false,
    VERSION: 4
  }

  return auditModel
}

export function migrateToV4(audit: AuditModelV3): AuditModelV4 {
  const auditModel: AuditModelV4 = Object.assign(audit, {
    pledges: audit.pledges.map(pl => ({
      ...pl,
      files: [],
      isGettingFiles: false
    })),
    VERSION: 4 as const
  })

  return auditModel
}
