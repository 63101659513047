import styled from 'styled-components'

export const Title = styled.span.attrs({
  className: 'tds-detail-04'
})``

export const Text = styled.p.attrs({
  className: 'tds-body-03'
})`
  margin-top 0;
  margin-bottom: 4rem;
`
