import { observer } from 'mobx-react-lite'

import { IconSorting, Tooltip } from '@cdab/scania/sdds'

import { IconWrapper, ButtonWrapper } from './print-sorting-button.styles'
import { useTranslation } from 'react-i18next'

export interface SortingIconButtonProps {
  onClick: () => void
}

export const SortingIconButton = observer(function SortingIconButton({
  onClick
}: SortingIconButtonProps) {
  const { t } = useTranslation('common')

  return (
    <ButtonWrapper onClick={onClick}>
      <Tooltip placement='top' selector='#reverse' text={t('change-sorting')} />

      <IconWrapper id='reverse'>
        <IconSorting />
      </IconWrapper>
    </ButtonWrapper>
  )
})
