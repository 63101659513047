/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type {
  StorageService as IStorageService,
  OnUploadProgressUpdate,
  UploadDealerFileResponse,
  UploadFileResponse
} from '@cdab/scania/qpr/interactor'
import type {
  AdminReferenceDocument,
  DocumentFileData,
  FileData
} from '@cdab/scania/qpr/schema'

export class StorageService implements IStorageService {
  private mockDelayMS: number

  public constructor(mockDelayMs: number) {
    this.mockDelayMS = mockDelayMs
  }
  GetADHUserFilesForDeviation(actionPlanItemId: number): Promise<
    {
      id: string
      url: string
      isImage: boolean
      fileName: string
      isUploaded: boolean
      uploadProgress: number
    }[]
  > {
    throw new Error('Method not implemented.')
  }
  DeleteFileForDealer(dealerId: number, fileId: string): Promise<void> {
    throw new Error('Method not implemented.')
  }
  GetDealerFilesForDealer(dealerId: number): Promise<
    {
      id: string
      url: string
      isImage: boolean
      fileName: string
      isUploaded: boolean
      uploadProgress: number
    }[]
  > {
    throw new Error('Method not implemented.')
  }
  UploadFileForDealer(
    dealerId: number,
    file: File,
    onProgressUpdate?: OnUploadProgressUpdate | undefined
  ): Promise<UploadDealerFileResponse> {
    throw new Error('Method not implemented.')
  }
  UploadFileForAuditPoint(
    auditId: number,
    auditPointId: number,
    file: File,
    onProgressUpdate?: OnUploadProgressUpdate | undefined
  ): Promise<UploadFileResponse> {
    throw new Error('Method not implemented.')
  }
  DeleteFileForAudit(auditId: number, fileId: string): Promise<void> {
    throw new Error('Method not implemented.')
  }
  GetFilesForAudit(auditId: number): Promise<
    {
      id: string
      url: string
      isImage: boolean
      fileName: string
      isUploaded: boolean
      uploadProgress: number
    }[]
  > {
    throw new Error('Method not implemented.')
  }
  UploadFileForAudit(
    auditId: number,
    file: File,
    onProgressUpdate?: OnUploadProgressUpdate | undefined
  ): Promise<UploadFileResponse> {
    throw new Error('Method not implemented.')
  }
  DeleteFileForDeviation(deviationId: number, fileId: string): Promise<void> {
    throw new Error('Method not implemented.')
  }
  DeleteFileForAuditPoint(
    auditId: number,
    auditPointId: number,
    fileId: string
  ): Promise<void> {
    throw new Error('Method not implemented.')
  }
  DeleteFileForPledge(
    auditId: number,
    pledgeId: number,
    fileId: string
  ): Promise<void> {
    throw new Error('Method not implemented.')
  }
  UploadFileForDeviation(
    auditId: number,
    deviationId: number,
    file: File,
    onProgressUpdate?: OnUploadProgressUpdate | undefined
  ): Promise<UploadFileResponse> {
    throw new Error('Method not implemented.')
  }
  GetFilesForDeviation(deviationId: number): Promise<FileData[]> {
    throw new Error('Method not implemented.')
  }
  GetAllFiles(): Promise<string[]> {
    throw new Error('Method not implemented.')
  }
  GetFile(path: string): Promise<unknown> {
    throw new Error('Method not implemented.')
  }

  DeleteFile(fileId: number): Promise<void> {
    throw new Error('Method not implemented.')
  }
  GetFilesForAuditPoint(
    auditId: number,
    auditPointId: number
  ): Promise<FileData[]> {
    throw new Error('Method not implemented.')
  }
  UploadFileForPledge(
    auditId: number,
    pledgeId: number,
    file: File,
    onProgressUpdate?: OnUploadProgressUpdate | undefined
  ): Promise<UploadFileResponse> {
    throw new Error('Method not implemented.')
  }
  GetFilesForPledge(auditId: number, pledgeId: number): Promise<FileData[]> {
    throw new Error('Method not implemented.')
  }
  UploadDocumentFile(
    file: File,
    onProgressUpdate?: OnUploadProgressUpdate
  ): Promise<AdminReferenceDocument['id']> {
    throw new Error('Method not implemented.')
  }
  DeleteDocumentFile(fileId: number): Promise<void> {
    throw new Error('Method not implemented.')
  }
  ReplaceDocumentFile(
    documenId: AdminReferenceDocument['id'],
    file: File,
    onProgressUpdate?: OnUploadProgressUpdate
  ): Promise<AdminReferenceDocument['id']> {
    throw new Error('Method not implemented.')
  }
  GetDocumentFile(fileId: number): Promise<DocumentFileData> {
    throw new Error('Method not implemented.')
  }
  GetFileUrl(key: string): Promise<string | undefined> {
    throw new Error('Method not implemented.')
  }
}
