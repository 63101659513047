import type { JSONSchemaType, ValidateFunction } from 'ajv'
import type { ADHActionPlanItemJson } from '../adh-action-plan-item'
import {
  ADHActionPlanItemSchema,
  mapResponseToADHActionPlanItem,
  type ADHActionPlanItem
} from '../adh-action-plan-item'
import { ajv, isValidThing } from '../ajv'

export interface ADHLink {
  id: number
  key: string
  name: string
  expiryDate: string
  dealerName: string
  actionPlanItems: ADHActionPlanItem[]
}

export interface ADHLinkJson {
  id: number
  key: string
  name: string
  expiryDate: string
  dealerName: string
  adhLinkActionPlanItems: ADHActionPlanItemJson[]
}

export interface ADHLinkNameIdDto {
  id: number
  name: string
  key: string
}

export const ADHLinkSchema: JSONSchemaType<ADHLinkJson> = {
  type: 'object',

  properties: {
    id: {
      type: 'integer'
    },
    key: {
      type: 'string'
    },
    dealerName: {
      type: 'string'
    },
    expiryDate: {
      type: 'string',
      format: 'iso-date-time',
      nullable: true
    },
    name: {
      type: 'string'
    },

    adhLinkActionPlanItems: { type: 'array', items: ADHActionPlanItemSchema }
  },

  required: [
    'id',
    'key',
    'expiryDate',
    'dealerName',
    'name',
    'adhLinkActionPlanItems'
  ]
}

const validateADHLink: ValidateFunction<ADHLinkJson> =
  ajv.compile(ADHLinkSchema)

export const isValidADHLink = (ADHLink: unknown): ADHLink is ADHLinkJson =>
  isValidThing<ADHLinkJson>(ADHLink, validateADHLink)

export const responseToADHLink = (json: ADHLinkJson): ADHLink => {
  const ADHLink: ADHLink = {
    id: json.id,
    name: json.name,
    key: json.key,
    expiryDate: json.expiryDate,
    dealerName: json.dealerName,
    actionPlanItems: []
  }

  for (const api of json.adhLinkActionPlanItems ?? []) {
    ADHLink.actionPlanItems.push(mapResponseToADHActionPlanItem(api))
  }

  return ADHLink
}
