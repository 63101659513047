import { v4 as uuidv4 } from 'uuid'
import invariant from 'tiny-invariant'
import { action } from 'mobx'

import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { Audit } from '@cdab/scania/qpr/schema'
import type { AuditModel } from '../audit-model'

type Type = 'set-checkpoint-note'

type Data = {
  note: string
  auditCheckPointId: number
}

export type SetCheckPointNoteOperationData = OperationDataBase<Type, Data>

export class SetCheckPointNoteOperation extends Operation {
  public data: SetCheckPointNoteOperationData

  constructor(
    auditId: Audit['id'],
    value: Data,
    previousValue: Data,
    guid = uuidv4()
  ) {
    super(auditId, guid)

    this.data = {
      type: 'set-checkpoint-note',
      value,
      previousValue
    }
  }

  private getCheckPointIndex = (audit: AuditModel) => {
    const { auditCheckPointId } = this.data.value
    const checkPointIndex = audit.checkPoints.findIndex(
      ({ id }) => id === auditCheckPointId
    )

    invariant(
      auditCheckPointId !== -1,
      `Tried to set checkpoint note but checkpoint was not found! Checkpoint Id: ${auditCheckPointId}`
    )
    return checkPointIndex
  }

  public ApplyTo = action((audit: AuditModel): void => {
    const auditPointIndex = this.getCheckPointIndex(audit)

    const { note } = this.data.value

    audit.checkPoints[auditPointIndex].note = note
  })

  public GetName = () =>
    `${this.data.type}-${this.auditId}-${this.data.value.auditCheckPointId}`

  public SendOperation = async (client: BackEnd) => {
    const success = await client.AuditsRealTimeService.SetNote({
      auditId: this.auditId,
      auditPointId: null,
      callIdentifier: this.guid,
      checkPointId: this.data.value.auditCheckPointId,
      created: new Date(),
      note: this.data.value.note,
      pledgeId: null
    })

    return success
  }
  public RollbackOn = action((audit: AuditModel): void => {
    const checkPointIndex = this.getCheckPointIndex(audit)

    invariant(
      this.data.previousValue,
      'Tried to rollback set checkpoint note but we had no previous value!'
    )

    audit.checkPoints[checkPointIndex].note = this.data.previousValue.note
  })
}
