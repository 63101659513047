import type { Audit } from '@cdab/scania/qpr/schema'

import { AuditPoints } from './audit-points'
import { CheckPoints } from './checkpoints'
import { PledgeAreas } from './pledge-areas'
import { PledgeSpectives } from './pledge-perspectives'
import { Pledges } from './pledges'
import { ReferenceDocuments } from './reference-documents'

export const MockAudit12345: Audit = {
  isCertified: false,
  isReadonly: false,
  id: 12345,
  did: 1,
  date: new Date('2020-01-01'),
  description: 'A small descripton for this audit',
  note: 'This is a mock audit',
  dosVersion: 6,
  pledgeAreas: PledgeAreas,
  pledges: Pledges,
  pledgePerspectives: PledgeSpectives,
  auditPoints: AuditPoints,
  checkPoints: CheckPoints,
  referenceDocuments: ReferenceDocuments,
  extraAuditors: '',
  deviations: [],
  auditTypeId: 0
}
