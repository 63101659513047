import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { CreateADHLinkData } from '@cdab/scania/qpr/interactor'
import type { ADHLink } from '@cdab/scania/qpr/schema'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export type CreateADHLinkFileState =
  | {
      status: 'success'
      linkData: ADHLink
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'loading'
      message: null
    }
  | {
      status: 'none'
      message: null
    }

export function useCreateADHLink() {
  const { t } = useTranslation('audit')
  const [submitState, setSubmitState] = useState<CreateADHLinkFileState>({
    status: 'none',
    message: null
  })
  const client = useBackend()

  const handleClickCreateADHLink = async (data: CreateADHLinkData) => {
    setSubmitState({ status: 'loading', message: null })
    try {
      const expiryDate = new Date()
      expiryDate.setDate(expiryDate.getDate() + 1) //FIX ME: should it be +1 day?

      const linkData = await client.AuditsService.CreateADHLink({
        ...data,
        expiryDate: expiryDate.toISOString()
      })

      setSubmitState({
        status: 'success',
        linkData: linkData,
        message: t('adh.success-created')
      })
    } catch (e) {
      setSubmitState({
        status: 'error',
        message: t('adh.create-error')
      })
    }
  }

  return [submitState, handleClickCreateADHLink, setSubmitState] as const
}
