import { CertificationStatus } from '@cdab/scania/qpr/schema'
import type { DealerModel } from '@cdab/scania/qpr/offline/models'

// TODO: Move to mockdata package
export const MockDealers: DealerModel[] = [
  {
    id: 2250,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40015',
    name: 'Börjessons Lastbilar AB',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Alingsås',
    auditDate: new Date('2022-03-02T00:00:00'),
    expiryDate: new Date('2022-06-13T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2286,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40625',
    name: 'Norrlands Bil Tunga Fordon AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Arvidsjaur',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2287,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40227',
    name: 'Lecab Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Arvika',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-12-14T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2374,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40267',
    name: 'Scania Sverige AB / Bollnäs',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Bollnäs',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2383,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40278',
    name: 'Scania Sverige AB / Borlänge',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Borlänge',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2385,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40215',
    name: 'Scania Sverige AB / Borås',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Borås',
    auditDate: new Date('2020-10-22T00:00:00'),
    expiryDate: new Date('2022-10-22T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2569,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40066',
    name: 'Arver Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Eskilstuna',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2023-11-08T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2577,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40140',
    name: 'Toveks Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Falköping',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2023-02-15T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2670,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40266',
    name: 'Scania Sverige AB / Gävle',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Gävle',
    auditDate: new Date('2020-09-03T00:00:00'),
    expiryDate: new Date('2022-09-03T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2671,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40210',
    name: 'Scania Sverige AB / Hisings Backa',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Hisings Backa Göteborg',
    auditDate: new Date('2019-10-23T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2681,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40230',
    name: 'Motor AB Halland',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Halmstad',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2024-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2699,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40279',
    name: 'Scania Sverige AB / Hedemora',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Hedemora',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2703,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40341',
    name: 'Scania Sverige AB / Helsingborg',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Helsingborg',
    auditDate: new Date('2019-11-14T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2710,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40217',
    name: 'Scania Sverige AB / Himle',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Himle Tvååker',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2721,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40268',
    name: 'Scania Sverige AB / Hudiksvall',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Hudiksvall',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2728,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40592',
    name: 'Be-Ge Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Hultsfred',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2729,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40768',
    name: 'Motor Väst AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: false,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Hällevadsholm',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2731,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40289',
    name: 'Lastvagnsservice Härnösand AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: false,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Härnösand',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2732,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40423',
    name: 'Börjessons Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Hässleholm',
    auditDate: new Date('2020-09-17T00:00:00'),
    expiryDate: new Date('2022-09-17T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2765,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40360',
    name: 'Atteviks Lastvagnar AB',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Jönköping',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2770,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40465',
    name: 'Atteviks Lastvagnar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Kalmar',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2024-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2776,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40380',
    name: 'Börjessons Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Karlshamn',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-12-18T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2777,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40097',
    name: 'Arver Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Karlskoga',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2778,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40383',
    name: 'Börjessons Lastbilar AB',
    truck: true,
    bus: false,
    engine: true,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Karlskrona',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2023-09-22T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2781,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40410',
    name: 'Lecab Lastbilar AB',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: true,
    engineSales: false,
    partsSales: true,
    city: 'Karlstad',
    auditDate: new Date('2020-01-29T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2785,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40067',
    name: 'Arver Lastbilar AB',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Katrineholm',
    auditDate: new Date('2022-01-25T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2799,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40847',
    name: 'Engströms Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Kisa',
    auditDate: new Date('2019-10-30T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2825,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40435',
    name: 'Börjessons Lastbilar AB',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Kristianstad',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2023-03-22T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2826,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40226',
    name: 'Lecab Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Kristinehamn',
    auditDate: new Date('2020-09-29T00:00:00'),
    expiryDate: new Date('2022-09-29T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2828,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40457',
    name: 'Bilab Lastbilar AB',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Kungälv',
    auditDate: new Date('2020-09-23T00:00:00'),
    expiryDate: new Date('2022-09-23T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2889,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40460',
    name: 'Toveks Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Lidköping',
    auditDate: new Date('2019-12-11T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2899,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40846',
    name: 'Engströms Lastbilar AB',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Linköping',
    auditDate: new Date('2022-01-10T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2908,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40831',
    name: 'Atteviks Lastvagnar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Ljungby',
    auditDate: new Date('2021-05-21T00:00:00'),
    expiryDate: new Date('2022-05-09T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Certified,
    VERSION: 1
  },
  {
    id: 2923,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40487',
    name: 'Norrlands Bil Tunga Fordon AB',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Luleå',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 2924,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40342',
    name: 'Scania Sverige AB / Lund',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Lund',
    auditDate: new Date('2019-11-12T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 2931,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40500',
    name: 'Bil-Nord i Lycksele AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Lycksele',
    auditDate: new Date('2020-02-26T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 2956,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40340',
    name: 'Scania Sverige AB / Malmö',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Malmö',
    auditDate: new Date('2020-02-05T00:00:00'),
    expiryDate: new Date('2022-07-03T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 2957,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40623',
    name: 'Norrlands Bil Tunga Fordon AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Malå',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 2976,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40648',
    name: 'Toveks Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Mariestad',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3026,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40309',
    name: 'Scania Sverige AB / Mora',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Mora',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3034,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40520',
    name: 'Wahlstedts Bil AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Motala',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2023-11-19T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3085,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40554',
    name: 'Arver Lastbilar AB',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Norrköping',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2023-02-13T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3097,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40466',
    name: 'Atteviks Lastvagnar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Nybro',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-04-08T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3098,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40558',
    name: 'Arver Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Nyköping',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2023-10-03T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3101,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40362',
    name: 'Atteviks Lastvagnar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Nässjö',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-05-09T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3115,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40599',
    name: 'Be-Ge Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Oskarshamn',
    auditDate: new Date('2019-12-04T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3170,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40486',
    name: 'Norrlands Bil Tunga Fordon AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Piteå',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3277,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40092',
    name: 'Arver Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Sala',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-11-21T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3346,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40620',
    name: 'Norrlands Bil Tunga Fordon AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Skellefteå',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3349,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40640',
    name: 'Toveks Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Skövde',
    auditDate: new Date('2022-01-20T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3352,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40651',
    name: 'Svenstigs Bil AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Smålandsstenar',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2023-07-01T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3354,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40288',
    name: 'Berners Tunga Fordon AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Sollefteå',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3376,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40747',
    name: 'Scania Sverige AB / Kallhäll',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Stockholm Kallhäll',
    auditDate: new Date('2020-10-28T00:00:00'),
    expiryDate: new Date('2022-10-28T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3377,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40436',
    name: 'Scania Sverige AB / Norrtälje',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Norrtälje',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2023-06-25T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3378,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40688',
    name: 'Scania Sverige AB / Enköping',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Enköping',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3379,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40585',
    name: 'Scania Sverige AB / Jordbro',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Stockholm Jordbro',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2024-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3380,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40685',
    name: 'Scania Sverige AB / Häggvik',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Stockholm Häggvik',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3381,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40680',
    name: 'Scania Sverige AB / Kungens Kurva',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Stockholm Kungens Kurva',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3396,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40286',
    name: 'Berners Tunga Fordon AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Sundsvall Sundsbruk',
    auditDate: new Date('2020-02-19T00:00:00'),
    expiryDate: new Date('2022-07-13T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3397,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40228',
    name: 'Lecab Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Sunne',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-12-15T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3402,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40922',
    name: 'Berners Tunga Fordon AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Sveg',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2024-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3410,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40735',
    name: 'Scania Sverige AB / Södertälje Hovsjö',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Södertälje Hovsjö',
    auditDate: new Date('2020-10-14T00:00:00'),
    expiryDate: new Date('2022-10-14T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3451,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40759',
    name: 'Scania Sverige AB / Tomelilla',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Tomelilla',
    auditDate: new Date('2020-01-15T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.Expired,
    VERSION: 1
  },
  {
    id: 3468,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40364',
    name: 'Atteviks Lastvagnar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Tranås',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3479,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40824',
    name: 'Toveks Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Trollhättan',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-09-26T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3490,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40822',
    name: 'Toveks Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Uddevalla',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-11-10T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3496,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40770',
    name: 'Motorcentralen i Umeå AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Umeå',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3497,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40784',
    name: 'Scania Sverige AB / Uppsala',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Uppsala',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3516,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40464',
    name: 'Toveks Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Vara',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2024-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3546,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40595',
    name: 'Be-Ge Lastbilar AB',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Vetlanda',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3565,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40849',
    name: 'Engströms Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Vimmerby',
    auditDate: new Date('2019-10-31T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3568,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40814',
    name: 'Be-Ge Lastbilar AB',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Visby',
    auditDate: new Date('2022-01-31T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3595,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40650',
    name: 'Svenstigs Bil AB',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Värnamo',
    auditDate: new Date('2021-06-23T00:00:00'),
    expiryDate: new Date('2022-09-28T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3596,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40848',
    name: 'Engströms Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Västervik',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3597,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40091',
    name: 'Arver Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Västerås',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-11-24T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3598,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40830',
    name: 'Atteviks Lastvagnar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Växjö',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-08-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3624,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40823',
    name: 'Toveks Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Åmål',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3625,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40832',
    name: 'Atteviks Lastvagnar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Älmhult',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-08-28T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3627,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40096',
    name: 'Arver Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Örebro',
    auditDate: new Date('2020-10-01T00:00:00'),
    expiryDate: new Date('2022-10-01T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3628,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40918',
    name: 'Bil-Nord i Örnsköldsvik AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Örnsköldsvik Arnäsvall',
    auditDate: new Date('2020-03-12T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3629,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40287',
    name: 'Berners Tunga Fordon AB',
    truck: true,
    bus: true,
    engine: false,
    truckSales: true,
    busSales: true,
    engineSales: false,
    partsSales: true,
    city: 'Östersund',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3630,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40016',
    name: 'Börjessons Lastbilar AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Östra Ljungby',
    auditDate: new Date('2020-09-16T00:00:00'),
    expiryDate: new Date('2022-09-16T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 3961,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40686',
    name: 'Scania Sverige AB / Kungens Kurva / Industrimotorer Marinmotorer',
    truck: false,
    bus: false,
    engine: true,
    truckSales: false,
    busSales: false,
    engineSales: true,
    partsSales: true,
    city: 'Stockholm Kungens Kurva Motorverkstaden',
    auditDate: new Date('2019-03-12T00:00:00'),
    expiryDate: new Date('2021-08-18T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 4099,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40483',
    name: 'Norrlands Bil Tunga Fordon AB',
    truck: true,
    bus: false,
    engine: true,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Kiruna',
    auditDate: new Date('2022-01-01T00:00:00'),
    expiryDate: new Date('2022-03-31T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 4319,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40216',
    name: 'Ö-varvet AB',
    truck: false,
    bus: false,
    engine: true,
    truckSales: false,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Öckerö',
    auditDate: new Date('2020-09-24T00:00:00'),
    expiryDate: new Date('2022-09-24T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 4653,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40211',
    name: 'Scania Sverige AB / Arendal',
    truck: true,
    bus: false,
    engine: true,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Arendal Göteborg',
    auditDate: new Date('2020-10-21T00:00:00'),
    expiryDate: new Date('2022-10-21T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 5188,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40000',
    name: 'Scania Sverige AB / Centralkundsförsäljning',
    truck: false,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: true,
    engineSales: false,
    partsSales: false,
    city: 'Stockholm Lindvreten',
    auditDate: new Date('2021-04-14T00:00:00'),
    expiryDate: new Date('2023-04-14T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 5530,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40484',
    name: 'Norrlands Bil Tunga Fordon AB',
    truck: true,
    bus: false,
    engine: false,
    truckSales: true,
    busSales: false,
    engineSales: false,
    partsSales: true,
    city: 'Gällivare',
    auditDate: new Date('2020-06-30T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 5551,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40715',
    name: 'Tenö Varv AB',
    truck: false,
    bus: false,
    engine: true,
    truckSales: false,
    busSales: false,
    engineSales: false,
    partsSales: false,
    city: 'Vaxholm',
    auditDate: new Date('2019-12-17T00:00:00'),
    expiryDate: new Date('2022-03-22T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 5552,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40343',
    name: 'Simrishamns Varv AB',
    truck: false,
    bus: false,
    engine: true,
    truckSales: false,
    busSales: false,
    engineSales: false,
    partsSales: false,
    city: 'Simrishamn',
    auditDate: new Date('2020-01-16T00:00:00'),
    expiryDate: new Date('2022-01-16T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 5582,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40440',
    name: 'Vinga Bus Partner AB',
    truck: false,
    bus: false,
    engine: false,
    truckSales: false,
    busSales: true,
    engineSales: false,
    partsSales: false,
    city: 'Kungälv',
    auditDate: new Date('2022-01-31T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 5676,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40748',
    name: 'Muskövarvet AB',
    truck: false,
    bus: false,
    engine: true,
    truckSales: false,
    busSales: false,
    engineSales: false,
    partsSales: false,
    city: 'Muskö',
    auditDate: new Date('2022-04-14T00:00:00'),
    expiryDate: new Date('2022-03-05T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 5679,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40749',
    name: 'N. Sundin Dockstavarvet AB',
    truck: false,
    bus: false,
    engine: true,
    truckSales: false,
    busSales: false,
    engineSales: false,
    partsSales: false,
    city: 'Docksta',
    auditDate: new Date('2020-03-13T00:00:00'),
    expiryDate: new Date('2022-03-13T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 5843,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40212',
    name: 'Swede Ship Yachtservice AB',
    truck: false,
    bus: false,
    engine: true,
    truckSales: false,
    busSales: false,
    engineSales: false,
    partsSales: false,
    city: 'Västra Frölunda',
    auditDate: new Date('2019-10-23T00:00:00'),
    expiryDate: new Date('2022-06-30T00:00:00'),
    hasDeviations: true,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  },
  {
    id: 8237,
    marketId: 1,
    marketName: 'Sverige',
    dealerId: '40925',
    name: 'Berners Tunga Fordon AB / Bussverkstaden',
    truck: false,
    bus: true,
    engine: false,
    truckSales: false,
    busSales: true,
    engineSales: false,
    partsSales: false,
    city: 'Östersund',
    auditDate: new Date('2021-12-30T00new Date(:00:00'),
    expiryDate: null,
    hasDeviations: false,
    certificationStatus: CertificationStatus.NeverCertified,
    VERSION: 1
  }
]
