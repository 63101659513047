import invariant from 'tiny-invariant'
import type { StoreName } from './constants'
import { getDb } from './indexeddb'

export async function createTransaction(
  storeNames: StoreName[],
  mode: IDBTransactionMode
): Promise<IDBTransaction> {
  const db = await getDb()

  invariant(db, `Tried to create a transaction without a db`)

  const tx = db.transaction(storeNames, mode)
  return tx
}
