import invariant from 'tiny-invariant'
import { v4 as uuidv4 } from 'uuid'

import type { AuditModel, PledgeModel } from '@cdab/scania/qpr/offline/models'
import { DeletePledgeFileOperation } from '@cdab/scania/qpr/offline/models'
import { addPledgeFiles } from '@cdab/scania/qpr/offline/models'
import { getAudit } from '@cdab/scania/qpr/offline/models'
import {
  AddPledgeFileOperation,
  SetPledgeNoteOperation,
  getPledge
} from '@cdab/scania/qpr/offline/models'
import { auditsController } from './audits-controller'

import { operationsController } from './operations-controller'
import type { Audit, Pledge, FileData } from '@cdab/scania/qpr/schema'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { runInAction } from 'mobx'

type AuditId = AuditModel['id']
type PledgeId = PledgeModel['id']

class PledgeController {
  private getPledge = async (
    auditId: AuditId,
    pledgeId: PledgeId
  ): Promise<PledgeModel> => {
    const { audit } = await auditsController.GetAudit(auditId, {
      dontListen: true
    })

    const pledge = audit.pledges.find(p => p.id === pledgeId)

    invariant(pledge, `No pledge found for {${auditId}:${pledgeId}}`)

    return pledge
  }

  public SaveNote = async (
    auditId: AuditId,
    pledgeId: PledgeId,
    note: string
  ) => {
    const pledge = await this.getPledge(auditId, pledgeId)

    const operation = new SetPledgeNoteOperation(
      auditId,
      {
        note,
        pledgeId
      },
      pledge.note || undefined
    )

    operationsController.handleNewOperation(operation)
  }

  public GetPledge = async (auditId: Audit['id'], pledgeId: Pledge['id']) => {
    if (!getAudit(auditId)) {
      await auditsController.GetAudit(auditId)
    }

    const pledge = getPledge(auditId, pledgeId)

    return pledge
  }

  public AddFile = async (
    auditId: Audit['id'],
    pledgeId: Pledge['id'],
    file: File
  ) => {
    const operation = new AddPledgeFileOperation(auditId, {
      pledgeId,
      file,
      fileGuid: uuidv4()
    })

    operationsController.handleNewOperation(operation)
  }

  public GetFiles = async (auditId: Audit['id'], pledgeId: Pledge['id']) => {
    const client = getClient()
    const pledge = await this.GetPledge(auditId, pledgeId)

    try {
      runInAction(() => {
        pledge.isGettingFiles = true
      })
      const files = await client.StorageService.GetFilesForPledge(
        auditId,
        pledgeId
      )

      addPledgeFiles(auditId, pledgeId, files)
    } catch (error) {
      console.warn('There was an error getting pledge files', error)
    } finally {
      runInAction(() => {
        pledge.isGettingFiles = false
      })
    }
  }

  public DeleteFile = async (
    auditId: Audit['id'],
    pledgeId: Pledge['id'],
    fileId: FileData['id']
  ) => {
    const operation = new DeletePledgeFileOperation(auditId, {
      pledgeId,
      fileId
    })

    operationsController.handleNewOperation(operation)
  }
}

export const pledgeController = new PledgeController()
