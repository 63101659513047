import { type TFunction } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs } from 'react-router-dom'
import {
  isRouteErrorResponse,
  json,
  useLoaderData,
  useNavigate,
  useParams,
  useRouteError
} from 'react-router-dom'
import styled from 'styled-components'

import {
  EmptyState,
  PageHeader,
  ScrollableWrapper
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import {
  useCreateSyncDealersForMarket,
  useCssVariableBreakpoint,
  useHandleGetSisImportJobStatus,
  useSddsTableRowClickWithCellValue
} from '@cdab/scania/qpr/hooks'
import type { Dealer, Market, User } from '@cdab/scania/qpr/schema'
import { CertificationStatus, Roles } from '@cdab/scania/qpr/schema'
import type { TOption } from '@cdab/scania/sdds'
import {
  Button,
  Column,
  Container,
  DataTable,
  DelayedSpinner,
  Dropdown,
  HeaderCell,
  Row,
  Toast
} from '@cdab/scania/sdds'
import { capitalizeFirstLetter, formatISODate, isNumber } from '@cdab/utils'
import { toast } from 'react-toastify'
import { SpinnerWrapper } from './dealer/dealer.styles'

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const DropdownWrapper = styled.div`
  align-self: flex-end;
  margin-top: 4rem;
  flex: 1;

  @media (min-width: ${({ theme }) => theme.size.lg}) {
    flex: initial;
  }
`

const SyncButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-top: 2rem;
  flex-direction: column;
`

const StyledButton = styled(Button)`
  margin-top: 4rem;
  margin-bottom: 1rem;
  @media (max-width: ${({ theme }) => theme.size.lg}) {
    margin-bottom: 2rem;
    margin-top: 0;
  }
`

type Params = {
  marketId: string
}

function prepareTableData(data: readonly Dealer[], t: TFunction) {
  const preparedData = data.map(dealer => {
    let expiryDateInfo: string | null = null

    switch (dealer.certificationStatus) {
      case CertificationStatus.Certified:
        expiryDateInfo = dealer.expiryDate
          ? formatISODate(dealer.expiryDate)
          : ''
        break

      case CertificationStatus.Expired:
        expiryDateInfo = t('certification-status.expired', { ns: 'common' })
        break

      case CertificationStatus.NeverCertified:
        expiryDateInfo = t('certification-status.never-certified', {
          ns: 'common'
        })
        break
    }

    return {
      city: dealer.city,
      dealerId: dealer.dealerId,
      name: dealer.name,
      auditDate: dealer.auditDate ? formatISODate(dealer.auditDate) : '',
      expiryDate: expiryDateInfo,
      activeDeviations: dealer.hasDeviations ? 'Yes' : 'No', //FIXME: Use translation
      id: dealer.id
    }
  })

  return preparedData
}

export type MarketPageLoaderData = {
  dealers: Dealer[]
  markets: Market[]
  currentUser: User
}

export async function loader({
  params
}: LoaderFunctionArgs): Promise<MarketPageLoaderData> {
  const { marketId: marketIdParam } = params
  const marketId = Number.parseInt(marketIdParam || 'NaN')

  if (!isNumber(marketId)) {
    throw json({
      status: 400
    })
  }

  const client = getClient()

  const user = await client.GetuserInfo()
  if (!user) {
    throw json({
      status: 401
    })
  }

  const marketsPromise = client.MarketsService.GetMarketsForUser(user.userId)
  const dealersPromise = client.DealersService.GetDealersForMarket(marketId)
  const currentUserPromise = client.UserService.GetUser(user.userId)

  const [markets, dealers, currentUser] = await Promise.all([
    marketsPromise,
    dealersPromise,
    currentUserPromise
  ])
  return {
    markets,
    dealers,
    currentUser
  }
}

export function MarketPage() {
  const { t } = useTranslation(['market', 'common', 'dealer'])
  const { dealers, markets, currentUser } =
    useLoaderData() as MarketPageLoaderData
  const navigate = useNavigate()
  const params = useParams<Params>()
  const { marketId } = params
  const { updateTitles, desktop } = useTitle()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const [tableEl, setTableEl] = useState<HTMLTableRowElement>()

  const [handleClickCreateSISSyncState] = useCreateSyncDealersForMarket(
    Number(marketId)
  )

  const [
    stateGetSisImportJobStatus,
    handleCallGetSisImportJobStatus,
    setStateGetSisImportJobStatus
  ] = useHandleGetSisImportJobStatus()

  const canCurrentUserSyncDealersForMarket =
    currentUser.role >= Roles.CoOrdinator

  const market: Market | undefined = markets.find(
    market => market.id === Number(marketId)
  )

  const marketFilterData = markets
    .map(market => ({
      label: market.marketName,
      value: market.id.toString()
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  useSddsTableRowClickWithCellValue(tableEl, 'id', dealerId => {
    if (dealerId) {
      navigate(`/dealer/${dealerId}`)
    }
  })

  const onSelectMarket = useCallback(
    (option: TOption) => {
      navigate(`/dealers/${option.value}`)
    },
    [navigate]
  )

  const handleDealersSyncClick = async () => {
    const sisId = await handleClickCreateSISSyncState()
    if (sisId) handleCallGetSisImportJobStatus(sisId)
  }

  useEffect(() => {
    if (stateGetSisImportJobStatus.status === 'success') {
      toast(
        <Toast
          type='success'
          headline={capitalizeFirstLetter(
            t('message.sync-dealers-succuss', { ns: 'market' })
          )}
          subheadline={stateGetSisImportJobStatus.message}
        />
      )
      setStateGetSisImportJobStatus({ status: 'none', message: null })
    }

    if (stateGetSisImportJobStatus.status === 'error') {
      toast(
        <Toast
          type={stateGetSisImportJobStatus.status}
          headline={capitalizeFirstLetter(
            t('message.sync-dealers-error', { ns: 'market' })
          )}
          subheadline={stateGetSisImportJobStatus.message}
        />,
        {
          autoClose: false
        }
      )
      setStateGetSisImportJobStatus({ status: 'none', message: null })
    }
  }, [
    navigate,
    t,
    stateGetSisImportJobStatus.status,
    stateGetSisImportJobStatus.message,
    setStateGetSisImportJobStatus
  ])

  useEffect(() => {
    updateTitles({
      desktop: {
        title: desktop.title
      },
      mobile: {
        title: market?.marketName || t('market'),
        description: null
      }
    })
  }, [desktop.title, t, updateTitles, market?.marketName])

  if (!market) {
    return (
      <Container
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column width={12} padding={isLg}>
            <EmptyState title={t('market-not-found', { ns: 'market' })} />
          </Column>
        </Row>
      </Container>
    )
  }

  return (
    <ScrollableWrapper>
      <Container fluid='normal'>
        {isLg && (
          <Row>
            <Column width={12} padding={isLg}>
              <PageHeader title={market.marketName} />
            </Column>
          </Row>
        )}
        <Row>
          {markets.length > 0 && (
            <Column
              width={12}
              lg={4}
              padding={isLg}
              style={{ overflow: 'inherit' }}
            >
              <HeaderRow>
                <DropdownWrapper>
                  <Dropdown
                    onSelect={onSelectMarket}
                    filter={marketFilterData}
                    defaultOption={marketId}
                    placeholder={t('available-markets')}
                  />
                </DropdownWrapper>
              </HeaderRow>
            </Column>
          )}
          <Column width={12} lg={markets.length > 0 ? 8 : 12}>
            <SyncButtonWrapper>
              {canCurrentUserSyncDealersForMarket &&
                (stateGetSisImportJobStatus.status === 'syncing' ? (
                  <SpinnerWrapper>
                    <DelayedSpinner size='md' />
                  </SpinnerWrapper>
                ) : (
                  <StyledButton
                    text={capitalizeFirstLetter(
                      t('sync-dealers', {
                        ns: 'market'
                      })
                    )}
                    type='primary'
                    size='md'
                    onClick={handleDealersSyncClick}
                  />
                ))}
            </SyncButtonWrapper>
          </Column>
        </Row>

        <Row>
          <Column width={12} padding={isLg}>
            <ScrollableWrapper>
              <DataTable
                ref={setTableEl}
                title={t('dealers-in', { market: market.marketName })}
                filtering
                data={prepareTableData(dealers, t)}
                noMinWidth={!isLg}
              >
                <HeaderCell
                  sortable
                  columnKey='city'
                  title={t('city', { ns: 'common' })}
                />
                <HeaderCell
                  sortable
                  columnKey='dealerId'
                  title={t('dealer-id')}
                />
                <HeaderCell
                  sortable
                  columnKey='name'
                  title={t('name', { ns: 'common' })}
                />
                <HeaderCell
                  sortable
                  columnKey='auditDate'
                  title={t('audit-date', { ns: 'common' })}
                />
                <HeaderCell
                  sortable
                  columnKey='expiryDate'
                  title={t('expiry-date', { ns: 'common' })}
                />
                <HeaderCell
                  sortable
                  columnKey='activeDeviations'
                  title={t('active-deviations')}
                />
                <HeaderCell sortable columnKey='id' title='ID' />
              </DataTable>
            </ScrollableWrapper>
          </Column>
        </Row>
      </Container>
    </ScrollableWrapper>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation(['errors', 'common'])
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const navigate = useNavigate()
  const error = useRouteError()

  function goToMarkets() {
    navigate('/dealers')
  }

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    switch (error.status) {
      case 401:
        message = t('not-logged-in')
        break
      case 400:
        message = t('invalid-market-id')
        break
    }

    return (
      <Container
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column width={12} padding={isLg}>
            <EmptyState title={t('market-not-found')} description={message}>
              <Button onClick={goToMarkets} text={t('go-to-dealers')} />
            </EmptyState>
          </Column>
        </Row>
      </Container>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
