import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useRouteLoaderData } from 'react-router-dom'
import styled from 'styled-components'

import { AttachmentList, UploadFileButton } from '@cdab/scania/qpr/components'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import {
  getAuditIdFromParams,
  getAuditPointIdFromParams
} from '@cdab/scania/qpr/loaders'
import { auditPointsController } from '@cdab/scania/qpr/offline/controllers'
import type { FileData } from '@cdab/scania/qpr/schema'
import { Column, Container, Divider, Row, Spinner } from '@cdab/scania/sdds'
import type { AuditLoaderData } from '../../../../auditId'

import type { AuditPointLoaderData } from '../audit-point-base-view'

export async function loader({ params }: LoaderFunctionArgs) {
  const auditId = getAuditIdFromParams(params)
  const auditPointId = getAuditPointIdFromParams(params)

  auditPointsController.GetFiles(auditId, auditPointId)

  return null
}

export const Attachments = observer(function Attachments() {
  const { audit } = useRouteLoaderData('audit') as AuditLoaderData
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')

  const { auditPoint } = useRouteLoaderData(
    'audit-point'
  ) as AuditPointLoaderData

  const { t } = useTranslation('audit')

  const onUploadFiles = useCallback(
    (files: FileList) => {
      Array.from(files).forEach(file => {
        auditPointsController.AddFile(audit.id, auditPoint.id, file)
      })
    },
    [audit.id, auditPoint.id]
  )

  const onDeleteUploadedFile = useCallback(
    (fileId: FileData['id']) => {
      if (!window.confirm(t('attachments.confirm'))) {
        return
      }
      auditPointsController.DeleteFile(audit.id, auditPoint.id, fileId)
    },
    [audit.id, auditPoint.id, t]
  )

  const uploadedFiles: FileData[] = []
  const localFiles: FileData[] = []
  auditPoint.files.forEach(file => {
    if (file.isUploaded) {
      uploadedFiles.push(file)
    } else {
      localFiles.push(file)
    }
  })

  return (
    <StyledContainer>
      <Container
        fluid='normal'
        paddingOnContainer={isLg}
        paddingOnColumns={isLg}
      >
        {!audit.isReadonly && (
          <Row className='sdds-u-mt2 sdds-u-mb2'>
            <Column width={6}>
              <UploadFileButton size='sm' onUploadFiles={onUploadFiles} />
            </Column>
            <Column width={6}>
              {localFiles.length > 0 && (
                <ul>
                  {localFiles.map(file => (
                    <li key={file.id}>
                      {file.fileName}
                      <progress max={1} value={file.uploadProgress}>
                        {Math.round(file.uploadProgress * 100)}%
                      </progress>
                    </li>
                  ))}
                </ul>
              )}
            </Column>
          </Row>
        )}

        <Divider type='light' className='sdds-u-mb1' />

        <Row>
          <Column width={12} lg={6} padding={isLg}>
            {auditPoint.isGettingFiles ? (
              <Spinner />
            ) : (
              <AttachmentList
                hideUploadPrompt={audit.isReadonly}
                color='on-grey'
                files={uploadedFiles}
                onDeleteAttachment={onDeleteUploadedFile}
                fileType='images'
                disableDelete={audit.isReadonly}
                title={t('images', { ns: 'common' })}
              />
            )}
          </Column>
          <ColumnOtherDocuments width={12} lg={6} padding={isLg}>
            {auditPoint.isGettingFiles ? (
              <Spinner />
            ) : (
              <AttachmentList
                hideUploadPrompt={audit.isReadonly}
                color='on-grey'
                files={uploadedFiles}
                onDeleteAttachment={onDeleteUploadedFile}
                disableDelete={audit.isReadonly}
                fileType='other'
                title={t('other', { ns: 'common' })}
              />
            )}
          </ColumnOtherDocuments>
        </Row>
      </Container>
    </StyledContainer>
  )
})

const ColumnOtherDocuments = styled(Column)`
  margin-top: var(--sdds-spacing-layout-16);

  @media (min-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 0;
  }
`

const StyledContainer = styled.div`
  height: 100%;
  padding-bottom: var(--sdds-spacing-element-40);
`
