import { Button, Column } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const StyledColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: var(--sdds-spacing-layout-32);
  padding-top: 0;
`

export const StyledButton = styled(Button)`
  margin-top: var(--sdds-spacing-layout-48);
`
