import type { StoreName } from './constants'
import {
  AUDITS_OBJECT_STORE_NAME,
  DEALERS_OBJECT_STORE_NAME,
  OPERATIONS_OBJECT_STORE_NAME,
  FAVORITE_AUDITS_OBJECT_STORE_NAME,
  FAVORITE_AUDITS_CHANGES_OBJECT_STORE_NAME
} from './constants'
import { createTransaction } from './transactions'

async function getObjectStore(name: StoreName, mode: IDBTransactionMode) {
  const tx = await createTransaction([name], mode)

  return tx.objectStore(name)
}

export async function getAuditsStore(
  mode: IDBTransactionMode
): Promise<IDBObjectStore> {
  return await getObjectStore(AUDITS_OBJECT_STORE_NAME, mode)
}

export async function getOperationsStore(
  mode: IDBTransactionMode
): Promise<IDBObjectStore> {
  return await getObjectStore(OPERATIONS_OBJECT_STORE_NAME, mode)
}

export async function getDealersStore(
  mode: IDBTransactionMode
): Promise<IDBObjectStore> {
  const tx = await createTransaction([DEALERS_OBJECT_STORE_NAME], mode)

  return tx.objectStore(DEALERS_OBJECT_STORE_NAME)
}

export async function getFavoriteAuditsStore(
  mode: IDBTransactionMode,
  useChangesStore = false
): Promise<IDBObjectStore> {
  let StoreName: StoreName

  if (useChangesStore) {
    StoreName = FAVORITE_AUDITS_CHANGES_OBJECT_STORE_NAME
  } else {
    StoreName = FAVORITE_AUDITS_OBJECT_STORE_NAME
  }

  const tx = await createTransaction([StoreName], mode)

  return tx.objectStore(StoreName)
}
