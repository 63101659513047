import { v4 as uuidv4 } from 'uuid'
import { action } from 'mobx'
import invariant from 'tiny-invariant'

import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { FileData } from '@cdab/scania/qpr/schema'

import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import { deletePledgeFile, addPledgeFiles } from '../store/pledges'
import type { PledgeModel, AuditModel } from '../audit-model'

type Type = 'delete-pledge-file'

type Data = {
  pledgeId: PledgeModel['id']
  fileId: FileData['id']
}

export type DeletePledgeFileOperationData = OperationDataBase<Type, Data>

export class DeletePledgeFileOperation extends Operation {
  public data: DeletePledgeFileOperationData
  private deletedFile: FileData | undefined = undefined

  constructor(auditId: AuditModel['id'], value: Data, guid = uuidv4()) {
    super(auditId, guid)

    this.data = {
      type: 'delete-pledge-file',
      value
    }
  }

  public ApplyTo = action((audit: AuditModel): void => {
    const { pledgeId, fileId } = this.data.value
    const deletedFile = deletePledgeFile(audit, pledgeId, fileId)

    this.deletedFile = deletedFile
  })

  public GetName = (): string => {
    return `${this.data.type}-${this.auditId}-${this.data.value.pledgeId}-${this.data.value.fileId}`
  }

  public SendOperation = async (client: BackEnd): Promise<boolean> => {
    await client.StorageService.DeleteFileForPledge(
      this.auditId,
      this.data.value.pledgeId,
      this.data.value.fileId
    )

    return true
  }
  public RollbackOn(audit: AuditModel): void {
    invariant(
      this.deletedFile,
      `Cannot rollback operation without having applied it first`
    )
    invariant(
      this.deletedFile.id === this.data.value.fileId,
      `The saved deleted file was not the same as data.value.fileId!`
    )

    addPledgeFiles(audit.id, this.data.value.pledgeId, [this.deletedFile])
  }
}
