import cx from 'classnames'
import type { DividerProps } from './divider.types'

/**
 * A divider is a 1px line that help us group and separate content within our layouts, components and patterns.
 *
 * [Documentation](https://digitaldesign.scania.com/components/dividers)
 */
export function Divider({
  className,
  type = 'light',
  vertical = false
}: DividerProps): JSX.Element {
  const classNames = cx(className, {
    [`sdds-divider-${type}`]: !vertical,
    [`sdds-divider-${type}-${vertical}`]: vertical
  })

  return <div className={classNames} />
}
