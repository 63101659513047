const dateRegex = /^(\d{4})-(\d{2})-(\d{2})/

/**
 * Parse a date string, ignore time
 * @param str - string with date
 * @returns Date
 */
export function parseDate(str: string) {
  const match = str.match(dateRegex)

  if (!match)
    throw new Error(`Error parsing date. '${str}' is not a valid date`)
  const [y, m, d] = [match[1], match[2], match[3]]

  return new Date(parseInt(y), parseInt(m) - 1, parseInt(d))
}

/**
 * Test if two dates are the same day
 * @param a
 * @param b
 * returns true if same date
 */
export function isSameDate(a: Date, b: Date) {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  )
}

const df = new Intl.DateTimeFormat(navigator.languages.slice())
export function formatDate(date: Date): string {
  return df.format(date)
}

export function formatISODate(d: Date): string {
  const year = d.getFullYear()
  const month = (d.getMonth() + 1).toString().padStart(2, '0')
  const date = d.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${date}`
}

export function isISODate(d: string): boolean {
  return !!d.match(dateRegex)
}
