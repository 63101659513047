import {
  FAVORITE_AUDITS_OBJECT_STORE_NAME,
  FAVORITE_AUDITS_CHANGES_OBJECT_STORE_NAME
} from '../constants'

export function upgradeToV3(db: IDBDatabase) {
  db.createObjectStore(FAVORITE_AUDITS_OBJECT_STORE_NAME, {
    keyPath: 'auditId'
  })

  db.createObjectStore(FAVORITE_AUDITS_CHANGES_OBJECT_STORE_NAME, {
    keyPath: 'auditId'
  })
}
