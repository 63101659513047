import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { UpdateUserData } from '@cdab/scania/qpr/interactor'
import type { User } from '@cdab/scania/qpr/schema'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type UpdateUserState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

export function useSendUpdateUser() {
  const client = useBackend()

  const updateUser = async (
    userId: User['userId'],
    data: UpdateUserData,
    keycloakUserName: string | undefined
  ): Promise<string> => {
    try {
      const response = await client.UserService.UpdateUser(
        userId,
        data,
        keycloakUserName
      )

      return response
    } catch (e) {
      return 'error'
    }
  }

  return {
    updateUser
  }
}

export function useUpdateUser(
  userId: User['userId'],
  data: UpdateUserData,
  keycloakUserName: string | undefined
) {
  const { t } = useTranslation('user')
  const { updateUser } = useSendUpdateUser()
  const [submitState, setSubmitState] = useState<UpdateUserState>({
    status: 'none',
    message: null
  })

  const handleClickUpdateUser = useCallback(async () => {
    try {
      const response = await updateUser(userId, data, keycloakUserName)
      setSubmitState({ status: 'none', message: null })

      switch (response) {
        case 'success': {
          setSubmitState({
            status: 'success',
            message: t('message.success')
          })
          return true
        }
        case 'User exists with same username': {
          setSubmitState({
            status: 'error',
            message: t('message.same-user')
          })
          return false
        }
        case 'User exists with same email': {
          setSubmitState({
            status: 'error',
            message: t('message.same-email')
          })
          return false
        }
        default: {
          setSubmitState({
            status: 'error',
            message: response
          })
          return false
        }
      }
    } catch (e) {
      setSubmitState({ status: 'error', message: t('message.error') })
      return false
    }
  }, [updateUser, userId, data, keycloakUserName, t])
  return [submitState, handleClickUpdateUser, setSubmitState] as const
}
