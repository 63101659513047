import cx from 'classnames'
import styled from 'styled-components'
import type {
  ItemStyleProps,
  RadioButtonStyleProps
} from './radio-button.styles.types'

export const RadioButtonItem = styled.div.attrs<ItemStyleProps>(
  ({ className }) => ({
    className: cx(className, 'sdds-radio-item')
  })
)<ItemStyleProps>``

export const StyledRadioButton = styled.input.attrs({
  className: 'sdds-form-input',
  type: 'radio'
})``

export const Label = styled.label.attrs({
  className: 'sdds-form-label'
})``

export const StyledRadioButtonGroup = styled.fieldset.attrs<RadioButtonStyleProps>(
  {
    className: 'sdds-radio-button-group',
    role: 'radiogroup'
  }
)<RadioButtonStyleProps>`
  margin: 0;
  padding: 0;
  border: 0;

  display: flex;

  flex-direction: ${({ direction }) =>
    direction === 'horizontal' ? 'row' : 'column'};

  ${RadioButtonItem} {
    margin-right: 8rem;
  }
`
