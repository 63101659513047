import styled from 'styled-components'

/**
 * The styling is copied from SDDS source code. It is assumed that it should always be an `a` tag. We need to style it to look like the same without the `a` tag.
 *
 * https://github.com/scania-digital-design-system/sdds/blob/fc86bb958afcd37b5c12b380f029f5a54f180d97/tegel/src/components/popover-menu/popover-menu-item.scss#L21-L57
 */
export const StyledPopoverMenuItem = styled.li`
  text-decoration: none;
  position: relative;
  width: 100%;
  color: var(--sdds-grey-958);
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;

  &:hover {
    &::before {
      z-index: -1;
      content: ' ';
      display: block;
      position: absolute;
      width: calc(100% + 32px);
      height: calc(100% + 16px);
      top: -8px;
      left: -16px;
      background-color: var(--sdds-grey-100);
    }
  }
`
