import type { AuditGridSettings } from './audit-grid-settings'

export const PLEDGE_GRID_ITEM_ID_START = 'pledge-'
export const PLEDGE_AREA_GRID_ITEM_ID_START = 'pledge-area-'
export const AUDIT_POINT_GRID_ITEM_ID_START = 'audit-point-'
export const CHECKPOINT_GRID_ITEM_ID_START = 'checkpoint-'

export const DEFAULT_AUDIT_GRID_VIEW_SETTING: AuditGridSettings = {
  pledgeAreas: {
    gridItemType: 'header'
  },
  pledges: {
    gridItemType: 'collapsible'
  },
  auditPoints: {
    gridItemType: 'header',
    showDetails: true
  },
  checkPoints: {
    gridItemType: 'point',
    showDetails: true
  },
  useDetailsSplitScreen: true
}
