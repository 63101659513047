export {
  ErrorBoundary as AdminDocumentsErrorBoundary,
  Page as AdminDocumentsPage,
  loader as adminDocumentsLoader
} from './documents'
export {
  ErrorBoundary as AdminEditDocumentErrorBoundary,
  Page as AdminEditDocumentPage,
  loader as adminEditDocumentLoader
} from './edit'
export {
  ErrorBoundary as AdminNewDocumentErrorBoundary,
  Page as AdminNewDocumentPage,
  loader as adminNewDocumentLoader
} from './new'
