import { ClickableList, ClickableListItem } from '@cdab/scania/qpr/components'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import {
  Accordion,
  AccordionItem,
  Badges,
  Block,
  Column
} from '@cdab/scania/sdds'
import { removeHtmlTagsFromString } from '@cdab/utils'
import { useLocation, useNavigate, useRouteLoaderData } from 'react-router'
import styled from 'styled-components'
import type { DashboardLoaderData } from './dashboard'

export const StyledColumn = styled(Column)`
  padding-top: var(--sdds-spacing-element-16);
  padding-bottom: var(--sdds-spacing-element-16);
`

export function DeviationsFeedback() {
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { adhAudits } = useRouteLoaderData('dashboard') as DashboardLoaderData

  function onClickDeviation(
    auditId: number,
    auditPointId: number,
    deviationId: number
  ) {
    navigate(`/audit/${auditId}/points/auditpoint/${auditPointId}/deviations`, {
      state: {
        deviationId: deviationId.toString(),
        previousPath: pathname
      }
    })
  }

  return (
    <StyledColumn
      width={12}
      lg={6}
      offset={{ md: 3 }}
      fullHeight
      padding={isLg}
    >
      {adhAudits.map(audit => (
        <Block color='on-grey' disablePadding>
          <Accordion paddingReset>
            <AccordionItem header={audit.dealerInfo}>
              <ClickableList>
                {audit.deviations.map(deviation => (
                  <ClickableListItem
                    title={`${
                      deviation.auditPointNo
                    } ${removeHtmlTagsFromString(
                      deviation.auditPointDescription
                    )}`}
                    description={deviation.deviation}
                    rightItem={
                      <Badges value={deviation.countOfMessages.toString()} />
                    }
                    onClick={() =>
                      onClickDeviation(
                        audit.auditId,
                        deviation.auditPointId,
                        deviation.actionPlanItemId
                      )
                    }
                  />
                ))}
              </ClickableList>
            </AccordionItem>
          </Accordion>
        </Block>
      ))}
    </StyledColumn>
  )
}
