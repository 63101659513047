import styled from 'styled-components'
import { Divider } from '@cdab/scania/sdds'

export const StyledDivider = styled(Divider)`
  @media (min-width: ${({ theme }) => theme.size.lg}) {
    display: none;
  }
`

export const CheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
  padding-bottom: 4rem;

  > * {
    margin-right: 4rem;
  }
`

export const Title = styled.h6.attrs({
  className: 'sdds-headline-06'
})`
  margin-bottom: 0;
  margin-top: 4rem;
`
