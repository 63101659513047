import cx from 'classnames'
import { Header, StyledBlock, StyledDivider } from './block.styles'
import type { BlockProps } from './block.types'

export function Block({
  children,
  className,
  color,
  description,
  divider = false,
  header,
  disablePadding
}: Readonly<BlockProps>) {
  const classNames = cx('sdds-block', className, {
    'sdds-block__ongrey': color === 'on-grey',
    'sdds-block__onwhite': color === 'on-white',
    'sdds-block__ongrey958': color === 'on-grey-958',
    'sdds-block__ongrey900': color === 'on-grey-900'
  })

  return (
    <StyledBlock className={classNames} disablePadding={disablePadding}>
      {header && <Header>{header}</Header>}
      {description && <span>{description}</span>}
      {(header || description) && divider && (
        <StyledDivider type='light' className='sdds-u-mb3' />
      )}
      {children}
    </StyledBlock>
  )
}
