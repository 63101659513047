import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import { downloadFileFromArrayBuffer } from '@cdab/utils'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type GetEmptyDOSReportState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'loading'
      message: null
    }
  | {
      status: 'none'
      message: null
    }

export function useGetEmptyDOSReport(dosVersion: number) {
  const { t } = useTranslation('reports')
  const [submitState, setSubmitState] = useState<GetEmptyDOSReportState>({
    status: 'none',
    message: null
  })
  const client = useBackend()

  const handleClickGetEmptyDOSReport = useCallback(
    async (
      viewSummary: boolean,
      viewCheckpoints: boolean,
      viewPerspectives: boolean
    ) => {
      setSubmitState({
        status: 'loading',
        message: null
      })

      try {
        const response = await client.ReportsService.GetEmptyDOSReport(
          dosVersion,
          viewSummary,
          viewCheckpoints,
          viewPerspectives
        )
        const downloadFile = downloadFileFromArrayBuffer(
          response.data,
          response.headers['content-type'] ?? '',
          `EmptyDOSReport.docx`
        )

        if (response.status === 204 || !downloadFile) {
          setSubmitState({
            status: 'error',
            message: t('message.empty-report')
          })
          return
        }

        setSubmitState({
          status: 'success',
          message: t('message.success')
        })
      } catch (e) {
        setSubmitState({
          status: 'error',
          message: t('message.error')
        })
      }
    },
    [client.ReportsService, dosVersion, t]
  )
  return [submitState, handleClickGetEmptyDOSReport, setSubmitState] as const
}
