import { CircleText, StyledCircleProgress } from './circle-progress.styles'

import './circle-progress.css'

export interface CircleProgressProps {
  percentage: number
  label?: string | React.ReactNode
}

export function CircleProgress(props: CircleProgressProps) {
  const p = Math.floor(props.percentage * 100)

  return (
    <div>
      <StyledCircleProgress viewBox='0 0 36 36'>
        <path
          className='circle-path'
          d='M18 2a16 16 0 010 32 16 16 0 010-32'
          strokeDasharray={`${p}, 100`}
        ></path>
        <CircleText x='50%' y='50%'>
          {p}%
        </CircleText>
      </StyledCircleProgress>
      {props.label}
    </div>
  )
}
