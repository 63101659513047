import { useTranslation } from 'react-i18next'
import { isRouteErrorResponse, useRouteError } from 'react-router'
import { RootErrorBoundary } from '@cdab/scania/qpr/components'

export function LoggedInBoundary() {
  const { t } = useTranslation('errors')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <RootErrorBoundary title={t('page-doesnt-exist')} />
    }

    if (error.status === 401) {
      return <RootErrorBoundary title={t('page-not-authorized')} />
    }

    if (error.status === 403) {
      return <RootErrorBoundary title={t('page-not-permitted')} />
    }

    if (error.status === 503) {
      return <RootErrorBoundary title={t('page-api-down')} />
    }

    if (error.status === 418) {
      return (
        <RootErrorBoundary title='Fancy a cup of tea?'>
          <span role='img' aria-label="I'm a tea pot">
            🫖
          </span>
        </RootErrorBoundary>
      )
    }
  }

  throw error
}
