import type { BadgesProps } from './badges.types'
import styled, { css } from 'styled-components'

const StyledWrapper = styled.span<{ color?: string }>`
  display: inline;
  ${({ color }) =>
    color &&
    css`
      --sdds-badges-bg-color: var(${color});
    `};
`

export function Badges({ color, shape, size, value }: BadgesProps) {
  return (
    <StyledWrapper color={color}>
      <sdds-badges shape={shape} size={size} value={value} />
    </StyledWrapper>
  )
}
