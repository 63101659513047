import {
  EmptyState,
  SystemMessageModal,
  SystemMessagesWidget
} from '@cdab/scania/qpr/components'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import type { SystemMessage } from '@cdab/scania/qpr/schema'
import {
  Button,
  Column,
  Container,
  EmptyScreen,
  IconMessageInactive,
  Row
} from '@cdab/scania/sdds'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  isRouteErrorResponse,
  useFetcher,
  useRouteError,
  useRouteLoaderData
} from 'react-router-dom'
import styled from 'styled-components'
import type { DashboardLoaderData } from './dashboard'
import { StyledColumn } from './dashboard'

const UnreadButton = styled(Button)`
  margin: var(--sdds-spacing-element-4);

  @media (min-width: ${({ theme }) => theme.size.lg}) {
    margin: 0;
    margin-bottom: var(--sdds-spacing-element-4);
  }
`

export function SystemMessages() {
  const { allSystemMessages, unreadSystemMessages } = useRouteLoaderData(
    'dashboard'
  ) as DashboardLoaderData
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { t } = useTranslation('dashboard')
  const fetcher = useFetcher()
  const [showAllMessages, setShowAllMessages] = useState(false)
  const [SystemMessageState, setSystemMessageState] = useState<
    SystemMessage | undefined
  >(undefined)

  function onCloseSystemMessageModal() {
    setSystemMessageState(undefined)
  }

  const onClickSystemMessage = useCallback((systemMessage: SystemMessage) => {
    if (systemMessage) {
      setSystemMessageState(systemMessage)
    }
  }, [])

  const onClickReadMessage = useCallback(
    (messageId: SystemMessage['id']) => {
      if (messageId) {
        fetcher.submit(null, {
          method: 'put',
          action: `${messageId}/read`
        })
      }
    },
    [fetcher]
  )

  return unreadSystemMessages.length > 0 || showAllMessages ? (
    <StyledColumn width={12} lg={6} fullHeight padding={isLg}>
      <UnreadButton
        size='sm'
        type='secondary'
        text={t('system-messages.show-all-messages')}
        onClick={() => setShowAllMessages(true)}
      />
      <SystemMessagesWidget
        onClickItem={onClickSystemMessage}
        onClickDelete={onClickReadMessage}
        showMarkAsReadMessageButton={!showAllMessages}
        systemMessages={
          showAllMessages ? allSystemMessages : unreadSystemMessages
        }
      />
      {SystemMessageState && (
        <SystemMessageModal
          open={!!SystemMessageState}
          systemMessage={SystemMessageState}
          onClose={onCloseSystemMessageModal}
        />
      )}
    </StyledColumn>
  ) : (
    <EmptyScreen.CenteredWrapper>
      <EmptyScreen
        icon={<IconMessageInactive />}
        title={t('system-messages.empty-screen.title')}
        description={t('system-messages.empty-screen.description')}
        callToAction={{
          type: 'button',
          component: (
            <Button
              size='sm'
              onClick={() => setShowAllMessages(true)}
              text={t('system-messages.empty-screen.call-to-action')}
            />
          )
        }}
      />
    </EmptyScreen.CenteredWrapper>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation(['errors', 'common'])
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()
  console.log(error)
  if (isRouteErrorResponse(error)) {
    return (
      <Container
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column width={12} padding={isLg}>
            <EmptyState
              title={t('something-bad')}
              description={t('talk-to-someone')}
            />
          </Column>
        </Row>
      </Container>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
