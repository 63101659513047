import { useEditDealer } from '@cdab/scania/qpr/hooks'
import { dealersController } from '@cdab/scania/qpr/offline/controllers'
import type { Dealer } from '@cdab/scania/qpr/schema'

import { DealerModal } from './dealer-modal'

export type DealerEditModalProps = {
  open: boolean
  onClose: () => void
  dealer: Dealer
}

export function DealerEditModal({
  onClose,
  open,
  dealer
}: DealerEditModalProps) {
  const { updateDealer } = useEditDealer()

  const onSubmit = async (date: string) => {
    const response = await updateDealer(dealer, date)

    if (response === 'success') {
      dealersController.SetCachedExpiryDate(dealer.id, new Date(date))
      onClose()
      return true
    }

    return false
  }

  return (
    <DealerModal
      onClose={onClose}
      open={open}
      onSubmit={onSubmit}
      dealer={dealer}
    />
  )
}
