import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { CreateAuditData } from '@cdab/scania/qpr/interactor'
import { AuditCreateError } from '@cdab/scania/qpr/schema'
import * as sentry from '@sentry/react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

type CreateAuditState =
  | { state: 'idle' }
  | { state: 'loading' }
  | { state: 'success'; auditId: number }
  | { state: 'error'; error: string }

export function useCreateAudit() {
  const { t } = useTranslation('audit')
  const client = useBackend()
  const [state, setState] = useState<CreateAuditState>({ state: 'idle' })
  const create = useCallback(
    async (audit: CreateAuditData) => {
      setState({ state: 'loading' })
      try {
        const auditId = await client.AuditsService.CreateAudit(audit)
        setState({ state: 'success', auditId })

        switch (auditId) {
          case AuditCreateError.UserWithIdNotAllowedToCreateAudit: {
            setState({
              state: 'error',
              error: t('message.user-not-allowed-create')
            })
            return undefined
          }
          case AuditCreateError.NotPossibleCreateAuditforDos5: {
            setState({
              state: 'error',
              error: t('message.only-possible-for-Dos5')
            })
            return undefined
          }
          default: {
            return auditId
          }
        }
      } catch (e) {
        sentry.captureException(e)
        setState({
          state: 'error',
          error: (e as Record<string, unknown>).toString()
        })
        return undefined
      }
    },
    [client.AuditsService, t]
  )

  return [create, state] as const
}
