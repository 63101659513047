import type { CheckPoint } from '@cdab/scania/qpr/schema'

export const CheckPoints: CheckPoint[] = [
  {
    id: 1450,
    translationGuid: '3bc84e01-78ed-4fa9-af97-fd676999bfef',
    auditPointId: 743,
    checkPointNo: '1.1.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 1,
    cp3: 1,
    cp4: 0,
    sortOrder: 16843008,
    score: null
  },
  {
    id: 1451,
    translationGuid: 'e6c6a3e8-7ebd-4b6a-896c-1aaaae74f816',
    auditPointId: 743,
    checkPointNo: '1.1.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 1,
    cp3: 2,
    cp4: 0,
    sortOrder: 16843264,
    score: null
  },
  {
    id: 1452,
    translationGuid: 'bc85359e-fda4-478d-948b-419bdc94e510',
    auditPointId: 743,
    checkPointNo: '1.1.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 1,
    cp3: 3,
    cp4: 0,
    sortOrder: 16843520,
    score: null
  },
  {
    id: 1453,
    translationGuid: '01c7e459-9b90-4fa5-a702-590235ee98f5',
    auditPointId: 743,
    checkPointNo: '1.1.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 1,
    cp3: 4,
    cp4: 0,
    sortOrder: 16843776,
    score: null
  },
  {
    id: 1454,
    translationGuid: '5586c9c7-4f0d-45d6-8748-99471f6e37f8',
    auditPointId: 743,
    checkPointNo: '1.1.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 1,
    cp3: 5,
    cp4: 0,
    sortOrder: 16844032,
    score: null
  },
  {
    id: 1994,
    translationGuid: '83138e6f-ffe6-4019-87b7-2de48005fcb7',
    auditPointId: 743,
    checkPointNo: '1.1.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 1,
    cp3: 6,
    cp4: 0,
    sortOrder: 16844288,
    score: null
  },
  {
    id: 1455,
    translationGuid: '1c378ee9-b2fe-4a66-acaa-6bf414c6eb76',
    auditPointId: 744,
    checkPointNo: '1.2.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 2,
    cp3: 1,
    cp4: 0,
    sortOrder: 16908544,
    score: null
  },
  {
    id: 1456,
    translationGuid: '3fcf8f66-c680-4fbb-88d3-767bf0b4388f',
    auditPointId: 744,
    checkPointNo: '1.2.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 2,
    cp3: 2,
    cp4: 0,
    sortOrder: 16908800,
    score: null
  },
  {
    id: 1457,
    translationGuid: '35adc820-7888-460c-8cc7-5f1c275b9b63',
    auditPointId: 744,
    checkPointNo: '1.2.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 2,
    cp3: 3,
    cp4: 0,
    sortOrder: 16909056,
    score: null
  },
  {
    id: 1458,
    translationGuid: '6b3b4d36-b52d-4de8-818e-6c9765f2e581',
    auditPointId: 744,
    checkPointNo: '1.2.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 2,
    cp3: 4,
    cp4: 0,
    sortOrder: 16909312,
    score: null
  },
  {
    id: 1459,
    translationGuid: '8e745613-fbda-4c6e-bbcb-8d013b1fea2d',
    auditPointId: 744,
    checkPointNo: '1.2.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 2,
    cp3: 5,
    cp4: 0,
    sortOrder: 16909568,
    score: null
  },
  {
    id: 1460,
    translationGuid: 'c09839a9-f789-4bf1-b3b6-76a40951f11e',
    auditPointId: 745,
    checkPointNo: '1.3.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 1,
    cp4: 0,
    sortOrder: 16974080,
    score: null
  },
  {
    id: 1461,
    translationGuid: '07917d72-2e57-43cc-b939-04a499085241',
    auditPointId: 745,
    checkPointNo: '1.3.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 2,
    cp4: 0,
    sortOrder: 16974336,
    score: null
  },
  {
    id: 1462,
    translationGuid: '8b14e1cf-07d4-4d29-83af-87b6932c40e5',
    auditPointId: 745,
    checkPointNo: '1.3.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 3,
    cp4: 0,
    sortOrder: 16974592,
    score: null
  },
  {
    id: 1463,
    translationGuid: 'b3a1f5f8-e6fc-4e6e-8f12-7afe049b7fec',
    auditPointId: 745,
    checkPointNo: '1.3.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 4,
    cp4: 0,
    sortOrder: 16974848,
    score: null
  },
  {
    id: 1464,
    translationGuid: '30476ed8-6c0c-4f4d-8f56-2aa6ed06117e',
    auditPointId: 745,
    checkPointNo: '1.3.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 5,
    cp4: 0,
    sortOrder: 16975104,
    score: null
  },
  {
    id: 1465,
    translationGuid: 'fcbcd0a1-dd40-492d-b8fb-4c53f4a8c2da',
    auditPointId: 745,
    checkPointNo: '1.3.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 6,
    cp4: 0,
    sortOrder: 16975360,
    score: null
  },
  {
    id: 1466,
    translationGuid: '4d404c90-d349-4c04-b536-0985298812da',
    auditPointId: 745,
    checkPointNo: '1.3.7',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 7,
    cp4: 0,
    sortOrder: 16975616,
    score: null
  },
  {
    id: 1467,
    translationGuid: '4b7e1273-5342-4719-bfc7-3680cec82faa',
    auditPointId: 745,
    checkPointNo: '1.3.8',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 8,
    cp4: 0,
    sortOrder: 16975872,
    score: null
  },
  {
    id: 1468,
    translationGuid: '613440a1-c5ec-452b-a748-ca810e2864dc',
    auditPointId: 745,
    checkPointNo: '1.3.9',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 9,
    cp4: 0,
    sortOrder: 16976128,
    score: null
  },
  {
    id: 1469,
    translationGuid: '63fcefa6-e065-4731-aefb-4325ab97542a',
    auditPointId: 745,
    checkPointNo: '1.3.10',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 10,
    cp4: 0,
    sortOrder: 16976384,
    score: null
  },
  {
    id: 1470,
    translationGuid: '14ee07ab-b2f7-45ea-83a5-93cddd4c312f',
    auditPointId: 745,
    checkPointNo: '1.3.11',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 11,
    cp4: 0,
    sortOrder: 16976640,
    score: null
  },
  {
    id: 1471,
    translationGuid: 'a2809fb1-1c15-4084-9e7c-536d16af752a',
    auditPointId: 745,
    checkPointNo: '1.3.12',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 12,
    cp4: 0,
    sortOrder: 16976896,
    score: null
  },
  {
    id: 1472,
    translationGuid: '0126ce73-8af7-41a5-8b76-695c431f009c',
    auditPointId: 745,
    checkPointNo: '1.3.13',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 13,
    cp4: 0,
    sortOrder: 16977152,
    score: null
  },
  {
    id: 1473,
    translationGuid: 'fc66312a-2103-4f2c-82aa-e0116fe9ffb3',
    auditPointId: 745,
    checkPointNo: '1.3.14',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 14,
    cp4: 0,
    sortOrder: 16977408,
    score: null
  },
  {
    id: 1474,
    translationGuid: '7bd4d0d6-42fd-47ec-bec3-55de074be5ce',
    auditPointId: 745,
    checkPointNo: '1.3.15',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 15,
    cp4: 0,
    sortOrder: 16977664,
    score: null
  },
  {
    id: 1475,
    translationGuid: 'fcb31fe3-084f-4265-bdf4-52b4db661a55',
    auditPointId: 745,
    checkPointNo: '1.3.16',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 16,
    cp4: 0,
    sortOrder: 16977920,
    score: null
  },
  {
    id: 1476,
    translationGuid: '61b3b7a0-7095-4e36-8983-bbd592ea094d',
    auditPointId: 745,
    checkPointNo: '1.3.17',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 17,
    cp4: 0,
    sortOrder: 16978176,
    score: null
  },
  {
    id: 1477,
    translationGuid: '6f9ab689-b6a1-4c03-8cf5-4d93389b1b9b',
    auditPointId: 745,
    checkPointNo: '1.3.18',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 18,
    cp4: 0,
    sortOrder: 16978432,
    score: null
  },
  {
    id: 1478,
    translationGuid: 'c36f5543-5976-4044-936e-8a33b8f490ff',
    auditPointId: 745,
    checkPointNo: '1.3.19',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 19,
    cp4: 0,
    sortOrder: 16978688,
    score: null
  },
  {
    id: 1479,
    translationGuid: 'ea773764-91de-4356-857d-f1f56745c4dc',
    auditPointId: 745,
    checkPointNo: '1.3.20',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 20,
    cp4: 0,
    sortOrder: 16978944,
    score: null
  },
  {
    id: 1480,
    translationGuid: '368c9b56-fb4b-429a-8e9e-5f1b7b9e6871',
    auditPointId: 745,
    checkPointNo: '1.3.21',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 3,
    cp3: 21,
    cp4: 0,
    sortOrder: 16979200,
    score: null
  },
  {
    id: 1484,
    translationGuid: '803336a5-2b90-47d7-b5a9-c46b23ed7671',
    auditPointId: 746,
    checkPointNo: '1.4.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 4,
    cp3: 1,
    cp4: 0,
    sortOrder: 17039616,
    score: null
  },
  {
    id: 1485,
    translationGuid: '1685b026-a6d3-4a49-b24f-c873e619153c',
    auditPointId: 746,
    checkPointNo: '1.4.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 4,
    cp3: 2,
    cp4: 0,
    sortOrder: 17039872,
    score: null
  },
  {
    id: 1486,
    translationGuid: 'db38c4e9-f802-4949-a97a-97e833cc8ca8',
    auditPointId: 747,
    checkPointNo: '1.5.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 5,
    cp3: 1,
    cp4: 0,
    sortOrder: 17105152,
    score: null
  },
  {
    id: 1487,
    translationGuid: '81809626-6d7f-4b3c-bf63-fabe395b7562',
    auditPointId: 747,
    checkPointNo: '1.5.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 5,
    cp3: 2,
    cp4: 0,
    sortOrder: 17105408,
    score: null
  },
  {
    id: 1488,
    translationGuid: '04545ccb-3649-4844-90ea-904342d60cbb',
    auditPointId: 747,
    checkPointNo: '1.5.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 5,
    cp3: 3,
    cp4: 0,
    sortOrder: 17105664,
    score: null
  },
  {
    id: 1489,
    translationGuid: '0de19382-07bd-4bd8-8be2-6850219dbac6',
    auditPointId: 747,
    checkPointNo: '1.5.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 5,
    cp3: 4,
    cp4: 0,
    sortOrder: 17105920,
    score: null
  },
  {
    id: 1490,
    translationGuid: '53771e80-49be-43b6-8ae0-f953a1922548',
    auditPointId: 748,
    checkPointNo: '1.6.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 6,
    cp3: 1,
    cp4: 0,
    sortOrder: 17170688,
    score: null
  },
  {
    id: 1491,
    translationGuid: '8f708da2-761c-42d5-bcdc-5e98888f2765',
    auditPointId: 748,
    checkPointNo: '1.6.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 6,
    cp3: 2,
    cp4: 0,
    sortOrder: 17170944,
    score: null
  },
  {
    id: 1492,
    translationGuid: '11399f9b-b8f8-46b3-85bb-420b5e86f922',
    auditPointId: 749,
    checkPointNo: '1.7.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 7,
    cp3: 1,
    cp4: 0,
    sortOrder: 17236224,
    score: null
  },
  {
    id: 1493,
    translationGuid: '262fb782-6a99-4506-b4f3-020d01284159',
    auditPointId: 750,
    checkPointNo: '1.8.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 8,
    cp3: 1,
    cp4: 0,
    sortOrder: 17301760,
    score: null
  },
  {
    id: 1494,
    translationGuid: 'e2a9e82a-a9d7-4d09-a4e4-a24e72faace3',
    auditPointId: 750,
    checkPointNo: '1.8.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 8,
    cp3: 2,
    cp4: 0,
    sortOrder: 17302016,
    score: null
  },
  {
    id: 1495,
    translationGuid: '61ef6964-623e-43c4-b839-d025069ff5bc',
    auditPointId: 751,
    checkPointNo: '1.9.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 9,
    cp3: 1,
    cp4: 0,
    sortOrder: 17367296,
    score: null
  },
  {
    id: 1496,
    translationGuid: '90b7de8f-f552-4973-b2a8-fdaa5f1f8416',
    auditPointId: 751,
    checkPointNo: '1.9.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 9,
    cp3: 2,
    cp4: 0,
    sortOrder: 17367552,
    score: null
  },
  {
    id: 1497,
    translationGuid: '627b01b8-2882-4417-92f4-94004847e625',
    auditPointId: 751,
    checkPointNo: '1.9.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 9,
    cp3: 3,
    cp4: 0,
    sortOrder: 17367808,
    score: null
  },
  {
    id: 1498,
    translationGuid: '3222832b-12e8-44e5-bac3-bac302043720',
    auditPointId: 751,
    checkPointNo: '1.9.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 9,
    cp3: 4,
    cp4: 0,
    sortOrder: 17368064,
    score: null
  },
  {
    id: 1499,
    translationGuid: '203a3a5b-e6f7-461a-a0c2-1a2a078c719c',
    auditPointId: 752,
    checkPointNo: '1.10.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 10,
    cp3: 1,
    cp4: 0,
    sortOrder: 17432832,
    score: null
  },
  {
    id: 1500,
    translationGuid: 'acecd031-24ff-4797-b297-a174c1bb8878',
    auditPointId: 752,
    checkPointNo: '1.10.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 10,
    cp3: 2,
    cp4: 0,
    sortOrder: 17433088,
    score: null
  },
  {
    id: 1501,
    translationGuid: '32c60b6b-b33c-43b7-bb24-0577a8f9753c',
    auditPointId: 752,
    checkPointNo: '1.10.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 10,
    cp3: 3,
    cp4: 0,
    sortOrder: 17433344,
    score: null
  },
  {
    id: 1502,
    translationGuid: '2c762bf6-b3bf-4b20-adba-4ddc69141ace',
    auditPointId: 753,
    checkPointNo: '1.11.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 11,
    cp3: 1,
    cp4: 0,
    sortOrder: 17498368,
    score: null
  },
  {
    id: 1503,
    translationGuid: 'f3d48b7a-fcc6-4939-b891-13197548bf67',
    auditPointId: 754,
    checkPointNo: '1.12.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 12,
    cp3: 1,
    cp4: 0,
    sortOrder: 17563904,
    score: null
  },
  {
    id: 1504,
    translationGuid: 'ceb4cd45-de2b-4ff8-869a-353118d143c5',
    auditPointId: 754,
    checkPointNo: '1.12.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 12,
    cp3: 2,
    cp4: 0,
    sortOrder: 17564160,
    score: null
  },
  {
    id: 1505,
    translationGuid: '0f2d11dd-b8e5-4e05-af64-4fa067083fc5',
    auditPointId: 754,
    checkPointNo: '1.12.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 12,
    cp3: 3,
    cp4: 0,
    sortOrder: 17564416,
    score: null
  },
  {
    id: 1506,
    translationGuid: '2f980f61-fd7e-4671-8ce5-e8b47fc667fa',
    auditPointId: 755,
    checkPointNo: '1.13.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 13,
    cp3: 1,
    cp4: 0,
    sortOrder: 17629440,
    score: null
  },
  {
    id: 1507,
    translationGuid: 'b6d7647e-dbf2-4fb4-9845-abb008a1d1ce',
    auditPointId: 755,
    checkPointNo: '1.13.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 13,
    cp3: 2,
    cp4: 0,
    sortOrder: 17629696,
    score: null
  },
  {
    id: 1508,
    translationGuid: '4329e289-9be9-4e47-b41b-06aa6f4eb9af',
    auditPointId: 755,
    checkPointNo: '1.13.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 13,
    cp3: 3,
    cp4: 0,
    sortOrder: 17629952,
    score: null
  },
  {
    id: 1509,
    translationGuid: 'ae71fb03-e83e-4c8f-828b-0bd04380254d',
    auditPointId: 756,
    checkPointNo: '1.14.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 14,
    cp3: 1,
    cp4: 0,
    sortOrder: 17694976,
    score: null
  },
  {
    id: 1510,
    translationGuid: 'db022eab-14b1-4940-a415-e2d8649f2d18',
    auditPointId: 756,
    checkPointNo: '1.14.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 14,
    cp3: 2,
    cp4: 0,
    sortOrder: 17695232,
    score: null
  },
  {
    id: 1511,
    translationGuid: 'e43214a6-603c-418f-8fe5-163d5f5f0c58',
    auditPointId: 756,
    checkPointNo: '1.14.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 14,
    cp3: 3,
    cp4: 0,
    sortOrder: 17695488,
    score: null
  },
  {
    id: 1512,
    translationGuid: '796730d2-c145-47c2-a595-831ab79cbb19',
    auditPointId: 756,
    checkPointNo: '1.14.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 14,
    cp3: 4,
    cp4: 0,
    sortOrder: 17695744,
    score: null
  },
  {
    id: 1513,
    translationGuid: '5236d77f-83a4-4af0-8e59-67f563c6d5dd',
    auditPointId: 756,
    checkPointNo: '1.14.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 14,
    cp3: 5,
    cp4: 0,
    sortOrder: 17696000,
    score: null
  },
  {
    id: 1514,
    translationGuid: '55811eae-a3f8-4536-9bce-802a8a715154',
    auditPointId: 756,
    checkPointNo: '1.14.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 14,
    cp3: 6,
    cp4: 0,
    sortOrder: 17696256,
    score: null
  },
  {
    id: 1515,
    translationGuid: 'bc19dbde-3fa9-4905-a397-5f990851d912',
    auditPointId: 757,
    checkPointNo: '1.15.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 15,
    cp3: 1,
    cp4: 0,
    sortOrder: 17760512,
    score: null
  },
  {
    id: 1481,
    translationGuid: '96a57fc5-3148-4b0f-b7ae-4393527c64c0',
    auditPointId: 758,
    checkPointNo: '1.16.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 16,
    cp3: 1,
    cp4: 0,
    sortOrder: 17826048,
    score: null
  },
  {
    id: 1482,
    translationGuid: 'a7827331-9306-44e7-aa9b-334a990e0145',
    auditPointId: 759,
    checkPointNo: '1.17.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 17,
    cp3: 1,
    cp4: 0,
    sortOrder: 17891584,
    score: null
  },
  {
    id: 1483,
    translationGuid: '9063f8e7-9566-46a4-92f6-45e32b578191',
    auditPointId: 759,
    checkPointNo: '1.17.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 17,
    cp3: 2,
    cp4: 0,
    sortOrder: 17891840,
    score: null
  },
  {
    id: 1516,
    translationGuid: '918b23f0-8334-4b66-b5f8-8539d77b8f88',
    auditPointId: 759,
    checkPointNo: '1.17.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 17,
    cp3: 3,
    cp4: 0,
    sortOrder: 17892096,
    score: null
  },
  {
    id: 1517,
    translationGuid: '49cabaa7-75d7-48f5-847c-edb33cb4ed66',
    auditPointId: 760,
    checkPointNo: '1.18.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 18,
    cp3: 1,
    cp4: 0,
    sortOrder: 17957120,
    score: null
  },
  {
    id: 1518,
    translationGuid: '0eab9e81-112a-4de7-ba1c-40d285437665',
    auditPointId: 760,
    checkPointNo: '1.18.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 18,
    cp3: 2,
    cp4: 0,
    sortOrder: 17957376,
    score: null
  },
  {
    id: 1519,
    translationGuid: '62a6c346-3ff8-46c6-b215-2de75a5a150c',
    auditPointId: 760,
    checkPointNo: '1.18.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 18,
    cp3: 3,
    cp4: 0,
    sortOrder: 17957632,
    score: null
  },
  {
    id: 1520,
    translationGuid: 'b94c5ae9-2e34-4e19-8b56-27699dd37b9b',
    auditPointId: 761,
    checkPointNo: '1.19.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 19,
    cp3: 1,
    cp4: 0,
    sortOrder: 18022656,
    score: null
  },
  {
    id: 1521,
    translationGuid: '06dfcaa3-f0e5-4c4a-8513-38220f0e47a0',
    auditPointId: 761,
    checkPointNo: '1.19.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 19,
    cp3: 2,
    cp4: 0,
    sortOrder: 18022912,
    score: null
  },
  {
    id: 1522,
    translationGuid: '1e984074-ce7a-4924-b040-011689400328',
    auditPointId: 762,
    checkPointNo: '1.20.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 20,
    cp3: 1,
    cp4: 0,
    sortOrder: 18088192,
    score: null
  },
  {
    id: 1523,
    translationGuid: 'e4c50147-d69f-4539-9f7f-6a236ef9c5f0',
    auditPointId: 762,
    checkPointNo: '1.20.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 20,
    cp3: 2,
    cp4: 0,
    sortOrder: 18088448,
    score: null
  },
  {
    id: 1524,
    translationGuid: 'fa59a4e9-1201-4485-91a6-7db23ce8b78b',
    auditPointId: 762,
    checkPointNo: '1.20.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 20,
    cp3: 3,
    cp4: 0,
    sortOrder: 18088704,
    score: null
  },
  {
    id: 1525,
    translationGuid: '49919b5e-0f95-409e-9789-1430e70007fd',
    auditPointId: 763,
    checkPointNo: '1.21.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 21,
    cp3: 1,
    cp4: 0,
    sortOrder: 18153728,
    score: null
  },
  {
    id: 1526,
    translationGuid: 'fed2a43b-4358-4ae4-8ab7-f95a22e4012c',
    auditPointId: 763,
    checkPointNo: '1.21.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 21,
    cp3: 2,
    cp4: 0,
    sortOrder: 18153984,
    score: null
  },
  {
    id: 1528,
    translationGuid: 'f12abe5a-b958-4e6c-95e2-c3e00456f826',
    auditPointId: 764,
    checkPointNo: '1.22.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 22,
    cp3: 1,
    cp4: 0,
    sortOrder: 18219264,
    score: null
  },
  {
    id: 1529,
    translationGuid: 'a54cec04-5c33-4851-8d28-68e5e3e36141',
    auditPointId: 764,
    checkPointNo: '1.22.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 22,
    cp3: 2,
    cp4: 0,
    sortOrder: 18219520,
    score: null
  },
  {
    id: 1527,
    translationGuid: 'c6d46eb0-94f2-4cb7-836d-58db37208786',
    auditPointId: 764,
    checkPointNo: '1.22.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 1,
    cp2: 22,
    cp3: 3,
    cp4: 0,
    sortOrder: 18219776,
    score: null
  },
  {
    id: 1530,
    translationGuid: '08773d90-4fe4-4ed3-a981-ece9f729a155',
    auditPointId: 765,
    checkPointNo: '2.1.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 1,
    cp3: 1,
    cp4: 0,
    sortOrder: 33620224,
    score: null
  },
  {
    id: 1531,
    translationGuid: '39160007-e01f-45a7-ba74-ae1a363a2145',
    auditPointId: 765,
    checkPointNo: '2.1.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 1,
    cp3: 2,
    cp4: 0,
    sortOrder: 33620480,
    score: null
  },
  {
    id: 1532,
    translationGuid: '3e6f8f99-b588-4f6f-aa74-f16f8d07475f',
    auditPointId: 765,
    checkPointNo: '2.1.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 1,
    cp3: 3,
    cp4: 0,
    sortOrder: 33620736,
    score: null
  },
  {
    id: 1533,
    translationGuid: 'd7f274a8-2b7e-4280-a334-82e32ccd53f8',
    auditPointId: 765,
    checkPointNo: '2.1.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 1,
    cp3: 4,
    cp4: 0,
    sortOrder: 33620992,
    score: null
  },
  {
    id: 1534,
    translationGuid: 'e4d50e55-c027-43b5-a5ad-02547d9618e2',
    auditPointId: 766,
    checkPointNo: '2.2.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 2,
    cp3: 1,
    cp4: 0,
    sortOrder: 33685760,
    score: null
  },
  {
    id: 1535,
    translationGuid: 'a2b52162-ffcc-41f6-b114-5c4ca01bc639',
    auditPointId: 766,
    checkPointNo: '2.2.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 2,
    cp3: 2,
    cp4: 0,
    sortOrder: 33686016,
    score: null
  },
  {
    id: 1536,
    translationGuid: 'e58b6f7d-47f0-49f2-b422-9e570bc4a5e6',
    auditPointId: 766,
    checkPointNo: '2.2.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 2,
    cp3: 3,
    cp4: 0,
    sortOrder: 33686272,
    score: null
  },
  {
    id: 1537,
    translationGuid: 'e1ef47da-dbf7-43c2-9316-5d1af5eebc47',
    auditPointId: 766,
    checkPointNo: '2.2.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 2,
    cp3: 4,
    cp4: 0,
    sortOrder: 33686528,
    score: null
  },
  {
    id: 1538,
    translationGuid: '8ba06097-91bd-4e1b-8053-a8d534657efa',
    auditPointId: 767,
    checkPointNo: '2.3.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 3,
    cp3: 1,
    cp4: 0,
    sortOrder: 33751296,
    score: null
  },
  {
    id: 1539,
    translationGuid: '0d772eaa-1629-4626-b519-18ff1dc1ff14',
    auditPointId: 767,
    checkPointNo: '2.3.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 3,
    cp3: 2,
    cp4: 0,
    sortOrder: 33751552,
    score: null
  },
  {
    id: 1540,
    translationGuid: '58e231ba-b882-46b2-85d0-1a0820a573a9',
    auditPointId: 768,
    checkPointNo: '2.4.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 4,
    cp3: 1,
    cp4: 0,
    sortOrder: 33816832,
    score: null
  },
  {
    id: 1541,
    translationGuid: '22a781d7-454c-4068-9629-cea3e74878ce',
    auditPointId: 768,
    checkPointNo: '2.4.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 4,
    cp3: 2,
    cp4: 0,
    sortOrder: 33817088,
    score: null
  },
  {
    id: 1542,
    translationGuid: '60596ce5-4e73-4335-b965-002ea88ae132',
    auditPointId: 769,
    checkPointNo: '2.5.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 5,
    cp3: 1,
    cp4: 0,
    sortOrder: 33882368,
    score: null
  },
  {
    id: 1543,
    translationGuid: '28a72c9b-cdb5-41f0-ae21-1d84bbde55e8',
    auditPointId: 770,
    checkPointNo: '2.6.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 6,
    cp3: 1,
    cp4: 0,
    sortOrder: 33947904,
    score: null
  },
  {
    id: 1544,
    translationGuid: '9d972890-55b3-40d6-9091-daa3c4887289',
    auditPointId: 770,
    checkPointNo: '2.6.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 6,
    cp3: 2,
    cp4: 0,
    sortOrder: 33948160,
    score: null
  },
  {
    id: 1545,
    translationGuid: 'ebf8dd0b-30ae-4a8c-91c5-fc5532092072',
    auditPointId: 770,
    checkPointNo: '2.6.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 6,
    cp3: 3,
    cp4: 0,
    sortOrder: 33948416,
    score: null
  },
  {
    id: 1546,
    translationGuid: '963361d6-a44f-4e4a-a4f5-2f5e9fd7394b',
    auditPointId: 771,
    checkPointNo: '2.7.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 2,
    cp2: 7,
    cp3: 1,
    cp4: 0,
    sortOrder: 34013440,
    score: null
  },
  {
    id: 1547,
    translationGuid: 'ce6493c6-bd43-4b8c-b354-07b9c10b0cc0',
    auditPointId: 772,
    checkPointNo: '3.1.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 1,
    cp3: 1,
    cp4: 0,
    sortOrder: 50397440,
    score: null
  },
  {
    id: 1548,
    translationGuid: '052cae1d-d2fe-45db-900e-a5a6a21a5de2',
    auditPointId: 772,
    checkPointNo: '3.1.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 1,
    cp3: 2,
    cp4: 0,
    sortOrder: 50397696,
    score: null
  },
  {
    id: 1549,
    translationGuid: 'e65bb59c-7f40-48b6-9636-4f8075b44c67',
    auditPointId: 772,
    checkPointNo: '3.1.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 1,
    cp3: 3,
    cp4: 0,
    sortOrder: 50397952,
    score: null
  },
  {
    id: 1550,
    translationGuid: '8d43cc9e-e358-4abf-8c07-b9bb1b2ab456',
    auditPointId: 773,
    checkPointNo: '3.2.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 2,
    cp3: 1,
    cp4: 0,
    sortOrder: 50462976,
    score: null
  },
  {
    id: 1551,
    translationGuid: '58422823-3b16-479c-b5ee-a5b8452227d9',
    auditPointId: 773,
    checkPointNo: '3.2.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 2,
    cp3: 2,
    cp4: 0,
    sortOrder: 50463232,
    score: null
  },
  {
    id: 1552,
    translationGuid: '99447b86-64dc-4e9c-8db7-8b17dc19e71e',
    auditPointId: 773,
    checkPointNo: '3.2.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 2,
    cp3: 3,
    cp4: 0,
    sortOrder: 50463488,
    score: null
  },
  {
    id: 1992,
    translationGuid: '0d07b1c8-2937-4fb5-a17b-68777932a977',
    auditPointId: 773,
    checkPointNo: '3.2.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 2,
    cp3: 4,
    cp4: 0,
    sortOrder: 50463744,
    score: null
  },
  {
    id: 1553,
    translationGuid: '521647e1-8523-44c2-8188-66db658b0847',
    auditPointId: 774,
    checkPointNo: '3.3.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 3,
    cp3: 1,
    cp4: 0,
    sortOrder: 50528512,
    score: null
  },
  {
    id: 1554,
    translationGuid: '7725de90-2799-4abe-9a76-be0299f1bf67',
    auditPointId: 774,
    checkPointNo: '3.3.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 3,
    cp3: 2,
    cp4: 0,
    sortOrder: 50528768,
    score: null
  },
  {
    id: 1555,
    translationGuid: 'f4429497-057e-47f9-8a18-94f3129c382d',
    auditPointId: 774,
    checkPointNo: '3.3.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 3,
    cp3: 3,
    cp4: 0,
    sortOrder: 50529024,
    score: null
  },
  {
    id: 1556,
    translationGuid: '979621c0-3dcb-4d3c-9908-b853a6a3b310',
    auditPointId: 774,
    checkPointNo: '3.3.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 3,
    cp3: 4,
    cp4: 0,
    sortOrder: 50529280,
    score: null
  },
  {
    id: 1557,
    translationGuid: '7f015720-bb70-4388-a9b8-9f227a37bea4',
    auditPointId: 774,
    checkPointNo: '3.3.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 3,
    cp3: 5,
    cp4: 0,
    sortOrder: 50529536,
    score: null
  },
  {
    id: 1558,
    translationGuid: '95fdf015-f14a-4a9b-89b1-c097bb999aea',
    auditPointId: 775,
    checkPointNo: '3.4.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 4,
    cp3: 1,
    cp4: 0,
    sortOrder: 50594048,
    score: null
  },
  {
    id: 1559,
    translationGuid: 'a6d96513-897d-47f9-af00-06772aa5fd37',
    auditPointId: 775,
    checkPointNo: '3.4.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 4,
    cp3: 2,
    cp4: 0,
    sortOrder: 50594304,
    score: null
  },
  {
    id: 1560,
    translationGuid: 'f8c01906-b890-4ccb-8665-3c839497fb22',
    auditPointId: 775,
    checkPointNo: '3.4.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 4,
    cp3: 3,
    cp4: 0,
    sortOrder: 50594560,
    score: null
  },
  {
    id: 1561,
    translationGuid: '95b9c3e4-a30f-419d-9a4a-721d2dd3949f',
    auditPointId: 776,
    checkPointNo: '3.5.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 5,
    cp3: 1,
    cp4: 0,
    sortOrder: 50659584,
    score: null
  },
  {
    id: 1562,
    translationGuid: '091aa46b-51df-4775-82cb-040f2e82e42c',
    auditPointId: 776,
    checkPointNo: '3.5.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 5,
    cp3: 2,
    cp4: 0,
    sortOrder: 50659840,
    score: null
  },
  {
    id: 1563,
    translationGuid: 'b22b673b-5516-4100-8509-a499d3053c8e',
    auditPointId: 777,
    checkPointNo: '3.6.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 6,
    cp3: 1,
    cp4: 0,
    sortOrder: 50725120,
    score: null
  },
  {
    id: 1564,
    translationGuid: '28ea588b-25b7-4532-ba25-0bd4235c9c63',
    auditPointId: 777,
    checkPointNo: '3.6.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 6,
    cp3: 2,
    cp4: 0,
    sortOrder: 50725376,
    score: null
  },
  {
    id: 1565,
    translationGuid: '6df58c0a-3856-4cbc-8970-bcf0ba74e665',
    auditPointId: 777,
    checkPointNo: '3.6.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 6,
    cp3: 3,
    cp4: 0,
    sortOrder: 50725632,
    score: null
  },
  {
    id: 1566,
    translationGuid: 'f92bd07d-1bb0-449a-b23c-3c6a0cf960f7',
    auditPointId: 778,
    checkPointNo: '3.7.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 7,
    cp3: 1,
    cp4: 0,
    sortOrder: 50790656,
    score: null
  },
  {
    id: 1567,
    translationGuid: 'b6d79627-4779-43b0-b318-3b20449a1cd9',
    auditPointId: 778,
    checkPointNo: '3.7.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 7,
    cp3: 2,
    cp4: 0,
    sortOrder: 50790912,
    score: null
  },
  {
    id: 1568,
    translationGuid: '3b4f8991-4dcb-44ca-b2c1-da893e012068',
    auditPointId: 778,
    checkPointNo: '3.7.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 3,
    cp2: 7,
    cp3: 3,
    cp4: 0,
    sortOrder: 50791168,
    score: null
  },
  {
    id: 1569,
    translationGuid: '64734695-d137-41c6-aff2-d6fc47cb016a',
    auditPointId: 779,
    checkPointNo: '4.1.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 1,
    cp3: 1,
    cp4: 0,
    sortOrder: 67174656,
    score: null
  },
  {
    id: 1570,
    translationGuid: '691eac00-f36e-4b6c-bb4c-985a4b389e89',
    auditPointId: 779,
    checkPointNo: '4.1.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 1,
    cp3: 2,
    cp4: 0,
    sortOrder: 67174912,
    score: null
  },
  {
    id: 1571,
    translationGuid: '73bd9dec-a322-4cf4-9973-5ffa6f4f9203',
    auditPointId: 781,
    checkPointNo: '4.2.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 2,
    cp3: 1,
    cp4: 0,
    sortOrder: 67240192,
    score: null
  },
  {
    id: 1572,
    translationGuid: '5382de28-2394-4f60-be0a-71767ca243b1',
    auditPointId: 781,
    checkPointNo: '4.2.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 2,
    cp3: 2,
    cp4: 0,
    sortOrder: 67240448,
    score: null
  },
  {
    id: 1573,
    translationGuid: '948bf30c-80a1-4d81-951a-4e1b9b8f3a5a',
    auditPointId: 782,
    checkPointNo: '4.3.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 3,
    cp3: 1,
    cp4: 0,
    sortOrder: 67305728,
    score: null
  },
  {
    id: 1574,
    translationGuid: '140466b1-ec85-4dad-9ee5-1393feb4ae74',
    auditPointId: 782,
    checkPointNo: '4.3.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 3,
    cp3: 2,
    cp4: 0,
    sortOrder: 67305984,
    score: null
  },
  {
    id: 1575,
    translationGuid: 'cb7fa04c-7c7e-4b45-8749-83601e5fc844',
    auditPointId: 782,
    checkPointNo: '4.3.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 3,
    cp3: 3,
    cp4: 0,
    sortOrder: 67306240,
    score: null
  },
  {
    id: 1576,
    translationGuid: 'da0ce182-d270-49fc-a2aa-3f3e98a20498',
    auditPointId: 783,
    checkPointNo: '4.4.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 4,
    cp3: 1,
    cp4: 0,
    sortOrder: 67371264,
    score: null
  },
  {
    id: 1577,
    translationGuid: '56d2dd0f-7f7d-4148-9464-49216c094fdc',
    auditPointId: 783,
    checkPointNo: '4.4.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 4,
    cp3: 2,
    cp4: 0,
    sortOrder: 67371520,
    score: null
  },
  {
    id: 1578,
    translationGuid: '368db41e-c62e-417d-91ab-48d8864659d3',
    auditPointId: 784,
    checkPointNo: '4.5.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 5,
    cp3: 1,
    cp4: 0,
    sortOrder: 67436800,
    score: null
  },
  {
    id: 1988,
    translationGuid: '761ca236-1d3d-4b47-9c4c-a34913791dca',
    auditPointId: 784,
    checkPointNo: '4.5.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 5,
    cp3: 2,
    cp4: 0,
    sortOrder: 67437056,
    score: null
  },
  {
    id: 1579,
    translationGuid: 'dbec4a5c-ebf1-456a-ba98-b4d916a9400f',
    auditPointId: 785,
    checkPointNo: '4.6.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 6,
    cp3: 1,
    cp4: 0,
    sortOrder: 67502336,
    score: null
  },
  {
    id: 1580,
    translationGuid: 'f9f34e7a-6b80-4b82-8a02-af7648fbaae9',
    auditPointId: 785,
    checkPointNo: '4.6.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 6,
    cp3: 2,
    cp4: 0,
    sortOrder: 67502592,
    score: null
  },
  {
    id: 1581,
    translationGuid: '93ec2cf0-8a8e-45d1-b888-d4f76ad32847',
    auditPointId: 785,
    checkPointNo: '4.6.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 6,
    cp3: 3,
    cp4: 0,
    sortOrder: 67502848,
    score: null
  },
  {
    id: 1582,
    translationGuid: '118554c7-0e06-4577-8b04-c588cb7897f1',
    auditPointId: 785,
    checkPointNo: '4.6.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 6,
    cp3: 4,
    cp4: 0,
    sortOrder: 67503104,
    score: null
  },
  {
    id: 1583,
    translationGuid: '0c9000f6-7fc0-42f8-9687-c1e57533f393',
    auditPointId: 785,
    checkPointNo: '4.6.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 6,
    cp3: 5,
    cp4: 0,
    sortOrder: 67503360,
    score: null
  },
  {
    id: 1584,
    translationGuid: '1f0e49c3-f19e-4bf4-aaea-ac3ba2c3edd5',
    auditPointId: 785,
    checkPointNo: '4.6.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 6,
    cp3: 6,
    cp4: 0,
    sortOrder: 67503616,
    score: null
  },
  {
    id: 1585,
    translationGuid: 'f07e523e-de9b-4911-8d72-4b3da28af09f',
    auditPointId: 786,
    checkPointNo: '4.7.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 7,
    cp3: 1,
    cp4: 0,
    sortOrder: 67567872,
    score: null
  },
  {
    id: 1586,
    translationGuid: 'a28516d9-c38e-4839-969d-cade264df015',
    auditPointId: 786,
    checkPointNo: '4.7.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 7,
    cp3: 2,
    cp4: 0,
    sortOrder: 67568128,
    score: null
  },
  {
    id: 1587,
    translationGuid: '535e5973-0739-4cba-81b8-8133492cc6e5',
    auditPointId: 787,
    checkPointNo: '4.8.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 8,
    cp3: 1,
    cp4: 0,
    sortOrder: 67633408,
    score: null
  },
  {
    id: 1588,
    translationGuid: '9e595ceb-1f40-4cb1-a350-aaf6872d473f',
    auditPointId: 787,
    checkPointNo: '4.8.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 8,
    cp3: 2,
    cp4: 0,
    sortOrder: 67633664,
    score: null
  },
  {
    id: 1589,
    translationGuid: '9d01105d-5dc8-4b77-afb3-aa9b8954bea7',
    auditPointId: 787,
    checkPointNo: '4.8.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 4,
    cp2: 8,
    cp3: 3,
    cp4: 0,
    sortOrder: 67633920,
    score: null
  },
  {
    id: 1635,
    translationGuid: '088720e0-6400-4bda-bc85-7c612782b407',
    auditPointId: 800,
    checkPointNo: '7.1.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 1,
    cp3: 1,
    cp4: 0,
    sortOrder: 117506304,
    score: null
  },
  {
    id: 1636,
    translationGuid: '4e5bb26d-4aae-4201-981f-1033fc879add',
    auditPointId: 800,
    checkPointNo: '7.1.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 1,
    cp3: 2,
    cp4: 0,
    sortOrder: 117506560,
    score: null
  },
  {
    id: 1637,
    translationGuid: '79d448da-d39a-4d39-b8d9-3f7e7e96e31e',
    auditPointId: 800,
    checkPointNo: '7.1.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 1,
    cp3: 3,
    cp4: 0,
    sortOrder: 117506816,
    score: null
  },
  {
    id: 1638,
    translationGuid: 'f298030f-0db9-4bb4-bbc0-8ad6ae975523',
    auditPointId: 801,
    checkPointNo: '7.2.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 2,
    cp3: 1,
    cp4: 0,
    sortOrder: 117571840,
    score: null
  },
  {
    id: 1639,
    translationGuid: '63cf458a-f64e-4560-8d49-0f9d51fda528',
    auditPointId: 801,
    checkPointNo: '7.2.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 2,
    cp3: 2,
    cp4: 0,
    sortOrder: 117572096,
    score: null
  },
  {
    id: 1640,
    translationGuid: '9841b995-eac9-4c63-9348-0a209d7780ef',
    auditPointId: 801,
    checkPointNo: '7.2.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 2,
    cp3: 3,
    cp4: 0,
    sortOrder: 117572352,
    score: null
  },
  {
    id: 1641,
    translationGuid: 'f3d86e5d-e938-4d09-bd9e-31f5ba1b339a',
    auditPointId: 801,
    checkPointNo: '7.2.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 2,
    cp3: 4,
    cp4: 0,
    sortOrder: 117572608,
    score: null
  },
  {
    id: 1642,
    translationGuid: 'd18ee45f-b8af-4b4c-a900-ee908be7dc94',
    auditPointId: 801,
    checkPointNo: '7.2.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 2,
    cp3: 5,
    cp4: 0,
    sortOrder: 117572864,
    score: null
  },
  {
    id: 1643,
    translationGuid: '224235c6-4604-44e2-9730-8c1c41f04495',
    auditPointId: 801,
    checkPointNo: '7.2.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 2,
    cp3: 6,
    cp4: 0,
    sortOrder: 117573120,
    score: null
  },
  {
    id: 1644,
    translationGuid: 'e98a8dbf-109d-42ee-ba58-c6d5b039203f',
    auditPointId: 801,
    checkPointNo: '7.2.7',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 2,
    cp3: 7,
    cp4: 0,
    sortOrder: 117573376,
    score: null
  },
  {
    id: 1645,
    translationGuid: 'a45fb860-5ee4-4705-8978-3c937a2052a7',
    auditPointId: 801,
    checkPointNo: '7.2.8',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 2,
    cp3: 8,
    cp4: 0,
    sortOrder: 117573632,
    score: null
  },
  {
    id: 1646,
    translationGuid: '1ffa4676-f306-4feb-b15d-3f10d0686f40',
    auditPointId: 801,
    checkPointNo: '7.2.9',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 2,
    cp3: 9,
    cp4: 0,
    sortOrder: 117573888,
    score: null
  },
  {
    id: 1647,
    translationGuid: 'f405fe48-f54b-41e9-9db2-58c5efc76c9c',
    auditPointId: 802,
    checkPointNo: '7.3.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 3,
    cp3: 1,
    cp4: 0,
    sortOrder: 117637376,
    score: null
  },
  {
    id: 1648,
    translationGuid: '1a8a3b78-5e83-4766-a794-0fbcc9c7b25c',
    auditPointId: 802,
    checkPointNo: '7.3.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 3,
    cp3: 2,
    cp4: 0,
    sortOrder: 117637632,
    score: null
  },
  {
    id: 1649,
    translationGuid: '7c4ae124-f313-44d4-80de-8ab7f8b0ded1',
    auditPointId: 802,
    checkPointNo: '7.3.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 3,
    cp3: 3,
    cp4: 0,
    sortOrder: 117637888,
    score: null
  },
  {
    id: 1650,
    translationGuid: 'c0d5aae2-c785-48a0-a0ee-9d4c2af990eb',
    auditPointId: 803,
    checkPointNo: '7.4.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 4,
    cp3: 1,
    cp4: 0,
    sortOrder: 117702912,
    score: null
  },
  {
    id: 1651,
    translationGuid: '61f2030d-4ef2-49fb-8749-21cd1e85bbd6',
    auditPointId: 803,
    checkPointNo: '7.4.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 4,
    cp3: 2,
    cp4: 0,
    sortOrder: 117703168,
    score: null
  },
  {
    id: 1652,
    translationGuid: '88404f85-7d7e-4668-9015-58995093edd2',
    auditPointId: 803,
    checkPointNo: '7.4.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 4,
    cp3: 3,
    cp4: 0,
    sortOrder: 117703424,
    score: null
  },
  {
    id: 1653,
    translationGuid: 'ddcf60e1-02e7-4b2f-87a6-85090c7d9880',
    auditPointId: 804,
    checkPointNo: '7.5.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 5,
    cp3: 1,
    cp4: 0,
    sortOrder: 117768448,
    score: null
  },
  {
    id: 1654,
    translationGuid: 'aad68684-8fdc-45ef-82fc-b45d850573dc',
    auditPointId: 804,
    checkPointNo: '7.5.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 5,
    cp3: 2,
    cp4: 0,
    sortOrder: 117768704,
    score: null
  },
  {
    id: 1655,
    translationGuid: '53f2b09a-1a70-4197-8948-30e2800f5c10',
    auditPointId: 804,
    checkPointNo: '7.5.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 5,
    cp3: 3,
    cp4: 0,
    sortOrder: 117768960,
    score: null
  },
  {
    id: 1989,
    translationGuid: 'b1e77e45-0388-4910-ae0a-77f8ce7ba701',
    auditPointId: 804,
    checkPointNo: '7.5.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 5,
    cp3: 4,
    cp4: 0,
    sortOrder: 117769216,
    score: null
  },
  {
    id: 1656,
    translationGuid: '6c4354d0-3009-4178-ac25-177c70998d01',
    auditPointId: 805,
    checkPointNo: '7.6.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 6,
    cp3: 1,
    cp4: 0,
    sortOrder: 117833984,
    score: null
  },
  {
    id: 1657,
    translationGuid: '16634185-9681-4fd2-baec-e30bffb97db7',
    auditPointId: 805,
    checkPointNo: '7.6.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 6,
    cp3: 2,
    cp4: 0,
    sortOrder: 117834240,
    score: null
  },
  {
    id: 1658,
    translationGuid: '76680a3a-7f1f-4c09-85ba-60fc29fdb2a2',
    auditPointId: 805,
    checkPointNo: '7.6.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 6,
    cp3: 3,
    cp4: 0,
    sortOrder: 117834496,
    score: null
  },
  {
    id: 1659,
    translationGuid: '6bb676ac-5aa5-4bb7-83dd-ce7e2bfce349',
    auditPointId: 805,
    checkPointNo: '7.6.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 6,
    cp3: 4,
    cp4: 0,
    sortOrder: 117834752,
    score: null
  },
  {
    id: 1660,
    translationGuid: 'c4cb0947-1379-410a-baaf-bac856d7aa18',
    auditPointId: 806,
    checkPointNo: '7.7.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 7,
    cp3: 1,
    cp4: 0,
    sortOrder: 117899520,
    score: null
  },
  {
    id: 1661,
    translationGuid: '68c55a76-9c63-4eae-9d85-61d12460ebd4',
    auditPointId: 806,
    checkPointNo: '7.7.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 7,
    cp3: 2,
    cp4: 0,
    sortOrder: 117899776,
    score: null
  },
  {
    id: 1662,
    translationGuid: '96118502-b01a-49b0-b99d-678c339e3c5b',
    auditPointId: 806,
    checkPointNo: '7.7.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 7,
    cp3: 3,
    cp4: 0,
    sortOrder: 117900032,
    score: null
  },
  {
    id: 1663,
    translationGuid: '56e80202-b072-470b-9b52-909405c2defe',
    auditPointId: 806,
    checkPointNo: '7.7.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 7,
    cp3: 4,
    cp4: 0,
    sortOrder: 117900288,
    score: null
  },
  {
    id: 1664,
    translationGuid: '8c947729-a489-4624-863c-aa9f76549590',
    auditPointId: 807,
    checkPointNo: '7.8.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 8,
    cp3: 1,
    cp4: 0,
    sortOrder: 117965056,
    score: null
  },
  {
    id: 1665,
    translationGuid: 'a7e208b4-dd7c-4256-bf1d-9c8a66fb2469',
    auditPointId: 807,
    checkPointNo: '7.8.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 8,
    cp3: 2,
    cp4: 0,
    sortOrder: 117965312,
    score: null
  },
  {
    id: 1666,
    translationGuid: '798b9fd7-2525-470a-92b1-8f351fcdcfea',
    auditPointId: 807,
    checkPointNo: '7.8.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 8,
    cp3: 3,
    cp4: 0,
    sortOrder: 117965568,
    score: null
  },
  {
    id: 1667,
    translationGuid: '223a9cb8-01a1-4b0f-8966-c223777a3a73',
    auditPointId: 808,
    checkPointNo: '7.9.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 9,
    cp3: 1,
    cp4: 0,
    sortOrder: 118030592,
    score: null
  },
  {
    id: 1668,
    translationGuid: 'e5a49359-3e03-42c8-9917-b4537dc2ba0d',
    auditPointId: 808,
    checkPointNo: '7.9.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 9,
    cp3: 2,
    cp4: 0,
    sortOrder: 118030848,
    score: null
  },
  {
    id: 1669,
    translationGuid: 'aa87d063-b2d3-4c38-936d-709492e84c8d',
    auditPointId: 809,
    checkPointNo: '7.10.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 10,
    cp3: 1,
    cp4: 0,
    sortOrder: 118096128,
    score: null
  },
  {
    id: 1670,
    translationGuid: '01b2bcac-751f-41d3-a4f9-b28567819d60',
    auditPointId: 809,
    checkPointNo: '7.10.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 10,
    cp3: 2,
    cp4: 0,
    sortOrder: 118096384,
    score: null
  },
  {
    id: 1671,
    translationGuid: '35c4ccbb-a579-4d12-bfea-4054d1d917fd',
    auditPointId: 809,
    checkPointNo: '7.10.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 10,
    cp3: 3,
    cp4: 0,
    sortOrder: 118096640,
    score: null
  },
  {
    id: 1672,
    translationGuid: '5737cb6b-803d-47f0-b6d6-9cb010d0c865',
    auditPointId: 809,
    checkPointNo: '7.10.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 10,
    cp3: 4,
    cp4: 0,
    sortOrder: 118096896,
    score: null
  },
  {
    id: 1673,
    translationGuid: '0cbf1b72-ffe5-4543-ab7c-d39c2314bc4a',
    auditPointId: 809,
    checkPointNo: '7.10.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 10,
    cp3: 5,
    cp4: 0,
    sortOrder: 118097152,
    score: null
  },
  {
    id: 1674,
    translationGuid: '7c7bfe25-def4-4d52-9699-db2261774b4c',
    auditPointId: 809,
    checkPointNo: '7.10.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 10,
    cp3: 6,
    cp4: 0,
    sortOrder: 118097408,
    score: null
  },
  {
    id: 1675,
    translationGuid: '786ea4b2-c38a-4226-be78-2fbe2e206fcc',
    auditPointId: 810,
    checkPointNo: '7.11.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 11,
    cp3: 1,
    cp4: 0,
    sortOrder: 118161664,
    score: null
  },
  {
    id: 1676,
    translationGuid: '58f366cc-01a5-49f4-a18e-368d72933349',
    auditPointId: 810,
    checkPointNo: '7.11.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 11,
    cp3: 2,
    cp4: 0,
    sortOrder: 118161920,
    score: null
  },
  {
    id: 1677,
    translationGuid: 'f582ee82-7554-4b68-a60a-e809c47eaba1',
    auditPointId: 811,
    checkPointNo: '7.12.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 12,
    cp3: 1,
    cp4: 0,
    sortOrder: 118227200,
    score: null
  },
  {
    id: 1678,
    translationGuid: '10ba5755-5d65-4094-91ad-7fc9a88993ca',
    auditPointId: 811,
    checkPointNo: '7.12.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 12,
    cp3: 2,
    cp4: 0,
    sortOrder: 118227456,
    score: null
  },
  {
    id: 1679,
    translationGuid: '84147556-f71c-4700-9c84-2da5f1f2b98a',
    auditPointId: 811,
    checkPointNo: '7.12.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 12,
    cp3: 3,
    cp4: 0,
    sortOrder: 118227712,
    score: null
  },
  {
    id: 1680,
    translationGuid: '9a8e9c20-aef6-43ff-bc90-d63d76ecc778',
    auditPointId: 780,
    checkPointNo: '7.13.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 13,
    cp3: 1,
    cp4: 0,
    sortOrder: 118292736,
    score: null
  },
  {
    id: 1681,
    translationGuid: '94e92880-8d54-41d1-9e4a-a59f6c65d035',
    auditPointId: 780,
    checkPointNo: '7.13.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 13,
    cp3: 2,
    cp4: 0,
    sortOrder: 118292992,
    score: null
  },
  {
    id: 1682,
    translationGuid: 'dac63919-ec24-4377-8a4a-a3dc9b2f9c27',
    auditPointId: 812,
    checkPointNo: '7.14.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 14,
    cp3: 1,
    cp4: 0,
    sortOrder: 118358272,
    score: null
  },
  {
    id: 1683,
    translationGuid: '2ed87dce-4bc9-42aa-8fdd-ac73278ef885',
    auditPointId: 812,
    checkPointNo: '7.14.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 14,
    cp3: 2,
    cp4: 0,
    sortOrder: 118358528,
    score: null
  },
  {
    id: 1684,
    translationGuid: 'a8d4ae86-f45b-4e0d-9fdb-8ff56627c6b1',
    auditPointId: 813,
    checkPointNo: '7.15.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 15,
    cp3: 1,
    cp4: 0,
    sortOrder: 118423808,
    score: null
  },
  {
    id: 1685,
    translationGuid: '2482f657-64be-44a4-b8f4-f0ab9c1de044',
    auditPointId: 814,
    checkPointNo: '7.16.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 16,
    cp3: 1,
    cp4: 0,
    sortOrder: 118489344,
    score: null
  },
  {
    id: 1686,
    translationGuid: '7f59c24c-05f8-4a67-94ce-7d527b20729a',
    auditPointId: 814,
    checkPointNo: '7.16.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 7,
    cp2: 16,
    cp3: 2,
    cp4: 0,
    sortOrder: 118489600,
    score: null
  },
  {
    id: 1687,
    translationGuid: '4e4c25e9-6644-497e-a055-0aa530263c94',
    auditPointId: 815,
    checkPointNo: '8.1.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 1,
    cp3: 1,
    cp4: 0,
    sortOrder: 134283520,
    score: null
  },
  {
    id: 1688,
    translationGuid: '844a725b-7bf3-4c81-b74b-f2928acfcdc3',
    auditPointId: 815,
    checkPointNo: '8.1.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 1,
    cp3: 2,
    cp4: 0,
    sortOrder: 134283776,
    score: null
  },
  {
    id: 1689,
    translationGuid: 'a292c351-3775-4541-8007-687c58c14dd0',
    auditPointId: 815,
    checkPointNo: '8.1.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 1,
    cp3: 3,
    cp4: 0,
    sortOrder: 134284032,
    score: null
  },
  {
    id: 1690,
    translationGuid: '94958fc7-f0cf-4be9-8a15-8242518a8a79',
    auditPointId: 815,
    checkPointNo: '8.1.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 1,
    cp3: 4,
    cp4: 0,
    sortOrder: 134284288,
    score: null
  },
  {
    id: 1691,
    translationGuid: '455ce779-53e5-4d05-9451-80cf003a3c0e',
    auditPointId: 816,
    checkPointNo: '8.2.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 2,
    cp3: 1,
    cp4: 0,
    sortOrder: 134349056,
    score: null
  },
  {
    id: 1692,
    translationGuid: '825e28c1-bdfe-409d-95be-fe49c2fb949d',
    auditPointId: 816,
    checkPointNo: '8.2.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 2,
    cp3: 2,
    cp4: 0,
    sortOrder: 134349312,
    score: null
  },
  {
    id: 1693,
    translationGuid: 'ac71126d-5074-4c2b-8f34-6ddf0b058725',
    auditPointId: 817,
    checkPointNo: '8.3.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 3,
    cp3: 1,
    cp4: 0,
    sortOrder: 134414592,
    score: null
  },
  {
    id: 1694,
    translationGuid: '5456924b-6560-4e07-bfc0-1f080c765620',
    auditPointId: 817,
    checkPointNo: '8.3.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 3,
    cp3: 2,
    cp4: 0,
    sortOrder: 134414848,
    score: null
  },
  {
    id: 1695,
    translationGuid: 'a7d2ca69-fe53-4bf6-8bfd-0b8bf4b6ff9c',
    auditPointId: 817,
    checkPointNo: '8.3.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 3,
    cp3: 3,
    cp4: 0,
    sortOrder: 134415104,
    score: null
  },
  {
    id: 1696,
    translationGuid: '0afcd5cc-8d83-41ee-ae31-2b04db13fad2',
    auditPointId: 818,
    checkPointNo: '8.4.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 4,
    cp3: 1,
    cp4: 0,
    sortOrder: 134480128,
    score: null
  },
  {
    id: 1697,
    translationGuid: 'df1a938c-656c-4dd1-ab47-cc54bd6e9aa5',
    auditPointId: 818,
    checkPointNo: '8.4.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 4,
    cp3: 2,
    cp4: 0,
    sortOrder: 134480384,
    score: null
  },
  {
    id: 1698,
    translationGuid: '8644a053-eb2d-47f9-b4fa-c40f99915ca3',
    auditPointId: 819,
    checkPointNo: '8.5.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 5,
    cp3: 1,
    cp4: 0,
    sortOrder: 134545664,
    score: null
  },
  {
    id: 1699,
    translationGuid: '227d2b1b-ae55-4be2-a412-634509aab931',
    auditPointId: 819,
    checkPointNo: '8.5.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 5,
    cp3: 2,
    cp4: 0,
    sortOrder: 134545920,
    score: null
  },
  {
    id: 1700,
    translationGuid: 'd5fa6ba4-25c4-4212-9b2f-b8aa5b693153',
    auditPointId: 819,
    checkPointNo: '8.5.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 5,
    cp3: 3,
    cp4: 0,
    sortOrder: 134546176,
    score: null
  },
  {
    id: 1701,
    translationGuid: '1f5fa87d-39ed-4a3d-920a-422d94d77250',
    auditPointId: 819,
    checkPointNo: '8.5.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 5,
    cp3: 4,
    cp4: 0,
    sortOrder: 134546432,
    score: null
  },
  {
    id: 1702,
    translationGuid: 'e8855115-fb07-4cb4-af7b-6c0f403da48f',
    auditPointId: 820,
    checkPointNo: '8.6.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 6,
    cp3: 1,
    cp4: 0,
    sortOrder: 134611200,
    score: null
  },
  {
    id: 1703,
    translationGuid: '4a8eab42-9e42-4c3a-a82b-10a3d34849f7',
    auditPointId: 820,
    checkPointNo: '8.6.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 6,
    cp3: 2,
    cp4: 0,
    sortOrder: 134611456,
    score: null
  },
  {
    id: 1704,
    translationGuid: '13b9d8b4-2cb5-4b48-b827-c949b742ed73',
    auditPointId: 820,
    checkPointNo: '8.6.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 6,
    cp3: 3,
    cp4: 0,
    sortOrder: 134611712,
    score: null
  },
  {
    id: 1705,
    translationGuid: '6573b02c-67b9-4507-8bb6-eb39215105fd',
    auditPointId: 820,
    checkPointNo: '8.6.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 6,
    cp3: 4,
    cp4: 0,
    sortOrder: 134611968,
    score: null
  },
  {
    id: 1706,
    translationGuid: '94bfe68e-6b15-4bf1-8696-e81cf5913df5',
    auditPointId: 820,
    checkPointNo: '8.6.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 6,
    cp3: 5,
    cp4: 0,
    sortOrder: 134612224,
    score: null
  },
  {
    id: 1707,
    translationGuid: '4e1824c9-b33c-482e-8215-fd5b50908af6',
    auditPointId: 820,
    checkPointNo: '8.6.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 6,
    cp3: 6,
    cp4: 0,
    sortOrder: 134612480,
    score: null
  },
  {
    id: 1708,
    translationGuid: '1d316589-a0e6-4024-9134-cfa64b6cc9d9',
    auditPointId: 821,
    checkPointNo: '8.7.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 7,
    cp3: 1,
    cp4: 0,
    sortOrder: 134676736,
    score: null
  },
  {
    id: 1709,
    translationGuid: '508184f5-345a-42ba-b03c-3017217275f6',
    auditPointId: 821,
    checkPointNo: '8.7.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 7,
    cp3: 2,
    cp4: 0,
    sortOrder: 134676992,
    score: null
  },
  {
    id: 1710,
    translationGuid: '55bea001-e6de-424b-b336-05742f75f552',
    auditPointId: 822,
    checkPointNo: '8.8.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 8,
    cp3: 1,
    cp4: 0,
    sortOrder: 134742272,
    score: null
  },
  {
    id: 1711,
    translationGuid: '115ec90b-1ce3-45d6-8c92-6d7008d29cfd',
    auditPointId: 822,
    checkPointNo: '8.8.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 8,
    cp3: 2,
    cp4: 0,
    sortOrder: 134742528,
    score: null
  },
  {
    id: 1712,
    translationGuid: '041b0fe6-9c92-4513-b562-aee0b517ab47',
    auditPointId: 822,
    checkPointNo: '8.8.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 8,
    cp3: 3,
    cp4: 0,
    sortOrder: 134742784,
    score: null
  },
  {
    id: 1713,
    translationGuid: '1f66827b-c541-4b41-83a0-d7a4c06b9c69',
    auditPointId: 823,
    checkPointNo: '8.9.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 9,
    cp3: 1,
    cp4: 0,
    sortOrder: 134807808,
    score: null
  },
  {
    id: 1714,
    translationGuid: 'a7b1cc1f-c0f7-42e8-893e-9627523ca807',
    auditPointId: 823,
    checkPointNo: '8.9.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 9,
    cp3: 2,
    cp4: 0,
    sortOrder: 134808064,
    score: null
  },
  {
    id: 1715,
    translationGuid: 'cfa84830-b9f9-4a80-99b4-8197c046a1ef',
    auditPointId: 823,
    checkPointNo: '8.9.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 9,
    cp3: 3,
    cp4: 0,
    sortOrder: 134808320,
    score: null
  },
  {
    id: 1716,
    translationGuid: '070382b4-60ad-4d46-a98b-f9d3cb27505e',
    auditPointId: 824,
    checkPointNo: '8.10.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 10,
    cp3: 1,
    cp4: 0,
    sortOrder: 134873344,
    score: null
  },
  {
    id: 1717,
    translationGuid: '73574687-8ccb-403e-bbe9-e2896ecd6984',
    auditPointId: 824,
    checkPointNo: '8.10.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 10,
    cp3: 2,
    cp4: 0,
    sortOrder: 134873600,
    score: null
  },
  {
    id: 1718,
    translationGuid: 'a92ffae1-13e7-4692-ad96-7070375eadd3',
    auditPointId: 824,
    checkPointNo: '8.10.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 10,
    cp3: 3,
    cp4: 0,
    sortOrder: 134873856,
    score: null
  },
  {
    id: 1719,
    translationGuid: '8219830c-3578-4a69-9203-948f90bc572e',
    auditPointId: 824,
    checkPointNo: '8.10.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 10,
    cp3: 4,
    cp4: 0,
    sortOrder: 134874112,
    score: null
  },
  {
    id: 1720,
    translationGuid: '870728cb-b373-4336-8d61-0d3cbd54a8f1',
    auditPointId: 824,
    checkPointNo: '8.10.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 10,
    cp3: 5,
    cp4: 0,
    sortOrder: 134874368,
    score: null
  },
  {
    id: 1721,
    translationGuid: 'b67fa402-0b61-4d3a-9a49-b85974b00f28',
    auditPointId: 824,
    checkPointNo: '8.10.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 10,
    cp3: 6,
    cp4: 0,
    sortOrder: 134874624,
    score: null
  },
  {
    id: 1722,
    translationGuid: 'def4e54c-c383-4027-add4-44b54fc61b0f',
    auditPointId: 824,
    checkPointNo: '8.10.7',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 10,
    cp3: 7,
    cp4: 0,
    sortOrder: 134874880,
    score: null
  },
  {
    id: 1723,
    translationGuid: '3dc5bf06-61b0-4141-8b42-2d4478c6471a',
    auditPointId: 824,
    checkPointNo: '8.10.8',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 10,
    cp3: 8,
    cp4: 0,
    sortOrder: 134875136,
    score: null
  },
  {
    id: 1724,
    translationGuid: 'a0e57ee3-ad3f-4cbf-b1df-596109e8bf93',
    auditPointId: 824,
    checkPointNo: '8.10.9',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 10,
    cp3: 9,
    cp4: 0,
    sortOrder: 134875392,
    score: null
  },
  {
    id: 1725,
    translationGuid: '6e7ba9da-fe0f-4c82-8e46-49bda2789faf',
    auditPointId: 825,
    checkPointNo: '8.11.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 11,
    cp3: 1,
    cp4: 0,
    sortOrder: 134938880,
    score: null
  },
  {
    id: 1726,
    translationGuid: 'ade61c8f-d966-4b13-ae09-28ed69bc3fb8',
    auditPointId: 825,
    checkPointNo: '8.11.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 11,
    cp3: 2,
    cp4: 0,
    sortOrder: 134939136,
    score: null
  },
  {
    id: 1727,
    translationGuid: '54df731a-9602-4f28-9121-fa75aaa48dbc',
    auditPointId: 825,
    checkPointNo: '8.11.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 11,
    cp3: 3,
    cp4: 0,
    sortOrder: 134939392,
    score: null
  },
  {
    id: 1728,
    translationGuid: 'd36eed2b-a429-4256-8b92-486f1710aba6',
    auditPointId: 825,
    checkPointNo: '8.11.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 11,
    cp3: 4,
    cp4: 0,
    sortOrder: 134939648,
    score: null
  },
  {
    id: 1729,
    translationGuid: '80807b73-141b-458c-8629-bdb360bb0acd',
    auditPointId: 825,
    checkPointNo: '8.11.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 11,
    cp3: 5,
    cp4: 0,
    sortOrder: 134939904,
    score: null
  },
  {
    id: 1730,
    translationGuid: 'ec5719f1-d7ee-430b-8b72-4b3e9a5cb465',
    auditPointId: 825,
    checkPointNo: '8.11.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 11,
    cp3: 6,
    cp4: 0,
    sortOrder: 134940160,
    score: null
  },
  {
    id: 1731,
    translationGuid: '02dd8fa4-bd29-47e9-92eb-525dd4da4161',
    auditPointId: 826,
    checkPointNo: '8.12.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 12,
    cp3: 1,
    cp4: 0,
    sortOrder: 135004416,
    score: null
  },
  {
    id: 1732,
    translationGuid: '31adb44a-2b7d-4353-98e2-4a5713890ab9',
    auditPointId: 826,
    checkPointNo: '8.12.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 12,
    cp3: 2,
    cp4: 0,
    sortOrder: 135004672,
    score: null
  },
  {
    id: 1733,
    translationGuid: '925342ef-c9e0-436f-b50b-3164f1fe9829',
    auditPointId: 826,
    checkPointNo: '8.12.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 12,
    cp3: 3,
    cp4: 0,
    sortOrder: 135004928,
    score: null
  },
  {
    id: 1734,
    translationGuid: '35044f42-10ad-47c4-801f-052e83af4437',
    auditPointId: 826,
    checkPointNo: '8.12.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 12,
    cp3: 4,
    cp4: 0,
    sortOrder: 135005184,
    score: null
  },
  {
    id: 1735,
    translationGuid: '67d21176-162b-4868-a7da-97c0b4c5f7e0',
    auditPointId: 826,
    checkPointNo: '8.12.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 12,
    cp3: 5,
    cp4: 0,
    sortOrder: 135005440,
    score: null
  },
  {
    id: 1736,
    translationGuid: '122d4e52-8a06-4914-81ba-78ccb6621385',
    auditPointId: 826,
    checkPointNo: '8.12.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 12,
    cp3: 6,
    cp4: 0,
    sortOrder: 135005696,
    score: null
  },
  {
    id: 1737,
    translationGuid: '8288420e-aa01-43e6-b31a-0a72c75dcfb3',
    auditPointId: 827,
    checkPointNo: '8.13.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 13,
    cp3: 1,
    cp4: 0,
    sortOrder: 135069952,
    score: null
  },
  {
    id: 1738,
    translationGuid: '8380d8c4-c4b6-4c1e-bcd5-39755fa051fd',
    auditPointId: 827,
    checkPointNo: '8.13.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 13,
    cp3: 2,
    cp4: 0,
    sortOrder: 135070208,
    score: null
  },
  {
    id: 1739,
    translationGuid: '43d53d31-d00f-4f2c-9469-22ba68b18bc7',
    auditPointId: 827,
    checkPointNo: '8.13.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 13,
    cp3: 3,
    cp4: 0,
    sortOrder: 135070464,
    score: null
  },
  {
    id: 1740,
    translationGuid: '3477a260-3709-4fe0-8ccc-771f3cd9b62d',
    auditPointId: 828,
    checkPointNo: '8.14.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 14,
    cp3: 1,
    cp4: 0,
    sortOrder: 135135488,
    score: null
  },
  {
    id: 1741,
    translationGuid: '67240fdf-d3be-4bf7-8a05-b7f29d722087',
    auditPointId: 828,
    checkPointNo: '8.14.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 14,
    cp3: 2,
    cp4: 0,
    sortOrder: 135135744,
    score: null
  },
  {
    id: 1742,
    translationGuid: '5626a5bf-c9ac-4a19-9262-0ad44e5a737e',
    auditPointId: 828,
    checkPointNo: '8.14.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 14,
    cp3: 3,
    cp4: 0,
    sortOrder: 135136000,
    score: null
  },
  {
    id: 1743,
    translationGuid: 'a3d7af79-b773-4cd2-b788-84cc7f098073',
    auditPointId: 828,
    checkPointNo: '8.14.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 14,
    cp3: 4,
    cp4: 0,
    sortOrder: 135136256,
    score: null
  },
  {
    id: 1744,
    translationGuid: '09667b43-e065-4b1b-b235-7bb780da9dbc',
    auditPointId: 828,
    checkPointNo: '8.14.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 14,
    cp3: 5,
    cp4: 0,
    sortOrder: 135136512,
    score: null
  },
  {
    id: 1745,
    translationGuid: '406c90ba-c6a1-44af-a362-6da9782ab4e4',
    auditPointId: 829,
    checkPointNo: '8.15.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 15,
    cp3: 1,
    cp4: 0,
    sortOrder: 135201024,
    score: null
  },
  {
    id: 1746,
    translationGuid: 'a9faf899-9f63-4a41-a856-9010e88b3334',
    auditPointId: 829,
    checkPointNo: '8.15.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 15,
    cp3: 2,
    cp4: 0,
    sortOrder: 135201280,
    score: null
  },
  {
    id: 1747,
    translationGuid: 'cc8f41f5-2aaa-4a39-8d38-ab2ea298ad5b',
    auditPointId: 830,
    checkPointNo: '8.16.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 16,
    cp3: 1,
    cp4: 0,
    sortOrder: 135266560,
    score: null
  },
  {
    id: 1748,
    translationGuid: '241295e3-a1b4-4556-98db-b8e51bb99b49',
    auditPointId: 830,
    checkPointNo: '8.16.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 16,
    cp3: 2,
    cp4: 0,
    sortOrder: 135266816,
    score: null
  },
  {
    id: 1749,
    translationGuid: '217d7c1c-a31b-4409-bf33-7cd682e0efbb',
    auditPointId: 830,
    checkPointNo: '8.16.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 16,
    cp3: 3,
    cp4: 0,
    sortOrder: 135267072,
    score: null
  },
  {
    id: 1750,
    translationGuid: '8bdd296f-7856-4910-bce6-5c5fced96274',
    auditPointId: 830,
    checkPointNo: '8.16.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 16,
    cp3: 4,
    cp4: 0,
    sortOrder: 135267328,
    score: null
  },
  {
    id: 1751,
    translationGuid: '333ea889-08cc-40c2-95ea-febfc9538e10',
    auditPointId: 830,
    checkPointNo: '8.16.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 16,
    cp3: 5,
    cp4: 0,
    sortOrder: 135267584,
    score: null
  },
  {
    id: 1752,
    translationGuid: 'eebfe613-0d57-4314-8ba7-03320e068bc0',
    auditPointId: 831,
    checkPointNo: '8.17.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 17,
    cp3: 1,
    cp4: 0,
    sortOrder: 135332096,
    score: null
  },
  {
    id: 1990,
    translationGuid: '6943de07-83c3-45c3-8408-c39d37f04a70',
    auditPointId: 831,
    checkPointNo: '8.17.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 17,
    cp3: 2,
    cp4: 0,
    sortOrder: 135332352,
    score: null
  },
  {
    id: 1753,
    translationGuid: 'd3d3c5ec-e9ca-4b18-bd20-705c5bba07f0',
    auditPointId: 832,
    checkPointNo: '8.18.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 18,
    cp3: 1,
    cp4: 0,
    sortOrder: 135397632,
    score: null
  },
  {
    id: 1754,
    translationGuid: 'cd798a75-8c2e-4aa1-a94c-9e29271869e7',
    auditPointId: 832,
    checkPointNo: '8.18.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 18,
    cp3: 2,
    cp4: 0,
    sortOrder: 135397888,
    score: null
  },
  {
    id: 1755,
    translationGuid: 'f0a4fb09-1847-4008-ab0b-07050d7264d4',
    auditPointId: 832,
    checkPointNo: '8.18.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 8,
    cp2: 18,
    cp3: 3,
    cp4: 0,
    sortOrder: 135398144,
    score: null
  },
  {
    id: 1756,
    translationGuid: '0da25a00-e187-4981-834d-8304c0e8623e',
    auditPointId: 833,
    checkPointNo: '9.1.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 1,
    cp3: 1,
    cp4: 0,
    sortOrder: 151060736,
    score: null
  },
  {
    id: 1757,
    translationGuid: 'ae9ac499-e997-40aa-8509-7006ffea0b5b',
    auditPointId: 833,
    checkPointNo: '9.1.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 1,
    cp3: 2,
    cp4: 0,
    sortOrder: 151060992,
    score: null
  },
  {
    id: 1758,
    translationGuid: '3bee6721-ec0a-43f1-b0e7-d0dd9d891c1a',
    auditPointId: 833,
    checkPointNo: '9.1.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 1,
    cp3: 3,
    cp4: 0,
    sortOrder: 151061248,
    score: null
  },
  {
    id: 1759,
    translationGuid: 'd04df8c4-d79a-4835-8c6e-a51d3515313e',
    auditPointId: 833,
    checkPointNo: '9.1.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 1,
    cp3: 4,
    cp4: 0,
    sortOrder: 151061504,
    score: null
  },
  {
    id: 1760,
    translationGuid: '165f8425-107f-42a6-83de-2e58b6d5341f',
    auditPointId: 833,
    checkPointNo: '9.1.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 1,
    cp3: 5,
    cp4: 0,
    sortOrder: 151061760,
    score: null
  },
  {
    id: 1761,
    translationGuid: '795106b3-3927-4c72-846f-4048268b8bfd',
    auditPointId: 833,
    checkPointNo: '9.1.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 1,
    cp3: 6,
    cp4: 0,
    sortOrder: 151062016,
    score: null
  },
  {
    id: 1762,
    translationGuid: '9f5a7314-1470-4817-89fa-5ef7bd1e8722',
    auditPointId: 834,
    checkPointNo: '9.2.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 2,
    cp3: 1,
    cp4: 0,
    sortOrder: 151126272,
    score: null
  },
  {
    id: 1763,
    translationGuid: '94d137ab-306a-429e-8a39-bcdd37fe02d5',
    auditPointId: 834,
    checkPointNo: '9.2.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 2,
    cp3: 2,
    cp4: 0,
    sortOrder: 151126528,
    score: null
  },
  {
    id: 1764,
    translationGuid: '25fb6589-1168-4c1c-a9e1-6194341be341',
    auditPointId: 834,
    checkPointNo: '9.2.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 2,
    cp3: 3,
    cp4: 0,
    sortOrder: 151126784,
    score: null
  },
  {
    id: 1765,
    translationGuid: '6c927531-2ea6-4f17-bfcc-8c184a40ffa1',
    auditPointId: 834,
    checkPointNo: '9.2.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 2,
    cp3: 4,
    cp4: 0,
    sortOrder: 151127040,
    score: null
  },
  {
    id: 1766,
    translationGuid: '84a5a7b1-0a65-4240-9994-01a811af188d',
    auditPointId: 835,
    checkPointNo: '9.3.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 3,
    cp3: 1,
    cp4: 0,
    sortOrder: 151191808,
    score: null
  },
  {
    id: 1767,
    translationGuid: '046f8ed8-b8cc-4e08-b6f4-3ab4c15e527f',
    auditPointId: 835,
    checkPointNo: '9.3.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 3,
    cp3: 2,
    cp4: 0,
    sortOrder: 151192064,
    score: null
  },
  {
    id: 1768,
    translationGuid: '866a1cb2-d2ef-443a-8a5d-9443a3511bca',
    auditPointId: 835,
    checkPointNo: '9.3.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 3,
    cp3: 3,
    cp4: 0,
    sortOrder: 151192320,
    score: null
  },
  {
    id: 1769,
    translationGuid: '62bfa3c9-575f-4c2d-9f3b-08aceeb6224e',
    auditPointId: 836,
    checkPointNo: '9.4.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 4,
    cp3: 1,
    cp4: 0,
    sortOrder: 151257344,
    score: null
  },
  {
    id: 1770,
    translationGuid: '3fdb1d7c-02db-4fce-8d4c-49ed8e36a947',
    auditPointId: 836,
    checkPointNo: '9.4.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 4,
    cp3: 2,
    cp4: 0,
    sortOrder: 151257600,
    score: null
  },
  {
    id: 1771,
    translationGuid: '8d782994-83b0-43fb-b844-5296ab4f43ac',
    auditPointId: 836,
    checkPointNo: '9.4.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 4,
    cp3: 3,
    cp4: 0,
    sortOrder: 151257856,
    score: null
  },
  {
    id: 1772,
    translationGuid: 'ff6b4e8a-2fcb-4426-aec1-a1fe38601dcc',
    auditPointId: 836,
    checkPointNo: '9.4.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 4,
    cp3: 4,
    cp4: 0,
    sortOrder: 151258112,
    score: null
  },
  {
    id: 1773,
    translationGuid: 'f8b5e018-3b4e-4bb0-9064-4464318c6972',
    auditPointId: 836,
    checkPointNo: '9.4.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 4,
    cp3: 5,
    cp4: 0,
    sortOrder: 151258368,
    score: null
  },
  {
    id: 1774,
    translationGuid: '3ec3735e-a1b7-4304-8dd6-1aa30564ce0e',
    auditPointId: 837,
    checkPointNo: '9.5.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 5,
    cp3: 1,
    cp4: 0,
    sortOrder: 151322880,
    score: null
  },
  {
    id: 1775,
    translationGuid: '1f4f2a55-8836-4747-9b94-b38f211809b7',
    auditPointId: 837,
    checkPointNo: '9.5.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 5,
    cp3: 2,
    cp4: 0,
    sortOrder: 151323136,
    score: null
  },
  {
    id: 1776,
    translationGuid: '0fd8020c-21bb-44b8-957b-674b6329905e',
    auditPointId: 838,
    checkPointNo: '9.6.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 6,
    cp3: 1,
    cp4: 0,
    sortOrder: 151388416,
    score: null
  },
  {
    id: 1777,
    translationGuid: 'f3b87220-547b-4785-a97d-c0118010d99d',
    auditPointId: 839,
    checkPointNo: '9.7.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 7,
    cp3: 1,
    cp4: 0,
    sortOrder: 151453952,
    score: null
  },
  {
    id: 1778,
    translationGuid: '3c29690c-ffef-43df-a4fb-ff9e155577a8',
    auditPointId: 839,
    checkPointNo: '9.7.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 7,
    cp3: 2,
    cp4: 0,
    sortOrder: 151454208,
    score: null
  },
  {
    id: 1779,
    translationGuid: 'a0a065db-fae1-45a5-a320-a124873b53f8',
    auditPointId: 839,
    checkPointNo: '9.7.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 7,
    cp3: 3,
    cp4: 0,
    sortOrder: 151454464,
    score: null
  },
  {
    id: 1780,
    translationGuid: '395772ce-f8a5-4f87-9f1c-3a52f9a2ed09',
    auditPointId: 839,
    checkPointNo: '9.7.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 7,
    cp3: 4,
    cp4: 0,
    sortOrder: 151454720,
    score: null
  },
  {
    id: 1781,
    translationGuid: '708d7fea-2600-42c5-a912-cf2b55e30368',
    auditPointId: 839,
    checkPointNo: '9.7.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 7,
    cp3: 5,
    cp4: 0,
    sortOrder: 151454976,
    score: null
  },
  {
    id: 1782,
    translationGuid: 'e795d27b-020d-4b35-8256-00048f4391f1',
    auditPointId: 840,
    checkPointNo: '9.8.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 8,
    cp3: 1,
    cp4: 0,
    sortOrder: 151519488,
    score: null
  },
  {
    id: 1783,
    translationGuid: 'f035a0a8-b59f-4a2c-889f-8cf3d0bc9dbb',
    auditPointId: 840,
    checkPointNo: '9.8.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 9,
    cp2: 8,
    cp3: 2,
    cp4: 0,
    sortOrder: 151519744,
    score: null
  },
  {
    id: 1784,
    translationGuid: '912847d3-43bc-4afc-8ead-e5707d07a7b4',
    auditPointId: 841,
    checkPointNo: '10.1.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 1,
    cp3: 1,
    cp4: 0,
    sortOrder: 167837952,
    score: null
  },
  {
    id: 1785,
    translationGuid: '35badd1e-a715-41a5-ac2d-fbfc65ad1dc9',
    auditPointId: 841,
    checkPointNo: '10.1.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 1,
    cp3: 2,
    cp4: 0,
    sortOrder: 167838208,
    score: null
  },
  {
    id: 1786,
    translationGuid: '13e07b2e-4085-456d-9eb7-2e45f8f67d8e',
    auditPointId: 841,
    checkPointNo: '10.1.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 1,
    cp3: 3,
    cp4: 0,
    sortOrder: 167838464,
    score: null
  },
  {
    id: 1787,
    translationGuid: '4254c7c6-ebb7-4859-9443-42b392fd57c4',
    auditPointId: 841,
    checkPointNo: '10.1.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 1,
    cp3: 4,
    cp4: 0,
    sortOrder: 167838720,
    score: null
  },
  {
    id: 1788,
    translationGuid: '29dafac4-c0c0-4efc-b86d-68c70a0c50bf',
    auditPointId: 841,
    checkPointNo: '10.1.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 1,
    cp3: 5,
    cp4: 0,
    sortOrder: 167838976,
    score: null
  },
  {
    id: 1789,
    translationGuid: '41500b32-10b8-47fb-bfdb-afe8a7d5bb71',
    auditPointId: 841,
    checkPointNo: '10.1.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 1,
    cp3: 6,
    cp4: 0,
    sortOrder: 167839232,
    score: null
  },
  {
    id: 1790,
    translationGuid: 'ffb3d8f8-74e1-483b-ab21-3fbf3c62dc5b',
    auditPointId: 842,
    checkPointNo: '10.2.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 2,
    cp3: 1,
    cp4: 0,
    sortOrder: 167903488,
    score: null
  },
  {
    id: 1791,
    translationGuid: 'bce6107a-48eb-49ef-b025-0f3b49fd26da',
    auditPointId: 843,
    checkPointNo: '10.3.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 3,
    cp3: 1,
    cp4: 0,
    sortOrder: 167969024,
    score: null
  },
  {
    id: 1792,
    translationGuid: '6a4880f4-7d5c-450a-8c9a-9a4a5bfd26c5',
    auditPointId: 843,
    checkPointNo: '10.3.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 3,
    cp3: 2,
    cp4: 0,
    sortOrder: 167969280,
    score: null
  },
  {
    id: 1793,
    translationGuid: '4f180242-d643-4e40-b331-5a9b308fcd7c',
    auditPointId: 843,
    checkPointNo: '10.3.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 3,
    cp3: 3,
    cp4: 0,
    sortOrder: 167969536,
    score: null
  },
  {
    id: 1794,
    translationGuid: 'bc462f5c-f696-47a8-9ee8-dbc5f44b3e2a',
    auditPointId: 844,
    checkPointNo: '10.4.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 4,
    cp3: 1,
    cp4: 0,
    sortOrder: 168034560,
    score: null
  },
  {
    id: 1795,
    translationGuid: '89b6e16c-59f5-4d97-bd94-f4a48b58bc38',
    auditPointId: 844,
    checkPointNo: '10.4.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 4,
    cp3: 2,
    cp4: 0,
    sortOrder: 168034816,
    score: null
  },
  {
    id: 1796,
    translationGuid: 'ade642d9-6a87-450b-9a92-37a50d1dc5a3',
    auditPointId: 845,
    checkPointNo: '10.5.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 5,
    cp3: 1,
    cp4: 0,
    sortOrder: 168100096,
    score: null
  },
  {
    id: 1797,
    translationGuid: '972acbd5-e37d-46fa-9a2a-eea493240980',
    auditPointId: 845,
    checkPointNo: '10.5.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 5,
    cp3: 2,
    cp4: 0,
    sortOrder: 168100352,
    score: null
  },
  {
    id: 1798,
    translationGuid: 'af85423d-4820-41cd-b984-ab1856e28b98',
    auditPointId: 846,
    checkPointNo: '10.6.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 6,
    cp3: 1,
    cp4: 0,
    sortOrder: 168165632,
    score: null
  },
  {
    id: 1799,
    translationGuid: 'ae7991db-8140-4f55-af3c-cb9b5587783f',
    auditPointId: 846,
    checkPointNo: '10.6.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 6,
    cp3: 2,
    cp4: 0,
    sortOrder: 168165888,
    score: null
  },
  {
    id: 1800,
    translationGuid: '923929c7-b3cd-4fa3-8049-29b41b726b0a',
    auditPointId: 846,
    checkPointNo: '10.6.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 6,
    cp3: 3,
    cp4: 0,
    sortOrder: 168166144,
    score: null
  },
  {
    id: 1801,
    translationGuid: '35f48274-4f52-4008-bc00-eebad30d6acc',
    auditPointId: 847,
    checkPointNo: '10.7.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 7,
    cp3: 1,
    cp4: 0,
    sortOrder: 168231168,
    score: null
  },
  {
    id: 1802,
    translationGuid: '4cb7fab7-91b9-4f80-aee6-1f080fd035f3',
    auditPointId: 847,
    checkPointNo: '10.7.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 7,
    cp3: 2,
    cp4: 0,
    sortOrder: 168231424,
    score: null
  },
  {
    id: 1803,
    translationGuid: 'f4b4abfa-24db-4820-941b-94fe7b2257f4',
    auditPointId: 847,
    checkPointNo: '10.7.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 7,
    cp3: 3,
    cp4: 0,
    sortOrder: 168231680,
    score: null
  },
  {
    id: 1804,
    translationGuid: '82b0ce64-d238-42a5-bea1-71117b062153',
    auditPointId: 848,
    checkPointNo: '10.8.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 8,
    cp3: 1,
    cp4: 0,
    sortOrder: 168296704,
    score: null
  },
  {
    id: 1805,
    translationGuid: '12feea22-9d59-4bf0-96cc-5c3432d8b678',
    auditPointId: 849,
    checkPointNo: '10.9.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 10,
    cp2: 9,
    cp3: 1,
    cp4: 0,
    sortOrder: 168362240,
    score: null
  },
  {
    id: 1806,
    translationGuid: 'e901371c-225b-4114-83e6-efa2fbc2d900',
    auditPointId: 850,
    checkPointNo: '11.1.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 1,
    cp3: 1,
    cp4: 0,
    sortOrder: 184615168,
    score: null
  },
  {
    id: 1807,
    translationGuid: 'da9fe778-f25d-49f2-be1b-b76c31fd289a',
    auditPointId: 850,
    checkPointNo: '11.1.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 1,
    cp3: 2,
    cp4: 0,
    sortOrder: 184615424,
    score: null
  },
  {
    id: 1808,
    translationGuid: 'b0b9fe33-53eb-4018-a338-047c6f217d95',
    auditPointId: 850,
    checkPointNo: '11.1.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 1,
    cp3: 3,
    cp4: 0,
    sortOrder: 184615680,
    score: null
  },
  {
    id: 1809,
    translationGuid: '82927444-bc2e-46e7-8910-4988ef144141',
    auditPointId: 850,
    checkPointNo: '11.1.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 1,
    cp3: 4,
    cp4: 0,
    sortOrder: 184615936,
    score: null
  },
  {
    id: 1810,
    translationGuid: 'b0fa06ad-0ae8-4c78-b55d-24776d18a627',
    auditPointId: 850,
    checkPointNo: '11.1.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 1,
    cp3: 5,
    cp4: 0,
    sortOrder: 184616192,
    score: null
  },
  {
    id: 1811,
    translationGuid: '3d17564b-b3b3-4ce1-b741-4501d1b8d6e2',
    auditPointId: 851,
    checkPointNo: '11.2.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 2,
    cp3: 1,
    cp4: 0,
    sortOrder: 184680704,
    score: null
  },
  {
    id: 1812,
    translationGuid: 'e278c26d-95c8-4685-8605-295853e8a255',
    auditPointId: 852,
    checkPointNo: '11.3.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 3,
    cp3: 1,
    cp4: 0,
    sortOrder: 184746240,
    score: null
  },
  {
    id: 1813,
    translationGuid: '796b7ffa-a0a7-4ac7-a288-d0cd76afc5d1',
    auditPointId: 853,
    checkPointNo: '11.4.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 4,
    cp3: 1,
    cp4: 0,
    sortOrder: 184811776,
    score: null
  },
  {
    id: 1814,
    translationGuid: '172e4eb7-551e-4bac-aa76-95034d2d7c79',
    auditPointId: 854,
    checkPointNo: '11.5.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 5,
    cp3: 1,
    cp4: 0,
    sortOrder: 184877312,
    score: null
  },
  {
    id: 1815,
    translationGuid: 'ddb9c4c5-bb30-410f-80b3-c295c7389f9d',
    auditPointId: 854,
    checkPointNo: '11.5.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 5,
    cp3: 2,
    cp4: 0,
    sortOrder: 184877568,
    score: null
  },
  {
    id: 1816,
    translationGuid: '38baa8fe-1293-414e-a132-f65bf1ca2156',
    auditPointId: 855,
    checkPointNo: '11.6.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 6,
    cp3: 1,
    cp4: 0,
    sortOrder: 184942848,
    score: null
  },
  {
    id: 1817,
    translationGuid: '022ba2b9-fd34-4c63-86f0-c3d53ba7a119',
    auditPointId: 855,
    checkPointNo: '11.6.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 6,
    cp3: 2,
    cp4: 0,
    sortOrder: 184943104,
    score: null
  },
  {
    id: 1818,
    translationGuid: '40f35781-d9a6-49f1-8f12-d069c1df6f57',
    auditPointId: 855,
    checkPointNo: '11.6.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 6,
    cp3: 3,
    cp4: 0,
    sortOrder: 184943360,
    score: null
  },
  {
    id: 1819,
    translationGuid: 'f3842602-0b73-4552-bfc8-4a42b5702b6d',
    auditPointId: 856,
    checkPointNo: '11.7.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 7,
    cp3: 1,
    cp4: 0,
    sortOrder: 185008384,
    score: null
  },
  {
    id: 1820,
    translationGuid: 'c345941c-52fe-4c37-a00c-4b8d540a9b85',
    auditPointId: 857,
    checkPointNo: '11.8.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 8,
    cp3: 1,
    cp4: 0,
    sortOrder: 185073920,
    score: null
  },
  {
    id: 1821,
    translationGuid: 'a9a57aa9-f967-4206-8084-80651aeceab2',
    auditPointId: 857,
    checkPointNo: '11.8.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 8,
    cp3: 2,
    cp4: 0,
    sortOrder: 185074176,
    score: null
  },
  {
    id: 1822,
    translationGuid: '16e30583-7746-41a5-a3af-3faa9fd30a15',
    auditPointId: 857,
    checkPointNo: '11.8.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 8,
    cp3: 3,
    cp4: 0,
    sortOrder: 185074432,
    score: null
  },
  {
    id: 1823,
    translationGuid: 'c0cb6783-1e0d-4f94-b3fa-447e3984a661',
    auditPointId: 858,
    checkPointNo: '11.9.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 9,
    cp3: 1,
    cp4: 0,
    sortOrder: 185139456,
    score: null
  },
  {
    id: 1824,
    translationGuid: '17c49e31-46bf-44e2-8fc6-84ef4a602e1e',
    auditPointId: 858,
    checkPointNo: '11.9.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 9,
    cp3: 2,
    cp4: 0,
    sortOrder: 185139712,
    score: null
  },
  {
    id: 1825,
    translationGuid: 'c4642e9f-52e3-4e49-81be-5563dc320cfd',
    auditPointId: 859,
    checkPointNo: '11.10.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 10,
    cp3: 1,
    cp4: 0,
    sortOrder: 185204992,
    score: null
  },
  {
    id: 1826,
    translationGuid: '683cfb57-0f9d-43b6-b522-d765aacb5c1b',
    auditPointId: 859,
    checkPointNo: '11.10.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 10,
    cp3: 2,
    cp4: 0,
    sortOrder: 185205248,
    score: null
  },
  {
    id: 1827,
    translationGuid: '591d8d69-1ab6-47b8-9e42-191531b81f79',
    auditPointId: 860,
    checkPointNo: '11.11.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 11,
    cp3: 1,
    cp4: 0,
    sortOrder: 185270528,
    score: null
  },
  {
    id: 1828,
    translationGuid: '8f220526-9604-4047-955c-2b387979b3b2',
    auditPointId: 860,
    checkPointNo: '11.11.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 11,
    cp3: 2,
    cp4: 0,
    sortOrder: 185270784,
    score: null
  },
  {
    id: 1829,
    translationGuid: '797e10ab-986f-47cf-a1a4-9af714682904',
    auditPointId: 860,
    checkPointNo: '11.11.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 11,
    cp3: 3,
    cp4: 0,
    sortOrder: 185271040,
    score: null
  },
  {
    id: 1830,
    translationGuid: '4b070857-4639-4581-8b27-e78ef70207f1',
    auditPointId: 861,
    checkPointNo: '11.12.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 12,
    cp3: 1,
    cp4: 0,
    sortOrder: 185336064,
    score: null
  },
  {
    id: 1831,
    translationGuid: '7868c9bf-4b5c-491e-9d99-7c499bbc31bc',
    auditPointId: 861,
    checkPointNo: '11.12.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 12,
    cp3: 2,
    cp4: 0,
    sortOrder: 185336320,
    score: null
  },
  {
    id: 1832,
    translationGuid: 'e5418faf-fbca-47d9-9353-565618bc061a',
    auditPointId: 862,
    checkPointNo: '11.13.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 13,
    cp3: 1,
    cp4: 0,
    sortOrder: 185401600,
    score: null
  },
  {
    id: 1833,
    translationGuid: '8b44f265-4aad-431e-b326-229a25aefaf8',
    auditPointId: 862,
    checkPointNo: '11.13.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 13,
    cp3: 2,
    cp4: 0,
    sortOrder: 185401856,
    score: null
  },
  {
    id: 1834,
    translationGuid: '5565b510-d3b0-4094-a126-0e00ebdc586a',
    auditPointId: 863,
    checkPointNo: '11.14.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 14,
    cp3: 1,
    cp4: 0,
    sortOrder: 185467136,
    score: null
  },
  {
    id: 1835,
    translationGuid: '376bcdcb-f477-4210-a18b-38488527b45a',
    auditPointId: 863,
    checkPointNo: '11.14.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 14,
    cp3: 2,
    cp4: 0,
    sortOrder: 185467392,
    score: null
  },
  {
    id: 1836,
    translationGuid: 'a633e503-ca82-4415-b992-73f83657f1ae',
    auditPointId: 864,
    checkPointNo: '11.15.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 15,
    cp3: 1,
    cp4: 0,
    sortOrder: 185532672,
    score: null
  },
  {
    id: 1837,
    translationGuid: '172c72eb-262c-4335-990c-8f9309f85d32',
    auditPointId: 864,
    checkPointNo: '11.15.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 15,
    cp3: 2,
    cp4: 0,
    sortOrder: 185532928,
    score: null
  },
  {
    id: 1838,
    translationGuid: '03ab4154-dcf2-48b5-b2ae-fbd07094e51f',
    auditPointId: 864,
    checkPointNo: '11.15.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 15,
    cp3: 3,
    cp4: 0,
    sortOrder: 185533184,
    score: null
  },
  {
    id: 1839,
    translationGuid: '10cf65cc-22f4-4a11-975f-fbd078c334db',
    auditPointId: 865,
    checkPointNo: '11.16.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 16,
    cp3: 1,
    cp4: 0,
    sortOrder: 185598208,
    score: null
  },
  {
    id: 1840,
    translationGuid: 'f990d96b-1a83-409f-90fc-0b994d65199c',
    auditPointId: 865,
    checkPointNo: '11.16.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 16,
    cp3: 2,
    cp4: 0,
    sortOrder: 185598464,
    score: null
  },
  {
    id: 1841,
    translationGuid: 'ac9da142-aef9-4ea2-a0d1-f70012066b2d',
    auditPointId: 865,
    checkPointNo: '11.16.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 16,
    cp3: 3,
    cp4: 0,
    sortOrder: 185598720,
    score: null
  },
  {
    id: 1842,
    translationGuid: 'f2a03a1f-6c42-4af1-b897-92ea6e0baafd',
    auditPointId: 866,
    checkPointNo: '11.17.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 17,
    cp3: 1,
    cp4: 0,
    sortOrder: 185663744,
    score: null
  },
  {
    id: 1843,
    translationGuid: 'bec10b68-273c-4f66-a373-4f9080b5064a',
    auditPointId: 866,
    checkPointNo: '11.17.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 17,
    cp3: 2,
    cp4: 0,
    sortOrder: 185664000,
    score: null
  },
  {
    id: 1844,
    translationGuid: '1dbbe6c9-9e20-4e83-85cc-238801840dbe',
    auditPointId: 867,
    checkPointNo: '11.18.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 18,
    cp3: 1,
    cp4: 0,
    sortOrder: 185729280,
    score: null
  },
  {
    id: 1845,
    translationGuid: 'bc7b3469-2b65-4c0c-b5bd-92a2d4c9043c',
    auditPointId: 867,
    checkPointNo: '11.18.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 18,
    cp3: 2,
    cp4: 0,
    sortOrder: 185729536,
    score: null
  },
  {
    id: 1846,
    translationGuid: '1f3b1658-0117-4491-9c20-4720596913fc',
    auditPointId: 867,
    checkPointNo: '11.18.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 18,
    cp3: 3,
    cp4: 0,
    sortOrder: 185729792,
    score: null
  },
  {
    id: 1847,
    translationGuid: '6dafd019-9770-4e48-8488-73220173058d',
    auditPointId: 868,
    checkPointNo: '11.19.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 19,
    cp3: 1,
    cp4: 0,
    sortOrder: 185794816,
    score: null
  },
  {
    id: 1848,
    translationGuid: '27c8039c-1114-4bc4-a518-4e298e7044a8',
    auditPointId: 869,
    checkPointNo: '11.20.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 20,
    cp3: 1,
    cp4: 0,
    sortOrder: 185860352,
    score: null
  },
  {
    id: 1849,
    translationGuid: '9fdc3198-d66d-4c4d-833b-5fcd77e598aa',
    auditPointId: 869,
    checkPointNo: '11.20.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 11,
    cp2: 20,
    cp3: 2,
    cp4: 0,
    sortOrder: 185860608,
    score: null
  },
  {
    id: 1850,
    translationGuid: '96540d84-1cfb-49d8-8089-35223f87ceeb',
    auditPointId: 870,
    checkPointNo: '12.1.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 1,
    cp3: 1,
    cp4: 0,
    sortOrder: 201392384,
    score: null
  },
  {
    id: 1851,
    translationGuid: 'ef2e5b29-620f-457d-aaf2-90fa7ddfe192',
    auditPointId: 870,
    checkPointNo: '12.1.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 1,
    cp3: 2,
    cp4: 0,
    sortOrder: 201392640,
    score: null
  },
  {
    id: 1852,
    translationGuid: '0e814192-0587-4bd5-9212-dd5a0d52e3ee',
    auditPointId: 870,
    checkPointNo: '12.1.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 1,
    cp3: 3,
    cp4: 0,
    sortOrder: 201392896,
    score: null
  },
  {
    id: 1853,
    translationGuid: '18117d22-5523-4038-a8dc-4dfd02e7deed',
    auditPointId: 870,
    checkPointNo: '12.1.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 1,
    cp3: 4,
    cp4: 0,
    sortOrder: 201393152,
    score: null
  },
  {
    id: 1854,
    translationGuid: '994f604e-6569-4c01-ba3d-cdd86f0ebfd8',
    auditPointId: 870,
    checkPointNo: '12.1.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 1,
    cp3: 5,
    cp4: 0,
    sortOrder: 201393408,
    score: null
  },
  {
    id: 1855,
    translationGuid: 'cc8bfabf-0e71-428b-975f-8e25bcc818ab',
    auditPointId: 870,
    checkPointNo: '12.1.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 1,
    cp3: 6,
    cp4: 0,
    sortOrder: 201393664,
    score: null
  },
  {
    id: 1856,
    translationGuid: '6773f3f9-9e42-44e0-9095-050c28ba0559',
    auditPointId: 870,
    checkPointNo: '12.1.7',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 1,
    cp3: 7,
    cp4: 0,
    sortOrder: 201393920,
    score: null
  },
  {
    id: 1857,
    translationGuid: '79c277c5-387a-40b0-8418-78ba61e8eea0',
    auditPointId: 871,
    checkPointNo: '12.2.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 2,
    cp3: 1,
    cp4: 0,
    sortOrder: 201457920,
    score: null
  },
  {
    id: 1858,
    translationGuid: '4534c39f-d233-486e-8728-702931147c16',
    auditPointId: 871,
    checkPointNo: '12.2.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 2,
    cp3: 2,
    cp4: 0,
    sortOrder: 201458176,
    score: null
  },
  {
    id: 1859,
    translationGuid: '22c37898-9d62-437f-b202-07c8bd510bdd',
    auditPointId: 871,
    checkPointNo: '12.2.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 2,
    cp3: 3,
    cp4: 0,
    sortOrder: 201458432,
    score: null
  },
  {
    id: 1860,
    translationGuid: '01279246-7778-4f5d-972b-4d41f2aff0ac',
    auditPointId: 871,
    checkPointNo: '12.2.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 2,
    cp3: 4,
    cp4: 0,
    sortOrder: 201458688,
    score: null
  },
  {
    id: 1861,
    translationGuid: '5595bd62-e072-4396-bb6e-5a2af785358e',
    auditPointId: 871,
    checkPointNo: '12.2.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 2,
    cp3: 5,
    cp4: 0,
    sortOrder: 201458944,
    score: null
  },
  {
    id: 1862,
    translationGuid: '62092a0e-46b3-42b3-824d-2c6b1aeea32b',
    auditPointId: 871,
    checkPointNo: '12.2.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 2,
    cp3: 6,
    cp4: 0,
    sortOrder: 201459200,
    score: null
  },
  {
    id: 1863,
    translationGuid: '08cce5a8-2bcb-4391-acf3-ef7bf0ccd1b3',
    auditPointId: 871,
    checkPointNo: '12.2.7',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 2,
    cp3: 7,
    cp4: 0,
    sortOrder: 201459456,
    score: null
  },
  {
    id: 1995,
    translationGuid: 'b1cb4110-eb73-4593-9d28-92efc9264806',
    auditPointId: 871,
    checkPointNo: '12.2.8',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 2,
    cp3: 8,
    cp4: 0,
    sortOrder: 201459712,
    score: null
  },
  {
    id: 1864,
    translationGuid: '05cd1f48-7574-4967-a932-3fd6c2321a26',
    auditPointId: 872,
    checkPointNo: '12.3.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 3,
    cp3: 1,
    cp4: 0,
    sortOrder: 201523456,
    score: null
  },
  {
    id: 1865,
    translationGuid: '4a1d51e2-91b2-476b-9416-395721e500f8',
    auditPointId: 872,
    checkPointNo: '12.3.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 3,
    cp3: 2,
    cp4: 0,
    sortOrder: 201523712,
    score: null
  },
  {
    id: 1866,
    translationGuid: 'e9c32d70-a127-4ed5-be10-c0cff9527306',
    auditPointId: 872,
    checkPointNo: '12.3.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 3,
    cp3: 3,
    cp4: 0,
    sortOrder: 201523968,
    score: null
  },
  {
    id: 1867,
    translationGuid: '6fb8fa80-ded7-4a88-893e-2477283d5062',
    auditPointId: 873,
    checkPointNo: '12.4.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 4,
    cp3: 1,
    cp4: 0,
    sortOrder: 201588992,
    score: null
  },
  {
    id: 1868,
    translationGuid: '98f9d8bb-0164-4af9-96b0-8583824342a1',
    auditPointId: 873,
    checkPointNo: '12.4.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 4,
    cp3: 2,
    cp4: 0,
    sortOrder: 201589248,
    score: null
  },
  {
    id: 1869,
    translationGuid: '45d3fe63-89c5-4f78-b7fb-4ce7d1624340',
    auditPointId: 873,
    checkPointNo: '12.4.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 4,
    cp3: 3,
    cp4: 0,
    sortOrder: 201589504,
    score: null
  },
  {
    id: 1870,
    translationGuid: '28b77a9c-65d4-42f6-bc23-98812289dd23',
    auditPointId: 873,
    checkPointNo: '12.4.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 4,
    cp3: 4,
    cp4: 0,
    sortOrder: 201589760,
    score: null
  },
  {
    id: 1871,
    translationGuid: '8c47c9ba-6b13-4990-bcd5-7f0250a42a1f',
    auditPointId: 873,
    checkPointNo: '12.4.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 4,
    cp3: 5,
    cp4: 0,
    sortOrder: 201590016,
    score: null
  },
  {
    id: 1872,
    translationGuid: '5a39dd7b-5ad1-4e27-a985-caa20bfef90c',
    auditPointId: 873,
    checkPointNo: '12.4.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 4,
    cp3: 6,
    cp4: 0,
    sortOrder: 201590272,
    score: null
  },
  {
    id: 1873,
    translationGuid: 'a5f24807-a4f5-48c4-8a06-f041d3a6e062',
    auditPointId: 874,
    checkPointNo: '12.5.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 5,
    cp3: 1,
    cp4: 0,
    sortOrder: 201654528,
    score: null
  },
  {
    id: 1874,
    translationGuid: '591790fd-a1a5-490f-90f2-0663ce9c3b0c',
    auditPointId: 874,
    checkPointNo: '12.5.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 12,
    cp2: 5,
    cp3: 2,
    cp4: 0,
    sortOrder: 201654784,
    score: null
  },
  {
    id: 1875,
    translationGuid: '3cba9a41-6218-41f6-905d-0d706e596c65',
    auditPointId: 875,
    checkPointNo: '13.1.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 1,
    cp3: 1,
    cp4: 0,
    sortOrder: 218169600,
    score: null
  },
  {
    id: 1876,
    translationGuid: '450dd0e3-a6cb-44c2-80c3-0a97d74af218',
    auditPointId: 875,
    checkPointNo: '13.1.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 1,
    cp3: 2,
    cp4: 0,
    sortOrder: 218169856,
    score: null
  },
  {
    id: 1877,
    translationGuid: '442d803a-96da-4815-bff2-1d561da803c3',
    auditPointId: 875,
    checkPointNo: '13.1.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 1,
    cp3: 3,
    cp4: 0,
    sortOrder: 218170112,
    score: null
  },
  {
    id: 1878,
    translationGuid: '02d5f9c1-f0ff-4b11-b25f-d87c21392980',
    auditPointId: 875,
    checkPointNo: '13.1.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 1,
    cp3: 4,
    cp4: 0,
    sortOrder: 218170368,
    score: null
  },
  {
    id: 1879,
    translationGuid: 'a3686597-84b0-4789-a761-375099ada204',
    auditPointId: 875,
    checkPointNo: '13.1.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 1,
    cp3: 5,
    cp4: 0,
    sortOrder: 218170624,
    score: null
  },
  {
    id: 1880,
    translationGuid: 'cfc98d90-f9f7-4512-973a-a18e6a1fdf0e',
    auditPointId: 876,
    checkPointNo: '13.2.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 2,
    cp3: 1,
    cp4: 0,
    sortOrder: 218235136,
    score: null
  },
  {
    id: 1881,
    translationGuid: '92e72f93-d757-41b9-84e7-dbf6aea1a3e3',
    auditPointId: 876,
    checkPointNo: '13.2.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 2,
    cp3: 2,
    cp4: 0,
    sortOrder: 218235392,
    score: null
  },
  {
    id: 1882,
    translationGuid: '127cdf6b-9a8a-48a8-80ea-15ce75e949bb',
    auditPointId: 876,
    checkPointNo: '13.2.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 2,
    cp3: 3,
    cp4: 0,
    sortOrder: 218235648,
    score: null
  },
  {
    id: 1883,
    translationGuid: '409c1bb6-7dce-49d4-9ada-ff9d7a84e49d',
    auditPointId: 876,
    checkPointNo: '13.2.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 2,
    cp3: 4,
    cp4: 0,
    sortOrder: 218235904,
    score: null
  },
  {
    id: 1884,
    translationGuid: 'ed395c05-8bc0-4ba0-8a55-0c80481b9cdb',
    auditPointId: 876,
    checkPointNo: '13.2.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 2,
    cp3: 5,
    cp4: 0,
    sortOrder: 218236160,
    score: null
  },
  {
    id: 1885,
    translationGuid: '6da960ef-f051-4204-a212-2e8330f16093',
    auditPointId: 877,
    checkPointNo: '13.3.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 3,
    cp3: 1,
    cp4: 0,
    sortOrder: 218300672,
    score: null
  },
  {
    id: 1886,
    translationGuid: '64e189ce-cf39-4cbc-bce1-6f81f0ad2c4f',
    auditPointId: 877,
    checkPointNo: '13.3.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 3,
    cp3: 2,
    cp4: 0,
    sortOrder: 218300928,
    score: null
  },
  {
    id: 1887,
    translationGuid: 'e56ec966-01bb-45fd-9aba-fd9310eeeb0c',
    auditPointId: 877,
    checkPointNo: '13.3.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 3,
    cp3: 3,
    cp4: 0,
    sortOrder: 218301184,
    score: null
  },
  {
    id: 1888,
    translationGuid: '1b68c7ce-ae3d-49b0-adc2-ac78da110d85',
    auditPointId: 877,
    checkPointNo: '13.3.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 3,
    cp3: 4,
    cp4: 0,
    sortOrder: 218301440,
    score: null
  },
  {
    id: 1889,
    translationGuid: 'fab13ed5-c55f-4d67-aeb1-7efc5ec2d2af',
    auditPointId: 877,
    checkPointNo: '13.3.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 3,
    cp3: 5,
    cp4: 0,
    sortOrder: 218301696,
    score: null
  },
  {
    id: 1890,
    translationGuid: '48e5535d-ea1d-48c5-b205-eddc002713d5',
    auditPointId: 877,
    checkPointNo: '13.3.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 3,
    cp3: 6,
    cp4: 0,
    sortOrder: 218301952,
    score: null
  },
  {
    id: 1891,
    translationGuid: 'f99d5ae6-d2ce-48e1-baa0-fac4fbcca591',
    auditPointId: 878,
    checkPointNo: '13.4.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 4,
    cp3: 1,
    cp4: 0,
    sortOrder: 218366208,
    score: null
  },
  {
    id: 1892,
    translationGuid: '9c1b9912-3b0e-4726-9d1b-92bf5bf5d38f',
    auditPointId: 878,
    checkPointNo: '13.4.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 4,
    cp3: 2,
    cp4: 0,
    sortOrder: 218366464,
    score: null
  },
  {
    id: 1893,
    translationGuid: '5afef1ce-8dfc-43bb-99a6-08c3af7333e5',
    auditPointId: 878,
    checkPointNo: '13.4.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 4,
    cp3: 3,
    cp4: 0,
    sortOrder: 218366720,
    score: null
  },
  {
    id: 1894,
    translationGuid: 'edc2a746-af84-4ef8-952f-d77e67d5d2fe',
    auditPointId: 878,
    checkPointNo: '13.4.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 4,
    cp3: 4,
    cp4: 0,
    sortOrder: 218366976,
    score: null
  },
  {
    id: 1895,
    translationGuid: '7cda0cd8-16f4-4cbc-8a32-22ba6b9c6b39',
    auditPointId: 879,
    checkPointNo: '13.5.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 5,
    cp3: 1,
    cp4: 0,
    sortOrder: 218431744,
    score: null
  },
  {
    id: 1896,
    translationGuid: '11857232-09b7-49f0-8eb8-fb688e418a3b',
    auditPointId: 879,
    checkPointNo: '13.5.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 5,
    cp3: 2,
    cp4: 0,
    sortOrder: 218432000,
    score: null
  },
  {
    id: 1897,
    translationGuid: '6d8e6f9a-c116-48b5-a91b-48d2b68b1229',
    auditPointId: 879,
    checkPointNo: '13.5.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 5,
    cp3: 3,
    cp4: 0,
    sortOrder: 218432256,
    score: null
  },
  {
    id: 1898,
    translationGuid: 'c95d945c-be93-41e0-a73f-bf078ae7e8da',
    auditPointId: 880,
    checkPointNo: '13.6.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 6,
    cp3: 1,
    cp4: 0,
    sortOrder: 218497280,
    score: null
  },
  {
    id: 1899,
    translationGuid: '88cb19e0-5db2-41b9-b417-8c20f86bb3cf',
    auditPointId: 880,
    checkPointNo: '13.6.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 6,
    cp3: 2,
    cp4: 0,
    sortOrder: 218497536,
    score: null
  },
  {
    id: 1900,
    translationGuid: 'fb490f4f-6ed5-4354-b248-dda06666fbf9',
    auditPointId: 880,
    checkPointNo: '13.6.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 6,
    cp3: 3,
    cp4: 0,
    sortOrder: 218497792,
    score: null
  },
  {
    id: 1901,
    translationGuid: 'f63a2e2e-897f-4d13-9a70-a4c1d757b130',
    auditPointId: 880,
    checkPointNo: '13.6.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 6,
    cp3: 4,
    cp4: 0,
    sortOrder: 218498048,
    score: null
  },
  {
    id: 1902,
    translationGuid: 'a63c2bcb-6cd7-42ac-88c0-ffff064d2c08',
    auditPointId: 881,
    checkPointNo: '13.7.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 7,
    cp3: 1,
    cp4: 0,
    sortOrder: 218562816,
    score: null
  },
  {
    id: 1903,
    translationGuid: '5b980d54-9942-4ff5-945e-0e3a6f093210',
    auditPointId: 881,
    checkPointNo: '13.7.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 13,
    cp2: 7,
    cp3: 2,
    cp4: 0,
    sortOrder: 218563072,
    score: null
  },
  {
    id: 1904,
    translationGuid: '14d2dc62-67e7-4f99-8b55-9ef6c171e28c',
    auditPointId: 882,
    checkPointNo: '14.1.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 1,
    cp3: 1,
    cp4: 0,
    sortOrder: 234946816,
    score: null
  },
  {
    id: 1905,
    translationGuid: '602c5fb8-f4ed-4e2a-b12f-b953a1dad7e0',
    auditPointId: 882,
    checkPointNo: '14.1.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 1,
    cp3: 2,
    cp4: 0,
    sortOrder: 234947072,
    score: null
  },
  {
    id: 1906,
    translationGuid: '5464a897-9500-4cb7-b76f-aa45902a653c',
    auditPointId: 882,
    checkPointNo: '14.1.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 1,
    cp3: 3,
    cp4: 0,
    sortOrder: 234947328,
    score: null
  },
  {
    id: 1907,
    translationGuid: '0bd0c556-8cfc-4771-941f-975bef970d4d',
    auditPointId: 882,
    checkPointNo: '14.1.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 1,
    cp3: 4,
    cp4: 0,
    sortOrder: 234947584,
    score: null
  },
  {
    id: 1908,
    translationGuid: 'd37cec1e-c68b-43e1-bf2b-a1347ce8a3ba',
    auditPointId: 882,
    checkPointNo: '14.1.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 1,
    cp3: 5,
    cp4: 0,
    sortOrder: 234947840,
    score: null
  },
  {
    id: 1909,
    translationGuid: 'f0403461-eede-42c8-b738-ea271d815ab1',
    auditPointId: 882,
    checkPointNo: '14.1.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 1,
    cp3: 6,
    cp4: 0,
    sortOrder: 234948096,
    score: null
  },
  {
    id: 1910,
    translationGuid: '7056290e-d5bf-4097-b806-8d9dc21258b5',
    auditPointId: 882,
    checkPointNo: '14.1.7',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 1,
    cp3: 7,
    cp4: 0,
    sortOrder: 234948352,
    score: null
  },
  {
    id: 1911,
    translationGuid: '08555209-8100-4ca1-b0eb-904f99cb5320',
    auditPointId: 882,
    checkPointNo: '14.1.8',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 1,
    cp3: 8,
    cp4: 0,
    sortOrder: 234948608,
    score: null
  },
  {
    id: 1912,
    translationGuid: '9c145929-e5b1-444c-ab39-84422f5dea7b',
    auditPointId: 883,
    checkPointNo: '14.2.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 2,
    cp3: 1,
    cp4: 0,
    sortOrder: 235012352,
    score: null
  },
  {
    id: 1913,
    translationGuid: 'b2c64fed-f1d3-4938-9f84-6e411df2a5f5',
    auditPointId: 883,
    checkPointNo: '14.2.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 2,
    cp3: 2,
    cp4: 0,
    sortOrder: 235012608,
    score: null
  },
  {
    id: 1914,
    translationGuid: 'd7d4c13b-2bc9-4a05-ad4a-60e878dbafa1',
    auditPointId: 883,
    checkPointNo: '14.2.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 2,
    cp3: 3,
    cp4: 0,
    sortOrder: 235012864,
    score: null
  },
  {
    id: 1915,
    translationGuid: 'af99d47a-1967-47f0-b6ed-d161b2e9b790',
    auditPointId: 883,
    checkPointNo: '14.2.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 2,
    cp3: 4,
    cp4: 0,
    sortOrder: 235013120,
    score: null
  },
  {
    id: 1916,
    translationGuid: '78309292-6e4e-46f2-9f7c-087bcaa47473',
    auditPointId: 884,
    checkPointNo: '14.3.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 3,
    cp3: 1,
    cp4: 0,
    sortOrder: 235077888,
    score: null
  },
  {
    id: 1917,
    translationGuid: '3c0a6bd5-1b62-4403-aef3-c80820ad8103',
    auditPointId: 884,
    checkPointNo: '14.3.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 3,
    cp3: 2,
    cp4: 0,
    sortOrder: 235078144,
    score: null
  },
  {
    id: 1918,
    translationGuid: '96ed29b5-8a0d-457c-a450-3c57dc666530',
    auditPointId: 884,
    checkPointNo: '14.3.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 3,
    cp3: 3,
    cp4: 0,
    sortOrder: 235078400,
    score: null
  },
  {
    id: 1919,
    translationGuid: '970e9d51-874a-495d-850a-98447eae6a1b',
    auditPointId: 884,
    checkPointNo: '14.3.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 3,
    cp3: 4,
    cp4: 0,
    sortOrder: 235078656,
    score: null
  },
  {
    id: 1920,
    translationGuid: '09bc7784-3680-4a35-abd0-7ba1e3ce0c40',
    auditPointId: 885,
    checkPointNo: '14.4.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 4,
    cp3: 1,
    cp4: 0,
    sortOrder: 235143424,
    score: null
  },
  {
    id: 1921,
    translationGuid: '1df99228-b15b-444d-aee6-69b2f31daa47',
    auditPointId: 885,
    checkPointNo: '14.4.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 4,
    cp3: 2,
    cp4: 0,
    sortOrder: 235143680,
    score: null
  },
  {
    id: 1922,
    translationGuid: '1a39cef7-4481-44d8-be3c-f85fbef41566',
    auditPointId: 885,
    checkPointNo: '14.4.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 4,
    cp3: 3,
    cp4: 0,
    sortOrder: 235143936,
    score: null
  },
  {
    id: 1923,
    translationGuid: '44d81c1c-2f40-446b-a24e-be45c92a6e23',
    auditPointId: 885,
    checkPointNo: '14.4.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 4,
    cp3: 4,
    cp4: 0,
    sortOrder: 235144192,
    score: null
  },
  {
    id: 1924,
    translationGuid: '4012b08d-b77f-446e-a689-aa581974c7a9',
    auditPointId: 886,
    checkPointNo: '14.5.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 5,
    cp3: 1,
    cp4: 0,
    sortOrder: 235208960,
    score: null
  },
  {
    id: 1925,
    translationGuid: '6915407c-9513-4da9-8425-e57cc42e77ee',
    auditPointId: 886,
    checkPointNo: '14.5.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 5,
    cp3: 2,
    cp4: 0,
    sortOrder: 235209216,
    score: null
  },
  {
    id: 1993,
    translationGuid: '26932836-0664-4bae-81ec-4464b2c0409a',
    auditPointId: 886,
    checkPointNo: '14.5.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 5,
    cp3: 3,
    cp4: 0,
    sortOrder: 235209472,
    score: null
  },
  {
    id: 1926,
    translationGuid: '42e278b5-639c-4914-a138-99b42ff05d7e',
    auditPointId: 887,
    checkPointNo: '14.6.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 6,
    cp3: 1,
    cp4: 0,
    sortOrder: 235274496,
    score: null
  },
  {
    id: 1927,
    translationGuid: '55641be3-1dd9-4c1f-bdcd-88864c1d0dc6',
    auditPointId: 887,
    checkPointNo: '14.6.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 6,
    cp3: 2,
    cp4: 0,
    sortOrder: 235274752,
    score: null
  },
  {
    id: 1928,
    translationGuid: 'fc32ae50-d9e2-4694-bb7d-5d4a452b8529',
    auditPointId: 887,
    checkPointNo: '14.6.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 6,
    cp3: 3,
    cp4: 0,
    sortOrder: 235275008,
    score: null
  },
  {
    id: 1929,
    translationGuid: 'ec770e7b-0a70-4d4a-ae00-4663077a6552',
    auditPointId: 887,
    checkPointNo: '14.6.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 6,
    cp3: 4,
    cp4: 0,
    sortOrder: 235275264,
    score: null
  },
  {
    id: 1930,
    translationGuid: '48e6dfe5-f231-4603-9472-4313946e38ae',
    auditPointId: 887,
    checkPointNo: '14.6.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 6,
    cp3: 5,
    cp4: 0,
    sortOrder: 235275520,
    score: null
  },
  {
    id: 1931,
    translationGuid: 'c01e123e-1240-4e80-8fca-9561252c4779',
    auditPointId: 887,
    checkPointNo: '14.6.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 6,
    cp3: 6,
    cp4: 0,
    sortOrder: 235275776,
    score: null
  },
  {
    id: 1932,
    translationGuid: 'ba5033be-e7b2-45f1-98f2-a352e302ed4f',
    auditPointId: 888,
    checkPointNo: '14.7.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 7,
    cp3: 1,
    cp4: 0,
    sortOrder: 235340032,
    score: null
  },
  {
    id: 1933,
    translationGuid: '70546713-7d9e-4ab5-8128-39c21a9d372b',
    auditPointId: 888,
    checkPointNo: '14.7.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 7,
    cp3: 2,
    cp4: 0,
    sortOrder: 235340288,
    score: null
  },
  {
    id: 1934,
    translationGuid: 'c46eec7e-caab-4f57-928a-efbe82c7d2d2',
    auditPointId: 888,
    checkPointNo: '14.7.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 7,
    cp3: 3,
    cp4: 0,
    sortOrder: 235340544,
    score: null
  },
  {
    id: 1935,
    translationGuid: '693d0fc5-98c5-4848-a400-f3decf971894',
    auditPointId: 888,
    checkPointNo: '14.7.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 7,
    cp3: 4,
    cp4: 0,
    sortOrder: 235340800,
    score: null
  },
  {
    id: 1936,
    translationGuid: '0177151c-fdc1-4104-b714-b5ff42f60a24',
    auditPointId: 888,
    checkPointNo: '14.7.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 7,
    cp3: 5,
    cp4: 0,
    sortOrder: 235341056,
    score: null
  },
  {
    id: 1937,
    translationGuid: 'bb2e8b48-6b63-4582-8556-ee83a4ddddf9',
    auditPointId: 889,
    checkPointNo: '14.8.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 8,
    cp3: 1,
    cp4: 0,
    sortOrder: 235405568,
    score: null
  },
  {
    id: 1938,
    translationGuid: 'bc2e1927-e784-4c9e-a382-499d8fd8fde6',
    auditPointId: 889,
    checkPointNo: '14.8.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 8,
    cp3: 2,
    cp4: 0,
    sortOrder: 235405824,
    score: null
  },
  {
    id: 1939,
    translationGuid: '3bbc3f7e-f4a2-45a7-91d1-32114a624b7a',
    auditPointId: 889,
    checkPointNo: '14.8.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 8,
    cp3: 3,
    cp4: 0,
    sortOrder: 235406080,
    score: null
  },
  {
    id: 1940,
    translationGuid: '137f4f9f-edd7-4706-95b5-f0249b66a647',
    auditPointId: 889,
    checkPointNo: '14.8.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 8,
    cp3: 4,
    cp4: 0,
    sortOrder: 235406336,
    score: null
  },
  {
    id: 1941,
    translationGuid: '633beba2-cf9c-4572-9482-9e927697125d',
    auditPointId: 889,
    checkPointNo: '14.8.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 8,
    cp3: 5,
    cp4: 0,
    sortOrder: 235406592,
    score: null
  },
  {
    id: 1942,
    translationGuid: '4367ee8d-2877-4399-84e1-729da935b6bc',
    auditPointId: 889,
    checkPointNo: '14.8.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 8,
    cp3: 6,
    cp4: 0,
    sortOrder: 235406848,
    score: null
  },
  {
    id: 1943,
    translationGuid: 'd28c5a7f-8c89-496c-adde-aab6d44ce89e',
    auditPointId: 889,
    checkPointNo: '14.8.7',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 8,
    cp3: 7,
    cp4: 0,
    sortOrder: 235407104,
    score: null
  },
  {
    id: 1944,
    translationGuid: 'd11a9995-e88f-4be9-bb74-2aa76cb65ab3',
    auditPointId: 890,
    checkPointNo: '14.9.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 9,
    cp3: 1,
    cp4: 0,
    sortOrder: 235471104,
    score: null
  },
  {
    id: 1945,
    translationGuid: '07516086-3708-41f7-ac11-adc8598c515c',
    auditPointId: 890,
    checkPointNo: '14.9.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 9,
    cp3: 2,
    cp4: 0,
    sortOrder: 235471360,
    score: null
  },
  {
    id: 1946,
    translationGuid: 'd1421b49-7c25-463e-97d2-6f42cb3b2ba9',
    auditPointId: 890,
    checkPointNo: '14.9.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 9,
    cp3: 3,
    cp4: 0,
    sortOrder: 235471616,
    score: null
  },
  {
    id: 1947,
    translationGuid: 'd0676e71-9f61-4dc8-955e-9a70e9f7f5e8',
    auditPointId: 890,
    checkPointNo: '14.9.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 9,
    cp3: 4,
    cp4: 0,
    sortOrder: 235471872,
    score: null
  },
  {
    id: 1948,
    translationGuid: '264fe3fc-480c-4f3a-99a1-ad2092380110',
    auditPointId: 890,
    checkPointNo: '14.9.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 9,
    cp3: 5,
    cp4: 0,
    sortOrder: 235472128,
    score: null
  },
  {
    id: 1949,
    translationGuid: 'be99b72d-76d6-440b-90bb-111d4b212302',
    auditPointId: 890,
    checkPointNo: '14.9.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 9,
    cp3: 6,
    cp4: 0,
    sortOrder: 235472384,
    score: null
  },
  {
    id: 1950,
    translationGuid: 'd4653d1b-f4c9-472e-9eb5-26aef9a804c8',
    auditPointId: 890,
    checkPointNo: '14.9.7',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 9,
    cp3: 7,
    cp4: 0,
    sortOrder: 235472640,
    score: null
  },
  {
    id: 1951,
    translationGuid: '66a6d0df-8f31-4dd4-95f9-fa06221f9c39',
    auditPointId: 891,
    checkPointNo: '14.10.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 10,
    cp3: 1,
    cp4: 0,
    sortOrder: 235536640,
    score: null
  },
  {
    id: 1952,
    translationGuid: '8151c74b-b597-4f5c-b8fe-25e30235dbe8',
    auditPointId: 891,
    checkPointNo: '14.10.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 10,
    cp3: 2,
    cp4: 0,
    sortOrder: 235536896,
    score: null
  },
  {
    id: 1953,
    translationGuid: '991fcf6a-d56d-49f5-9037-7c3e41886483',
    auditPointId: 891,
    checkPointNo: '14.10.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 10,
    cp3: 3,
    cp4: 0,
    sortOrder: 235537152,
    score: null
  },
  {
    id: 1954,
    translationGuid: 'aa3121cb-ee00-4a4e-973b-d547c0b88141',
    auditPointId: 891,
    checkPointNo: '14.10.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 10,
    cp3: 4,
    cp4: 0,
    sortOrder: 235537408,
    score: null
  },
  {
    id: 1955,
    translationGuid: '6cd1dbe5-15d3-4249-b1cb-3c2b703fdc1c',
    auditPointId: 891,
    checkPointNo: '14.10.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 10,
    cp3: 5,
    cp4: 0,
    sortOrder: 235537664,
    score: null
  },
  {
    id: 1956,
    translationGuid: '8907108d-25ce-4d25-b492-a2f0240ae992',
    auditPointId: 892,
    checkPointNo: '14.11.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 11,
    cp3: 1,
    cp4: 0,
    sortOrder: 235602176,
    score: null
  },
  {
    id: 1957,
    translationGuid: 'e597f08b-874a-4d9b-99cd-763f02ff155a',
    auditPointId: 892,
    checkPointNo: '14.11.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 11,
    cp3: 2,
    cp4: 0,
    sortOrder: 235602432,
    score: null
  },
  {
    id: 1958,
    translationGuid: '98e4b554-1a0c-4f81-b969-242999b7b5c5',
    auditPointId: 892,
    checkPointNo: '14.11.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 11,
    cp3: 3,
    cp4: 0,
    sortOrder: 235602688,
    score: null
  },
  {
    id: 1959,
    translationGuid: '574e18ae-a2a9-49bc-afef-188e839d4929',
    auditPointId: 892,
    checkPointNo: '14.11.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 11,
    cp3: 4,
    cp4: 0,
    sortOrder: 235602944,
    score: null
  },
  {
    id: 1960,
    translationGuid: '90f3de84-a3c8-48e9-bd5b-2a8e73ba9578',
    auditPointId: 892,
    checkPointNo: '14.11.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 11,
    cp3: 5,
    cp4: 0,
    sortOrder: 235603200,
    score: null
  },
  {
    id: 1961,
    translationGuid: 'e5c35c1a-b41e-436b-8160-8e6d7c4c3da5',
    auditPointId: 892,
    checkPointNo: '14.11.6',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 11,
    cp3: 6,
    cp4: 0,
    sortOrder: 235603456,
    score: null
  },
  {
    id: 1962,
    translationGuid: 'f741dc77-9a58-464b-9adf-19a467f3954e',
    auditPointId: 892,
    checkPointNo: '14.11.7',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 11,
    cp3: 7,
    cp4: 0,
    sortOrder: 235603712,
    score: null
  },
  {
    id: 1963,
    translationGuid: '55b1aacc-bfc2-48b9-98ca-3ce977d4a4a1',
    auditPointId: 893,
    checkPointNo: '14.12.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 12,
    cp3: 1,
    cp4: 0,
    sortOrder: 235667712,
    score: null
  },
  {
    id: 1964,
    translationGuid: '16281f03-d5c0-425a-b7b3-892d6d2b4fa0',
    auditPointId: 893,
    checkPointNo: '14.12.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 14,
    cp2: 12,
    cp3: 2,
    cp4: 0,
    sortOrder: 235667968,
    score: null
  },
  {
    id: 1965,
    translationGuid: '7abd1c10-0ea9-4825-ab44-7ed7ba9b53f1',
    auditPointId: 894,
    checkPointNo: '15.1.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 1,
    cp3: 1,
    cp4: 0,
    sortOrder: 251724032,
    score: null
  },
  {
    id: 1966,
    translationGuid: 'f50bf522-99e2-4fdf-95f7-16e65abc734e',
    auditPointId: 894,
    checkPointNo: '15.1.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 1,
    cp3: 2,
    cp4: 0,
    sortOrder: 251724288,
    score: null
  },
  {
    id: 1967,
    translationGuid: 'c9116144-63a9-4f8c-b55b-663498bdb3cc',
    auditPointId: 894,
    checkPointNo: '15.1.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 1,
    cp3: 3,
    cp4: 0,
    sortOrder: 251724544,
    score: null
  },
  {
    id: 1968,
    translationGuid: '531d7995-465a-413b-ac4d-4dfa17bdf756',
    auditPointId: 895,
    checkPointNo: '15.2.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 2,
    cp3: 1,
    cp4: 0,
    sortOrder: 251789568,
    score: null
  },
  {
    id: 1969,
    translationGuid: '3aa8d454-437b-4bba-8ce2-9a74b6a14b08',
    auditPointId: 895,
    checkPointNo: '15.2.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 2,
    cp3: 2,
    cp4: 0,
    sortOrder: 251789824,
    score: null
  },
  {
    id: 1970,
    translationGuid: 'ac9f59a5-cc84-4950-adee-681e44f37b06',
    auditPointId: 895,
    checkPointNo: '15.2.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 2,
    cp3: 3,
    cp4: 0,
    sortOrder: 251790080,
    score: null
  },
  {
    id: 1971,
    translationGuid: '57327c76-e078-42e6-b87f-c1e3db18788e',
    auditPointId: 895,
    checkPointNo: '15.2.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 2,
    cp3: 4,
    cp4: 0,
    sortOrder: 251790336,
    score: null
  },
  {
    id: 1972,
    translationGuid: '3b68e677-e853-4c65-85be-c270837117fb',
    auditPointId: 896,
    checkPointNo: '15.3.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 3,
    cp3: 1,
    cp4: 0,
    sortOrder: 251855104,
    score: null
  },
  {
    id: 1973,
    translationGuid: '9e3aee73-d24c-4a39-8be3-ed6459e9dc32',
    auditPointId: 896,
    checkPointNo: '15.3.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 3,
    cp3: 2,
    cp4: 0,
    sortOrder: 251855360,
    score: null
  },
  {
    id: 1974,
    translationGuid: 'aec9eafb-1494-4b5f-9fa6-171aec923e32',
    auditPointId: 896,
    checkPointNo: '15.3.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 3,
    cp3: 3,
    cp4: 0,
    sortOrder: 251855616,
    score: null
  },
  {
    id: 1975,
    translationGuid: '3943b7f6-6e8c-4e98-bbbc-c6a3b1e284cd',
    auditPointId: 897,
    checkPointNo: '15.4.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 4,
    cp3: 1,
    cp4: 0,
    sortOrder: 251920640,
    score: null
  },
  {
    id: 1976,
    translationGuid: '997375f9-a773-4fb8-a545-1e085ba209d1',
    auditPointId: 897,
    checkPointNo: '15.4.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 4,
    cp3: 2,
    cp4: 0,
    sortOrder: 251920896,
    score: null
  },
  {
    id: 1977,
    translationGuid: 'da5f0f6d-40b0-4b9f-a5bb-a7bef452ae3b',
    auditPointId: 898,
    checkPointNo: '15.5.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 5,
    cp3: 1,
    cp4: 0,
    sortOrder: 251986176,
    score: null
  },
  {
    id: 1978,
    translationGuid: '05e7f5ad-ce0e-4779-aa07-76335cdfca35',
    auditPointId: 898,
    checkPointNo: '15.5.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 5,
    cp3: 2,
    cp4: 0,
    sortOrder: 251986432,
    score: null
  },
  {
    id: 1979,
    translationGuid: '5ecfe3c3-b632-4213-837e-de623e2d49fd',
    auditPointId: 899,
    checkPointNo: '15.6.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 6,
    cp3: 1,
    cp4: 0,
    sortOrder: 252051712,
    score: null
  },
  {
    id: 1980,
    translationGuid: '18e3a5b0-2b82-4a64-bddc-d58f638fd473',
    auditPointId: 899,
    checkPointNo: '15.6.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 6,
    cp3: 2,
    cp4: 0,
    sortOrder: 252051968,
    score: null
  },
  {
    id: 1981,
    translationGuid: 'ec4947a0-6592-4bc2-8e44-cc798d65387f',
    auditPointId: 899,
    checkPointNo: '15.6.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 6,
    cp3: 3,
    cp4: 0,
    sortOrder: 252052224,
    score: null
  },
  {
    id: 1982,
    translationGuid: '9daad8eb-8444-49e9-a1cf-0699fc9006f6',
    auditPointId: 899,
    checkPointNo: '15.6.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 6,
    cp3: 4,
    cp4: 0,
    sortOrder: 252052480,
    score: null
  },
  {
    id: 1983,
    translationGuid: '0e6f2b1c-0b20-4837-83b2-6bfd60f4d371',
    auditPointId: 899,
    checkPointNo: '15.6.5',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 6,
    cp3: 5,
    cp4: 0,
    sortOrder: 252052736,
    score: null
  },
  {
    id: 1984,
    translationGuid: '4ab25c9a-5499-4d42-8b46-2ce32206c94b',
    auditPointId: 900,
    checkPointNo: '15.7.1',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 7,
    cp3: 1,
    cp4: 0,
    sortOrder: 252117248,
    score: null
  },
  {
    id: 1985,
    translationGuid: '63bd5101-a2db-4ed3-9dfb-3399737e51a2',
    auditPointId: 900,
    checkPointNo: '15.7.2',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 7,
    cp3: 2,
    cp4: 0,
    sortOrder: 252117504,
    score: null
  },
  {
    id: 1986,
    translationGuid: 'e7853121-710e-459a-b354-0d172d735878',
    auditPointId: 900,
    checkPointNo: '15.7.3',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 7,
    cp3: 3,
    cp4: 0,
    sortOrder: 252117760,
    score: null
  },
  {
    id: 1987,
    translationGuid: 'e90e7664-1785-4392-9aef-8eecacbf7ea3',
    auditPointId: 900,
    checkPointNo: '15.7.4',
    note: null,
    isPointCheckedYes: null,
    cp1: 15,
    cp2: 7,
    cp3: 4,
    cp4: 0,
    sortOrder: 252118016,
    score: null
  }
].map(cp => ({ ...cp, score: cp.isPointCheckedYes }))
