import { typedFlagsmith } from './features'
import type { FeatureFlag, Features } from './flags.types'

export function getValue<T extends FeatureFlag>(
  props: T
): Features[T]['value'] {
  return typedFlagsmith.getValue(props, {
    json: true
  })
}

export function isFeatureEnabled(feature: FeatureFlag): boolean {
  const hasFeature = typedFlagsmith.hasFeature(feature)

  return hasFeature
}
