import { getIconSize, IconDocumentCheck } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const StyledDocumentList = styled.div`
  background-color: var(-sdds-white);
`
export const StyledRightItem = styled(IconDocumentCheck)`
  width: ${getIconSize('medium')};
  height: ${getIconSize('medium')};
`
