import { CircleProgress } from '@cdab/components'
import {
  DealerInfoBlock,
  FavoriteAuditIconButton
} from '@cdab/scania/qpr/components/atoms'
import { AuditBadge } from '@cdab/scania/qpr/components/molecules'
import type {
  AuditModel,
  DealerModel,
  FavoriteAuditModel
} from '@cdab/scania/qpr/offline/models'
import { Block, Column, Row } from '@cdab/scania/sdds'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { ActionsBlocks } from './actions-block'
import { AuditAttachments } from './attachments'
import { AuditInfo } from './audit-info'
import {
  AuditBadges,
  CircleProgressLabel,
  CircleProgressWrapper,
  Numbers
} from './audit-summary.styles'
import { AuditNotes } from './notes'

export const AuditSummary = observer(
  (props: {
    audit: AuditModel
    dealer: DealerModel
    favoriteAuditData?: FavoriteAuditModel
  }) => {
    const { t } = useTranslation()

    const { audit, dealer, favoriteAuditData } = props

    const mandatoryAuditPoints = audit.auditPoints.filter(ap => ap.isMandatory)
    const completedMandatoryAuditPoints = mandatoryAuditPoints.filter(
      auditPoint => auditPoint.isMandatory && auditPoint.score === true
    ).length

    const developmentAuditPoints = audit.auditPoints.filter(
      auditPoint => !auditPoint.isMandatory
    )
    const approvedDevelopmentAuditPoints = developmentAuditPoints.filter(
      auditPoint => auditPoint.score === true
    ).length
    const completedDevelopmentAuditPoints = developmentAuditPoints.filter(
      auditPoint => auditPoint.score !== null
    ).length

    return (
      <>
        <Row className='sdds-hide-lg'>
          <Column width={12}>
            <AuditBadges>
              <FavoriteAuditIconButton
                favoriteAudit={favoriteAuditData}
                auditId={audit.id}
              />
              {audit.isCertified && <AuditBadge type='certify' />}
              {audit.isReadonly && <AuditBadge type='readonly' />}
            </AuditBadges>
          </Column>
        </Row>

        <Row>
          {/* Main content block*/}
          <Column width={12} lg={8} className='sdds-u-pb1'>
            <h4 className='sdds-u-mt0'>{t('statistics')}</h4>
            <Block color='on-grey'>
              <CircleProgressWrapper>
                <CircleProgress
                  percentage={
                    completedMandatoryAuditPoints /
                    (mandatoryAuditPoints.length || 1)
                  }
                  label={
                    <CircleProgressLabel>
                      {t('completed-audit-points')} (
                      <Numbers>
                        {completedMandatoryAuditPoints}/
                        {mandatoryAuditPoints.length}
                      </Numbers>
                      )
                    </CircleProgressLabel>
                  }
                />
                <CircleProgress
                  percentage={
                    completedDevelopmentAuditPoints /
                    (developmentAuditPoints.length || 1)
                  }
                  label={
                    <CircleProgressLabel>
                      {t('approved-development-points')} (
                      <Numbers>
                        {approvedDevelopmentAuditPoints}/
                        {developmentAuditPoints.length}
                      </Numbers>
                      )
                    </CircleProgressLabel>
                  }
                />
              </CircleProgressWrapper>
            </Block>

            {/* in xs-lg show dealer-info here, otherwise put it in the aside */}
            <DealerInfoBlock
              dealer={dealer}
              className='sdds-show-xs sdds-hide-lg'
            />
            <AuditInfo audit={audit} className='sdds-show-xs sdds-hide-lg' />

            <AuditNotes audit={audit} />
          </Column>

          {/* side content block */}
          <Column width={12} lg={4} className='sdds-u-pb1'>
            <DealerInfoBlock
              dealer={dealer}
              className='sdds-hide-xs sdds-show-lg'
            />
            <AuditInfo audit={audit} className='sdds-hide-xs sdds-show-lg' />
            <AuditAttachments audit={audit} className='sdds-u-mt3' />
            <ActionsBlocks audit={audit} />
          </Column>
        </Row>
      </>
    )
  }
)
