import type {
  ADHService as ADHServiceDefinition,
  CreateADHLinkChatMessageData,
  OnUploadProgressUpdate,
  UploadFileResponse
} from '@cdab/scania/qpr/interactor'
import type {
  ADHAuditWithUnreadMessages,
  ADHAuditWithUnreadMessagesJson,
  ADHLink,
  ADHLinkJson
} from '@cdab/scania/qpr/schema'
import {
  fileDataJsonArrayToFileDataArray,
  fileDataJsonToFileData,
  isValidADHAuditWithUnreadMessages,
  isValidADHDeviationWithUnreadMessages,
  isValidADHLink,
  isValidFileData,
  isValidFileDataArray,
  responseToADHLink,
  type FileData
} from '@cdab/scania/qpr/schema'
import type { PartialWithNull } from '@cdab/type-utils'
import { axiosErrorToError } from '@cdab/utils'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import type { Configuration } from './generated-swagger-client'
import { ADHApi } from './generated-swagger-client'

export class ADHService implements ADHServiceDefinition {
  private readonly ADHApi: ADHApi

  public constructor(
    configuration: Configuration,
    axiosInstance: AxiosInstance
  ) {
    this.ADHApi = new ADHApi(
      configuration,
      configuration.basePath,
      axiosInstance
    )
  }

  public CreateADHLinkChatMessage = async (
    data: CreateADHLinkChatMessageData
  ): Promise<number> => {
    try {
      const response = await this.ADHApi.apiADHChatMessagePost({
        ...data,
        actionPlanItemId: data.actionPlanItemId
      })

      return response.data
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetADHLinkChatFiles = async (linkId: number): Promise<FileData[]> => {
    try {
      const res = await this.ADHApi.apiADHListFilesADHLinkLinkIdGet(linkId)

      const fileDataArray = res.data

      if (!isValidFileDataArray(fileDataArray))
        throw new Error('Invalid file data')

      return fileDataJsonArrayToFileDataArray(fileDataArray)
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetADHLinkFromKey = async (
    ADHLink: string,
    options?: AxiosRequestConfig
  ): Promise<ADHLink> => {
    try {
      const response = await this.ADHApi.apiADHGet(ADHLink, options)

      const link: PartialWithNull<ADHLinkJson> = {
        adhLinkActionPlanItems: response.data.adhLinkActionPlanItems,
        dealerName: response.data.dealerName,
        expiryDate: response.data.expiryDate,
        name: response.data.name,
        id: response.data.id,
        key: response.data.key
      }

      if (!isValidADHLink(link))
        throw new Error(`ADH link ${link.id} is invalid`)

      return responseToADHLink(link)
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public CreateKeyChatMessage = async (
    data: CreateADHLinkChatMessageData
  ): Promise<number> => {
    try {
      const response = await this.ADHApi.apiADHChatMessagePost(data)

      return response.data
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetKeyChatFiles = async (linkId: number): Promise<FileData[]> => {
    try {
      const res = await this.ADHApi.apiADHListFilesADHLinkLinkIdGet(linkId)

      const fileDataArray = res.data

      if (!isValidFileDataArray(fileDataArray))
        throw new Error('Invalid file data')

      return fileDataJsonArrayToFileDataArray(fileDataArray)
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  async UploadChatFile(
    file: File,
    onUploadProgress?: OnUploadProgressUpdate
  ): Promise<UploadFileResponse> {
    const response = await this.ADHApi.apiADHUploadFileADHChatMessagePost(
      file,
      {
        onUploadProgress
      }
    )

    const { fileData: fileDataJson, success } = response.data

    if (success === false) {
      return {
        success: false,
        fileData: null
      }
    }

    if (!isValidFileData(fileDataJson)) throw new Error('Invalid file data')

    return {
      success: true,
      fileData: fileDataJsonToFileData(fileDataJson)
    }
  }

  async DeleteChatFile(fileId: number): Promise<void> {
    await this.ADHApi.apiADHDeleteFileADHChatMessageDelete(fileId)
  }

  async SetChatMessagesAsReadByDealer(actionPlanItemId: number): Promise<void> {
    await this.ADHApi.apiADHChatMessageReadActionPlanItemIdPut(actionPlanItemId)
  }

  async GetLinkActionPlansForUser(): Promise<ADHAuditWithUnreadMessages[]> {
    const response = await this.ADHApi.apiADHLinkDeviationsUserGet()

    const result: ADHAuditWithUnreadMessages[] = []

    for (const audit of response.data.audits ?? []) {
      const deviations: ADHAuditWithUnreadMessages['deviations'] = []

      for (const deviation of audit.deviations ?? []) {
        if (!isValidADHDeviationWithUnreadMessages(deviation)) {
          throw new Error(
            `adh Deviation ${deviation.actionPlanItemId} is invalid`
          )
        }
        deviations.push(deviation)
      }
      const auditData: PartialWithNull<ADHAuditWithUnreadMessagesJson> = {
        auditId: audit.auditId,
        dealerInfo: audit.dealerInfo,
        deviations: deviations
      }

      if (!isValidADHAuditWithUnreadMessages(auditData)) {
        throw new Error(`adh audit ${audit.auditId} is invalid`)
      }

      result.push(auditData)
    }

    return result
  }
}
