import type { AuditGridSettings } from '@cdab/scania/qpr/settings'
import { DEFAULT_AUDIT_GRID_VIEW_SETTING } from '@cdab/scania/qpr/settings'

// This hook will probably load the user's setting from somewhere
// Maybe it will read localStorage
// Maybe it will read from a server
// Who knows? The possibility are endless!
export function useAuditGridSettings(): AuditGridSettings {
  return DEFAULT_AUDIT_GRID_VIEW_SETTING
}
