export enum ListItemType {
  normal,
  header,
  subHeader
}

export interface ListItemProps {
  className?: string
  title: string
  description?: string
  prefix?: string
  onClick?: () => void
  highlighted?: boolean
  type?: ListItemType
}
