import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'
import { parseDate } from '@cdab/utils'
export interface FavoriteAudit {
  auditDate: Date
  auditDescription: string
  auditId: number
  auditor: string
  createdTime: Date
  dealerCity: string
  dealerName: string
  expiryDate: Date | null
  id: number
  isCertified: boolean
  userId: number
}

export interface FavoriteAuditJson {
  auditDate: string
  auditDescription: string
  auditId: number
  auditor: string

  createdTime: string
  dealerCity: string
  dealerName: string
  expiryDate: string | null
  id: number
  isCertified: boolean
  userId: number
}

export const FavoriteAuditSchema: JSONSchemaType<FavoriteAuditJson> = {
  type: 'object',

  properties: {
    auditDate: { type: 'string' },
    auditDescription: { type: 'string' },
    auditId: { type: 'number' },
    auditor: { type: 'string' },
    createdTime: { type: 'string', format: 'iso-date-time' },
    dealerCity: { type: 'string' },
    dealerName: { type: 'string' },
    expiryDate: { type: 'string', nullable: true },
    id: { type: 'number' },
    isCertified: { type: 'boolean' },
    userId: { type: 'number' }
  },

  required: [
    'auditDate',
    'auditDescription',
    'auditId',
    'auditor',
    'createdTime',
    'dealerCity',
    'dealerName',
    'expiryDate',
    'id',
    'isCertified',
    'userId'
  ]
}

const validateFavoriteAudit: ValidateFunction<FavoriteAuditJson> =
  ajv.compile(FavoriteAuditSchema)

export const isValidFavoriteAuditJson = (
  favoriteAudit: unknown
): favoriteAudit is FavoriteAuditJson => {
  return isValidThing<FavoriteAuditJson>(favoriteAudit, validateFavoriteAudit)
}

export function createFavoriteAuditFromJson(
  json: FavoriteAuditJson
): FavoriteAudit {
  return {
    auditDate: parseDate(json.auditDate),
    auditDescription: json.auditDescription,
    auditId: json.auditId,
    auditor: json.auditor,
    createdTime: parseDate(json.createdTime),
    dealerCity: json.dealerCity,
    dealerName: json.dealerName,
    expiryDate: json.expiryDate ? parseDate(json.expiryDate) : null,
    id: json.id,
    isCertified: json.isCertified,
    userId: json.userId
  }
}
