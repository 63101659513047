import cx from 'classnames'
import styled, { css } from 'styled-components'
import type { StyleProps } from './column.styles.types'
import { calculateOffset, calculateSizes } from './column.utils'

export const StyledColumn = styled.div.attrs<StyleProps>(
  ({ className, padding, offset, ...sizes }: StyleProps) => ({
    className: cx(
      className,
      calculateSizes({ ...sizes }),
      offset && calculateOffset(offset),
      {
        'sdds-no-padding': padding === false
      }
    )
  })
)<StyleProps>`
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};

  overflow-y: ${({ scrollY }) =>
    scrollY ? 'scroll' : scrollY === false ? 'hidden' : 'initial'};
  overflow-x: ${({ scrollX }) =>
    scrollX ? 'scroll' : scrollX === false ? 'hidden' : 'initial'};
`
