import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enCommon from './translations/en/common.json'
import enAudit from './translations/en/audit.json'

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',

  // have a common namespace used around the full app
  ns: ['common'],
  defaultNS: 'common',

  debug: false,

  resources: {
    en: {
      audit: enAudit,
      common: enCommon
    }
  }
})

export { i18n }
