import { favoriteAuditsController } from '@cdab/scania/qpr/offline/controllers'
import type { FavoriteAuditModel } from '@cdab/scania/qpr/offline/models'
import { IconHeartInactive, Toast, Tooltip } from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  IconFilledHeart,
  IconWrapper
} from './print-favorite-audit-icon.styles'

export interface FavoriteAuditIconButtonProps {
  favoriteAudit: FavoriteAuditModel | undefined
  auditId: FavoriteAuditModel['auditId']
}

export const FavoriteAuditIconButton = observer(
  function FavoriteAuditIconButton({
    auditId,
    favoriteAudit
  }: FavoriteAuditIconButtonProps) {
    const { t } = useTranslation(['favoriteAudits', 'common'])
    const isFavorite = !!favoriteAudit?.id

    const onClickIsFavorite = async () => {
      if (favoriteAudit?.id !== null) {
        await favoriteAuditsController.Remove(auditId)
        toast(
          <Toast
            type='info'
            headline={capitalizeFirstLetter(t('removed.headline'))}
            subheadline={t('removed.subheadline', {
              ns: 'favoriteAudits'
            })}
          />
        )
      } else {
        await favoriteAuditsController.Add(auditId)
        toast(
          <Toast
            type='success'
            headline={capitalizeFirstLetter(t('added.headline'))}
            subheadline={t('added.subheadline', {
              ns: 'favoriteAudits'
            })}
          />
        )
      }
    }

    return (
      <span onClick={onClickIsFavorite}>
        <Tooltip
          placement='top'
          selector='#favorite'
          text={isFavorite ? t('icon.remove') : t('icon.add')}
        />

        <IconWrapper id='favorite' isFavorite={isFavorite}>
          {isFavorite ? <IconFilledHeart /> : <IconHeartInactive />}
        </IconWrapper>
      </span>
    )
  }
)
