import { AuditNote, EmptyState } from '@cdab/scania/qpr/components'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { pledgeController } from '@cdab/scania/qpr/offline/controllers'
import {
  Accordion,
  AccordionItem,
  Block,
  Column,
  Container,
  Row
} from '@cdab/scania/sdds'
import parse from 'html-react-parser'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {
  isRouteErrorResponse,
  useParams,
  useRouteError,
  useRouteLoaderData
} from 'react-router-dom'
import styled from 'styled-components'
import invariant from 'tiny-invariant'

import { isNumber } from '@cdab/utils'
import type { PledgeLoaderData } from '../pledge-base-view'

const StyledRow = styled(Row)`
  margin-top: var(--sdds-spacing-element-16);
`

const StyledAccordionItem = styled(AccordionItem)`
  > :first-child {
    margin-top: 0;
  }
`

type Params = {
  auditId: string
  pledgeId: string
}

export const GeneralTab = observer(() => {
  const { t } = useTranslation('audit')
  const { auditId: sAuditId } = useParams<Params>()

  const auditId = Number.parseInt(sAuditId ?? '')
  invariant(
    isNumber(auditId),
    `GeneralTab: expected auditId to be number but was: ${auditId}`
  )

  const { pledge, perspectiveGuids, auditIsReadonly } = useRouteLoaderData(
    'pledge'
  ) as PledgeLoaderData

  const onPledgeNoteSaved = (note: string) => {
    pledgeController.SaveNote(auditId, pledge.id, note)
  }

  return (
    <Container>
      <StyledRow>
        <Column width={12} lg={6} fullHeight>
          <Block color='on-grey'>
            <Accordion onWhite>
              <StyledAccordionItem header={t('keyword')}>
                {parse(t(perspectiveGuids.keyword))}
              </StyledAccordionItem>
              <StyledAccordionItem header={t('customer-perspective')}>
                {parse(t(perspectiveGuids.customer))}
              </StyledAccordionItem>
              <StyledAccordionItem header={t('dealer-perspective')}>
                {parse(t(perspectiveGuids.dealer))}
              </StyledAccordionItem>
            </Accordion>
          </Block>
        </Column>
        <Column width={12} lg={6}>
          <Block color='on-grey' disablePadding={false}>
            <AuditNote
              disabled={auditIsReadonly}
              className='sdds-on-white-bg'
              note={pledge.note ?? ''}
              onSaveNote={onPledgeNoteSaved}
            />
          </Block>
        </Column>
      </StyledRow>
    </Container>
  )
})

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <Container
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column width={12} padding={isLg}>
            <EmptyState
              title={t('pledge-not-found')}
              description={t('talk-to-someone')}
            />
          </Column>
        </Row>
      </Container>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
