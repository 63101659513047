import type { Audit } from '@cdab/scania/qpr/schema'
import type { FavoriteAuditModel } from '@cdab/scania/qpr/offline/models'
import { getFavoriteAuditModelCacheData } from '@cdab/scania/qpr/offline/models'
import { restoreFavoriteAuditFromData } from '@cdab/scania/qpr/offline/models'
import { getFavoriteAuditsStore } from './stores'

function getCachedFavoriteAuditData(
  auditId: Audit['id'],
  Store: IDBObjectStore
) {
  return new Promise<FavoriteAuditModel | undefined>((resolve, reject) => {
    const req = Store.get(auditId)

    req.onerror = ev => {
      console.error(
        `An error occurred while getting cached favorite audit with audit id: ${auditId}`,
        ev
      )
      reject(ev)
    }

    req.onsuccess = () => {
      const { result } = req

      if (!result) {
        return resolve(undefined)
      }

      const favoriteAudit = restoreFavoriteAuditFromData(result)

      resolve(favoriteAudit)
    }
  })
}

export async function getCachedFavoriteAudit(auditId: Audit['id']) {
  const store = await getFavoriteAuditsStore('readwrite', false)

  return getCachedFavoriteAuditData(auditId, store)
}

export async function getCachedFavoriteAuditChanges(auditId: Audit['id']) {
  const store = await getFavoriteAuditsStore('readwrite', true)

  return getCachedFavoriteAuditData(auditId, store)
}

function cacheFavoriteAuditData(
  favoriteAudit: FavoriteAuditModel,
  Store: IDBObjectStore
) {
  return new Promise<void>((resolve, reject) => {
    const req = Store.put(getFavoriteAuditModelCacheData(favoriteAudit))
    req.onerror = ev => {
      console.error(
        'An error occurred while caching favorite audit ',
        favoriteAudit,
        ev
      )
      reject()
    }
    req.onsuccess = () => {
      resolve()
    }
  })
}

export async function cacheFavoriteAudit(favoriteAudit: FavoriteAuditModel) {
  const store = await getFavoriteAuditsStore('readwrite', false)

  return cacheFavoriteAuditData(favoriteAudit, store)
}

export async function cacheFavoriteAuditChanges(
  favoriteAudit: FavoriteAuditModel
) {
  const store = await getFavoriteAuditsStore('readwrite', true)

  return cacheFavoriteAuditData(favoriteAudit, store)
}

function deleteCachedFavoriteAuditData(
  auditId: Audit['id'],
  Store: IDBObjectStore
) {
  return new Promise<FavoriteAuditModel | undefined>((resolve, reject) => {
    const req = Store.delete(auditId)

    req.onerror = ev => {
      console.error(
        `An error occurred while deleting cached favorite audit with audit id: ${auditId}`,
        ev
      )
      reject(ev)
    }

    req.onsuccess = () => {
      return resolve(undefined)
    }
  })
}

export async function deleteCachedFavoriteAudit(auditId: Audit['id']) {
  const store = await getFavoriteAuditsStore('readwrite', false)

  return deleteCachedFavoriteAuditData(auditId, store)
}

export async function deleteCachedFavoriteAuditChanges(auditId: Audit['id']) {
  const store = await getFavoriteAuditsStore('readwrite', true)

  return deleteCachedFavoriteAuditData(auditId, store)
}
