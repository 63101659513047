import styled from 'styled-components'
import { getIconSize } from '../utils'
import type { CallToAction } from './empty-screen.types'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: var(--sdds-spacing-element-16);
`

export const IconWrapper = styled.div`
  align-items: start;
  display: flex;
  margin-right: var(--sdds-spacing-element-16);
  width: ${getIconSize('large')};
  width: ${getIconSize('large')};
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1.attrs({
  className: 'sdds-headline-04'
})`
  margin: 0;
`

export const Description = styled.p.attrs({
  className: 'sdds-body-01'
})`
  margin: 0;
  margin-top: var(--sdds-spacing-element-16);
`

export const ActionWrapper = styled.div<Pick<CallToAction, 'type'>>`
  margin-top: ${({ type }) =>
    type === 'link'
      ? 'var(--sdds-spacing-element-16)'
      : 'var(--sdds-spacing-element-48)'};
`
