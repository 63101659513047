import {
  ElasticContentContainer,
  PledgeTabs,
  TabContainer
} from '@cdab/scania/qpr/components'
import {
  getAuditIdFromParams,
  getPledgeIdFromParams
} from '@cdab/scania/qpr/loaders'
import {
  auditsController,
  pledgeController
} from '@cdab/scania/qpr/offline/controllers'
import type { AuditModel, PledgeModel } from '@cdab/scania/qpr/offline/models'
import type { Audit, PledgePerspective } from '@cdab/scania/qpr/schema'
import * as sentry from '@sentry/react'
import parse from 'html-react-parser'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Outlet, useLoaderData } from 'react-router-dom'
import {
  PledgeName,
  PledgeNumber,
  PledgeNumberWrapper
} from './pledge-base-view.styles'

type Guid = PledgePerspective['translationGuid']

export type PledgeLoaderData = {
  pledge: PledgeModel
  perspectiveGuids: {
    keyword: Guid
    dealer: Guid
    customer: Guid
  }
  auditId: Audit['id']
  audit: AuditModel
  auditIsReadonly: boolean
}

const CUSTOMER_PERSPECTIVE = 0
const DEALER_PERSPECTIVE = 1
const KEYWORD_PERSPECTIVE = 2

function getPerspectiveGuidFromPledgePespetives(
  pledgePerspectives: PledgePerspective[],
  value: number
): string {
  const result = pledgePerspectives.find(
    perspective => perspective['perspectiveType'] === value
  )

  if (!result) {
    const error = new Error('Could not find perspective')

    sentry.captureException(error)
    throw error
  }

  return result.translationGuid
}

export async function loader({
  params
}: LoaderFunctionArgs): Promise<PledgeLoaderData> {
  try {
    const pledgeId = getPledgeIdFromParams(params)
    const auditId = getAuditIdFromParams(params)

    const [{ audit }, pledge] = await Promise.all([
      await auditsController.GetAudit(auditId),
      await pledgeController.GetPledge(auditId, pledgeId)
    ])

    const pledgePerspectives = audit.pledgePerspectives.filter(
      perspective => perspective.pledgeId === pledgeId
    )

    const perspectiveGuids = {
      keyword: getPerspectiveGuidFromPledgePespetives(
        pledgePerspectives,
        KEYWORD_PERSPECTIVE
      ),
      dealer: getPerspectiveGuidFromPledgePespetives(
        pledgePerspectives,
        DEALER_PERSPECTIVE
      ),
      customer: getPerspectiveGuidFromPledgePespetives(
        pledgePerspectives,
        CUSTOMER_PERSPECTIVE
      )
    }

    return {
      pledge,
      perspectiveGuids,
      auditId: audit.id,
      audit: audit,
      auditIsReadonly: audit.isReadonly
    }
  } catch (error) {
    console.warn('Error in pledgeIdLoader', error)
    throw new Response('PledgeID is not a number', { status: 400 })
  }
}

export const PledgeBaseView = observer(function PledgeBaseViewPage() {
  const { t } = useTranslation('audit')
  const { pledge } = useLoaderData() as PledgeLoaderData

  return (
    <ElasticContentContainer
      scrollY
      header={
        <>
          <PledgeNumberWrapper>
            <PledgeNumber>{pledge.pledgeNo + '.'}</PledgeNumber>
            <PledgeName>{parse(t(pledge.translationGuid))}</PledgeName>
          </PledgeNumberWrapper>
          <PledgeTabs />
        </>
      }
    >
      <TabContainer>
        <Outlet />
      </TabContainer>
    </ElasticContentContainer>
  )
})
