import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { Outlet } from 'react-router'
import { cssTransition } from 'react-toastify'
import { StyledToastContainer } from './toast-container.styles'
import './toast.css'

const sddsSlide = cssTransition({
  enter: 'slideInUp',
  exit: 'slideOutRight'
})

export const ToastContainer = () => {
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')

  return (
    <>
      <StyledToastContainer
        hideProgressBar
        limit={isLg ? 2 : 1}
        closeButton={false}
        autoClose={5000}
        transition={sddsSlide}
        position='bottom-right'
      />
      <Outlet />
    </>
  )
}
