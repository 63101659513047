import { useEffect } from 'react'
import { useNavigate, useNavigation } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import type { NavigateProps } from 'react-router'

// Should this be moved to headless components library?
export function NavigateAndRestoreSearchParams({
  to,
  replace,
  state,
  relative
}: NavigateProps): null {
  const { state: navigationState } = useNavigation()
  const navigate = useNavigate()
  const [search] = useSearchParams()

  useEffect(() => {
    // Avoid kicking off multiple navigations if we're in the middle of a
    // data-router navigation, since components get re-rendered when we enter
    // a submitting/loading state
    if (navigationState !== 'idle') {
      return
    }

    if (typeof to !== 'string') {
      return navigate(to, { replace, state, relative })
    }

    navigate({
      pathname: to,
      search: search.toString()
    })
  })

  return null
}
