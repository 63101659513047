import type { BackEndADH } from '@cdab/scania/qpr/interactor'
import axios from 'axios'

import { ADHService } from './adh-service'
import { Configuration } from './generated-swagger-client'
import { StorageService } from './storage-service'

export interface BaseOptionsDeviationHandling {
  timeout: number
}

export class ApiClientDeviationHandling implements BackEndADH {
  public StorageService: StorageService
  public ADHService: ADHService

  public constructor(
    apiUrl: string,
    baseOptions?: BaseOptionsDeviationHandling
  ) {
    const config = new Configuration({
      basePath: apiUrl,
      baseOptions
    })

    const instance = axios.create()

    instance.interceptors.response.use(
      response => response,
      async error =>
        // TODO: Add callback handlers, e.g. https://github.com/CongenialData/cplan-api-client/blob/main/src/api.ts

        Promise.reject(error)
    )

    this.StorageService = new StorageService(config, instance)
    this.ADHService = new ADHService(config, instance)
  }
}
