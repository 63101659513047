import { useCreateAudit } from '@cdab/scania/qpr/hooks'
import { Toast } from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import type {
  CreateAuditModalProps,
  ParsedAuditData
} from './audit-modal.types'
import { AuditModal } from './common-audit-modal'

export function AuditCreateModal(props: CreateAuditModalProps) {
  const { dealerId, onClose, open } = props
  const [createAudit, createState] = useCreateAudit()
  const auditIdRef = useRef<number | undefined>(undefined)
  const [t] = useTranslation()

  const onSubmit = async (data: ParsedAuditData) => {
    auditIdRef.current = await createAudit({
      dealerId: dealerId,
      description: data.description,
      extraAuditors: data.extraAuditor || '',
      date: data.date,
      auditTypeId: data.auditTypeId
    })

    if (auditIdRef.current !== undefined) {
      toast(
        <Toast
          type='success'
          headline={capitalizeFirstLetter(t('audit-created'))}
          subheadline={capitalizeFirstLetter(t('audit-created-success'))}
        />
      )
      return true
    }

    return false
  }

  const handleClose = useCallback(() => {
    onClose(auditIdRef.current)
  }, [onClose])

  return (
    <AuditModal
      initialAuditTypeId={1} // If no audit type is provided, default is Certification audit and hence id 1
      header={t('create-audit')}
      disableSave={createState.state === 'loading'}
      onClose={handleClose}
      onSubmit={onSubmit}
      open={open}
      errorMessage={
        createState.state === 'error' ? createState.error : undefined
      }
    />
  )
}
