import { action } from 'mobx'
import invariant from 'tiny-invariant'

import type { Deviation, FileData } from '@cdab/scania/qpr/schema'

import type { AuditModel, DeviationModel } from '../audit-model'
import { mergeObjects } from '../merge-objects'
import { getAudit as getAuditModel } from './audits'
import { syncRemovedFiles } from './utilities'

function getAudit(auditId: AuditModel['id']): AuditModel {
  const audit = getAuditModel(auditId)
  invariant(audit, `No audit in store for ${auditId}`)

  return audit
}

export function getDeviation(
  auditId: AuditModel['id'],
  deviationId: Deviation['id']
): DeviationModel | undefined {
  const audit = getAudit(auditId)

  const deviation = audit.deviations.find(d => d.id === deviationId)

  return deviation
}

export const addDeviation = action(
  (auditId: AuditModel['id'], deviation: Deviation) => {
    const audit = getAudit(auditId)

    const deviationModel: DeviationModel = {
      ...deviation,
      files: [],
      isGettingFiles: false
    }

    audit.deviations.push(deviationModel)
  }
)

export function getLocalDeviation(
  auditId: AuditModel['id'],
  deviationGuid: Deviation['clientGuid']
) {
  const audit = getAudit(auditId)

  const deviation = audit.deviations.find(d => d.clientGuid === deviationGuid)
  invariant(deviation)

  return deviation
}

export const addDeviatoinFiles = action(
  (
    auditId: AuditModel['id'],
    deviationGuid: Deviation['clientGuid'],
    files: FileData[]
  ) => {
    const deviation = getLocalDeviation(auditId, deviationGuid)

    syncRemovedFiles(deviation.files, files)
    files.forEach(file => {
      const fileIndex = deviation.files.findIndex(f => f.id === file.id)

      if (fileIndex === -1) {
        deviation.files.push(file)
      } else {
        mergeObjects(deviation.files[fileIndex], file)
      }
    })
  }
)

export const deleteDeviationFiles = action(
  (
    auditId: AuditModel['id'],
    deviationGuid: Deviation['clientGuid'],
    predicate: (fileData: FileData) => boolean
  ) => {
    const deviation = getLocalDeviation(auditId, deviationGuid)
    const deviationFilesToDelete: FileData[] = []

    deviation.files.forEach(fileData => {
      if (predicate(fileData)) {
        deviationFilesToDelete.push(fileData)
      }
    })

    deviationFilesToDelete.forEach(fileData => {
      const index = deviation.files.indexOf(fileData)

      if (index !== -1) {
        deviation.files.splice(index, 1)
      }
    })
  }
)
