import styled from 'styled-components'
import { ListItemType } from './list-item.types'
import { WithLineLimit } from '@cdab/headless-components'

interface StyledListItemProps {
  clickable: boolean
  selected: boolean
  type: ListItemType
}

function getBackgroundColor(selected: boolean, type: ListItemType): string {
  if (selected) return 'var(--sdds-blue-100)'

  switch (type) {
    case ListItemType.header:
      return 'var(--sdds-grey-400)'
    case ListItemType.subHeader:
      return 'var(--sdds-grey-300)'
    default:
      return 'var(--sdds-grey-50)'
  }
}

export const StyledListItem = styled.li<StyledListItemProps>`
  list-style: none;

  padding: var(--sdds-spacing-layout-16);
  box-sizing: border-box;

  background-color: ${({ selected, type }) =>
    getBackgroundColor(selected, type)};
  border-bottom: var(--sdds-grey-300) solid 1px;

  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  cursor: ${({ clickable }) => (clickable ? `pointer` : `inherit`)};

  overflow: hidden;
`

export const Title = styled.h6.attrs({
  className: 'sdds-headline-06'
})`
  margin: 0;
`

export const Prefix = styled.span.attrs({
  className: 'sdds-headline-06'
})`
  width: var(--sdds-spacing-layout-32);
  margin-right: var(--sdds-spacing-layout-8);
`

export const Content = styled(WithLineLimit)`
  flex-grow: 1;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
