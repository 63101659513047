import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import invariant from 'tiny-invariant'

import {
  ClickableList,
  ElasticContentContainer,
  SortingIconButton
} from '@cdab/scania/qpr/components/atoms'
import type { Dealer } from '@cdab/scania/qpr/schema'
import { Dropdown, type TOption } from '@cdab/scania/sdds'
import { DropdownOption } from '@cdab/scania/sdds'

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 4rem 4rem 0;

  .sdds-dropdown--selected {
    flex: 1;
  }
`

export type SortOption = {
  sortFunction: (a: Dealer, b: Dealer) => number
  displayName: string
  id: string
}

export type DashboardWidgetListProps = {
  emptyMessage: React.ReactNode
  children?: React.ReactNode[]
  className?: string
  sortOptions: SortOption[]
  defaultSortOptionID?: SortOption['id']
  onSelectSorting: (option: SortOption) => void
}

export function ExpiringCertificatesList(props: DashboardWidgetListProps) {
  const { t } = useTranslation('common')
  const [reverse, setReverse] = useState(false)

  const onSelectChange = useCallback(
    (option: TOption) => {
      const selectedSortOption = props.sortOptions.find(
        ({ id }) => option.value === id
      )
      invariant(
        selectedSortOption,
        `No sort option found when looking for ${option.value}`
      )

      props.onSelectSorting(selectedSortOption)
    },
    [props]
  )

  return (
    <ElasticContentContainer
      scrollY
      header={
        <HeaderWrapper>
          <Dropdown
            label={t('sorting')}
            size='sm'
            labelPosition='outside'
            onSelect={onSelectChange}
            placeholder={t('select-sort-order')}
            defaultOption={props.defaultSortOptionID}
          >
            {props.sortOptions.map(sortOption => (
              <DropdownOption
                key={sortOption.id}
                value={sortOption.id}
                text={sortOption.displayName}
              />
            ))}
          </Dropdown>
          <SortingIconButton onClick={() => setReverse(!reverse)} />
        </HeaderWrapper>
      }
    >
      <ClickableList scrollable={false} className={props.className}>
        {reverse ? props.children?.slice().reverse() : props.children}
      </ClickableList>
    </ElasticContentContainer>
  )
}
