import { TdsCard } from '@scania/tegel-react'
import styled from 'styled-components'

export const Bubble = styled(TdsCard)<{ right: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  width: max-content;
  max-width: 350px;
  align-self: ${props => (props.right ? 'flex-end' : 'flex-start')};
  margin-bottom: 4rem;

  .card-body-img {
    max-height: 300px;
  }
`
