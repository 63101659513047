import styled from 'styled-components'
import {
  AuditItemState,
  getAuditItemStateColor
} from '@cdab/scania/qpr/components/atoms'
import {
  getIconSize,
  IconDocument,
  IconInfo,
  IconSmartphone,
  IconStar
} from '@cdab/scania/sdds'

export interface InfoIconProps {
  state: AuditItemState
}

export const StyledIconInfo = styled(IconInfo)<InfoIconProps>`
  color: ${({ state }) =>
    state === AuditItemState.NotComplete
      ? 'var(--sdds-black)'
      : getAuditItemStateColor(state)};
`

export const StarIcon = styled(IconStar)`
  width: ${getIconSize('medium')};
  height: ${getIconSize('medium')};
  color: var(--sdds-black);
`

export const ExtrasRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: var(--sdds-spacing-element-8);
  row-gap: var(--sdds-spacing-element-4);
  margin-top: var(--sdds-spacing-element-4);
`

export const Extra = styled.div.attrs({
  className: 'sdds-detail-05'
})`
  display: flex;
  align-items: flex-end;

  svg {
    width: ${getIconSize('small')};
    height: ${getIconSize('small')};
  }
`

export const DocumentIcon = styled(IconDocument)`
  width: ${getIconSize('medium')};
  height: ${getIconSize('medium')};
  color: var(--sdds-black);
`

export const NoteIcon = styled(IconSmartphone)`
  width: ${getIconSize('medium')};
  height: ${getIconSize('medium')};
  color: var(--sdds-black);
`

export const AuditItemContent = styled.div`
  display: flex;
  flex-direction: column;
`
