import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  padding: 16px 32px 0;
  align-items: baseline;
  flex-grow: 1;
`

export const Title = styled.h3`
  margin: 0 16px 0 0;
`

export const Description = styled.h5`
  margin: 0;
`
