import { getEnvVarsQPR } from '@cdab/scania/qpr/env-vars'
import type { KeycloakConfiguration } from '@cdab/scania/qpr/interactors/api'
import { PROVIDER_API } from './constants'
import type { ProviderBase } from './provider-base'

interface ApiClientParams {
  apiUrl: string
  auditsRealTimeEndpoint: string
  keycloakConfiguration: KeycloakConfiguration
}

export interface ApiProvider extends ApiClientParams, ProviderBase {
  type: typeof PROVIDER_API
}

export function getApiProvider(): ApiProvider {
  const {
    ApiUrl,
    AuditsRealTimeEndpoint,
    keycloakClientId,
    keycloakRealm,
    keycloakUrl
  } = getEnvVarsQPR()

  return {
    type: PROVIDER_API,
    apiUrl: ApiUrl,
    auditsRealTimeEndpoint: AuditsRealTimeEndpoint,
    keycloakConfiguration: {
      keycloakClientId,
      keycloakRealm,
      keycloakUrl
    }
  }
}
