import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'

import { UploadFileButton } from '@cdab/scania/qpr/components/atoms'
import { AttachmentList } from '@cdab/scania/qpr/components/molecules'
import { auditsController } from '@cdab/scania/qpr/offline/controllers'
import type { AuditModel } from '@cdab/scania/qpr/offline/models'
import type { FileData } from '@cdab/scania/qpr/schema'
import { Block, Spinner } from '@cdab/scania/sdds'

type AuditAttachmentsProps = {
  audit: AuditModel
  className?: string
}

type Attachments = {
  localFiles: FileData[]
  uploadedFiles: FileData[]
}

function getAuditAttachments(audit: AuditModel): Attachments {
  const localFiles: Attachments['localFiles'] = []
  const uploadedFiles: Attachments['uploadedFiles'] = []

  audit.files.forEach(file => {
    if (file.isUploaded) {
      uploadedFiles.push(file)
    } else {
      localFiles.push(file)
    }
  })

  return {
    localFiles,
    uploadedFiles
  }
}

export const AuditAttachments = observer(function AuditAttachments({
  audit,
  ...props
}: AuditAttachmentsProps) {
  const onUploadFilesClick = useCallback(
    (fileList: FileList) => {
      Array.from(fileList).forEach(file => {
        auditsController.AddFile(audit.id, file)
      })
    },
    [audit.id]
  )

  const onDeleteAttachment = (fileId: FileData['id']) => {
    // TODO: Add translations
    if (!window.confirm('Are you sure you want to delete this file?')) {
      return
    }

    auditsController.DeleteFile(audit.id, fileId)
  }

  const { localFiles, uploadedFiles } = getAuditAttachments(audit)

  const { isReadonly } = audit

  return (
    <Block color='on-grey' className={props.className}>
      {audit.isGettingFiles === true && <Spinner />}

      {!isReadonly && (
        <UploadFileButton
          className='sdds-u-mb2'
          size='sm'
          variant='primary'
          onUploadFiles={onUploadFilesClick}
        />
      )}

      {!isReadonly && localFiles.length > 0 && (
        <AttachmentList
          color='on-white'
          disableDelete
          files={localFiles}
          // TODO: Add translations
          title='Attachments not yet uploaded'
        />
      )}

      <AttachmentList
        color='on-white'
        disableDelete={isReadonly}
        files={uploadedFiles}
        onDeleteAttachment={onDeleteAttachment}
        hideUploadPrompt={isReadonly}
        // TODO: Add translations
        title='Attachments'
      />
    </Block>
  )
})
