import { v4 as uuidv4 } from 'uuid'
import invariant from 'tiny-invariant'
import { action } from 'mobx'

import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { Audit, Deviation } from '@cdab/scania/qpr/schema'

import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import { getLocalDeviation } from '../store/deviations'
import type { AuditModel } from '../audit-model'

type Type = 'update-deviation'

type Data = Deviation

export type UpdateDeviationOperationData = OperationDataBase<Type, Data>

export class UpdateDeviationOperation extends Operation {
  public data: UpdateDeviationOperationData
  public deviationId: Deviation['id'] // For keeping track of deviation updates in indexeddb

  constructor(
    auditId: Audit['id'],
    value: Data,
    previousValue: Data,
    guid = uuidv4()
  ) {
    super(auditId, guid)
    this.data = {
      type: 'update-deviation',
      value,
      previousValue
    }
    this.deviationId = value.id
  }

  private static updateDeviation = action(
    (audit: AuditModel, deviationData: Deviation) => {
      const deviation = audit.deviations.find(
        ({ clientGuid, id }) =>
          clientGuid === deviationData.clientGuid ||
          (id !== undefined && id === deviationData.id)
      )
      invariant(
        deviation,
        'No deviation in store to update for update deviation operation!'
      )

      Object.assign(deviation, deviationData)
    }
  )

  public ApplyTo = (audit: AuditModel): void => {
    UpdateDeviationOperation.updateDeviation(audit, this.data.value)
  }

  public GetName = () =>
    `${this.data.type}-${this.auditId}-${this.data.value.clientGuid}`

  public RollbackOn(audit: AuditModel): void {
    invariant(
      this.data.previousValue,
      'No previous value when trying to run roll back update deviation operation'
    )
    UpdateDeviationOperation.updateDeviation(audit, this.data.previousValue)
  }

  public SendOperation = async (client: BackEnd) => {
    const deviation = getLocalDeviation(
      this.auditId,
      this.data.value.clientGuid
    )
    invariant(
      deviation.id !== undefined,
      `Cannot send operation for deviation without id. guid = ${this.data.value.clientGuid}`
    )

    const now = new Date()

    const success = await client.AuditsRealTimeService.UpdateDeviation(
      this.data.value.deviationWithoutActions
        ? {
            withActionPlan: false,
            deviation: {
              auditId: this.data.value.auditId,
              callIdentifier: this.guid,
              created: now,
              auditPointId: this.data.value.auditPointId,
              auditPointNumber: this.data.value.auditPointNumber,
              deviation: this.data.value.deviation,
              id: deviation.id
            }
          }
        : {
            withActionPlan: true,
            deviation: {
              auditId: this.data.value.auditId,
              approvalDate: this.data.value.approvalDate?.toISOString() || null,
              created: now,
              expirationDate: (
                this.data.value.expirationDate || now
              ).toISOString(),
              proposedActions: this.data.value.proposedActions || '',
              responsible: this.data.value.responsible || '',
              callIdentifier: this.guid,
              auditPointId: this.data.value.auditPointId,
              auditPointNumber: this.data.value.auditPointNumber,
              deviation: this.data.value.deviation,
              id: deviation.id
            }
          }
    )

    return success
  }
}
