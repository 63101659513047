import * as signalR from '@microsoft/signalr'
import * as sentry from '@sentry/react'
import invariant from 'tiny-invariant'
import { v4 as uuidv4 } from 'uuid'

import { getEnvVarsQPR } from '@cdab/scania/qpr/env-vars'
import type {
  AuditsRealTimeService as AuditsRealTimeServiceDefinition,
  CreateDeviationResponse,
  OnReconnectedCallback,
  RealTimeCallbacks,
  SetNoteData,
  SetNoteDataCommon,
  Unsub,
  UpdateCheckPointScoreAsync,
  UpdateDeviationData
} from '@cdab/scania/qpr/interactor'
import type { Audit, Deviation } from '@cdab/scania/qpr/schema'
import { formatISODate } from '@cdab/utils'

import type {
  AuditPointUpdateDto,
  CheckPointUpdateDto,
  DeviationCreateDto,
  DeviationCreateResponseDto,
  DeviationDeleteDto,
  DeviationUpdateDto,
  DeviationWithoutActionsCreateDto,
  DeviationWithoutActionsCreateResponseDto,
  DeviationWithoutActionsUpdateDto,
  NoteCreateUpdateDto
} from './generated-swagger-client'

export class AuditsRealTimeService implements AuditsRealTimeServiceDefinition {
  private callbacks = new Map<
    Audit['id'],
    Map<RealTimeCallbacks['id'], RealTimeCallbacks>
  >()
  private onReconnectedCallbacks: OnReconnectedCallback[] = []

  private static instance: AuditsRealTimeService | undefined = undefined

  private connection!: signalR.HubConnection

  private readonly retryMs = 5000
  private hasConnectedFirstTime = false

  public constructor(signalRUrl: string) {
    // Turn this into a Singleton, we only ever want one SignalR connection (because I decided so)
    if (AuditsRealTimeService.instance) {
      if (!AuditsRealTimeService.instance.connection) {
        throw new Error('AuditsRealTimeService is in an invalid state!')
      }

      return AuditsRealTimeService.instance
    }

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(signalRUrl)
      .configureLogging(
        getEnvVarsQPR().NODE_ENV === 'development'
          ? signalR.LogLevel.Information
          : signalR.LogLevel.None
      )
      .build()

    AuditsRealTimeService.instance = this

    // Listeners
    this.connection.on(
      'AuditPointCheckBoxChanged',
      (auditUpdate: AuditPointUpdateDto) => {
        const { auditId, callIdentifier, id, isPointCheckedYes } = auditUpdate

        const score =
          typeof isPointCheckedYes === 'undefined' ? null : isPointCheckedYes

        this.handleCallbacks(auditId, callbacks => {
          callbacks.onAuditPointScoreChange(
            auditId,
            id,
            score,
            callIdentifier,
            new Date(auditUpdate.created)
          )
        })
      }
    )
    this.connection.on(
      'CheckPointCheckBoxChanged',
      (checkpointUpdate: CheckPointUpdateDto) => {
        const { created, ...checkpointUpdateData } = checkpointUpdate

        this.handleCallbacks(checkpointUpdate.auditId, callback => {
          callback.onCheckPointScoreChange({
            created: new Date(created),
            ...checkpointUpdateData
          })
        })
      }
    )
    this.connection.on(
      'NoteSaveOrUpdateChanged',
      (noteUpdate: NoteCreateUpdateDto) => {
        const { pledgeId, auditPointId, checkPointId, created, ...rest } =
          noteUpdate
        const common: SetNoteDataCommon = {
          ...rest,
          created: new Date(created)
        }
        let noteData: SetNoteData

        if (typeof pledgeId === 'number') {
          noteData = {
            ...common,
            pledgeId,
            auditPointId: null,
            checkPointId: null
          }
        } else if (typeof auditPointId === 'number') {
          noteData = {
            ...common,
            pledgeId: null,
            auditPointId,
            checkPointId: null
          }
        } else if (typeof checkPointId === 'number') {
          noteData = {
            ...common,
            pledgeId: null,
            auditPointId: null,
            checkPointId
          }
        } else {
          noteData = {
            ...common,
            pledgeId: null,
            auditPointId: null,
            checkPointId: null
          }
        }

        this.handleCallbacks(noteUpdate.auditId, callback => {
          callback.onNoteChange(noteData)
        })
      }
    )
    this.connection.on(
      'DeviationWithoutActionUpdateChanged',
      (deviationUpdate: DeviationWithoutActionsUpdateDto) => {
        const { created, ...data } = deviationUpdate

        this.handleCallbacks(deviationUpdate.auditId, callback => {
          callback.onDeviationUpdate({
            deviation: {
              ...data,
              created: new Date(created)
            },
            withActionPlan: false
          })
        })
      }
    )
    this.connection.on(
      'DeviationUpdateChanged',
      (deviationUpdate: DeviationUpdateDto) => {
        const { created, approvalDate, ...data } = deviationUpdate

        this.handleCallbacks(deviationUpdate.auditId, callback => {
          callback.onDeviationUpdate({
            deviation: {
              ...data,
              created: new Date(created),
              approvalDate: approvalDate || null
            },
            withActionPlan: true
          })
        })
      }
    )
    this.connection.on(
      'DeviationSaveChanged',
      (deviation: DeviationCreateResponseDto) => {
        const { approvalDate, expirationDate, id } = deviation
        const { auditId } = deviation

        if (typeof id !== 'number') throw new Error('No id')

        this.handleCallbacks(auditId, callback => {
          callback.onCreateDeviation(
            deviation.callIdentifier,
            {
              ...deviation,
              clientGuid: uuidv4(),
              deviationWithoutActions: false,
              approvalDate: approvalDate ? new Date(approvalDate) : null,
              expirationDate: new Date(expirationDate),
              id,
              actionPlanId: deviation.actionPlanId
            },
            new Date(deviation.created)
          )
        })
      }
    )
    this.connection.on(
      'DeviationWithoutActionSaveChanged',
      (deviation: DeviationWithoutActionsCreateResponseDto) => {
        this.handleCallbacks(deviation.auditId, callback => {
          callback.onCreateDeviation(
            deviation.callIdentifier,
            {
              ...deviation,
              clientGuid: uuidv4(),
              deviationWithoutActions: true,
              approvalDate: null,
              expirationDate: null,
              proposedActions: null,
              responsible: null,
              actionPlanId: deviation.actionPlanId
            },
            new Date(deviation.created)
          )
        })
      }
    )
    this.connection.on(
      'DeviationDeleteChanged',
      (deviationDeleteDto: DeviationDeleteDto) => {
        const { auditId, callIdentifier, id } = deviationDeleteDto

        this.handleCallbacks(deviationDeleteDto.auditId, callback => {
          callback.onDeviationDelete({
            auditId,
            callIdentifier,
            deviationId: id
          })
        })
      }
    )

    this.connection.onclose(err => {
      console.error('SignalR.onclose: err =', err)
      this.start()
    })

    this.start()
  }

  private handleCallbacks = (
    auditId: Audit['id'],
    callbackFn: (callbacks: RealTimeCallbacks) => void
  ) => {
    this.callbacks.get(auditId)?.forEach(callbackFn)
  }

  public AddOnReconnectedCallback = (callback: OnReconnectedCallback) => {
    if (this.onReconnectedCallbacks.includes(callback)) return

    this.onReconnectedCallbacks.push(callback)
  }

  public UpdateCheckPointScore = async (update: UpdateCheckPointScoreAsync) => {
    const ok: boolean = await this.connection.invoke(
      'UpdateCheckPointCheckBoxAsync',
      update
    )

    return ok
  }

  private onReconnect = () => {
    this.onReconnectedCallbacks.forEach(callback => callback())
  }

  private start = async () => {
    try {
      await this.connection.start()

      if (this.hasConnectedFirstTime) {
        this.onReconnect()
      }

      this.hasConnectedFirstTime = true
    } catch (error) {
      sentry.captureException(error)
      setTimeout(() => {
        this.start()
      }, this.retryMs)
    }
  }

  public UpdateAuditPointScore = async (
    auditId: number,
    auditPointId: number,
    score: boolean | null,
    callIdentifier: string,
    actionCreated: Date
  ) => {
    // See swagger
    const updateParams: AuditPointUpdateDto = {
      auditId,
      id: auditPointId,
      isPointCheckedYes: score,
      callIdentifier,
      created: actionCreated.toJSON()
    }

    const ok: boolean = await this.connection.invoke(
      'UpdateAuditPointCheckBoxAsync',
      updateParams
    )

    return ok
  }

  public ListenTo = async (
    auditId: number,
    callbacks: RealTimeCallbacks
  ): Promise<Unsub> => {
    if (callbacks) {
      if (!this.callbacks.has(auditId)) this.callbacks.set(auditId, new Map())

      const callbacksMap = this.callbacks.get(auditId)
      invariant(callbacksMap)

      if (!callbacksMap.has(callbacks.id)) {
        callbacksMap.set(callbacks.id, callbacks)
      }
    }

    await this.connection.invoke('ListenToAudit', { auditId })

    return () => {
      this.connection.invoke('StopListenToAudit', { auditId })

      if (callbacks) {
        const auditCallbacks = this.callbacks.get(auditId)

        if (!auditCallbacks) return

        if (auditCallbacks.has(callbacks.id)) {
          auditCallbacks.delete(callbacks.id)
        }
      }
    }
  }

  public SetNote = async (setNoteData: SetNoteData) => {
    const data: NoteCreateUpdateDto = {
      ...setNoteData,
      created: setNoteData.created.toJSON()
    }

    const ok: boolean = await this.connection.invoke(
      'SaveOrUpdateNoteAsync',
      data
    )

    return ok
  }

  public async UpdateDeviation(deviationData: UpdateDeviationData) {
    const methodName = deviationData.withActionPlan
      ? 'UpdateDeviationAsync'
      : 'UpdateDeviationWithoutActionsAsync'

    const { created, ...deviation } = deviationData.deviation
    const data: DeviationUpdateDto | DeviationWithoutActionsUpdateDto = {
      ...deviation,
      created: created.toJSON()
    }

    return this.connection.invoke(methodName, data)
  }

  public async CreateDeviation(
    callIdentifier: string,
    deviation: Deviation,
    created: Date
  ): Promise<CreateDeviationResponse> {
    let methodName: string = deviation.deviationWithoutActions
      ? 'SaveDeviationWithoutActionAsync'
      : 'SaveDeviationAsync'

    let createDeviation: DeviationCreateDto | DeviationWithoutActionsCreateDto

    if (deviation.deviationWithoutActions) {
      if (!deviation.auditPointId) {
        throw new Error(
          'Audit Point Id is required when creating a deviation without action plan!'
        )
      }

      methodName = 'SaveDeviationWithoutActionAsync'
      createDeviation = {
        auditPointNumber: deviation.auditPointNumber,
        auditId: deviation.auditId,
        auditPointId: deviation.auditPointId,
        callIdentifier,
        deviation: deviation.deviation,
        created: created.toJSON()
      }
    } else {
      const { approvalDate, expirationDate, ...rest } = deviation

      if (!expirationDate)
        throw new Error(
          'Expiration date is requried when creating deviation with action plan!'
        )

      if (
        !rest.auditId ||
        !rest.auditPointId ||
        !rest.deviation ||
        rest.deviationWithoutActions === null ||
        rest.proposedActions === null ||
        rest.responsible === null
      ) {
        throw new Error(
          'There were missing required fields when creating a deviation!'
        )
      }

      methodName = 'SaveDeviationAsync'
      createDeviation = {
        auditId: rest.auditId,
        auditPointId: rest.auditPointId,
        deviation: rest.deviation,
        proposedActions: rest.proposedActions,
        responsible: rest.responsible,
        auditPointNumber: rest.auditPointNumber,

        callIdentifier,
        created: created.toJSON(),
        expirationDate: formatISODate(expirationDate),
        approvalDate: approvalDate ? formatISODate(approvalDate) : null
      }
    }

    const response: string = await this.connection.invoke(
      methodName,
      createDeviation
    )

    return JSON.parse(response)
  }

  public DeleteDeviation = async (
    callIdentifier: string,
    created: Date,
    auditId: number,
    deviationId: number
  ): Promise<boolean> => {
    const deleteDeviation: DeviationDeleteDto = {
      auditId,
      callIdentifier,
      created: created.toJSON(),
      id: deviationId
    }

    const ok = await this.connection.invoke(
      'DeleteDeviationAsync',
      deleteDeviation
    )

    return ok
  }
}
