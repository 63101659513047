import styled from 'styled-components'

import type { StyleProps } from './audit-grid-item-point.styles.types'

export const H2 = styled.p<StyleProps>`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'inherit')};

  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
`
