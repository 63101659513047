import type { AxiosResponse } from 'axios'

export function validateResponse(response: AxiosResponse, message = ''): void {
  if (response.status >= 200 && response.status < 300) {
    return
  }

  const error = new Error(`${message ? message + ' :' : ''}response.statusText`)
  error.name = response.statusText
  error.message = response.data
  throw error
}
