import styled from 'styled-components'
import type { ElasticContentContainerProps } from './elastic-content-container.interfaces'

export const Container = styled.div<
  Pick<ElasticContentContainerProps, 'overflowHidden'>
>`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: ${({ overflowHidden }) => (overflowHidden ? 'hidden' : 'visible')};
`

export const CapItem = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
`

export const Content = styled.div<{
  scrollX: boolean
  scrollY: boolean
  scrollType: string
}>`
  flex-grow: 1;
  overflow: hidden;
  overflow-y: ${({ scrollY, scrollType }) => (scrollY ? scrollType : 'hidden')};
  overflow-x: ${({ scrollX, scrollType }) => (scrollX ? scrollType : 'hidden')};
`
