import type { BackEnd, UserInfo } from '@cdab/scania/qpr/interactor'

import { ADHService } from './adh-service'
import type { RealTimeMocks } from './audits-real-time-service'
import { MockAuditsRealTimeService } from './audits-real-time-service'
import { AuditsService } from './audits-service'
import { DealersService } from './dealers-service'
import { DeviationsService } from './deviations-service'
import { DocumentService } from './document-service'
import { MarketsService } from './markets-service'
import { MockTranslationsService } from './mock-translations'
import { RemindersService } from './reminders-service'
import { ReportsService } from './reports-service'
import { StorageService } from './storage-service'
import { SystemMessagesService } from './system-messages-service'
import { UserMockService } from './user-service'

export class MockBackEnd implements BackEnd {
  public AuditsRealTimeService: MockAuditsRealTimeService
  public AuditsService: AuditsService
  public DealersService: DealersService
  public DeviationsService: DeviationsService
  public MarketsService: MarketsService
  public SystemMessagesService: SystemMessagesService
  public TranslationsService: MockTranslationsService
  public UserService: UserMockService
  public StorageService: StorageService
  public RemindersService: RemindersService
  public ReportsService: ReportsService
  public DocumentService: DocumentService
  public ADHService: ADHService

  public constructor(
    mockDelayMS: number,
    realTimeMocks: RealTimeMocks | undefined
  ) {
    this.AuditsRealTimeService = new MockAuditsRealTimeService(realTimeMocks)
    this.AuditsService = new AuditsService(mockDelayMS)
    this.DealersService = new DealersService(mockDelayMS)
    this.DeviationsService = new DeviationsService(mockDelayMS)
    this.MarketsService = new MarketsService(mockDelayMS)
    this.SystemMessagesService = new SystemMessagesService(mockDelayMS)
    this.TranslationsService = new MockTranslationsService(mockDelayMS)
    this.UserService = new UserMockService(mockDelayMS)
    this.StorageService = new StorageService(mockDelayMS)
    this.RemindersService = new RemindersService(mockDelayMS)
    this.ReportsService = new ReportsService(mockDelayMS)
    this.DocumentService = new DocumentService(mockDelayMS)
    this.ADHService = new ADHService(mockDelayMS)
  }

  public GetuserInfo = async (): Promise<UserInfo | undefined> => ({
    firstName: 'Dennis',
    lastName: 'Jutemark',
    userId: 200
  })

  public Login = async () => {
    // This doesn't have to do anything
  }

  public IsLoggedIn = () => true

  public Logout = async () => {
    // This doesn't have to do anything
  }
}
