import { CapitalizeFirstLetter } from '@cdab/headless-components'
import { NavigationTabs } from '@cdab/scania/sdds'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { SEARCH_PARAM_OPEN_PLEDGES_KEY } from '../audit-point-tabs'

export const PledgeTabs = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams({
    [SEARCH_PARAM_OPEN_PLEDGES_KEY]: []
  })

  function getLink(path: string) {
    const link = `${path}?${searchParams.toString()}`
    return link
  }

  return (
    <NavigationTabs>
      <NavigationTabs.Tab to={getLink('general')}>
        <CapitalizeFirstLetter>{t('general')}</CapitalizeFirstLetter>
      </NavigationTabs.Tab>
      <NavigationTabs.Tab to={getLink('attachments')}>
        <CapitalizeFirstLetter>{t('attachments')}</CapitalizeFirstLetter>
      </NavigationTabs.Tab>
    </NavigationTabs>
  )
}
