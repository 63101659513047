import { CapitalizeFirstLetter } from '@cdab/headless-components'
import {
  AttachmentList,
  AuditCreateModal,
  AuditReportModal,
  AuditsTable,
  BeforeDeleteModal,
  DealerBadge,
  DealerEditModal,
  DealerServiceCard,
  DealerStatusCard,
  EmptyState,
  PageHeader,
  UploadFileButton
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useUser } from '@cdab/scania/qpr/contexts/user-provider'
import {
  useCssVariableBreakpoint,
  useDeleteDealerFile,
  useUploadDealerFiles
} from '@cdab/scania/qpr/hooks'
import type {
  DealerFile,
  DealerFileData,
  FileData
} from '@cdab/scania/qpr/schema'
import { Roles, type Dealer, type DealerAudit } from '@cdab/scania/qpr/schema'
import { freeMemory } from '@cdab/scania/qpr/utils'
import {
  Breadcrumbs,
  Button,
  Column,
  Container,
  DelayedSpinner,
  Divider,
  EmptyScreen,
  IconMessageInactive,
  InlineTabs,
  Row,
  Toast
} from '@cdab/scania/sdds'
import { capitalizeFirstLetter, fileIsImage, isNumber } from '@cdab/utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isRouteErrorResponse, useRouteError } from 'react-router'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { json, useFetcher, useLoaderData, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import {
  ButtonsWrapper,
  ColumnOtherDocuments,
  Headline,
  HeadlineRow,
  SpinnerWrapper,
  StyledButton
} from './dealer.styles'

export type DealerPageLoaderData = {
  dealer: Dealer
  dealerFiles: DealerFileData[]
  dealerAudits: DealerAudit[]
  dealerId: number
}

export async function loader({
  params
}: LoaderFunctionArgs): Promise<DealerPageLoaderData> {
  const dealerId = Number.parseInt(params['dealerId'] ?? 'NaN')

  if (!isNumber(dealerId)) {
    throw new Response('DealerID is not a number', { status: 400 })
  }

  const client = getClient()
  const dealerAuditsPromise = client.AuditsService.GetAuditsForDealer(dealerId)
  const dealerPromise = client.DealersService.GetDealerById(dealerId).catch(
    () => {
      throw json(
        {
          message: 'Dealer not found'
        },
        { status: 404 }
      )
    }
  )
  const dealerFilesPromise =
    client.StorageService.GetDealerFilesForDealer(dealerId)

  const [dealerAudits, dealer, dealerFiles] = await Promise.all([
    dealerAuditsPromise,
    dealerPromise,
    dealerFilesPromise
  ])
  return {
    dealerAudits,
    dealerFiles,
    dealer,
    dealerId
  }
}

export function DealerPage() {
  const { updateTitles, desktop } = useTitle()
  const { t } = useTranslation(['dealer', 'common'])
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { userData } = useUser()
  const loaderData = useLoaderData() as DealerPageLoaderData
  const fetcher = useFetcher<DealerPageLoaderData>()
  const navigate = useNavigate()

  const dealer = fetcher.data?.dealer ?? loaderData.dealer
  const dealerAudits = fetcher.data?.dealerAudits ?? loaderData.dealerAudits

  const [isNavigating, setIsNavigating] = useState(false)
  const [showDealerEditModal, setShowDealerEditModal] = useState(false)
  const [showCreateAuditModal, setShowCreateAuditModal] = useState(false)
  const [showAuditReportModal, setShowAuditReportModal] = useState(false)

  const [deleteFileName, setDeleteFileName] = useState<string | undefined>(
    undefined
  )
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const [selectedDealerFileId, setSelectedDealerFileId] = useState('')
  const [selectedFilesToUploadState, setSelectedFilesToUploadState] = useState<
    DealerFile[]
  >([])

  const [
    createUploadDealerFilesState,
    uploadFiles,
    uploadedFiles,
    setUploadDealerFilesState
  ] = useUploadDealerFiles(loaderData.dealerId, selectedFilesToUploadState)

  const [
    deleteDealerFileState,
    deleteDealerFile,
    deletedFileIds,
    setDeleteDealerFileState
  ] = useDeleteDealerFile(loaderData.dealerId)

  const files: FileData[] = loaderData.dealerFiles
  const isUploading = createUploadDealerFilesState.status === 'uploading'
  const isCertified = !!dealer.expiryDate && dealer.expiryDate > new Date()
  const canEditExpiryDate =
    dealer.expiryDate !== null &&
    userData.role >= Roles.CoOrdinator &&
    isCertified

  const onEditDealerClick = () => {
    setShowDealerEditModal(true)
  }
  const onCloseDealerEditModal = () => {
    setShowDealerEditModal(false)
  }

  const onCloseAuditModal = (auditId?: number) => {
    setShowCreateAuditModal(false)

    if (auditId) {
      fetcher.load(`/dealer/${loaderData.dealer.id}`)
    }
  }

  const onAuditRowClick = (auditId: string) => {
    setIsNavigating(true)
    if (auditId) {
      navigate(`/audit/${auditId}`)
    }
  }

  const onUploadFilesClick = (fileList: FileList) => {
    const files = Array.from(fileList)

    if (files.length === 0) return

    freeMemory(selectedFilesToUploadState)
    setSelectedFilesToUploadState([])

    const inputFiles: DealerFile[] = Array.from(files).map(file => ({
      file,
      id: uuidv4(),
      url: URL.createObjectURL(file)
    }))

    setSelectedFilesToUploadState(inputFiles)
    setUploadDealerFilesState({ status: 'none', message: null })
  }

  const mapToFilesData = ({
    file,
    id,
    url
  }: {
    file: File
    id: string
    url: string
  }) => {
    const isCurrentFileUploading =
      isUploading && createUploadDealerFilesState.fileId === id

    return {
      fileName: file.name,
      id,
      isImage: fileIsImage(file),
      isUploaded:
        isCurrentFileUploading && createUploadDealerFilesState.progress === 1,
      uploadProgress: isCurrentFileUploading
        ? createUploadDealerFilesState.progress
        : 0,
      url
    }
  }

  const isNotUploaded = (file: FileData) =>
    !uploadedFiles.find(
      uploadedFile => uploadedFile.uploaded_guid_id === file.id
    )

  const isDeleted = (file: FileData) =>
    !deletedFileIds.find(deletedFile => deletedFile === file.id)

  const filesForShow: FileData[] = [
    ...files,
    ...uploadedFiles,
    ...selectedFilesToUploadState.map(mapToFilesData).filter(isNotUploaded)
  ].filter(isDeleted)

  useEffect(() => {
    updateTitles({
      desktop: {
        title: desktop.title
      },
      mobile: {
        title: dealer.name || t('dealer', { ns: 'common' }),
        description: null
      }
    })
  }, [dealer.name, desktop.title, t, updateTitles])

  useEffect(() => {
    return () => {
      freeMemory(selectedFilesToUploadState)
    }
  }, [selectedFilesToUploadState])

  useEffect(() => {
    if (createUploadDealerFilesState.status === 'uploaded') {
      toast(
        <Toast
          type='success'
          headline={t('dealer-attachments-saved', { ns: 'common' })}
          subheadline={createUploadDealerFilesState.message}
        />
      )
      setUploadDealerFilesState({ status: 'success', message: null })
    } else if (createUploadDealerFilesState.status === 'error') {
      toast(
        <Toast
          type={createUploadDealerFilesState.status}
          headline={capitalizeFirstLetter(
            t('message.dealer-attachments-error-saving', { ns: 'dealer' })
          )}
          subheadline={createUploadDealerFilesState.message}
        />
      )
      setUploadDealerFilesState({ status: 'success', message: null })
    } else if (deleteDealerFileState.status === 'success') {
      toast(
        <Toast
          type='success'
          headline={capitalizeFirstLetter(
            t('message.dealer-file-deleted', { ns: 'dealer' })
          )}
          subheadline={capitalizeFirstLetter(deleteDealerFileState.message)}
        />
      )
      setDeleteDealerFileState({ status: 'none', message: null })
    } else if (deleteDealerFileState.status === 'error') {
      toast(
        <Toast
          type={deleteDealerFileState.status}
          headline={capitalizeFirstLetter(
            t('message.dealer-file-error-deleting', { ns: 'dealer' })
          )}
          subheadline={capitalizeFirstLetter(deleteDealerFileState.message)}
        />
      )
    } else if (
      selectedFilesToUploadState.length > 0 &&
      createUploadDealerFilesState.status === 'none'
    ) {
      uploadFiles()
    }
  }, [
    t,
    createUploadDealerFilesState,
    setUploadDealerFilesState,
    selectedFilesToUploadState.length,
    uploadFiles,
    deleteDealerFileState.status,
    deleteDealerFileState.message,
    setDeleteDealerFileState
  ])

  return (
    <>
      <Container
        paddingOnColumns={isLg}
        paddingOnContainer={isLg}
        fluid='push'
        scrollY={true}
      >
        {isLg && (
          <>
            <Row>
              <Column width={12} className='sdds-u-pt2'>
                <Breadcrumbs
                  links={[
                    {
                      text: t('back-to-market', {
                        market: dealer.marketName
                      }),
                      to: `/dealers/${dealer.marketId}`
                    },
                    { text: dealer.name, to: '' }
                  ]}
                />
              </Column>
            </Row>
            <Row>
              <Column width={12}>
                <PageHeader title={`${dealer.name}, ${dealer.city}`} />
              </Column>
            </Row>
          </>
        )}

        <Row>
          <Column width={12} md={6}>
            <HeadlineRow>
              <Headline>
                <CapitalizeFirstLetter>
                  {t('dealer-information')}
                </CapitalizeFirstLetter>
              </Headline>
              <DealerBadge certificationStatus={dealer.certificationStatus} />
            </HeadlineRow>
            <DealerStatusCard
              dealer={dealer}
              canEditExpiryDate={canEditExpiryDate}
              onEditDealerClick={onEditDealerClick}
            />
          </Column>
          <Column width={12} md={6}>
            <Headline>{t('services-and-sales')}</Headline>
            <DealerServiceCard dealer={dealer} />
          </Column>
        </Row>

        <Row>
          <Column padding={isLg} width={12} className='sdds-u-pt2'>
            <InlineTabs modeVariant='secondary'>
              <InlineTabs.Content
                name={capitalizeFirstLetter(t('audits', { ns: 'common' }))}
              >
                {dealerAudits.length === 0 ? (
                  <EmptyScreen.CenteredWrapper>
                    <EmptyScreen
                      icon={<IconMessageInactive />}
                      title={t('no-audits-found', { ns: 'common' })}
                      description={t('no-audits-found-description', {
                        ns: 'common'
                      })}
                      callToAction={{
                        type: 'button',
                        component: (
                          <Button
                            type='primary'
                            size='sm'
                            onClick={() => setShowCreateAuditModal(true)}
                            text={t('create-audit', { ns: 'common' })}
                          />
                        )
                      }}
                    />
                  </EmptyScreen.CenteredWrapper>
                ) : (
                  <>
                    <Button
                      className='sdds-u-mb2'
                      type='primary'
                      size='sm'
                      onClick={() => setShowCreateAuditModal(true)}
                      text={t('create-audit', { ns: 'common' })}
                    />
                    <StyledButton
                      className='sdds-u-mb2'
                      type='secondary'
                      size='sm'
                      onClick={() => setShowAuditReportModal(true)}
                      text={t('empty-qpr-dos', {
                        ns: 'common',
                        dosVersion: '5'
                      })}
                    />
                    {isNavigating || fetcher.state === 'loading' ? (
                      <DelayedSpinner />
                    ) : (
                      <AuditsTable
                        audits={dealerAudits}
                        onRowClick={onAuditRowClick}
                      />
                    )}
                  </>
                )}
              </InlineTabs.Content>
              <InlineTabs.Content
                name={capitalizeFirstLetter(t('attachments', { ns: 'common' }))}
              >
                <Row>
                  <Column width={6}>
                    <ButtonsWrapper>
                      <UploadFileButton
                        size='sm'
                        onUploadFiles={onUploadFilesClick}
                      />
                      {isUploading && (
                        <SpinnerWrapper>
                          <DelayedSpinner size='sm' className='sdds-u-ml3' />
                        </SpinnerWrapper>
                      )}
                    </ButtonsWrapper>
                  </Column>
                </Row>

                <Divider type='light' className='sdds-u-mb1 sdds-u-mt2' />

                <Row>
                  <Column width={12} lg={6} padding={isLg}>
                    <AttachmentList
                      color='on-white'
                      files={filesForShow.filter(f => f.isImage)}
                      onDeleteAttachment={(id: string) => {
                        setDeleteFileName(
                          filesForShow.find(f => f.id === id)?.fileName ??
                            undefined
                        )
                        setOpenDeleteModal(true)
                        setSelectedDealerFileId(id)
                      }}
                      disableDelete={isUploading}
                      fileType='images'
                      title={t('images', { ns: 'common' })}
                    />
                  </Column>
                  <ColumnOtherDocuments width={12} lg={6} padding={isLg}>
                    <AttachmentList
                      color='on-white'
                      files={filesForShow.filter(f => !f.isImage)}
                      onDeleteAttachment={(id: string) => {
                        setDeleteFileName(
                          filesForShow.find(f => f.id === id)?.fileName ??
                            undefined
                        )
                        setOpenDeleteModal(true)
                        setSelectedDealerFileId(id)
                      }}
                      disableDelete={isUploading}
                      fileType='other'
                      title={t('other', { ns: 'common' })}
                    />
                  </ColumnOtherDocuments>
                </Row>
              </InlineTabs.Content>
            </InlineTabs>
          </Column>
        </Row>
      </Container>
      <DealerEditModal
        open={showDealerEditModal}
        onClose={onCloseDealerEditModal}
        dealer={dealer}
      />
      <AuditCreateModal
        open={showCreateAuditModal}
        onClose={onCloseAuditModal}
        dealerId={loaderData.dealer.id}
      />
      <BeforeDeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onSubmit={() => {
          setOpenDeleteModal(false)
          deleteDealerFile(selectedDealerFileId)
        }}
        objectName={deleteFileName}
      />
      <AuditReportModal
        open={showAuditReportModal}
        onClose={() => setShowAuditReportModal(false)}
      />
    </>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const navigate = useNavigate()
  const error = useRouteError()

  function goToMarkets() {
    navigate('/dealers')
  }

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <Container
          fluid='normal'
          paddingOnColumns={!isLg}
          paddingOnContainer={!isLg}
        >
          <Row>
            <Column width={12} padding={!isLg}>
              <EmptyState
                title={t('dealer-not-found')}
                description={t('talk-to-someone')}
              >
                <Button onClick={goToMarkets} text={t('go-to-dealers')} />
              </EmptyState>
            </Column>
          </Row>
        </Container>
      )
    }
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
