import type { Dealer } from '@cdab/scania/qpr/schema'
import { action, toJS } from 'mobx'
import invariant from 'tiny-invariant'
import type { DealerModel } from './store'
import { dealersStore } from './store'

function mergeDealers(target: DealerModel, source: DealerModel) {
  invariant(
    target.VERSION === source.VERSION,
    `Tried to merge object on different versions!`
  )

  target.auditDate = source.auditDate
  target.bus = source.bus
  target.busSales = source.busSales
  target.city = source.city
  target.certificationStatus = source.certificationStatus
  target.dealerId = source.dealerId
  target.engine = source.engine
  target.engineSales = source.engineSales
  target.expiryDate = source.expiryDate
  target.hasDeviations = source.hasDeviations
  target.id = source.id
  target.name = source.name
  target.partsSales = source.partsSales
  target.truck = source.truck
  target.truckSales = source.truckSales
  target.marketId = source.marketId
  target.marketName = source.marketName
}

export const addDealer = action((dealer: DealerModel): DealerModel => {
  let dealerModel = dealersStore[dealer.id]

  if (dealerModel) {
    mergeDealers(dealerModel, dealer)
  } else {
    dealersStore[dealer.id] = {
      ...dealer,
      VERSION: 1
    }
    dealerModel = dealersStore[dealer.id]
  }

  invariant(dealerModel, `No dealer in store after just adding it!`)
  return dealerModel
})

export function getDealerModel(dealerId: Dealer['id']) {
  const dealer = dealersStore[dealerId]

  return dealer
}

// FIXME: Migrate dealer model to its own module when we need to change/add/remove fields
export function restoreDealerFromData(dealer: DealerModel): DealerModel {
  // TODO: Look at VERSION and migrate if necessary
  return {
    ...dealer,
    VERSION: dealer.VERSION ?? 1 // With old cached dealers we might not have a VERSION!
  }
}

export function getDealerModelCacheData(dealer: DealerModel) {
  return toJS(dealer)
}

export function toDealerModel(dealer: Dealer): DealerModel {
  return {
    ...dealer,
    VERSION: 1
  }
}
