import { createZodParser, isISODate, parseDate } from '@cdab/utils'
import { z } from 'zod'

export const CreateAuditSchema = z.object({
  date: z
    .string()
    .refine(d => isISODate(d), 'Not a valid date')
    .transform(d => parseDate(d)),
  description: z.string().min(1),
  extraAuditor: z.string().optional(),
  auditTypeId: z.number()
})

export const auditFormParser = createZodParser(CreateAuditSchema)
