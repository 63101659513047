import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'

export type SetChatMessageAsReadByAuditorFileStatus =
  | 'success'
  | 'error'
  | 'loading'
  | 'none'

export function useSetChatMessageAsReadByAuditor() {
  const client = useBackend()

  const handleClickSetChatMessageAsReadByAuditor = async (
    auditPlanItemId: number
  ) => {
    try {
      await client.AuditsService.SetChatMessagesAsReadByAuditor(auditPlanItemId)
    } catch (e) {
      console.error(e)
    }
  }

  return [handleClickSetChatMessageAsReadByAuditor] as const
}
