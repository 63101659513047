import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { capitalize } from '../utils'
import type { TextareaProps } from './textarea.types'

export const Textarea = forwardRef(function Textarea(
  props: TextareaProps,
  ref
) {
  const {
    autoFocus,
    cols,
    className,
    disabled,
    labelPosition,
    maxLength,
    onBlur,
    onChange,
    name,
    rows,
    state,
    value,
    autoResize = false
  } = props
  const helper = props.helper ? capitalize(props.helper) : ''
  const label = props.label ? capitalize(props.label) : undefined
  const placeholder = props.placeholder
    ? capitalize(props.placeholder)
    : undefined

  const textAreaRef = useRef<HTMLTextAreaElement>()
  useImperativeHandle(ref, () => textAreaRef.current)

  useEffect(() => {
    const textarea = textAreaRef.current?.shadowRoot?.querySelector('textarea')
    if (!textarea || !autoResize) return

    if (textarea.clientHeight < textarea.scrollHeight)
      textarea.style.height = textarea.scrollHeight + 1 + 'px'
  })

  return (
    <sdds-textarea
      ref={textAreaRef}
      autofocus={autoFocus}
      cols={cols}
      className={className}
      disabled={disabled}
      helper={helper}
      label={label}
      labelPosition={labelPosition}
      maxlength={maxLength}
      name={name}
      onInput={onChange}
      rows={rows}
      placeholder={placeholder}
      state={state}
      value={value}
      onBlur={onBlur}
    ></sdds-textarea>
  )
})
