import { useEffect, useState } from 'react'
import { Spinner } from './spinner'
import type { SpinnerSize } from './spinner/spinner.types'

const SHOW_SPINNER_AFTER_MS = 2000

export interface DelayedSpinnerProps {
  className?: string
  size?: SpinnerSize
}

export const DelayedSpinner = ({
  className,
  size = 'lg'
}: DelayedSpinnerProps): JSX.Element | null => {
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    const timerId = setTimeout(() => {
      setShowSpinner(true)
    }, SHOW_SPINNER_AFTER_MS)

    return () => {
      clearTimeout(timerId)
    }
  }, [])

  return showSpinner ? <Spinner size={size} className={className} /> : null
}
