import { CapitalizeFirstLetter } from '@cdab/headless-components'
import { useIsDos5 } from '@cdab/scania/qpr/hooks'
import type { AuditModel } from '@cdab/scania/qpr/offline/models'
import { Accordion, AccordionItem, Block } from '@cdab/scania/sdds'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {
  NoteContainer,
  NoteContent,
  NoteContentContainer,
  NotePrefix,
  StyledNoteRow
} from './audit-summary.styles'

type NoteData = {
  id: string
  notePrefix: string
  note: string
  linkTo: string
}

type NoteRowProps = Omit<NoteData, 'id'> & {
  isDOS5: boolean
}

const NoteRow = observer(function NoteRow(props: NoteRowProps) {
  return (
    <StyledNoteRow to={props.isDOS5 ? props.linkTo : ''}>
      <NotePrefix>
        <CapitalizeFirstLetter>{props.notePrefix}</CapitalizeFirstLetter>
      </NotePrefix>
      <NoteContentContainer>
        <NoteContent limitRows={2}>{props.note}</NoteContent>
      </NoteContentContainer>
    </StyledNoteRow>
  )
})

export const AuditNotes = observer(({ audit }: { audit: AuditModel }) => {
  const { t } = useTranslation('common')
  const isDos5 = useIsDos5(audit)

  const notes: NoteData[] = []

  if (audit.note) {
    notes.push({
      id: audit.id.toString(),
      linkTo: '',
      note: audit.note,
      notePrefix: `${t('audit')} ${t('note')}`
    })
  }

  audit.pledges.forEach(pledge => {
    if (pledge.note) {
      notes.push({
        linkTo: `../points/pledge/${pledge.id}?openPledges=${pledge.id}`,
        note: pledge.note,
        notePrefix: `${t('pledge')} ${pledge.pledgeNo}`,
        id: pledge.id.toString()
      })
    }

    audit.auditPoints
      .filter(ap => ap.pledgeId === pledge.id)
      .forEach(auditPoint => {
        if (auditPoint.note) {
          notes.push({
            linkTo: `../points/auditpoint/${auditPoint.id}?openPledges=${auditPoint.pledgeId}`,
            note: auditPoint.note,
            notePrefix: `${t('audit-point')} ${auditPoint.auditPointNo}`,
            id: auditPoint.id.toString()
          })
        }

        audit.checkPoints
          .filter(
            checkPoint =>
              checkPoint.auditPointId === auditPoint.id && !!checkPoint.note
          )
          .forEach(checkPoint => {
            if (checkPoint.note) {
              notes.push({
                linkTo: `../points/auditpoint/${checkPoint.auditPointId}/checkpoints`,
                note: checkPoint.note,
                notePrefix: `${t('checkpoint')} ${checkPoint.checkPointNo}`,
                id: checkPoint.id.toString()
              })
            }
          })
      })
  })

  return (
    <Block color='on-grey' className='sdds-u-mt3'>
      <Accordion>
        <AccordionItem header={t('notes')} disabled={notes.length === 0}>
          <NoteContainer>
            {notes.map(note => (
              <NoteRow
                isDOS5={isDos5}
                key={note.id}
                linkTo={note.linkTo}
                note={note.note}
                notePrefix={note.notePrefix}
              />
            ))}
          </NoteContainer>
        </AccordionItem>
      </Accordion>
    </Block>
  )
})
