/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import type {
  CreateADHLinkData,
  CreateAuditData,
  AuditsService as IAuditsService,
  UpdateAuditData
} from '@cdab/scania/qpr/interactor'
import {
  MockAudit12345,
  MockAudit321,
  MockDealerAudits
} from '@cdab/scania/qpr/mockdata'
import type {
  ADHLink,
  Audit,
  AuditPointNumber,
  AuditType,
  ChatMessage,
  CheckPointNumber,
  DealerAudit,
  FavoriteAudit,
  FavoriteAuditSingle
} from '@cdab/scania/qpr/schema'
import { wait } from '@cdab/utils'
import type { AxiosRequestConfig } from 'axios'

const mockAuditsData: Audit[] = [MockAudit12345, MockAudit321]

export class AuditsService implements IAuditsService {
  private readonly mockDelayMS: number

  public constructor(mockDelayMS: number) {
    this.mockDelayMS = mockDelayMS
  }
  GetAuditTypes(): Promise<AuditType[]> {
    throw new Error('Method not implemented.')
  }
  SetChatMessagesAsReadByAuditor(actionPlanItemId: number): Promise<void> {
    throw new Error('Method not implemented.')
  }
  GetADHChatMessagesForDeviation(
    actionPlanItemId: number
  ): Promise<ChatMessage[] | undefined> {
    throw new Error('Method not implemented.')
  }
  GetADHLinksForAudit(auditId: number): Promise<ADHLink[]> {
    throw new Error('Method not implemented.')
  }
  CreateADHLink(data: CreateADHLinkData): Promise<ADHLink> {
    throw new Error('Method not implemented.')
  }
  DeleteADHLink(linkId: number): Promise<void> {
    throw new Error('Method not implemented.')
  }
  UpdateAudit(
    auditId: number,
    update: UpdateAuditData,
    callIdentifier: string
  ): Promise<void> {
    if (update) {
      return Promise.resolve()
    }

    if (auditId) {
      return Promise.resolve()
    }

    if (callIdentifier) {
      return Promise.resolve()
    }

    return Promise.resolve()
  }

  SetAuditNote(
    auditId: number,
    content: string,
    callIdentifier: string
  ): Promise<void> {
    if (auditId) {
      throw new Error('Method not implemented.')
    }

    if (content) {
      throw new Error('Method not implemented.')
    }

    if (callIdentifier) {
      throw new Error('Method not implemented.')
    }

    throw new Error('Method not implemented.')
  }

  public GetAudit = async (auditId: number): Promise<Audit> => {
    await wait(this.mockDelayMS)

    const audit = mockAuditsData.find(a => a.id === auditId)

    if (!audit) throw new Error('No audit found')

    return audit
  }

  public GetAuditsForDealer = async (
    dealerId: number
  ): Promise<DealerAudit[]> => {
    await wait(this.mockDelayMS)

    const dealerAudits = MockDealerAudits

    if (!dealerAudits) throw new Error('No audits found')

    return dealerAudits
  }

  CreateAudit(data: CreateAuditData): Promise<number> {
    if (data) return Promise.resolve(0)

    return Promise.resolve(0)
  }

  GetAuditPointNumbersForAudit(auditId: number): Promise<AuditPointNumber[]> {
    if (auditId) return Promise.resolve([])

    return Promise.resolve([])
  }

  CertifyAudit(auditId: number): Promise<string> {
    if (auditId) throw new Error('Method not implemented.')

    throw new Error('Method not implemented.')
  }

  public GetFavoriteAuditsForUser = async (
    userId: number
  ): Promise<FavoriteAudit[]> => {
    if (userId) throw new Error('Method not implemented.')

    throw new Error('Method not implemented.')
  }

  public GetFavoriteAuditForUserAndAudit = async (
    userId: number,
    auditId: number
  ): Promise<FavoriteAuditSingle> => {
    if (userId) throw new Error('Method not implemented.')
    if (auditId) throw new Error('Method not implemented.')

    throw new Error('Method not implemented.')
  }

  public CreateFavoriteAudit = async (auditId: number): Promise<number> => {
    if (auditId) throw new Error('Method not implemented.')

    throw new Error('Method not implemented.')
  }
  public DeleteFavoriteAudit = async (
    favoriteAuditId: number
  ): Promise<void> => {
    if (favoriteAuditId) throw new Error('Method not implemented.')

    throw new Error('Method not implemented.')
  }
  public GetAuditPointNumbers = async (
    dosVersion: number
  ): Promise<AuditPointNumber[]> => {
    if (dosVersion) throw new Error('Method not implemented.')

    throw new Error('Method not implemented.')
  }
  public GetCheckPointNumbers = async (
    dosVersion: number
  ): Promise<CheckPointNumber[]> => {
    if (dosVersion) throw new Error('Method not implemented.')

    throw new Error('Method not implemented.')
  }

  public CreateCheckpoint = async (
    auditPointId: number,
    dosVersion: number,
    baseTranslation: string,
    options?: AxiosRequestConfig
  ): Promise<void> => {
    throw new Error('Method not implemented.')
  }
}
