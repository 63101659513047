import { Block, Button, Card, IconEdit } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const StyledIconEditDeviation = styled(IconEdit)`
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  color: black;
  margin-left: 1rem;
`
export const StyledEditIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
`
export const StyledButton = styled(Button)`
  margin-left: 2rem;
`

export const StyledButtonBack = styled(Button)`
  margin-top: 2rem;
  margin-left: 4rem;
  margin-bottom: 1rem;
`

export const EmptyScreenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
`

export const TitleFilesList = styled.h4.attrs({
  className: 'sdds-headline-04 sdds-u-mb3'
})`
  margin: 0;
`

export const StyledBlock = styled(Block)`
  display: flex;
  flex-direction: column;
`
export const FormRow = styled.div.attrs({
  className: 'sdds-u-mb1'
})``

export const StyledCard = styled(Card)`
  width: 100%;
`
