import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import type { DeviationsService as DeviationsServiceDefinition } from '@cdab/scania/qpr/interactor'
import type {
  Deviation,
  DeviationJson,
  ExpiringDeviation,
  ExpiringDeviationJson
} from '@cdab/scania/qpr/schema'
import {
  createDeviationFromJson,
  createExpiringDeviationFromJson,
  isValidDeviationJson,
  isValidExpiringDeviationJson
} from '@cdab/scania/qpr/schema'
import type { PartialWithNull } from '@cdab/type-utils'
import { axiosErrorToError } from '@cdab/utils'

import type { Configuration } from './generated-swagger-client'
import { DeviationsApi } from './generated-swagger-client'

export class DeviationsService implements DeviationsServiceDefinition {
  private readonly deviationsApi: DeviationsApi

  public constructor(
    configuration: Configuration,
    axiosInstance: AxiosInstance
  ) {
    this.deviationsApi = new DeviationsApi(
      configuration,
      configuration.basePath,
      axiosInstance
    )
  }

  public GetDeviation = async (
    auditId: number,
    deviationId: number,
    options?: AxiosRequestConfig
  ): Promise<Deviation | undefined> => {
    try {
      const deviations = await this.GetDeviations(auditId, options)

      return deviations.find(d => d.id === deviationId)
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetDeviations = async (
    auditId: number,
    options?: AxiosRequestConfig
  ): Promise<Deviation[]> => {
    try {
      const response = await this.deviationsApi.apiDeviationsAuditAuditIdGet(
        auditId,
        options
      )

      if (response.status !== 200)
        throw new Error(
          `Error getting deviation: (${response.status})${response.statusText}`
        )

      const deviations: Deviation[] = []

      for (const deviation of response.data.deviations ?? []) {
        const deviationData: PartialWithNull<DeviationJson> = {
          approvalDate: deviation.approvalDate ?? null,
          auditPointId: deviation.auditPointId,
          auditPointNo: deviation.auditPointNo,
          deviation: deviation.deviation,
          deviationWithoutActions: deviation.deviationWithoutActions,
          expirationDate: deviation.expirationDate ?? null,
          id: deviation.id,
          proposedActions: deviation.proposedActions,
          responsible: deviation.responsible,
          auditId
        }

        if (!isValidDeviationJson(deviationData))
          throw new Error(`Deviation ${deviation.id} is invalid`)

        deviations.push(createDeviationFromJson(deviationData as DeviationJson))
      }

      return deviations
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetExpiringDeviationsForUser = async (
    includeTestMarket?: boolean,
    options?: AxiosRequestConfig
  ): Promise<ExpiringDeviation[]> => {
    try {
      const response = await this.deviationsApi.apiDeviationsUserGet(
        includeTestMarket,
        options
      )

      if (response.status !== 200)
        throw new Error(
          `Error getting expiring deviation: (${response.status})${response.statusText}`
        )

      const deviations: ExpiringDeviation[] = []

      for (const expiringDeviation of response.data.deviations ?? []) {
        const expiringDeviationData: PartialWithNull<ExpiringDeviationJson> = {
          auditDate: expiringDeviation.auditDate,
          auditId: expiringDeviation.auditId,
          dealerCity: expiringDeviation.dealerCity,
          dealerName: expiringDeviation.dealerName,
          expiryDate: expiringDeviation.expiryDate ?? null,
          id: expiringDeviation.id,
          hasDeviations: expiringDeviation.hasDeviations
        }

        if (!isValidExpiringDeviationJson(expiringDeviationData))
          throw new Error(
            `Expiring deviation ${expiringDeviation.id} is invalid`
          )

        deviations.push(
          createExpiringDeviationFromJson(
            expiringDeviationData as ExpiringDeviationJson
          )
        )
      }

      return deviations
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }
}
