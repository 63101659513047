import { getEnvVarsQPR } from '@cdab/scania/qpr/env-vars'
import type { ADHLink, Deviation } from '@cdab/scania/qpr/schema'
import { sortFnByAuditPointNo } from '@cdab/scania/qpr/utils'
import { Toast } from '@cdab/scania/sdds'
import { capitalizeFirstLetter, removeHtmlTagsFromString } from '@cdab/utils'
import { TdsButton, TdsLink, TdsModal, TdsTooltip } from '@scania/tegel-react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  StyledDeviationsList,
  StyledLinkRow,
  StyledTextfieldWrapper
} from './adh-link-details-form.styles'
import type { ADHLinkDetailsFormProps } from './adh-link-details-form.types'

const filterDeviations = (
  deviations: Deviation[],
  linkData: ADHLink | undefined
) => {
  return deviations
    .filter(deviation => deviation.id !== undefined)
    .filter(deviation => deviation.deviationWithoutActions === false)
    .filter(
      deviation =>
        !!linkData?.actionPlanItems?.find(
          api => api.actionPlanItemId === deviation.id
        )
    )
}

export function ADHLinkDetailsForm({
  onSubmit,
  linkData,
  audit,
  selector
}: ADHLinkDetailsFormProps) {
  const { adhUrl } = getEnvVarsQPR()
  const { t } = useTranslation(['audit', 'common'])

  const closeModal = () => {
    ;(
      document.querySelector(`[selector="${selector}"]`) as HTMLTdsModalElement
    ).closeModal()
  }

  const onDelete = () => {
    if (
      !window.confirm(
        t('are-you-sure-you-want-delete', {
          ns: 'common',
          object: `"${linkData?.name}" link`
        })
      )
    ) {
      return
    }

    onSubmit(linkData?.id ?? 0)
    closeModal()
  }

  return (
    <TdsModal
      selector={selector}
      size='lg'
      actions-position='static'
      header={linkData?.name}
    >
      <>
        <span slot='body'>
          <StyledLinkRow>
            <StyledTextfieldWrapper>
              <TdsLink className='tds-detail-03' underline>
                <a
                  href={`${adhUrl}/${linkData?.key}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`${adhUrl}/${linkData?.key}`}
                </a>
              </TdsLink>
            </StyledTextfieldWrapper>
            <TdsButton
              size='sm'
              text={capitalizeFirstLetter(t('copy', { ns: 'common' }))}
              onClick={() => {
                navigator.clipboard.writeText(`${adhUrl}/${linkData?.key}`)
                toast(
                  <Toast
                    type='info'
                    headline={t('link-copied', { ns: 'common' })}
                    subheadline={t('adh.success-copied', { ns: 'audit' })}
                  />
                )
              }}
            />
          </StyledLinkRow>

          <div className='sdds-u-mt3'>
            <StyledDeviationsList>
              {filterDeviations(audit.deviations, linkData)
                .sort((a, b) =>
                  sortFnByAuditPointNo(
                    { auditPointNo: a.auditPointNumber },
                    { auditPointNo: b.auditPointNumber }
                  )
                )
                .map(deviation => {
                  const auditPoint = audit.auditPoints.find(
                    ap => ap.id === deviation.auditPointId
                  )
                  return (
                    <>
                      <li>
                        <div
                          className='tds-detail-03'
                          id={`deviation-${deviation.id}`}
                        >{`${
                          auditPoint?.auditPointNo
                        } ${removeHtmlTagsFromString(
                          t(auditPoint?.translationGuid ?? '')
                        )} (${deviation.responsible})`}</div>
                      </li>
                      <TdsTooltip
                        selector={`#deviation-${deviation.id}`}
                        offsetDistance={10}
                        placement='top'
                        text={deviation.deviation}
                      />
                    </>
                  )
                })}
            </StyledDeviationsList>
          </div>
        </span>
        <span slot='actions' className='tds-u-flex tds-u-gap2'>
          <TdsButton
            size='sm'
            text={t('delete', { ns: 'common' })}
            variant='danger'
            onClick={onDelete}
          />

          <TdsButton
            size='sm'
            text={t('cancel', { ns: 'common' })}
            className='sdds-u-mr2'
            modeVariant='secondary'
            variant='secondary'
            onClick={() => closeModal()}
          />
        </span>
      </>
    </TdsModal>
  )
}
