import type { BackEnd } from '@cdab/scania/qpr/interactor'
import { ApiClient } from '@cdab/scania/qpr/interactors/api'
import type { MockAuditsRealTimeService } from '@cdab/scania/qpr/interactors/mock'
import { MockBackEnd } from '@cdab/scania/qpr/interactors/mock'
import invariant from 'tiny-invariant'

import type { Provider } from './client-providers'
import {
  PROVIDER_API,
  PROVIDER_FIREBASE,
  PROVIDER_MOCK
} from './client-providers/constants'

const ERR_NO_CLIENT = 'client was undefined! Did you call initializeClient?'

// Maybe should move this to a (singleton?) class, so we don't have globals? 🤔
// Then again, a singleton is a global I guess 🤷
let client: BackEnd | undefined = undefined

let rtMock: MockAuditsRealTimeService | undefined = undefined

export function initializeClient(provider: Provider): BackEnd {
  const providerType = provider.type

  switch (providerType) {
    case PROVIDER_API:
      client = new ApiClient(
        provider.apiUrl,
        provider.auditsRealTimeEndpoint,
        provider.keycloakConfiguration
      )
      break

    case PROVIDER_FIREBASE:
      client = new MockBackEnd(0, undefined) // TODO: Replace with Firebase specs
      break

    case PROVIDER_MOCK:
      client = new MockBackEnd(provider.mockDelayMS, provider.realTimeMocks)
      rtMock = client.AuditsRealTimeService as MockAuditsRealTimeService
      break

    default:
      throw new Error(`Invalid provider type, panicking! (${providerType})`)
  }

  return client
}

export function getClient(): BackEnd {
  invariant(client, ERR_NO_CLIENT)
  return client
}

export function getRtMock() {
  return rtMock
}
