import { AuditNote } from '@cdab/scania/qpr/components/atoms'
import { IconSmartphone, getIconSize } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const NoteTextareaWrapper = styled(AuditNote)`
  margin-bottom: var(--sdds-spacing-element-12);
`
export const NoteIcon = styled(IconSmartphone)`
  width: ${getIconSize('medium')};
  height: ${getIconSize('medium')};
  margin-right: var(--sdds-spacing-element-4);
`
