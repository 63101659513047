import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type CreateCheckpointState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

export function useSendCreateCheckpoint() {
  const client = useBackend()

  const createCheckpoint = async (
    auditPointId: number,
    dosVersion: number,
    baseTranslation: string
  ): Promise<string> => {
    try {
      await client.AuditsService.CreateCheckpoint(
        auditPointId,
        dosVersion,
        baseTranslation
      )
      return 'success'
    } catch (e) {
      return 'error'
    }
  }

  return {
    createCheckpoint
  }
}

export function useCreateCheckpoint(
  auditPointId: number,
  dosVersion: number,
  baseTranslation: string
) {
  const { t } = useTranslation(['errors', 'common'])
  const { createCheckpoint } = useSendCreateCheckpoint()
  const [submitState, setSubmitState] = useState<CreateCheckpointState>({
    status: 'none',
    message: null
  })

  const handleClickCreateCheckpoint = useCallback(async () => {
    const response = await createCheckpoint(
      auditPointId,
      dosVersion,
      baseTranslation
    )
    setSubmitState({ status: 'none', message: null })

    if (response === 'success') {
      setSubmitState({
        status: response,
        message: t('create-success', {
          object: t('checkpoint', { ns: 'common' })
        })
      })
      return true
    }

    setSubmitState({
      status: 'error',
      message: t('create-error', { object: t('checkpoint', { ns: 'common' }) })
    })
    return false
  }, [createCheckpoint, auditPointId, dosVersion, baseTranslation, t])
  return [submitState, handleClickCreateCheckpoint] as const
}
