import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import type { TranslationsService } from '@cdab/scania/qpr/interactor'
import {
  isValidAdminTranslations,
  type AdminTranslations,
  type Translations
} from '@cdab/scania/qpr/schema'
import { axiosErrorToError } from '@cdab/utils'

import { ApiBase } from './api-base'
import type { Configuration } from './generated-swagger-client'
import { TranslationsApi } from './generated-swagger-client'

export class ApiTranslationsService
  extends ApiBase
  implements TranslationsService
{
  private translationsApi: TranslationsApi

  constructor(configuration: Configuration, axiosInstance: AxiosInstance) {
    super(configuration, axiosInstance)
    this.translationsApi = new TranslationsApi(
      configuration,
      configuration.basePath,
      axiosInstance
    )
  }
  public async GetAdminTranslations(
    languageId: number,
    dosVersion: number,
    options?: AxiosRequestConfig
  ): Promise<AdminTranslations> {
    try {
      const response =
        await this.translationsApi.apiTranslationsAdminTranslationsDosVersionLanguageIdGet(
          dosVersion,
          languageId,
          options
        )
      if (!isValidAdminTranslations(response.data))
        throw new Error(`Admin translations is invalid`)

      return response.data as AdminTranslations
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public async GetTranslations(
    languageId: number,
    dosVersion: number,
    options?: AxiosRequestConfig
  ): Promise<Translations> {
    try {
      const response =
        await this.translationsApi.apiTranslationsTranslationsDosVersionLanguageIdGet(
          dosVersion,
          languageId,
          options
        )

      return response.data.translations as Translations
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public async UpdateTranslation(
    translationId: number,
    translationText: string,
    options?: AxiosRequestConfig
  ): Promise<void> {
    try {
      await this.translationsApi.apiTranslationsTranslationIdPut(
        translationId,
        { translationText },
        options
      )
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public async CreateTranslation(
    languageId: number,
    translationGuid: string,
    translatedText: string,
    options?: AxiosRequestConfig
  ): Promise<number> {
    try {
      const result =
        await this.translationsApi.apiTranslationsAdminTranslationsPost(
          { languageId, translationGuid, translatedText },
          options
        )

      return result.data
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }
}
