import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export interface SystemMessage {
  text: string
  author: string
  id: number
  read: boolean
  title: string
  createdTime: string
}

export const SystemMessageSchema: JSONSchemaType<SystemMessage> = {
  type: 'object',

  properties: {
    text: {
      type: 'string'
    },
    author: {
      type: 'string'
    },
    id: {
      type: 'integer'
    },
    read: {
      type: 'boolean'
    },
    title: {
      type: 'string'
    },
    createdTime: {
      type: 'string',
      format: 'iso-date-time',
      nullable: true
    }
  },

  required: ['text', 'author', 'id', 'read', 'title', 'createdTime']
}

const validateSystemMessage: ValidateFunction<SystemMessage> =
  ajv.compile(SystemMessageSchema)

export const isValidSystemMessage = (
  systemMessage: unknown
): systemMessage is SystemMessage =>
  isValidThing<SystemMessage>(systemMessage, validateSystemMessage)

export const mapResponseToSystemMessage = (
  responseData: any
): SystemMessage => {
  const {
    systemMessageAuthor,
    id,
    systemMessageText,
    systemMessageTitle,
    systemMessageCreatedTime
  } = responseData
  const systemMessage: SystemMessage = {
    author: systemMessageAuthor,
    id: id,
    read: false,
    text: systemMessageText,
    title: systemMessageTitle,
    createdTime: systemMessageCreatedTime
  }

  if (!isValidSystemMessage(systemMessage)) {
    throw new Error(`System message ${id}`)
  }

  return systemMessage
}
