import type { DateTimeProps } from './datetime.types'

export function DateTime({
  className,
  disabled = false,
  helper,
  label,
  name = 'datetime',
  customChange,
  onInput,
  noMinWidth = false,
  size,
  state = 'none',
  type = 'date',
  value
}: Readonly<DateTimeProps>) {
  const disabledAttr = disabled ? { disabled: true } : ''

  return (
    <sdds-datetime
      class={className}
      customChange={customChange} // NOTE: This doesn't seem to work.
      onInput={onInput}
      name={name}
      size={size}
      nominwidth={noMinWidth}
      state={state}
      type={type}
      value={value}
      id={`input-${name}`}
      aria-labelledby={`label-${name}`}
      {...disabledAttr}
    >
      {label && (
        <label slot='sdds-label' id={`label-${name}`} htmlFor={`input-${name}`}>
          {label}
        </label>
      )}
      {helper && <span slot='sdds-helper'>{helper}</span>}
    </sdds-datetime>
  )
}
