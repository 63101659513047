import { v4 as uuidv4 } from 'uuid'
import { action } from 'mobx'

import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { Audit, FileData } from '@cdab/scania/qpr/schema'
import { fileIsImage, fileToArrayBuffer } from '@cdab/utils'

import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import invariant from 'tiny-invariant'
import { getAudit } from '../store/audits'
import type { CachedExtraFileData } from './types'
import type { AuditModel, PledgeModel } from '../audit-model'

type Type = 'add-pledge-file'

type Data = {
  pledgeId: PledgeModel['id']
  file: File
  fileGuid: string
}

export type AddPledgeFileOperationData = OperationDataBase<Type, Data>

export type CachedAddPledgeFileOperationData = AddPledgeFileOperation & {
  data: AddPledgeFileOperationData & {
    value: Data & CachedExtraFileData
  }
}

export class AddPledgeFileOperation extends Operation {
  public data: AddPledgeFileOperationData
  private responseFileData: FileData | null = null

  constructor(auditId: Audit['id'], value: Data, guid = uuidv4()) {
    super(auditId, guid)

    this.data = {
      type: 'add-pledge-file',
      value,
      previousValue: undefined
    }
  }

  private getPledge = (audit: AuditModel, pledgeId: PledgeModel['id']) => {
    const pledge = audit.pledges.find(ap => ap.id === pledgeId)

    invariant(pledge, `${this.data.type}: No pledge found for ${pledgeId}`)

    return pledge
  }

  public ApplyTo = action((audit: AuditModel): void => {
    const { pledgeId, file, fileGuid } = this.data.value

    const pledge = this.getPledge(audit, pledgeId)

    const fileData: FileData = this.responseFileData ?? {
      fileName: file.name,
      id: fileGuid,
      isImage: fileIsImage(file),
      url: URL.createObjectURL(file),
      isUploaded: false,
      uploadProgress: 0
    }

    // Could happen if we apply the same operation twice
    const hasFile = !!pledge.files.find(
      f => f.id === fileData.id || f.id === fileGuid
    )

    if (hasFile) {
      return
    }

    pledge.files.push(fileData)
  })

  public GetName = (): string =>
    `${this.data.type}-${this.auditId}-${this.data.value.fileGuid}`

  public SendOperation = async (client: BackEnd): Promise<boolean> => {
    const { pledgeId, file, fileGuid } = this.data.value

    const audit = getAudit(this.auditId)
    let pledgeFile: FileData | undefined = undefined
    if (audit) {
      const pledge = this.getPledge(audit, pledgeId)

      if (pledge) {
        pledgeFile = pledge.files.find(f => f.id === fileGuid)
      }
    }

    const { fileData, success } =
      await client.StorageService.UploadFileForPledge(
        this.auditId,
        pledgeId,
        file,
        action(progressEvent => {
          if (!pledgeFile) return
          if (typeof progressEvent.progress === 'undefined') return

          pledgeFile.uploadProgress = progressEvent.progress
        })
      )

    this.responseFileData = fileData

    return success
  }

  public RollbackOn(audit: AuditModel): void {
    const { fileGuid, pledgeId } = this.data.value

    const pledge = audit.pledges.find(ap => ap.id === pledgeId)
    invariant(pledge, `${this.data.type}: No pledge found for ${pledge}`)

    const index = pledge.files.findIndex(f => f.id === fileGuid)
    invariant(
      index !== -1,
      `${this.data.type}: No file found for guid ${fileGuid}`
    )

    pledge.files.splice(index, 1)
  }

  public override GetData =
    async (): Promise<CachedAddPledgeFileOperationData> => {
      const { file } = this.data.value
      const fileBuffer = await fileToArrayBuffer(file)

      const cachedData: CachedExtraFileData = {
        fileBuffer,
        fileName: file.name,
        mimeType: file.type
      }

      const json = JSON.parse(JSON.stringify(this))

      return {
        ...json,
        data: {
          ...json.data,
          value: {
            ...json.data.value,
            ...cachedData
          }
        }
      }
    }

  public override OnConfirm = action((): void => {
    if (!this.responseFileData) return

    const audit = getAudit(this.auditId)
    if (!audit) return

    const { pledgeId, fileGuid } = this.data.value

    const pledge = this.getPledge(audit, pledgeId)

    invariant(
      pledge,
      `AddPledgeFile.OnConfirm: no pledge for ${this.auditId}:${pledgeId}`
    )

    const file = pledge.files.find(f => f.id === fileGuid)
    if (file) {
      URL.revokeObjectURL(file.url)
      file.url = this.responseFileData.url
      file.id = this.responseFileData.id
      file.isUploaded = true
    } else {
      pledge.files.push(this.responseFileData)
    }
  })
}
