import {
  CenteredContainer,
  Editor,
  ElasticContentContainer,
  ScrollableWrapper
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import {
  useCreateCheckpoint,
  useCssVariableBreakpoint
} from '@cdab/scania/qpr/hooks'
import type { AuditPointNumber } from '@cdab/scania/qpr/schema'
import { Roles } from '@cdab/scania/qpr/schema'
import type { TOption } from '@cdab/scania/sdds'
import {
  Breadcrumbs,
  Column,
  Container,
  Dropdown,
  DropdownOption,
  EmptyScreen,
  IconWarning,
  Row
} from '@cdab/scania/sdds'
import { useCallback, useEffect, useState } from 'react'
import {
  isRouteErrorResponse,
  json,
  useLoaderData,
  useNavigate,
  useRouteError
} from 'react-router'

import { capitalizeFirstLetter } from '@cdab/utils'
import { useTranslation } from 'react-i18next'
import {
  ButtonMessageWrapper,
  StyledButton,
  StyledFirstButton,
  StyledMessage,
  StyledRow,
  Title
} from './checkpoints.styles'

const CurrentDOSVersionId = 6 // DOS 5

export type NewAdminCheckpointLoaderData = {
  auditPoints: AuditPointNumber[]
}

export type NewCheckpointData = {
  auditPointId: number
  baseTranslation: string
}

export async function loader(): Promise<NewAdminCheckpointLoaderData> {
  try {
    const client = getClient()

    const userInfo = await client.GetuserInfo()
    if (!userInfo) {
      throw json({
        status: 401
      })
    }

    const [user, auditPoints] = await Promise.all([
      client.UserService.GetUser(userInfo.userId),
      client.AuditsService.GetAuditPointNumbers(CurrentDOSVersionId)
    ])

    if (
      user.role !== Roles.FactoryAdmin &&
      user.role !== Roles.FactoryEngineAdmin
    ) {
      throw json({
        status: 403
      })
    }

    return { auditPoints }
  } catch (error) {
    if (error instanceof Response) {
      throw new Response(null, {
        status: error.status
      })
    } else {
      throw new Response(null, {
        status: 404
      })
    }
  }
}

export function Page() {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { updateTitles } = useTitle()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { auditPoints } = useLoaderData() as NewAdminCheckpointLoaderData

  const [data, setData] = useState<NewCheckpointData>({
    auditPointId: auditPoints[0].id,
    baseTranslation: ''
  })

  const [submitState, handleClickCreateCheckpoint] = useCreateCheckpoint(
    data.auditPointId,
    CurrentDOSVersionId,
    data.baseTranslation
  )

  const minCharsBaseTranslation = 4

  const [stateBaseTranslation, setStateBaseTranslation] = useState(true)

  const onSaveClick = useCallback(() => {
    setStateBaseTranslation(
      data.baseTranslation.length > minCharsBaseTranslation
    )
    if (data.baseTranslation.length > minCharsBaseTranslation) {
      handleClickCreateCheckpoint()
    }
  }, [data.baseTranslation.length, handleClickCreateCheckpoint])

  useEffect(() => {
    updateTitles({
      contentHeader: {
        title: t('administration', { ns: 'common' }),
        subtitle: capitalizeFirstLetter(t('translations', { ns: 'common' }))
      },

      mobile: {
        title: `${capitalizeFirstLetter(t('translations', { ns: 'common' }))}`,
        description: null
      }
    })
  }, [t])

  return (
    <ElasticContentContainer overflowHidden>
      <ScrollableWrapper>
        <Container
          fullHeight
          fluid='normal'
          paddingOnColumns={isLg}
          paddingOnContainer={isLg}
        >
          {isLg && (
            <Row>
              <Column width={12} className='sdds-u-pt2'>
                <Breadcrumbs
                  links={[
                    {
                      text: capitalizeFirstLetter(
                        t('translations', { ns: 'common' })
                      ),
                      to: '/admin/translations'
                    },
                    {
                      text: capitalizeFirstLetter(
                        t('checkpoints', { ns: 'common' })
                      ),
                      to: '/admin/translations'
                    },
                    { text: t('new', { ns: 'common' }), to: '' }
                  ]}
                />
              </Column>
            </Row>
          )}
          <Row>
            <Column width={12} lg={6} offset={{ lg: 3 }}>
              <StyledRow>
                <Title>{`${t('new')} ${t('checkpoint')}`}</Title>
              </StyledRow>
              <StyledRow>
                <Dropdown
                  id='auditPointId'
                  label={capitalizeFirstLetter(
                    t('audit-point', {
                      ns: 'common'
                    })
                  )}
                  size='lg'
                  labelPosition='outside'
                  defaultOption={auditPoints[0].id.toString()}
                  openDirection={isLg ? 'down' : 'auto'}
                  onSelect={(option: TOption) =>
                    setData({ ...data, auditPointId: parseInt(option.value) })
                  }
                >
                  {auditPoints.map(({ id, auditPointNo }) => {
                    return (
                      <DropdownOption
                        key={id}
                        value={id.toString()}
                        text={auditPointNo}
                      />
                    )
                  })}
                </Dropdown>
              </StyledRow>
              <StyledRow>
                <Editor
                  content={data.baseTranslation}
                  maxLength={4000}
                  label={t('original-text')}
                  onChange={text => {
                    setData({ ...data, baseTranslation: text })
                  }}
                  onBlur={length => {
                    setStateBaseTranslation(length > minCharsBaseTranslation)
                  }}
                  placeholder={t('original-text')}
                  hasError={!stateBaseTranslation}
                  helper={
                    !stateBaseTranslation &&
                    t('message.validation-error', {
                      ns: 'common',
                      chars: minCharsBaseTranslation.toString()
                    })
                  }
                />
              </StyledRow>

              <ButtonMessageWrapper>
                <StyledFirstButton
                  text={t('cancel', {
                    ns: 'common'
                  })}
                  type='secondary'
                  size='md'
                  onClick={() => navigate('/admin/translations')}
                />
                <StyledButton
                  text={capitalizeFirstLetter(
                    t('add', {
                      ns: 'common'
                    })
                  )}
                  disabled={!stateBaseTranslation}
                  type='primary'
                  size='md'
                  onClick={() => onSaveClick()}
                />
                {(submitState.status === 'error' ||
                  submitState.status === 'success') && (
                  <StyledMessage
                    className='sdds-u-mt1'
                    variant='single-line'
                    singleLineMessage={submitState.message}
                    type={submitState.status}
                  />
                )}
              </ButtonMessageWrapper>
            </Column>
          </Row>
        </Container>
      </ScrollableWrapper>
    </ElasticContentContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    switch (error.status) {
      case 401:
        message = t('not-logged-in')
        break
      case 403:
        message = t('access-denied')
        break
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
