import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'
import { parseDate } from '@cdab/utils'
import { v4 as uuidv4 } from 'uuid'

// TODO: Vi borde nog dela upp Deviation till två, en med och en utan action plan. Så får deviation vara en | mellan dessa två
export type Deviation = {
  approvalDate: Date | null
  auditId: number
  auditPointId: number
  auditPointNumber: string
  deviation: string
  deviationWithoutActions: boolean | null
  expirationDate: Date | null
  id?: number
  proposedActions: string | null
  responsible: string | null
  clientGuid: string // A guid for the client to keep track of the same deviation, since we don't always have a (valid) id
  actionPlanId: number
}

export interface DeviationJson {
  approvalDate: string | null
  auditId: number
  auditPointId: number
  auditPointNo: string
  deviation: string
  deviationWithoutActions: boolean | null
  expirationDate: string | null
  id: number
  proposedActions: string | null
  responsible: string | null
  actionPlanId: number
}

export type CreateDeviation = Omit<Deviation, 'id'>

export const DeviationSchema: JSONSchemaType<DeviationJson> = {
  type: 'object',

  properties: {
    approvalDate: {
      type: 'string',
      format: 'iso-date-time',
      nullable: true
    },
    auditId: {
      type: 'integer'
    },
    auditPointId: {
      type: 'integer'
    },
    auditPointNo: {
      type: 'string'
    },
    deviation: {
      type: 'string'
    },
    deviationWithoutActions: {
      type: 'boolean',
      nullable: true
    },
    expirationDate: {
      type: 'string',
      format: 'iso-date-time',
      nullable: true
    },
    id: {
      type: 'integer',
      nullable: false
    },
    proposedActions: {
      type: 'string',
      nullable: true
    },
    responsible: {
      type: 'string',
      nullable: true
    },
    actionPlanId: {
      type: 'number',
      nullable: false
    }
  },

  required: [
    'approvalDate',
    'auditId',
    'auditPointId',
    'auditPointNo',
    'deviation',
    'deviationWithoutActions',
    'expirationDate',
    'id',
    'proposedActions',
    'responsible'
  ]
}

const validateDeviation: ValidateFunction<DeviationJson> =
  ajv.compile(DeviationSchema)

export const isValidDeviationJson = (
  deviation: unknown
): deviation is DeviationJson => {
  return isValidThing<DeviationJson>(deviation, validateDeviation)
}

export function createDeviationFromJson(json: DeviationJson): Deviation {
  return {
    approvalDate: json.approvalDate ? parseDate(json.approvalDate) : null,
    auditId: json.auditId,
    auditPointId: json.auditPointId,
    auditPointNumber: json.auditPointNo,
    deviation: json.deviation,
    deviationWithoutActions: json.deviationWithoutActions,
    expirationDate: json.expirationDate ? parseDate(json.expirationDate) : null,
    id: json.id,
    proposedActions: json.proposedActions,
    responsible: json.responsible,
    clientGuid: uuidv4(),
    actionPlanId: json.actionPlanId
  }
}
