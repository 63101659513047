import * as sentry from '@sentry/react'

import type {
  AuditModel,
  UnknownAuditModel
} from '@cdab/scania/qpr/offline/models'
import {
  getAuditModelCacheData,
  restoreAuditFromData
} from '@cdab/scania/qpr/offline/models'

import { getAuditsStore } from './stores'

export async function getCachedAudit(
  auditid: number
): Promise<AuditModel | undefined> {
  const auditsStore = await getAuditsStore('readonly')

  return new Promise<AuditModel | undefined>((resolve, reject) => {
    const req = auditsStore.get(auditid)

    req.onerror = ev => {
      console.error(
        'An error occured during getting audit with id ' + auditid,
        ev
      )
      sentry.captureException(ev)
      reject(ev)
    }
    req.onsuccess = () => {
      const { result } = req

      if (!result || typeof result !== 'object') {
        resolve(undefined)
        return
      }

      if ((result as Partial<UnknownAuditModel>).VERSION === undefined) {
        resolve(undefined)
        return
      }

      const audit = restoreAuditFromData(result)

      resolve(audit)
    }
  })
}

export async function cacheAudit(audit: AuditModel): Promise<void> {
  const auditsStore = await getAuditsStore('readwrite')

  return new Promise((resolve, reject) => {
    const auditData = getAuditModelCacheData(audit)
    const req = auditsStore.put(auditData)
    req.onerror = ev => {
      console.error(ev)
      sentry.captureException(ev)
      reject()
    }
    req.onsuccess = () => {
      resolve()
    }
  })
}
