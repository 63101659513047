import styled from 'styled-components'
import { DateTime } from '@cdab/scania/sdds'
import { Dialog } from '@headlessui/react'

export const Overlay = styled.div.attrs({
  className: 'sdds-modal-backdrop'
})``

export const Panel = styled.div.attrs({
  className: 'sdds-modal sdds-modal-sm'
})`
  display: flex;
  max-height: 100%;
`

export const FullWidthForm = styled.form`
  width: 100%;
`

export const Header = styled.div.attrs({ className: 'sdds-modal-header' })``

export const Container = styled.div`
  position: fixed;
  display: flex;

  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  align-items: center;

  justify-content: center;

  z-index: 40000;
`

export const ModalHeadline = styled(Dialog.Title).attrs({
  className: 'sdds-modal-headline'
})`
  text-transform: capitalize;
`

export const ModalBody = styled.div.attrs({
  className: 'sdds-modal-body sdds-on-white-bg'
})``

export const FormRow = styled.div.attrs({
  className: 'sdds-u-mt3'
})``

export const DateRow = styled.div.attrs({
  className: 'sdds-row'
})``

export const StyledDateTime = styled(DateTime).attrs({
  className: 'sdds-col-xs-12 sdds-col-md-6 sdds-u-mt3'
})`
  @media (max-width: ${({ theme }) => theme.size.md}) {
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.size.md}) {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`

export const ModalActions = styled.div.attrs({
  className: 'sdds-modal-actions sdds-u-pb1'
})``
