import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import type { DefaultTheme } from 'styled-components'
import type { PropsWithChildren } from 'react'
import { useMemo } from 'react'
import { getCSSVariableValue } from '@cdab/utils'

// eslint-disable-next-line @typescript-eslint/ban-types
export function ThemeProvider(props: PropsWithChildren<{}>) {
  const defaultTheme = useMemo((): DefaultTheme => {
    const sm = getCSSVariableValue('--sdds-grid-sm')
    const md = getCSSVariableValue('--sdds-grid-md')
    const lg = getCSSVariableValue('--sdds-grid-lg')
    const xlg = getCSSVariableValue('--sdds-grid-xlg')
    const xxlg = getCSSVariableValue('--sdds-grid-xxlg')
    const max = getCSSVariableValue('--sdds-grid-max')

    return {
      size: {
        sm: sm || '',
        md: md || '',
        lg: lg || '',
        xlg: xlg || '',
        xxlg: xxlg || '',
        max: max || ''
      }
    }
  }, [])

  return (
    <StyledThemeProvider theme={defaultTheme}>
      {props.children}
    </StyledThemeProvider>
  )
}

export default ThemeProvider
