import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export type PledgeArea = {
  id: number
  description: string
}

export const PledgeAreaSchema: JSONSchemaType<PledgeArea> = {
  type: 'object',

  properties: {
    id: {
      type: 'number'
    },
    description: {
      type: 'string'
    }
  },

  required: ['id', 'description']
}

const validatePledgeArea: ValidateFunction<PledgeArea> =
  ajv.compile(PledgeAreaSchema)

export const isValidPledgeArea = (
  pledgeArea: unknown
): pledgeArea is PledgeArea =>
  isValidThing<PledgeArea>(pledgeArea, validatePledgeArea)
