import * as sentry from '@sentry/react'
import invariant from 'tiny-invariant'

import type { UnknownAuditModel } from '.'
import { LATEST_AUDIT_MODEL_VERSION } from '.'
import { migrateToV2 } from './v2'
import { migrateToV3 } from './v3'
import { migrateToV4 } from './v4'
import { migrateToV5 } from './v5'

export function migrateAuditModelToLatest(audit: UnknownAuditModel): void {
  if (audit.VERSION === LATEST_AUDIT_MODEL_VERSION) {
    // We're already on the latest version!
    return
  }

  for (let i = audit.VERSION; i < LATEST_AUDIT_MODEL_VERSION; i++) {
    const { VERSION } = audit
    switch (VERSION) {
      case 1:
        migrateToV2(audit)
        break

      case 2:
        migrateToV3(audit)
        break
      case 3:
        migrateToV4(audit)
        break
      case 4:
        migrateToV5(audit)
        break

      default: {
        const error = new Error(
          `Tried to migrate unknown audit version (${VERSION})! Did you remember to add migration case?`
        )
        sentry.captureException(error)

        throw error
      }
    }

    const toVersion = i + 1
    invariant(
      audit.VERSION === toVersion,
      `Audit version was not updated after trying to migrate to version ${toVersion}. Version was ${audit.VERSION}`
    )
  }
}
