import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export interface Market {
  id: number
  marketName: string
  countryCode: string
  isMainMarket: boolean
  areaId: number | null
}

export type MarketName = Pick<Market, 'id' | 'marketName'>
type MarketNameJson = { id: number; marketName: string }

export const MarketNameSchema: JSONSchemaType<MarketNameJson> = {
  type: 'object',
  properties: {
    id: { type: 'integer' },
    marketName: { type: 'string' }
  },
  required: ['id', 'marketName']
}

export const MarketNamesSchema: JSONSchemaType<MarketNameJson[]> = {
  type: 'array',
  items: MarketNameSchema
}

export const MarketSchema: JSONSchemaType<Market> = {
  type: 'object',

  properties: {
    id: {
      type: 'number'
    },
    marketName: {
      type: 'string'
    },
    countryCode: {
      type: 'string'
    },
    isMainMarket: {
      type: 'boolean'
    },
    areaId: { type: 'integer', nullable: true }
  },

  required: ['id', 'marketName', 'countryCode', 'isMainMarket', 'areaId']
}

const validateMarket: ValidateFunction<Market> = ajv.compile(MarketSchema)

export const isValidMarket = (market: unknown): market is Market =>
  isValidThing<Market>(market, validateMarket)

const validateMarketName: ValidateFunction<MarketNameJson> =
  ajv.compile(MarketNameSchema)

export const isValidMarketName = (data: unknown): data is MarketNameJson =>
  isValidThing<MarketNameJson>(data, validateMarketName)

const validateMarketNames: ValidateFunction<MarketNameJson[]> =
  ajv.compile(MarketNamesSchema)

export const isValidMarketNames = (data: unknown): data is MarketNameJson[] =>
  isValidThing<MarketNameJson[]>(data, validateMarketNames)
