import cx from 'classnames'
import { useMemo } from 'react'
import { capitalize } from '../utils'

export interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label?: string | JSX.Element
}

export function Checkbox({
  className,
  label,
  name,
  ...props
}: Readonly<CheckboxProps>) {
  const classNames = cx('sdds-checkbox-item', className)
  const id = useMemo(
    () => props.id ?? name ?? `input-${Math.floor(Math.random() * 100000)}`,
    [props.id, name]
  )

  const labelText = typeof label === 'string' ? capitalize(label) : label

  return (
    <div className={classNames}>
      <input className='sdds-form-input' type='checkbox' {...props} id={id} />
      {label && (
        <label className='sdds-form-label' htmlFor={id}>
          {labelText}
        </label>
      )}
    </div>
  )
}
