import { StyledColumn } from './column.styles'
import type { ColumnProps } from './column.types'

export function Column({
  children,
  className,
  fullHeight = false,
  padding = true,
  scrollY,
  scrollX,
  offset,
  ...sizes
}: Readonly<ColumnProps>) {
  return (
    <StyledColumn
      className={className}
      fullHeight={fullHeight}
      padding={padding}
      offset={offset}
      scrollX={scrollX}
      scrollY={scrollY}
      {...sizes}
    >
      {children}
    </StyledColumn>
  )
}
