import cx from 'classnames'
import styled from 'styled-components'

export const StyledMessageWrapper = styled.div`
  margin-top: 2rem;
`

export const StyledModalContent = styled.div.attrs({
  className: cx('sdds-u-mt3')
})`
  padding-left: 2rem;
`

export const StyledDeviationsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

export const StyledParagraph = styled.p`
  margin-top: 0;
  margin-bottom: 0.5rem;
`

export const StyledDiv = styled.div`
  margin-left: 5.5rem;
  padding-left: 1rem;
`
