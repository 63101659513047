import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { UploadProgress } from '@cdab/scania/qpr/interactor'
import type { DealerFile, FileData } from '@cdab/scania/qpr/schema'
import { capitalizeFirstLetter } from '@cdab/utils'
import { action } from 'mobx'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type UploadedDealerFile = FileData & {
  uploaded_guid_id: string
}

export type UploadDealerFilesState =
  | {
      status: 'success'
      message: null
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'uploaded'
      message: string
    }
  | {
      status: 'uploading'
      progress: number
      fileId: string
    }
  | {
      status: 'none'
      message: null
    }

export function useUploadDealerFiles(
  dealerId: number,
  dealerFiles: DealerFile[] | undefined
) {
  const { t } = useTranslation('dealer')
  const client = useBackend()

  const [submitState, setSubmitState] = useState<UploadDealerFilesState>({
    status: 'none',
    message: null
  })

  const [uploadedFiles, setUploadedFiles] = useState<UploadedDealerFile[]>([])

  const handleUploadProgress = (
    progressEvent: UploadProgress,
    fileId: string
  ) => {
    if (typeof progressEvent.progress === 'undefined') return
    setSubmitState({
      status: 'uploading',
      progress: progressEvent.progress,
      fileId: fileId
    })
  }

  const handleUploadError = (message: string) => {
    setSubmitState({
      status: 'error',
      message: message
    })
  }

  const uploadFiles = useCallback(async () => {
    if (!dealerFiles || dealerFiles.length === 0) return

    for (const dealerFile of dealerFiles) {
      if (uploadedFiles.find(uf => uf.id === dealerFile.id)) continue
      setSubmitState({
        status: 'uploading',
        progress: 0,
        fileId: dealerFile.id
      })

      try {
        const uploadPromise = client.StorageService.UploadFileForDealer(
          dealerId,
          dealerFile.file,
          action((progressEvent: UploadProgress) =>
            handleUploadProgress(progressEvent, dealerFile.id)
          )
        )

        const [uploadResult] = await Promise.allSettled([uploadPromise])

        if (uploadResult.status === 'rejected' || !uploadResult.value.success) {
          handleUploadError(t('message.error-upload'))
          return
        }

        if (uploadResult.value.success && uploadResult.value.dealerFile) {
          const uploadedCurrFiles = uploadedFiles
          uploadedCurrFiles.push({
            ...uploadResult.value.dealerFile,
            uploaded_guid_id: dealerFile.id
          })

          setUploadedFiles(uploadedCurrFiles)
        }
      } catch (error) {
        handleUploadError(t('message.error-upload'))
        break
      }
    }
    setSubmitState({
      status: 'uploaded',
      message: capitalizeFirstLetter(
        t('message.dealer-attachments-saved-success')
      )
    })
  }, [client.StorageService, dealerFiles, dealerId, t, uploadedFiles])

  return [submitState, uploadFiles, uploadedFiles, setSubmitState] as const
}
