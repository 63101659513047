import { action, toJS } from 'mobx'
import invariant from 'tiny-invariant'

import type { AuditModel } from '../audit-model'
import { mergeAudits_old } from '../merge-audits'
import { auditsStore } from './store'
import { syncRemovedFiles } from './utilities'

// TODO: Should this live per version instead?
export function getAuditModelCacheData(audit: AuditModel): unknown {
  const auditData = toJS(audit)

  // Never include a file which isn't uploaded
  auditData.files = auditData.files.filter(f => f.isUploaded)

  auditData.deviations.forEach((deviation, index) => {
    auditData.deviations[index].files = toJS(
      deviation.files.filter(f => f.isUploaded)
    )
  })

  auditData.auditPoints.forEach((auditPoint, index) => {
    auditData.auditPoints[index].files = toJS(
      auditPoint.files.filter(file => file.isUploaded)
    )
  })

  return auditData
}

export const addAudit = action((newAuditModel: AuditModel): AuditModel => {
  let currentAudit = auditsStore[newAuditModel.id]

  if (currentAudit === undefined) {
    auditsStore[newAuditModel.id] = newAuditModel
    currentAudit = auditsStore[newAuditModel.id]
  } else {
    // We already have an audit in the store,
    // so instead of replacing it (and potentially loosing a mobx reference)
    // we deep merge the objects
    mergeAudits_old(currentAudit, newAuditModel)
    // mergeObjects(currentAudit, newAudit as unknown as JSObject)
  }

  currentAudit = auditsStore[newAuditModel.id]
  invariant(currentAudit)

  return currentAudit
})

export function getAudit(auditId: AuditModel['id']): AuditModel | undefined {
  const audit = auditsStore[auditId]

  return audit
}

export const addAuditFiles = action(
  (auditId: AuditModel['id'], files: AuditModel['files']) => {
    const audit = getAudit(auditId)
    invariant(
      audit,
      `Cannot add audit files to audit we don't have (id ${auditId})`
    )

    syncRemovedFiles(audit.files, files)

    files.forEach(file => {
      const fileIndex = audit.files.findIndex(f => f.id === file.id)

      if (fileIndex === -1) {
        audit.files.push(file)
      } else {
        const f = audit.files[fileIndex]
        f.fileName = file.fileName
        f.isImage = file.isImage
        f.isUploaded = file.isUploaded
        f.url = file.url
      }
    })
  }
)

export function deleteAudit(auditId: AuditModel['id']): void {
  delete auditsStore[auditId]
}
