import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { Audit } from '@cdab/scania/qpr/schema'
import { action } from 'mobx'
import invariant from 'tiny-invariant'
import { v4 as uuidv4 } from 'uuid'
import type { AuditModel } from '../audit-model'
import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'

type Type = 'update-audit'

export type UpdateAuditData = Pick<
  Audit,
  'date' | 'extraAuditors' | 'description' | 'auditTypeId'
>

export type UpdateAuditOperationData = OperationDataBase<Type, UpdateAuditData>

export class UpdateAuditOperation extends Operation {
  public data: UpdateAuditOperationData

  constructor(
    auditId: number,
    value: UpdateAuditData,
    previousValue: UpdateAuditData,
    guid = uuidv4()
  ) {
    super(auditId, guid)
    this.data = {
      type: 'update-audit',
      value,
      previousValue
    }
  }

  public GetName = () => `${this.data.type}-${this.auditId}`

  private static updateAudit = action(
    (audit: AuditModel, update: UpdateAuditData) => {
      audit.date = update.date
      audit.description = update.description
      audit.extraAuditors = update.extraAuditors
      audit.auditTypeId = update.auditTypeId
    }
  )

  public ApplyTo(audit: AuditModel): void {
    UpdateAuditOperation.updateAudit(audit, this.data.value)
  }

  public RollbackOn(audit: AuditModel): void {
    invariant(
      this.data.previousValue,
      'No previous value when trying to roll back update audit operation'
    )
    UpdateAuditOperation.updateAudit(audit, this.data.previousValue)
  }

  public async SendOperation(client: BackEnd): Promise<boolean> {
    const value = this.data.value
    await client.AuditsService.UpdateAudit(
      this.auditId,
      {
        date: value.date,
        description: value.description ?? '',
        extraAuditors: value.extraAuditors,
        auditTypeId: value.auditTypeId
      },
      this.guid
    )
    return true
  }
}
