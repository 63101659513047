export function getCSSVariableValue(cssVariable: string): string | undefined {
  const htmlSelector = document?.querySelector('html')
  if (!htmlSelector) {
    return undefined
  }
  const htmlStyles = window.getComputedStyle(htmlSelector)

  const value = htmlStyles.getPropertyValue(cssVariable)

  return value
}
