import type { AuditModelV1 } from './v1'
import type { AuditModelV2 } from './v2'
import type { AuditModelV3 } from './v3'
import type { AuditModelV4 } from './v4'
import { createFromAuditV5, type AuditModelV5 } from './v5'

export type AuditModel = AuditModelV5
// When AuditModel is updated to a new version, we also have to bump this number
export const LATEST_AUDIT_MODEL_VERSION = 5
// As well as "rebind" this function
export const toAuditModel = createFromAuditV5
// We also have to add the new model version to this collection
export type UnknownAuditModel =
  | AuditModelV1
  | AuditModelV2
  | AuditModelV3
  | AuditModelV4
  | AuditModelV5

export type AuditPointModel = AuditModel['auditPoints'][number]
export type DeviationModel = AuditModel['deviations'][number]
export type PledgeAreaModel = AuditModel['pledgeAreas'][number]
export type PledgeModel = AuditModel['pledges'][number]
export type CheckPointModel = AuditModel['checkPoints'][number]
export type ReferenceDocumentModel = AuditModel['referenceDocuments'][number]

export { restoreAuditFromData } from './restoration'
