import cx from 'classnames'
import { forwardRef, useCallback, useEffect } from 'react'
import { capitalize } from '../utils'
import type {
  DropdownOptionProps,
  DropdownProps,
  TOption
} from './dropdown.types'

export function DropdownOption({ value, text }: Readonly<DropdownOptionProps>) {
  return <sdds-dropdown-option value={value}>{text}</sdds-dropdown-option>
}

export const Dropdown = forwardRef(function Dropdown(
  {
    children,
    defaultOption,
    filter,
    labelPosition,
    selectedOption,
    onSelect,
    onWhite,
    type,
    id,
    helper,
    openDirection = 'auto',
    ...props
  }: DropdownProps,
  ref
) {
  const classNames = cx({ 'sdds-on-white-bg': onWhite })

  const helperText = helper ? capitalize(helper) : undefined
  const handleSelectOption = useCallback(
    (event: unknown) => {
      const { detail } = event as { detail: TOption }
      onSelect && onSelect(detail)
    },
    [onSelect]
  )

  // Event listener on dropdown
  useEffect(() => {
    if (id) {
      document
        .getElementById(id)
        ?.addEventListener('selectOption', handleSelectOption)
    } else {
      document.addEventListener('selectOption', handleSelectOption)
    }

    return () => {
      if (id) {
        document
          .getElementById(id)
          ?.removeEventListener('selectOption', handleSelectOption)
      } else {
        document.removeEventListener('selectOption', handleSelectOption)
      }
    }
  }, [handleSelectOption, id])

  const baseProps = {
    'default-option': defaultOption,
    'label-position': labelPosition,
    'selected-option': selectedOption,
    'open-direction': openDirection
  }

  if (!filter) {
    return (
      <sdds-dropdown
        class={classNames}
        ref={ref}
        type={type}
        id={id}
        helper={helperText}
        {...baseProps}
        {...props}
      >
        {children}
      </sdds-dropdown>
    )
  }

  return (
    <sdds-dropdown-filter
      class={classNames}
      data={JSON.stringify(filter)}
      ref={ref}
      type={type}
      id={id}
      helper={helperText}
      {...baseProps}
      {...props}
    />
  )
})
