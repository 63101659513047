import { CapitalizeFirstLetter } from '@cdab/headless-components'
import {
  BadgeTab,
  ElasticContentContainer,
  EmptyState,
  PageHeader,
  ScrollableWrapper
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { type Area, type Market } from '@cdab/scania/qpr/schema'
import { Column, Container, NavigationTabs, Row } from '@cdab/scania/sdds'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, isRouteErrorResponse, useRouteError } from 'react-router'
import { json } from 'react-router-dom'
import styled from 'styled-components'

export type ReportsPageLoaderData = {
  markets: Market[]
  userMarkets: Market[]
  areas: Area[]
}

const StyledRow = styled(Row)`
  display: flex;
  flex-direction: column;
  padding-left: 4rem;
  padding-right: 4rem;
`

export async function loader(): Promise<ReportsPageLoaderData> {
  const client = getClient()

  const userInfo = await client.GetuserInfo()
  if (!userInfo) {
    throw json(null, {
      status: 401
    })
  }

  try {
    const [markets, userMarkets, areas] = await Promise.all([
      client.MarketsService.GetAllMarkets(),
      client.MarketsService.GetMarketsForUser(userInfo.userId),
      client.MarketsService.GetAllAreas()
    ])

    return {
      markets,
      userMarkets,
      areas
    }
  } catch (error) {
    throw new Response(null, { status: 404 })
  }
}

export function ReportsPage() {
  const { t } = useTranslation(['reports', 'common'])
  const { updateTitles } = useTitle()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')

  useEffect(() => {
    updateTitles({
      mobile: {
        title: t('reports'),
        description: null
      }
    })
  }, [updateTitles, t])

  return (
    <ElasticContentContainer
      overflowHidden={true}
      header={
        <>
          {isLg && (
            <Row>
              <Column width={12}>
                <PageHeader title={t('reports')} />
              </Column>
            </Row>
          )}
          <StyledRow>
            <NavigationTabs>
              <NavigationTabs.Tab to='area-summary'>
                <BadgeTab>
                  <CapitalizeFirstLetter>
                    {t('area-summary', { ns: 'reports' })}
                  </CapitalizeFirstLetter>
                </BadgeTab>
              </NavigationTabs.Tab>
              <NavigationTabs.Tab to='country-summary'>
                <BadgeTab>
                  <CapitalizeFirstLetter>
                    {t('country-summary', { ns: 'reports' })}
                  </CapitalizeFirstLetter>
                </BadgeTab>
              </NavigationTabs.Tab>
              <NavigationTabs.Tab to='top-market-deviations'>
                <BadgeTab>
                  <CapitalizeFirstLetter>
                    {t('top-market-deviations', { ns: 'reports' })}
                  </CapitalizeFirstLetter>
                </BadgeTab>
              </NavigationTabs.Tab>
            </NavigationTabs>
          </StyledRow>
        </>
      }
    >
      <StyledRow fullHeight>
        <ScrollableWrapper>
          <Outlet />
        </ScrollableWrapper>
      </StyledRow>
    </ElasticContentContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    switch (error.status) {
      case 401:
        message = t('not-logged-in')
        break
      case 403:
        message = t('access-denied')
        break
    }
    return (
      <Container
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column width={12} padding={isLg}>
            <EmptyState
              title={t('could-not-fetch-reports')}
              description={message}
            />
          </Column>
        </Row>
      </Container>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
