import type { Deviation } from '@cdab/scania/qpr/schema'

type DeviationDates = Pick<Deviation, 'approvalDate' | 'expirationDate'>

export function restoreDeviationDates(deviations: DeviationDates[]) {
  deviations.forEach(d => {
    if (d.approvalDate) {
      d.approvalDate = new Date(d.approvalDate)
    }

    if (d.expirationDate) {
      d.expirationDate = new Date(d.expirationDate)
    }
  })
}
