import {
  ClickableList,
  ClickableListItem
} from '@cdab/scania/qpr/components/atoms'
import type { ADHActionPlanItem } from '@cdab/scania/qpr/schema'
import { formatISODate } from '@cdab/utils'
import { TdsBadge } from '@scania/tegel-react'
import type { ADHDeviationsListProps } from './adh-deviations-list.types'

type ADHDeviationListItem = {
  id: number
  actionPlanItemId: number
  deviation: string
  expirationDate: string | undefined
  finishedDate: string | undefined
  responsible: string
  countOfUnreadMessages: number
}

function getItemColor(expiryDate?: string, finishedDate?: string) {
  if (finishedDate) {
    return 'var(--tds-green-100)'
  } else if (expiryDate) {
    if (new Date(expiryDate).getTime() <= new Date().getTime()) {
      return 'var(--tds-red-100)'
    } else {
      return 'var(--tds-yellow-100)'
    }
  }

  return undefined
}

function sortDeviations(data: ADHActionPlanItem[]) {
  return data
    .map(deviation => {
      const countMessages = deviation.chatMessages.filter(
        msg => !msg.readByDealer
      ).length

      return {
        ...deviation,
        countOfUnreadMessages: countMessages
      }
    })
    .sort((a, b) => {
      return b.countOfUnreadMessages - a.countOfUnreadMessages
    })
}

export function ADHDeviationsList(props: Readonly<ADHDeviationsListProps>) {
  const deviations: ADHDeviationListItem[] = sortDeviations(
    props.data.actionPlanItems
  )

  return (
    <ClickableList scrollable={false} style={{ overflow: 'hidden' }}>
      {deviations.map(deviation => (
        <ClickableListItem
          backgroundColor={getItemColor(
            deviation.expirationDate,
            deviation.finishedDate
          )}
          key={deviation.id}
          title={deviation.deviation}
          description={
            deviation.expirationDate
              ? 'Expire date: ' +
                formatISODate(new Date(deviation.expirationDate))
              : ''
          }
          onClick={() =>
            props.onClick(deviation.id, deviation.actionPlanItemId)
          }
          rightItem={
            deviation.countOfUnreadMessages > 0 && (
              <TdsBadge value={deviation.countOfUnreadMessages.toString()} />
            )
          }
        />
      ))}
    </ClickableList>
  )
}
