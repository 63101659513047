import type { Audit } from '@cdab/scania/qpr/schema'

import type { AuditModelBase } from './base'
import { restoreDeviationDates } from './restoration.utils'
import type { AuditModelV1, AuditModelV1Data, FileData } from './v1'

export type AuditModelV2Data = AuditModelV1Data & {
  files: FileData[]
  isGettingFiles: boolean
}

export type AuditModelV2 = AuditModelBase<AuditModelV2Data, 2>

export function restoreAuditFromDataV2(audit: AuditModelV2) {
  restoreDeviationDates(audit.deviations)

  return audit
}

export function createFromAuditV2(audit: Audit) {
  const auditModel: AuditModelV2 = {
    ...audit,
    deviations: audit.deviations.map(d => ({
      ...d,
      files: [],
      isGettingFiles: false
    })),
    auditPoints: audit.auditPoints.map(ap => ({
      ...ap,
      files: [],
      isGettingFiles: false
    })),
    pledges: audit.pledges.map(pl => ({
      ...pl,
      files: [],
      isGettingFiles: false
    })),
    files: [],
    isGettingFiles: false,
    VERSION: 2
  }

  return auditModel
}

export function migrateToV2(audit: AuditModelV1): AuditModelV2 {
  const a: AuditModelV2 = Object.assign(audit, {
    files: [],
    isGettingFiles: false,
    VERSION: 2 as const
  })

  return a
}
