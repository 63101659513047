import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'

export interface HeaderMenuItemProps {
  active?: boolean
  text: string
  to: string
}

export function HeaderMenuItem({ active, text, to }: HeaderMenuItemProps) {
  const navigate = useNavigate()

  const onNavigate = () => {
    navigate(to)
  }

  return (
    <li
      className={classnames('sdds-nav__item', {
        'sdds-nav__item--active': active
      })}
      onClick={onNavigate}
    >
      <div className='sdds-nav__item-core'>
        <p className='sdds-nav__item-core-text'>{text}</p>
      </div>
    </li>
  )
}
