import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ClickableList,
  ClickableListItem,
  EmptyState,
  RightActionButton
} from '@cdab/scania/qpr/components/atoms'
import type { FileData } from '@cdab/scania/qpr/schema'
import type { BlockProps } from '@cdab/scania/sdds'
import { Block, IconCross } from '@cdab/scania/sdds'

import { Title } from './attachment-list.styles'

type FileType = 'images' | 'other' | 'all'

interface AttachmentListProps extends Pick<BlockProps, 'color'> {
  files?: FileData[]
  onDeleteAttachment?: (id: FileData['id']) => void
  disableDelete: boolean
  fileType?: FileType
  title?: string
  hideUploadProgress?: boolean
  hideUploadPrompt?: boolean
}

type FileRowProps = {
  file: FileData
  onDelete?: (id: FileData['id']) => void
  disableDelete: boolean
  hideUploadProgress?: boolean
}

const FileRow = observer(function FileRow({
  file,
  onDelete,
  disableDelete,
  ...props
}: FileRowProps) {
  const { fileName, id, url, isImage } = file
  const previewImage = true
  const tooltipId = 'id-' + id.toString()

  const onDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      const fileId = e.currentTarget.dataset['fileId']
      if (fileId && onDelete) {
        onDelete(fileId)
      }
    },
    [onDelete]
  )

  return (
    <ClickableListItem
      breakTitleWords
      breakDescriptionWords
      key={id}
      title={fileName}
      url={url}
      openInNewTab
      thumbnail={
        previewImage && isImage
          ? {
              src: url,
              alt: 'User uploaded'
            }
          : undefined
      }
      rightItem={
        !disableDelete && (
          <RightActionButton
            id={tooltipId}
            icon={<IconCross />}
            data-file-id={id}
            onClick={onDeleteClick}
          />
        )
      }
    >
      {!props.hideUploadProgress && file.uploadProgress !== 1 && (
        <progress max={1} value={file.uploadProgress}>
          {Math.round(file.uploadProgress * 100)}%
        </progress>
      )}
    </ClickableListItem>
  )
})

function filterFiles(files: FileData[], fileType: FileType): FileData[] {
  if (fileType === 'images') {
    return files.filter((file: FileData) => file.isImage)
  }

  if (fileType === 'other') {
    return files.filter((file: FileData) => !file.isImage)
  }

  return files
}

export function AttachmentList({
  files = [],
  onDeleteAttachment,
  disableDelete,
  fileType = 'all',
  title,
  color,
  hideUploadProgress,
  ...props
}: AttachmentListProps): JSX.Element | null {
  const { t } = useTranslation('audit')

  const filteredFiles = filterFiles(files, fileType)

  if (filteredFiles.length === 0) {
    return (
      <Block color={color} header={title} divider>
        <EmptyState
          title={t('attachments.empty-state.title')}
          description={
            props.hideUploadPrompt
              ? undefined
              : t('attachments.empty-state.description')
          }
        />
      </Block>
    )
  }

  return (
    <>
      {title && <Title>{title}</Title>}
      <Block color={color} disablePadding>
        <ClickableList>
          {filteredFiles.map(file => (
            <FileRow
              key={file.id}
              file={file}
              onDelete={onDeleteAttachment}
              disableDelete={disableDelete}
              hideUploadProgress={hideUploadProgress}
            />
          ))}
        </ClickableList>
      </Block>
    </>
  )
}
