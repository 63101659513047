import { useCallback, useEffect, useState } from 'react'

import { Block } from '@cdab/scania/sdds'

import { Editor } from '@cdab/scania/qpr/components/molecules'
import { useFeatures } from '@cdab/scania/qpr/feature-flags'
import {
  useCreateTranslation,
  useUpdateTranslation
} from '@cdab/scania/qpr/hooks'
import parse from 'html-react-parser'
import {
  AdditionalInfoBlock,
  AdditionalInfoContent,
  AdditionalInfoItem,
  AdditionalInfoTitle,
  BaseTranslation,
  EmptyBaseTranslation,
  SavedText,
  StyledBlock,
  TextHeaderWrapper,
  TranslationItemWrapper,
  TranslationNo,
  TranslationTextAreaWrapper
} from './admin-translation-item.styles'
import type { AdminTranslationItemProps } from './admin-translation-item.types'

const SAVED_TEXT_DURATION = 1000

export function AdminTranslationItem(props: AdminTranslationItemProps) {
  const {
    id,
    translationNo,
    baseText,
    translationGuid,
    languageId,
    translatedText,
    showSavedErrorText,
    additionalInfo,
    showEditor,
    onEnableEditor,
    onSaveTranslation,
    disable = false
  } = props
  const { render_html } = useFeatures(['render_html'])
  const [savedTextIsVisible, setSavedTextIsVisible] = useState(false)
  const [errorTextIsVisible, setErrorTextIsVisible] = useState(false)

  const [editedTextState, setEditedTextState] = useState<string>(
    translatedText ?? ''
  )
  const [translatedTextState, setTranslatedTextState] = useState<string>(
    translatedText ?? ''
  )
  const [idState, setIdState] = useState<number | null>(id)

  const { updateTranslation } = useUpdateTranslation()
  const { createTranslation } = useCreateTranslation()

  const onBlur = useCallback(async () => {
    // We don't need to save if we haven't changed the text
    if (editedTextState === translatedTextState) {
      onEnableEditor('') //hide editor
      return
    }

    if (idState) {
      //update
      const response = await updateTranslation(idState, editedTextState)
      if (response === 'success') {
        setTranslatedTextState(editedTextState)
        onSaveTranslation(translationGuid, editedTextState)
        onEnableEditor('') //hide editor
        setSavedTextIsVisible(true)
      } else {
        setErrorTextIsVisible(true)
      }
    } else {
      //create
      const response = await createTranslation(
        languageId,
        translationGuid,
        editedTextState
      )
      if (response.status === 'success') {
        setIdState(response.id)
        setTranslatedTextState(editedTextState)
        onEnableEditor('') //hide editor
        setSavedTextIsVisible(true)
      } else {
        setErrorTextIsVisible(true)
      }
    }
  }, [
    editedTextState,
    translatedTextState,
    idState,
    onEnableEditor,
    updateTranslation,
    createTranslation,
    languageId,
    translationGuid
  ])

  // When text was saved, show the saved text for 2 seconds
  useEffect(() => {
    const handlerShowReslutTextareaText = setTimeout(() => {
      setSavedTextIsVisible(false)
      setErrorTextIsVisible(false)
    }, SAVED_TEXT_DURATION)

    return () => {
      clearTimeout(handlerShowReslutTextareaText)
    }
  })

  const onChangeText = useCallback((value: string) => {
    setEditedTextState(value)
  }, [])

  return (
    <Block color={'on-grey'} disablePadding={false}>
      <TranslationItemWrapper>
        <TranslationNo>{translationNo}</TranslationNo>
        <TranslationTextAreaWrapper>
          <TextHeaderWrapper>
            {baseText !== '' ? (
              <BaseTranslation>
                {render_html.enabled
                  ? baseText
                    ? parse(baseText)
                    : baseText
                  : baseText}
              </BaseTranslation>
            ) : (
              <EmptyBaseTranslation>
                {'Missing original text'}
              </EmptyBaseTranslation>
            )}
            {showSavedErrorText &&
              (savedTextIsVisible || errorTextIsVisible) && (
                <SavedText show={true} success={!!savedTextIsVisible}>
                  {savedTextIsVisible ? 'Saved!' : 'Error!'}
                </SavedText>
              )}
          </TextHeaderWrapper>
          <div className='sdds-on-white-bg'>
            <Editor
              autofocus
              content={editedTextState}
              onChange={onChangeText}
              onBlur={onBlur}
              maxLength={4000}
              key={translationGuid}
              placeholder='Not translated'
              showEditor={showEditor}
              onFocusTextarea={() =>
                !disable && onEnableEditor(translationGuid)
              }
            />
          </div>
        </TranslationTextAreaWrapper>
        {additionalInfo && (
          <div style={{ display: 'flex' }}>
            <StyledBlock color={'on-grey'} disablePadding={false}>
              <AdditionalInfoBlock>
                {additionalInfo.map(ai => {
                  return (
                    <AdditionalInfoItem key={ai.id}>
                      <AdditionalInfoTitle>{ai.title}</AdditionalInfoTitle>
                      <AdditionalInfoContent>{ai.text}</AdditionalInfoContent>
                    </AdditionalInfoItem>
                  )
                })}
              </AdditionalInfoBlock>
            </StyledBlock>
          </div>
        )}
      </TranslationItemWrapper>
    </Block>
  )
}
