import {
  StyledInput,
  StyledLabel,
  StyledSpan,
  StyledToggle
} from './toggle.styles'
import type { ToggleProps } from './toggle.types'

export function Toggle({ disabled = false, ...props }: ToggleProps) {
  return (
    <StyledToggle disabled={disabled}>
      <StyledInput
        id={props.id}
        checked={props.checked}
        onChange={props.onChange}
      ></StyledInput>
      <StyledSpan />
      <StyledLabel>{props.label}</StyledLabel>
    </StyledToggle>
  )
}
