import {
  AuditNote,
  AuditPointInfoCard,
  PointCompliantCard
} from '@cdab/scania/qpr/components'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { auditPointsController } from '@cdab/scania/qpr/offline/controllers'
import { Column, Container, Divider, Row } from '@cdab/scania/sdds'
import { isNumber } from '@cdab/utils'
import parse from 'html-react-parser'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useRouteLoaderData } from 'react-router-dom'
import styled from 'styled-components'
import invariant from 'tiny-invariant'
import type { AuditLoaderData } from '../../../../auditId'
import type { AuditPointLoaderData } from '../audit-point-base-view'

type Params = {
  auditId: string
  auditPointId: string
}

const StyledRow = styled(Row)`
  margin-top: var(--sdds-spacing-element-16);
`

const StyledAuditNote = styled(AuditNote)`
  margin-top: var(--sdds-spacing-element-16);
`
const Content = styled.div`
  height: 100%;
  padding-bottom: var(--sdds-spacing-element-40);
`

export const StatusTab = observer(() => {
  const { t } = useTranslation('audit')
  const { auditId: sAuditId } = useParams<Params>()
  const { updateTitles } = useTitle()

  const auditId = Number.parseInt(sAuditId ?? '')
  invariant(
    isNumber(auditId),
    `StatusTab: expected auditId to be number but was: ${auditId}`
  )

  const { audit } = useRouteLoaderData('audit') as AuditLoaderData
  const { auditPoint } = useRouteLoaderData(
    'audit-point'
  ) as AuditPointLoaderData

  useEffect(() => {
    updateTitles({
      mobile: {
        title: `${t('audit-point')} ${auditPoint.auditPointNo}`,
        description: null
      }
    })
  }, [auditPoint.auditPointNo, t, updateTitles])

  const onScoreChange = useCallback(
    (score: boolean | null) => {
      auditPointsController.SetScore(auditId, auditPoint.id, score)
    },
    [auditId, auditPoint.id]
  )

  const onSaveNote = useCallback(
    (note: string) => {
      auditPointsController.SetNote(auditId, auditPoint.id, note)
    },
    [auditId, auditPoint.id]
  )

  return (
    <Content>
      <Container>
        <StyledRow>
          <Column width={12} lg={6} fullHeight>
            <AuditPointInfoCard
              description={parse(t(auditPoint.translationGuid))}
              purposeDescription={parse(t(auditPoint.purposeTranslationGuid))}
            />
          </Column>
          <Column width={12} lg={6}>
            <PointCompliantCard
              onCompliantChange={onScoreChange}
              compliant={auditPoint.score}
              disabled={audit.isReadonly}
            />
            <Divider type={'light'} />
            <StyledAuditNote
              note={auditPoint.note ?? ''}
              onSaveNote={onSaveNote}
              disabled={audit.isReadonly}
            />
          </Column>
        </StyledRow>
      </Container>
    </Content>
  )
})
