import { useCallback } from 'react'
import styled from 'styled-components'

import {
  ClickableList,
  ClickableListItem,
  RightActionButton
} from '@cdab/scania/qpr/components/atoms'
import type { SystemMessage } from '@cdab/scania/qpr/schema'
import { Block, IconCross } from '@cdab/scania/sdds'

import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { getFirstLineFromHtmlText } from '@cdab/utils'
import type { SystemMessagesWidgetProps } from './system-messages-widget.types'

const StyledBlock = styled(Block)`
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr;
  height: 100%;
`

export function SystemMessagesWidget({
  systemMessages,
  showMarkAsReadMessageButton,
  onClickItem,
  onClickDelete
}: SystemMessagesWidgetProps) {
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')

  const onClickDeleteMessage = (
    e: React.MouseEvent,
    message: SystemMessage
  ) => {
    e.stopPropagation()
    onClickDelete(message.id)
  }

  const onClick = useCallback(
    (message: SystemMessage) => () => {
      onClickItem(message)
    },
    [onClickItem]
  )

  return (
    <StyledBlock color={isLg ? 'on-grey' : 'on-white'} disablePadding={!isLg}>
      <ClickableList scrollable>
        {systemMessages.map(systemMessage => {
          return (
            <ClickableListItem
              key={systemMessage.id}
              title={systemMessage.title}
              description={getFirstLineFromHtmlText(systemMessage.text)}
              onClick={onClick(systemMessage)}
              rightItem={
                showMarkAsReadMessageButton && (
                  <RightActionButton
                    id={systemMessage.id.toString()}
                    onClick={event =>
                      onClickDeleteMessage(event, systemMessage)
                    }
                    icon={<IconCross />}
                  />
                )
              }
            />
          )
        })}
      </ClickableList>
    </StyledBlock>
  )
}
