import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { DocumentFile } from '@cdab/scania/qpr/schema'
import { useCallback, useState } from 'react'
import { action } from 'mobx'
import type { UpdateDocumentData } from '@cdab/scania/qpr/interactor'
import { useTranslation } from 'react-i18next'

export type UpdateDocumentFileState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'uploading'
      progress: number
    }
  | {
      status: 'none'
      message: null
    }

export function useUpdateDocumentWithFile(
  data: UpdateDocumentData,
  documentFile: DocumentFile | undefined
) {
  const { t } = useTranslation('documents')
  const [submitState, setSubmitState] = useState<UpdateDocumentFileState>({
    status: 'none',
    message: null
  })
  const client = useBackend()
  const handleClickUploadAndUpdateDocument = useCallback(async () => {
    setSubmitState({
      status: 'uploading',
      progress: 0
    })

    if (documentFile) {
      // file should be updated
      let uploadPromise = undefined

      if (data.documentFileId) {
        uploadPromise = client.StorageService.ReplaceDocumentFile(
          data.documentFileId,
          documentFile.file,
          action(progressEvent => {
            if (typeof progressEvent.progress === 'undefined') return
            setSubmitState({
              status: 'uploading',
              progress: progressEvent.progress
            })
          })
        )
      } else {
        uploadPromise = client.StorageService.UploadDocumentFile(
          documentFile.file,
          action(progressEvent => {
            if (typeof progressEvent.progress === 'undefined') return
            setSubmitState({
              status: 'uploading',
              progress: progressEvent.progress
            })
          })
        )
      }

      const updatePromise = client.DocumentService.UpdateDocument({
        ...data,
        documentFileId: await uploadPromise
      })

      const [uploadResult, updateResult] = await Promise.allSettled([
        uploadPromise,
        updatePromise
      ])

      if (uploadResult.status === 'rejected') {
        setSubmitState({
          status: 'error',
          message: t('message.error-upload')
        })
        return
      }

      if (
        updateResult.status === 'rejected' &&
        uploadResult.status === 'fulfilled'
      ) {
        await client.StorageService.DeleteDocumentFile(uploadResult.value)
        setSubmitState({
          status: 'error',
          message: t('message.error')
        })
        return
      }
    } else {
      //we need to update only document
      try {
        await client.DocumentService.UpdateDocument({
          ...data
        })
      } catch (e) {
        setSubmitState({
          status: 'error',
          message: t('message.error')
        })
        return
      }
    }

    setSubmitState({ status: 'success', message: t('message.success') })
  }, [documentFile, t, client.StorageService, client.DocumentService, data])
  return [
    submitState,
    handleClickUploadAndUpdateDocument,
    setSubmitState
  ] as const
}
