import { useFeatures } from '@cdab/scania/qpr/feature-flags'
import { capitalize } from '@cdab/scania/sdds'
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import CharacterCount from '@tiptap/extension-character-count'
import Document from '@tiptap/extension-document'
import Heading from '@tiptap/extension-heading'
import History from '@tiptap/extension-history'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Strike from '@tiptap/extension-strike'
import Text from '@tiptap/extension-text'
import Underline from '@tiptap/extension-underline'
import { useEditor } from '@tiptap/react'
import parse from 'html-react-parser'
import { useRef } from 'react'
import {
  RichControlsWrapper,
  StyledContentWrapper,
  StyledEditorContent,
  StyledFakeToolbarDiv,
  StyledTextContent
} from './editor.styles'
import { RichTextOptions } from './rich-text-options'

export const Editor = ({
  autofocus = false,
  content,
  hasError = false,
  label,
  // disabled, // TODO: Implement me
  onBlur,
  maxLength,
  onChange,
  placeholder = '',
  showEditor = true,
  onFocusTextarea,
  ...props
}: {
  autofocus?: boolean
  content?: string
  hasError?: boolean
  // disabled?: boolean
  maxLength?: number
  helper?: string | false | null
  label?: string
  onBlur?: (length: number) => void
  onChange?: (value: string) => void
  placeholder?: string
  showEditor?: boolean
  onFocusTextarea?: () => void
}) => {
  const showingEditor = useRef(showEditor)
  const { render_html } = useFeatures(['render_html'])

  //all new possibilities to add line breaks should be added to getFirstLineFromHtmlText util function as well
  const editor = useEditor({
    extensions: [
      Bold,
      BulletList,
      CharacterCount.configure({
        limit: maxLength ?? 0
      }),
      Document,
      Heading.configure({
        levels: [4, 5, 6]
      }),
      History,
      Italic,
      ListItem,
      OrderedList,
      Paragraph,
      Placeholder.configure({
        placeholder
      }),
      Strike,
      Text,
      Underline,
      Link.configure({
        HTMLAttributes: { class: 'sdds-link' },
        autolink: true,
        linkOnPaste: true
      })
    ],
    content,
    autofocus,
    editable: true,
    editorProps: {
      attributes: {
        class: 'editor sdds-textfield-input'
      }
    },

    onBlur({ editor, event }) {
      //we shouldn't trigger after clicking the toolbar button
      if (event.relatedTarget == null)
        onBlur?.(editor.storage['characterCount']?.characters())
    },
    onUpdate({ editor }) {
      onChange?.(editor.getHTML())
    }
  })

  if (!editor) return null

  // after enabling the editor we should do focus
  if (!showingEditor.current && showEditor && !editor.isFocused && autofocus) {
    editor.commands.focus()
  }
  showingEditor.current = showEditor

  const helper = props.helper ? capitalize(props.helper) : ''

  return (
    <div
      className={`sdds-form-textfield ${
        hasError ? ' sdds-form-textfield-error' : ''
      }`}
    >
      <div className='sdds-textfield-data' style={{ height: 'auto' }}>
        <div className='sdds-textfield-slot-wrap-label'>
          {label && <span className='sdds-text-label'>{label}</span>}
        </div>
        {showEditor ? (
          <RichControlsWrapper>
            <RichTextOptions editor={editor} />
          </RichControlsWrapper>
        ) : (
          <StyledFakeToolbarDiv />
        )}
        <div
          className='sdds-textfield-container'
          style={{ height: 'auto', display: 'flex', flexDirection: 'column' }}
        >
          <div className='sdds-textfield-input-container'>
            {showEditor ? (
              <StyledEditorContent editor={editor} />
            ) : (
              <StyledContentWrapper
                onClick={() => onFocusTextarea && onFocusTextarea()}
              >
                <StyledTextContent>
                  {render_html.enabled
                    ? content
                      ? parse(content)
                      : content
                    : content}
                </StyledTextContent>
              </StyledContentWrapper>
            )}
          </div>

          {/* FIXME: This is not working */}
          {/* <div className='sdds-textfield-bar' /> */}
        </div>
      </div>
      <div className='sdds-textfield-helper'>
        <div
          className={`sdds-textfield-helper-wrapper ${
            hasError
              ? 'sdds-form-textfield-error'
              : 'sdds-form-textfield-success'
          }`}
        >
          {hasError && (
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8 2.00015C4.6853 2.00015 1.9982 4.68725 1.9982 8.00195C1.9982 11.3167 4.6853 14.0038 8 14.0038C11.3147 14.0038 14.0018 11.3167 14.0018 8.00195C14.0018 4.68725 11.3147 2.00015 8 2.00015ZM1 8.00195C1 4.13596 4.13401 1.00195 8 1.00195C11.866 1.00195 15 4.13596 15 8.00195C15 11.8679 11.866 15.002 8 15.002C4.13401 15.002 1 11.8679 1 8.00195Z'
                fill='#FF2340'
              />
              <path
                d='M7.4014 7.2352V5H8.5894V7.2352L8.4134 9.3824H7.5774L7.4014 7.2352ZM7.375 10.0512H8.6246V11.248H7.375V10.0512Z'
                fill='#FF2340'
              />
            </svg>
          )}
          <span className='sdds-textfield-helper'>{helper}</span>
        </div>

        {maxLength && maxLength > 0 && (
          <div className='sdds-textfield-textcounter'>
            {editor.storage['characterCount']?.characters()}
            <span className='sdds-textfield-textcounter-divider'> / </span>
            {maxLength}
          </div>
        )}
      </div>
    </div>
  )
}
