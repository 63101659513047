import { action, toJS } from 'mobx'
import invariant from 'tiny-invariant'

import type { FileData } from '@cdab/scania/qpr/schema'

import type { AuditModel, AuditPointModel } from '../audit-model'
import { auditsStore } from './store'
import { syncRemovedFiles } from './utilities'

export function getAuditPoint(
  auditId: AuditModel['id'],
  auditPointId: AuditPointModel['id']
): AuditPointModel {
  const audit = auditsStore[auditId]
  invariant(audit)

  const auditPoint = audit.auditPoints.find(ap => ap.id === auditPointId)
  invariant(auditPoint)

  return auditPoint
}

export const addAuditPointFiles = action(
  (
    auditId: AuditModel['id'],
    auditPointId: AuditPointModel['id'],
    filesToAdd: FileData[]
  ) => {
    const auditPoint = getAuditPoint(auditId, auditPointId)

    syncRemovedFiles(auditPoint.files, filesToAdd)

    filesToAdd.forEach(fileToAdd => {
      const fileIndex = auditPoint.files.findIndex(f => f.id === fileToAdd.id)

      if (fileIndex !== -1) {
        const file = auditPoint.files[fileIndex]

        file.fileName = fileToAdd.fileName
        file.isImage = fileToAdd.isImage
        file.isUploaded = fileToAdd.isUploaded
        file.url = fileToAdd.url
      } else {
        auditPoint.files.push(fileToAdd)
      }
    })
  }
)

export const deleteAuditPointFile = action(
  (
    audit: AuditModel,
    auditPointId: AuditPointModel['id'],
    fileId: FileData['id']
  ): FileData | undefined => {
    const auditPoint = audit.auditPoints.find(ap => ap.id === auditPointId)
    invariant(
      auditPoint,
      `Cannot delete audit point file if we cannot find audit point!`
    )

    const fileIndex = auditPoint.files.findIndex(f => f.id === fileId)

    if (fileIndex === -1) {
      return
    }

    const [mobxFile] = auditPoint.files.splice(fileIndex, 1)

    const file = toJS(mobxFile)

    return file
  }
)
