import { useTranslation } from 'react-i18next'
import invariant from 'tiny-invariant'

import { getEnvVarsQPR } from '@cdab/scania/qpr/env-vars'
import type { AuditModel } from '@cdab/scania/qpr/offline/models'
import type { Audit } from '@cdab/scania/qpr/schema'
import { Button } from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'

function getPrintNotesDosVersion(dosVersion: Audit['dosVersion']): string {
  switch (dosVersion) {
    case 6:
      return 'DOS 5'

    case 5:
      return 'DOS 4'

    default:
      return ''
  }
}

export function PrintAuditNotesButton(props: { audit: AuditModel }) {
  const { t } = useTranslation('audit')
  const { qpr2007url } = getEnvVarsQPR()

  const { id, date, did } = props.audit
  invariant(date, `Cannot print notes without audit date`)
  const auditdate = new Date(date)

  const dosVersion = getPrintNotesDosVersion(props.audit.dosVersion)

  return (
    <a
      href={`${qpr2007url}/NotesPrint.aspx?auditID=${id}&auditDate=${
        auditdate.getMonth() + 1
      }/${auditdate.getDate()}/${auditdate.getFullYear()}&DID=${did}&DOSVersion=${dosVersion}&PageTo=SearchAndPlan.aspx&closeTab=true`}
      target='_blank'
      rel='noreferrer'
    >
      <Button fullBleed text={capitalizeFirstLetter(t('audit-notes'))} />
    </a>
  )
}
