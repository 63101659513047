import { parseDate } from '@cdab/utils'
import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { z } from 'zod'
import { ajv, isValidThing } from '../ajv'
import { FileDataSchema } from '../file-data'

// This enum's values needs to be kept up to date with backend
export enum CertificationStatus {
  Certified = 'Certified',
  Expired = 'Expired',
  NeverCertified = 'NeverCertified'
}

function stringToCertificationStatus(s: string): CertificationStatus {
  switch (s) {
    case CertificationStatus.Certified:
      return CertificationStatus.Certified

    case CertificationStatus.Expired:
      return CertificationStatus.Expired

    case CertificationStatus.NeverCertified:
      return CertificationStatus.NeverCertified

    default:
      throw new Error(`Invalid Certification Status string: ${s}`)
  }
}

export type Dealer = {
  auditDate: Date | null
  bus: boolean
  busSales: boolean | null
  city: string
  certificationStatus: CertificationStatus
  dealerId: string
  engine: boolean
  engineSales: boolean | null
  expiryDate: Date | null
  hasDeviations: boolean
  id: number
  name: string
  partsSales: boolean | null
  truck: boolean
  truckSales: boolean | null
  marketId: number
  marketName: string
}

export interface DealerJson {
  auditDate: string | null
  bus: boolean
  busSales: boolean | null
  certificationStatus: string
  dealerCity: string
  dealerId: string
  dealerName: string
  engine: boolean
  engineSales: boolean | null
  expiryDate: string | null
  hasDeviations: boolean
  id: number
  partsSales: boolean | null
  truck: boolean
  truckSales: boolean | null
  marketId: number
  marketName: string
}

const DealerSchema: JSONSchemaType<DealerJson> = {
  type: 'object',

  properties: {
    auditDate: {
      type: 'string',
      format: 'iso-date-time',
      nullable: true
    },
    bus: {
      type: 'boolean'
    },
    busSales: {
      type: 'boolean',
      nullable: true
    },
    certificationStatus: {
      type: 'string',
      nullable: false
    },
    dealerCity: {
      type: 'string'
    },
    dealerId: {
      type: 'string'
    },
    engine: {
      type: 'boolean'
    },
    engineSales: {
      type: 'boolean',
      nullable: true
    },
    expiryDate: {
      type: 'string',
      format: 'iso-date-time',
      nullable: true
    },
    hasDeviations: {
      type: 'boolean'
    },
    id: {
      type: 'integer'
    },
    dealerName: {
      type: 'string'
    },
    partsSales: {
      type: 'boolean',
      nullable: true
    },
    truck: {
      type: 'boolean'
    },
    truckSales: {
      type: 'boolean',
      nullable: true
    },
    marketId: {
      type: 'number',
      nullable: false
    },
    marketName: {
      type: 'string',
      nullable: false
    }
  },

  required: [
    'auditDate',
    'bus',
    'busSales',
    'dealerCity',
    'dealerId',
    'engine',
    'engineSales',
    'expiryDate',
    'hasDeviations',
    'id',
    'dealerName',
    'partsSales',
    'truck',
    'truckSales',
    'marketId',
    'marketName'
  ]
}

const validateDealer: ValidateFunction<DealerJson> = ajv.compile(DealerSchema)

export const isValidDealerJson = (dealer: unknown): dealer is DealerJson => {
  return isValidThing<DealerJson>(dealer, validateDealer)
}

export function createDealerFromJson(json: DealerJson): Dealer {
  const certificationStatus = stringToCertificationStatus(
    json.certificationStatus
  )

  return {
    auditDate: json.auditDate ? parseDate(json.auditDate) : null,
    bus: json.bus,
    busSales: json.busSales,
    city: json.dealerCity,
    certificationStatus,
    dealerId: json.dealerId,
    engine: json.engine,
    engineSales: json.engineSales,
    expiryDate: json.expiryDate ? parseDate(json.expiryDate) : null,
    hasDeviations: json.hasDeviations,
    id: json.id,
    name: json.dealerName,
    partsSales: json.partsSales,
    truck: json.truck,
    truckSales: json.truckSales,
    marketId: json.marketId,
    marketName: json.marketName
  }
}

export const DealerFileSchema = z
  .object({
    file: z.instanceof(File)
  })
  .extend(FileDataSchema.pick({ id: true, url: true }).shape)

export type DealerFile = z.infer<typeof DealerFileSchema>
