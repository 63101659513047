import { Button, Message, Row } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const StyledRow = styled(Row)`
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`
export const StyledFirstButton = styled(Button)`
  padding-right: 2rem;
`
export const StyledButton = styled(Button)`
  flex: 0.13;
  padding-right: 2rem;
`

export const StyledMessage = styled(Message)`
  flex: auto;
`

export const Title = styled.h3.attrs({
  className: 'sdds-headline-03'
})`
  margin-bottom: 2rem;
  margin-top: 2rem;
`

export const ButtonMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 4rem;
  @media (max-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 3rem;
  }
`
