import { z } from 'zod'
import { FileDataSchema } from './file-data'

export const UserFileSchema = z
  .object({
    file: z.instanceof(File)
  })
  .extend(FileDataSchema.pick({ id: true, url: true }).shape)

export type UserFile = z.infer<typeof UserFileSchema>
