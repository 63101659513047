import type { Deviation } from '@cdab/scania/qpr/schema'
import { v4 as uuidv4 } from 'uuid'

// TODO: Move to mockdata package
export const MockDeviation123: Deviation = {
  id: 37362,
  clientGuid: uuidv4(),
  auditId: 12345,
  approvalDate: null,
  auditPointId: 743,
  auditPointNumber: '1.1',
  deviation: 'Test deviation',
  deviationWithoutActions: false,
  expirationDate: new Date('2022-08-12'),
  proposedActions: 'Proposed actions',
  responsible: null,
  actionPlanId: 1
}

export const MockDeviation987: Deviation = {
  id: 73627,
  clientGuid: uuidv4(),
  auditId: 12345,
  approvalDate: null,
  auditPointId: 743,
  auditPointNumber: '1.1',
  deviation: 'Test deviation 2',
  deviationWithoutActions: false,
  expirationDate: new Date('2022-08-12'),
  proposedActions: null,
  responsible: 'Dennis Jutemark',
  actionPlanId: 1
}

export const MockDeviations: Deviation[] = [MockDeviation123, MockDeviation987]
