import { useCallback, useEffect, useState } from 'react'

export function wrapPromise<T>(promise: Promise<T>) {
  let state:
    | { status: 'pending' }
    | { status: 'success'; result: T }
    | { status: 'error'; error: unknown } = { status: 'pending' }

  const suspend = promise.then(
    res => {
      state = {
        status: 'success',
        result: res
      }
    },
    err => {
      state = {
        status: 'error',
        error: err
      }
    }
  )

  return {
    read() {
      if (state.status === 'pending') throw suspend
      else if (state.status === 'error') throw state.error

      return state.result
    }
  }
}

export function transformResource<TIn, TOut>(
  resource: SuspendedPromise<TIn>,
  transform: (data: TIn) => TOut
): SuspendedPromise<TOut> {
  return {
    read: () => transform(resource.read())
  }
}

export function useAsResource<T>(
  fn: () => Promise<T>,
  deps: unknown[]
): SuspendedPromise<T> & { reload: () => void } {
  const [wrappedPromise, setWrappedPromise] = useState(
    wrapPromise(new Promise<T>(() => undefined))
  )

  const reload = useCallback(() => {
    setWrappedPromise(wrapPromise(fn()))
  }, [])

  useEffect(() => {
    reload()
  }, deps)

  return {
    ...wrappedPromise,
    reload
  }
}

export type SuspendedPromise<T> = ReturnType<typeof wrapPromise<T>>
