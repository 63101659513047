import { TdsTextarea } from '@scania/tegel-react'
import styled from 'styled-components'

export const Actions = styled.form`
  align-items: center;
  border-top: 1px solid var(--tds-divider-background);
  display: flex;
  gap: 4rem;
  padding: 0 2rem;
  width: 100%;
  background: white;
`

export const UserInput = styled(TdsTextarea)`
  display: flex;
  flex: 1;

  .textarea-input.sc-tds-textarea {
    resize: none;
  }
`

export const StyledImg = styled.img`
  height: 16rem;
  object-fit: cover;
  overflow: hidden;
  padding-top: 2.5rem;
  width: 16rem;
`

export const StyledImgContainer = styled.div`
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;

  @media (min-width: ${({ theme }) => theme.size.xxlg}) {
    button {
      display: none;
    }
    &:hover {
      button {
        display: flex;
      }
    }
  }
`

export const DeleteCrossWrapper = styled.div`
  align-content: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
`
