import styled, { css } from 'styled-components'

import { Column, Divider } from '@cdab/scania/sdds'
import { Link } from 'react-router-dom'

export const HeaderRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

export const AuditBadges = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.size.lg}) {
    flex-direction: column;
  }

  & > * {
    margin: 0 0 0 2rem;
  }

  &:first-child {
    margin: 0;
  }
`

export const Header = styled.span.attrs({
  className: 'sdds-headline-06'
})`
  text-transform: capitalize;
`

export const StyledDivider = styled(Divider)`
  margin: var(--sdds-spacing-layout-16) 0;
`

export const CircleProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.size.lg}) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`

export const CircleProgressLabel = styled.h5`
  margin-top: 0;
  text-align: center;
`

export const Numbers = styled.span.attrs({
  className: 'sdds-detail-05'
})`
  &&& {
    font-size: 1em;
    font-weight: 500;
  }
`

export const StyledColumn = styled(Column)`
  margin-bottom: var(--sdds-spacing-layout-16);

  @media (min-width: ${({ theme }) => theme.size.md}) {
    margin-bottom: 0;
  }
`

export const ErrorMessage = styled.div`
  color: var(--sdds-red-500);
`

export const ActionsSection = styled.div`
  @media (min-width: ${({ theme }) => theme.size.max}) {
    flex-direction: row;
  }
  flex-direction: column;
  display: flex;

  gap: var(--sdds-spacing-layout-32);
`

export const NoteContainer = styled.div``

export const StyledNoteRow = styled(Link)`
  padding: var(--sdds-spacing-layout-16) 0;
  border-bottom: 1px solid var(--sdds-grey-300);

  text-decoration: none;
  color: black;

  &:hover {
    background: var(--sdds-grey-100);
  }

  display: flex;
  flex-direction: row;

  &:last-child {
    border-bottom: none;
  }
`

export const NotePrefix = styled.div`
  font-weight: bolder;
  flex-basis: 32rem;
  flex-grow: 0;
  flex-shrink: 0;
`

export const NoteContentContainer = styled.div`
  flex-grow: 1;
`
export const NoteContent = styled.div<{ limitRows?: number }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${({ limitRows }) =>
    limitRows &&
    css`
      text-overflow: ellipsis;
      line-clamp: ${limitRows};
      -webkit-line-clamp: ${limitRows};
    `}
`
