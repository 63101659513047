import {
  CenteredContainer,
  FavoriteAuditsList,
  PageHeader,
  ScrollableWrapper
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import type { FavoriteAudit } from '@cdab/scania/qpr/schema'
import { sortFnByExpiryDate } from '@cdab/scania/qpr/utils'
import {
  Button,
  Column,
  Container,
  EmptyScreen,
  IconWarning,
  Row
} from '@cdab/scania/sdds'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  isRouteErrorResponse,
  json,
  useLoaderData,
  useNavigate,
  useRouteError
} from 'react-router-dom'
import styled from 'styled-components'

type FavoriteAuditsPageLoaderData = {
  favoriteAudits: FavoriteAudit[]
}

const FlexContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`

export async function loader(): Promise<FavoriteAuditsPageLoaderData> {
  const client = getClient()

  const user = await client.GetuserInfo()
  if (!user) {
    throw json({
      status: 401
    })
  }
  try {
    const favoriteAudits = (
      await client.AuditsService.GetFavoriteAuditsForUser(user.userId)
    ).sort((a, b) =>
      sortFnByExpiryDate(
        { expiryDate: a.expiryDate },
        { expiryDate: b.expiryDate }
      )
    )

    return {
      favoriteAudits
    }
  } catch (error) {
    throw new Response(null, { status: 404 })
  }
}

export function FavoriteAuditsPage() {
  return <FavoriteAuditsPageContent />
}

export function FavoriteAuditsPageContent() {
  const navigate = useNavigate()
  const { updateTitles } = useTitle()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { favoriteAudits } = useLoaderData() as FavoriteAuditsPageLoaderData
  const { t } = useTranslation('favoriteAudits')

  useEffect(() => {
    updateTitles({
      mobile: {
        title: t('favorite-audits'),
        description: null
      }
    })
  }, [updateTitles, t])

  const onAuditListClick = useCallback(
    (auditId: FavoriteAudit['auditId']) => {
      if (auditId) {
        navigate(`/audit/${auditId}`)
      }
    },
    [navigate]
  )

  function goToDealers() {
    navigate('/dealers')
  }

  if (favoriteAudits.length > 0) {
    return (
      <FlexContainer fluid='normal' fullHeight paddingOnContainer={false}>
        {isLg && (
          <Row>
            <Column width={12}>
              <PageHeader title={t('favorite-audits')} />
            </Column>
          </Row>
        )}
        <ScrollableWrapper>
          <Row>
            <Column width={12} lg={6} padding={false} offset={{ lg: 3 }}>
              <FavoriteAuditsList
                favoriteAudits={favoriteAudits}
                onClick={onAuditListClick}
              />
            </Column>
          </Row>
        </ScrollableWrapper>
      </FlexContainer>
    )
  }

  return (
    <CenteredContainer fluid='normal' fullHeight>
      <Row>
        <Column fullHeight={true} width={12} padding={false}>
          <EmptyScreen
            title={t('empty-screen.title')}
            description={t('empty-screen.description')}
            icon={<IconWarning />}
            callToAction={{
              type: 'button',
              component: (
                <Button
                  size='sm'
                  onClick={goToDealers}
                  text={t('go-to-dealers')}
                />
              )
            }}
          />
        </Column>
      </Row>
    </CenteredContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    switch (error.status) {
      case 401:
        message = t('not-logged-in')
        break
    }

    return (
      <CenteredContainer fluid='normal' fullHeight>
        <Row>
          <Column fullHeight={true} width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
