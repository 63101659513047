import {
  useCssVariableBreakpoint,
  useGetAreaReport,
  useGetMarketDeviationsReport,
  useGetMarketSummaryReport
} from '@cdab/scania/qpr/hooks'
import type { Area, Market } from '@cdab/scania/qpr/schema'
import type { TOption } from '@cdab/scania/sdds'
import {
  Button,
  Column,
  Container,
  DateTime,
  Dropdown,
  DropdownOption,
  Row
} from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FullWidth,
  MessagesRow,
  StyledFirstRow,
  StyledMessage
} from './area-market-report.styles'
import type { AreaMarketReportProps } from './area-market-report.types'

export function AreaMarketReport({
  reportType,
  areas,
  markets = []
}: AreaMarketReportProps) {
  const { t } = useTranslation(['reports', 'common'])
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')

  const [marketsToShowState, setMarketsToShowState] = useState<Market[]>([])

  const [marketIdsState, setMarketIdsState] = useState<number[]>([])

  const [areaIdsState, setAreaIdsState] = useState<number[]>([])

  const [dateFromState, setDateFromState] = useState<string>('')
  const [dateToState, setDateToState] = useState<string>('')

  const [getAreaReportState, clickGetAreaReportState] =
    useGetAreaReport(areaIdsState)

  const areaReportDisbled =
    getAreaReportState.status === 'loading' || areaIdsState.length === 0

  const [getMarketSummaryReportState, clickGetMarketSummaryReportState] =
    useGetMarketSummaryReport(marketIdsState, areaIdsState)

  const marketSummaryReportDisbled =
    getMarketSummaryReportState.status === 'loading' ||
    areaIdsState.length === 0

  const [getMarketDeviationsReportState, clickGetMarketDeviationsReportState] =
    useGetMarketDeviationsReport(marketIdsState, dateFromState, dateToState)

  const marketDeviationsReportDisbled =
    getMarketDeviationsReportState.status === 'loading' ||
    marketIdsState.length === 0 ||
    dateFromState === '' ||
    dateToState === ''

  const onChangeAreas = useCallback(
    (option: TOption) => {
      const areaId = parseInt(option.value)
      let curAreas = areaIdsState.slice()
      const curMarkets = marketIdsState.slice()
      const areaInData = curAreas.find(aId => aId === areaId)

      if (areaInData) {
        curAreas = curAreas.filter(e => e !== areaId)
      } else {
        curAreas.push(areaId)
      }

      setAreaIdsState(curAreas)
      setMarketIdsState(curAreas.length === 1 ? curMarkets : [])
      setMarketsToShowState(
        curAreas.length === 1
          ? markets.filter(market => market.areaId === curAreas[0])
          : []
      )
    },
    [areaIdsState, marketIdsState, markets]
  )

  const onChangeMarkets = useCallback(
    (option: TOption) => {
      const changedMarketId = parseInt(option.value)
      let curMarkets = marketIdsState.slice()
      const marketInData = curMarkets.find(
        marketId => marketId === changedMarketId
      )

      if (marketInData) {
        curMarkets = curMarkets.filter(e => e !== changedMarketId)
        setMarketIdsState(curMarkets)
      } else {
        curMarkets.push(changedMarketId)
        setMarketIdsState(curMarkets)
      }
    },
    [marketIdsState]
  )

  return (
    <Container fluid='normal' fullHeight>
      <StyledFirstRow>
        <Column width={12} lg={8} offset={{ lg: 2 }}>
          <Row>
            <Column width={12} lg={reportType === 'area-summary' ? 12 : 6}>
              <FullWidth>
                <Dropdown
                  id={reportType + '-areas'}
                  label={capitalizeFirstLetter(t('areas', { ns: 'common' }))}
                  size='lg'
                  disabled={false}
                  labelPosition='outside'
                  placeholder={capitalizeFirstLetter(
                    t('areas', { ns: 'common' })
                  )}
                  type='multiselect'
                  onSelect={onChangeAreas}
                >
                  {areas
                    .sort((a: Area, b: Area) => a.name.localeCompare(b.name))
                    .map(({ id, name }) => {
                      return (
                        <DropdownOption
                          key={'areas' + id.toString()}
                          value={id.toString()}
                          text={name}
                        />
                      )
                    })}
                </Dropdown>
              </FullWidth>
            </Column>
            <Column width={12} lg={6}>
              {reportType !== 'area-summary' &&
                marketsToShowState.length > 0 && (
                  <FullWidth>
                    <Dropdown
                      id={reportType + '-markets'}
                      label={capitalizeFirstLetter(
                        t('markets', { ns: 'common' })
                      )}
                      size='lg'
                      disabled={false}
                      labelPosition='outside'
                      placeholder={capitalizeFirstLetter(
                        t('markets', { ns: 'common' })
                      )}
                      type='multiselect'
                      onSelect={onChangeMarkets}
                    >
                      {marketsToShowState
                        .sort((a: Market, b: Market) =>
                          a.marketName.localeCompare(b.marketName)
                        )
                        .map(({ id, marketName }) => {
                          return (
                            <DropdownOption
                              key={'markets' + id.toString()}
                              value={id.toString()}
                              text={marketName}
                            />
                          )
                        })}
                    </Dropdown>
                  </FullWidth>
                )}
            </Column>
          </Row>

          {reportType === 'top-market-deviations' && (
            <Row>
              <Column width={12} lg={6} padding={isLg}>
                <DateTime
                  name='dateFrom'
                  noMinWidth={true}
                  label={t('date-from', { ns: 'common' })}
                  onInput={e => setDateFromState(e.target.value)}
                  type='date'
                />
              </Column>
              <Column width={12} lg={6} padding={isLg}>
                <DateTime
                  name='dateTo'
                  noMinWidth={true}
                  label={t('date-to', { ns: 'common' })}
                  onInput={e => setDateToState(e.target.value)}
                  type='date'
                />
              </Column>
            </Row>
          )}

          <MessagesRow>
            {reportType === 'area-summary' &&
              getAreaReportState.status === 'error' && (
                <StyledMessage
                  className='sdds-u-mt1'
                  variant='single-line'
                  singleLineMessage={`${capitalizeFirstLetter(
                    t('area-summary')
                  )}: ${getAreaReportState.message} `}
                  type={'error'}
                />
              )}
            {reportType === 'country-summary' &&
              getMarketSummaryReportState.status === 'error' && (
                <StyledMessage
                  className='sdds-u-mt1'
                  variant='single-line'
                  singleLineMessage={`${capitalizeFirstLetter(
                    t('country-summary')
                  )}: ${getMarketSummaryReportState.message} `}
                  type={'error'}
                />
              )}
            {reportType === 'top-market-deviations' &&
              getMarketDeviationsReportState.status === 'error' && (
                <StyledMessage
                  className='sdds-u-mt1'
                  variant='single-line'
                  singleLineMessage={`${t('top-market-deviations')}: ${
                    getMarketDeviationsReportState.message
                  } `}
                  type={'error'}
                />
              )}
          </MessagesRow>
          <Row>
            {reportType === 'area-summary' ? (
              <Button
                type='primary'
                size='md'
                text={capitalizeFirstLetter(t('area-summary'))}
                onClick={() => areaReportDisbled || clickGetAreaReportState()}
                disabled={areaReportDisbled}
              />
            ) : reportType === 'country-summary' ? (
              <Button
                type='primary'
                size='md'
                text={capitalizeFirstLetter(t('country-summary'))}
                onClick={() =>
                  marketSummaryReportDisbled ||
                  clickGetMarketSummaryReportState()
                }
                disabled={marketSummaryReportDisbled}
              />
            ) : (
              reportType === 'top-market-deviations' && (
                <Button
                  type='primary'
                  size='md'
                  text={t('top-market-deviations')}
                  onClick={() =>
                    marketDeviationsReportDisbled ||
                    clickGetMarketDeviationsReportState()
                  }
                  disabled={marketDeviationsReportDisbled}
                />
              )
            )}
          </Row>
        </Column>
      </StyledFirstRow>
    </Container>
  )
}
