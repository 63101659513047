import { action, toJS } from 'mobx'
import invariant from 'tiny-invariant'

import type { FileData } from '@cdab/scania/qpr/schema'

import type { AuditModel, PledgeModel } from '../audit-model'
import { auditsStore } from './store'
import { syncRemovedFiles } from './utilities'

export function getPledge(
  auditId: AuditModel['id'],
  pledgeId: PledgeModel['id']
): PledgeModel {
  const audit = auditsStore[auditId]
  invariant(audit)

  const pledge = audit.pledges.find(ap => ap.id === pledgeId)
  invariant(pledge)

  return pledge
}

export const addPledgeFiles = action(
  (
    auditId: AuditModel['id'],
    pledgeId: PledgeModel['id'],
    filesToAdd: FileData[]
  ) => {
    const pledge = getPledge(auditId, pledgeId)

    syncRemovedFiles(pledge.files, filesToAdd)

    filesToAdd.forEach(fileToAdd => {
      const fileIndex = pledge.files.findIndex(f => f.id === fileToAdd.id)

      if (fileIndex !== -1) {
        const file = pledge.files[fileIndex]

        file.fileName = fileToAdd.fileName
        file.isImage = fileToAdd.isImage
        file.isUploaded = fileToAdd.isUploaded
        file.url = fileToAdd.url
      } else {
        pledge.files.push(fileToAdd)
      }
    })
  }
)

export const deletePledgeFile = action(
  (
    audit: AuditModel,
    pledgeId: PledgeModel['id'],
    fileId: FileData['id']
  ): FileData | undefined => {
    const pledge = audit.pledges.find(ap => ap.id === pledgeId)
    invariant(pledge, `Cannot delete pledge file if we cannot find pledge!`)

    const fileIndex = pledge.files.findIndex(f => f.id === fileId)

    if (fileIndex === -1) {
      return
    }

    const [mobxFile] = pledge.files.splice(fileIndex, 1)

    const file = toJS(mobxFile)

    return file
  }
)
