import { v4 as uuidv4 } from 'uuid'
import { action } from 'mobx'

import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { Audit, FileData } from '@cdab/scania/qpr/schema'
import { fileIsImage, fileToArrayBuffer } from '@cdab/utils'

import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import type { AuditModel } from '../audit-model'
import { getAudit } from '../store/audits'
import type { CachedExtraFileData } from './types'

type Type = 'add-audit-file'

type Data = {
  file: File
  fileGuid: string
}

export type AddAuditFileOperationData = OperationDataBase<Type, Data>

export type CachedAddAuditFileOperationData = AddAuditFileOperation & {
  data: AddAuditFileOperationData & {
    value: Data & CachedExtraFileData
  }
}

export class AddAuditFileOperation extends Operation {
  public data: AddAuditFileOperationData
  private uploadedFileResponse: FileData | null = null
  private auditFile: FileData | null = null

  constructor(auditId: Audit['id'], data: Data, guid = uuidv4()) {
    super(auditId, guid)

    this.data = {
      type: 'add-audit-file',
      value: data
    }
  }

  public ApplyTo = action((audit: AuditModel): void => {
    const { file, fileGuid } = this.data.value

    const fileData: FileData = this.uploadedFileResponse ?? {
      fileName: file.name,
      id: fileGuid,
      isImage: fileIsImage(file),
      isUploaded: false,
      uploadProgress: 0,
      url: URL.createObjectURL(file)
    }

    const hasFile = !!audit.files.find(f => f.id === fileData.id)

    if (hasFile) return

    const length = audit.files.push(fileData)
    this.auditFile = audit.files[length - 1]
  })

  public GetName(): string {
    return `${this.data.type}-${this.auditId}-${this.data.value.fileGuid}`
  }

  public SendOperation = async (client: BackEnd): Promise<boolean> => {
    const { auditFile } = this

    const { success, fileData } =
      await client.StorageService.UploadFileForAudit(
        this.auditId,
        this.data.value.file,
        action(progressEvent => {
          if (!auditFile) return
          if (typeof progressEvent.progress === 'undefined') return

          auditFile.uploadProgress = progressEvent.progress
        })
      )

    this.uploadedFileResponse = fileData

    return success
  }

  public RollbackOn = action((audit: AuditModel): void => {
    const fileIndex = audit.files.findIndex(
      f => f.id === this.data.value.fileGuid
    )

    if (fileIndex === -1) return

    const [file] = audit.files.splice(fileIndex, 1)

    if (file) {
      URL.revokeObjectURL(file.url)
    }

    this.auditFile = null
  })

  public override GetData = async () => {
    const { file } = this.data.value
    const fileBuffer = await fileToArrayBuffer(file)

    const cachedData: CachedExtraFileData = {
      fileBuffer,
      fileName: file.name,
      mimeType: file.type
    }

    const json = JSON.parse(JSON.stringify(this))

    return {
      ...json,
      data: {
        ...json.data,
        value: {
          ...json.data.value,
          ...cachedData
        }
      }
    } as CachedAddAuditFileOperationData
  }

  public override OnConfirm = action((): void => {
    if (!this.uploadedFileResponse) return

    const audit = getAudit(this.auditId)

    if (!audit) return

    const { fileGuid } = this.data.value

    const file = audit.files.find(f => f.id === fileGuid)

    if (file) {
      URL.revokeObjectURL(file.url)
      file.url = this.uploadedFileResponse.url
      file.id = this.uploadedFileResponse.id
      file.isUploaded = true
    } else {
      audit.files.push(this.uploadedFileResponse)
    }
  })
}
