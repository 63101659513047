import { useMemo } from 'react'

import type { MessageProps } from './message.types'
import {
  ErrorIcon,
  InformativeIcon,
  SuccessIcon,
  WarningIcon,
  MessageCard,
  Title,
  MultiLineMessage
} from './message.styles'

export function Message({ type = 'information', ...props }: MessageProps) {
  const hideIcon: boolean = useMemo(() => {
    if (props.variant === 'minimal') return false

    return !!props.hideIcon
  }, [props])

  return (
    <MessageCard
      hideIcon={hideIcon}
      onWhite={!!props.onWhite}
      type={type}
      variant={props.variant}
      className={props.className}
    >
      {!hideIcon && (
        <>
          <InformativeIcon />
          <ErrorIcon />
          <WarningIcon />
          <SuccessIcon />
        </>
      )}
      <Title>{props.singleLineMessage}</Title>

      {props.variant === 'multi-line' && (
        <MultiLineMessage>{props.multiLineMessage}</MultiLineMessage>
      )}
    </MessageCard>
  )
}
