import { PLACEHOLDER_URL } from './deviation-card.constants'
import type { DeviationCardProps } from './deviation-card.types'
import { StyledDeviationCard } from './deviation-card.styles'

export function DeviationCard({
  date,
  description,
  imageUrl,
  onClick,
  title,
  ...props
}: DeviationCardProps): JSX.Element {
  return (
    <StyledDeviationCard
      headline={title}
      subheadline={date}
      onClick={onClick}
      image={{
        alt: 'A deviation',
        url: imageUrl || PLACEHOLDER_URL
      }}
      {...props}
    >
      {description}
    </StyledDeviationCard>
  )
}
