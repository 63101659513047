import styled from 'styled-components'

export const StyledRightActionButton = styled.button`
  background-color: var(--sdds-red-400);
  align-self: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  place-content: center;

  color: var(--sdds-white);

  &:hover {
    background-color: var(--sdds-red-500);
  }
`

export const RightActionButton = ({
  icon,
  id,
  onClick,
  ...props
}: {
  id: string
  icon: JSX.Element
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}): JSX.Element => {
  return (
    <StyledRightActionButton onClick={onClick} type='button' id={id} {...props}>
      {icon}
    </StyledRightActionButton>
  )
}
