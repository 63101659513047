import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import { downloadFileFromArrayBuffer } from '@cdab/utils'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type GetDOSReportState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'loading'
      message: null
    }
  | {
      status: 'none'
      message: null
    }

export function useGetDOSReport(auditId: number) {
  const { t } = useTranslation('reports')
  const [submitState, setSubmitState] = useState<GetDOSReportState>({
    status: 'none',
    message: null
  })
  const client = useBackend()

  const handleClickGetDOSReport = useCallback(
    async (
      viewSummary: boolean,
      viewCheckpoints: boolean,
      viewPerspectives: boolean,
      viewDeviations: boolean,
      viewNotes: boolean
    ) => {
      if (auditId === 0) return
      setSubmitState({
        status: 'loading',
        message: null
      })

      try {
        const response = await client.ReportsService.GetDOSReport(
          auditId,
          viewSummary,
          viewCheckpoints,
          viewPerspectives,
          viewDeviations,
          viewNotes
        )
        const downloadFile = downloadFileFromArrayBuffer(
          response.data,
          response.headers['content-type'] ?? '',
          `DOSReport.docx`
        )

        if (response.status === 204 || !downloadFile) {
          setSubmitState({
            status: 'error',
            message: t('message.-report')
          })
          return
        }

        setSubmitState({
          status: 'success',
          message: t('message.success')
        })
      } catch (e) {
        setSubmitState({
          status: 'error',
          message: t('message.error')
        })
      }
    },
    [client.ReportsService, auditId, t]
  )
  return [submitState, handleClickGetDOSReport, setSubmitState] as const
}
