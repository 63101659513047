import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { StatusBadge } from '@cdab/scania/qpr/components/atoms'

type BadgeType = 'certify' | 'readonly'

export interface AuditBadgeProps {
  type: BadgeType
  className?: string
}

const badgeColors = (type: BadgeType) => {
  switch (type) {
    case 'certify':
      return '--sdds-green-500'
    case 'readonly':
      return '--sdds-red-500'
  }
}

export function AuditBadge({ type, ...props }: AuditBadgeProps) {
  const { t } = useTranslation('common')
  const text = useMemo(() => {
    switch (type) {
      case 'certify':
        return t('certification')
      case 'readonly':
        return t('readonly')
    }
  }, [t, type])

  const sddsColorVar = useMemo(() => badgeColors(type), [type])

  return (
    <StatusBadge
      sddsColorVar={sddsColorVar}
      text={text}
      className={props.className}
    />
  )
}

export default AuditBadge
