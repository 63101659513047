import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export type DeleteADHLinkFileState =
  | {
      status: 'success'
      linkId: number
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

export function useDeleteADHLink() {
  const { t } = useTranslation('audit')
  const [submitState, setSubmitState] = useState<DeleteADHLinkFileState>({
    status: 'none',
    message: null
  })
  const client = useBackend()

  const handleClickDeleteADHLink = async (linkId: number) => {
    try {
      const expiryDate = new Date()
      expiryDate.setDate(expiryDate.getDate() + 1)

      await client.AuditsService.DeleteADHLink(linkId)

      setSubmitState({
        status: 'success',
        linkId: linkId,
        message: t('adh.success-deleted')
      })
    } catch (e) {
      setSubmitState({
        status: 'error',
        message: t('adh.delete-error')
      })
    }
  }

  return [submitState, handleClickDeleteADHLink, setSubmitState] as const
}
