export { ReactComponent as IconArrowDown } from './arrow_down.svg'
export { ReactComponent as IconArrowLeft } from './arrow_left.svg'
export { ReactComponent as IconArrowRight } from './arrow_right.svg'
export { ReactComponent as IconArrowUp } from './arrow_up.svg'
export { ReactComponent as IconBento } from './bento.svg'
export { ReactComponent as IconBurger } from './burger.svg'
export { ReactComponent as IconChevronDown } from './chevron_down.svg'
export { ReactComponent as IconChevronLeft } from './chevron_left.svg'
export { ReactComponent as IconChevronRight } from './chevron_right.svg'
export { ReactComponent as IconChevronUp } from './chevron_up.svg'
export { ReactComponent as IconCross } from './cross.svg'
export { ReactComponent as IconDocument } from './document.svg'
export { ReactComponent as IconDocumentCheck } from './document_check.svg'
export { ReactComponent as IconDoubleKebab } from './double_kebab.svg'
export { ReactComponent as IconEdit } from './edit.svg'
export { ReactComponent as IconError } from './error.svg'
export { ReactComponent as IconHeart } from './heart.svg'
export { ReactComponent as IconHeartInactive } from './heart_inactive.svg'
export { ReactComponent as IconHome } from './home.svg'
export { ReactComponent as IconInfo } from './info.svg'
export { ReactComponent as IconLock } from './lock.svg'
export { ReactComponent as IconMessage } from './message.svg'
export { ReactComponent as IconMinus } from './minus.svg'
export { ReactComponent as IconPlus } from './plus.svg'
export { ReactComponent as IconProfile } from './profile.svg'
export { ReactComponent as IconSmartphone } from './smartphone.svg'
export { ReactComponent as IconSorting } from './sorting.svg'
export { ReactComponent as IconStar } from './star.svg'
export { ReactComponent as IconTick } from './tick.svg'
export { ReactComponent as IconTool } from './tool.svg'
export { ReactComponent as IconTruck } from './truck.svg'
export {
  ReactComponent as IconMessageInactive,
  ReactComponent as IconWarning
} from './warning.svg'
