import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  isRouteErrorResponse,
  json,
  useLoaderData,
  useNavigate,
  useRouteError
} from 'react-router'

import {
  CenteredContainer,
  ClickableListItem,
  ElasticContentContainer,
  IconButton
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import type { SystemMessage } from '@cdab/scania/qpr/schema'
import { Roles } from '@cdab/scania/qpr/schema'
import { Column, EmptyScreen, IconWarning, Row } from '@cdab/scania/sdds'

import { getFirstLineFromHtmlText } from '@cdab/utils'
import {
  StyledBlock,
  StyledClickableList,
  StyledContainer,
  StyledIcon,
  StyledPaddingWrapper,
  StyledWrapper
} from './system-messages.styles'

export type AdminSystemMessagesLoaderData = {
  systemMessages: SystemMessage[]
}

export async function loader(): Promise<AdminSystemMessagesLoaderData> {
  const client = getClient()

  const userInfo = await client.GetuserInfo()
  if (!userInfo) {
    throw json({
      status: 401
    })
  }

  const user = await client.UserService.GetUser(userInfo.userId)
  if (user.role !== Roles.FactoryAdmin) {
    throw json({
      status: 403
    })
  }

  try {
    const [systemMessages] = await Promise.all([
      client.SystemMessagesService.GetSystemMessagesForUser()
    ])

    return {
      systemMessages
    }
  } catch (error) {
    if (error instanceof Response) {
      throw new Response(null, {
        status: error.status
      })
    } else {
      throw new Response(null, {
        status: 404
      })
    }
  }
}

export function Page() {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { updateTitles } = useTitle()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { systemMessages } = useLoaderData() as AdminSystemMessagesLoaderData

  const onSystemMessageListClick = useCallback(
    (messageId: SystemMessage['id']) => {
      if (messageId) {
        navigate(`/admin/system-messages/${messageId}`)
      }
    },
    [navigate]
  )

  useEffect(() => {
    updateTitles({
      contentHeader: {
        title: t('administration'),
        subtitle: t('system-messages')
      },
      mobile: {
        title: `${t('system-messages')}`,
        description: null
      }
    })
  }, [t])

  return (
    <ElasticContentContainer overflowHidden>
      <StyledContainer
        fullHeight
        fluid='normal'
        paddingOnColumns={isLg}
        paddingOnContainer={isLg}
      >
        <Row fullHeight>
          <Column
            fullHeight
            width={12}
            lg={6}
            padding={false}
            offset={{ lg: 3 }}
          >
            <StyledWrapper>
              <StyledPaddingWrapper>
                <IconButton
                  disabled={false}
                  onClick={() => navigate('/admin/system-messages/new')}
                >
                  <span>{t('add-new')}</span>
                  <StyledIcon />
                </IconButton>
              </StyledPaddingWrapper>
              <StyledBlock color='on-grey' disablePadding={true}>
                <StyledClickableList>
                  {systemMessages.map(systemMessage => {
                    return (
                      <ClickableListItem
                        key={systemMessage.id}
                        title={systemMessage.title}
                        description={getFirstLineFromHtmlText(
                          systemMessage.text
                        )}
                        onClick={() =>
                          onSystemMessageListClick(systemMessage.id)
                        }
                      />
                    )
                  })}
                </StyledClickableList>
              </StyledBlock>
            </StyledWrapper>
          </Column>
        </Row>
      </StyledContainer>
    </ElasticContentContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    switch (error.data.status) {
      case 401:
        message = t('not-logged-in')
        break
      case 403:
        message = t('access-denied')
        break
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
