import { useTranslation } from 'react-i18next'
import { Checkbox } from '@cdab/scania/sdds'
import { useCallback } from 'react'

import type { PointCompliantCardProps } from './point-compliant-card.types'
import {
  StyledDivider,
  Title,
  CheckboxRow
} from './point-compliant-card.styles'

export function PointCompliantCard({
  compliant,
  onCompliantChange,
  disabled = false
}: PointCompliantCardProps) {
  const { t } = useTranslation('common')

  const onYesChange = useCallback(() => {
    if (compliant === true) {
      onCompliantChange(null)
    } else {
      onCompliantChange(true)
    }
  }, [onCompliantChange, compliant])

  const onNoChange = useCallback(() => {
    if (compliant === false) {
      onCompliantChange(null)
    } else {
      onCompliantChange(false)
    }
  }, [onCompliantChange, compliant])

  return (
    <>
      <StyledDivider type='light' />
      <Title>{t('compliant')}</Title>
      <CheckboxRow>
        <Checkbox
          label={t('yes')}
          onChange={onYesChange}
          checked={compliant === true}
          disabled={disabled}
        />
        <Checkbox
          label={t('no')}
          onChange={onNoChange}
          checked={compliant === false}
          disabled={disabled}
        />
      </CheckboxRow>
    </>
  )
}
