import { EmptyState } from '@cdab/scania/qpr/components'
import {
  useCssVariableBreakpoint,
  useSddsTableRowClickWithCellValue
} from '@cdab/scania/qpr/hooks'
import type { ExpiringDeviation } from '@cdab/scania/qpr/schema'
import {
  Column,
  Container,
  DataTable,
  EmptyScreen,
  HeaderCell,
  IconMessageInactive,
  Row
} from '@cdab/scania/sdds'
import { t } from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
  useRouteLoaderData
} from 'react-router-dom'
import type { DashboardLoaderData } from './dashboard'
import { StyledColumn } from './dashboard'

export function ExpiringActionPlans() {
  const { expiringDeviations } = useRouteLoaderData(
    'dashboard'
  ) as DashboardLoaderData
  const navigate = useNavigate()

  const [tableEl, setTableEl] = useState<HTMLTableRowElement>()

  useSddsTableRowClickWithCellValue(tableEl, 'auditId', deviationAuditId => {
    if (deviationAuditId) {
      navigate(`/audit/${deviationAuditId}/deviations`)
    }
  })

  function prepareExpiringDeviationsTableData(
    data: readonly ExpiringDeviation[]
  ) {
    const preparedData = data.map(expiringDeviation => {
      return {
        auditDate: expiringDeviation.auditDate.toISOString().substring(0, 10),
        dealerCity: expiringDeviation.dealerCity,
        dealerName: expiringDeviation.dealerName,
        expiryDate: expiringDeviation.expiryDate.toISOString().substring(0, 10),
        hasDeviations: expiringDeviation.hasDeviations ? 'Yes' : 'No',
        auditId: expiringDeviation.auditId
      }
    })

    return preparedData
  }

  return expiringDeviations.length > 0 ? (
    <StyledColumn width={12} fullHeight scrollY scrollX>
      <DataTable
        title={t('expiring-deviations')}
        data={prepareExpiringDeviationsTableData(expiringDeviations)}
        ref={setTableEl}
        rowsPerPage={10}
        pagination
      >
        <HeaderCell columnKey='auditDate' title={t('audit-date')} sortable />
        <HeaderCell columnKey='dealerCity' title={t('city')} sortable />
        <HeaderCell columnKey='dealerName' title={t('dealer')} sortable />
        <HeaderCell columnKey='expiryDate' title={t('expiry-date')} sortable />
        <HeaderCell
          columnKey='hasDeviations'
          title={t('has-deviations')}
          sortable
        />
        {/* TODO: Hide column auditId */}
        <HeaderCell columnKey='auditId' title={t('audit-Id')} />
      </DataTable>
    </StyledColumn>
  ) : (
    <EmptyScreen.CenteredWrapper>
      <EmptyScreen
        icon={<IconMessageInactive />}
        title={t('no-expiring-deviations-found', { ns: 'common' })}
        description={t('everything-under-control', { ns: 'common' })}
      />
    </EmptyScreen.CenteredWrapper>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <Container
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column width={12} padding={isLg} scrollX scrollY>
            <EmptyState
              title={t('something-bad')}
              description={t('talk-to-someone')}
            />
          </Column>
        </Row>
      </Container>
    )
  }

  throw error
}
