import { Wrapper, Title, Description } from './empty-state.styles'
import type { EmptyStateProps } from './empty-state.types'

export const EmptyState = (props: EmptyStateProps) => {
  const { children, title, description } = props

  return (
    <Wrapper>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      {children}
    </Wrapper>
  )
}
