import { createOperationFromData } from '@cdab/scania/qpr/offline/models'
import type { Operation } from '@cdab/scania/qpr/offline/models'
import { cacheAudit, getCachedAudit } from './audits'
import { OPERATIONS_INDEX_AUDIT_ID, OPERATIONS_INDEX_GUID } from './constants'
import { getOperationsStore } from './stores'
import type { Audit } from '@cdab/scania/qpr/schema'

export async function cacheOperation(operation: Operation) {
  const operationName = operation.GetName()
  const operationData = await operation.GetData()

  const operationsStore = await getOperationsStore('readwrite')

  /**
   * If we already have an operation for this operation type,
   * overwrite it BUT keep the old operations previous value instead of the new one.
   */
  return new Promise<void>((resolve, reject) => {
    // First check if we have an audit
    const req = operationsStore.get(operationName)

    req.onerror = ev => {
      console.error('error getting name', operationName, ev)
      reject()
    }
    req.onsuccess = () => {
      const { result } = req

      if (result && result.data && 'previousValue' in result.data) {
        operation.data.previousValue = result.data.previousValue
      }

      const req2 = operationsStore.put(operationData, operationName)

      req2.onerror = ev => {
        console.error('error doing req2', ev)
        reject()
      }
      req2.onsuccess = () => {
        resolve()
      }
    }
  })
}

export async function getCachedOperation(guid: Operation['guid']) {
  const operationsStore = await getOperationsStore('readonly')

  return new Promise<Operation | undefined>((resolve, reject) => {
    const index = operationsStore.index(OPERATIONS_INDEX_GUID)
    const req = index.get(guid)
    req.onerror = ev => {
      console.error(
        `getCachedOperation: error getting index value for guid ${guid}`,
        ev
      )
      reject()
    }
    req.onsuccess = () => {
      const operationData = req.result

      if (!operationData) {
        resolve(undefined)
        return
      }

      const operation = createOperationFromData(operationData)

      resolve(operation)
    }
  })
}

export async function getCachedOperations(auditId: Audit['id']) {
  const operationsStore = await getOperationsStore('readonly')

  return new Promise<Operation[]>((resolve, reject) => {
    const operations: Operation[] = []

    const index = operationsStore.index(OPERATIONS_INDEX_AUDIT_ID)

    const req = index.openCursor(IDBKeyRange.only(auditId))
    req.onerror = ev => {
      console.error(
        `error opening/handling cursor "${OPERATIONS_INDEX_AUDIT_ID}":`,
        ev
      )
      reject()
    }
    req.onsuccess = () => {
      const cursor = req.result
      if (cursor) {
        // This is a bit unstable. If this fail, we could get a spinner of death.
        // Preferably we should just crash, and show an error page.
        const operation = createOperationFromData(cursor.value)

        operations.push(operation)
        cursor.continue()
      } else {
        resolve(operations)
      }
    }
  })
}

// Se if we have an operation with this guid in indexeddb
// If we do, delete it. Otherwise, do nothing
export async function removeCachedOperation(operation: Operation) {
  const operationsstore = await getOperationsStore('readwrite')

  return new Promise<void>((resolve, reject) => {
    const name = operation.GetName()
    const req = operationsstore.delete(name)

    req.onerror = ev => {
      console.error('Could not delete', name, ev)
      reject(ev)
    }
    req.onsuccess = () => {
      resolve()
    }
  })
}

export async function confirmOperation(operation: Operation) {
  operation.OnConfirm()

  /**
   * When we confirm an operation, we also want to update the cached audit.
   * Therefore, when we start the application offline, we use displayed audit = cached audit + cached operations
   * 1. load cached audit
   * 2. apply operation to the cached audit
   * 3. save the updated audit
   * 4. remove the cached operation
   */
  const audit = await getCachedAudit(operation.auditId)
  if (audit) {
    operation.ApplyTo(audit)
    await cacheAudit(audit)
  }

  await removeCachedOperation(operation)
}
