import type { AuditModel, DeviationModel } from './audit-model'
import { mergeObjectFields, addOrMergeArrayObject } from './merge'
import { mergeReferenceDocuments } from './merge-reference-documents'

export const mergeAudits_old = (
  target: AuditModel,
  other: AuditModel
): void => {
  mergeObjectFields(target, other, ['isGettingFiles'] as (keyof AuditModel)[])

  other.files.forEach(file =>
    addOrMergeArrayObject(target.files, file, f => f.id === file.id)
  )

  other.auditPoints.forEach(auditPoint =>
    addOrMergeArrayObject(
      target.auditPoints,
      auditPoint,
      ap => ap.id === auditPoint.id
    )
  )

  other.checkPoints.forEach(checkPoint =>
    addOrMergeArrayObject(
      target.checkPoints,
      checkPoint,
      cp => cp.id === checkPoint.id
    )
  )

  other.pledgeAreas.forEach(pledgeArea =>
    addOrMergeArrayObject(
      target.pledgeAreas,
      pledgeArea,
      pa => pa.id === pledgeArea.id
    )
  )

  other.pledges.forEach(pledge =>
    addOrMergeArrayObject(target.pledges, pledge, p => p.id === pledge.id)
  )

  target.referenceDocuments = mergeReferenceDocuments(
    target.referenceDocuments,
    other.referenceDocuments
  )

  other.deviations.forEach(deviation =>
    addOrMergeArrayObject(
      target.deviations,
      deviation,
      ({ id, clientGuid }) =>
        (id !== undefined && id === deviation.id) ||
        clientGuid === deviation.clientGuid,
      (target, source) => {
        mergeObjectFields(target, {
          ...source,
          clientGuid: target.clientGuid // Never overwrite old clientGuid
        } as DeviationModel)

        source.files.forEach(file => {
          if (!file.isUploaded) return

          addOrMergeArrayObject(target.files, file, f => f.id === file.id)
        })
      }
    )
  )

  other.pledgePerspectives.forEach(pledgePerspective =>
    addOrMergeArrayObject(
      target.pledgePerspectives,
      pledgePerspective,
      pp => pp.id === pledgePerspective.id
    )
  )
}
