import { useContext, useEffect, useState } from 'react'
import { I18nextProvider, I18nContext } from 'react-i18next'
import { default as defaultI18n } from 'i18next'
import { Outlet } from 'react-router-dom'

import { useUser } from '@cdab/scania/qpr/contexts/user-provider'
import { setupI18n } from '@cdab/scania/qpr/i18n'

export function WithDefaultI18n() {
  return (
    <I18nextProvider i18n={defaultI18n}>
      <Outlet />
    </I18nextProvider>
  )
}

export function WithUserI18n() {
  const { i18n } = useContext(I18nContext)
  const { userData } = useUser()
  const [userI18n, setUserI18n] = useState(i18n)

  useEffect(() => {
    const instance = i18n.createInstance()

    setupI18n({
      instance,
      useQprBackend: true,
      language: userData.languageId.toString(),
      languageVersions: userData.languageVersions
    }).then(() => setUserI18n(instance))
  }, [i18n, userData.languageId, userData.languageVersions])

  return (
    <I18nextProvider i18n={userI18n}>
      <Outlet />
    </I18nextProvider>
  )
}
