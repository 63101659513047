import { useTranslation } from 'react-i18next'
import {
  isRouteErrorResponse,
  useRouteError,
  useRouteLoaderData
} from 'react-router-dom'

import {
  EmptyState,
  ExpiringCertificatesWidget
} from '@cdab/scania/qpr/components'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import {
  Column,
  Container,
  EmptyScreen,
  IconDocumentCheck,
  Row
} from '@cdab/scania/sdds'

import type { DashboardLoaderData } from './dashboard'
import { StyledColumn } from './dashboard'

export function ExpiringCertificates() {
  const { t } = useTranslation('dashboard')
  const { dealers } = useRouteLoaderData('dashboard') as DashboardLoaderData
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')

  if (dealers.length > 0) {
    return (
      <StyledColumn width={12} lg={6} fullHeight padding={isLg}>
        <ExpiringCertificatesWidget dealers={dealers} />
      </StyledColumn>
    )
  }

  return (
    <EmptyScreen.CenteredWrapper>
      <EmptyScreen
        icon={<IconDocumentCheck />}
        title={t('no-expiring-certificates-title', {
          ns: 'dashboard'
        })}
        description={t('no-expiring-certificates-description')}
      />
    </EmptyScreen.CenteredWrapper>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation(['errors', 'common'])
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <Container
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column width={12} padding={isLg}>
            <EmptyState
              title={t('something-bad')}
              description={t('talk-to-someone')}
            />
          </Column>
        </Row>
      </Container>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
