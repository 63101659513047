import invariant from 'tiny-invariant'

import type { BackEndADH } from '@cdab/scania/qpr/interactor'
import { ApiClientDeviationHandling } from '@cdab/scania/qpr/interactors/api'
import type { Provider } from './client-providers'
import { PROVIDER_API } from './client-providers/constants'

const ERR_NO_CLIENT = 'client was undefined! Did you call initializeClient?'

// Maybe should move this to a (singleton?) class, so we don't have globals? 🤔
// Then again, a singleton is a global I guess 🤷
let client: BackEndADH | undefined = undefined

export function initializeClient(provider: Provider): BackEndADH {
  const providerType = provider.type
  switch (providerType) {
    case PROVIDER_API:
      client = new ApiClientDeviationHandling(provider.apiUrl)
      break

    default:
      throw new Error(`Invalid provider type, panicking! (${providerType})`)
  }

  return client
}

export function getClient(): BackEndADH {
  invariant(client, ERR_NO_CLIENT)
  return client
}
