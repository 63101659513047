import styled from 'styled-components'
import { Button } from '@cdab/scania/sdds'

export const RelativeDiv = styled.div`
  position: relative;
`

export const ImageDeleteButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 2rem;
`

export const UserImage = styled.img`
  width: 100%;
`
export const UserFileItem = styled.div`
  width: 100%;
`

export const UserFileLinkRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: var(--sdds-grey-100);
  border-bottom: 1px solid var(--sdds-grey-400);
  align-items: center;
  padding-left: 2rem;

  :first-child {
    border-top: 1px solid var(--sdds-grey-400);
  }
`

export const UserFileLink = styled.a`
  padding: var(--sdds-spacing-layout-16) 0;
`
