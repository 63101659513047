import styled from 'styled-components'
import type { SpinnerProps } from './spinner.types'

export const StyledSpinner = styled.div<Pick<SpinnerProps, 'block'>>`
  display: flex;
  width: ${({ block }) => (block ? '100%' : 'auto')};
  height: 100%;
  justify-content: center;
  align-items: center;
`
