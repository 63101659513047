import {
  AuditBottomNavigation,
  AuditItemList,
  EmptyState
} from '@cdab/scania/qpr/components'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import {
  getAuditIdFromParams,
  getAuditPointIdFromParams,
  getPledgeIdFromParams
} from '@cdab/scania/qpr/loaders'
import type { AuditModel } from '@cdab/scania/qpr/offline/models'
import type { Audit, Pledge } from '@cdab/scania/qpr/schema'
import type { PointType, SelectedItem } from '@cdab/scania/qpr/template-engine'
import { Column, Container, Row } from '@cdab/scania/sdds'
import { useDoOnce } from '@cdab/utils'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { To } from 'react-router-dom'
import {
  Navigate,
  Outlet,
  useNavigate,
  useParams,
  useRouteLoaderData
} from 'react-router-dom'
import invariant from 'tiny-invariant'
import type { AuditLoaderData } from '../../auditId'
import {
  ColumnWithShadow,
  Content,
  FullHeightRelativeContainer
} from './points-base-view.styles'
type Params = {
  auditId: string
  auditPointId: string | undefined
  pledgeId: string | undefined
}
function scrollToPoint(
  item: SelectedItem,
  overrides?: Partial<ScrollIntoViewOptions>
): void {
  const selectedElement = document.getElementById(
    `${item.pointType}-${item.id}`
  )

  const viewOptions: ScrollIntoViewOptions = {
    behavior: overrides?.behavior || 'smooth',
    block: overrides?.block || 'center',
    inline: overrides?.inline || 'start'
  }

  selectedElement?.scrollIntoView(viewOptions)
}
function getPointPath(pointType: PointType): string {
  switch (pointType) {
    case 'audit-point':
      return 'auditpoint'
    case 'pledge':
      return 'pledge'
    default:
      throw new Error('Invalid point type to navigate to!')
  }
}
function getNavigationTarget(selectedItem: SelectedItem): To {
  const pointPrefix = getPointPath(selectedItem.pointType)
  const pathname = `${pointPrefix}/${selectedItem.id}`
  return {
    pathname
  }
}
function getSelectedItemFromParams(
  params: Record<string, string>
): SelectedItem | undefined {
  // TODO: Should we add memo here?
  let pledgeId = undefined
  try {
    pledgeId = getPledgeIdFromParams(params)
  } catch (error) {
    // Do nothing
  }
  let auditPointId
  try {
    auditPointId = getAuditPointIdFromParams(params)
  } catch (error) {
    // Do nothing
  }
  if (pledgeId) {
    return {
      id: pledgeId,
      pointType: 'pledge'
    }
  }
  if (auditPointId) {
    return {
      id: auditPointId,
      pointType: 'audit-point'
    }
  }
  return undefined
}
type PointHistoryItem = {
  item: SelectedItem
  auditId: Audit['id']
}
let LAST_POINT: PointHistoryItem | undefined = undefined
const getDefaultOpenItems =
  (currentSelectedItem: SelectedItem | undefined, audit: AuditModel) =>
  (): SelectedItem[] => {
    if (!currentSelectedItem) return []
    if (currentSelectedItem.pointType === 'pledge') return [currentSelectedItem]
    invariant(currentSelectedItem.pointType === 'audit-point', `Invalid case!`)
    const auditPoint = audit.auditPoints.find(
      ap => ap.id === currentSelectedItem.id
    )
    if (!auditPoint) return []
    return [
      {
        id: auditPoint.pledgeId,
        pointType: 'pledge'
      }
    ]
  }
export const PointsBaseView = observer(() => {
  const { t } = useTranslation(['audit', 'common'])
  const params = useParams<Params>()
  const navigate = useNavigate()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const [isBottomNavigationOpen, setIsBottomNavigationOpen] = useState(false)
  const { updateTitles } = useTitle()
  const auditId = getAuditIdFromParams(params, { optional: true })
  const auditPointId = getAuditPointIdFromParams(params, { optional: true })
  const pledgeId = getPledgeIdFromParams(params, { optional: true })
  const { audit } = useRouteLoaderData('audit') as AuditLoaderData
  const auditPoint = useMemo(() => {
    return audit.auditPoints.find(ap => ap.id === auditPointId)
  }, [auditPointId, audit])
  const currentSelectedItem = getSelectedItemFromParams(params)
  const [openItems, setOpenItems] = useState<SelectedItem[]>(
    getDefaultOpenItems(currentSelectedItem || LAST_POINT?.item, audit)
  )
  const isOpeningBottomNavigation = useRef(true)
  useEffect(() => {
    if (isLg || !isBottomNavigationOpen) return
    if (!isOpeningBottomNavigation.current) {
      scrollToPoint({ id: auditPointId, pointType: 'audit-point' })
    } else {
      isOpeningBottomNavigation.current = !isBottomNavigationOpen
    }
    // Never rerender when updating ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditPointId, isBottomNavigationOpen, isLg])

  const [ignoreForceOpeningPladgeId, setIgnoreForceOpeningPladgeId] = useState<
    number | undefined
  >(undefined)

  useEffect(() => {
    // Only update the title if we have a valid audit point.
    if (auditPointId) {
      updateTitles({
        mobile: {
          description: audit.description || '',
          title: auditPoint?.auditPointNo || ''
        }
      })
    }
  }, [audit.description, auditPoint?.auditPointNo, auditPointId, updateTitles])
  const auditPointsSorted = useMemo(
    () => audit.auditPoints.slice().sort((a, b) => a.sortOrder - b.sortOrder),
    [audit.auditPoints]
  )
  invariant(
    audit.auditPoints.length > 0,
    `Expected to have audit points, but it was empty!`
  )
  useDoOnce(() => {
    scrollToPoint(
      LAST_POINT?.item || {
        id: auditPointId ?? auditPointsSorted[0].id,
        pointType: 'audit-point'
      },
      {
        behavior: 'auto',
        block: 'center'
      }
    )
  })

  useEffect(() => {
    //when trying to open audit-point, pladge could be closed
    if (
      currentSelectedItem &&
      currentSelectedItem.pointType === 'audit-point'
    ) {
      const auditPoint = audit.auditPoints.find(
        ap => ap.id === currentSelectedItem.id
      )

      const id = auditPoint?.pledgeId
      if (!id || ignoreForceOpeningPladgeId === id) return
      //its not ignored id, we should open pledge and reset ignore Id
      const newOpenItems = openItems.slice()
      const i = newOpenItems.findIndex(
        item => item.id === id && item.pointType === 'pledge'
      )
      if (i === -1 && id) {
        newOpenItems.push({
          id,
          pointType: 'pledge'
        })
        setOpenItems(newOpenItems)
      }
      setIgnoreForceOpeningPladgeId(undefined)
    }
  }, [
    audit.auditPoints,
    currentSelectedItem,
    ignoreForceOpeningPladgeId,
    openItems
  ])

  const currentPointIndex = useMemo(
    () => auditPointsSorted.findIndex(point => point.id === auditPointId),
    [auditPointsSorted, auditPointId]
  )
  const previousAuditPoint = useMemo(
    () => auditPointsSorted[currentPointIndex - 1],
    [auditPointsSorted, currentPointIndex]
  )
  const nextAuditPoint = useMemo(
    () => auditPointsSorted[currentPointIndex + 1],
    [auditPointsSorted, currentPointIndex]
  )

  const goToAuditPoint = useCallback(
    (point: 'previous' | 'next') => {
      const pointToNavigateTo =
        point === 'previous' ? previousAuditPoint : nextAuditPoint

      if (!pointToNavigateTo) return

      //Mobile, ignore force opening on navigation in menu
      if (currentSelectedItem?.pointType === 'audit-point') {
        const auditPoint = audit.auditPoints.find(
          ap => ap.id === currentSelectedItem.id
        )
        if (auditPoint) {
          setIgnoreForceOpeningPladgeId(auditPoint.pledgeId)
        }
      }

      const { pledgeId } = pointToNavigateTo

      setOpenItems([
        {
          id: pledgeId,
          pointType: 'pledge'
        }
      ])
      navigate({
        pathname: `auditpoint/${pointToNavigateTo.id}/status` // FIXME: add CONST routes object
      })
    },
    [
      audit.auditPoints,
      currentSelectedItem?.id,
      currentSelectedItem?.pointType,
      navigate,
      nextAuditPoint,
      previousAuditPoint
    ]
  )

  const onAuditPointClick = useCallback(
    (id: number) => {
      const auditPoint = audit.auditPoints.find(ap => ap.id === id)

      if (auditPointId === auditPoint?.id) return

      navigate({
        pathname: `auditpoint/${id}/status`
      })
    },
    [audit.auditPoints, auditPointId, navigate]
  )

  const onPledgeClick = useCallback(
    (id: number, isLongPress?: boolean) => {
      const newOpenItems = openItems.slice()

      const i = newOpenItems.findIndex(
        item => item.id === id && item.pointType === 'pledge'
      )

      if (isLongPress === true) {
        if (i === -1) {
          //open all
          newOpenItems.length = 0
          audit.pledges.forEach(({ id }) =>
            newOpenItems.push({ id, pointType: 'pledge' })
          )
        } else {
          //close all
          newOpenItems.length = 0
        }
        setOpenItems(newOpenItems)
        return
      }

      if (i === -1) {
        // If we just openend this point...
        if (isLg) {
          navigate({
            pathname: `pledge/${id}`
          })
        }

        newOpenItems.push({
          id,
          pointType: 'pledge'
        })
      } else {
        //closing pladge
        newOpenItems.splice(i, 1)
        //if current selected item is audit point of pladge which we are closing,
        //add current data for ignoring of opening theis pladge again
        if (currentSelectedItem?.pointType === 'audit-point') {
          const auditPoint = audit.auditPoints.find(
            ap => ap.id === currentSelectedItem.id
          )

          if (auditPoint && auditPoint.pledgeId === id) {
            setIgnoreForceOpeningPladgeId(id)
          }
        }
      }

      setOpenItems(newOpenItems)
      if (!isLg) scrollToPoint({ id: id, pointType: 'pledge' })
    },
    [
      audit.auditPoints,
      audit.pledges,
      currentSelectedItem?.id,
      currentSelectedItem?.pointType,
      isLg,
      navigate,
      openItems
    ]
  )

  const onItemClick = useCallback(
    (
      { id, pointType }: SelectedItem,
      score?: boolean | null,
      isLongPress?: boolean
    ) => {
      switch (pointType) {
        case 'audit-point':
          onAuditPointClick(id)
          break

        case 'pledge':
          onPledgeClick(id, isLongPress)
          break

        default:
          break
      }
    },
    [onAuditPointClick, onPledgeClick]
  )

  const onViewPledgeClick = useCallback(
    (pledgeId: Pledge['id']) => {
      navigate(
        getNavigationTarget({
          id: pledgeId,
          pointType: 'pledge'
        })
      )
    },
    [navigate]
  )

  const lastTarget =
    !currentSelectedItem &&
    LAST_POINT?.auditId === auditId &&
    getNavigationTarget(LAST_POINT.item)
  if (lastTarget) {
    return <Navigate to={lastTarget} />
  }
  LAST_POINT = currentSelectedItem
    ? {
        auditId,
        item: currentSelectedItem
      }
    : undefined

  const showEmptyState = !auditPointId && !pledgeId
  return (
    <Container
      fluid='push'
      paddingOnContainer={false}
      paddingOnColumns={false}
      fullHeightGrid
    >
      <Row>
        {isLg && (
          <Column padding={false} scrollY width={4}>
            <AuditItemList
              currentSelectedItem={currentSelectedItem}
              onItemClick={onItemClick}
              audit={audit}
              currentOpenItems={openItems}
              onScoreChange={onItemClick}
              onViewPledgeClick={onViewPledgeClick}
            />
          </Column>
        )}
        <ColumnWithShadow width={12} lg={8} padding={false}>
          <FullHeightRelativeContainer>
            {showEmptyState ? (
              <EmptyState
                title={t('points.empty-state.title', { ns: 'audit' })}
                description={t('points.empty-state.description')}
              />
            ) : (
              <Content>
                <Outlet />
              </Content>
            )}
            {!isLg && (
              <AuditBottomNavigation
                audit={audit}
                auditPointId={auditPointId}
                isOpen={isBottomNavigationOpen}
                onViewPledgeClick={item => {
                  onViewPledgeClick(item)
                  setIsBottomNavigationOpen(!isBottomNavigationOpen)
                }}
                onItemClick={(item, score, isLongPress) => {
                  onItemClick(item, score, isLongPress)
                  if (item.pointType === 'audit-point') {
                    setIsBottomNavigationOpen(!isBottomNavigationOpen)
                  }
                }}
                onScoreChange={onItemClick}
                onToggleMenu={() => {
                  isOpeningBottomNavigation.current = !isBottomNavigationOpen
                  setIsBottomNavigationOpen(!isBottomNavigationOpen)
                }}
                onLeftClick={
                  previousAuditPoint
                    ? () => goToAuditPoint('previous')
                    : undefined
                }
                onRightClick={
                  nextAuditPoint ? () => goToAuditPoint('next') : undefined
                }
                currentSelectedItem={currentSelectedItem}
                currentOpenItems={openItems}
              />
            )}
          </FullHeightRelativeContainer>
        </ColumnWithShadow>
      </Row>
    </Container>
  )
})
