import { WithLineLimit } from '@cdab/headless-components'
import {
  ArrowType,
  AuditItemState,
  AuditItemType
} from '@cdab/scania/qpr/components/atoms'
import type { Audit, AuditPoint, Pledge } from '@cdab/scania/qpr/schema'
import type {
  PointTypePledge,
  SelectedItem
} from '@cdab/scania/qpr/template-engine'
import { Button, IconStar } from '@cdab/scania/sdds'
import parse from 'html-react-parser'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  AuditItemContent,
  DocumentIcon,
  Extra,
  ExtrasRow,
  NoteIcon,
  StyledIconInfo
} from './audit-item-list.styles'
import { AuditPointItem } from './audit-point-item'
import { StyledPledgeItem } from './pledge-item.styles'
import { getPledgeStatistics } from './statistics'

const NumOfComplete = styled.span`
  min-width: 45px;
`

function bubbleScore(
  points: { score: boolean | null; isMandatory: boolean }[]
): AuditItemState {
  /**
   * Parent value depends on child points:
   *  - Yes (Green): All points are approved (yes)
   *  - No (Red)   : At least one point failed (No)
   *  - Not Complete (yellow): There is no failed points, but not every point is approved
   * TODO: What about deviations? Do they affect this?
   *
   * Non mandatory (development) points VALUES doesn't count, but they must be set (not null)!
   */
  let value: AuditItemState = AuditItemState.Yes

  for (const { score, isMandatory } of points) {
    if (isMandatory && score === false) {
      // if at least ONE REQUIRED point is false, we can break with false value
      value = AuditItemState.No
      break
    }

    // If we have at least one not set, regardless of type, we can never complete.
    // But we have to continue to check points, since one required point we haven't checked yet can be false
    if (score === null) {
      value = AuditItemState.NotComplete
    }

    // value will remain as "Yes" if we loop through without changing the value
  }

  return value
}

function getRequiredDevelopmentCounts(auditPoints: AuditPoint[]) {
  let completedDevelopmentAuditPoints = 0
  let completedRequiredAuditPoints = 0
  let totalDevelopmentAuditPoints = 0
  let totalRequiredAuditPoints = 0

  auditPoints.forEach(({ isMandatory, score }) => {
    if (isMandatory) {
      totalRequiredAuditPoints++

      if (score === true) {
        completedRequiredAuditPoints++
      }
    } else {
      totalDevelopmentAuditPoints++

      if (score === true) {
        completedDevelopmentAuditPoints++
      }
    }
  })

  return {
    completedDevelopmentAuditPoints,
    completedRequiredAuditPoints,
    totalDevelopmentAuditPoints,
    totalRequiredAuditPoints
  }
}

function getAuditPointsForPledge(
  pledge: Pledge,
  auditPoints: AuditPoint[]
): AuditPoint[] {
  return auditPoints.filter(ap => ap.pledgeId === pledge.id)
}

function sortAuditPoints(auditPoints: AuditPoint[]) {
  return auditPoints.slice().sort((a, b) => a.sortOrder - b.sortOrder)
}

export type PledgeItemProps = {
  pledge: Pledge
  audit: Audit
  onClick?: (item: SelectedItem, IsLongPress?: boolean) => void
  onScoreChange?: (item: SelectedItem, score: boolean | null) => void
  currentSelectedItem?: SelectedItem
  currentOpenItems: SelectedItem[]
  disabled: boolean
  onViewPledgeClick?: (pledgeId: Pledge['id']) => void
  showOpenPledgeButton: boolean
  isBottomNavigationItem?: boolean
}

export const PledgeItem = observer(({ pledge, ...props }: PledgeItemProps) => {
  const { t } = useTranslation('audit')

  const auditPoints = useMemo(
    () => getAuditPointsForPledge(pledge, props.audit.auditPoints),
    [pledge, props.audit.auditPoints]
  )

  const auditPointsSorted = useMemo(
    () => sortAuditPoints(auditPoints),
    [auditPoints]
  )

  const isOpen = useMemo(
    () =>
      props.currentOpenItems.filter(
        ({ id, pointType }) => pointType === 'pledge' && id === pledge.id
      ).length > 0,
    [props.currentOpenItems, pledge.id]
  )

  const value = bubbleScore(auditPoints)
  const {
    completedDevelopmentAuditPoints,
    completedRequiredAuditPoints,
    totalDevelopmentAuditPoints,
    totalRequiredAuditPoints
  } = getRequiredDevelopmentCounts(auditPoints)

  // We loose real time updates if we use useMemo here
  const { numOfDeviations, numOfNotes } = getPledgeStatistics(
    pledge.note,
    auditPoints,
    props.audit.checkPoints,
    props.audit.deviations
  )
  const handleAuditItemClick = (
    e: React.MouseEvent<Element, MouseEvent>,
    isLongPress?: boolean
  ) => {
    props.onClick?.({ id: pledge.id, pointType: 'pledge' }, isLongPress)
  }

  const handleOpenPledgeClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    props.onViewPledgeClick?.(pledge.id)
  }

  const isSelected =
    props.currentSelectedItem?.id === pledge.id &&
    props.currentSelectedItem.pointType === 'pledge'

  return (
    <>
      <StyledPledgeItem
        prefix={pledge.pledgeNo}
        hideButton
        type={AuditItemType.MajorSubheader}
        onClick={handleAuditItemClick}
        arrowType={isOpen ? ArrowType.Up : ArrowType.Down}
        value={value}
        id={`${'pledge' as PointTypePledge}-${pledge.id}`}
        disabled={props.disabled}
        selected={isSelected}
        isBottomNavigationItem={props.isBottomNavigationItem}
      >
        <AuditItemContent>
          <WithLineLimit maxLines={2}>
            {parse(t(pledge.translationGuid))}
          </WithLineLimit>
          <ExtrasRow>
            <Extra>
              <StyledIconInfo state={value} />
              R:{' '}
              <NumOfComplete>
                {completedRequiredAuditPoints} / {totalRequiredAuditPoints}
              </NumOfComplete>
            </Extra>
            <Extra>
              <IconStar />
              D:{' '}
              <NumOfComplete>
                {completedDevelopmentAuditPoints} /{' '}
                {totalDevelopmentAuditPoints}
              </NumOfComplete>
            </Extra>
            {numOfDeviations > 0 && (
              <Extra>
                <DocumentIcon /> {numOfDeviations}
              </Extra>
            )}
            {numOfNotes > 0 && (
              <Extra>
                <NoteIcon /> {numOfNotes}
              </Extra>
            )}
          </ExtrasRow>
          {props.showOpenPledgeButton && isOpen && (
            <Button
              data-pledgeid={pledge.id}
              className='sdds-u-mt2'
              ariaLabel='Open pledge'
              type='secondary'
              fullBleed
              onClick={handleOpenPledgeClick}
              size='sm'
              text={t('open-pledge')}
            />
          )}
        </AuditItemContent>
      </StyledPledgeItem>
      {isOpen &&
        auditPointsSorted.map(auditPoint => (
          <AuditPointItem
            key={`audit-point-${auditPoint.id}`}
            audit={props.audit}
            auditPoint={auditPoint}
            onClick={props.onClick}
            currentSelectedItem={props.currentSelectedItem}
            onScoreChange={props.onScoreChange}
            disabled={props.disabled}
          />
        ))}
    </>
  )
})
