import { Button, Column, Message, Row } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const StyledRow = styled(Row)`
  margin-top: 4rem;
  margin-bottom: 2rem;
  @media (max-width: ${({ theme }) => theme.size.lg}) {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }
`
export const Title = styled.h3.attrs({
  className: 'sdds-headline-03'
})`
  margin-bottom: 2rem;
  margin-top: 2rem;
`
export const StyledColumn = styled(Column)`
  @media (max-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 4rem;
  @media (max-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 3rem;
  }
`
export const StyledFirstButton = styled(Button)`
  padding-right: 8px;
`
export const StyledButton = styled(Button)`
  flex: 0.13;
  padding-right: 2rem;
`
export const StyledSectionTitle = styled.div`
  margin-bottom: 2rem;
  font-size: 3rem;
`
export const UploadFileButtonWrapper = styled.div`
  padding-right: 4px;
  margin-top: 3rem;
`

export const SpinnerWrapper = styled.div`
  flex: 0.13;
  padding-right: 1rem;
`
export const StyledMessage = styled(Message)`
  flex: auto;
`

export const StyledLastButtonWrapper = styled.div`
  flex: 1 0 30%;
  direction: rtl;
`

export const StyledTextfieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 2rem;
`

export const StyledLinkRow = styled(Row)`
  display: flex;
  align-items: center;
`
