import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type ValidatePasswordState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

type ValidatePasswordErrors = {
  minMax: boolean
  upper: boolean
  lower: boolean
  digits: boolean
  special: boolean
}

export function useDoValidatePassword() {
  const validatePassword = async (
    password: string
  ): Promise<ValidatePasswordErrors> => {
    const minMax = password.length >= 12 && password.length <= 64

    const upper = new RegExp(`^(?=.*[A-Z]).*$`).test(password)
    const lower = new RegExp(`^(?=.*[a-z]).*$`).test(password)
    const digits = new RegExp(`(?:[^0-9]*[0-9]){2}`).test(password)

    const special = new RegExp(
      `^(?=.*[\\!@#$%^&*()\\[\\]{}\\-_+=~\`|:;"'<>,./?]).*$`
    ).test(password)

    return { minMax, upper, lower, digits, special }
  }

  return {
    validatePassword
  }
}

export function useValidatePassword() {
  const { t } = useTranslation('user')
  const { validatePassword } = useDoValidatePassword()
  const [submitState, setSubmitState] = useState<ValidatePasswordState>({
    status: 'none',
    message: null
  })

  const handleDoValidatePassword = useCallback(
    async (password: string) => {
      const response = await validatePassword(password)
      setSubmitState({ status: 'none', message: null })

      const charsError =
        !response.lower ||
        !response.upper ||
        !response.digits ||
        !response.special

      let message = t('message.password-must-contain')

      message += !response.minMax ? t('message.password-min-max') : ''
      message += !response.digits ? t('message.password-digits') : ''
      message += !response.lower ? t('message.password-lower') : ''
      message += !response.upper ? t('message.password-upper') : ''
      message += !response.special ? t('message.password-special') : ''

      message = message.substring(0, message.length - 2) //removing ', '
      message += charsError ? ' ' + t('characters') : ''

      if (!response.minMax || charsError) {
        setSubmitState({ status: 'error', message: message })
        return false
      }

      setSubmitState({ status: 'success', message: '' })
      return true
    },
    [t, validatePassword]
  )
  return [submitState, handleDoValidatePassword] as const
}
