import { Container, Row, Column } from '@cdab/scania/sdds'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { useTranslation } from 'react-i18next'

export function NotFound({ text }: { text?: string }) {
  const { t } = useTranslation()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')

  return (
    <Container fluid='normal'>
      <Row>
        <Column width={12} lg={6}>
          {isLg && <h2>{t('not-found')}</h2>}
          <div>{text ? text : t('not-found-description')}</div>
        </Column>
      </Row>
    </Container>
  )
}
