import type { Audit } from '@cdab/scania/qpr/schema'
import type { AuditModelBase } from './base'
import { restoreDeviationDates } from './restoration.utils'

export type FileData = {
  id: string
  url: string
  isImage: boolean
  fileName: string
  isUploaded: boolean
  uploadProgress: number
}

type AuditPointModel = {
  auditPointNo: string
  translationGuid: string
  id: number
  isMandatory: boolean
  note: string | null
  pledgeId: number
  purposeTranslationGuid: string
  score: boolean | null
  sortOrder: number
  files: FileData[]
  isGettingFiles: boolean
}

type DeviationModel = {
  files: FileData[]
  isGettingFiles: boolean
  approvalDate: Date | null
  auditId: number
  auditPointId: number
  auditPointNumber: string
  deviation: string
  deviationWithoutActions: boolean | null
  expirationDate: Date | null
  id?: number
  proposedActions: string | null
  responsible: string | null
  clientGuid: string
  actionPlanId: number
}

type PledgeAreaModel = {
  id: number
  description: string
}

type PledgeModel = {
  id: number
  note: string | null
  translationGuid: string
  pledgeNo: string | null
  pledgeAreaId: number | null
  sortOrder: number
  files: FileData[]
  isGettingFiles: boolean
}

type CheckPointModel = {
  id: number
  auditPointId: number
  checkPointNo: string
  translationGuid: string
  note: string | null
  score: boolean | null
  sortOrder: number
}

type ReferenceDocumentModel = {
  auditPointId: number | null
  checkPointId: number | null
  documentVersion: number
  id: number
  title: string
  url: string
}

export type AuditModelV1Data = {
  description: string | null
  id: number
  did: number
  note: string | null
  isCertified: boolean
  isReadonly: boolean
  dosVersion: number
  extraAuditors: string
  pledgeAreas: PledgeAreaModel[]
  pledges: PledgeModel[]
  checkPoints: CheckPointModel[]
  referenceDocuments: ReferenceDocumentModel[]
  date: Date
  auditPoints: AuditPointModel[]
  deviations: DeviationModel[]
}

export type AuditModelV1 = AuditModelBase<AuditModelV1Data, 1>

export function restoreAuditFromDataV1(audit: AuditModelV1) {
  restoreDeviationDates(audit.deviations)

  return audit
}

export function createFromAuditV1(audit: Audit) {
  const auditModel: AuditModelV1 = {
    ...audit,
    auditPoints: audit.auditPoints.map(ap => ({
      ...ap,
      files: [],
      isGettingFiles: false
    })),
    deviations: audit.deviations.map(d => ({
      ...d,
      files: [],
      isGettingFiles: false
    })),
    pledges: audit.pledges.map(pl => ({
      ...pl,
      files: [],
      isGettingFiles: false
    })),
    VERSION: 1
  }

  return auditModel
}
