import type { PledgeArea } from '@cdab/scania/qpr/schema'

export const PledgeAreas: PledgeArea[] = [
  {
    id: 1,
    description: 'Surroundings & Conduct'
  },
  {
    id: 2,
    description: 'Sales'
  },
  {
    id: 3,
    description: 'Service'
  },
  {
    id: 4,
    description: 'Continuous improvement'
  },
  {
    id: 5,
    description: 'Sustainability'
  }
]
