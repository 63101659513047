import type { Pledge } from '@cdab/scania/qpr/schema'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import type { AuditItemListProps } from './audit-item-list.types'

import { PledgeItem } from './pledge-item'

// NOTE: Should this be renamed to PledgeList?
export const AuditItemList = observer(
  ({ currentOpenItems = [], ...props }: AuditItemListProps) => {
    const pledgesSorted = useMemo(
      () =>
        props.audit.pledges.slice().sort((a, b) => b.sortOrder - a.sortOrder),
      [props.audit.pledges]
    )

    return (
      <div>
        {pledgesSorted.map((pledge: Pledge) => (
          <PledgeItem
            showOpenPledgeButton={!!props.showOpenPledgeButton}
            key={pledge.id}
            pledge={pledge}
            audit={props.audit}
            onClick={(e, IsLongPress) =>
              props.onItemClick
                ? props.onItemClick(e, undefined, IsLongPress)
                : undefined
            }
            currentSelectedItem={props.currentSelectedItem}
            currentOpenItems={currentOpenItems}
            onScoreChange={props.onScoreChange}
            disabled={props.audit.isReadonly}
            onViewPledgeClick={props.onViewPledgeClick}
            isBottomNavigationItem={props.isBottomNavigationList}
          />
        ))}
      </div>
    )
  }
)
