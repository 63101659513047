import { useEffect, useRef } from 'react'
import { useEvent } from './use-event'

export function useSddsTableRowClick(
  tableEl: Element | ShadowRoot | null | undefined,
  clb: (row: Element, event: Event) => void
) {
  // Sync callback
  const callbackRef = useRef(clb)
  useEffect(() => {
    callbackRef.current = clb
  }, [clb])

  // Manage click event
  useEvent(tableEl, 'click', (e: Event) => {
    const path = e.composedPath()
    let row: HTMLTableRowElement | undefined = undefined

    // Find table row from event path
    for (let i = 0; i < path.length; i++) {
      if ((path[i] as Node).nodeName === 'SDDS-TABLE-BODY-ROW') {
        row = path[i] as HTMLTableRowElement
        break
      }
    }

    if (row && callbackRef.current) {
      callbackRef.current(row, e)
    }
  })
}

export function useSddsTableRowClickWithCellValue(
  tableEl: Element | ShadowRoot | null | undefined,
  cellKey: string,
  clb: (value: string, event: Event) => void
) {
  useSddsTableRowClick(tableEl, (row, event) => {
    const cell = row.querySelector(`[cell-key=${cellKey}]`)
    const value = cell?.getAttribute('cell-value')
    if (value) clb(value, event)
  })
}
