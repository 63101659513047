import styled from 'styled-components'

import type { StyleProps } from './audit-grid-item-header.styles.types'

export const H2 = styled.p<StyleProps>`
  ${({ clickable }) =>
    clickable &&
    `
  cursor: pointer;
`}
`
