import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export interface Language {
  id: number
  name: string
}

export const LanguageSchema: JSONSchemaType<Language> = {
  type: 'object',

  properties: {
    id: {
      type: 'number'
    },
    name: {
      type: 'string'
    }
  },

  required: ['id', 'name']
}

const validateLanguage: ValidateFunction<Language> = ajv.compile(LanguageSchema)

export const isValidLanguage = (language: unknown): language is Language =>
  isValidThing<Language>(language, validateLanguage)
