import type { GridItemId } from '@cdab/scania/qpr/audit-grid'
import { formatForGrid, getAuditGridItem } from '@cdab/scania/qpr/audit-grid'
import {
  AuditPointDetail,
  CheckPointDetail
} from '@cdab/scania/qpr/components/atoms'
import type { Audit } from '@cdab/scania/qpr/schema'
import type { AuditGridSettings } from '@cdab/scania/qpr/settings'
import parse from 'html-react-parser'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export interface AuditGridProps {
  audit: Audit
  onDetailClick: (detailId: GridItemId) => void
  selectedGridItemIds: string[]
  settings: AuditGridSettings
  className?: string
}

export function AuditGrid({
  audit: auditData,
  onDetailClick,
  selectedGridItemIds,
  settings,
  className
}: AuditGridProps) {
  const { t } = useTranslation()
  const audit = formatForGrid(auditData)

  const PledgeAreaSection = getAuditGridItem(settings.pledgeAreas.gridItemType)
  const PledgeSection = getAuditGridItem(settings.pledges.gridItemType)
  const AuditPointSection = getAuditGridItem(settings.auditPoints.gridItemType)
  const CheckPointSection = getAuditGridItem(settings.checkPoints.gridItemType)

  const canShowAuditDetails = useMemo(
    () => settings.auditPoints.showDetails && !settings.useDetailsSplitScreen,
    [settings.auditPoints.showDetails, settings.useDetailsSplitScreen]
  )

  return (
    <div className={className}>
      {audit.pledgeAreas.map(pledgeArea => (
        <PledgeAreaSection
          id={`pledge-area-${pledgeArea.id}`}
          key={pledgeArea.id}
          header={pledgeArea.description}
          fontClassName='sdds-headline-03'
        >
          {pledgeArea.pledges.map(pledge => {
            const pledgeHeaderId: GridItemId = `pledge-${pledge.id}`
            return (
              <PledgeSection
                id={pledgeHeaderId}
                header={`${pledge.pledgeNo} ${parse(
                  t(pledge.translationGuid)
                )}`}
                key={pledge.id}
                fontClassName='sdds-headline-04'
              >
                {pledge.auditPoints.map(auditPoint => {
                  const auditPointHeaderId: GridItemId = `audit-point-${auditPoint.id}`
                  return (
                    <AuditPointSection
                      id={auditPointHeaderId}
                      fontClassName='sdds-paragraph-02'
                      header={`${auditPoint.auditPointNo} ${parse(
                        t(auditPoint.translationGuid)
                      )}`}
                      key={auditPoint.id}
                      onHeaderClick={
                        settings.auditPoints.showDetails
                          ? onDetailClick
                          : undefined
                      }
                    >
                      {auditPoint.checkPoints.map(checkPoint => {
                        const checkPointHeaderId: GridItemId = `checkpoint-${checkPoint.id}`

                        return (
                          <div key={checkPoint.id}>
                            <CheckPointSection
                              id={checkPointHeaderId}
                              header={`${checkPoint.checkPointNo} ${
                                parse(checkPoint.translationGuid) as string
                              }`}
                              fontClassName='sdds-body-02'
                              onHeaderClick={
                                settings.checkPoints.showDetails
                                  ? onDetailClick
                                  : undefined
                              }
                            />
                            {settings.checkPoints.showDetails &&
                              !settings.useDetailsSplitScreen &&
                              selectedGridItemIds.includes(
                                checkPointHeaderId
                              ) && <CheckPointDetail checkPoint={checkPoint} />}
                          </div>
                        )
                      })}
                      {canShowAuditDetails &&
                        selectedGridItemIds.includes(auditPointHeaderId) && (
                          <AuditPointDetail
                            auditPoint={auditPoint}
                            key={auditPoint.id}
                          />
                        )}
                    </AuditPointSection>
                  )
                })}
              </PledgeSection>
            )
          })}
        </PledgeAreaSection>
      ))}
    </div>
  )
}
