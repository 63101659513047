export function fileIsImage(file: File): boolean {
  return file.type.startsWith('image/')
}

export async function fileToArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.addEventListener('loadend', () => {
      const { result } = reader

      if (result === null || typeof result === 'string') {
        reject()
        return
      }
      resolve(result)
    })
    reader.addEventListener('error', reject)

    reader.readAsArrayBuffer(file)
  })
}

export function arrayBufferToFile(
  buffer: ArrayBuffer,
  fileName: string,
  mimeType: string
) {
  return new File([buffer], fileName, {
    type: mimeType
  })
}
