import {
  AdminTranslationsTabs,
  CenteredContainer,
  ElasticContentContainer,
  TabContainer
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import {
  getAdminTranslationsDOSVersionIdFromParams,
  getAdminTranslationsLanguageIdFromParams
} from '@cdab/scania/qpr/loaders'
import type { AdminTranslations } from '@cdab/scania/qpr/schema'
import {
  Breadcrumbs,
  Column,
  Container,
  EmptyScreen,
  IconWarning,
  Row
} from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs } from 'react-router'
import {
  Outlet,
  isRouteErrorResponse,
  json,
  useLoaderData,
  useRouteError
} from 'react-router'
import styled from 'styled-components'

const StyledRow = styled(Row)`
  margin-left: 6rem;
  margin-right: 6rem;
`

export type AdminTranslationsLanguageLoaderData = {
  languageName: string
  translations: AdminTranslations
}

export async function loader({
  params
}: LoaderFunctionArgs): Promise<AdminTranslationsLanguageLoaderData> {
  try {
    const languageId = getAdminTranslationsLanguageIdFromParams(params)
    const dosVersionId = getAdminTranslationsDOSVersionIdFromParams(params)

    const client = getClient()
    const userInfo = await client.GetuserInfo()
    if (!userInfo) {
      throw json({
        status: 401
      })
    }

    const [languages, translations] = await Promise.all([
      client.UserService.GetLanguagesForUser(true),
      client.TranslationsService.GetAdminTranslations(languageId, dosVersionId)
    ])

    const languageName = languages.find(l => l.id === languageId)?.name ?? ''

    return {
      languageName,
      translations
    }
  } catch (error) {
    if (error instanceof Response) {
      throw new Response(null, {
        status: error.status
      })
    } else if (error instanceof Error) {
      throw new Response(error.message, { status: 500 })
    } else {
      throw new Response(null, {
        status: 404
      })
    }
  }
}

export function Page() {
  const { t } = useTranslation('common')
  const { updateTitles } = useTitle()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { languageName } =
    useLoaderData() as AdminTranslationsLanguageLoaderData

  useEffect(() => {
    updateTitles({
      contentHeader: {
        title: t('administration'),
        subtitle: capitalizeFirstLetter(t('translations'))
      },
      mobile: {
        title: `${capitalizeFirstLetter(t('translations'))}`,
        description: null
      }
    })
  }, [t])

  return (
    <ElasticContentContainer
      scrollY={true}
      overflowHidden={true}
      header={
        <>
          <StyledRow>
            <Column width={12} padding={isLg} className='sdds-u-pt2'>
              <Breadcrumbs
                links={[
                  {
                    text: capitalizeFirstLetter(
                      t('translations', { ns: 'common' })
                    ),
                    to: `/admin/translations`
                  },
                  { text: languageName, to: '' }
                ]}
              />
            </Column>
          </StyledRow>
          <StyledRow>
            <Column width={12} padding={isLg} className='sdds-u-pt2'>
              <AdminTranslationsTabs
                links={{
                  pledges: 'pledges',
                  auditPoints: 'audit-points',
                  auditPurposes: 'audit-purposes',
                  checkPoints: 'check-points',
                  pledgePerspectives: 'pledge-perspectives'
                }}
              />
            </Column>
          </StyledRow>
        </>
      }
    >
      <Container fluid='normal' fullHeight>
        <TabContainer>
          <Outlet />
        </TabContainer>
      </Container>
    </ElasticContentContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    if (error.status === 403) {
      message = t('access-denied')
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
