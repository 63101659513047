import {
  ClickableList,
  ClickableListItem
} from '@cdab/scania/qpr/components/atoms'
import { Block, IconDocumentCheck } from '@cdab/scania/sdds'

import type { FavoriteAuditsListProps } from './favorite-audits-list.types'
import { formatISODate } from '@cdab/utils'
import { StyledRightItem } from './favorite-audits-list.styles'

function getDescription({
  auditor,
  description,
  auditDate
}: {
  auditor: string
  description?: string
  auditDate: Date
}) {
  const textDescription = description ? `, ${description}` : ''
  const textAuditDate = `, ${formatISODate(auditDate)}`

  return `${auditor}${textDescription}${textAuditDate}`
}

function getTitle({
  dealerCity,
  dealerName,
  expiryDate
}: {
  dealerCity: string
  dealerName: string
  expiryDate: Date | null
}) {
  const textExpiryDate = expiryDate ? `, ${formatISODate(expiryDate)}` : ``

  return `${dealerCity}, ${dealerName}${textExpiryDate}`
}

export function FavoriteAuditsList({
  favoriteAudits,
  onClick
}: FavoriteAuditsListProps) {
  // TODO: Add file type icon
  // https://linear.app/congenialdata/issue/QPR-256/indicate-file-type-for-document-list

  return (
    <Block color='on-grey' disablePadding={true}>
      <ClickableList>
        {favoriteAudits.map(favoriteAudit => (
          <ClickableListItem
            key={favoriteAudit.id}
            title={getTitle({
              dealerCity: favoriteAudit.dealerCity,
              dealerName: favoriteAudit.dealerName,
              expiryDate: favoriteAudit.expiryDate
            })}
            description={getDescription({
              auditor: favoriteAudit.auditor,
              description: favoriteAudit.auditDescription,
              auditDate: favoriteAudit.auditDate
            })}
            onClick={() => onClick(favoriteAudit.auditId)}
            rightItem={
              favoriteAudit.isCertified && (
                <StyledRightItem>
                  <IconDocumentCheck />
                </StyledRightItem>
              )
            }
          />
        ))}
      </ClickableList>
    </Block>
  )
}
