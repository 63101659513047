import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSddsTableRowClickWithCellValue } from '@cdab/scania/qpr/hooks'
import type { DealerAudit } from '@cdab/scania/qpr/schema'
import { DataTable, HeaderCell } from '@cdab/scania/sdds'
import { capitalizeFirstLetter, formatISODate } from '@cdab/utils'
import type { TFunction } from 'i18next'

/* eslint-disable-next-line */
export interface AuditsTableProps {
  audits: DealerAudit[]
  onRowClick: (auditId: string) => void
}

function prepareTableData(data: readonly DealerAudit[], t: TFunction) {
  const preparedData = data
    .map(dealerAudit => {
      return {
        auditDate: dealerAudit.auditDate
          ? formatISODate(dealerAudit.auditDate)
          : null,
        isCertifyingAudit: dealerAudit.isCertificationAudit ? t('yes') : null,
        auditDescription: dealerAudit.auditDescription,
        auditor: dealerAudit.auditor,
        extraAuditors: dealerAudit.extraAuditors,
        auditType: dealerAudit.auditTypeDescription,
        dosVersion: dealerAudit.dosVersion,
        hasDeviations: dealerAudit.hasDeviations ? 'Yes' : 'No',
        hasNotes: dealerAudit.hasNotes ? 'Yes' : 'No',
        id: dealerAudit.id
      }
    })
    .sort((a, b) => {
      const aDate = a.auditDate || ''
      const bDate = b.auditDate || ''

      return aDate > bDate ? -1 : aDate < bDate ? 1 : 0
    })

  return preparedData
}

export function AuditsTable({ audits, onRowClick }: AuditsTableProps) {
  const { t } = useTranslation('common')
  const [tableEl, setTableEl] = useState<HTMLTableRowElement>()
  useSddsTableRowClickWithCellValue(tableEl, 'id', onRowClick)

  return (
    <DataTable
      compact
      ref={setTableEl}
      filtering
      title={capitalizeFirstLetter('audits')}
      data={prepareTableData(audits, t)}
      whiteBackground
      scroll
    >
      <HeaderCell sortable columnKey='auditDate' title={t('date')} />
      <HeaderCell
        columnKey='isCertifyingAudit'
        title={t('certification')}
        customWidth='0px'
      />
      <HeaderCell
        sortable
        columnKey='auditDescription'
        title={t('description')}
      />
      <HeaderCell sortable columnKey='auditor' title={t('auditor')} />
      <HeaderCell
        sortable
        columnKey='extraAuditors'
        title={t('extra-auditors')}
      />
      <HeaderCell columnKey='auditTypeDescription' title={t('audit-type')} />
      <HeaderCell sortable columnKey='dosVersion' title={t('dos-version')} />
      <HeaderCell
        sortable
        columnKey='hasDeviations'
        title={t('has-deviations')}
      />
      <HeaderCell sortable columnKey='hasNotes' title={t('has-notes')} />
      <HeaderCell sortable columnKey='id' title={t('id')} />
    </DataTable>
  )
}
