import { v4 as uuidv4 } from 'uuid'
import { action } from 'mobx'

import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { Audit, FileData } from '@cdab/scania/qpr/schema'
import { fileIsImage, fileToArrayBuffer } from '@cdab/utils'

import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import invariant from 'tiny-invariant'
import { getAudit } from '../store/audits'
import type { CachedExtraFileData } from './types'
import type { AuditPointModel, AuditModel } from '../audit-model'

type Type = 'add-audit-point-file'

type Data = {
  auditPointId: AuditPointModel['id']
  file: File
  fileGuid: string
}

export type AddAuditPointFileOperationData = OperationDataBase<Type, Data>

export type CachedAddAuditPointFileOperationData =
  AddAuditPointFileOperation & {
    data: AddAuditPointFileOperationData & {
      value: Data & CachedExtraFileData
    }
  }

export class AddAuditPointFileOperation extends Operation {
  public data: AddAuditPointFileOperationData
  private responseFileData: FileData | null = null

  constructor(auditId: Audit['id'], value: Data, guid = uuidv4()) {
    super(auditId, guid)

    this.data = {
      type: 'add-audit-point-file',
      value,
      previousValue: undefined
    }
  }

  private getAuditPoint = (
    audit: AuditModel,
    auditPointId: AuditPointModel['id']
  ) => {
    const auditPoint = audit.auditPoints.find(ap => ap.id === auditPointId)

    invariant(
      auditPoint,
      `${this.data.type}: No audit point found for ${auditPointId}`
    )

    return auditPoint
  }

  public ApplyTo = action((audit: AuditModel): void => {
    const { auditPointId, file, fileGuid } = this.data.value

    const auditPoint = this.getAuditPoint(audit, auditPointId)

    const fileData: FileData = this.responseFileData ?? {
      fileName: file.name,
      id: fileGuid,
      isImage: fileIsImage(file),
      url: URL.createObjectURL(file),
      isUploaded: false,
      uploadProgress: 0
    }

    // Could happen if we apply the same operation twice
    const hasFile = !!auditPoint.files.find(
      f => f.id === fileData.id || f.id === fileGuid
    )

    if (hasFile) {
      return
    }

    auditPoint.files.push(fileData)
  })

  public GetName = (): string =>
    `${this.data.type}-${this.auditId}-${this.data.value.fileGuid}`

  public SendOperation = async (client: BackEnd): Promise<boolean> => {
    const { auditPointId, file, fileGuid } = this.data.value

    const audit = getAudit(this.auditId)
    let auditPointFile: FileData | undefined = undefined
    if (audit) {
      const auditPoint = this.getAuditPoint(audit, auditPointId)

      if (auditPoint) {
        auditPointFile = auditPoint.files.find(f => f.id === fileGuid)
      }
    }

    const { fileData, success } =
      await client.StorageService.UploadFileForAuditPoint(
        this.auditId,
        auditPointId,
        file,
        action(progressEvent => {
          if (!auditPointFile) return
          if (typeof progressEvent.progress === 'undefined') return

          auditPointFile.uploadProgress = progressEvent.progress
        })
      )

    this.responseFileData = fileData

    return success
  }

  public RollbackOn(audit: AuditModel): void {
    const { fileGuid, auditPointId } = this.data.value

    const auditPoint = audit.auditPoints.find(ap => ap.id === auditPointId)
    invariant(
      auditPoint,
      `${this.data.type}: No audit point found for ${auditPoint}`
    )

    const index = auditPoint.files.findIndex(f => f.id === fileGuid)
    invariant(
      index !== -1,
      `${this.data.type}: No file found for guid ${fileGuid}`
    )

    auditPoint.files.splice(index, 1)
  }

  public override GetData =
    async (): Promise<CachedAddAuditPointFileOperationData> => {
      const { file } = this.data.value
      const fileBuffer = await fileToArrayBuffer(file)

      const cachedData: CachedExtraFileData = {
        fileBuffer,
        fileName: file.name,
        mimeType: file.type
      }

      const json = JSON.parse(JSON.stringify(this))

      return {
        ...json,
        data: {
          ...json.data,
          value: {
            ...json.data.value,
            ...cachedData
          }
        }
      }
    }

  public override OnConfirm = action((): void => {
    if (!this.responseFileData) return

    const audit = getAudit(this.auditId)
    if (!audit) return

    const { auditPointId, fileGuid } = this.data.value

    const auditPoint = this.getAuditPoint(audit, auditPointId)

    invariant(
      auditPoint,
      `AddAuditpointFile.OnConfirm: no audit point for ${this.auditId}:${auditPointId}`
    )

    const file = auditPoint.files.find(f => f.id === fileGuid)

    if (file) {
      URL.revokeObjectURL(file.url)
      file.url = this.responseFileData.url
      file.id = this.responseFileData.id
      file.isUploaded = true
    } else {
      auditPoint.files.push(this.responseFileData)
    }
  })
}
