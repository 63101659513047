import { TdsLink } from '@scania/tegel-react'
import type { LinkProps } from './link.types'

export function Link({
  children,
  className,
  disabled = false,
  openInNewTab = undefined,
  onClick,
  noUnderline = false,
  url,
  id
}: Readonly<LinkProps>) {
  const handleClick = () => {
    if (!disabled && onClick) onClick()
  }

  return (
    <TdsLink underline={!noUnderline} className={className}>
      <a
        target={openInNewTab ? '_blank' : undefined}
        href={url}
        onClick={handleClick}
        rel={openInNewTab ? 'noreferrer' : undefined}
        id={id}
      >
        {children}
      </a>
    </TdsLink>
  )
}
