import styled from 'styled-components'
import { DateTime } from '@cdab/scania/sdds'

type TType = {
  extra?: boolean
}

export const FormRow = styled.div.attrs<TType>(({ extra }) => ({
  className: extra === true ? 'sdds-u-mb3 sdds-u-pb3' : 'sdds-u-mb3'
}))<TType>``

export const DateRow = styled.div.attrs({
  className: 'sdds-row'
})``

export const StyledDateTime = styled(DateTime).attrs({
  className: 'sdds-col-xs-12 sdds-col-md-6 sdds-u-mt3'
})`
  @media (max-width: ${({ theme }) => theme.size.md}) {
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.size.md}) {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`
