import classnames from 'classnames'

import type { Props } from './details-panel.types'
import { DetailsPanelInner, DetailsPanelOuter } from './details-panel.styles'

export function DetailsPanel({
  hidden,
  children,
  className,
  showBorderRight,
  showBorderLeft
}: Props) {
  return (
    <DetailsPanelOuter
      hidden={hidden}
      className={classnames(className, 'sdds-u-ml1', {
        'sdds-divider-dark-border-left': showBorderLeft,
        'sdds-divider-dark-border-right': showBorderRight
      })}
    >
      <DetailsPanelInner
        className={classnames({
          'sdds-u-pl1': showBorderLeft,
          'sdds-u-pr1': showBorderRight
        })}
      >
        {children}
      </DetailsPanelInner>
    </DetailsPanelOuter>
  )
}
