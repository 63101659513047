import type { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

export type WithLineLimitProps = PropsWithChildren<{
  maxLines: number
  breakWords?: boolean
}>

const LineLimit = styled.div<WithLineLimitProps>`
  /* Taken from: https://stackoverflow.com/questions/5269713/css-ellipsis-on-second-line/50566101#50566101 */
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.maxLines};
    -webkit-box-orient: vertical;
  }
  > :first-child {
    margin-top: 0;
  }

  /* Fallback to always 1 line height on unsupported browsers */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props =>
    props.breakWords &&
    css`
      word-break: break-all;
    `}
`

export const WithLineLimit = (props: WithLineLimitProps) => (
  <LineLimit {...props}>{props.children}</LineLimit>
)
