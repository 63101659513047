import type { Deviation } from '@cdab/scania/qpr/schema'
import { action } from 'mobx'
import invariant from 'tiny-invariant'

export type JSObject = Record<string, unknown>

const mergeArrays = action(
  (target: Array<JSObject>, source: Array<JSObject>) => {
    source.forEach(item => {
      const { id } = item
      invariant(id, `No id when merging arrays!`)
      const index = target.findIndex(i => {
        const { id: targetId } = i

        invariant(targetId, `targetId was not set`)

        return targetId === id
      })

      if (index === -1) {
        target.push(item)
      } else {
        mergeObjects(target[index], item)
      }
    })
  }
)

const KEYS_TO_SKIP: string[] = ['clientGuid' as keyof Deviation]

export const mergeObjects = action(
  (targetObject: JSObject, sourceObject: JSObject) => {
    Object.keys(sourceObject).forEach(key => {
      if (KEYS_TO_SKIP.includes(key)) return

      const sourceValue = sourceObject[key]
      const targetValue = targetObject[key]

      if (typeof targetValue === 'undefined' || targetValue === null) {
        targetObject[key] = sourceValue
        return
      }

      if (Array.isArray(sourceValue)) {
        invariant(
          Array.isArray(targetValue),
          `target value for key ${key} was not an array, but it was for source`
        )

        mergeArrays(targetObject[key] as Array<JSObject>, sourceValue)
        return
      }

      if (sourceValue !== null && typeof sourceValue === 'object') {
        invariant(
          typeof targetValue === 'object',
          `target value for ${key} was not object, but it was for source`
        )

        mergeObjects(targetObject[key] as JSObject, sourceValue as JSObject)
        return
      }

      targetObject[key] = sourceValue
    })
  }
)
