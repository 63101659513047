import { useEffect } from 'react'

export const useEvent = (
  object: Element | ShadowRoot | null | undefined,
  event: string,
  callback: EventListenerOrEventListenerObject
): void => {
  useEffect(() => {
    if (object) {
      object.addEventListener(event, callback)
    }

    return () => {
      if (object) {
        object.removeEventListener(event, callback)
      }
    }
  }, [object, event, callback])
}
