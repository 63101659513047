/**
 * Downloades file from ArrayBuffer object
 *
 * @param arrayBuffer - array buffer with file
 * @param type - type of file
 * @param filename - name of file
 * @return boolean - success
 */
export function downloadFileFromArrayBuffer(
  arrayBuffer: ArrayBuffer,
  type: string,
  filename: string
) {
  const fileBlob = new Blob([arrayBuffer], {
    type: type
  })

  if (fileBlob.size === 0) return false

  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(fileBlob)
  link.download = filename
  link.click()
  link.remove()

  return true
}
