import * as sentry from '@sentry/react'
import invariant from 'tiny-invariant'

import type { AuditModel, UnknownAuditModel } from '.'
import { LATEST_AUDIT_MODEL_VERSION } from '.'
import { migrateAuditModelToLatest } from './migrations'
import { restoreAuditFromDataV1 } from './v1'
import { restoreAuditFromDataV2 } from './v2'
import { restoreAuditFromDataV3 } from './v3'
import { restoreAuditFromDataV4 } from './v4'
import { restoreAuditFromDataV5 } from './v5'

export function restoreAuditFromData(auditData: UnknownAuditModel): AuditModel {
  let audit: UnknownAuditModel

  const { VERSION } = auditData
  switch (VERSION) {
    case 1:
      audit = restoreAuditFromDataV1(auditData)
      break

    case 2:
      audit = restoreAuditFromDataV2(auditData)
      break

    case 3:
      audit = restoreAuditFromDataV3(auditData)
      break

    case 4:
      audit = restoreAuditFromDataV4(auditData)
      break

    case 5:
      audit = restoreAuditFromDataV5(auditData)
      break

    default: {
      const error = new Error(
        `Tried to restore audit with unknown version (${VERSION})! `
      )
      sentry.captureException(error)
      throw error
    }
  }

  migrateAuditModelToLatest(audit)

  invariant(
    audit.VERSION === LATEST_AUDIT_MODEL_VERSION,
    `Audit was not latest version after migrations!`
  )
  return audit
}
