import type {
  ChatMessageUploading,
  CreateChatMessageData
} from '@cdab/scania/qpr/components/atoms'
import { ChatMessage, Chatinput } from '@cdab/scania/qpr/components/atoms'
import { useADHCreateChatMessageWithFile } from '@cdab/scania/qpr/hooks'
import type {
  ChatMessage as ChatMessageType,
  DocumentFile
} from '@cdab/scania/qpr/schema'
import { freeMemory } from '@cdab/scania/qpr/utils'
import { Row } from '@cdab/scania/sdds'
import { fileIsImage, formatISODate } from '@cdab/utils'
import { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  ActionRow,
  ActionRowTitle,
  MessagesWrapper,
  StyledBadges,
  StyledElasticContentContainer,
  StyledUploadingMessage
} from './chat.styles'
import type { ChatProps } from './chat.types'

const TEGEL_DOCUMENT_ICON_URL =
  'https://cdn.digitaldesign.scania.com/icons/website_hosted/document.svg'

export function Chat({
  files,
  actionPlanItemId,
  data,
  showHeader = true
}: Readonly<ChatProps>) {
  const [messages, setMessages] = useState<ChatMessageType[]>(
    data.actionPlanItems.flatMap(api => api.chatMessages)
  )
  const [messagesBeingUploaded, setMessagesBeingUploaded] = useState<
    ChatMessageUploading[]
  >([])

  const countOfUnreadMessages = data.actionPlanItems
    .filter(api => api.actionPlanItemId == actionPlanItemId)
    .flatMap(api => api.chatMessages)
    .filter(msg => !msg.readByDealer).length

  const [messageData, setMessageData] = useState<CreateChatMessageData>({
    actionPlanItemId: 0,
    text: '',
    author: 'Dealer',
    fileId: undefined,
    isDealer: true
  })

  const [
    createDataState,
    uploadedFilesDataState,
    handleClickCreateData,
    setCreateDataState
  ] = useADHCreateChatMessageWithFile()

  const messagesRef = useRef<HTMLDivElement>(null)
  const lastMessageRef = useRef<HTMLTdsCardElement | null>(null)
  const [scrollToLastMessage, setScrollToLastMessage] = useState(false)

  const onUploadFileClick = (fileList: FileList) => {
    const files = Array.from(fileList)

    if (files.length === 0) return

    if (messageData.filetoUpload) {
      freeMemory(messageData.filetoUpload)
    }

    const inputFiles: DocumentFile[] = Array.from(files).map(file => ({
      file,
      id: uuidv4(),
      url: URL.createObjectURL(file)
    }))

    setMessageData({ ...messageData, filetoUpload: inputFiles[0] })
  }

  const onUnsetFileClick = () => {
    if (messageData.filetoUpload) {
      freeMemory(messageData.filetoUpload)
    }
    setMessageData({ ...messageData, filetoUpload: undefined })
  }

  const onCreateMessage = (messageText: string) => {
    if (createDataState.status !== 'uploading')
      handleClickCreateData(
        {
          ...messageData,
          text: messageText,
          actionPlanItemId: actionPlanItemId ?? 0
        },
        messageData.filetoUpload
      )

    const messages = messagesBeingUploaded ?? []
    messages.push({
      author: 'Dealer',
      text: messageText,
      createdTime: formatISODate(new Date()),
      filetoUpload: messageData.filetoUpload ?? undefined,
      actionPlanItemId: actionPlanItemId ?? 0,
      isDealer: true,
      isImage: messageData.filetoUpload
        ? fileIsImage(messageData.filetoUpload.file)
        : false
    })
    setMessagesBeingUploaded(messages)
    setScrollToLastMessage(true)

    setMessageData({
      ...messageData,
      text: '',
      filetoUpload: undefined
    })
  }

  useEffect(() => {
    if (scrollToLastMessage && messagesRef.current && lastMessageRef.current) {
      const timer = setTimeout(() => {
        setScrollToLastMessage(false)
        lastMessageRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        })
        lastMessageRef.current = null
      }, 10)
      return () => clearTimeout(timer)
    }
    return () => null
  }, [scrollToLastMessage])

  useEffect(() => {
    if (createDataState.status === 'success') {
      const newMessages = messages

      newMessages.push({
        ...messagesBeingUploaded[0],
        id: createDataState.chatMessageId,
        author: 'Dealer',
        fileId: messagesBeingUploaded[0].filetoUpload?.url
          ? Number.parseInt(
              uploadedFilesDataState.find(
                file =>
                  file.local_file_url ===
                  messagesBeingUploaded[0].filetoUpload?.url
              )?.id ?? '0'
            )
          : undefined,
        readByAuditor: true,
        readByDealer: false
      })

      setMessages(newMessages)
      setCreateDataState({
        status: 'none',
        message: null
      })

      const remainingMessages = messagesBeingUploaded.slice(1)
      setMessagesBeingUploaded(remainingMessages)

      if (remainingMessages.length > 0) {
        handleClickCreateData(
          { ...remainingMessages[0], actionPlanItemId: actionPlanItemId ?? 0 },
          remainingMessages[0].filetoUpload
        )
      }
    }
  }, [
    actionPlanItemId,
    createDataState,
    handleClickCreateData,
    messages,
    messagesBeingUploaded,
    messagesBeingUploaded.length,
    setCreateDataState,
    uploadedFilesDataState
  ])

  const filesToShow = files.concat(
    uploadedFilesDataState.map(file => {
      return {
        id: file.id,
        url: file.url,
        isImage: file.isImage,
        fileName: file.fileName,
        isUploaded: true,
        uploadProgress: 100
      }
    })
  )

  return (
    <StyledElasticContentContainer
      header={
        showHeader && (
          <ActionRow>
            <Row>
              <ActionRowTitle>{'Chat with Auditor'}</ActionRowTitle>
              {countOfUnreadMessages > 0 && (
                <StyledBadges value={countOfUnreadMessages.toString()} />
              )}
            </Row>
          </ActionRow>
        )
      }
      footer={
        <div style={{ display: 'flex', height: '20rem' }}>
          <Chatinput
            value={messageData.text}
            onCreateMessage={onCreateMessage}
            onUploadFileClick={onUploadFileClick}
            uploadedFileUrl={
              messageData.filetoUpload
                ? fileIsImage(messageData.filetoUpload.file)
                  ? messageData.filetoUpload.url
                  : TEGEL_DOCUMENT_ICON_URL
                : undefined
            }
            onUnsetFile={onUnsetFileClick}
          />
        </div>
      }
    >
      <MessagesWrapper ref={messagesRef}>
        {messages
          .filter(msg => msg.actionPlanItemId === actionPlanItemId)
          .map(msg => {
            const file = filesToShow.find(
              file => file.id === msg.fileId?.toString()
            )
            const imageUrl = file
              ? file?.isImage
                ? file.url
                : TEGEL_DOCUMENT_ICON_URL
              : undefined
            return (
              <ChatMessage
                onMessageClick={() => {
                  const fileUrl = filesToShow.find(
                    file => file.id === msg.fileId?.toString()
                  )?.url

                  if (fileUrl) window.open(fileUrl, '_blank')
                }}
                rightPosition={msg.author === 'Dealer'}
                key={msg.id}
                imagePlacement='above-header'
                message={msg.text}
                imageUrl={imageUrl}
                hasThumbnail={!file?.isImage}
              >
                <>
                  {msg.author && <span>{msg.author}, </span>}
                  <span>{formatISODate(new Date(msg.createdTime))}</span>
                </>
              </ChatMessage>
            )
          })}
        {messagesBeingUploaded
          .filter(msg => msg.actionPlanItemId === actionPlanItemId)
          .map((msg, i) => {
            const imageUrl = msg.filetoUpload?.url
              ? msg.isImage
                ? msg.filetoUpload.url
                : TEGEL_DOCUMENT_ICON_URL
              : undefined
            return (
              <StyledUploadingMessage key={msg.createdTime}>
                <ChatMessage
                  ref={
                    scrollToLastMessage &&
                    i === messagesBeingUploaded.length - 1
                      ? lastMessageRef
                      : undefined
                  }
                  id={i.toString()}
                  rightPosition
                  message={msg.text}
                  imagePlacement='below-header'
                  imageUrl={imageUrl}
                  onMessageClick={() =>
                    msg.filetoUpload?.url &&
                    window.open(msg.filetoUpload.url, '_blank')
                  }
                  hasThumbnail={!msg.isImage}
                >
                  <>
                    {msg.author && <span>{msg.author}, </span>}
                    <span>{formatISODate(new Date(msg.createdTime))}</span>
                  </>
                </ChatMessage>
              </StyledUploadingMessage>
            )
          })}
      </MessagesWrapper>
    </StyledElasticContentContainer>
  )
}
