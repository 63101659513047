import React, { createContext, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'

type Mobile = {
  title: string
  description: string | null
}

type Desktop = {
  title: string
}

type ContentHeader = {
  title: string
  subtitle: string
}

type UpdateTitlesParams = {
  mobile?: Mobile
  desktop?: Desktop
  contentHeader?: ContentHeader
}

type TitleContextType = {
  mobile: Mobile
  desktop: Desktop
  contentHeader?: ContentHeader
  updateTitles: (data: UpdateTitlesParams) => void
}

const defaultContext: TitleContextType = {
  contentHeader: undefined,
  mobile: {
    title: 'QPR',
    description: null
  },
  desktop: {
    title: 'Dealer Quality Profile Reports'
  },
  updateTitles: () => undefined
}

export const TitleContext = createContext<TitleContextType>(defaultContext)

export function TitleProvider({
  children
}: Readonly<{ children: React.ReactNode }>) {
  const [contentHeader, setContentHeader] = useState(
    defaultContext.contentHeader
  )
  const [mobileTitle, setMobileTitle] = useState(defaultContext.mobile.title)
  const [mobileDescription, setMobileDescription] = useState(
    defaultContext.mobile.description
  )
  const [desktopTitle, setDesktopTitle] = useState(defaultContext.desktop.title)

  const contextValue = useMemo(
    () => ({
      contentHeader: contentHeader,
      desktop: {
        title: desktopTitle
      },
      mobile: {
        title: mobileTitle,
        description: mobileDescription
      },
      updateTitles
    }),
    [contentHeader, desktopTitle, mobileDescription, mobileTitle]
  )

  function updateTitles({
    desktop,
    mobile,
    contentHeader
  }: UpdateTitlesParams) {
    setDesktopTitle(desktop?.title ?? defaultContext.desktop.title)
    setMobileTitle(mobile?.title ?? defaultContext.mobile.title)
    setContentHeader(contentHeader)
    setMobileDescription(
      mobile?.description ?? defaultContext.mobile.description
    )
  }

  return (
    <TitleContext.Provider value={contextValue}>
      {children}
    </TitleContext.Provider>
  )
}

export function WithTitle() {
  return (
    <TitleProvider>
      <Outlet />
    </TitleProvider>
  )
}
