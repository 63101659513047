import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'

export type CreateTranslationResponse =
  | {
      status: 'success'
      id: number
    }
  | {
      status: 'fail'
      id: null
    }

export function useCreateTranslation() {
  const client = useBackend()

  const createTranslation = async (
    languageId: number,
    transaltionGuid: string,
    translationText: string
  ): Promise<CreateTranslationResponse> => {
    try {
      const newTranslationId =
        await client.TranslationsService.CreateTranslation(
          languageId,
          transaltionGuid,
          translationText
        )

      return { status: 'success', id: newTranslationId }
    } catch (e) {
      return { status: 'fail', id: null }
    }
  }

  return {
    createTranslation
  }
}
