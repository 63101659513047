import { ClickableListItem } from '@cdab/scania/qpr/components/atoms'
import type { SortOption } from '@cdab/scania/qpr/components/molecules'
import { ExpiringCertificatesList } from '@cdab/scania/qpr/components/molecules'
import type { Dealer } from '@cdab/scania/qpr/schema'
import { DelayedSpinner } from '@cdab/scania/sdds'
import { t } from 'i18next'
import { useCallback, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { NavigateOptions, To } from 'react-router'
import { useNavigate } from 'react-router'
import invariant from 'tiny-invariant'
import {
  byAuditDate,
  byDealerName,
  byExpiryDateAndName,
  byHasActiveDeviations,
  byLocation,
  getExpiryDescription
} from './expiring-certificates-widget.internals'
import { FullHeightBlock } from './expiring-certificates.styles'

function useNavigateLoader() {
  const routerNavigate = useNavigate()
  const [isNavigating, setIsNavigating] = useState(false)

  const navigate = useCallback(
    (to: To, options?: NavigateOptions) => {
      if (isNavigating) return

      setIsNavigating(true)
      routerNavigate(to, options)
    },
    [routerNavigate, isNavigating]
  )

  return {
    navigate,
    isNavigating
  }
}

export type ExpiringCertificatesWidgetProps = {
  dealers: Dealer[]
}

const SORT_OPTIONS: SortOption[] = [
  {
    displayName: t('expiry-date-and-name'),
    id: '1',
    sortFunction: byExpiryDateAndName
  },
  {
    displayName: t('dealer-name'),
    id: '2',
    sortFunction: byDealerName
  },
  {
    displayName: t('location'),
    id: '3',
    sortFunction: byLocation
  },
  {
    displayName: t('audit-date'),
    id: '4',
    sortFunction: byAuditDate
  },
  {
    displayName: t('has-deviations'),
    id: '5',
    sortFunction: byHasActiveDeviations
  }
]

const DEFAULT_SORT_OPTION_ID = '1'

export function ExpiringCertificatesWidget(
  props: ExpiringCertificatesWidgetProps
) {
  const { isNavigating, navigate } = useNavigateLoader()
  const { t } = useTranslation('common')
  const [dealers, setFunction] = useReducer(
    (previousDealers: Dealer[], name: SortOption): Dealer[] => {
      return previousDealers.slice().sort(name.sortFunction)
    },
    props.dealers.slice(),
    initialDealers => {
      const defaultSortOption = SORT_OPTIONS.find(
        ({ id }) => id === DEFAULT_SORT_OPTION_ID
      )
      invariant(
        defaultSortOption,
        `Could not find default sort option with ID ${DEFAULT_SORT_OPTION_ID}`
      )
      return initialDealers.sort(defaultSortOption?.sortFunction)
    }
  )

  const onDealerClick = useCallback(
    (dealerId: Dealer['id']) => () => {
      navigate(`/dealer/${dealerId}`)
    },
    [navigate]
  )

  const onSelectSorting = useCallback((name: SortOption) => {
    setFunction(name)
  }, [])

  if (isNavigating) {
    return <DelayedSpinner />
  }

  return (
    <FullHeightBlock color='on-grey'>
      <ExpiringCertificatesList
        onSelectSorting={onSelectSorting}
        emptyMessage={t('no-expiring-dealers')}
        sortOptions={SORT_OPTIONS}
        defaultSortOptionID={DEFAULT_SORT_OPTION_ID}
      >
        {dealers.map(dealer => (
          <ClickableListItem
            key={dealer.id}
            title={`${dealer.name}, ${dealer.city}`}
            description={getExpiryDescription(dealer, t)}
            onClick={onDealerClick(dealer.id)}
          />
        ))}
      </ExpiringCertificatesList>
    </FullHeightBlock>
  )
}
