import { isNumber } from '@cdab/utils'
import { observer } from 'mobx-react-lite'
import { useParams, useRouteLoaderData } from 'react-router-dom'
import invariant from 'tiny-invariant'

import { CheckpointList, ScrollableWrapper } from '@cdab/scania/qpr/components'

import type { AuditLoaderData } from '../../../../auditId'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Row,
  Column,
  EmptyScreen,
  IconMessageInactive
} from '@cdab/scania/sdds'
import styled from 'styled-components'

export const Content = styled.div`
  height: 100%;
`

type Params = {
  auditId: string
  auditPointId: string
}

export const CheckPointsTab = observer(() => {
  const params = useParams<Params>()
  const { t } = useTranslation(['audit', 'common'])

  const auditPointId = Number.parseInt(params.auditPointId || 'NaN')
  invariant(isNumber(auditPointId), 'auditPointId is required')

  const { audit } = useRouteLoaderData('audit') as AuditLoaderData

  const checkPointsForAuditPoints = audit.checkPoints.filter(
    checkpoint => checkpoint.auditPointId === Number(auditPointId)
  )

  return (
    <Content>
      <Container paddingOnContainer={false} fullHeight>
        <Row fullHeight>
          <Column width={12} padding={false}>
            {checkPointsForAuditPoints.length > 0 ? (
              <ScrollableWrapper>
                <CheckpointList
                  audit={audit}
                  checkPoints={checkPointsForAuditPoints}
                />
              </ScrollableWrapper>
            ) : (
              <EmptyScreen.CenteredWrapper>
                <EmptyScreen
                  icon={<IconMessageInactive />}
                  title={t('no-checkpoints', { ns: 'common' })}
                />
              </EmptyScreen.CenteredWrapper>
            )}
          </Column>
        </Row>
      </Container>
    </Content>
  )
})
