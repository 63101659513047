import type { JSONSchemaType } from 'ajv'
import Ajv from 'ajv'
import { isValidThing } from '../ajv'
import type { UserMarket } from '../user-market'
import { UserMarketSchema } from '../user-market'

export type AuditReminderInterval = {
  name: string
  id: string
}

export const AuditReminderIntervals: AuditReminderInterval[] = [
  { id: 'null', name: 'None' },
  { id: '1', name: '1 month' },
  { id: '2', name: '2 months' },
  { id: '3', name: '3 months' },
  { id: '6', name: '6 months' },
  { id: '12', name: '12 months' }
]

// It would probably be a better idea to use bit flags to control rights
// e.g. Read = 2^1, Edit = 2^2, Certify = 2^3, Remote = 2^4,
// and use bitwise OR to check them.
export enum EditRights {
  ReadOnly = 0,
  EditOnly = 1,
  EditAndCertify = 2,
  EditCertifyAndRemote = 3
}

export enum Roles {
  SystemAccount = 5,
  Auditor = 10,
  CoOrdinator = 50,
  FactoryEngineAdmin = 75,
  FactoryAdmin = 100
}

export interface User {
  userId: number
  firstName: string
  lastName: string
  userName: string
  languageId: number
  languageVersions: Record<string, string>
  role: Roles
  viewId: number
  cellPhone: string
  email: string
  country: string
  auditReminderInterval: number | null
  truck: boolean
  bus: boolean
  engine: boolean
  alertOnCertify: boolean
  active: boolean
  defaultMarketId: number
  // If we want to allow edit rights per market, we could use a map here,
  // i.e. Map<MarketID, EditRights>
  editRights: EditRights
  markets: UserMarket[]
}

export const UserSchema: JSONSchemaType<User> = {
  type: 'object',
  properties: {
    userId: { type: 'number' },
    firstName: { type: 'string' },
    lastName: { type: 'string' },
    userName: { type: 'string' },
    languageId: { type: 'number' },
    languageVersions: {
      type: 'object',
      patternProperties: {
        '.*': { type: 'string' }
      },
      required: []
    },
    role: {
      type: 'number'
    },
    viewId: {
      type: 'number',
      nullable: true
    },
    cellPhone: {
      type: 'string',
      nullable: true
    },
    email: {
      type: 'string'
    },
    country: {
      type: 'string',
      nullable: true
    },
    auditReminderInterval: {
      type: 'number',
      nullable: true
    },
    truck: {
      type: 'boolean'
    },
    bus: {
      type: 'boolean'
    },
    engine: {
      type: 'boolean'
    },
    alertOnCertify: {
      type: 'boolean',
      nullable: true
    },
    active: {
      type: 'boolean',
      nullable: true
    },
    defaultMarketId: {
      type: 'number',
      nullable: true
    },
    editRights: {
      type: 'number',
      minimum: EditRights.ReadOnly, // = 0
      maximum: EditRights.EditCertifyAndRemote // = 3
    },
    markets: {
      type: 'array',
      items: UserMarketSchema
    }
  },
  required: [
    'userId',
    'firstName',
    'lastName',
    'userName',
    'languageId',
    'languageVersions',
    'role',
    'viewId',
    'cellPhone',
    'country',
    'auditReminderInterval',
    'truck',
    'bus',
    'engine',
    'alertOnCertify',
    'active',
    'defaultMarketId',
    'editRights',
    'markets'
  ]
}

const ajv = new Ajv()
const validateUser = ajv.compile(UserSchema)

export const isUser = (obj: unknown): obj is User =>
  isValidThing<User>(obj, validateUser)
