import { useState, useEffect } from 'react'

export function useDoOnce(toDo: () => void) {
  const [hasDone, setHasDone] = useState(false)

  useEffect(() => {
    if (!hasDone) {
      toDo()
      setHasDone(true)
    }
  }, [hasDone, toDo])
}
