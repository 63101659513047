import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import { getEnvVarsQPR } from '@cdab/scania/qpr/env-vars'
import type { UserInfo } from '@cdab/scania/qpr/interactor'
import type { AuditModel } from '@cdab/scania/qpr/offline/models'
import { DOS_VERSION_4, DOS_VERSION_5 } from '@cdab/scania/qpr/schema'
import { Button } from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'

function getAuditReportPrintFileName(dosVersion: number): string | undefined {
  switch (dosVersion) {
    case DOS_VERSION_5:
      return 'QPRPrint_DOS5.aspx'

    case DOS_VERSION_4:
      return 'QPRPrint_DOS4.aspx'
    default:
      return undefined
  }
}

export function PrintAuditReportButton(props: { audit: AuditModel }) {
  const { t } = useTranslation()
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>()
  const client = useBackend()

  useEffect(() => {
    client.GetuserInfo().then(setUserInfo)
  }, [client])

  const qprPrintFileName = getAuditReportPrintFileName(props.audit.dosVersion)

  if (!qprPrintFileName || !userInfo) return null

  const { qpr2007url } = getEnvVarsQPR()

  return (
    <a
      href={`${qpr2007url}/${qprPrintFileName}?auditID=${props.audit.id}&userID=${userInfo.userId}&pdfPrintout=true&closeTab=true`}
      target='_blank'
      rel='noreferrer'
    >
      <Button fullBleed text={capitalizeFirstLetter(t('audit'))} />
    </a>
  )
}
