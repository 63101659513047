import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useRouteLoaderData
} from 'react-router-dom'
import invariant from 'tiny-invariant'

import {
  DeviationList,
  DeviationTable,
  EmptyState,
  useDeviationModal
} from '@cdab/scania/qpr/components'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import type { Deviation } from '@cdab/scania/qpr/schema'
import type { TOption } from '@cdab/scania/sdds'
import {
  Button,
  Column,
  Container,
  Dropdown,
  DropdownOption,
  EmptyScreen,
  IconMessageInactive,
  Row
} from '@cdab/scania/sdds'
import { useDoOnce } from '@cdab/utils'
import styled from 'styled-components'
import type { AuditLoaderData } from '../../../../../auditId'

const Content = styled.div`
  height: 100%;
  padding-bottom: var(--sdds-spacing-element-40);
`

type Params = {
  auditId: string
  auditPointId: string
}

enum View {
  Table = 'table',
  Cards = 'cards'
}

export const DeviationsTab = observer((): JSX.Element => {
  const { t } = useTranslation()
  const { pathname, state: stateLocation } = useLocation()
  const navigate = useNavigate()

  const params = useParams<Params>()
  const isMd = useCssVariableBreakpoint('--sdds-grid-md')
  const [view, setView] = useState<View>(View.Table)
  const { auditPointId: sAuditPointId } = params
  const auditId = Number(params.auditId)

  const { audit } = useRouteLoaderData('audit') as AuditLoaderData
  const { deviations, auditPoints, isReadonly } = audit

  const auditPointId = Number.parseInt(sAuditPointId ?? 'NaN')
  invariant(auditId, 'auditId is required')
  invariant(!Number.isNaN(auditPointId), 'auditPointId is required')

  const deviationsForCurrentAuditPoint = deviations.filter(
    deviation => deviation.auditPointId === auditPointId
  )

  const auditPoint = auditPoints.find(ap => ap.id === auditPointId)
  invariant(auditPoint, `Could not find audit point with id ${auditPointId}`)
  const { show: showDeviationModal } = useDeviationModal()

  const onSelectView = useCallback(
    (option: TOption) => {
      setView(option.value === View.Cards.toString() ? View.Cards : View.Table)
    },
    [setView]
  )

  const handleClickDeviation = useCallback(
    (guid: Deviation['clientGuid']) => {
      const deviation = deviations.find(({ clientGuid }) => clientGuid === guid)
      invariant(deviation)

      navigate(
        `/audit/${auditId}/points/auditpoint/${auditPointId}/deviations/${deviation.clientGuid}`
      )
    },
    [auditId, auditPointId, deviations, navigate]
  )

  const handleClickDeviationModal = useCallback(
    (guid: Deviation['clientGuid']) => {
      const deviation = deviations.find(({ clientGuid }) => clientGuid === guid)
      invariant(deviation)

      showDeviationModal({
        auditId,
        deviation
      })
    },
    [auditId, deviations, showDeviationModal]
  )

  const handleClickCreateDeviation = useCallback(() => {
    navigate(
      `/audit/${auditId}/points/auditpoint/${auditPointId}/deviations/new`
    )
  }, [auditId, auditPointId, navigate])

  useDoOnce(() => {
    if (stateLocation && 'deviationId' in stateLocation) {
      const deviationGuid = deviations.find(
        ({ id }) => id?.toString() === stateLocation.deviationId
      )?.clientGuid

      if (deviationGuid)
        navigate(
          `/audit/${auditId}/points/auditpoint/${auditPointId}/deviations/${deviationGuid}`,
          {
            state:
              'previousPath' in stateLocation
                ? { previousPath: stateLocation.previousPath }
                : undefined
          }
        )
    }
  })

  if (
    pathname ===
    `/audit/${auditId}/points/auditpoint/${auditPointId}/deviations/new`
  ) {
    return <Outlet />
  }

  if (deviationsForCurrentAuditPoint.length === 0) {
    return (
      <Content>
        <EmptyScreen.CenteredWrapper>
          <EmptyScreen
            icon={<IconMessageInactive />}
            title={t('no-deviations-found')}
            description={t('no-deviations-found-description')}
            callToAction={
              !isReadonly
                ? {
                    type: 'button',
                    component: (
                      <Button
                        className='sdds-u-mb2 sdds-u-mt2'
                        size='sm'
                        onClick={() => {
                          !isReadonly && handleClickCreateDeviation()
                        }}
                        disabled={isReadonly}
                        text={t('create-deviation', { ns: 'audit' })}
                      />
                    )
                  }
                : undefined
            }
          />
        </EmptyScreen.CenteredWrapper>
      </Content>
    )
  }

  if (
    pathname ===
    `/audit/${auditId}/points/auditpoint/${auditPointId}/deviations`
  ) {
    return (
      <Content>
        <Container>
          <Row className='sdds-u-pt3 sdds-u-pb2'>
            {!audit.isReadonly && (
              <Column width={12} md={6}>
                <Button
                  size='sm'
                  onClick={() => !isReadonly && handleClickCreateDeviation()}
                  disabled={isReadonly}
                  text={t('create-deviation', { ns: 'audit' })}
                />
              </Column>
            )}

            <Column
              width={12}
              md={6}
              className={cx({ 'sdds-u-mt2': !isMd })}
              style={{ overflow: 'inherit' }}
            >
              {deviationsForCurrentAuditPoint.length > 0 && (
                <Dropdown
                  size='sm'
                  onSelect={onSelectView}
                  filter={[
                    { label: t('cards'), value: View.Cards },
                    { label: t('table'), value: View.Table }
                  ]}
                  defaultOption={view}
                >
                  <DropdownOption
                    key={View.Cards}
                    value={View.Cards}
                    text={t('cards')}
                  />
                  <DropdownOption
                    key={View.Table}
                    value={View.Table}
                    text={t('table')}
                  />
                </Dropdown>
              )}
            </Column>
          </Row>
          <Row>
            <Column width={12}>
              {deviationsForCurrentAuditPoint.length > 0 ? (
                view === 'table' ? (
                  <div style={{ overflow: 'scroll' }}>
                    <DeviationTable
                      deviations={deviationsForCurrentAuditPoint}
                      onClickDeviation={handleClickDeviation}
                    />
                  </div>
                ) : (
                  <DeviationList
                    deviations={deviationsForCurrentAuditPoint}
                    onClickDeviation={handleClickDeviation}
                  />
                )
              ) : (
                // TODO: https://linear.app/congenialdata/issue/QPR-485/empty-screen-templates
                <EmptyState title={t('no-deviations')} />
              )}
            </Column>
          </Row>
        </Container>
      </Content>
    )
  } else {
    return <Outlet />
  }
})
