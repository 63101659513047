import { useEffect, useState } from 'react'

export function useRerenderOnce(): void {
  const [hasRefreshed, setHasRefreshed] = useState(false)

  useEffect(() => {
    if (!hasRefreshed) {
      setHasRefreshed(true)
    }
  }, [hasRefreshed])
}
