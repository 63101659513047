import { Card, CardStyles } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const StyledDeviationCard = styled(Card)`
  ${CardStyles.Image} {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    object-position: center;
  }
`
