import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export type Pledge = {
  id: number
  note: string | null
  translationGuid: string
  pledgeNo: string | null // TODO: Is this required?
  pledgeAreaId: number | null
  sortOrder: number
}

export const PledgeSchema: JSONSchemaType<Pledge> = {
  type: 'object',
  properties: {
    id: {
      type: 'number'
    },
    note: {
      type: 'string',
      nullable: true
    },
    translationGuid: {
      type: 'string'
    },
    pledgeNo: {
      type: 'string',
      nullable: true
    },
    pledgeAreaId: {
      type: 'number',
      nullable: true
    },
    sortOrder: {
      type: 'number'
    }
  },
  required: [
    'translationGuid',
    'id',
    'note',
    'pledgeAreaId',
    'pledgeNo',
    'sortOrder'
  ]
}

const validatePledge: ValidateFunction<Pledge> = ajv.compile(PledgeSchema)

export const isValidPledge = (pledge: unknown): pledge is Pledge =>
  isValidThing<Pledge>(pledge, validatePledge)
