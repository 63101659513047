import { formatISODate } from '@cdab/utils'
import { useTranslation } from 'react-i18next'
import {
  ExpiryItem,
  ExpiryValue,
  Headline,
  Item,
  ItemList,
  StyledBlock,
  StyledIconExpiryDate,
  Value
} from './dealer-status-card.styles'
import type { DealerStatusCardProps } from './dealer-status-card.types'

export function DealerStatusCard({
  dealer,
  canEditExpiryDate,
  onEditDealerClick
}: Readonly<DealerStatusCardProps>) {
  const { t } = useTranslation(['common', 'dealer'])
  const expired = !!(dealer.expiryDate && dealer.expiryDate < new Date())

  return (
    <StyledBlock color='on-grey' expired={expired}>
      <ItemList>
        <Item>
          <Headline>{t('last-audit-date', { ns: 'dealer' })}</Headline>
          <Value>
            {dealer.auditDate ? formatISODate(dealer.auditDate) : '-'}
          </Value>
        </Item>
        <ExpiryItem expired={expired}>
          <Headline>{t('expiry-date', { ns: 'common' })}</Headline>
          <ExpiryValue expired={expired} as={expired ? 'strong' : 'div'}>
            {canEditExpiryDate && (
              <StyledIconExpiryDate onClick={onEditDealerClick} />
            )}
            {dealer.expiryDate ? formatISODate(dealer.expiryDate) : '-'}
          </ExpiryValue>
        </ExpiryItem>
        <Item>
          <Headline>{t('active-actionplan', { ns: 'dealer' })}</Headline>
          <Value>
            {dealer.hasDeviations
              ? t('yes', { ns: 'common' })
              : t('no', { ns: 'common' })}
          </Value>
        </Item>
      </ItemList>
    </StyledBlock>
  )
}
