import type { Audit, AuditPoint } from '@cdab/scania/qpr/schema'

export function useReferenceDocuments(
  audit: Audit,
  auditPointId: AuditPoint['id']
) {
  const referenceDocuments = audit.referenceDocuments.filter(
    document => document.auditPointId === auditPointId
  )

  return referenceDocuments
}
