import { v4 as uuidv4 } from 'uuid'

import {
  AddAuditPointFileOperation,
  addAuditPointFiles,
  DeleteAuditPointFileOperation,
  getAudit,
  getAuditPoint,
  SetAuditPointNoteOperation,
  SetAuditPointScoreOperation
} from '@cdab/scania/qpr/offline/models'
import type { Audit, AuditPoint, FileData } from '@cdab/scania/qpr/schema'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'

import { operationsController } from './operations-controller'
import { auditsController } from './audits-controller'
import { runInAction } from 'mobx'

class AuditPointsController {
  public SetScore = (
    auditId: Audit['id'],
    auditPointId: AuditPoint['id'],
    score: boolean | null
  ) => {
    const auditPoint = getAuditPoint(auditId, auditPointId)

    const operation = new SetAuditPointScoreOperation(
      auditId,
      {
        auditPointId,
        score
      },
      {
        auditPointId,
        score: auditPoint.score
      }
    )

    operationsController.handleNewOperation(operation)
  }

  public SetNote = (
    auditId: Audit['id'],
    auditPointId: AuditPoint['id'],
    note: string
  ) => {
    const auditPoint = getAuditPoint(auditId, auditPointId)

    const operation = new SetAuditPointNoteOperation(
      auditId,
      {
        auditPointId,
        note
      },
      {
        auditPointId,
        note: auditPoint.note || ''
      }
    )

    operationsController.handleNewOperation(operation)
  }

  public GetAuditPoint = async (
    auditId: Audit['id'],
    auditPointId: AuditPoint['id']
  ) => {
    if (!getAudit(auditId)) {
      await auditsController.GetAudit(auditId)
    }

    const auditPoint = getAuditPoint(auditId, auditPointId)

    return auditPoint
  }

  public AddFile = async (
    auditId: Audit['id'],
    auditPointId: AuditPoint['id'],
    file: File
  ) => {
    const operation = new AddAuditPointFileOperation(auditId, {
      auditPointId,
      file,
      fileGuid: uuidv4()
    })

    operationsController.handleNewOperation(operation)
  }

  public GetFiles = async (
    auditId: Audit['id'],
    auditPointId: AuditPoint['id']
  ) => {
    const client = getClient()
    const auditPoint = await this.GetAuditPoint(auditId, auditPointId)

    try {
      // Not super happy about this solution, but imho it's better than creating an
      // operation for getting stuff. Then it will grow out of control...
      runInAction(() => {
        auditPoint.isGettingFiles = true
      })
      const files = await client.StorageService.GetFilesForAuditPoint(
        auditId,
        auditPointId
      )

      addAuditPointFiles(auditId, auditPointId, files)
    } catch (error) {
      console.warn('There was an error getting files', error)
    } finally {
      runInAction(() => {
        auditPoint.isGettingFiles = false
      })
    }
  }

  public DeleteFile = async (
    auditId: Audit['id'],
    auditPointId: AuditPoint['id'],
    fileId: FileData['id']
  ) => {
    const operation = new DeleteAuditPointFileOperation(auditId, {
      auditPointId,
      fileId
    })

    operationsController.handleNewOperation(operation)
  }
}

export const auditPointsController = new AuditPointsController()
