import { createContext } from 'react'

import type { User } from '@cdab/scania/qpr/schema'

export type UserContext = {
  userData: User
  setData: (data: User) => void
}

export const userContext = createContext<UserContext>(
  null as unknown as UserContext
)
