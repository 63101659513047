import {
  CenteredContainer,
  Editor,
  ElasticContentContainer,
  ScrollableWrapper
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import {
  useCreateSystemMessage,
  useCssVariableBreakpoint
} from '@cdab/scania/qpr/hooks'
import type { User } from '@cdab/scania/qpr/schema'
import { Roles } from '@cdab/scania/qpr/schema'
import {
  Breadcrumbs,
  Button,
  Column,
  Container,
  EmptyScreen,
  IconWarning,
  Message,
  Row,
  Textarea,
  Textfield,
  Toast
} from '@cdab/scania/sdds'
import { useCallback, useEffect, useState } from 'react'
import {
  isRouteErrorResponse,
  json,
  useLoaderData,
  useNavigate,
  useRouteError
} from 'react-router'

import { useFeatures } from '@cdab/scania/qpr/feature-flags'
import type { CreateSystemMessageData } from '@cdab/scania/qpr/interactor'
import { capitalizeFirstLetter, formatISODate } from '@cdab/utils'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  StyledButtonsRow,
  StyledFirstButton,
  StyledRow,
  Title
} from './system-messages.styles'

type TextfieldState = 'error' | 'ok'

export type NewAdminSystemMessageLoaderData = {
  user: User
}

export async function loader(): Promise<NewAdminSystemMessageLoaderData> {
  try {
    const client = getClient()

    const userInfo = await client.GetuserInfo()
    if (!userInfo) {
      throw json({
        status: 401
      })
    }

    const [user] = await Promise.all([
      client.UserService.GetUser(userInfo.userId)
    ])

    if (user.role !== Roles.FactoryAdmin) {
      throw json({
        status: 403
      })
    }

    return { user }
  } catch (error) {
    if (error instanceof Response) {
      throw new Response(null, {
        status: error.status
      })
    } else {
      throw new Response(null, {
        status: 404
      })
    }
  }
}

export function Page() {
  const navigate = useNavigate()
  const { t } = useTranslation(['systemMessages', 'common'])
  const { updateTitles } = useTitle()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { use_new_editor } = useFeatures(['use_new_editor'])
  const { user } = useLoaderData() as NewAdminSystemMessageLoaderData

  const [data, setData] = useState<CreateSystemMessageData>({
    author: `${user.firstName} ${user.lastName}`,
    text: '',
    title: formatISODate(new Date())
  })

  const [submitState, handleClickCreateSystemMessage, setSubmitState] =
    useCreateSystemMessage(data)

  const [stateTitle, setStateTitle] = useState<TextfieldState>('ok')
  const [stateText, setStateText] = useState<TextfieldState>('ok')
  const [stateAuthor, setStateAuthor] = useState<TextfieldState>('ok')

  const saveButtonEnabled = useCallback(() => {
    return stateTitle === 'error' ||
      stateText === 'error' ||
      stateAuthor === 'error'
      ? true
      : false
  }, [stateAuthor, stateText, stateTitle])

  const onSaveClick = () => {
    if (stateTitle === 'ok' && stateText === 'ok' && stateAuthor === 'ok') {
      handleClickCreateSystemMessage()
    }
  }

  useEffect(() => {
    updateTitles({
      contentHeader: {
        title: t('administration', { ns: 'common' }),
        subtitle: t('system-messages', { ns: 'common' })
      },

      mobile: {
        title: `${t('system-messages', { ns: 'common' })}`,
        description: null
      }
    })
  }, [t])

  useEffect(() => {
    if (submitState.status === 'success') {
      toast(
        <Toast
          type='success'
          headline={capitalizeFirstLetter(
            t('system-message-created', { ns: 'common' })
          )}
          subheadline={submitState.message}
        />
      )
      setSubmitState({ status: 'none', message: null })
      navigate('/admin/system-messages/all')
    }
  }, [navigate, submitState.status, submitState.message, setSubmitState, t])

  return (
    <ElasticContentContainer overflowHidden>
      <ScrollableWrapper>
        <Container
          fullHeight
          fluid='normal'
          paddingOnColumns={isLg}
          paddingOnContainer={isLg}
        >
          {isLg && (
            <Row>
              <Column width={12} className='sdds-u-pt2'>
                <Breadcrumbs
                  links={[
                    {
                      text: t('system-messages', { ns: 'common' }),
                      to: `/admin/system-messages/all`
                    },
                    { text: t('new', { ns: 'common' }), to: '' }
                  ]}
                />
              </Column>
            </Row>
          )}
          <Row>
            <Column width={12} lg={6} offset={{ lg: 3 }}>
              <StyledRow>
                <Title>{t('create-title')}</Title>
              </StyledRow>
              <StyledRow>
                <Textfield
                  label={t('title-label')}
                  labelPosition='outside'
                  maxLength={100}
                  name='title'
                  placeholder={t('title-label')}
                  value={data.title ?? ''}
                  disabled={false}
                  onChange={e => {
                    setData({ ...data, title: e.target.value })
                  }}
                  onBlur={e => {
                    e.target.value?.length > 0 && e.target.value?.length < 5
                      ? setStateTitle('error')
                      : setStateTitle('ok')
                  }}
                  state={stateTitle === 'error' ? 'error' : undefined}
                  helper={
                    stateTitle === 'error' &&
                    t('message.validation-error', {
                      ns: 'systemMessages',
                      chars: '4'
                    })
                  }
                />
              </StyledRow>
              <StyledRow>
                {use_new_editor.enabled ? (
                  <Editor
                    autofocus
                    maxLength={500}
                    label='Content'
                    onChange={text => {
                      setData({ ...data, text })
                    }}
                    onBlur={length => {
                      setStateText(length > 4 ? 'ok' : 'error')
                    }}
                    placeholder={t('text-label')}
                    hasError={stateText === 'error'}
                    helper={
                      stateText === 'error' &&
                      t('message.validation-error', {
                        ns: 'systemMessages',
                        chars: '4'
                      })
                    }
                  />
                ) : (
                  <Textarea
                    label={t('text-label')}
                    labelPosition='outside'
                    maxLength={500}
                    name='text'
                    placeholder={t('text-label')}
                    value={data.text ?? ''}
                    disabled={false}
                    autoFocus={true}
                    rows={5}
                    onChange={e => {
                      setData({ ...data, text: e.target.value })
                    }}
                    onBlur={e => {
                      e.target.value?.length > 0 && e.target.value?.length < 5
                        ? setStateText('error')
                        : setStateText('ok')
                    }}
                    state={stateText === 'error' ? 'error' : undefined}
                    helper={
                      stateText === 'error' &&
                      t('message.validation-error', {
                        ns: 'systemMessages',
                        chars: '4'
                      })
                    }
                  />
                )}
              </StyledRow>
              <StyledRow>
                <Textfield
                  label={t('author-label')}
                  labelPosition='outside'
                  maxLength={100}
                  name='author'
                  placeholder={t('author-label')}
                  value={data.author ?? ''}
                  disabled={false}
                  onChange={e => {
                    setData({ ...data, author: e.target.value })
                  }}
                  onBlur={e => {
                    e.target.value?.length > 0 && e.target.value?.length < 5
                      ? setStateAuthor('error')
                      : setStateAuthor('ok')
                  }}
                  state={stateAuthor === 'error' ? 'error' : undefined}
                  helper={
                    stateAuthor === 'error' &&
                    t('message.validation-error', {
                      ns: 'systemMessages',
                      chars: '4'
                    })
                  }
                />
              </StyledRow>
              <StyledRow>
                {submitState.status === 'error' && (
                  <Message
                    className='sdds-u-mt1'
                    variant='single-line'
                    singleLineMessage={submitState.message}
                    type={submitState.status}
                  />
                )}
              </StyledRow>
              <StyledButtonsRow>
                <StyledFirstButton
                  text={t('cancel', {
                    ns: 'common'
                  })}
                  type='secondary'
                  size='md'
                  onClick={() => navigate('/admin/system-messages/all')}
                />
                <Button
                  text={capitalizeFirstLetter(
                    t('add', {
                      ns: 'common'
                    })
                  )}
                  disabled={!saveButtonEnabled}
                  type='primary'
                  size='md'
                  onClick={() => onSaveClick()}
                />
              </StyledButtonsRow>
            </Column>
          </Row>
        </Container>
      </ScrollableWrapper>
    </ElasticContentContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    switch (error.status) {
      case 401:
        message = t('not-logged-in')
        break
      case 403:
        message = t('access-denied')
        break
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
