import {
  AUDITS_OBJECT_STORE_NAME,
  OPERATIONS_INDEX_AUDIT_ID,
  OPERATIONS_INDEX_DEVIATION_ID,
  OPERATIONS_INDEX_GUID,
  OPERATIONS_OBJECT_STORE_NAME
} from '../constants'

export function upgradeToV1(db: IDBDatabase) {
  db.createObjectStore(AUDITS_OBJECT_STORE_NAME, {
    keyPath: 'id'
  })

  const operationsStore = db.createObjectStore(OPERATIONS_OBJECT_STORE_NAME, {
    keyPath: null
  })
  operationsStore.createIndex(OPERATIONS_INDEX_AUDIT_ID, 'auditId')
  operationsStore.createIndex(OPERATIONS_INDEX_GUID, 'guid', {
    unique: true
  })
  operationsStore.createIndex(OPERATIONS_INDEX_DEVIATION_ID, 'deviationId') // For deviation updates
}
