import { v4 as uuidv4 } from 'uuid'
import invariant from 'tiny-invariant'
import { action } from 'mobx'

import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { Audit } from '@cdab/scania/qpr/schema'
import type { AuditModel } from '../audit-model'

type Type = 'set-audit-point-note'

type Data = {
  note: string
  auditPointId: number
}

export type SetAuditPointNoteOperationData = OperationDataBase<Type, Data>

export class SetAuditPointNoteOperation extends Operation {
  public data: SetAuditPointNoteOperationData

  constructor(
    auditId: Audit['id'],
    value: Data,
    previousValue: Data,
    guid = uuidv4()
  ) {
    super(auditId, guid)

    this.data = {
      type: 'set-audit-point-note',
      value,
      previousValue
    }
  }

  private getAuditPointIndex = (audit: AuditModel) => {
    const { auditPointId } = this.data.value
    const auditPointIndex = audit.auditPoints.findIndex(
      ({ id }) => id === auditPointId
    )

    invariant(
      auditPointIndex !== -1,
      `Tried to set audit point note but audit point was not found! Audit Point Id: ${auditPointId}`
    )
    return auditPointIndex
  }

  public ApplyTo = action((audit: AuditModel): void => {
    const auditPointIndex = this.getAuditPointIndex(audit)

    const { note } = this.data.value

    audit.auditPoints[auditPointIndex].note = note
  })

  public GetName = () =>
    `${this.data.type}-${this.auditId}-${this.data.value.auditPointId}`

  public SendOperation = async (client: BackEnd) => {
    const success = await client.AuditsRealTimeService.SetNote({
      auditId: this.auditId,
      auditPointId: this.data.value.auditPointId,
      callIdentifier: this.guid,
      checkPointId: null,
      created: new Date(),
      note: this.data.value.note,
      pledgeId: null
    })

    return success
  }
  public RollbackOn = action((audit: AuditModel): void => {
    const auditPointIndex = this.getAuditPointIndex(audit)

    invariant(
      this.data.previousValue,
      'Tried to rollback set audit point note but we had no previous value!'
    )

    audit.auditPoints[auditPointIndex].note = this.data.previousValue.note
  })
}
