import styled, { keyframes } from 'styled-components'

const progressAnimation = keyframes`
  0% {
    stroke-dasharray: 0 100;
  }
  100% {
    stroke-dasharray: 100 100;
  }
`

export const StyledCircleProgress = styled.svg`
  display: block;
  width: 100%;
`

export const CirclePath = styled.path`
  fill: #121617;

  animation: 1s ${progressAnimation} forwards;

  stroke-linecap: round;
  stroke-width: 2.8;
  stroke: #00bcd4;
`

export const CircleText = styled.text`
  fill: #fff;
  font-size: 1rem;
  text-anchor: middle;
  dominant-baseline: middle;
  font-weight: 500;
`
