import { Container } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const StyledContentHeader = styled(Container)`
  padding-bottom: 10rem;
  height: 48rem;
  margin-top: 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  background-color: var(--sdds-blue-800);
  font-family: 'Scania Sans', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 8rem;
  line-height: 8rem;
  letter-spacing: -0.04em;
`

export const Title = styled.div`
  color: var(--sdds-grey-500);
  margin-bottom: 2rem;
`

export const Subtitle = styled.div`
  color: white;
`
