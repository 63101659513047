import { IconHeart } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const IconWrapper = styled.div<{ isFavorite: boolean }>`
  cursor: pointer;
  width: 6rem;
  color: ${({ isFavorite }) =>
    isFavorite ? 'var(--sdds-orange-600)' : 'var(--sdds-grey-600)'};

  &:hover {
    color: ${({ isFavorite }) =>
      isFavorite ? 'var(--sdds-grey-500)' : 'var(--sdds-orange-500)'};
  }
`

export const IconFilledHeart = styled(IconHeart)`
  path {
    fill-rule: nonzero;
  }
`
