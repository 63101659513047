import { useTranslation } from 'react-i18next'

import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { Divider } from '@cdab/scania/sdds'

import type { AuditPointInfoCardProps } from './audit-point-info-card.types'
import { Title } from './audit-point-info-card.styles'

export function AuditPointInfoCard({
  purposeDescription,
  description
}: AuditPointInfoCardProps) {
  const { t } = useTranslation()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')

  return (
    <>
      {!isLg && (
        <>
          <Title>{t('description')}</Title>
          <p>{description}</p>

          <Divider type='light' />
        </>
      )}

      <Title>{t('purpose')}</Title>
      {purposeDescription}
    </>
  )
}
