import { deviationsController } from '@cdab/scania/qpr/offline/controllers'
import type { DeviationModel } from '@cdab/scania/qpr/offline/models'
import type {
  AuditPoint,
  Deviation,
  DeviationValidationSchemaOut
} from '@cdab/scania/qpr/schema'
import { Toast } from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { DeviationModal } from './deviation-modal'

export const UpdateDeviationModal = observer(
  (props: {
    onClose: () => void
    onDelete: () => void
    open: boolean
    deviation: DeviationModel
    auditId: number
    allowEditAuditPoint: boolean
    defaultAuditPoint?: AuditPoint
  }) => {
    const { onClose, deviation, auditId } = props

    useEffect(() => {
      deviationsController.GetFiles(auditId, deviation.clientGuid)
    }, [auditId, deviation.clientGuid])

    const { t } = useTranslation(['common'])

    const onSave = (data: DeviationValidationSchemaOut) => {
      const common: Partial<Deviation> = {
        ...deviation,
        auditPointId: data.auditPointId,
        auditPointNumber: data.auditPointNumber,
        deviation: data.deviation
      }

      const updatedDeviation: Deviation = data.withActionPlan
        ? {
            ...(common as Required<typeof common>),
            deviationWithoutActions: false,
            approvalDate: data.completed || null,
            expirationDate: data.due,
            proposedActions: data.action,
            responsible: data.assignee
          }
        : {
            ...(common as Required<typeof common>),
            deviationWithoutActions: true,
            approvalDate: null,
            expirationDate: null,
            proposedActions: null,
            responsible: null
          }

      deviationsController.UpdateDeviation(updatedDeviation)

      toast(
        <Toast
          type='success'
          headline={capitalizeFirstLetter(t('deviation-saved'))}
          subheadline={capitalizeFirstLetter(t('deviation-updated'))}
        />
      )

      if (data.files) {
        deviationsController.AddFiles(
          auditId,
          deviation.clientGuid,
          data.files.map(({ file }) => file)
        )
      }

      data.filesToDelete?.forEach(fileId => {
        deviationsController.DeleteFile(auditId, deviation.clientGuid, fileId)
      })

      return Promise.resolve()
    }

    const onDelete = () => {
      if (window.confirm('Are you sure you want to delete this deviation?')) {
        deviationsController.DeleteDeviation(auditId, deviation.clientGuid)

        toast(
          <Toast
            type='success'
            headline={capitalizeFirstLetter(t('deviation-deleted'))}
            subheadline={capitalizeFirstLetter(t('deviation-deleted-success'))}
          />
        )

        props.onDelete()
      }
    }

    return (
      <DeviationModal
        allowEditAuditPoint={props.allowEditAuditPoint}
        auditId={props.auditId}
        hideWithActionPlanCheckbox={false}
        onClose={onClose}
        onSave={onSave}
        open={props.open}
        deviation={props.deviation}
        onDelete={onDelete}
        defaultAuditPoint={props.defaultAuditPoint}
      />
    )
  }
)
