import type { Pledge } from '@cdab/scania/qpr/schema'

export const Pledges: Pledge[] = [
  {
    id: 62,
    translationGuid: '6707aa0d-46c0-4720-aa00-4eb81359c6a0',
    pledgeNo: '1',
    note: null,
    pledgeAreaId: 1
  },
  {
    id: 63,
    translationGuid: 'cf2f34da-1356-4152-b435-44c29c77357b',
    pledgeNo: '2',
    note: null,
    pledgeAreaId: 1
  },
  {
    id: 64,
    translationGuid: '7001b0ca-3e05-4879-823b-cecfcfe68a48',
    pledgeNo: '3',
    note: null,
    pledgeAreaId: 1
  },
  {
    id: 65,
    translationGuid: '29b8bbc2-6c75-4899-b0b1-c7d3a3a48e00',
    pledgeNo: '4',
    note: null,
    pledgeAreaId: 2
  },
  {
    id: 68,
    translationGuid: '037a062b-d7e2-4e77-8896-2513800facbf',
    pledgeNo: '7',
    note: null,
    pledgeAreaId: 3
  },
  {
    id: 69,
    translationGuid: '25a31b6e-aeaa-4cb8-b31c-ba3d3daf7f75',
    pledgeNo: '8',
    note: null,
    pledgeAreaId: 3
  },
  {
    id: 70,
    translationGuid: '409c15f5-3119-4631-a02a-686dc4c97a3d',
    pledgeNo: '9',
    note: null,
    pledgeAreaId: 3
  },
  {
    id: 71,
    translationGuid: '1d0267e7-af99-401b-889d-720d06429863',
    pledgeNo: '10',
    note: null,
    pledgeAreaId: 3
  },
  {
    id: 72,
    translationGuid: '0ea8117f-b22a-4ef1-8a9a-031b02cbe6a4',
    pledgeNo: '11',
    note: null,
    pledgeAreaId: 3
  },
  {
    id: 73,
    translationGuid: '8292f01a-db8e-4161-aff5-3ad7d9fe6952',
    pledgeNo: '12',
    note: null,
    pledgeAreaId: 4
  },
  {
    id: 74,
    translationGuid: 'dc9913d4-807b-4389-bacb-279bb3fd98a3',
    pledgeNo: '13',
    note: null,
    pledgeAreaId: 4
  },
  {
    id: 75,
    translationGuid: '94b8f02b-b2ff-4aee-9bc6-c654ae8baa5f',
    pledgeNo: '14',
    note: null,
    pledgeAreaId: 5
  },
  {
    id: 76,
    translationGuid: '051c2f43-eba2-47f5-a84a-ab0a7d643929',
    pledgeNo: '15',
    note: null,
    pledgeAreaId: 5
  }
].map(p => ({ ...p, sortOrder: p.id }))
