import type { RowProps } from './row.types'
import { StyledRow } from './row.styles'

export const Row = ({
  children,
  className,
  fullHeight = false,
  ...props
}: RowProps): JSX.Element => {
  return (
    <StyledRow className={className} fullHeight={fullHeight} {...props}>
      {children}
    </StyledRow>
  )
}
