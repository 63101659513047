export const AUDITS_DATABASE_NAME = 'audits-db'
export const AUDITS_OBJECT_STORE_NAME = 'audits-store'
export const OPERATIONS_OBJECT_STORE_NAME = 'operations-store'
export const DEALERS_OBJECT_STORE_NAME = 'dealers-store'
export const FAVORITE_AUDITS_OBJECT_STORE_NAME = 'favorite-audits-store'
export const FAVORITE_AUDITS_CHANGES_OBJECT_STORE_NAME =
  'favorite-audits-changes-store'

export const OPERATIONS_INDEX_AUDIT_ID = 'operations-index-audit-id'
export const OPERATIONS_INDEX_GUID = 'operations-index-guid'
export const OPERATIONS_INDEX_DEVIATION_ID = 'operations-index-deviation-id'

export type StoreName =
  | typeof AUDITS_OBJECT_STORE_NAME
  | typeof OPERATIONS_OBJECT_STORE_NAME
  | typeof DEALERS_OBJECT_STORE_NAME
  | typeof FAVORITE_AUDITS_OBJECT_STORE_NAME
  | typeof FAVORITE_AUDITS_CHANGES_OBJECT_STORE_NAME
