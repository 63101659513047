import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const Description = styled.p.attrs({
  className: 'sdds-detail-01'
})``

export const Title = styled.h4`
  ::first-letter {
    text-transform: uppercase;
  }
`
