import { Block, IconEdit } from '@cdab/scania/sdds'
import styled from 'styled-components'

import type { Expired } from './dealer-status-card.styles.types'

export const StyledBlock = styled(Block)<Expired>`
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: ${({ expired }) =>
    expired ? 'var(--sdds-negative)' : 'var(--sdds-positive)'};

  @media (min-width: ${({ theme }) => theme.size.lg}) {
    height: 15rem;
    display: flex;
    align-items: center;
  }
`

export const ItemList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.size.md}) {
    flex-direction: row;
  }
`

export const Item = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.size.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const ExpiryItem = styled(Item)<Expired>`
  color: ${({ expired }) =>
    expired ? 'var(--sdds-negative)' : 'var(--sdds-positive)'};
`

export const Headline = styled.div.attrs({
  className: 'sdds-detail-04'
})``

export const Value = styled.div.attrs({
  className: 'sdds-detail-02'
})`
  font-weight: inherit;
`

export const ExpiryValue = styled(Value).attrs<Expired>(({ expired }) => ({
  role: expired ? 'strong' : 'generic'
}))<Expired>`
  display: flex;
`

export const StyledIconExpiryDate = styled(IconEdit)`
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  color: black;
  margin-left: 1rem;
`
