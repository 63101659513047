import { ApplicationLoader } from '@cdab/scania/qpr/components'
import { TemplateProvider } from '@cdab/scania/qpr/template-engine'
import { Suspense, useMemo } from 'react'
import { RouterProvider } from 'react-router-dom'
import { GlobalStyle } from './GlobalStyles'
import { createRouter } from './routes/routes'

export function App() {
  const router = useMemo(() => {
    return createRouter()
  }, [])

  return (
    <>
      <GlobalStyle />
      <TemplateProvider>
        <Suspense fallback={<ApplicationLoader />}>
          <RouterProvider
            router={router}
            fallbackElement={<ApplicationLoader />}
          />
        </Suspense>
      </TemplateProvider>
    </>
  )
}
