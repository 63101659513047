import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { z } from 'zod'
import { ajv, isValidThing } from '../ajv'
import type { AuditPointNumber } from '../audit-point'
import { AuditPointNumbersSchema } from '../audit-point'
import type { CheckPointNumber } from '../checkpoint'
import { CheckPointNumbersSchema } from '../checkpoint'
import { FileDataSchema } from '../file-data'
import type { MarketName } from '../market'
import { MarketNameSchema } from '../market'

export type AdminReferenceDocument = {
  auditPoints: AuditPointNumber[]
  checkPoints: CheckPointNumber[]
  documentVersion: number
  id: number
  market: MarketName | null
  title: string
  url: string | null
  documentFileId: number | null
  physicalDocumentFileName: string | null
  updatedTime: string
}

export const AdminReferenceDocumentSchema: JSONSchemaType<AdminReferenceDocument> =
  {
    type: 'object',
    properties: {
      auditPoints: AuditPointNumbersSchema,
      checkPoints: CheckPointNumbersSchema,
      documentVersion: {
        type: 'integer'
      },
      id: {
        type: 'integer'
      },
      market: { ...MarketNameSchema, nullable: true },
      title: {
        type: 'string'
      },
      url: {
        type: 'string',
        nullable: true
      },
      documentFileId: {
        type: 'integer',
        nullable: true
      },
      physicalDocumentFileName: {
        type: 'string',
        nullable: true
      },
      updatedTime: {
        type: 'string',
        format: 'iso-date-time',
        nullable: false
      }
    },
    required: [
      'auditPoints',
      'checkPoints',
      'documentVersion',
      'market',
      'id',
      'title',
      'url',
      'documentFileId',
      'physicalDocumentFileName',
      'updatedTime'
    ]
  }

const validateAdminReferenceDocument: ValidateFunction<AdminReferenceDocument> =
  ajv.compile(AdminReferenceDocumentSchema)

export const isValidAdminReferenceDocument = (
  referenceDocument: unknown
): referenceDocument is AdminReferenceDocument =>
  isValidThing<AdminReferenceDocument>(
    referenceDocument,
    validateAdminReferenceDocument
  )

export const DocumentFileSchema = z
  .object({
    file: z.instanceof(File)
  })
  .extend(FileDataSchema.pick({ id: true, url: true }).shape)

export type DocumentFile = z.infer<typeof DocumentFileSchema>
