import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import {
  PrintAuditNotesButton,
  PrintAuditReportButton,
  PrintDeviationsButton
} from '@cdab/scania/qpr/components/atoms'
import type { AuditModel } from '@cdab/scania/qpr/offline/models'
import { Block, Button } from '@cdab/scania/sdds'

import { useFeatures } from '@cdab/scania/qpr/feature-flags'
import { capitalizeFirstLetter } from '@cdab/utils'
import { useState } from 'react'
import { AuditReportModal } from '../audit-report-modal'
import { AuditActions } from './audit-actions'
import { ActionsSection } from './audit-summary.styles'

export const ActionsBlocks = observer(
  ({ audit, className }: { audit: AuditModel; className?: string }) => {
    const { t } = useTranslation(['common', 'reports'])
    const { dos_5_report } = useFeatures(['dos_5_report'])
    const [openAuditReporteModal, setOpenAuditReportModal] = useState(false)

    const hasDeviations = audit.deviations.some(
      deviation =>
        !deviation.deviationWithoutActions && deviation.approvalDate == null
    )

    return (
      <>
        <div style={{ width: '100%' }} className={className}>
          <AuditActions
            dealerId={audit.did}
            auditId={audit.id}
            canCertify={!audit.isReadonly}
            hasDeviations={hasDeviations}
            isCertified={audit.isCertified}
          />
          <Block
            style={{ width: '100%' }}
            color='on-grey'
            header={t('reports')}
            className='sdds-u-mt3'
          >
            <ActionsSection>
              <PrintAuditReportButton audit={audit} />

              <PrintDeviationsButton audit={audit} />
              <PrintAuditNotesButton audit={audit} />
            </ActionsSection>
            {dos_5_report.enabled && (
              <ActionsSection style={{ paddingTop: '8rem' }}>
                <Button
                  fullBleed
                  onClick={() => setOpenAuditReportModal(true)}
                  text={capitalizeFirstLetter(
                    t('audit-dos-report', { ns: 'reports' })
                  )}
                />
              </ActionsSection>
            )}
          </Block>
        </div>
        <AuditReportModal
          auditId={audit.id}
          open={openAuditReporteModal}
          onClose={() => setOpenAuditReportModal(false)}
        />
      </>
    )
  }
)
