export type IconSize = 'xlarge' | 'large' | 'medium' | 'small'

export enum Sizes {
  small = '16px',
  medium = '20px',
  large = '24px',
  xlarge = '32px'
}

/**
 *
 * @param name Convert a size into unit size
 *
 * @returns Size in px, like `24px`
 */
export function getIconSize(size: IconSize): string {
  return Sizes[size].toString()
}
