import type { JSONSchemaType, ValidateFunction } from 'ajv'
import { ajv, isValidThing } from '../ajv'

export interface ChatMessage {
  text: string
  author: string
  id: number
  createdTime: string
  fileId?: number
  actionPlanItemId: number
  readByDealer: boolean
  readByAuditor: boolean
}

export interface ChatMessageJson {
  adhChatMessageText: string
  adhChatMessageAuthor: string
  id: number
  adhChatMessageCreatedTime: string
  chatFileId?: number
  actionPlanItemId: number
  readByDealer: boolean
  readByAuditor: boolean
}

export const ChatMessageSchema: JSONSchemaType<ChatMessageJson> = {
  type: 'object',

  properties: {
    adhChatMessageText: {
      type: 'string'
    },
    adhChatMessageAuthor: {
      type: 'string'
    },
    id: {
      type: 'integer'
    },
    adhChatMessageCreatedTime: {
      type: 'string',
      format: 'iso-date-time',
      nullable: true
    },
    chatFileId: {
      type: 'integer',
      nullable: true
    },
    actionPlanItemId: {
      type: 'integer'
    },
    readByAuditor: {
      type: 'boolean'
    },
    readByDealer: {
      type: 'boolean'
    }
  },

  required: [
    'adhChatMessageText',
    'adhChatMessageAuthor',
    'id',
    'adhChatMessageCreatedTime',
    'actionPlanItemId',
    'readByAuditor',
    'readByDealer'
  ]
}

const validateChatMessage: ValidateFunction<ChatMessageJson> =
  ajv.compile(ChatMessageSchema)

export const isValidChatMessage = (
  ChatMessage: unknown
): ChatMessage is ChatMessageJson =>
  isValidThing<ChatMessageJson>(ChatMessage, validateChatMessage)

export const mapResponseToADHChatMessage = (
  json: ChatMessageJson
): ChatMessage => {
  return {
    author: json.adhChatMessageAuthor,
    id: json.id,
    text: json.adhChatMessageText,
    createdTime: json.adhChatMessageCreatedTime,
    fileId: json.chatFileId,
    actionPlanItemId: json.actionPlanItemId,
    readByAuditor: json.readByAuditor,
    readByDealer: json.readByDealer
  }
}
