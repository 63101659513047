import { v4 as uuidv4 } from 'uuid'
import type { BackEnd } from '@cdab/scania/qpr/interactor'
import type { Audit, FileData } from '@cdab/scania/qpr/schema'
import type { AuditModel } from '../audit-model'
import type { OperationDataBase } from './operations-base'
import { Operation } from './operations-base'
import { action } from 'mobx'
import invariant from 'tiny-invariant'

type Type = 'delete-audit-file'

type Data = {
  fileId: FileData['id']
}

export type DeleteAuditFileOperationData = OperationDataBase<Type, Data>

export class DeleteAuditFileOperation extends Operation {
  public data: DeleteAuditFileOperationData
  private deletedFile: FileData | undefined = undefined

  constructor(auditId: Audit['id'], data: Data, guid = uuidv4()) {
    super(auditId, guid)

    this.data = {
      type: 'delete-audit-file',
      value: {
        fileId: data.fileId
      }
    }
  }

  public ApplyTo = action((audit: AuditModel) => {
    const { fileId } = this.data.value
    const fileIndex = audit.files.findIndex(f => f.id === fileId)

    if (fileIndex === -1) {
      console.warn("tried to delete file we couldn't find")
      return
    }

    const [file] = audit.files.splice(fileIndex, 1)
    this.deletedFile = file
  })

  public GetName(): string {
    return `${this.data.type}-${this.auditId}-${this.data.value.fileId}`
  }

  public SendOperation = async (client: BackEnd): Promise<boolean> => {
    await client.StorageService.DeleteFileForAudit(
      this.auditId,
      this.data.value.fileId
    )

    return true
  }

  public RollbackOn = action((audit: AuditModel): void => {
    invariant(
      this.deletedFile,
      `Cannot rollback deletoin of file if we haven't deleted it!`
    )
    invariant(
      audit.files.findIndex(f => f.id === this.data.value.fileId) !== -1,
      `Cannot rollback deletion of file if we already have it!`
    )

    audit.files.push(this.deletedFile)
    this.deletedFile = undefined
  })
}
