import invariant from 'tiny-invariant'
import * as sentry from '@sentry/react'

import { AUDITS_DATABASE_NAME } from './constants'
import { upgrade, CURRENT_DB_VERSION } from './upgrades'

let db: IDBDatabase | undefined = undefined
let createDbPromise: Promise<IDBDatabase> | undefined = undefined

export async function getDb(): Promise<IDBDatabase> {
  if (db) return db

  if (createDbPromise) return createDbPromise

  createDbPromise = new Promise((resolve, reject) => {
    const request = indexedDB.open(AUDITS_DATABASE_NAME, CURRENT_DB_VERSION)
    request.onerror = ev => {
      // FIXME: Needs better error handling
      alert('Could not open database!')
      console.error(ev)
      sentry.captureException(ev)

      reject(ev)
    }
    request.onsuccess = () => {
      db = request.result

      resolve(db)
      createDbPromise = undefined
    }
    request.onupgradeneeded = ev => {
      const database = request.result

      invariant(ev.newVersion)

      for (let i = ev.oldVersion + 1; i <= ev.newVersion; i++) {
        upgrade(i, database)
      }
    }
  })

  return createDbPromise
}
