import type {
  AuditsService as AuditsServiceDefinition,
  CreateADHLinkData,
  CreateAuditData,
  UpdateAuditData
} from '@cdab/scania/qpr/interactor'
import type {
  ADHLink,
  ADHLinkJson,
  Audit,
  AuditPoint,
  AuditPointNumber,
  AuditPointNumberJson,
  AuditType,
  AuditTypeJson,
  ChatMessage,
  CheckPoint,
  CheckPointNumber,
  CheckPointNumberJson,
  DealerAudit,
  DealerAuditJson,
  DeviationJson,
  FavoriteAudit,
  FavoriteAuditJson,
  FavoriteAuditSingle,
  FavoriteAuditSingleJson,
  Pledge,
  PledgeArea,
  PledgePerspective,
  ReferenceDocument
} from '@cdab/scania/qpr/schema'
import {
  createAuditTypeFromAuditTypeJson,
  createDealerAuditFromJson,
  createDeviationFromJson,
  createFavoriteAuditFromJson,
  createFavoriteAuditSingleFromJson,
  isValidADHLink,
  isValidAuditPoint,
  isValidAuditPointNumber,
  isValidAuditPointNumbers,
  isValidAuditType,
  isValidChatMessage,
  isValidCheckPoint,
  isValidCheckPointNumber,
  isValidDealerAuditJson,
  isValidDeviationJson,
  isValidFavoriteAuditJson,
  isValidFavoriteAuditSingleJson,
  isValidPledge,
  isValidPledgeArea,
  isValidPledgePerspective,
  isValidReferenceDocument,
  mapResponseToADHChatMessage,
  responseToADHLink
} from '@cdab/scania/qpr/schema'
import type { PartialWithNull } from '@cdab/type-utils'
import { axiosErrorToError, formatISODate } from '@cdab/utils'
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'

import invariant from 'tiny-invariant'
import type {
  AuditCompleteDto,
  AvailableAuditsDto,
  Configuration
} from './generated-swagger-client'
import { AuditsApi } from './generated-swagger-client'

export class AuditsService implements AuditsServiceDefinition {
  private readonly auditsApi: AuditsApi

  public constructor(
    configuration: Configuration,
    axiosInstance: AxiosInstance
  ) {
    this.auditsApi = new AuditsApi(
      configuration,
      configuration.basePath,
      axiosInstance
    )
  }

  public GetAuditTypes = async (
    option?: AxiosRequestConfig
  ): Promise<AuditType[]> => {
    const response = await this.auditsApi.apiAuditsAuditTypesGet(option)

    const auditTypes: AuditType[] = []

    for (const auditType of response.data.auditTypes ?? []) {
      const auditTypeJson: PartialWithNull<AuditTypeJson> = {
        auditTypeId: auditType.auditTypeId,
        auditTypeDescription: auditType.auditTypeDescription
      }

      if (!isValidAuditType(auditTypeJson))
        throw new Error(`Audit type ${auditType.auditTypeId} is invalid`)

      auditTypes.push(createAuditTypeFromAuditTypeJson(auditTypeJson))
    }

    return auditTypes
  }

  async UpdateAudit(
    auditId: number,
    update: UpdateAuditData,
    callIdentifier: string // TODO: Check what the purpose of this is. It is not being used internally
  ): Promise<void> {
    await this.auditsApi.apiAuditsAuditIdPut(auditId, {
      auditDate: formatISODate(update.date),
      auditDescription: update.description,
      extraAuditors: update.extraAuditors,
      auditTypeId: update.auditTypeId
    })
  }

  public GetAudit = async (
    auditId: number,
    options?: AxiosRequestConfig
  ): Promise<Audit> => {
    // TODO: Refactor validation logic to separate functions
    try {
      const response: AxiosResponse<AuditCompleteDto, Configuration> =
        await this.auditsApi.apiAuditsAuditAuditIdGet(auditId, options)

      if (response.status !== 200)
        throw new Error(
          `Error getting audit: (${response.status})${response.statusText}`
        )

      const { data: auditData } = response

      invariant(auditData.auditDate)

      const audit: Audit = {
        id: auditId,
        description: auditData.auditDescription ?? null,
        date: new Date(auditData.auditDate),
        note: auditData.note ?? null,
        did: auditData.did ?? -1,
        dosVersion: auditData.dosVersion ?? 0,
        isCertified: auditData.isCertified ?? false,
        isReadonly: auditData.isReadonly ?? false,
        extraAuditors: auditData.extraAuditors ?? '',
        auditPoints: [],
        checkPoints: [],
        pledgeAreas: [],
        pledgePerspectives: [],
        pledges: [],
        referenceDocuments: [],
        deviations: [],
        auditTypeId: auditData.auditTypeId ?? 0
      }

      // Verify that the audit points are valid
      if (!auditData.auditPoints)
        throw new Error('No audit points with audit data!')
      for (const auditPoint of auditData.auditPoints) {
        const {
          id,
          auditPointNo,
          translationGuid,
          pledgeId,
          note,
          purposeTranslationGuid,
          score,
          isMandatory,
          sortOrder
        } = auditPoint

        const maybeAuditPoint: PartialWithNull<AuditPoint> = {
          auditPointNo,
          translationGuid,
          id,
          isMandatory,
          note,
          pledgeId,
          purposeTranslationGuid,
          score,
          sortOrder
        }

        if (!isValidAuditPoint(maybeAuditPoint)) {
          throw new Error(`Invalid audit point: ${String(maybeAuditPoint)}`)
        }

        audit.auditPoints.push(maybeAuditPoint)
      }

      // Verify that checkpoints are valid
      if (!auditData.checkPoints)
        throw new Error('No check points with audit data!')
      for (const checkPoint of auditData.checkPoints) {
        const {
          auditPointId,
          id,
          checkPointNo,
          translationGuid,
          isPointCheckedYes,
          note
        } = checkPoint

        const maybeCheckPoint: PartialWithNull<CheckPoint> = {
          auditPointId,
          checkPointNo,
          translationGuid,
          id,
          note: note ?? '',
          score: isPointCheckedYes,
          sortOrder: -1 // FIXME: Not in response
        }

        if (!isValidCheckPoint(maybeCheckPoint)) {
          throw new Error(`Invalid check point: ${String(checkPoint)}`)
        }

        audit.checkPoints.push(maybeCheckPoint)
      }

      // Verify that the pledge areas are valid
      if (!auditData.pledgeAreas)
        throw new Error('No pledge areas with audit data!')
      for (const pledgeArea of auditData.pledgeAreas) {
        const { description, id } = pledgeArea

        const maybePledgeArea: PartialWithNull<PledgeArea> = {
          description,
          id
        }

        if (!isValidPledgeArea(maybePledgeArea)) {
          throw new Error(`Invalid pledge area ${String(pledgeArea)}`)
        }

        audit.pledgeAreas.push(maybePledgeArea)
      }

      // Verify that the pledge are valid
      if (!auditData.pledges) throw new Error('No pledges with audit data!')
      for (const pledge of auditData.pledges) {
        const { translationGuid, note, pledgeAreaId, id, pledgeNo } = pledge

        const maybePledge: PartialWithNull<Pledge> = {
          translationGuid,
          id,
          note: note ?? '',
          pledgeAreaId: pledgeAreaId ?? -1, // FIXME: Incorrect
          pledgeNo,
          sortOrder: -(id ?? 1) // FIXME: Not in response
        }

        if (!isValidPledge(maybePledge)) {
          throw new Error(`Invalid pledge: ${String(pledge)}`)
        }

        audit.pledges.push(maybePledge)
      }

      // Verify that the pledge perspectives are valid
      if (!auditData.pledgePerspectives)
        throw new Error('No pledge perspectives with audit data!')
      for (const perspective of auditData.pledgePerspectives) {
        const { translationGuid, id, perspectiveType, pledgeId } = perspective

        const maybePledgePerspective: PartialWithNull<PledgePerspective> = {
          translationGuid,
          id,
          perspectiveType,
          pledgeId
        }

        if (!isValidPledgePerspective(maybePledgePerspective)) {
          throw new Error(`Invalid pledge: ${String(perspective)}`)
        }

        audit.pledgePerspectives.push(maybePledgePerspective)
      }

      // Verify that the support documents are valid
      if (!auditData.referenceDocuments)
        throw new Error('No reference documents!')
      for (const referenceDocument of auditData.referenceDocuments) {
        const {
          auditPointId,
          checkPointId,
          documentVersion,
          id,
          logicalDocumentName,
          url
        } = referenceDocument

        const maybeReferenceDocument: PartialWithNull<ReferenceDocument> = {
          auditPointId: auditPointId,
          checkPointId: checkPointId,
          documentVersion: documentVersion,
          id: id,
          title: logicalDocumentName,
          url
        }

        if (!isValidReferenceDocument(maybeReferenceDocument)) {
          throw new Error(
            `Invalid reference document: ${String(maybeReferenceDocument)}`
          )
        }

        audit.referenceDocuments.push(maybeReferenceDocument)
      }

      // Verify that deviations are valid
      if (typeof auditData.deviations === 'undefined')
        throw new Error('Deviations was undefined!')
      for (const deviationData of auditData.deviations) {
        const maybeDeviation: PartialWithNull<DeviationJson> = {
          ...deviationData
        }

        if (!isValidDeviationJson(maybeDeviation))
          throw new Error(`Invalid deviation!: ${String(maybeDeviation)}`)

        audit.deviations.push(createDeviationFromJson(maybeDeviation))
      }

      return audit
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetAuditsForDealer = async (
    dealerId: number,
    options?: AxiosRequestConfig
  ): Promise<DealerAudit[]> => {
    try {
      const response: AxiosResponse<AvailableAuditsDto, Configuration> =
        await this.auditsApi.apiAuditsDealerDidGet(dealerId, options)

      const dealerAudits: DealerAudit[] = []

      for (const dealerAudit of response.data.audits ?? []) {
        const dealerAuditData: PartialWithNull<DealerAuditJson> = {
          auditDate: dealerAudit.auditDate ?? null,
          auditDescription: dealerAudit.auditDescription,
          auditor: dealerAudit.auditor,
          dosVersion: dealerAudit.dosVersion,
          extraAuditors: dealerAudit.extraAuditors,
          hasDeviations: dealerAudit.hasDeviations,
          hasNotes: dealerAudit.hasNotes,
          id: dealerAudit.id,
          isCertificationAudit: dealerAudit.isCertificationAudit ?? null,
          auditTypeDescription: dealerAudit.auditTypeDescription
        }

        if (!isValidDealerAuditJson(dealerAuditData))
          throw new Error(`Audit ${dealerAudit.id} is invalid`)

        dealerAudits.push(createDealerAuditFromJson(dealerAuditData))
      }

      return dealerAudits
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  async CreateAudit(data: CreateAuditData): Promise<number> {
    try {
      const result = await this.auditsApi.apiAuditsPost({
        auditDate: formatISODate(data.date),
        auditDescription: data.description,
        did: data.dealerId,
        userId: data.userId,
        extraAuditors: data.extraAuditors,
        isRemoteAudit: false,
        dosversion: 6,
        auditTypeId: data.auditTypeId
      })

      return result.data
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  async GetAuditPointNumbersForAudit(
    auditId: number
  ): Promise<AuditPointNumber[]> {
    try {
      const result =
        await this.auditsApi.apiAuditsAuditAuditIdAuditPointNumbersGet(auditId)

      const data = result.data.data as unknown

      if (!isValidAuditPointNumbers(data))
        throw new Error(`AuditPointNumbers received are invalid`)
      return (
        data.map(
          (apn): AuditPointNumber => ({
            id: apn.id,
            auditPointNo: apn.auditPointNo
          })
        ) ?? []
      )
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  async CertifyAudit(auditId: number): Promise<string> {
    try {
      const result = await this.auditsApi.apiAuditsAuditAuditIdCertifyPost(
        auditId
      )
      return result.data.data
    } catch (e) {
      // TODO: Handle error with axiosErrorToError
      if (
        isAxiosError<
          ApiReturnType<
            ReturnType<typeof this.auditsApi.apiAuditsAuditAuditIdCertifyPost>
          >
        >(e) &&
        e.response
      ) {
        return e.response.data.data
      }
      throw e
    }
  }

  async SetAuditNote(
    auditId: number,
    content: string,
    callIdentifier: string
  ): Promise<void> {
    try {
      await this.auditsApi.apiAuditsAuditIdNotePut(auditId, {
        content,
        callIdentifier
      })
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetFavoriteAuditsForUser = async (
    userId: number,
    options?: AxiosRequestConfig
  ): Promise<FavoriteAudit[]> => {
    try {
      const response = await this.auditsApi.apiAuditsFavoriteUserIdGet(
        userId,
        options
      )

      const favoriteAudits: FavoriteAudit[] = []

      for (const favoriteAudit of response.data.favoritesAudits ?? []) {
        const favoriteAuditData: PartialWithNull<FavoriteAuditJson> = {
          auditDate: favoriteAudit.auditDate,
          auditDescription: favoriteAudit.auditDescription,
          auditId: favoriteAudit.auditId,
          auditor: favoriteAudit.auditor,
          createdTime: favoriteAudit.createdTime,
          dealerCity: favoriteAudit.dealerCity,
          dealerName: favoriteAudit.dealerName,
          expiryDate: favoriteAudit.expiryDate,
          id: favoriteAudit.id,
          isCertified: favoriteAudit.isCertified,
          userId: favoriteAudit.userId
        }

        if (!isValidFavoriteAuditJson(favoriteAuditData))
          throw new Error(`Favorite audit ${favoriteAudit.id} is invalid`)

        favoriteAudits.push(createFavoriteAuditFromJson(favoriteAuditData))
      }

      return favoriteAudits
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetFavoriteAuditForUserAndAudit = async (
    userId: number,
    auditId: number,
    options?: AxiosRequestConfig
  ): Promise<FavoriteAuditSingle> => {
    try {
      const response = await this.auditsApi.apiAuditsFavoriteUserIdAuditIdGet(
        userId,
        auditId,
        options
      )

      if (response.status !== 200)
        throw new Error(
          `Error getting favorite audit single: (${response.status})${response.statusText}`
        )

      const { data: favoriteAuditSingleData } = response

      const favoriteAuditSingle: PartialWithNull<FavoriteAuditSingleJson> = {
        auditId: favoriteAuditSingleData.auditId,
        createdTime: favoriteAuditSingleData.createdTime ?? null,
        id: favoriteAuditSingleData.id ?? null
      }

      if (!isValidFavoriteAuditSingleJson(favoriteAuditSingle))
        throw new Error(
          `Favorite audit single ${favoriteAuditSingleData.id} is invalid`
        )

      const favoriteAudit =
        createFavoriteAuditSingleFromJson(favoriteAuditSingle)

      return favoriteAudit
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  async CreateFavoriteAudit(auditId: number): Promise<number> {
    try {
      const result = await this.auditsApi.apiAuditsFavoritePost({
        auditId: auditId
      })

      return result.data
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public DeleteFavoriteAudit = async (
    favoriteAuditId: number
  ): Promise<void> => {
    await this.auditsApi.apiAuditsFavoriteFavoriteAuditIdDelete(favoriteAuditId)
  }

  public GetAuditPointNumbers = async (
    dosVersion: number,
    options?: AxiosRequestConfig
  ): Promise<AuditPointNumber[]> => {
    try {
      const response =
        await this.auditsApi.apiAuditsAuditPointAuditPointNumbersDosVersionGet(
          dosVersion,
          options
        )

      const auditPointNumbers: AuditPointNumber[] = []

      for (const auditPointNumberData of response.data ?? []) {
        const auditPointNumber: PartialWithNull<AuditPointNumberJson> = {
          id: auditPointNumberData.id,
          auditPointNo: auditPointNumberData.auditPointNo
        }

        if (!isValidAuditPointNumber(auditPointNumber))
          throw new Error(`Audit point numbers is invalid`)

        auditPointNumbers.push(auditPointNumber)
      }

      return auditPointNumbers
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetCheckPointNumbers = async (
    dosVersion: number,
    options?: AxiosRequestConfig
  ): Promise<CheckPointNumber[]> => {
    try {
      const response =
        await this.auditsApi.apiAuditsCheckPointCheckPointNumbersDosVersionGet(
          dosVersion,
          options
        )
      const checkPointNumbers: CheckPointNumber[] = []

      for (const checkPointNumberData of response.data ?? []) {
        const checkPointNumber: PartialWithNull<CheckPointNumberJson> = {
          id: checkPointNumberData.id,
          checkPointNo: checkPointNumberData.checkPointNo
        }

        if (!isValidCheckPointNumber(checkPointNumber))
          throw new Error(`Check point numbers is invalid`)

        checkPointNumbers.push(checkPointNumber)
      }

      return checkPointNumbers
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public CreateCheckpoint = async (
    auditPointId: number,
    dosVersion: number,
    baseTranslation: string,
    options?: AxiosRequestConfig
  ): Promise<void> => {
    try {
      await this.auditsApi.apiAuditsCheckPointPost(
        {
          auditPointId,
          dosVersionId: dosVersion,
          baseTranslation
        },
        options
      )
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public CreateADHLink = async (
    data: CreateADHLinkData,
    options?: AxiosRequestConfig
  ): Promise<ADHLink> => {
    try {
      const response = await this.auditsApi.apiAuditsADHLinkPost(data, options)

      const link: PartialWithNull<ADHLinkJson> = {
        adhLinkActionPlanItems: response.data.adhLinkActionPlanItems,
        dealerName: response.data.dealerName,
        expiryDate: response.data.expiryDate,
        name: response.data.name,
        id: response.data.id,
        key: response.data.key
      }

      if (!isValidADHLink(link))
        throw new Error(`ADH link ${link.id} is invalid`)

      return responseToADHLink(link)
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public DeleteADHLink = async (linkId: number): Promise<void> => {
    try {
      await this.auditsApi.apiAuditsADHLinkIdDelete(linkId)
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetADHLinksForAudit = async (
    auditId: number,
    options?: AxiosRequestConfig
  ): Promise<ADHLink[]> => {
    try {
      const response = await this.auditsApi.apiAuditsADHLinksGet(
        auditId,
        options
      )

      const keys: ADHLink[] = []

      for (const key of response.data.keys ?? []) {
        const keyJson: PartialWithNull<ADHLinkJson> = {
          adhLinkActionPlanItems: key.adhLinkActionPlanItems,
          dealerName: key.dealerName,
          expiryDate: key.expiryDate,
          name: key.name,
          id: key.id,
          key: key.key
        }

        if (!isValidADHLink(keyJson))
          throw new Error(`ADH key ${keyJson.id} is invalid`)
        keys.push(responseToADHLink(keyJson))
      }

      return keys
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetADHChatMessagesForDeviation = async (
    actionPlanItemId: number,
    options?: AxiosRequestConfig
  ): Promise<ChatMessage[] | undefined> => {
    try {
      const response = await this.auditsApi.apiAuditsADHChatMessagesGet(
        actionPlanItemId,
        options
      )
      if (response.status == 204) {
        // No content. No available adh links
        return undefined
      }

      const chatMessages: ChatMessage[] = []

      for (const chatMessage of response.data ?? []) {
        if (!isValidChatMessage(chatMessage))
          throw new Error('Invalid chat message')

        chatMessages.push(mapResponseToADHChatMessage(chatMessage))
      }

      return chatMessages
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  async SetChatMessagesAsReadByAuditor(
    actionPlanItemId: number
  ): Promise<void> {
    await this.auditsApi.apiAuditsADHChatMessageReadActionPlanItemIdPut(
      actionPlanItemId
    )
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAxiosError<T>(obj: any): obj is AxiosError<T> {
  return !!obj.response
}

type ApiReturnType<T> = T extends Promise<AxiosResponse<infer R>> ? R : never
