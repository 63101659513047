import { Button, Column, IconProfile, Row } from '@cdab/scania/sdds'
import styled from 'styled-components'

export const StyledIcon = styled(IconProfile).attrs({
  className: 'sdds-btn-icon'
})``

export const StyledRow = styled(Row)`
  flex-wrap: nowrap;
  margin-top: 4rem;
  margin-bottom: 2rem;
  @media (max-width: ${({ theme }) => theme.size.lg}) {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }
`

export const StyledFirstButton = styled(Button)`
  padding-right: 8px;
`

export const Title = styled.h3.attrs({
  className: 'sdds-headline-03'
})`
  margin-bottom: 2rem;
  margin-top: 2rem;
`

export const StyledColumn = styled(Column)`
  @media (max-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`

export const ToggleWrapper = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
  @media (max-width: ${({ theme }) => theme.size.lg}) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`

export const ButtonMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 4rem;
  @media (max-width: ${({ theme }) => theme.size.lg}) {
    margin-top: 3rem;
  }
`

export const StyledSectionTitle = styled.div`
  margin-bottom: 2rem;
  font-size: 3rem;
`

export const StyledButton = styled(Button)`
  flex: 0.13;
  padding-right: 4px;
`

export const CheckboxWrapper = styled.div`
  margin-bottom: 1rem;
`
