import type {
  CreateUserData,
  UpdateUserData,
  UserService
} from '@cdab/scania/qpr/interactor'
import type {
  DOSVersion,
  Language,
  User,
  UserMarket
} from '@cdab/scania/qpr/schema'
import {
  isUser,
  isValidDOSVersion,
  isValidLanguage,
  isValidUserMarket
} from '@cdab/scania/qpr/schema'
import type { PartialWithNull } from '@cdab/type-utils'
import { axiosErrorToError } from '@cdab/utils'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { ApiBase } from './api-base'
import { validateResponse } from './api-utils'
import type { Configuration } from './generated-swagger-client'
import { UserApi } from './generated-swagger-client'

export class ApiUserService extends ApiBase implements UserService {
  private userApi: UserApi

  public async GetUser(
    userId: User['userId'],
    options?: AxiosRequestConfig
  ): Promise<User> {
    const response = await this.userApi.apiUserUserIdGet(userId, options)
    validateResponse(response, 'Error fetching user')

    const userMarkets: UserMarket[] = []

    for (const userMarket of response.data.markets ?? []) {
      if (!isValidUserMarket(userMarket)) {
        throw new Error(`Invalid market: ${String(userMarket)}`)
      }
      userMarkets.push(userMarket)
    }

    const user: PartialWithNull<User> = {
      userId: response.data.id,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      userName: response.data.userName,
      languageId: response.data.languageId,
      languageVersions: response.data.languageVersions,
      editRights: response.data.editRightId,
      role: response.data.roleId,
      viewId: response.data.viewId,
      cellPhone: response.data.cellPhone,
      email: response.data.email,
      country: response.data.country,
      auditReminderInterval: response.data.auditReminderInterval,
      truck: response.data.truck,
      bus: response.data.bus,
      engine: response.data.engine,
      active: response.data.active,
      alertOnCertify: response.data.alertOnCertify,
      defaultMarketId: response.data.defaultMarketId,
      markets: userMarkets
    }

    if (!isUser(user)) {
      throw new Error('Invalid user')
    }

    return user
  }

  async UpdateUser(
    userId: User['userId'],
    data: UpdateUserData,
    keycloakUserName: string | undefined,
    options?: AxiosRequestConfig
  ): Promise<string> {
    try {
      const response = await this.userApi.apiUserUserIdPut(
        userId,
        { ...data },
        keycloakUserName,
        options
      )

      return response.data
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetLanguagesForUser = async (
    includeBaseLanguage = false,
    options?: AxiosRequestConfig
  ): Promise<Language[]> => {
    try {
      const response = await this.userApi.apiUserLanguagesGet(
        includeBaseLanguage,
        options
      )

      const languages: Language[] = []

      for (const language of response.data.languages ?? []) {
        if (isValidLanguage(language)) {
          languages.push(language)
        }
      }

      return languages
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetDOSVersionsForUser = async (
    options?: AxiosRequestConfig
  ): Promise<DOSVersion[]> => {
    try {
      const response = await this.userApi.apiUserDosversionsGet(options)

      const dosVersions: DOSVersion[] = []

      for (const dosVersion of response.data.dosVersions ?? []) {
        if (isValidDOSVersion(dosVersion)) {
          dosVersions.push(dosVersion)
        }
      }

      return dosVersions
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }
  public GetUsers = async (options?: AxiosRequestConfig): Promise<User[]> => {
    try {
      const response = await this.userApi.apiUserGet(options)

      const users: User[] = []

      for (const userData of response.data.users ?? []) {
        const userMarkets: UserMarket[] = []

        for (const userMarket of userData.markets ?? []) {
          if (!isValidUserMarket(userMarket)) {
            throw new Error(`Invalid market: ${String(userMarket)}`)
          }
          userMarkets.push(userMarket)
        }

        const user: PartialWithNull<User> = {
          userId: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          userName: userData.userName,
          languageId: userData.languageId,
          languageVersions: userData.languageVersions,
          editRights: userData.editRightId,
          role: userData.roleId,
          viewId: userData.viewId,
          cellPhone: userData.cellPhone,
          email: userData.email,
          country: userData.country,
          auditReminderInterval: userData.auditReminderInterval,
          truck: userData.truck,
          bus: userData.bus,
          engine: userData.engine,
          active: userData.active,
          alertOnCertify: userData.alertOnCertify,
          defaultMarketId: userData.defaultMarketId,
          markets: userMarkets
        }

        if (isUser(user)) {
          users.push(user)
        }
      }
      return users
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  async CreateUser(
    data: CreateUserData,
    options?: AxiosRequestConfig
  ): Promise<string> {
    try {
      const response = await this.userApi.apiUserPost(
        { defaultView: data.viewId, ...data },
        options
      )

      return response.data
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  constructor(configuration: Configuration, axiosInstance: AxiosInstance) {
    super(configuration, axiosInstance)
    this.userApi = new UserApi(
      configuration,
      configuration.basePath,
      axiosInstance
    )
  }
}
