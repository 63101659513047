import type { AxiosInstance, AxiosResponse } from 'axios'

import type { ReportsService as ReportsServiceDefinition } from '@cdab/scania/qpr/interactor'
import { axiosErrorToError } from '@cdab/utils'

import type { Configuration } from './generated-swagger-client'
import { ReportsApi } from './generated-swagger-client'

export class ReportsService implements ReportsServiceDefinition {
  private readonly reportsApi: ReportsApi

  public constructor(
    configuration: Configuration,
    axiosInstance: AxiosInstance
  ) {
    this.reportsApi = new ReportsApi(
      configuration,
      configuration.basePath,
      axiosInstance
    )
  }

  public GetAreaReport = async (areaIds: number[]): Promise<AxiosResponse> => {
    try {
      const response = await this.reportsApi.apiReportsAreasGet(areaIds, {
        responseType: 'arraybuffer'
      })

      return response
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetMarketSummaryReport = async (
    marketIds: number[],
    areaIds: number[]
  ): Promise<AxiosResponse> => {
    try {
      const response = await this.reportsApi.apiReportsCountryGet(
        marketIds,
        areaIds,
        {
          responseType: 'arraybuffer'
        }
      )

      return response
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetMarketDevioationsReport = async (
    marketIds: number[],
    dateFrom: string,
    dateTo: string
  ): Promise<AxiosResponse> => {
    try {
      const response = await this.reportsApi.apiReportsMarketDeviationsGet(
        marketIds,
        dateFrom,
        dateTo,
        {
          responseType: 'arraybuffer'
        }
      )

      return response
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetEmptyDOSReport = async (
    dosVersion: number,
    viewSummary: boolean,
    viewCheckpoints: boolean,
    viewPerspectives: boolean
  ): Promise<AxiosResponse> => {
    try {
      const response = await this.reportsApi.apiReportsEmptyDOSReportGet(
        dosVersion,
        viewSummary,
        viewCheckpoints,
        viewPerspectives,
        {
          responseType: 'arraybuffer'
        }
      )

      return response
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }

  public GetDOSReport = async (
    auditId: number,
    viewSummary: boolean,
    viewCheckpoints: boolean,
    viewPerspectives: boolean,
    viewDeviations: boolean,
    viewNotes: boolean
  ): Promise<AxiosResponse> => {
    try {
      const response = await this.reportsApi.apiReportsDOSReportGet(
        auditId,
        viewSummary,
        viewCheckpoints,
        viewPerspectives,
        viewDeviations,
        viewNotes,
        {
          responseType: 'arraybuffer'
        }
      )

      return response
    } catch (error) {
      throw axiosErrorToError(error)
    }
  }
}
