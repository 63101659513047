import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { wait } from '@cdab/utils'

interface BackendOptions {
  auditNamespace: string
  clientRetryTime: number
  dosVersion: number
}

export type BackendOptionsParameter = Partial<BackendOptions>

const defaultOptions: BackendOptions = {
  auditNamespace: 'audits',
  dosVersion: 6,
  clientRetryTime: 1000
}

export class QprBackend {
  static type = 'backend'
  options: BackendOptions = defaultOptions

  constructor(
    services: unknown,
    backendOptions: BackendOptionsParameter = {},
    i18nextOptions: unknown = {}
  ) {
    this.init(services, backendOptions, i18nextOptions)
  }

  init(
    services: unknown,
    backendOptions: BackendOptionsParameter,
    i18nextOptions: unknown
  ) {
    this.options = {
      ...defaultOptions,
      ...backendOptions
    }
  }

  async read(
    language: string,
    namespace: string,
    callback: (
      error: string | null,
      translations?: { [key: string]: string } | null
    ) => void
  ) {
    if (namespace !== this.options.auditNamespace) {
      return callback(null, null)
    }
    const client = await this._getClient()
    const languageId = parseInt(language)
    if (isNaN(languageId)) return callback(null, null)

    const translations = await client.TranslationsService.GetTranslations(
      languageId,
      this.options.dosVersion
    )
    callback(null, translations)
  }

  async _getClient() {
    let client = getClient()
    while (!client) {
      await wait(this.options.clientRetryTime)
      client = getClient()
    }

    return client
  }
}

export default QprBackend
