import React from 'react'
import { capitalize } from '../utils'
import { Wrapper } from './data-table.styles'
import type { HeaderCellProps, TableProps } from './data-table.types'

const hiddenStyles: React.CSSProperties = {
  display: 'none'
}

export function HeaderCell({
  columnKey,
  customWidth,
  sortable = false,
  textAlign = 'left',
  hidden = false,
  ...props
}: HeaderCellProps): JSX.Element {
  const title = capitalize(props.title)

  return (
    <sdds-header-cell
      style={hidden ? hiddenStyles : undefined}
      custom-width={customWidth}
      column-key={columnKey}
      column-title={title}
      sortable={sortable}
      text-align={textAlign}
    ></sdds-header-cell>
  )
}

export const DataTable = React.forwardRef(function DataTable(
  {
    children,
    compact = false,
    data,
    filtering = false,
    multiSelect = false,
    noMinWidth = false,
    pagination = false,
    rowsPerPage,
    scroll,
    title,
    verticalDividers = false,
    whiteBackground = false
  }: TableProps,
  ref
) {
  function stringifyData(): string {
    return JSON.stringify(data, null, 2)
  }

  return (
    <Wrapper scroll={scroll}>
      <sdds-table
        ref={ref}
        body-data={stringifyData()}
        compact-design={compact}
        filtering={filtering}
        multi-select={multiSelect}
        no-min-width={noMinWidth}
        pagination={pagination}
        rows-per-page={rowsPerPage}
        table-title={title}
        vertical-dividers={verticalDividers}
        white-background={whiteBackground}
      >
        {children}
      </sdds-table>
    </Wrapper>
  )
})
