import { useMemo } from 'react'
import * as sentry from '@sentry/react'
import { getCSSVariableValue, useMediaQuery } from '@cdab/utils'

export type ValidUseCssVariableBreakpointVariableNames =
  | '--sdds-grid-lg'
  | '--sdds-grid-xs'
  | '--sdds-grid-sm'
  | '--sdds-grid-md'
  | '--sdds-grid-lg'
  | '--sdds-grid-xlg'
  | '--sdds-grid-xxlg'
  | '--sdds-grid-max'

export function useCssVariableBreakpoint(
  cssVariable: ValidUseCssVariableBreakpointVariableNames
): boolean {
  const size = useMemo(() => getCSSVariableValue(cssVariable), [cssVariable])
  const query = `(min-width: ${size})`
  const matches = useMediaQuery(query)

  if (!size) {
    sentry.captureMessage('useCssVariableBreakpoint: size was undefined')
    return false
  }

  return matches
}
