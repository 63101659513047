import { IconCross } from '@cdab/scania/sdds'
import { TdsButton, TdsIcon } from '@scania/tegel-react'
import { useEffect, useRef, useState } from 'react'
import { ADHUploadFileButton } from '../adh-upload-file-button'
import { RightActionButton } from '../clickable-list-item'
import {
  Actions,
  DeleteCrossWrapper,
  StyledImg,
  StyledImgContainer,
  UserInput
} from './chat-input.styles'
import type { ChatInputProps } from './chat-input.types'

export function Chatinput({
  onUploadFileClick,
  value,
  onCreateMessage,
  uploadedFileUrl,
  onUnsetFile
}: Readonly<ChatInputProps>) {
  const refInput = useRef<HTMLTdsTextareaElement>(null)
  const [errorState, setErrorState] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === 'Enter' &&
        document.activeElement ===
          refInput.current?.firstChild?.firstChild?.firstChild // This is the input textarea
      ) {
        event.preventDefault()
        setErrorState(!refInput.current?.value)
        if (refInput.current?.value !== '' && refInput.current?.value) {
          onCreateMessage(refInput.current?.value)
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onCreateMessage])

  return (
    <Actions
      onSubmit={e => {
        e.preventDefault()
      }}
      className='actions'
    >
      {uploadedFileUrl ? (
        <StyledImgContainer>
          <StyledImg src={uploadedFileUrl} alt='' />
          <DeleteCrossWrapper>
            <RightActionButton
              id='1'
              icon={<IconCross />}
              onClick={onUnsetFile}
            />
          </DeleteCrossWrapper>
        </StyledImgContainer>
      ) : (
        <ADHUploadFileButton
          onUploadFiles={onUploadFileClick}
          multiple={false}
          size='sm'
          variant='ghost'
          modeVariant='primary'
        />
      )}

      <UserInput
        ref={refInput}
        modeVariant='primary'
        name='message'
        rows={1}
        title='title'
        placeholder='Message'
        value={value}
        helper={errorState ? 'Please enter a message' : undefined} //Since we don't have translations in ADH, we can't use the translation key here
        state={errorState ? 'error' : undefined}
      />

      <TdsButton
        size='sm'
        type='submit'
        onClick={() => {
          setErrorState(!refInput.current?.value)
          if (refInput.current?.value !== '' && refInput.current?.value) {
            onCreateMessage(refInput.current?.value)
          }
        }}
      >
        <TdsIcon slot='icon' size='20px' name='send' />
      </TdsButton>
    </Actions>
  )
}
