import styled, { css } from 'styled-components'

export const DisabledNoteBlock = styled.div`
  background-color: var(--sdds-white);
  padding: var(--sdds-spacing-layout-8);
`

export const NoNote = styled.p`
  color: var(--sdds-grey-600);
`

export const UneditableNote = styled.p``

export const NoteTitle = styled.h6.attrs({
  className: 'sdds-headline-06'
})`
  margin-bottom: 4rem;
  margin-block-start: 0;
`

export const NoteHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SavedText = styled.span<{ show: boolean }>`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 100;
    }
    80% {
      opacity: 100;
    }
    90% {
      opacity: 0;
    }
  }

  color: var(--sdds-positive);

  ${({ show }) =>
    show &&
    css`
      animation-duration: 2.1s;
      animation-name: fadeIn;
      animation-direction: alternate;
      transition-timing-function: cubic-bezier(0.22, 0.61, 0, 1);
    `};
`
