import { upgradeToV1 } from './v1'
import { upgradeToV2 } from './v2'
import { upgradeToV3 } from './v3'

export const CURRENT_DB_VERSION = 3

export function upgrade(version: number, db: IDBDatabase) {
  switch (version) {
    case 1:
      upgradeToV1(db)
      break

    case 2:
      upgradeToV2(db)
      break

    case 3:
      upgradeToV3(db)
      break

    default:
      break
  }
}
