import styled from 'styled-components'

export interface ListProps {
  className?: string
  children: React.ReactNode
}

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
`

export function List({ children, className }: ListProps) {
  return <StyledList className={className}>{children}</StyledList>
}
