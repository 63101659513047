import { useMemo } from 'react'
import type { StatusBadgeProps } from '@cdab/scania/qpr/components/atoms'
import { StatusBadge } from '@cdab/scania/qpr/components/atoms'
import type { Dealer } from '@cdab/scania/qpr/schema'
import { CertificationStatus } from '@cdab/scania/qpr/schema'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'

export type DealerBadgeProps = {
  certificationStatus: Dealer['certificationStatus']
}

function certificationStatusToStatusProps(
  { certificationStatus }: Pick<DealerBadgeProps, 'certificationStatus'>,
  t: TFunction<'dealer', undefined>
): StatusBadgeProps {
  switch (certificationStatus) {
    case CertificationStatus.Certified:
      return {
        sddsColorVar: '--sdds-green-500',
        text: t('certification-status.certified')
      }

    case CertificationStatus.Expired:
      return {
        sddsColorVar: '--sdds-red-500',
        text: t('certification-status.expired')
      }

    case CertificationStatus.NeverCertified:
      return {
        sddsColorVar: '--sdds-orange-500',
        text: t('certification-status.never-certified')
      }
  }
}

export function DealerBadge(props: DealerBadgeProps) {
  const { t } = useTranslation()

  const statusBadgeProps = useMemo(
    () => certificationStatusToStatusProps(props, t),
    [props, t]
  )

  return <StatusBadge {...statusBadgeProps} />
}
