import {
  ArrowType,
  AuditItem,
  auditItemStateToScore,
  scoreToAuditItemState,
  type AuditItemState
} from '@cdab/scania/qpr/components/atoms'
import { useFeatures } from '@cdab/scania/qpr/feature-flags'
import { checkPointsController } from '@cdab/scania/qpr/offline/controllers'
import type { CheckPoint } from '@cdab/scania/qpr/schema'
import parse from 'html-react-parser'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { NoteIcon, NoteTextareaWrapper } from './checkpoint-item.styles'
import type { CheckpointItemProps } from './checkpoint-item.types'

export const CheckpointItem = observer((props: CheckpointItemProps) => {
  const { t } = useTranslation('audit')
  const { checkPoint, audit } = props
  const features = useFeatures(['disable_checkpoint_no_status'])

  const onCheckpointValueChange = useCallback(
    (item: CheckPoint) => (value: AuditItemState) => {
      const score = auditItemStateToScore(value)

      checkPointsController.SetScore(audit.id, item.id, score)
    },
    [audit.id]
  )

  const onCheckpointNoteSaved = useCallback(
    (content: string, checkPointId: CheckPoint['id']) => {
      checkPointsController.SetNote(audit.id, checkPointId, content)
    },
    [audit.id]
  )

  const onClickNote = (e: React.MouseEvent) => {
    e.stopPropagation() // don't trigger `onClick` when clicking the textarea
  }

  return (
    <AuditItem
      key={checkPoint.id}
      hideButton={false}
      fullWidth
      prefix={checkPoint.checkPointNo}
      value={scoreToAuditItemState(checkPoint.score)}
      onChange={onCheckpointValueChange(checkPoint)}
      selected={checkPoint.score ?? undefined}
      disableNo={features.disable_checkpoint_no_status.enabled}
      maxLines={Number.MAX_SAFE_INTEGER}
      disabled={audit.isReadonly}
      arrowType={props.open ? ArrowType.Up : ArrowType.Down}
      onClick={props.onClick}
      rightItem={props.hasNote ? <NoteIcon /> : undefined}
      fullChildren={
        props.open ? (
          <NoteTextareaWrapper
            onClick={onClickNote}
            disabled={audit.isReadonly}
            note={checkPoint.note ?? ''}
            onSaveNote={(node: string) =>
              onCheckpointNoteSaved(node, checkPoint.id)
            }
            showTitle={false}
            showSavedText={false}
          />
        ) : undefined
      }
    >
      <div>
        <div>{parse(t(checkPoint.translationGuid))}</div>
      </div>
    </AuditItem>
  )
})
