import {
  CenteredContainer,
  ClickableList,
  ClickableListItem,
  PageHeader,
  ScrollableWrapper
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { Roles, type AdminReferenceDocument } from '@cdab/scania/qpr/schema'
import {
  Block,
  Column,
  Container,
  EmptyScreen,
  IconWarning,
  Row
} from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  isRouteErrorResponse,
  json,
  useLoaderData,
  useNavigate,
  useRouteError
} from 'react-router'
import styled from 'styled-components'

export type DocumentsLoaderData = {
  documents: AdminReferenceDocument[]
}

const FlexContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`

export async function loader(): Promise<DocumentsLoaderData> {
  const client = getClient()

  const userInfo = await client.GetuserInfo()
  if (!userInfo) {
    throw json({
      status: 401
    })
  }

  const user = await client.UserService.GetUser(userInfo.userId)
  if (user.role > Roles.Auditor) {
    throw json({
      status: 404
    })
  }

  try {
    const [documents] = await Promise.all([
      client.DocumentService.GetDocuments()
    ])

    return { documents }
  } catch (error) {
    if (error instanceof Response) {
      throw new Response(null, {
        status: error.status
      })
    } else {
      throw new Response(null, {
        status: 404
      })
    }
  }
}

export function DocumentsPage() {
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'documents'])
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { updateTitles } = useTitle()
  const { documents } = useLoaderData() as DocumentsLoaderData

  const onDocumentListClick = useCallback(
    (documentId: AdminReferenceDocument['id']) => {
      if (documentId) {
        console.log('DocumentId i navigate', documentId)
        navigate(`/documents/${documentId}`)
      }
    },
    [navigate]
  )

  useEffect(() => {
    updateTitles({
      mobile: {
        title: t('reference-documents', { ns: 'common' }),
        description: null
      }
    })
  }, [updateTitles, t])

  const sortedDocuments = documents.slice().sort((a, b) => {
    const isATitleNumeric = /^\d/.test(a.title) // Check if a.title starts with a number
    const isBTitleNumeric = /^\d/.test(b.title) // Check if b.title starts with a number

    if (isATitleNumeric && !isBTitleNumeric) {
      return 1 // Place `a` after `b` if `a` starts with a number
    } else if (!isATitleNumeric && isBTitleNumeric) {
      return -1 // Place `a` before `b` if `b` starts with a number
    } else {
      return a.title.localeCompare(b.title) // Otherwise, sort alphabetically
    }
  })

  return (
    <FlexContainer fluid='normal' fullHeight paddingOnContainer={false}>
      {isLg && (
        <Row>
          <Column width={12}>
            <PageHeader
              title={capitalizeFirstLetter(
                t('reference-documents', { ns: 'common' })
              )}
            />
          </Column>
        </Row>
      )}
      <ScrollableWrapper>
        <Row>
          <Column width={12} lg={6} padding={false} offset={{ lg: 3 }}>
            <Block color='on-grey' disablePadding={true}>
              <ClickableList>
                {sortedDocuments.map(document => {
                  return (
                    <ClickableListItem
                      key={document.id}
                      title={document.title}
                      description={
                        document.market === null
                          ? 'Factory'
                          : document.market.marketName
                      }
                      rightItem={
                        document.auditPoints.length > 1
                          ? document.auditPoints.map(
                              auditPoint => auditPoint.auditPointNo
                            )[0] + '...'
                          : document.auditPoints.map(
                              auditPoint => auditPoint.auditPointNo
                            )
                      }
                      onClick={() => onDocumentListClick(document.id)}
                    />
                  )
                })}
              </ClickableList>
            </Block>
          </Column>
        </Row>
      </ScrollableWrapper>
    </FlexContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    if (error.status === 403) {
      message = t('access-denied')
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
