import { useCallback } from 'react'
import type { AuditGridItemProps } from '../../types'

import { H2 } from './audit-grid-item-point.styles'

export function AuditGridItemPoint({
  header,
  className,
  children,
  id,
  onHeaderClick,
  fontClassName
}: AuditGridItemProps): JSX.Element {
  const onClick = useCallback(() => {
    if (onHeaderClick) onHeaderClick(id)
  }, [id, onHeaderClick])

  return (
    <div className={className}>
      <div className='flex flex-col'>
        <H2
          clickable={!!onHeaderClick}
          className={fontClassName}
          onClick={onClick}
        >
          {header}
        </H2>
        {children}
      </div>
    </div>
  )
}
