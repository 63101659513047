import type { Dealer } from '@cdab/scania/qpr/schema'
import type { DealerModel } from '@cdab/scania/qpr/offline/models'
import { getDealerModelCacheData } from '@cdab/scania/qpr/offline/models'
import { restoreDealerFromData } from '@cdab/scania/qpr/offline/models'
import { getDealersStore } from './stores'

export async function getCachedDealer(dealerId: Dealer['id']) {
  const dealersStore = await getDealersStore('readonly')

  return new Promise<DealerModel | undefined>((resolve, reject) => {
    const req = dealersStore.get(dealerId)

    req.onerror = ev => {
      console.error(
        `An error occurred while getting cached dealer with id: ${dealerId}`,
        ev
      )
      reject(ev)
    }

    req.onsuccess = () => {
      const { result } = req

      if (!result) {
        return resolve(undefined)
      }

      const dealer = restoreDealerFromData(result)

      resolve(dealer)
    }
  })
}

export async function cacheDealer(dealer: DealerModel) {
  const dealersStore = await getDealersStore('readwrite')

  return new Promise<void>((resolve, reject) => {
    const req = dealersStore.put(getDealerModelCacheData(dealer))
    req.onerror = ev => {
      console.error('An error occurred while caching dealer', dealer, ev)
      reject()
    }
    req.onsuccess = () => {
      resolve()
    }
  })
}
