import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { SystemMessage } from '@cdab/scania/qpr/schema'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type UpdateSystemMessageState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

export function useSendUpdateSystemMessage() {
  const client = useBackend()

  const updateSystemMessage = async (data: SystemMessage): Promise<string> => {
    try {
      await client.SystemMessagesService.UpdateSystemMessage(data)
      return 'success'
    } catch (e) {
      return 'error'
    }
  }

  return {
    updateSystemMessage
  }
}

export function useUpdateSystemMessage(data: SystemMessage) {
  const { t } = useTranslation('systemMessages')
  const { updateSystemMessage } = useSendUpdateSystemMessage()
  const [submitState, setSubmitState] = useState<UpdateSystemMessageState>({
    status: 'none',
    message: null
  })

  const handleClickUpdateSystemMessage = useCallback(async () => {
    const response = await updateSystemMessage(data)
    setSubmitState({ status: 'none', message: null })

    if (response === 'success') {
      setSubmitState({ status: response, message: t('message.update-success') })
      return true
    }

    setSubmitState({ status: 'error', message: t('message.update-error') })
    return false
  }, [data, t, updateSystemMessage])
  return [submitState, handleClickUpdateSystemMessage, setSubmitState] as const
}
