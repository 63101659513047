import { isISODate, parseDate } from '@cdab/utils'
import { z } from 'zod'
import { UserFileSchema } from './user-file'

const DeviationValidationSchemaCommon = z.object({
  auditPointId: z
    .string()
    .min(1)
    .transform(id => parseInt(id)),
  auditPointNumber: z.string(),
  deviation: z.string().min(1),
  files: z.array(UserFileSchema).optional(),
  filesToDelete: z.array(z.string()).optional() // string = UserFile['id']
})

export const DeviationValidationSchemaWithoutActionPlan =
  DeviationValidationSchemaCommon.extend({
    withActionPlan: z.literal(false)
  })

export const DeviationValidationSchemaWithActionPlan =
  DeviationValidationSchemaCommon.extend({
    withActionPlan: z.literal(true),
    action: z.string().min(1),
    assignee: z.string().min(1),
    due: z
      .string()
      .refine(d => isISODate(d), 'Not a valid date')
      .transform(d => parseDate(d)),
    completed: z
      .string()
      .optional()
      .refine(d => (d ? isISODate(d) : true), 'Not a valid date')
      .transform(d => (d ? parseDate(d) : undefined))
  })

export const DeviationValidationSchema = z.discriminatedUnion(
  'withActionPlan',
  [
    DeviationValidationSchemaWithActionPlan,
    DeviationValidationSchemaWithoutActionPlan
  ]
)

export type DeviationValidationSchemaIn = z.input<
  typeof DeviationValidationSchema
>
export type DeviationValidationSchemaOut = z.output<
  typeof DeviationValidationSchema
>
