import { Block } from '@cdab/scania/sdds'
import styled, { css } from 'styled-components'

export const TextHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SavedText = styled.span<{ show: boolean; success: boolean }>`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 100;
    }
    80% {
      opacity: 100;
    }
    90% {
      opacity: 0;
    }
  }
  color: ${({ success }) =>
    success ? 'var(--sdds-positive)' : 'var(--sdds-negative)'};

  ${({ show }) =>
    show &&
    css`
      animation-duration: 2.1s;
      animation-name: fadeIn;
      animation-direction: alternate;
      transition-timing-function: cubic-bezier(0.22, 0.61, 0, 1);
    `};
`

export const TranslationItemWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`

export const TranslationTextAreaWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: 4rem;
  margin-right: 4rem;
`

export const AdditionalInfoTitle = styled.h6.attrs({
  className: 'sdds-headline-06'
})`
  margin-top: 0;
  margin-bottom: 0;
`

export const AdditionalInfoBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
`

export const AdditionalInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const AdditionalInfoContent = styled.p`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export const TranslationNo = styled.h4.attrs({
  className: 'sdds-headline-05'
})`
  margin-top: 0;
  margin-left: 1rem;
`

export const BaseTranslation = styled.div`
  > :first-child {
    margin-top: 0;
  }

  margin-top: 1rem;
  margin-bottom: 3rem;
`

export const EmptyBaseTranslation = styled.h5.attrs({
  className: 'sdds-headline-05'
})`
  text {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 2rem;
  background-color: black;
  color: white;
`

export const StyledBlock = styled(Block)`
  width: 200px;
`
