import cx from 'classnames'
import { NavLink } from 'react-router-dom'

import type {
  InlineTabsFullbleedProps,
  InlineTabsFullbleedTabProps
} from './inline-tabs-fullbleed.types'

const Tab = ({
  children,
  disabled = false,
  to
}: InlineTabsFullbleedTabProps): JSX.Element => {
  return (
    <NavLink
      className={({ isActive }) =>
        cx({
          'sdds-inline-tabs-fullbleed--tab': true,
          'sdds-inline-tabs-fullbleed--tab__active': isActive,
          'sdds-inline-tabs-fullbleed--tab__disabled': disabled
        })
      }
      to={to}
      role='tab'
    >
      {children}
    </NavLink>
  )
}

const InlineTabsFullbleed = ({ children }: InlineTabsFullbleedProps) => {
  return (
    <sdds-inline-tabs-fullbleed role='tablist'>
      {children}
    </sdds-inline-tabs-fullbleed>
  )
}
InlineTabsFullbleed.Tab = Tab

export { InlineTabsFullbleed }
