import { Outlet } from 'react-router-dom'
import { ApplicationLoader } from '@cdab/scania/qpr/components'
import { useEffect, useMemo, useState } from 'react'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import * as sentry from '@sentry/react'

type RequireAuthState = 'loading' | 'success' | 'error'

export function RequireAuth(): JSX.Element {
  const [authState, setAuthState] = useState<RequireAuthState>('loading')

  const client = useMemo(getClient, [])

  useEffect(() => {
    const isLoggedIn = !!client.GetuserInfo()

    if (isLoggedIn) {
      setAuthState('success')
      return
    }

    ;(async () => {
      try {
        await client.Login()
        setAuthState('success')
      } catch (error) {
        sentry.captureException(error)
        setAuthState('error')
      }
    })()
  }, [authState, client])

  if (authState === 'error') {
    return (
      <p>An error occurred while logging in. Please try to reload the page.</p>
    )
  }

  if (authState === 'loading') {
    return <ApplicationLoader />
  }

  return <Outlet />
}
