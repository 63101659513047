import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import type { AdminReferenceDocument } from '@cdab/scania/qpr/schema'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type DeleteDocumentState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'none'
      message: null
    }

export function useSendDeleteDocument() {
  const client = useBackend()

  const deleteDocument = async (
    documentId: AdminReferenceDocument['id']
  ): Promise<string> => {
    try {
      await client.DocumentService.DeleteDocument(documentId)
      return 'success'
    } catch (e) {
      return 'error'
    }
  }

  return {
    deleteDocument
  }
}

export function useDeleteDocument(documentId: AdminReferenceDocument['id']) {
  const { t } = useTranslation('documents')
  const { deleteDocument } = useSendDeleteDocument()
  const [submitState, setSubmitState] = useState<DeleteDocumentState>({
    status: 'none',
    message: null
  })

  const handleClickDeleteDocument = useCallback(async () => {
    const response = await deleteDocument(documentId)
    setSubmitState({ status: 'none', message: null })

    if (response === 'success') {
      setSubmitState({ status: response, message: t('message.delete-success') })
      return
    }

    setSubmitState({ status: 'error', message: t('message.delete-error') })
  }, [deleteDocument, documentId, t])
  return [submitState, handleClickDeleteDocument, setSubmitState] as const
}
