import { TdsIcon } from '@scania/tegel-react'
import { useRef, type ChangeEvent } from 'react'
import { ADHSelectFileButton } from './adh-upload-file-button.styles'
import type { ADHUploadFileButtonProps } from './adh-upload-file-button.types'

export function ADHUploadFileButton(props: Readonly<ADHUploadFileButtonProps>) {
  const {
    className,
    multiple = true,
    onUploadFiles,
    size,
    variant = 'primary',
    type = 'submit',
    modeVariant = 'primary'
  } = props
  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      onUploadFiles(e.target.files)
    }
    e.target.value = ''
  }

  const hiddenFileInput = useRef<HTMLInputElement | null>(null)

  return (
    <ADHSelectFileButton
      type={type}
      variant={variant}
      modeVariant={modeVariant}
      size={size}
      className={className}
      onClick={() => hiddenFileInput.current?.click()}
    >
      <TdsIcon slot='icon' size='20px' name='image_add' />
      <input
        type='file'
        multiple={multiple}
        onChange={onChangeFile}
        ref={hiddenFileInput}
      />
    </ADHSelectFileButton>
  )
}
