import cx from 'classnames'
import { NavLink, useLocation } from 'react-router-dom'
import type { SideMenuSubItemProps } from './side-menu.types'
import { SideMenuItemType, type SideMenuItemProps } from './side-menu.types'
import React, { useState } from 'react'

const style: React.CSSProperties = {
  textDecoration: 'none'
}

function getClassNames(isActive: boolean, type: SideMenuItemType) {
  return cx(
    type === SideMenuItemType.link
      ? 'sdds-sidebar-nav__item'
      : 'sdds-sidebar-nav-subnav__item',
    {
      'sdds-item--active': isActive
    }
  )
}

function getMainSubMenuItemClassNames(isOpen: boolean) {
  return cx('sdds-sidebar-nav__item sdds-sidebar-nav__extended', {
    'subnav-open': isOpen
  })
}

type LinkProps = React.PropsWithChildren<
  Pick<
    SideMenuItemProps,
    'to' | 'external' | 'callbackFn' | 'openInNewTab' | 'type' | 'title'
  >
>

function Link({ to, type, title = '', ...props }: LinkProps) {
  // For some reason this didn't work with styled components 🤷
  // If someone could get it to work I would be happy -- Dennis

  if (props.external) {
    return (
      <a
        href={to}
        target={props.openInNewTab ? '_blank' : undefined}
        rel={props.openInNewTab ? 'noreferrer' : undefined}
        className={'sdds-sidebar-nav__item-link'}
        style={style}
        onClick={e => type === SideMenuItemType.submenu && e.stopPropagation()}
      >
        {type === SideMenuItemType.submenu && (
          <span className='sdds-sidebar-nav__item-text'>{title}</span>
        )}
        {props.children}
      </a>
    )
  }

  return (
    <NavLink
      to={to}
      onClick={e =>
        type === SideMenuItemType.link ? props.callbackFn : e.stopPropagation()
      }
      style={style}
      className={'sdds-sidebar-nav__item-link'}
      target={props.openInNewTab ? '_blank' : undefined}
      rel={props.openInNewTab ? 'noreferrer' : undefined}
    >
      {type === SideMenuItemType.submenu && (
        <span className='sdds-sidebar-nav__item-text'>{title}</span>
      )}
      {props.children}
    </NavLink>
  )
}

export function SideMenuSubItem({ ...props }: SideMenuSubItemProps) {
  const location = useLocation()

  return (
    <li
      className={getClassNames(
        location.pathname.includes(props.to),
        SideMenuItemType.submenu
      )}
    >
      <Link type={SideMenuItemType.submenu} {...props}></Link>
    </li>
  )
}

export function SideMenuItem({
  title,
  icon,
  type = SideMenuItemType.link,
  ...props
}: SideMenuItemProps) {
  const [open, setOpen] = useState(false)
  const location = useLocation()

  return (
    <>
      {type === SideMenuItemType.link && (
        <li
          className={getClassNames(
            location.pathname.includes(props.to),
            SideMenuItemType.link
          )}
        >
          <Link title={title} type={type} {...props}>
            <span className='sdds-sidebar-nav__icon'>{icon}</span>
            <span className='sdds-sidebar-nav__item-text'>{title}</span>
          </Link>
        </li>
      )}
      {type === SideMenuItemType.submenu && (
        <li
          className={getMainSubMenuItemClassNames(open)}
          onClick={e => {
            e.preventDefault()
            setOpen(!open)
          }}
        >
          <a
            href={props.to}
            target={props.openInNewTab ? '_blank' : undefined}
            rel={props.openInNewTab ? 'noreferrer' : undefined}
            className='sdds-sidebar-nav__item-link'
            style={style}
          >
            <span className='sdds-sidebar-nav__icon'>{icon}</span>
            <span className='sdds-sidebar-nav__item-text'>{title}</span>
            <svg
              className='sdds-sidebar-nav__chevron'
              width='12'
              height='7'
              viewBox='0 0 12 7'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 1L6 6L11 1'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
              ></path>
            </svg>
          </a>
          <ul className='sdds-sidebar-nav-subnav'>
            <li className='sdds-sidebar-nav-subnav__item'>
              <span className='sdds-sidebar-nav__item-title'>{title}</span>
            </li>
            {props.children}
          </ul>
        </li>
      )}
    </>
  )
}
