/**
 * Validate email  *
 * @email string with e-mail
 * @returns boolean
 */
export function ValidateEmail(email: string) {
  return new RegExp(`^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$`).test(email)
}

/**
 * Validate url  *
 * @url string with url
 * @returns boolean
 */
export function ValidateUrl(url: string) {
  return new RegExp(
    `^https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)`
  ).test(url)
}
