import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import { getEnvVarsQPR } from '@cdab/scania/qpr/env-vars'
import type { UserInfo } from '@cdab/scania/qpr/interactor'
import type { AuditModel } from '@cdab/scania/qpr/offline/models'
import { Button } from '@cdab/scania/sdds'
import { capitalizeFirstLetter } from '@cdab/utils'

export function PrintDeviationsButton(props: { audit: AuditModel }) {
  const { t } = useTranslation()

  const client = useBackend()
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>()

  useEffect(() => {
    client.GetuserInfo().then(setUserInfo)
  }, [client])

  const { qpr2007url } = getEnvVarsQPR()

  if (!userInfo || props.audit.deviations.length === 0) return null

  const anyDeviation = props.audit.deviations[0]

  return (
    <a
      href={`${qpr2007url}/ActionPlanPrint.aspx?actionPlanID=${anyDeviation.actionPlanId}&userID=${userInfo.userId}&DOSVersion=${props.audit.dosVersion}&pdfPrintout=true&closeTab=true`}
      target='_blank'
      rel='noreferrer'
    >
      <Button fullBleed text={capitalizeFirstLetter(t('deviations'))} />
    </a>
  )
}
