import { useCallback } from 'react'
import type { AuditGridItemProps } from '../../types'

import { H2 } from './audit-grid-item-header.styles'

export function AuditGridItemHeader({
  children,
  header,
  className,
  id,
  onHeaderClick,
  fontClassName
}: AuditGridItemProps): JSX.Element {
  const onClick = useCallback((): void => {
    if (onHeaderClick) {
      onHeaderClick(id)
    }
  }, [id, onHeaderClick])

  return (
    <div className={className}>
      <H2
        clickable={!!onHeaderClick}
        onClick={onClick}
        className={fontClassName}
      >
        {header}
      </H2>
      {children}
    </div>
  )
}
