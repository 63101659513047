import { useBackend } from '@cdab/scania/qpr/contexts/backend-provider'
import { useCallback, useState } from 'react'
import type { UpdateDocumentData } from '@cdab/scania/qpr/interactor'
import { useTranslation } from 'react-i18next'

export type UpdateDocumentState =
  | {
      status: 'success'
      message: string
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'loading'
      message: null
    }
  | {
      status: 'none'
      message: null
    }

export function useUpdateDocument(data: UpdateDocumentData) {
  const { t } = useTranslation('documents')
  const [submitState, setSubmitState] = useState<UpdateDocumentState>({
    status: 'none',
    message: null
  })
  const client = useBackend()
  const handleClickUpdateDocument = useCallback(async () => {
    setSubmitState({
      status: 'loading',
      message: null
    })
    try {
      if (data.documentFileId) {
        // if we have ID we should delete that file
        await client.StorageService.DeleteDocumentFile(data.documentFileId)
        data.documentFileId = null
      }

      await client.DocumentService.UpdateDocument({
        ...data
      })
    } catch (e) {
      setSubmitState({
        status: 'error',
        message: t('message.error')
      })
      return false
    }

    setSubmitState({ status: 'success', message: t('message.success') })

    return true
  }, [t, data, client.StorageService, client.DocumentService])
  return [submitState, handleClickUpdateDocument, setSubmitState] as const
}
