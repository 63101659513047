import {
  AuditPointTabs,
  ElasticContentContainer
} from '@cdab/scania/qpr/components'
import {
  getAuditIdFromParams,
  getAuditPointIdFromParams
} from '@cdab/scania/qpr/loaders'
import {
  auditPointsController,
  auditsController
} from '@cdab/scania/qpr/offline/controllers'
import type {
  AuditModel,
  AuditPointModel
} from '@cdab/scania/qpr/offline/models'
import parse from 'html-react-parser'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs } from 'react-router'
import { Outlet, useLoaderData } from 'react-router'
import {
  AuditPointNumber,
  AuditPointNumberWrapper,
  StyledSpan
} from './audit-point-base-view.styles'

export type AuditPointLoaderData = {
  audit: AuditModel
  auditPoint: AuditPointModel
}

export async function loader({
  params
}: LoaderFunctionArgs): Promise<AuditPointLoaderData> {
  const auditId = getAuditIdFromParams(params)
  const auditPointId = getAuditPointIdFromParams(params)

  const { audit } = await auditsController.GetAudit(auditId)

  const auditPoint = await auditPointsController.GetAuditPoint(
    auditId,
    auditPointId
  )

  return {
    audit,
    auditPoint
  }
}

export const AuditPointBaseView = observer(function AuditPointBaseView() {
  const { t } = useTranslation('audit')
  const { audit, auditPoint } = useLoaderData() as AuditPointLoaderData

  // We loose real time updates if we use useMemo here
  const numberOfDeviationsForAuditPoint = audit.deviations.filter(
    deviation => deviation.auditPointId === Number(auditPoint.id)
  ).length

  const numberOfReferenceDocuments = useMemo(
    () =>
      audit.referenceDocuments.filter(sd => sd.auditPointId === auditPoint.id)
        .length,
    [audit, auditPoint.id]
  )

  return (
    <ElasticContentContainer
      scrollY
      header={
        <>
          <AuditPointNumberWrapper>
            <AuditPointNumber>{auditPoint.auditPointNo}</AuditPointNumber>
            <StyledSpan>{parse(t(auditPoint.translationGuid))}</StyledSpan>
          </AuditPointNumberWrapper>
          <AuditPointTabs
            numberOfReferenceDocuments={numberOfReferenceDocuments}
            numberOfDeviations={numberOfDeviationsForAuditPoint || 0}
          />
        </>
      }
    >
      <Outlet />
    </ElasticContentContainer>
  )
})
