/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { SystemMessage } from '@cdab/scania/qpr/schema'
import type {
  CreateSystemMessageData,
  SystemMessagesService as ISystemMessagesService
} from '@cdab/scania/qpr/interactor'

import { wait } from '@cdab/utils'

const mockSystemMessages: SystemMessage[] = [
  {
    author: 'Dennis Jutemark',
    id: 0,
    read: false,
    text: 'The first message',
    title: 'Title',
    createdTime: '2022-01-01'
  },
  {
    author: 'Andreas Olsson',
    id: 1,
    read: true,
    text: 'Marked as read 😎',
    title: 'Title',
    createdTime: '2022-01-01'
  },
  {
    author: 'Simon Dahlberg',
    id: 2,
    read: false,
    text: 'The third message',
    title: 'Title',
    createdTime: '2022-01-01'
  }
]

export class SystemMessagesService implements ISystemMessagesService {
  private readonly waitTime: number

  public constructor(waitTime: number) {
    this.waitTime = waitTime
  }

  public async GetSystemMessagesForUser(): Promise<SystemMessage[]> {
    await wait(this.waitTime)

    return mockSystemMessages
  }

  public async GetUnreadSystemMessagesForUser(): Promise<SystemMessage[]> {
    await wait(this.waitTime)

    return mockSystemMessages
  }

  public async ReadSystemMessageIdForUser(
    messageId: SystemMessage['id']
  ): Promise<void> {
    throw new Error('Method not implemented.')
  }

  public async GetSystemMessage(
    messageId: SystemMessage['id']
  ): Promise<SystemMessage> {
    throw new Error('Method not implemented.')
  }

  public async UpdateSystemMessage(
    systemMessage: SystemMessage
  ): Promise<void> {
    throw new Error('Method not implemented.')
  }

  public async DeleteSystemMessage(
    systemMessageId: SystemMessage['id']
  ): Promise<void> {
    throw new Error('Method not implemented.')
  }

  public async CreateSystemMessage(
    data: CreateSystemMessageData
  ): Promise<void> {
    throw new Error('Method not implemented.')
  }
}
