import { createGlobalStyle } from 'styled-components'
export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /* Scoped to TipTap */
  .tiptap {
    > * + * {
      margin-top: 0.75em;
    }
  }

  .tiptap p {
    margin: 1em 0 !important;
  }

  .tiptap ul, ol {
    padding: 0 4rem;
  }

  .tiptap code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  .tiptap pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
  }

  .tiptap pre code code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
  }

  .tiptap img {
    max-width: 100%;
    height: auto;
  }

  .tiptap blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  .tiptap hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
`
