import { Container, Row } from '@cdab/scania/sdds'
import { useTranslation } from 'react-i18next'
import { StyledButton, StyledColumn } from './root-error-boundary.styles'
import type { Props } from './root-error-boundary.types'

export function RootErrorBoundary(props: Props): JSX.Element {
  const { children, title, description } = props
  const { t } = useTranslation('errors')

  return (
    <Container fluid='normal' fullHeight>
      <Row fullHeight>
        <StyledColumn fullHeight width={12}>
          <h1 className='sdds-headline-02'>{title}</h1>
          {description && <p className='sdds-paragraph-02'>{description}</p>}
          {children}
          <a href='/' rel='noreferrer'>
            <StyledButton text={t('restart-application')} />
          </a>
        </StyledColumn>
      </Row>
    </Container>
  )
}
